import React from 'react';
import { MenuItem, Menu } from '@szhsin/react-menu';
import { FiMoreVertical } from 'react-icons/fi';
import * as S from './styles';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { handlerClick } from '../../../../hooks/actions';

interface MenuActionsProps {
  label: string;
  actionDelete: () => void;
}

export default function MenuActions({ label, actionDelete }: MenuActionsProps) {
  return (
    <div onClick={(e) => handlerClick(e)} aria-hidden="true">
      <Menu
        menuButton={
          <S.MenuBtn>
            <FiMoreVertical size={25} />
          </S.MenuBtn>
        }
        transition
      >
        <MenuItem onClick={actionDelete}>{label}</MenuItem>
      </Menu>
    </div>
  );
}
