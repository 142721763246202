import ReactInputMask from 'react-input-mask';
import { Smalled } from '../../../LegacyV4Components';

import * as S from './styles';

export type TextFieldStatus =
  | 'default'
  | 'focus'
  | 'filled'
  | 'error'
  | 'warning'
  | 'success';

export function FormikCardNumber({ ...props }: any) {
  let inputStatus = '';

  if (props.value !== '') {
    inputStatus = 'filled';
  }

  if (props.helper) {
    inputStatus = 'error';
  }

  if (props.status === 'default') {
    inputStatus = 'default';
  }

  /* <NumberFormat
            // format="#### #### #### ####"
            normalizeData
            mask={s.length < 12 ? '_' : ''}
            placeholder={props.label}
            error={props.error}
            fullWidth
            InputProps={{ inputMode: 'numeric' }}
            onClick={props.onClick}
            allowLeadingZeros
            value={props.value}
            onValueChange={props.onValueChange}
            max={props.max}
            min={props.min}
            {...props}
          /> */

  return (
    <S.InputWrapper className={inputStatus}>
      <label htmlFor={props.name}>
        <h6>{props.label}</h6>
        <S.InputControl
          className="input-control"
          style={{
            width: props.width ? `${props.width}` : 'auto',
            background: '#fff',
          }}
        >
          <ReactInputMask
            mask={props.mask ? props.mask : ''}
            // ref={inputRef}
            // onFocus={handleInputFocus}
            onBlur={props.onBlur}
            defaultValue={props.value}
            placeholder={props.placeholder}
            // onChangeCapture={props.onValueChange}
            onChange={(e) => props.onValueChange(e.target.value)}
            {...props}
          />
        </S.InputControl>
        {props.helper ? <Smalled>{props.helper}</Smalled> : null}
        {props.status === 'warning' ? (
          <Smalled>{props.warningMessage}</Smalled>
        ) : null}
      </label>
    </S.InputWrapper>
  );
}
