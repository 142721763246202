import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  p {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
  }

  h3 {
    font-size: 2.375rem;
    font-weight: 700;
    line-height: 3rem;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  gap: 1.5rem;

  & > div:first-child {
    width: 25rem;
  }
`;

export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 1.5rem;

  border-radius: 0.5625rem;
  border: 1px solid var(--grayscale-10);

  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.12);
`;

export const IconWrapper = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid
    var(--lab-ds-semantic-placeholder-color-border-border-default, #bebec1);

  & svg {
    color: var(--success-2);
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  gap: 0.5rem;

  h3 {
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  p {
    color: var(--success-1, #00c49a);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    cursor: pointer;
  }
`;

export const LineWrapper = styled.div`
  display: flex;

  && section {
    margin: 0;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & p {
    color: var(--secondary-main);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
  }

  & a {
    gap: 0.5rem;
    display: flex;
    align-items: center;
  }

  & svg {
    color: var(--secondary-main);
    width: 1rem;
    height: 1rem;
  }
`;

export const Link = styled.a`
  && {
    color: var(--lab-ds-semantic-color-fg-default, #333);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-decoration-line: underline;
    white-space: nowrap;
  }
`;

export const VideoContainer = styled.section`
  width: 18rem;
  height: 10rem;
  margin: 0 auto;
  display: flex;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0.1875rem;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .v4-table {
    max-height: max(calc(100vh - 45rem), 32rem);
  }

  .cell-row-container {
    gap: 0.5rem;
    justify-content: flex-start;
  }

  td:nth-child(3) {
    .cell-row-text {
      font-family: Inter;
      font-size: 0.75rem;
      font-weight: 700;
      text-decoration-line: underline;
    }
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const DetailsWrapper = styled.div`
  position: relative;

  width: fit-content;

  & > p {
    width: fit-content;
  }

  & .details-tooltip {
    opacity: 0;
    pointer-events: none;
  }

  & h6 {
    border-bottom: 1px solid var(--grayscale-20);

    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
  }

  &:hover .details-tooltip {
    opacity: 1;
    pointer-events: all;
  }
`;
