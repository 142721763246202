import { financeApi } from '../../api';

export async function getVerifyInvoiceById(depositId: string): Promise<any> {
  try {
    const response = await financeApi.get(`/media-wallet/invoice/${depositId}`);
    const pay: any = response.data;

    return pay;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar o pagamento, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
