import { investmentInfos } from '../../../services/requests/Unit/calculate';
import { convertNumberToBRL } from '../../../utils/convertNumberToBRL';

export const PAYMENT_METHODS = [
  { key: 'inCash', label: 'À vista', isChecked: false },
  { key: 'semester', label: '6 meses', isChecked: false },
  { key: 'monthly', label: 'Mensal', isChecked: false },
];
export const CONTRACT_DURATION = [
  { key: '12', label: '12 Meses', isChecked: false },
  { key: '24', label: '24 Meses', isChecked: false },
];

export const DISCOUNT_IN_CASH = 0.88;
export const DISCOUNT_PAY_IN_SIX_MONTHS = 0.94;

export function percentageFee(
  totalPayment: number,
  detailedInvestmentPerMonth: investmentInfos['detailedInvestmentPerMonth'],
) {
  return (
    (totalPayment / detailedInvestmentPerMonth?.operationalInvestment) *
    100
  ).toFixed(2);
}

export function getAllExpenses(
  detailedInvestment: investmentInfos['detailedInvestment'],
) {
  return convertNumberToBRL(
    (detailedInvestment.directExpenses +
      detailedInvestment.directOperationSalary +
      detailedInvestment.taxValue) *
      -1,
  );
}
