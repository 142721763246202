import {
  useRef,
  useCallback,
  useState,
  useEffect,
  TextareaHTMLAttributes,
} from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import * as S from './styles';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
}

export function Textarea({ name, ...rest }: TextareaProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleTextareaFocus = useCallback(() => {
    setIsTextareaFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsTextareaFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value',
    });
  }, [registerField, fieldName]);

  return (
    <>
      <S.Container isErrored={!!error} isTextareaFocused={isTextareaFocused}>
        <textarea
          name={name}
          onFocus={handleTextareaFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={textareaRef}
          {...rest}
        />
        {error && (
          <S.Error title={error}>
            <FiAlertCircle color="var(--primary-main)" size={20} />
          </S.Error>
        )}
      </S.Container>
    </>
  );
}
