import React from 'react';
import { FiCopy } from 'react-icons/fi';

import { useToast } from '../../../../hooks/toast';

import * as S from './styles';

export function CopyButton({ text }: { text: string }) {
  const { addToast } = useToast();

  return (
    <S.CopyButton
      onClick={(e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(text);
        addToast({
          title: 'Sucesso',
          description: 'Valor copiado para a área de transferência!',
          type: 'success',
        });
      }}
    >
      <FiCopy size={18} />
    </S.CopyButton>
  );
}
