import styled from 'styled-components';

export const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  gap: 2rem;

  div:first-child {
    max-width: 25rem;
    width: 100%;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    padding: 0 !important;
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.8rem;
`;

export const SectionHeader = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: var(--success-1);
    font-weight: bold;
    text-decoration: underline;

    display: flex;
    align-items: center;

    svg {
      margin-left: 0.625rem;
    }
  }
`;

export const ButtonsWraper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
`;
