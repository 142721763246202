import { LabDsButton, LabDsTable } from 'v4web-components-react';
import { useEffect, useState } from 'react';
import { IData, IOptionsSelect } from 'v4web-components';
import * as S from '../../styles';
import { useUser } from '../../../../hooks/user';
import { getUnitsByWorkload } from '../../../../services/requests/Unit/getUnitsByWorkload';
import { TeamStructureInterface } from '../../interface';
import { Mandate, Unit } from '../../../../hooks/types';
import { DEDICATION_OPTIONS, teamStructureHeader } from './utils';
import { useToast } from '../../../../hooks/toast';
import { InputItens } from '../../../../components/AtomicDesign/molecules/ModalPermissionsEdit/interfaces';

interface InviteNewColabProps {
  setUnits: (state: Unit[]) => void;
  setUnitSelected: (state: string) => void;
  unitsSelected: string;
  setIsloading: (state: boolean) => void;
  teamStructure: TeamStructureInterface[];
  setTeamStructure: (state: TeamStructureInterface[]) => void;
}

export function TeamStructure({
  setUnits,
  setUnitSelected,
  unitsSelected,
  teamStructure,
  setIsloading,
  setTeamStructure,
}: InviteNewColabProps) {
  const MAX_NUMBER_OF_ELEMENTS_TO_NOT_BREAK_PAGE = 7;

  const { mandate: mandateOptions, seniority: seniorityOptions } = useUser();
  const [rows, setRows] = useState<IData['rows']>([]);
  const headers = teamStructureHeader;
  const { addToast } = useToast();

  useEffect(() => {
    function getUnitsRespnoseHaveUnitSelected(units: Unit[]) {
      const index = units.findIndex((item) => {
        return item.unitId === unitsSelected;
      });
      if (index === -1) setUnitSelected('');
    }
    async function getUnits() {
      setIsloading(true);

      const uncompletedTeam = teamStructure.find((item) => {
        return !item.mandateId.length || !item.seniorityId.length;
      });

      if (uncompletedTeam) return;

      const response = await getUnitsByWorkload(teamStructure, 1);
      setIsloading(false);
      if (response) {
        setUnits(response);
        getUnitsRespnoseHaveUnitSelected(response);
        return;
      }

      setUnits([]);
      addToast({
        title:
          'Verifique se todos campos da estrutura time foram preenchidos, caso sim não há como atender',
        type: 'error',
      });
    }
    function getMandateOptions(value: string) {
      const newOptions = JSON.parse(JSON.stringify(mandateOptions));

      const newPriceMandates = newOptions.filter((item: InputItens) => {
        return (item.value as Mandate).isNewPrice;
      });
      newPriceMandates.map((item: IOptionsSelect) => {
        const newItem = item;
        if (newItem.key === value) {
          newItem.isChecked = true;
        }
        return newItem;
      });
      return newPriceMandates;
    }

    function getSeniorityOptions(value: string) {
      const newOptions: IOptionsSelect[] = JSON.parse(
        JSON.stringify(seniorityOptions as IOptionsSelect[]),
      );

      newOptions.map((item) => {
        const newItem = item;
        if (newItem.key === value) {
          newItem.isChecked = true;
        }
        return newItem;
      });
      return newOptions.filter((item) => {
        return item.label !== 'Trainee' && item.label !== 'Especialista';
      });
    }

    function getDedication(value: string) {
      const newOptions = JSON.parse(JSON.stringify(DEDICATION_OPTIONS));

      return newOptions.map((item: IOptionsSelect) => {
        const newItem = item;
        if (newItem.key === value) {
          newItem.isChecked = true;
        }
        return newItem;
      });
    }

    function setRowsTable() {
      const row: IData['rows'] = teamStructure.map(
        (team: TeamStructureInterface, index) => {
          return {
            mandate: {
              select: {
                options: getMandateOptions(team.mandateId),
                onChangeInputOptions: ({ detail }) => {
                  const newTeamStructure = teamStructure;
                  newTeamStructure[index].mandateId = detail[0].key;
                  getUnits();
                  setTeamStructure(newTeamStructure);
                  setRowsTable();
                },
                label: 'Selecione um cargo',
                type: 'radioButton',
                avatar: false,
                state: `${team.mandateId === '' ? 'error' : 'default'}`,
                value: team.mandateId,
              },
            },
            seniority: {
              select: {
                options: getSeniorityOptions(team.seniorityId),
                label: 'Selecione uma senioridade',
                type: 'radioButton',
                avatar: false,
                state: `${team.seniorityId === '' ? 'error' : 'default'}`,
                onChangeInputOptions: ({ detail }) => {
                  const newTeamStructure = teamStructure;
                  newTeamStructure[index].seniorityId = detail[0].key;
                  setTeamStructure(newTeamStructure);
                  getUnits();
                  setRowsTable();
                },
              },
            },
            dedication: {
              select: {
                options: getDedication(team.dedication),
                label: 'Selecione uma dedicação',
                type: 'radioButton',
                avatar: false,
                value: `${team.dedication}`,
                onChangeInputOptions({ detail }) {
                  const newTeamStructure = teamStructure;
                  newTeamStructure[index].dedication = detail[0].key;
                  setTeamStructure(newTeamStructure);
                  getUnits();
                },
              },
            },
            quantity: {
              quantify: {
                value: team.quantityOfWorkers,
                onHandleCounterValue: ({ detail }) => {
                  const aux = teamStructure;
                  aux[index].quantityOfWorkers = detail;
                  const filterRow = aux.filter(
                    (item) => item.quantityOfWorkers !== 0,
                  );
                  setTeamStructure(filterRow);
                  getUnits();
                },
              },
            },
          };
        },
      );
      setRows(row);
    }
    setRowsTable();
  }, [
    mandateOptions,
    seniorityOptions,
    setTeamStructure,
    setUnits,
    addToast,
    teamStructure,
    setIsloading,
    setUnitSelected,
    unitsSelected,
  ]);

  return (
    <S.ContentBox>
      <S.ContentHeader>
        <h4>Estrutura do time</h4>
      </S.ContentHeader>

      <S.Content
        overflow={
          teamStructure.length < MAX_NUMBER_OF_ELEMENTS_TO_NOT_BREAK_PAGE
        }
      >
        <>
          <LabDsTable data={{ headers, rows }} />
          <S.ButtonAction>
            <LabDsButton
              label="Adicionar cargos"
              variant="link-button"
              stepIcon="add"
              onHandleButton={() => {
                setTeamStructure(
                  teamStructure.concat({
                    mandateId: '',
                    seniorityId: '',
                    dedication: '',
                    quantityOfWorkers: 1,
                  }),
                );
                return;
              }}
            />
          </S.ButtonAction>
        </>
      </S.Content>
    </S.ContentBox>
  );
}
