import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  .breadcrumb-content {
    a {
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
      font-size: 1rem;
      line-height: 1.5rem;
      color: var(--secondary-main);
      text-decoration: none;
    }

    :hover {
      span {
        color: var(--secondary-light);
        border-bottom: 1px solid var(--secondary-light);
        transform: translateY(2px);
      }
    }

    &:first-child p {
      display: none;
    }

    p {
      margin: 0 1rem;
    }

    span {
      transition: all 0.3s ease;
      border-bottom: 1px solid transparent;
    }
  }
`;
