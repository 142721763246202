import { sdrWhatsappBot } from '../../api';
import { ISdrBotMessages } from '../../../types/sdrBotMessages';

export async function getSdrBotMessages(
  phone_number: string,
): Promise<ISdrBotMessages | undefined> {
  try {
    const response = await sdrWhatsappBot.post(`/messages`, { phone_number });

    const payload: ISdrBotMessages = response.data as ISdrBotMessages;

    return payload;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar as mensagens, atualize a página e tente novamente 😕`,
      err,
    );
    return undefined;
  }
}
