import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #f4f4f8;
  border-radius: 8px;
  padding: 40px 80px 40px 80px;
  @media (max-width: 880px) {
    padding: 40px 16px 40px 16px;
  }
  .titleLookingAhead {
    margin-top: 3rem;
    @media (max-width: 880px) {
      margin-top: 1.5rem;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  svg {
    width: 2rem;
    height: 2rem;
    color: #8383af;
  }
`;

export const Navbar = styled.section`
  display: flex;
  margin-top: 4.375rem;
  gap: 1.5rem;
  @media (max-width: 880px) {
    margin-top: 1.5rem;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: #8383af;
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3rem;
  p {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-left: 8px;
    }
  }
  span {
    font-size: 12px;
    font-weight: 400;
    color: #7f7f7f;
  }
`;

export const RequestsDesktop = styled.div`
  margin-top: 3rem;
  height: fit-content;
  width: 32%;
  background: #fff;
  border-radius: 4px;
  display: ${(props) => props.color};

  .your-request {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #b4b4cf;
    padding: 16px 10px 8px;
    svg {
      margin-right: 26px;
      width: 18px;
      height: 18px;
    }
  }
  .your-product {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    padding: 16px 10px 0px;
  }

  section > p {
    padding: 10px 8px;
    font-size: 12px;
    color: #7f7f7f;
  }

  @media (max-width: 880px) {
    display: none;
  }
`;

export const RequestsMobile = styled.div`
  display: none;
  margin-top: 64px;
  height: fit-content;
  width: auto;
  background: #fff;
  border: 1px solid #00c49a;
  border-radius: 4px;
  .your-request {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #b4b4cf;
    padding: 16px 10px 8px;
    svg {
      margin-right: 26px;
      width: 18px;
      height: 18px;
    }
  }
  .your-product {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    padding: 16px 10px 0px;
  }

  section > p {
    padding: 4px 10px 8px;
    font-size: 12px;
    color: #7f7f7f;
  }

  @media (max-width: 880px) {
    display: ${(props) => props.color};
  }
`;

export const Body = styled.div`
  display: flex;
  justify-content: ${(props) => props.theme};
  gap: 24px;
  @media (max-width: 880px) {
    flex-direction: column;
  }
`;

export const ContainerTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  width: 100%;
  gap: 20px;
  display: ${(props) => props.color};
  @media (max-width: 880px) {
    gap: 0px;
  }
  section {
    cursor: pointer;
    position: relative;
    display: flex;
    width: 100%;
    height: 56px;
    border-radius: 1px solid #00c49a;
    background: #fff;
  }
  span {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    font-family: 'Inter';
    @media (max-width: 880px) {
      display: none;
    }
  }
  .selected1 {
    border-radius: 4px;
    border: 1px solid #00c49a;
  }
  section {
    border: 1px solid #dadae7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: #7f7f7f;
    padding: 1.25rem;
    @media (max-width: 880px) {
      padding: 0rem;
    }
      svg {
        color: #7f7f7f;
      }
  }
  svg {
    margin-right: 1.25rem;
    @media (max-width: 880px) {
      margin: 0 auto;
    }
  }
  .selected {
    background: #fff;
    color: #333333;
      svg {
        color: #00c49a;
      }
    }
  }
  .NotSelected {
    // background: #00c49a;
    color: #333333;
    svg {
      color: #333333;
    }
  }
`;

export const CustomerAvatar = styled.img`
  max-width: 220px;
  max-height: 120px;
  object-fit: contain;
  border: none;
`;

export const ButtonCoupons = styled.button`
  max-width: 80px;
  max-height: 40px;
  width: 100%;
  height: 100%;
  border: 2px solid #00c49a;
  border-radius: 4px;
  background: #fff;

  color: #00c49a;
  font-size: 14px;
  font-weight: bold;

  padding: 12px 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 1rem;
  &:hover {
    opacity: 0.7;
  }
`;

export const Cookie = styled.div`
  position: fixed;
  right: 4rem;
  bottom: 1rem;
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
  background-color: #00c49a;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 880px) {
    right: 1rem;
  }
  &:hover {
    background-color: #0ddeb1;
  }
`;

export const LinkDisabled = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .ContainerDisabled {
    padding: 2rem;
    width: 100%;
    text-align: center;
    border-radius: 0.5rem;

    background: var(--white);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 20px;
  }
`;
