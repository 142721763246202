import { useMemo } from 'react';
import { LabDsButton } from 'v4web-components-react';
import { useSearchParams } from 'react-router-dom';
import { Paragraph } from '../../../LegacyV4Components';

import * as S from './styles';

import facebookAdsIcon from '../../../../assets/icons/facebook.svg';
import { newAds } from '../../../../services/api';
import { useGetFacebookTokens } from '../../../../services/requests/Ads/getFacebookTokens';

interface ConnectFacebookProps {
  setShowModal: any;
}

export function ConnectFacebook({ setShowModal }: ConnectFacebookProps) {
  const [searchParams] = useSearchParams();

  const { data: tokens, refetch } = useGetFacebookTokens();
  const isConnected = !!tokens && !!tokens.length;

  function handleClick() {
    isConnected
      ? setShowModal(true)
      : // eslint-disable-next-line no-restricted-globals
        (location.href = `https://www.facebook.com/${process.env.REACT_APP_FB_GRAPH_VERSION}/dialog/oauth?client_id=${process.env.REACT_APP_FB_CLIENT_ID}&scope=public_profile,email,ads_read,ads_management&state=mktlab&redirect_uri=${location.href}`);
  }

  useMemo(async () => {
    const code = searchParams.get('code');
    if (code) {
      await newAds.post('/facebookAccounts/access-token', {
        code,
      });
      await refetch();
    }
  }, [searchParams, refetch]);

  return (
    <S.Wrapper>
      <S.ImageIcon src={facebookAdsIcon} />
      <S.ButtonWrapper>
        <Paragraph>Facebook Ads</Paragraph>
        <LabDsButton
          label={isConnected ? 'Vincular' : 'Conectar'}
          color="green"
          variant="outlined"
          onClick={handleClick}
        />
      </S.ButtonWrapper>
    </S.Wrapper>
  );
}
