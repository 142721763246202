import axios from 'axios';
import { parseCookies } from 'nookies';

const cookies = parseCookies(undefined);
const token = cookies['v4company.token'];

const headers = token
  ? {
      Authorization: `Bearer ${cookies['v4company.token']}`,
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': 'POST, GET',
    }
  : undefined;

export const api = axios.create({
  baseURL: process.env.REACT_APP_LAB_URL,
  headers,
});

export const apiV2 = axios.create({
  baseURL: process.env.REACT_APP_LAB2_URL,
  headers,
});

export const dashboardsApi = axios.create({
  baseURL: process.env.REACT_APP_DASHBOARDS_URL,
  headers,
});

export const notificationApi = axios.create({
  baseURL: process.env.REACT_APP_NOTIFICATIONS_URL,
  headers,
});

export const financeApi = axios.create({
  baseURL: process.env.REACT_APP_FINANCE_URL,
  headers,
});

export const federationRoiHuntersApi = axios.create({
  baseURL: process.env.REACT_APP_FEDERATION_ROIHUNTERS_URL,
  headers,
});

export const kubernetesApi = axios.create({
  baseURL: process.env.REACT_APP_FEDERATION_KUBERNETES_URL,
  headers: {
    Authorization: `Bearer ${cookies['v4company.token']}`,
  },
});

export const midiaWalletApi = axios.create({
  baseURL: process.env.REACT_APP_FEDERATION_KUBERNETES_URL,
  headers: {
    Authorization: `Bearer ${cookies['v4company.token']}`,
  },
});

export const usersApi = axios.create({
  baseURL: process.env.REACT_APP_USERS_URL,
  headers,
});

export const customerUsersApi = axios.create({
  baseURL: `${process.env.REACT_APP_FEDERATION_KUBERNETES_URL}/customerUsers`,
  headers,
});

export const customerAuthApi = axios.create({
  baseURL: `${process.env.REACT_APP_FEDERATION_KUBERNETES_URL}/customerAuth`,
  headers,
});

export const newAds = axios.create({
  baseURL: `${process.env.REACT_APP_FEDERATION_KUBERNETES_URL}/ads`,
  headers,
});

export const unitsApi = axios.create({
  baseURL: process.env.REACT_APP_UNITS_URL,
  headers,
});

export const leadBrokerApi = axios.create({
  baseURL: process.env.REACT_APP_HB_LEADS_URL,
  headers,
});

export const serverlessGateway = axios.create({
  baseURL: process.env.REACT_APP_SERVERLESS_GATEWAY,
  headers: {
    Authorization: `Bearer ${cookies['v4company.token']}`,
  },
});

export const timelineApi = axios.create({
  baseURL: process.env.REACT_APP_TIMELINE_URL,
  headers: {
    Authorization: `Bearer ${cookies['v4company.token']}`,
  },
});

export const customerApi = axios.create({
  baseURL: process.env.REACT_APP_CUSTOMERS_URL,
  headers: {
    Authorization: `Bearer ${cookies['v4company.token']}`,
  },
});

export const customerNewApi = axios.create({
  baseURL: `${process.env.REACT_APP_FEDERATION_KUBERNETES_URL}/customers`,
  headers: {
    Authorization: `Bearer ${cookies['v4company.token']}`,
  },
});

export const stackApi = axios.create({
  baseURL: process.env.REACT_APP_STACK_URL,
  headers: {
    Authorization: `Bearer ${cookies['v4company.token']}`,
  },
});

export const cepApi = axios.create({
  baseURL: 'https://api.brasilaberto.com/v1/zipcode',
});

export const fileApi = axios.create({
  baseURL: process.env.REACT_APP_STORAGE_URL,
  headers,
});
export const sdrWhatsappBot = axios.create({
  baseURL: process.env.REACT_APP_SDR_WHATSAPP_BOT_URL,
});

export const socketApi = process.env.REACT_APP_HB_LEADS_URL;

export default apiV2;
