import { defaultExtraction } from './TieAccountsModal';

const knowledgeBaseRoute = '/baseconhecimento';

export const facebookAds = {
  type: 'FACEBOOK_ADS',
  name: 'Facebook Ads',
  description: [
    'Com a conexão do Facebook Ads, você pode acessar todos os dados de desempenho das campanhas do seu cliente. Isso inclui informações detalhadas sobre o público-alvo, como idade, gênero, localização geográfica e interesses. Além disso, você pode obter dados sobre as impressões, cliques, conversões e outras métricas importantes.',
    'Com a conexão estabelecida, você pode visualizar informações de até 180 dias antes da conexão. Isso significa que você pode ver como as campanhas de anúncios do seu cliente foram executadas ao longo do tempo e identificar tendências ou padrões de desempenho.',
    'Em resumo, a conexão do Facebook Ads com a Business Manager do seu cliente fornece uma visão abrangente e detalhada do desempenho das campanhas de anúncios. Com informações precisas e atualizadas, você pode tomar decisões informadas em tempo real e ajustar suas estratégias de publicidade para maximizar o retorno do investimento do seu cliente.',
  ],
  extraction: [...defaultExtraction, 'Modelo de dashboards no Power B.I'],
  requirements: [
    'Ter acesso a BM do Cliente e realizar o login social na página a seguir',
  ],
  docs: `${knowledgeBaseRoute}/96893b8d2fcc4ea0af916dfd89afe37e`,
};

export const googleAds = {
  type: 'GOOGLE_ADS',
  name: 'Google Ads',
  description: [
    'Com a conexão do Google Ads, você pode acessar os dados dos resultados de todas as campanhas da conta de anúncio do seu cliente. Isso pode ser extremamente útil para avaliar a eficácia das campanhas e tomar decisões informadas sobre como otimizá-las. Além disso, é possível trazer dados de até 180 dias anteriores à conexão ser realizada e ter atualização diária dos dados, permitindo uma análise mais aprofundada e de longo prazo. Por exemplo, você pode acompanhar as tendências de desempenho ao longo do tempo e identificar quais tipos de campanhas têm funcionado melhor para o seu cliente.',
    'Com essas informações em mãos, você pode ajustar a estratégia de marketing do seu cliente para maximizar o retorno do investimento e aumentar a eficácia do seu marketing digital.',
  ],
  extraction: [...defaultExtraction, 'Modelo de dashboards no Power B.I'],
  requirements: [
    'Ter a MCC de sua unidade com acesso a conta de anúncio do cliente',
    'MCC da sua unidade precisa estar vinculada a MCC da Matriz',
  ],
  requirementsLinks: [
    {
      label: 'Clique aqui para saber como',
      link: `${knowledgeBaseRoute}/como-criar-uma-conta-mcc-no-google`,
    },
  ],
  docs: `${knowledgeBaseRoute}/daaff3206db3486a96aa1f6c5b2b6ed7`,
};

export const googleAnalytics = {
  type: 'GOOGLE_ANALYTICS_4',
  name: 'Google Analytics',
  description: [
    'Ao conectar sua plataforma ao Google Analytics, você terá acesso a uma gama de informações valiosas sobre o comportamento dos usuários em seu site. ',
    'Através da ferramenta, você poderá visualizar dados como o número de visualizações de página, a duração das sessões, as origens de tráfego e a taxa de rejeição de seu site. Essas informações são cruciais para entender como os usuários estão interagindo com seu conteúdo e para identificar possíveis problemas em sua estratégia de marketing digital. Além disso, o Google Analytics oferece recursos avançados, como a segmentação de público-alvo e a análise de funil de conversão, que permitem uma compreensão ainda mais profunda do comportamento dos usuários e uma otimização mais eficaz de seu site.',
  ],
  extraction: defaultExtraction,
  requirements: [
    'Liberar acesso de leitor da propriedade do cliente ou grupo ao seguinte e-mail: v4data-analytics@ga4-dados.iam.gserviceaccount.com',
  ],
  docs: `${knowledgeBaseRoute}/865b932537274e259c5c20f647144d53`,
};

export const activeCampaign = {
  type: 'ACTIVE_CAMPAIGN',
  name: 'Active Campaign',
  description: [
    'Com a conexão do ActiveCampaign, você pode acessar uma grande variedade de dados que te ajudarão a entender melhor o desempenho das suas campanhas de e-mail marketing. Além dos resultados das campanhas e automações, você também terá acesso a informações valiosas sobre a sua lista de contatos, como dados demográficos e comportamentais. Com essas informações em mãos, você poderá aprimorar suas estratégias de marketing e criar campanhas mais efetivas, alcançando um público maior e com um ROI maior.',
  ],
  extraction: defaultExtraction,
  requirements: [
    'No site do ActiveCampaign procure a aba Desenvolvedor. Você vai precisar da URL da API e da chave da API.',
  ],
  docs: `${knowledgeBaseRoute}/9373088ec0124128a7a217c797cae456`,
};

export const trayEcommerce = {
  type: 'TRAY_ECOMMERCE',
  name: 'Tray',
  description: [
    'Com a conexão do Tray, você terá acesso a informações valiosas sobre o seu e-commerce. Além de poder visualizar a lista de clientes, as vendas realizadas e os produtos adicionados no carrinho, você também poderá analisar o comportamento de compra dos seus clientes e identificar padrões de consumo. Com esses dados em mãos, você poderá tomar decisões mais informadas sobre suas estratégias de marketing, definir preços mais competitivos e otimizar a experiência do usuário no seu site. Além disso, a integração com o Tray permite que você tenha acesso a uma série de ferramentas de automação, como o acompanhamento de pedidos e a gestão de estoque, que podem ajudar a simplificar e agilizar o gerenciamento do seu negócio online.',
  ],
  extraction: [
    ...defaultExtraction,
    'Tenha um modelo de dashboard Power BI pronto (caso tenha vinculado com Facebook Ads e Google Ads)',
  ],
  requirements: [],
  docs: `${knowledgeBaseRoute}/8ddaa78f20544b6dae7116e787beddef`,
};

export const unbounce = {
  type: 'LANDING_PAGE',
  name: 'Unbounce',
  description: [
    'Com a conexão do Unbounce, você pode acessar os dados das suas landings pages como quantidade de usuários, cadastros e outros. Além disso, com esses dados em mãos, você pode analisar o desempenho de suas campanhas de marketing digital e identificar quais elementos de suas páginas estão funcionando melhor para atrair e converter novos clientes. Dessa forma, você pode otimizar suas páginas e maximizar seus resultados. Além disso, ao monitorar o desempenho de suas landings pages ao longo do tempo, você pode identificar tendências e padrões que podem ajudá-lo a prever o comportamento futuro dos seus clientes e ajustar sua estratégia de marketing em conformidade.',
  ],
  extraction: defaultExtraction,
  requirements: [
    'Landing Page ter sido criado no ambiente da V4 Company no Unbounce.',
  ],
  docs: `${knowledgeBaseRoute}/bd0aee6aa9ec486c8360ab5ffe5fb559`,
};

export const kommo = {
  type: 'KOMMO_CRM',
  name: 'Kommo CRM',
  description: [
    'Com a conexão da Kommo, você pode acessar todos os dados do seu funil de vendas. Isso inclui informações sobre o desempenho de cada etapa do funil, como o número de leads gerados, a taxa de conversão de cada etapa e o tempo médio que um lead leva para passar de uma etapa para outra. Além disso, é possível visualizar o histórico de interações de cada lead, incluindo e-mails enviados, ligações realizadas e reuniões agendadas. Com esses dados, você pode identificar pontos de melhoria em seu funil de vendas e tomar decisões mais informadas sobre como otimizá-lo para aumentar as conversões e vendas.',
  ],
  extraction: defaultExtraction,
  requirements: [
    'Ter criado o aplicativo de integração na plataforma Kommo e ter em mãos as seguintes informações geradas pela plataforma:',
    'Chave secreta',
    'Id da integração',
  ],
  docs: `${knowledgeBaseRoute}/c97205d5601e40c2b99fc2b23bcc34aa`,
};

export const vtex = {
  type: 'VTEX',
  name: 'Vtex',
  description: [
    'A conexão com a plataforma de e-commerce VTEX é uma funcionalidade incrível que permite que você gerencie e analise os dados das vendas dos seus produtos. Além disso, você pode visualizar o funil de conversão, que lhe dará uma visão mais clara dos estágios pelos quais seus clientes passam antes de fazer uma compra. Isso pode ser utilizado para otimizar o processo de vendas e melhorar a experiência do cliente. Além disso, a integração com a VTEX também permite que você visualize outras informações importantes, como a taxa de rejeição, o tempo médio de permanência na página e muito mais. Dessa forma, você terá um panorama completo do desempenho do seu e-commerce e poderá tomar decisões mais informadas para impulsionar o seu negócio.',
  ],
  extraction: defaultExtraction,
  requirements: [
    'Ter criado o aplicativo de integração na plataforma e ter em mãos as seguintes informações geradas pela plataforma:',
    'App key',
    'App secret',
    'Subdomínio do cliente',
  ],
  docs: `${knowledgeBaseRoute}/conectar-a-vtex-no-mediahub`,
};

export const tiktok = {
  type: 'TIKTOK_ADS',
  name: 'TikTok',
  description: [
    'Com a conexão no Tiktok Ads, você pode saber exatamente como estão os resultados das suas campanhas de mídia na plataforma. Isso é extremamente útil para avaliar o desempenho dos anúncios e tomar decisões estratégicas com base em dados precisos. Além disso, o Tiktok é uma das redes sociais mais populares do mundo atualmente, com um público jovem e engajado. Isso significa que, ao anunciar no Tiktok, você tem a oportunidade de alcançar uma audiência ampla e diversificada.',
  ],
  extraction: defaultExtraction,
  requirements: ['Liberar acesso a sua conta do Tiktok na página a seguir'],
  docs: `${knowledgeBaseRoute}/conectar-a-vtex-no-mediahub-1`,
};

export const bitrix = {
  type: 'BITRIX',
  name: 'Bitrix24',
  description: [
    'Com a integração à Bitrix24 CRM, você ganha acesso a um conjunto amplo e detalhado de métricas que revolucionam o gerenciamento de contatos e negociações. Esta conexão traz uma visão completa, desde dados fundamentais de contatos até nuances específicas das negociações. ',
    'Tenha uma visão clara de cada negociação, incluindo ID único, título, valor, estágio, categoria e oportunidades associadas. Rastreie datas de início, fechamento e atualizações, bem como os responsáveis por cada etapa da negociação, permitindo uma análise aprofundada do ciclo de vendas e otimização de processos.',
    'Esta integração com a Bitrix24 CRM não só fornece dados cruciais para um entendimento aprofundado de cada contato e negociação, mas também facilita a personalização das abordagens de vendas e fortalece as estratégias de relacionamento com o cliente. Transforme esses insights em ações concretas para impulsionar o crescimento e a eficiência do seu negócio.',
  ],
  extraction: defaultExtraction.slice(0, 1),
  requirements: [
    'Ter permissão de administrador na conta da Bitrix CRM',
    'Criar um webhook na Bitrix para autorizar o recebimento dos dados.',
  ],
  docs: `${knowledgeBaseRoute}/conectar-a-bitrix24-no-mediahub`,
};

export const rdstation = {
  type: 'RDSTATION',
  name: 'RD Station CRM',
  description: [
    'Descubra o poder da integração avançada com o RD Station CRM, agora com acesso direto a métricas detalhadas de produtos. Esta nova conexão traz transparência e precisão aos seus dados de vendas, oferecendo insights valiosos como ID do produto, nome, descrição, visibilidade, preço base, além de informações de criação e atualização. Com esses dados ao seu alcance, você pode monitorar de perto o ciclo de vida dos produtos, ajustar estratégias de vendas e marketing, e tomar decisões baseadas em informações precisas e atualizadas, levando a um gerenciamento de produto mais eficiente e a uma experiência de cliente aprimorada.',
  ],
  extraction: defaultExtraction.slice(0, 1),
  requirements: [
    'Ter permissão de administrador na conta da RD Station CRM',
    'ID da conta da RD Station CRM',
    'Token de utilização para coleta de dados ',
  ],
  docs: `${knowledgeBaseRoute}/conectar-a-rd-station-crm-no-mediahub`,
};

export const lojaIntegrada = {
  type: 'LOJA_INTEGRADA',
  name: 'Loja Integrada',
  description: [
    'Esta ferramenta foi desenvolvida para proporcionar uma integração eficiente e direta dos dados cruciais do seu negócio de e-commerce. Com esta conexão, você terá acesso imediato a três categorias essenciais de dados: dados do cliente, dados das compras e dados dos produtos.',
    'Dados do Cliente: A integração permite a coleta e análise de informações detalhadas dos clientes, incluindo histórico de interações, preferências e comportamento de compra, facilitando uma compreensão aprofundada do seu público-alvo.',
    'Dados das Compras: Obtenha insights valiosos sobre as transações efetuadas, incluindo detalhes de pedidos, histórico de compras, padrões de gasto e outras métricas de desempenho que são cruciais para a análise financeira e estratégica.',
    'Dados dos Produtos: Acesse informações detalhadas sobre os produtos, incluindo desempenho de vendas, níveis de estoque, categorizações e tendências, o que é vital para gerenciar o inventário e planejar estratégias de marketing e vendas.',
    'Esta integração foi projetada para ser robusta e segura, garantindo a precisão e a confiabilidade dos dados. Além disso, a plataforma oferece uma interface de usuário intuitiva, facilitando o acesso e a interpretação dos dados para a tomada de decisões estratégicas mais informadas e eficazes.',
  ],
  extraction: defaultExtraction.slice(0, 1),
  requirements: [
    'Ter permissão de administrador na conta da Loja Integrada',
    'Criar um aplicativo de integração na Loja Integrada',
    'Ter acesso ao APP Key e API Key',
  ],
  docs: `${knowledgeBaseRoute}/conectar-a-vtex-no-mediahub-2`,
};

export const pipedrive = {
  type: 'PIPEDRIVE',
  name: 'Pipedrive',
  description: [
    'Esta ferramenta foi desenvolvida para proporcionar uma integração eficiente e direta dos dados cruciais do seu negócio de e-commerce. Com esta conexão, você terá acesso imediato a três categorias essenciais de dados: dados do cliente, dados das compras e dados dos produtos.',
    'Dados do Cliente: A integração permite a coleta e análise de informações detalhadas dos clientes, incluindo histórico de interações, preferências e comportamento de compra, facilitando uma compreensão aprofundada do seu público-alvo.',
    'Dados das Compras: Obtenha insights valiosos sobre as transações efetuadas, incluindo detalhes de pedidos, histórico de compras, padrões de gasto e outras métricas de desempenho que são cruciais para a análise financeira e estratégica.',
    'Dados dos Produtos: Acesse informações detalhadas sobre os produtos, incluindo desempenho de vendas, níveis de estoque, categorizações e tendências, o que é vital para gerenciar o inventário e planejar estratégias de marketing e vendas.',
    'Esta integração foi projetada para ser robusta e segura, garantindo a precisão e a confiabilidade dos dados. Além disso, a plataforma oferece uma interface de usuário intuitiva, facilitando o acesso e a interpretação dos dados para a tomada de decisões estratégicas mais informadas e eficazes.',
  ],
  extraction: defaultExtraction.slice(0, 1),
  requirements: [
    'Ter permissão de administrador na conta do Pipedrive',
    'Ter acesso ao token do Pipedrive',
  ],
  docs: `${knowledgeBaseRoute}/conectar-o-pipedrive-no-mediahub`,
};
