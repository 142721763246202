import { Dispatch, SetStateAction, useState } from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { FiX } from 'react-icons/fi';
import { Tooltip } from '../Tooltip';
import * as S from './styles';

type Data = Record<string, number>;

interface ColumnProps {
  label: string;
  accessor: string;
  isSorted: boolean;
  sortable: boolean;
  sortByOrder: 'asc' | 'desc';
  maxWidth: number;
  isActions?: boolean;
}

interface ModalCreditsProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  modalData: ModalDataProps;
}

interface ModalDataProps {
  name?: string;
  email?: string;
  value?: string;
  justification?: string;
  creationDate?: string;
  creationTime?: string;
  credit_type?: string;
  expiresAt?: string;
}

export const ModalCredits = ({
  isModalOpen,
  setIsModalOpen,
  modalData,
}: ModalCreditsProps) => {
  return (
    <S.ContainerModal>
      <S.AllContainer>
        <S.ModalHeader>
          <h2>Informações dos créditos</h2>

          <FiX
            style={{ color: '#8383AF' }}
            onClick={() => setIsModalOpen(!isModalOpen)}
          />
        </S.ModalHeader>

        <S.ModalContent>
          <div>
            <h3>Nome</h3>

            <strong>{modalData.name}</strong>
          </div>

          <div>
            <h3>Email</h3>

            <strong>{modalData.email}</strong>
          </div>

          <div>
            <h3>Valor</h3>

            <strong>{modalData.value}</strong>
          </div>

          <div>
            <h3>Data da transação</h3>

            <strong>{modalData.creationDate}</strong>
          </div>

          <div>
            <h3>Hora da transação</h3>

            <strong>{modalData.creationTime}</strong>
          </div>

          <div>
            <h3>Tipo de crédito</h3>

            <strong>{modalData.credit_type}</strong>
          </div>

          <div>
            <h3>Data limite do crédito</h3>

            <strong>{modalData.expiresAt}</strong>
          </div>
        </S.ModalContent>

        <S.ReasonCredits>
          <h3>Motivo dos créditos</h3>

          <strong>{modalData.justification}</strong>
        </S.ReasonCredits>
      </S.AllContainer>

      <S.Background />
    </S.ContainerModal>
  );
};

function TableHead({ columns }: { columns: ColumnProps[] }) {
  return (
    <thead>
      {columns.map(({ label, accessor, isSorted, sortByOrder, maxWidth }) => {
        const cl = isSorted ? sortByOrder : '';

        return (
          <th
            style={{ maxWidth: maxWidth ? `${maxWidth}%` : '10%' }}
            key={accessor}
            className={cl}
            title={label}
          >
            <p>{label}</p>
          </th>
        );
      })}
    </thead>
  );
}

export function TableExtract({
  columns,
  data,
}: {
  columns: ColumnProps[];
  data: Data[];
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({} as ModalDataProps);

  return (
    <S.Table>
      <TableHead columns={columns} />

      {data?.map((item: Data) => (
        <S.Body key={item.id}>
          <tr>
            {columns.map(({ accessor }) => {
              const tData = item[accessor] ? item[accessor] : '——';

              return accessor === 'email' && String(tData).length > 20 ? (
                <Tooltip info title={`${tData}`} extract>
                  <td>{tData}</td>
                </Tooltip>
              ) : (
                <td>
                  {accessor === 'log' ? (
                    <AiOutlineExclamationCircle
                      color="var(--success-3)"
                      size={22}
                      onClick={() => {
                        setModalData(item);
                        setIsModalOpen(true);
                      }}
                    />
                  ) : (
                    tData
                  )}
                </td>
              );
            })}
          </tr>
        </S.Body>
      ))}

      {isModalOpen && (
        <ModalCredits
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          modalData={modalData}
        />
      )}
    </S.Table>
  );
}
