import { usersApi as api } from '../../api';

interface GetPermissions {
  unitId?: string;
  userId: string;
}

interface ResponseRoles {
  _id?: string;
  userId?: string;
  unitId?: string;
  whoChanged?: string;
  whoCreated?: string;
  deletedAt?: Date;
  dashboard?: {
    roleName?: string;
    view?: boolean;
    edit?: boolean;
    delete?: boolean;
    admin?: boolean;
  };
  unit?: {
    roleName?: string;
    view?: boolean;
    edit?: boolean;
    delete?: boolean;
    invite?: boolean;
    admin?: boolean;
  };
  meUnit?: {
    roleName?: string;
    view?: boolean;
    edit?: boolean;
    delete?: boolean;
    invite?: boolean;
    admin?: boolean;
  };
  leadbroker?: {
    roleName?: string;
    view?: boolean;
    bid?: boolean;
    admin?: boolean;
    withdraw?: boolean;
    addFunds?: boolean;
  };
  paymentGateway?: {
    roleName?: string;
    view?: boolean;
    edit?: boolean;
    admin?: boolean;
  };
  ekyte?: {
    roleName?: string;
    view?: boolean;
    admin?: boolean;
  };
  customer?: {
    roleName?: string;
    view?: boolean;
    delete?: boolean;
    edit?: boolean;
    admin?: boolean;
  };
  users?: {
    roleName?: string;
    view?: boolean;
    delete?: boolean;
    edit?: boolean;
    admin?: boolean;
  };
}

export async function getPermissionUser({ userId }: GetPermissions) {
  try {
    const response = await api.get(`/user/roles/${userId}`);

    const permissions = response.data as ResponseRoles;

    return permissions;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao salvar as informações deste usuário 😕`,
      err,
    );
    return err.response.data;
  }
}
