import { ReactNode } from 'react';
import { MdRemoveCircleOutline } from 'react-icons/md';
import { Input } from '../../../../LegacyV4Components';

import { TieAccountsModal } from '../TieAccountsModal';

import {
  CustomerIntegrationsContextData,
  CustomerIntegrationsProvider,
} from '../../../../../hooks/customerIntegrations';

import { googleAds as options } from '../platforms';

import * as Edit from '../components/IntegrationEditRow/styles';
import * as Summary from '../components/IntegrationSummaryRow/styles';

interface ActiveCampaignModalProps {
  show: boolean;
  onClose: () => void;
}

const googleAdsRegex = /\d{3}-\d{3}-\d{4}/;

function testRegex(integrationId: string): boolean {
  return googleAdsRegex.test(integrationId);
}

export function ConfigGoogleAdsModal({
  show,
  onClose,
}: ActiveCampaignModalProps) {
  const tableColumns = [
    {
      accessor: 'customer',
      label: 'Cliente',
      sizing: 1,
      sortable: false,
    },
    {
      accessor: 'integrationId',
      label: 'ID da conta',
      sizing: 1,
      sortable: false,
    },
    {
      accessor: 'status',
      label: 'Status',
      sizing: 0.5,
      sortable: false,
    },
  ];

  function isConnected(integration: AdsAccounts): boolean {
    return (
      testRegex(integration.integrationId) &&
      !!integration.projectId &&
      !integration.isDeleted
    );
  }

  function additionalValidation(integration: AdsAccounts): boolean {
    return isConnected(integration);
  }

  function renderEditRow(
    integration: AdsAccounts,
    error: boolean,
    context: CustomerIntegrationsContextData,
  ): ReactNode {
    const { setIntegrationId, deleteIntegration } = context;
    const idError = error && !testRegex(integration.integrationId);
    return (
      <Edit.Row title={integration.integrationId}>
        <div>
          <Input
            label=""
            value={integration.integrationId}
            name="integrationId"
            onChange={({ target: { value } }) =>
              setIntegrationId(integration, value)
            }
            placeholder="000-000-0000"
            className={idError ? 'error' : ''}
            style={{ width: '10rem' }}
            mask="999-999-9999"
          />
          {idError && <Edit.Error>ID inválido</Edit.Error>}
        </div>
        <MdRemoveCircleOutline
          size={22}
          onClick={() => deleteIntegration(integration)}
        />
      </Edit.Row>
    );
  }

  function renderSummaryRow(integration: AdsAccounts): ReactNode {
    return (
      <>
        <td title={integration.integrationId}>
          <Summary.IntegrationParagraph>
            {integration.integrationId}
          </Summary.IntegrationParagraph>
        </td>
      </>
    );
  }

  return (
    <CustomerIntegrationsProvider type={options.type} show={show}>
      <TieAccountsModal
        options={options}
        show={show}
        onClose={onClose}
        tableColumns={tableColumns}
        renderEditRow={renderEditRow}
        renderSummaryRow={renderSummaryRow}
        isConnected={isConnected}
        additionalValidation={additionalValidation}
      />
    </CustomerIntegrationsProvider>
  );
}
