import styled, { css } from 'styled-components';

export const ModalOverlay = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  z-index: 30;

  background-color: rgb(0 0 0 / 25%);

  &.show {
    display: flex;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
  max-height: calc(100vh - 2rem);
  width: 85vw;

  border-radius: 8px;
  background-color: var(--white);
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 27px 40px 29px 40px;

  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);

  & .close-button {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    cursor: pointer;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 1.5rem;
  padding: 0 2.5rem 2.5rem 2.5rem;

  overflow: auto;

  & table {
    flex-grow: 1;

    tr {
      p.color-error {
        color: var(--error);
      }
    }

    & tbody tr .text-wrapper {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1.75rem 2.5rem;
`;

export const ProgressBar = styled.div<{ porc: string }>`
  width: ${({ porc }) => porc};
  height: 0.5rem;
  border-radius: 0 10rem 10rem 0;
  background-color: var(--success-2);
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2.5rem;
`;

export const Phase = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 2rem 2.5rem 2rem 2.5rem;

  & svg {
    color: var(--success-2);
  }
`;

export const PhaseItem = styled.p<{ active: boolean }>`
  ${({ active }) =>
    active &&
    css`
      color: var(--success-2);
    `};
`;

export const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 12rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--grayscale-6);
  border-radius: 4px;
  font-weight: 700;
  white-space: nowrap;
  &.color-green {
    color: var(--success-2);
  }
  &.color-red {
    color: var(--error);
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3 3 0;
  gap: 1rem;
  padding-right: 3rem;
`;

export const Info = styled.div`
  flex-direction: column;
  display: flex;
  flex: 2;
  gap: 1rem;
  padding-left: 3rem;
`;

export const ManualWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  gap: 0.5rem;
  & a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    p {
      text-decoration: underline;
    }
  }
  & svg {
    color: var(--success-2);
  }
`;

export const HelpLink = styled.p`
  margin-left: 1.25rem;
  color: var(--success-2);
  line-height: 1.5rem;
  text-decoration-line: underline;
`;

export const ControllersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 10;
  min-height: 2.82rem;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.5rem;

  td {
    overflow: hidden;
  }
`;
