import React, { ReactNode } from 'react';

import * as S from './styles';

interface TooltipProps {
  title: string;
  children: ReactNode;
  position?: 'right' | 'left';
}

export function Tooltip({ title, children, position = 'right' }: TooltipProps) {
  return (
    <S.TooltipCard>
      <S.TooltipText>{children}</S.TooltipText>
      <S.TooltipBox className={`${position}`}>
        <p>{title}</p>
      </S.TooltipBox>
    </S.TooltipCard>
  );
}
