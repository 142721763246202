import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.75rem 1rem;

  p {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  p:first-child {
    margin-top: 0;
  }

  input::placeholder {
    color: var(--secondary-light);
  }
`;

export const InputField = styled.input<{ error?: boolean }>`
  width: 25rem;
  height: 3rem;
  border-radius: 0.25rem;
  border: 1px solid
    ${({ error }) => (error ? 'var(--error)' : 'var(--secondary-light)')};

  padding: 1rem 0.75rem;
`;
export const ErrorTag = styled.h6`
  color: var(--error);
`;

export const ImportFilesTitle = styled.p`
  display: flex;
  align-items: center;

  cursor: pointer;

  margin-bottom: 0 !important;
  margin-top: 0 !important;

  margin-left: -5px;

  padding: 0;

  color: var(--success-1);

  text-decoration: underline;
`;

export const SizeWarning = styled.p`
  margin-top: 1.25rem !important;
  margin-bottom: 0.5rem !important;

  font-size: 0.75rem;
`;
export const FileList = styled.div`
  width: 100%;
`;

export const File = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 2.5rem;

  margin-bottom: 0.5rem;
  padding: 0 1rem;

  border-radius: 0.4rem;

  background-color: var(--tertiary-main);

  svg {
    cursor: pointer;
  }
`;

export const FileName = styled.p`
  margin: 0 !important;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  margin-top: 1rem;
  padding: 1rem;

  background-color: white;

  border-radius: 0px 0px 0.25rem 0.25rem;
`;
