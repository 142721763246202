import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 2rem;
  margin: 0 1rem;
  border-radius: 0.5rem;
  background: white;

  h2 {
    font-size: 2rem;
    margin-right: 4rem;
  }

  > section {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;

    > span {
      font-weight: bold;
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      line-height: 25px;
      color: var(--secondary-light);
    }
  }
`;

export const InputArea = styled.div`
  width: 400px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Info = styled.div`
  min-width: 60%;
  height: 80px;
  margin-left: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  span {
    text-align: right;
  }
`;

export const ErrorInput = styled.samp`
  font-size: 12px;
  color: red;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;

  button {
    :first-of-type {
      margin-right: 1rem;
    }
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 1120px;
  width: 100%;

  padding: 3rem 4rem;
  margin: 0 1rem;
  border-radius: 0.5rem;
  background: var(--white);

  h2 {
    font-size: 2rem;
    margin: 40px 0;
  }
`;

export const SuccessButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  button {
    white-space: nowrap;
    width: auto;
    padding: 1rem 2rem;

    :first-of-type {
      margin-right: 1rem;
    }
  }
`;

export const Loading = styled.div`
  width: 400px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  margin-top: 25px;
  justify-content: center;
  align-items: center;
`;

export const ModalHeader = styled.div`
  margin: -15px;
  padding: 15px;
  margin-bottom: 30px;
  height: 95px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.12);
`;
export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: -15px;
  margin-top: 45px;
  padding: 15px;
`;
