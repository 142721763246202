import styled from 'styled-components';

export const Container = styled.div`
  header {
    padding-bottom: 0;
  }
`;

export const AllContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-top: 2rem;
    margin-bottom: 1.2rem;
    font-size: 1.38rem;
    font-weight: 700;
    font-family: 'Inter';
  }

  strong {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Nunito Sans';
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
  padding-bottom: 2rem;

  .cancel {
    width: 11.57rem;
    height: 2.5rem;
    border-radius: 0.3rem;
    border: 0.13rem solid var(--primary-dark-2);
    background: transparent;
    font-size: 0.88rem;
    font-weight: 700;
    font-family: 'Inter';
    color: var(--primary-dark-2);

    &:active {
      opacity: 0.7;
    }
  }

  .block {
    width: 11.57rem;
    height: 2.5rem;
    border-radius: 0.3rem;
    border: 0;
    background: var(--success-2);
    font-size: 0.88rem;
    font-weight: 700;
    font-family: 'Inter';
    color: var(--secondary-main);

    &:hover {
      opacity: 0.8;
    }
  }
`;
