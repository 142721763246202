import { LabDsButton, LabDsTextArea } from 'v4web-components-react';
import { useState } from 'react';
import * as S from '../../styles';

export function Observations() {
  const [observation, setObservation] = useState<string | null>(null);
  return (
    <S.ContentBox>
      <S.ContentHeader>
        <h4>Observações</h4>
        {observation === null && (
          <LabDsButton
            label="Adicionar Observações"
            variant="link-button"
            stepIcon="add"
            onHandleButton={() => {
              setObservation('');
            }}
          />
        )}
      </S.ContentHeader>

      {observation !== null && (
        <S.Content>
          <S.Input>
            <LabDsTextArea
              titleInput="Digite sua observação"
              value={observation}
              cols={999}
            />
          </S.Input>
          <S.ButtonAction>
            <LabDsButton
              label="Remover observação"
              variant="link-button"
              stepIcon=""
              onHandleButton={() => {
                setObservation(null);
              }}
            />
          </S.ButtonAction>
        </S.Content>
      )}
    </S.ContentBox>
  );
}
