import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { Box } from '@mui/material';
import { Smalled } from '../../../LegacyV4Components';
import * as S from './styles';

export type TextFieldStatus =
  | 'default'
  | 'focus'
  | 'filled'
  | 'error'
  | 'warning'
  | 'success';

export function FormikCepInput({ ...props }: NumberFormatProps) {
  let inputStatus = '';

  if (props.value !== '') {
    inputStatus = 'filled';
  }

  if (props.helper) {
    inputStatus = 'error';
  }

  if (props.status === 'default') {
    inputStatus = 'default';
  }

  const s = props.value ? props.value.toString() : '';

  return (
    <S.InputWrapper className={inputStatus}>
      <label htmlFor={props.name}>
        <h6>{props.label}</h6>
        <S.InputControl
          className="input-control"
          style={{
            width: props.width ? `${props.width}` : 'auto',
            background: '#fff',
          }}
        >
          <NumberFormat
            format="#####-###"
            mask={s.length < 12 ? '_' : ''}
            placeholder={props.label}
            error={props.error}
            fullWidth
            InputProps={{ inputMode: 'numeric' }}
            onClick={props.onClick}
            allowLeadingZeros
            helperText="sdkjfn"
            isNumericString={false}
            value={props.value}
            onValueChange={props.onValueChange}
            {...props}
          />
        </S.InputControl>
        <Box sx={{ height: '1rem' }}>
          {props.helper ? <Smalled>{props.helper}</Smalled> : null}
          {props.status === 'warning' ? (
            <Smalled>{props.warningMessage}</Smalled>
          ) : null}
        </Box>
      </label>
    </S.InputWrapper>
  );
}
