import { EditPermissions } from '../../../components/AtomicDesign/molecules/ModalPermissionsEdit/interfaces';
import { RoleValues } from '../../../utils/roleValuesByName';
import { usersApi as api } from '../../api';

interface BodyInvite extends EditPermissions {
  unitId: string;
}

export async function updatePermissionUser({
  userId,
  paymentGatewayRole,
  dashboardRole,
  unitRole,
  leadbrokerRole,
  blackBoxRole,
  dealBrokerRole,
  userRole,
  customerRole,
  headquarterRole,
  transitionRole,
}: BodyInvite) {
  try {
    const response = await api.put(`/user/roles/update`, {
      userId,
      paymentGateway: paymentGatewayRole && RoleValues[paymentGatewayRole],
      dashboard: dashboardRole && RoleValues[dashboardRole],
      unit: unitRole && RoleValues[unitRole],
      leadbroker: leadbrokerRole && RoleValues[leadbrokerRole],
      blackBox: blackBoxRole && RoleValues[blackBoxRole],
      dealBroker: dealBrokerRole && RoleValues[dealBrokerRole],
      users: userRole && RoleValues[userRole],
      customer: customerRole && RoleValues[customerRole],
      headquarter: headquarterRole && RoleValues[headquarterRole],
      transition: transitionRole && RoleValues[transitionRole],
    });
    const user = response.data;

    return user;
  } catch (err) {
    console.error(`Ocorreu algum problema ao atualizar este usuário 😕`, err);
    return err.response.data;
  }
}
