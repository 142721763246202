import { SetStateAction } from 'react';
import { User } from '../../hooks/types';

export enum Tabs {
  active = 'Ativas',
  inactive = 'Histórico de contas',
}
export interface ModalPropsUser {
  disableUnitProps: {
    name: string;
    id: string;
    email: string;
    unitId: string;
  };
  user: User;
  getUser: React.Dispatch<SetStateAction<User | undefined>>;
}
