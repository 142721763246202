import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { financeApi } from '../../api';

export type DataExtract = IExtract[];

export interface IExtract {
  _id: string;
  recipientId: string;
  name: string;
  originalValue: number;
  email: string;
  telephone: string;
  paymentMethod: string;
  paymentProviderId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  cpfCnpj?: string;
  creditCardInstallments?: number;
  finalValue: number;
  itemDescription: string;
  status?: string;
  urlInvoice: string;
  paydAt: string;
  productType: string;
  couponApplied?: CouponApplied;
}

export interface CouponApplied {
  _id: string;
  name: string;
  initialValidity: string;
  endValidity: string;
  discountType: string;
  value: number;
  disable: boolean;
  products: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export function useGetExtractList(
  type: string | undefined,
): UseQueryResult<DataExtract, DataExtract> {
  return useQuery<DataExtract, DataExtract>(
    ['extract'],
    () =>
      financeApi.get<DataExtract>(`payments/all/${type}`).then(async (r) => {
        return r.data;
      }),
    {},
  );
}
