import { useState } from 'react';
import { FiArrowDown } from 'react-icons/fi';
import * as S from './styles';

interface IAccordionDS {
  titleAccordion: string;
  contentAccordion: () => JSX.Element;
}

export const AccordionDS = ({
  titleAccordion,
  contentAccordion,
}: IAccordionDS) => {
  const [closeAccordion, setCloseAccordion] = useState<boolean>(false);

  return (
    <S.ContainerAccordion>
      <S.HeaderAccordion close={closeAccordion}>
        <S.TitleAccordion>{titleAccordion}</S.TitleAccordion>
        <FiArrowDown onClick={() => setCloseAccordion(!closeAccordion)} />
      </S.HeaderAccordion>
      <S.ContentAccordion close={closeAccordion}>
        {contentAccordion()}
      </S.ContentAccordion>
    </S.ContainerAccordion>
  );
};
