import { FiAlertTriangle, FiX } from 'react-icons/fi';
import { Modal } from '../../../LegacyV4Components';

import * as S from './styles';

interface ModalFieldsIncompleteProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

export function ModalFieldsIncomplete({
  isOpen,
  setIsOpen,
}: ModalFieldsIncompleteProps) {
  return (
    <Modal size="sm" show={isOpen} onBackground={() => setIsOpen(false)}>
      <S.ModalHeader>
        <h3> </h3>
        <FiX style={{ color: '#8383AF' }} onClick={() => setIsOpen(false)} />
      </S.ModalHeader>
      <S.ModalBody>
        <FiAlertTriangle color="#FABC2A" size={48} />
        <p>Espera ae!</p>
        <span>
          Antes de ir para a próxima etapa é necessário que você preencha os
          campos da etapa atual!
        </span>
      </S.ModalBody>
      <S.ModalFooter>{/* </div> */}</S.ModalFooter>
    </Modal>
  );
}
