import { Form as Unform } from '@unform/web';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ selected: boolean | undefined }>`
  position: relative;
  cursor: pointer;
  transition: 0.5s;
  border: solid 2px var(--tertiary-dark);
  border-bottom: 0;

  &:last-child {
    border: solid 2px var(--tertiary-dark);
  }

  padding: 2rem 2rem;
  display: flex;
  width: 100%;

  :hover {
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
  }

  h3 {
    font-size: 1.25rem;
  }

  header {
    display: flex;
    align-items: center;
  }

  header > svg {
    height: 1.875rem;
    width: 1.875rem;
    margin-right: 1rem;
  }

  section {
    margin-left: 2rem;
  }

  section div {
    display: flex;
    align-items: center;
  }

  section strong {
    color: var(--secondary-light);
    text-transform: uppercase;
    font-family: 'Nunito Sans', sans-serif;
  }

  section p {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1rem;
  }

  section div svg {
    margin-right: 0.5rem;
  }

  > div p {
    padding: 1rem;
    color: var(--black-text);
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1rem;

    :hover {
      opacity: 0.5;
    }
  }

  > div:last-child {
    margin: 1rem;
    position: absolute;
    top: 0;
    right: 0;
  }

  ${({ selected }) =>
    selected &&
    css`
      background: var(--tertiary-light);
    `}
`;

export const DeleteForm = styled.div`
  padding: 2rem 4rem;
  background: var(--white);
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    color: var(--primary-main);
    min-height: 2rem;
    min-width: 2rem;
  }

  h3 {
    text-align: center;
    margin: 0.5rem;
  }

  p {
    font-size: 1rem;
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
  }

  footer {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;

    button:first-child {
      margin-right: 1rem;
    }
  }
`;

export const Form = styled(Unform)`
  padding: 2rem;
  background: var(--white);
  border-radius: 0.5rem;

  h3 {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  > div {
    width: 20rem;
  }

  footer {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;

    button:first-child {
      margin-right: 1rem;
    }
  }
`;
