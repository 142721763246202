import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const Background = styled.div`
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  background: #fff;
  z-index: 1;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  padding: 2.5rem;

  aside {
    display: flex;
    flex-direction: column;
    margin-right: 5.5rem;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const Title = styled.p`
  font-family: 'Inter';
  font-size: 2.5rem;
  text-align: center;
  color: var(--primary-dark-1);
  font-weight: 600;
  margin-bottom: 1.5rem;
  line-height: 3rem;
`;

export const Description = styled.p`
  font-size: 1.25rem;
  text-align: center;
`;
