import React, { useState } from 'react';

import {
  FiShoppingCart,
  FiPercent,
  FiCreditCard,
  FiPlus,
  FiMinus,
  FiInfo,
} from 'react-icons/fi';
import { Box } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';
import { Button, Headline, Smalled } from '../../../LegacyV4Components';

import { Modal } from '../../../Default/Modal';
import { TextField } from '../../molecules/TextField';
import { FormikCurrencyInput } from '../../molecules/FormikCurrencyInput';
import { FormikPercentageInput } from '../../molecules/FormikPercentageInput';

import * as S from './styles';
import { ResumeCheckoutModal } from '../ResumeCheckoutModal';
import { Tooltip } from '../../../Default/Tooltip';
import { CustomerFinance } from '../../../../services/requests/Finance/getCustomer';

export function GenerateCheckoutModal({
  isShown,
  setIsShown,
  recipientId,
  data,
  bankResponse,
  valuesBank,
}: {
  recipientId?: string;
  isShown: boolean;
  setIsShown: (value: React.SetStateAction<boolean>) => void;
  data?: any;
  bankResponse?: { data: CustomerFinance } | undefined;
  valuesBank?: any;
}) {
  const [installments, setIsInstallments] = useState(1);
  const [creditChecked, setCreditCheck] = useState(false);
  const [boletoChecked, setBoletoCheck] = useState(false);
  const [pixChecked, setPixCheck] = useState(false);
  const [isOpenResume, setIsOpenResume] = useState(false);
  const [isMatriz, setIsMatriz] = useState<boolean>();
  const [isRoyalties] = useState<boolean>(true);
  const [isVariableValue, setIsVariableValue] = useState<boolean>();
  const [clientV4, setIsClientV4] = useState('customer');
  const [isJustHeadquarterCommission, setIsJustHeadquarterCommission] =
    useState(false);

  const GenerateSchema = Yup.object().shape({
    name: Yup.string().required('O campo é obrigatório!'),
    value: Yup.number()
      .min(1, 'O valor deve maior que R$ 1,00')
      .required('O campo é obrigatório!')
      // eslint-disable-next-line radix
      .transform((o, v) => parseInt(v.replace(/,/g, ''))),
    description: Yup.string().required('O campo é obrigatório!'),
    commissions: Yup.object({
      comissionType: Yup.string().required(),
      commissionValue: Yup.object({
        unitComission: Yup.number().when([], {
          is: () => isJustHeadquarterCommission,
          then: Yup.number(),
          otherwise: Yup.number()
            .min(1, 'O valor deve ser maior que 1')
            .required(),
        }),
        headquarterComission: Yup.number(),
      }),
    }).nullable(),
    payableWith: Yup.array()
      .of(Yup.string().required())
      .required('O campo é obrigatório!'),
    feeResponsible: Yup.string(),
    creditCardInstallments: Yup.number().required('O campo é obrigatório!'),
    physicalProduct: Yup.boolean(),
  });

  return (
    <>
      <Formik
        initialValues={{
          recipientId: `${recipientId || data._id}`,
          name: '',
          value: '',
          description: '',
          commissions: {
            comissionType: '',
            commissionValue: { unitComission: 0, headquarterComission: 0 },
          },
          payableWith: [''],
          feeResponsible: `${clientV4}`,
          creditCardInstallments: installments,
          physicalProduct: false,
        }}
        validationSchema={GenerateSchema}
        onSubmit={async () => {
          setIsOpenResume(true);
        }}
      >
        {({ errors, values, handleBlur, setFieldValue, isValid, dirty }) => {
          const verifyRateNotIsZero = !!(
            values?.commissions?.commissionValue?.unitComission !== 0 ||
            values?.commissions?.commissionValue?.headquarterComission !== 0
          );

          return (
            <>
              <>
                {isOpenResume ? (
                  <ResumeCheckoutModal
                    isShown={isOpenResume}
                    setIsOpenResume={setIsOpenResume}
                    setIsShown={setIsShown}
                    data={values}
                    bankResponse={bankResponse}
                    valuesBank={valuesBank}
                    isMatriz={isMatriz}
                    isRoyalties={isRoyalties}
                    isVariableValue={isVariableValue}
                  />
                ) : null}
              </>
              <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
                <S.ModalWrapper>
                  <S.ModalHeader>
                    <Headline variant="h4">Gerar checkout</Headline>
                    <button
                      type="button"
                      onClick={() => setIsShown(false)}
                      aria-label="default"
                    >
                      <FiX />
                    </button>
                  </S.ModalHeader>
                  <Form>
                    <S.ModalBody>
                      <S.SectionGenerate>
                        <Headline variant="h5">
                          <FiShoppingCart />
                          Cadastrar produto
                        </Headline>
                        <div
                          style={{ display: 'flex', marginTop: '1.875rem' }}
                          className="containerProduct"
                        >
                          <TextField
                            width="25rem"
                            name="name"
                            label="Nome do produto"
                            value={values.name}
                            placeholder="Digite o nome do produto"
                            helper={errors.name}
                            validateOnChange={false}
                            onBlur={handleBlur}
                          />

                          <FormikCurrencyInput
                            label="Valor"
                            onValueChange={(value) => {
                              setFieldValue('value', value);
                              setFieldValue('value', value);
                            }}
                          />
                        </div>
                        <div style={{ display: 'flex', marginBottom: '2rem' }}>
                          <TextField
                            width="40rem"
                            name="description"
                            label="Descrição"
                            value={values.description}
                            placeholder="Dê uma breve descrição do produto"
                            helper={errors.description}
                            onBlur={handleBlur}
                          />
                        </div>
                        <button type="button" style={{ display: 'none' }}>
                          Enviar
                        </button>
                      </S.SectionGenerate>
                      <S.SectionGenerate>
                        <div style={{ paddingBottom: '2rem' }}>
                          <Headline variant="h5">
                            <FiPercent />
                            Porcentagem de comissão
                          </Headline>
                          <Box sx={{ marginTop: '1.5rem' }}>
                            <span>
                              Quem irá receber a porcentagem de comissão?
                            </span>
                          </Box>
                          <S.ContainerHeadquartesType>
                            <S.MatrizRadio
                              color={isMatriz ? '1px solid #00C49A' : ''}
                              onClick={() => {
                                setIsMatriz(true);
                                setFieldValue(
                                  'commissions.commissionValue.unitComission',
                                  0,
                                );
                                setFieldValue(
                                  'commissions.commissionValue.headquarterComission',
                                  0,
                                );
                              }}
                            >
                              <input
                                name="matriz"
                                checked={isMatriz}
                                type="radio"
                                onChange={() => {
                                  setIsMatriz(true);
                                  setFieldValue(
                                    'commissions.commissionValue',
                                    null,
                                  );
                                  setFieldValue(
                                    'commissions.commissionValue.unitComission',
                                    0,
                                  );
                                  setFieldValue(
                                    'commissions.commissionValue.headquarterComission',
                                    0,
                                  );
                                }}
                              />
                              <p>Apenas matriz</p>
                            </S.MatrizRadio>
                            <S.MatrizRadio
                              color={
                                isMatriz === false ? '1px solid #00C49A' : ''
                              }
                              onClick={() => {
                                setIsMatriz(false);
                                setFieldValue(
                                  'commissions.commissionValue.unitComission',
                                  0,
                                );
                                setFieldValue(
                                  'commissions.commissionValue.headquarterComission',
                                  0,
                                );
                              }}
                            >
                              <input
                                name="matriz"
                                type="radio"
                                checked={isMatriz === false}
                                onChange={() => {
                                  setIsMatriz(false);
                                  setFieldValue(
                                    'commissions.commissionValue.unitComission',
                                    0,
                                  );
                                  setFieldValue(
                                    'commissions.commissionValue.headquarterComission',
                                    0,
                                  );
                                }}
                              />
                              <p>Matriz e franquia</p>
                            </S.MatrizRadio>
                          </S.ContainerHeadquartesType>
                          {isMatriz && (
                            <>
                              <S.ContainerValueVariable>
                                <span>
                                  Qual será a forma de comissionamento do valor
                                  para a matriz?
                                </span>
                                <Box
                                  sx={{ display: 'flex', marginTop: '1rem' }}
                                >
                                  <S.ContainerRadio
                                    color={
                                      isVariableValue ? '1px solid #00C49A' : ''
                                    }
                                    onClick={() => {
                                      setIsVariableValue(true);
                                      setFieldValue(
                                        'commissions.comissionType',
                                        'percentage',
                                      );
                                      setIsJustHeadquarterCommission(true);
                                      setFieldValue(
                                        'commissions.commissionValue.unitComission',
                                        0,
                                      );
                                      setFieldValue(
                                        'commissions.commissionValue.headquarterComission',
                                        0,
                                      );
                                    }}
                                  >
                                    <input
                                      name="variable"
                                      type="radio"
                                      checked={isVariableValue === true}
                                      onChange={() => {
                                        setIsVariableValue(true);
                                        setFieldValue(
                                          'commissions.comissionType',
                                          'percentage',
                                        );
                                        setFieldValue(
                                          'commissions.commissionValue.unitComission',
                                          0,
                                        );
                                        setFieldValue(
                                          'commissions.commissionValue.headquarterComission',
                                          0,
                                        );
                                        setIsJustHeadquarterCommission(true);
                                      }}
                                    />
                                    <p>Valor variável</p>
                                  </S.ContainerRadio>
                                  <S.ContainerRadio
                                    color={
                                      isVariableValue === false
                                        ? '1px solid #00C49A'
                                        : ''
                                    }
                                    onClick={() => {
                                      setIsVariableValue(false);
                                      setFieldValue(
                                        'commissions.comissionType',
                                        'value',
                                      );
                                      setFieldValue(
                                        'commissions.commissionValue.headquarterComission',
                                        0,
                                      );
                                      setFieldValue(
                                        'commissions.commissionValue.unitComission',
                                        0,
                                      );
                                      setIsJustHeadquarterCommission(true);
                                    }}
                                  >
                                    <input
                                      name="variable"
                                      type="radio"
                                      checked={isVariableValue === false}
                                      onChange={() => {
                                        setIsVariableValue(false);
                                        setFieldValue(
                                          'commissions.comissionType',
                                          'value',
                                        );
                                        setFieldValue(
                                          'commissions.commissionValue.headquarterComission',
                                          0,
                                        );
                                        setFieldValue(
                                          'commissions.commissionValue.unitComission',
                                          0,
                                        );
                                        setIsJustHeadquarterCommission(true);
                                      }}
                                    />
                                    <p>Valor fixo</p>
                                  </S.ContainerRadio>
                                </Box>
                              </S.ContainerValueVariable>
                              {isVariableValue ? (
                                <Box sx={{ marginLeft: '1rem' }}>
                                  <S.ContainerPercentageVariable>
                                    <span>
                                      Informe a porcentagem de comissão que será
                                      transferido para a matriz
                                      <Tooltip
                                        info
                                        title="Essa porcentagem deve ser referente ao montante que será transferido para a matriz sobre a venda."
                                        className="TooltipCheckout"
                                      >
                                        <FiInfo />
                                      </Tooltip>
                                    </span>
                                    <FormikPercentageInput
                                      number
                                      maxLength={2}
                                      width="9.375rem"
                                      name="commissions.commissionValue.headquarterComission"
                                      label=""
                                      value={
                                        values.commissions.commissionValue
                                          .headquarterComission
                                      }
                                      placeholder="0%"
                                      helper={
                                        errors.commissions?.commissionValue
                                          ?.headquarterComission
                                      }
                                      validateOnChange={false}
                                      onBlur={handleBlur}
                                      status={
                                        values.commissions.commissionValue
                                          .headquarterComission > 40 &&
                                        values.commissions.commissionValue
                                          .headquarterComission < 70
                                          ? 'warning'
                                          : 'default'
                                      }
                                      onValueChange={(value) => {
                                        setFieldValue(
                                          'commissions.commissionValue.headquarterComission',
                                          value,
                                        );
                                      }}
                                      warningMessage="O valor informado é relativamente alto. Verifique se esse é o valor realmente acordado."
                                    />
                                  </S.ContainerPercentageVariable>
                                </Box>
                              ) : (
                                isVariableValue === false && (
                                  <Box sx={{ marginLeft: '1rem' }}>
                                    <S.ContainerPercentageVariable>
                                      <span>
                                        Informe o valor de comissão que será
                                        transferido para a matriz
                                        <Tooltip
                                          info
                                          title="Esse valor deve ser referente ao montante que será transferido para a matriz sobre o a venda."
                                          className="TooltipCheckout"
                                        >
                                          <FiInfo />
                                        </Tooltip>
                                      </span>
                                      <FormikCurrencyInput
                                        width="8rem"
                                        onValueChange={(value) => {
                                          setFieldValue(
                                            'commissions.commissionValue.headquarterComission',
                                            value,
                                          );
                                        }}
                                      />
                                    </S.ContainerPercentageVariable>
                                  </Box>
                                )
                              )}
                            </>
                          )}
                          {isMatriz === false && (
                            <>
                              <S.ContainerValueVariable>
                                <span>
                                  Qual será a forma de comissionamento do valor?
                                </span>
                                <Box
                                  sx={{ display: 'flex', marginTop: '1rem' }}
                                >
                                  <S.ContainerRadio
                                    color={
                                      isVariableValue ? '1px solid #00C49A' : ''
                                    }
                                    onClick={() => {
                                      setFieldValue(
                                        'commissions.comissionType',
                                        'percentage',
                                      );
                                      setIsVariableValue(true);
                                      setFieldValue(
                                        'commissions.commissionValue.unitComission',
                                        0,
                                      );
                                      setFieldValue(
                                        'commissions.commissionValue.headquarterComission',
                                        0,
                                      );
                                    }}
                                  >
                                    <input
                                      name="variable"
                                      type="radio"
                                      value="percentage"
                                      checked={isVariableValue === true}
                                      onChange={() => {
                                        setFieldValue(
                                          'commissions.comissionType',
                                          'percentage',
                                        );
                                        setIsVariableValue(true);
                                        setFieldValue(
                                          'commissions.commissionValue.unitComission',
                                          0,
                                        );
                                        setFieldValue(
                                          'commissions.commissionValue.headquarterComission',
                                          0,
                                        );
                                      }}
                                    />
                                    <p>Valor variável</p>
                                  </S.ContainerRadio>
                                  <S.ContainerRadio
                                    color={
                                      isVariableValue === false
                                        ? '1px solid #00C49A'
                                        : ''
                                    }
                                    onClick={() => {
                                      setIsVariableValue(false);
                                      setFieldValue(
                                        'commissions.comissionType',
                                        'value',
                                      );
                                      setFieldValue(
                                        'commissions.commissionValue.headquarterComission',
                                        0,
                                      );
                                      setFieldValue(
                                        'commissions.commissionValue.unitComission',
                                        0,
                                      );
                                    }}
                                  >
                                    <input
                                      name="variable"
                                      type="radio"
                                      checked={isVariableValue === false}
                                      onChange={() => {
                                        setIsVariableValue(false);
                                        setFieldValue(
                                          'commissions.comissionType',
                                          'value',
                                        );
                                        setFieldValue(
                                          'commissions.commissionValue.headquarterComission',
                                          0,
                                        );
                                        setFieldValue(
                                          'commissions.commissionValue.unitComission',
                                          0,
                                        );
                                      }}
                                    />
                                    <p>Valor fixo</p>
                                  </S.ContainerRadio>
                                </Box>
                              </S.ContainerValueVariable>
                              <>
                                {(isMatriz === false &&
                                  isVariableValue === true) ||
                                isVariableValue === false ? (
                                  <>
                                    {isRoyalties ? (
                                      <Box sx={{ marginLeft: '1rem' }}>
                                        {isRoyalties &&
                                        isVariableValue === true ? (
                                          <S.ContainerPercentageVariable>
                                            <span>
                                              Informe a porcentagem de comissão
                                              que sua unidade receberá sobre o
                                              valor do produto
                                              <Tooltip
                                                info
                                                title="Essa porcentagem deve ser referente ao montante que será transferido para a sua unidade sobre a venda, já subtraído o valor que será dividido para a matriz."
                                                className="TooltipCheckout"
                                              >
                                                <FiInfo />
                                              </Tooltip>
                                            </span>
                                            <FormikPercentageInput
                                              number
                                              maxLength={2}
                                              width="9.375rem"
                                              name="commissions.commissionValue.unitComission"
                                              label=""
                                              value={
                                                values.commissions
                                                  .commissionValue.unitComission
                                              }
                                              placeholder="0%"
                                              helper={
                                                errors.commissions
                                                  ?.commissionValue
                                                  ?.unitComission
                                              }
                                              validateOnChange={false}
                                              onBlur={handleBlur}
                                              status={
                                                values.commissions
                                                  .commissionValue
                                                  .unitComission > 70
                                                  ? 'warning'
                                                  : 'default'
                                              }
                                              onValueChange={(value) => {
                                                setFieldValue(
                                                  'commissions.commissionValue.unitComission',
                                                  value,
                                                );
                                              }}
                                              warningMessage="O valor informado é relativamente alto. Verifique se esse é o valor realmente acordado."
                                            />
                                          </S.ContainerPercentageVariable>
                                        ) : (
                                          <>
                                            {isVariableValue === false &&
                                              isRoyalties === true && (
                                                <S.ContainerPercentageVariable>
                                                  <span>
                                                    Informe o valor da comissão
                                                    que será transferida para a
                                                    sua unidade
                                                    <Tooltip
                                                      info
                                                      title="Esse valor deve ser referente ao montante que será transferido para a sua unidade sobre a venda, já subtraído o valor que será dividido para a matriz."
                                                      className="TooltipCheckout"
                                                    >
                                                      <FiInfo />
                                                    </Tooltip>
                                                  </span>
                                                  <FormikCurrencyInput
                                                    width="8rem"
                                                    onValueChange={(value) => {
                                                      setFieldValue(
                                                        'commissions.commissionValue.unitComission',
                                                        value,
                                                      );
                                                    }}
                                                  />
                                                </S.ContainerPercentageVariable>
                                              )}
                                          </>
                                        )}
                                      </Box>
                                    ) : (
                                      isRoyalties === false &&
                                      isVariableValue === false && (
                                        <Box sx={{ marginLeft: '1rem' }}>
                                          <S.ContainerPercentageVariable>
                                            <span>
                                              Informe o valor da comissão que
                                              será transferida para a sua
                                              unidade
                                              <Tooltip
                                                info
                                                title="Esse valor deve ser referente ao montante que será transferido para a sua unidade sobre a venda, já subtraído o valor que será dividido para a matriz."
                                                className="TooltipCheckout"
                                              >
                                                <FiInfo />
                                              </Tooltip>
                                            </span>
                                            <FormikCurrencyInput
                                              width="8rem"
                                              onValueChange={(value) => {
                                                setFieldValue(
                                                  'commissions.commissionValue.unitComission',
                                                  value,
                                                );
                                              }}
                                            />
                                          </S.ContainerPercentageVariable>
                                          <S.ContainerPercentageVariable>
                                            <span>
                                              Informe o valor de comissão que
                                              será transferida para a matriz
                                              <Tooltip
                                                info
                                                title="Esse valor deve ser referente ao montante que será transferido para a matriz sobre a venda, já subtraído o valor que será enviado para a sua unidade."
                                                className="TooltipCheckout"
                                              >
                                                <FiInfo />
                                              </Tooltip>
                                            </span>
                                            <FormikCurrencyInput
                                              width="8rem"
                                              onValueChange={(value) => {
                                                setFieldValue(
                                                  'commissions.commissionValue.headquarterComission',
                                                  value,
                                                );
                                              }}
                                            />
                                          </S.ContainerPercentageVariable>
                                        </Box>
                                      )
                                    )}
                                    {isRoyalties === false &&
                                      isVariableValue === true && (
                                        <Box sx={{ marginLeft: '1rem' }}>
                                          <S.ContainerPercentageVariable>
                                            <span>
                                              Informe a porcentagem da comissão
                                              que será transferida para a sua
                                              unidade
                                              <Tooltip
                                                info
                                                title="Essa porcentagem deve ser referente ao montante que será transferido para a sua unidade sobre a venda, já subtraído o valor que será dividido para a matriz."
                                                className="TooltipCheckout"
                                              >
                                                <FiInfo />
                                              </Tooltip>
                                            </span>
                                            <FormikPercentageInput
                                              number
                                              maxLength={2}
                                              width="9.375rem"
                                              name="commissions.commissionValue.unitComission"
                                              label=""
                                              value={
                                                values.commissions
                                                  .commissionValue.unitComission
                                              }
                                              placeholder="0%"
                                              helper={
                                                errors.commissions
                                                  ?.commissionValue
                                                  ?.unitComission
                                              }
                                              validateOnChange={false}
                                              onBlur={handleBlur}
                                              status={
                                                values.commissions
                                                  .commissionValue
                                                  .unitComission >= 40 &&
                                                values.commissions
                                                  .commissionValue
                                                  .unitComission <= 70
                                                  ? 'warning'
                                                  : 'default'
                                              }
                                              onValueChange={(value) => {
                                                setFieldValue(
                                                  'commissions.commissionValue.unitComission',
                                                  value,
                                                );
                                              }}
                                            />
                                          </S.ContainerPercentageVariable>
                                          <S.ContainerPercentageVariable>
                                            <span>
                                              Informe a porcentagem de comissão
                                              que será transferida para a matriz
                                              <Tooltip
                                                info
                                                title="Essa porcentagem deve ser referente ao montante que será transferido para a matriz sobre a venda, já subtraído o valor que será enviado para a sua unidade."
                                                className="TooltipCheckout"
                                              >
                                                <FiInfo />
                                              </Tooltip>
                                            </span>
                                            <FormikPercentageInput
                                              number
                                              maxLength={2}
                                              width="9.375rem"
                                              name="commissions.commissionValue.headquarterComission"
                                              label=""
                                              value={
                                                values.commissions
                                                  .commissionValue
                                                  .headquarterComission
                                              }
                                              placeholder="0%"
                                              helper={
                                                errors.commissions
                                                  ?.commissionValue
                                                  ?.headquarterComission
                                              }
                                              validateOnChange={false}
                                              onBlur={handleBlur}
                                              status={
                                                values.commissions
                                                  .commissionValue
                                                  .headquarterComission >= 40 &&
                                                values.commissions
                                                  .commissionValue
                                                  .headquarterComission <= 70
                                                  ? 'warning'
                                                  : 'default'
                                              }
                                              onValueChange={(value) => {
                                                setFieldValue(
                                                  'commissions.commissionValue.headquarterComission',
                                                  value,
                                                );
                                              }}
                                            />
                                          </S.ContainerPercentageVariable>
                                        </Box>
                                      )}
                                  </>
                                ) : (
                                  <>
                                    {!isVariableValue && isMatriz && (
                                      <Box sx={{ marginLeft: '1rem' }}>
                                        <S.ContainerPercentageVariable>
                                          <span>
                                            Informe o valor de comissão que será
                                            transferido para a matriz
                                            <Tooltip
                                              info
                                              title="Esse valor deve ser referente ao montante que será transferido para a matriz sobre o a venda."
                                              className="TooltipCheckout"
                                            >
                                              <FiInfo />
                                            </Tooltip>
                                          </span>
                                          <FormikCurrencyInput
                                            width="8rem"
                                            onValueChange={(value) => {
                                              setFieldValue(
                                                'commissions.commissionValue.headquarterComission',
                                                value,
                                              );
                                            }}
                                          />
                                        </S.ContainerPercentageVariable>
                                      </Box>
                                    )}
                                  </>
                                )}
                              </>
                            </>
                          )}
                        </div>
                      </S.SectionGenerate>
                      <S.SectionGenerate>
                        <Headline variant="h5">
                          <FiCreditCard />
                          Habilitar formas de pagamento
                        </Headline>
                        <S.ContainerProductType>
                          <S.ContainerCheckbox
                            color={pixChecked ? '1px solid #00C49A' : ''}
                          >
                            <input
                              type="checkbox"
                              name="payableWith"
                              value="pix"
                              onChange={(e) => {
                                const { checked } = e.target;
                                if (values.payableWith[0] === '') {
                                  values.payableWith.splice(0, 1);
                                }
                                const valueIncluded =
                                  values.payableWith.includes('pix');
                                if (valueIncluded && !checked) {
                                  setPixCheck(false);
                                  const position = values.payableWith.indexOf(
                                    e.target.value,
                                  );
                                  values.payableWith.splice(position, 1);
                                }
                                if (!valueIncluded && checked) {
                                  setPixCheck(true);
                                  setFieldValue('payableWith', [
                                    ...values.payableWith,
                                    e.target.value,
                                  ]);
                                }
                              }}
                            />
                            <Headline variant="h5">PIX</Headline>
                          </S.ContainerCheckbox>
                          <S.ContainerCheckbox
                            color={boletoChecked ? '1px solid #00C49A' : ''}
                          >
                            <input
                              type="checkbox"
                              name="payableWith"
                              value="bank_slip"
                              onChange={(e) => {
                                const { checked } = e.target;
                                if (values.payableWith[0] === '') {
                                  values.payableWith.splice(0, 1);
                                }
                                const valueIncluded =
                                  values.payableWith.includes('bank_slip');
                                if (valueIncluded && !checked) {
                                  setBoletoCheck(false);
                                  const position = values.payableWith.indexOf(
                                    e.target.value,
                                  );
                                  values.payableWith.splice(position, 1);
                                }
                                if (!valueIncluded && checked) {
                                  setBoletoCheck(true);
                                  setFieldValue('payableWith', [
                                    ...values.payableWith,
                                    e.target.value,
                                  ]);
                                }
                              }}
                            />
                            <Headline variant="h5">Boleto</Headline>
                          </S.ContainerCheckbox>
                          <S.ContainerCheckbox
                            color={creditChecked ? '1px solid #00C49A' : ''}
                          >
                            <input
                              type="checkbox"
                              name="payableWith"
                              value="credit_card"
                              onChange={(e) => {
                                const { checked } = e.target;
                                if (values.payableWith[0] === '') {
                                  values.payableWith.splice(0, 1);
                                }
                                const valueIncluded =
                                  values.payableWith.includes('credit_card');
                                if (valueIncluded && !checked) {
                                  setCreditCheck(false);
                                  const position = values.payableWith.indexOf(
                                    e.target.value,
                                  );
                                  values.payableWith.splice(position, 1);
                                }
                                if (!valueIncluded && checked) {
                                  setCreditCheck(true);
                                  setFieldValue('payableWith', [
                                    ...values.payableWith,
                                    e.target.value,
                                  ]);
                                }
                              }}
                            />
                            <div style={{ flexDirection: 'column' }}>
                              <Headline variant="h5">Cartão</Headline>
                              <div className="ContainerInstallments">
                                <p>n° de parcelas</p>
                                <div className="ContainerSelectNumber">
                                  <S.DecrescentNumber
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setIsInstallments(installments - 1);
                                      setFieldValue(
                                        'creditCardInstallments',
                                        installments - 1,
                                      );
                                    }}
                                    disabled={installments === 0}
                                  >
                                    <FiMinus />
                                  </S.DecrescentNumber>
                                  <input type="number" value={installments} />
                                  <S.CrescentNumber
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setIsInstallments(installments + 1);
                                      setFieldValue(
                                        'creditCardInstallments',
                                        installments + 1,
                                      );
                                    }}
                                    disabled={installments === 12}
                                  >
                                    <FiPlus />
                                  </S.CrescentNumber>
                                </div>
                              </div>
                            </div>
                          </S.ContainerCheckbox>
                        </S.ContainerProductType>
                      </S.SectionGenerate>
                      {installments === 13 ? (
                        <S.SectionGenerate>
                          <Headline variant="h5">
                            {/* <Fi /> */}
                            Juros
                          </Headline>
                          <Smalled>
                            Quem irá assumir os juros das parcelas do cartão de
                            credito?
                            <Tooltip
                              info
                              title="Valor de juros referente para quando o pagamento não ocorrer até a data de vencimento. O valor de juros é de 2% ao mês."
                              className="TooltipCheckout"
                            >
                              <FiInfo />
                            </Tooltip>
                          </Smalled>
                          <S.ContainerProductType>
                            <div
                              style={{
                                marginBottom: '1.5rem',
                                display: 'flex',
                              }}
                            >
                              <S.ContainerRadio
                                color={
                                  clientV4 === 'customer'
                                    ? '1px solid #00C49A'
                                    : ''
                                }
                              >
                                <input
                                  value="customer"
                                  type="radio"
                                  checked={clientV4 === 'customer'}
                                  onChange={(e) =>
                                    setIsClientV4(e.target.value)
                                  }
                                />
                                <Headline variant="h6">Cliente V4</Headline>
                              </S.ContainerRadio>
                              <S.ContainerRadio
                                color={
                                  clientV4 !== 'customer'
                                    ? '1px solid #00C49A'
                                    : ''
                                }
                              >
                                <input
                                  value="clientfinal"
                                  type="radio"
                                  checked={clientV4 !== 'customer'}
                                  onChange={(e) =>
                                    setIsClientV4(e.target.value)
                                  }
                                />
                                <Headline variant="h6">Cliente final</Headline>
                              </S.ContainerRadio>
                            </div>
                          </S.ContainerProductType>
                        </S.SectionGenerate>
                      ) : (
                        ''
                      )}
                    </S.ModalBody>
                  </Form>
                  <S.ModalFooter>
                    <Button
                      variant="primary"
                      color="green"
                      size="default"
                      onClick={async () => {
                        setIsOpenResume(true);
                        setIsShown(false);
                      }}
                      disabled={!(isValid && dirty && verifyRateNotIsZero)}
                    >
                      Prosseguir para tela de resumo
                    </Button>
                    <Button
                      variant="secondary"
                      color="red"
                      size="default"
                      onClick={() => setIsShown(false)}
                    >
                      Cancelar
                    </Button>
                  </S.ModalFooter>
                </S.ModalWrapper>
              </Modal>
            </>
          );
        }}
      </Formik>
    </>
  );
}
