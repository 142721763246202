import { useInView } from 'react-intersection-observer';
import { useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp, FiSearch } from 'react-icons/fi';

import { Paragraph } from '../../../LegacyV4Components';

import * as S from './styles';

import useDebounce from '../../../../hooks/useDebounce';
import { useOutsideAlerter } from '../../../../hooks/outsideClickAlert';
import { useInfiniteQueryOptions } from '../../../../services/requests/MediaHub/useInfiniteQueryOptions';
import { useQueryInitialValue } from '../../../../services/requests/MediaHub/useQueryInitialValue';

export interface SelectValue<T> {
  label: string;
  value: T;
}

export interface PagedResponse<T> {
  page: number;
  totalPages: number;
  data: SelectValue<T>[];
}

interface SelectQueryProps<T> {
  id: string;
  value?: SelectValue<T>;
  setValue: (value: SelectValue<T> | undefined) => void;
  fetchInitialValue?: () => Promise<SelectValue<T> | undefined>;
  fetchOptions: (query: string, page: number) => Promise<PagedResponse<T>>;
  placeholder: string;
}

export default function SelectQuery<T>({
  id,
  value,
  setValue,
  fetchInitialValue,
  fetchOptions,
  placeholder,
}: SelectQueryProps<T>) {
  const selectRef = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(!!fetchInitialValue);

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search.toLocaleLowerCase(), 500);

  const [displayValue, setDisplayValue] = useState('');

  const { data: dataInitialValue, status: statusInitialValue } =
    useQueryInitialValue(id, fetchInitialValue);
  const {
    data: optionsData,
    fetchNextPage: fetchNextOptionsPage,
    isLoading: optionsIsLoading,
    isError: optionsIsError,
  } = useInfiniteQueryOptions(id, debouncedSearch, fetchOptions);

  const options =
    optionsData?.pages?.map((page) => page?.data ?? [])?.flat() ?? [];

  useEffect(() => {
    if (dataInitialValue && !isLoaded && statusInitialValue === 'success') {
      setValue(dataInitialValue);
      setIsDisabled(false);
      setIsLoaded(true);
    }

    if (!dataInitialValue && statusInitialValue === 'error') {
      setIsDisabled(false);
      setIsLoaded(true);
    }
  }, [dataInitialValue, isLoaded, setValue, statusInitialValue]);

  useOutsideAlerter({ ref: selectRef, action: () => setIsOpen(false) });

  useEffect(() => {
    if (!isLoaded) {
      setDisplayValue('Aguarde...');
    }

    setDisplayValue(value?.label ?? placeholder);
  }, [isLoaded, value, placeholder]);

  useEffect(() => {
    if (inView) {
      fetchNextOptionsPage();
    }
  }, [inView, fetchNextOptionsPage]);

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  }

  function handlePlaceholderClick(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();

    setIsOpen(!isOpen);
  }

  function handleOptionClick(e: React.MouseEvent, option: SelectValue<T>) {
    e.stopPropagation();
    setValue(option.value === value?.value ? undefined : option);
  }

  return (
    <S.SelectWrapper
      id={id}
      className={`${isOpen ? 'open' : ''}`}
      title={displayValue}
      ref={selectRef}
    >
      <S.SelectPlaceholder
        className={`${value ? 'filled' : ''} ${isOpen ? 'open' : ''} ${
          isDisabled || !isLoaded ? 'disabled' : ''
        }`}
        onClick={handlePlaceholderClick}
      >
        <Paragraph>{displayValue}</Paragraph>
        <S.SelectArrows>
          <FiChevronDown size={24} className="select-arrow arrow-down" />
          <FiChevronUp size={24} className="select-arrow arrow-up" />
        </S.SelectArrows>
      </S.SelectPlaceholder>
      <S.SelectOptionsWrapper className={isOpen ? 'open' : ''}>
        <S.SelectSearchWrapper>
          <S.SelectSearch>
            <input
              placeholder="Buscar"
              type="text"
              value={search}
              onChange={handleSearchChange}
            />
            <FiSearch size={16} />
          </S.SelectSearch>
        </S.SelectSearchWrapper>
        <S.SelectOptionsBody>
          <S.SelectOptions>
            {options?.map((option) => (
              <S.SelectOption
                key={`${id}-${option.value}`}
                className={option.value === value?.value ? 'selected' : ''}
                title={option.label}
                onClick={(e: React.MouseEvent) => handleOptionClick(e, option)}
              >
                <>
                  <S.SelectOptionRadio className="radio" />
                  <Paragraph>{option.label}</Paragraph>
                </>
              </S.SelectOption>
            ))}
            {optionsIsLoading ? <p>Carregando...</p> : null}
            {optionsIsError ? <p>Erro ao buscar opções</p> : null}
            <div ref={ref} />
          </S.SelectOptions>
        </S.SelectOptionsBody>
      </S.SelectOptionsWrapper>
    </S.SelectWrapper>
  );
}
