import { MdClose } from 'react-icons/md';
import React from 'react';

import * as S from './styles';

interface ChipProps {
  item: string;
  remove: () => void;
}

export function Chip({ item, remove }: ChipProps) {
  return (
    <S.Container>
      <span>{item}</span>
      <S.IconBtn
        onClick={() => {
          remove();
        }}
      >
        <MdClose />
      </S.IconBtn>
    </S.Container>
  );
}
