import React from 'react';

import { MdClose } from 'react-icons/md';
import { Button, Headline, Modal } from '../../../LegacyV4Components';
import * as S from './styles';

export function ModalRemoveOnboarding({
  isShown,
  setIsShown,
  removeFromOnboarding,
}: {
  isShown: boolean;
  setIsShown: (value: React.SetStateAction<boolean>) => void;
  removeFromOnboarding: () => void;
}) {
  function closeModal() {
    setIsShown(false);
    return;
  }

  return (
    <Modal show={isShown} onBackground={closeModal}>
      <S.ModalBody>
        <MdClose size={16} className="close-button" onClick={closeModal} />
        <Headline variant="h5" className="Headline">
          Você tem certeza que deseja continuar?
        </Headline>
        <div>
          O onboarding é um processo essencial para a integração do investidor
          junto ao time,
          <br /> a cultura e aos processos da V4 Company.
        </div>
        <S.ModalFooter>
          <Button
            variant="secondary"
            color="red"
            size="default"
            onClick={closeModal}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            color="green"
            size="default"
            onClick={() => {
              removeFromOnboarding();
              closeModal();
            }}
          >
            Remover do onboarding
          </Button>
        </S.ModalFooter>
      </S.ModalBody>
    </Modal>
  );
}
