import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { InvestorTeam } from '../../../hooks/types';
import { usersApi as api } from '../../api';
import { Project } from '../../../types/projects';

export function useGetInvestorsByProjectId(
  customerId: string,
): UseQueryResult<InvestorTeam[], { data: InvestorTeam }> {
  return useQuery<InvestorTeam[], { data: InvestorTeam }>(
    ['Customer', customerId],
    () =>
      api
        .get<InvestorTeam[]>(`/capacity/manage/find-all/users/${customerId}`)
        .then((res) => res.data),
    {
      retry: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
    },
  );
}

export function useGetClient(
  customerId: string,
): UseQueryResult<Project, { data: Project }> {
  return useQuery<Project, { data: Project }>(
    ['Project', customerId],
    () => api.get<Project>(`/customer/${customerId}`).then((res) => res.data),
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
    },
  );
}
