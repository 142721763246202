import { useState } from 'react';
import { LabDsButton, LabDsIconNotSelectable } from 'v4web-components-react';

import { useToast } from '../../../../hooks/toast';

import * as S from './styles';

import { oportunityInterface } from '../../../../services/requests/Calculator/types';
import { deleteOportunity } from '../../../../services/requests/Calculator/oportunities/deleteOportunity';

interface createLeadModalInterface {
  closeModal: (state: boolean) => void;
  currentOportunity: oportunityInterface;
}

export function CancelOportunityModal({
  closeModal,
  currentOportunity,
}: createLeadModalInterface) {
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  async function removeOportunity() {
    setIsLoading(true);
    const data = currentOportunity;
    data.status = 'project_canceled';
    const response = await deleteOportunity(currentOportunity.id, data);
    setIsLoading(false);

    if (!response) {
      addToast({
        type: 'error',
        title: 'Erro ao cancelar oportunidade',
        description: 'Tente mais tarde.',
      });
      return;
    }
    addToast({
      type: 'success',
      title: 'Oportunidade cancelada com sucesso',
    });
    closeModal(false);
  }

  return (
    <S.Modal>
      <S.Content>
        <LabDsIconNotSelectable
          icon="close"
          onClick={() => {
            closeModal(false);
          }}
        />
        <h5>Excluir projeto</h5>
        <p>
          Tem certeza que deseja excluir o projeto{' '}
          {currentOportunity.projectName}?
        </p>
        <S.Actions>
          <LabDsButton
            label="Cancelar"
            variant="danger-outlined"
            onHandleButton={() => {
              closeModal(false);
            }}
          />
          <LabDsButton
            label="Cancelar oportunidade"
            onHandleButton={removeOportunity}
            loading={isLoading}
          />
        </S.Actions>
      </S.Content>
    </S.Modal>
  );
}
