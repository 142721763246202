import { useEffect } from 'react';

import { FiAlertCircle, FiCheckCircle, FiInfo } from 'react-icons/fi';
import { MdOutlineWarningAmber } from 'react-icons/md';

import { ToastMessage, useToast } from '../../../../hooks/toast';

import * as S from './styles';

interface ToastProps {
  message: ToastMessage;
  style: Record<string, unknown>;
}

const icons = {
  info: <FiInfo size={24} />,
  warning: <MdOutlineWarningAmber size={24} />,
  error: <FiAlertCircle size={24} />,
  success: <FiCheckCircle size={24} />,
};

export function Toast({ message, style }: ToastProps) {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [message.id, removeToast]);

  return (
    <S.Container
      style={style}
      type={message.type}
      hasdescription={Number(!!message.description)}
    >
      {icons[message.type || 'info']}

      <div>
        <h4>{message.title}</h4>
        {message.description && <p>{message.description}</p>}
      </div>
    </S.Container>
  );
}
