import styled from 'styled-components';

export const Body = styled.tbody`
  display: flex;
  flex-direction: column;
  padding: 1.313rem 2.125rem;
  gap: 0.5rem;
  width: 100%;
  lab-ds-skeleton {
    margin-bottom: 1rem;
  }
`;

export const Content = styled.div`
  max-width: 42.5rem;
  width: 100%;
  margin: 1.5rem auto;
`;

export const Info = styled.div`
  display: flex;
  margin-bottom: 2.5rem;
  svg {
    margin-right: 0.5rem;
  }
`;

export const Text = styled.div`
  p:first-of-type {
    margin-bottom: 0.5rem;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 1rem;
  svg {
    margin-right: 0.5rem;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;

  li {
    margin-bottom: 0.7rem;
  }
  span {
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
  }
`;
