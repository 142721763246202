import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
import { Button, Headline } from '../../../LegacyV4Components';

import { Modal } from '../../../Default/Modal';

import * as S from './styles';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';
import { EditPlanModal } from '../EditPlanModal';

export function DetailsPlanModal({
  isShown,
  setIsShown,
  data,
}: {
  isShown: boolean;
  setIsShown(value: boolean): any;
  data: any;
}) {
  const [showEditPlanModal, setShowEditPlanModal] = useState(false);
  data;

  return (
    <>
      <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
        <>
          <S.ModalWrapper>
            <S.ModalHeader>
              <Headline variant="h4">Detalhes do Plano</Headline>
              <button
                type="button"
                onClick={() => setIsShown(false)}
                aria-label="default"
              >
                <FiX />
              </button>
            </S.ModalHeader>
            <S.ModalBody>
              <S.Row>
                <S.Title>
                  <Headline variant="h5">Setup</Headline>
                  <p>{convertNumberToBRL(5000)} - Cartão de crédito</p>
                </S.Title>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <S.Status color="#FABC2A">Assinatura pendente</S.Status>
                </div>
              </S.Row>

              <S.Row>
                <S.Title>
                  <Headline className="title" variant="h6">
                    DURAÇÃO
                  </Headline>
                  <span>Ilimitada</span>
                </S.Title>
                <S.Title>
                  <Headline className="title" variant="h6">
                    DATA DA COBRANÇA
                  </Headline>
                  <span>Dia 10</span>
                </S.Title>
                <S.Title style={{ textAlign: 'right' }}>
                  <Headline className="title" variant="h6">
                    DATA DE CRIAÇÃO
                  </Headline>
                  <span>06/03/2023</span>
                </S.Title>
              </S.Row>
              <S.Row style={{ marginBottom: 0 }}>
                <S.Title>
                  <Headline variant="h6">Informações da assinatura</Headline>
                </S.Title>
              </S.Row>
              <S.Row>
                <S.Title>
                  <Headline variant="h6">Cliente XPTO</Headline>
                  <p>{convertNumberToBRL(5000)}</p>
                </S.Title>
                <S.Title style={{ textAlign: 'right' }}>
                  <Headline variant="h6">Pagamentos recebidos</Headline>
                  <p>1</p>
                </S.Title>
              </S.Row>
              <S.Row>
                <S.Title>
                  <Headline className="title" variant="h6">
                    DATA DA ADESÃO
                  </Headline>
                  <span>06/03/2023</span>
                </S.Title>
                <S.Title style={{ textAlign: 'right' }}>
                  <Headline className="title" variant="h6">
                    DURAÇÃO DA ASSINATURA
                  </Headline>
                  <span>Ilimitada</span>
                </S.Title>
              </S.Row>
            </S.ModalBody>
            <S.ModalFooter>
              <>
                <Button
                  variant="primary"
                  color="green"
                  size="default"
                  onClick={async () => {
                    setShowEditPlanModal(true);
                  }}
                >
                  Editar meio de pagamento
                </Button>
              </>
            </S.ModalFooter>
          </S.ModalWrapper>
        </>
      </Modal>
      <EditPlanModal
        isShown={showEditPlanModal}
        setIsShown={setShowEditPlanModal}
        data={{}}
      />
    </>
  );
}
