import { LabDsTable } from 'v4web-components-react';
import { IData } from 'v4web-components';

import { useMemo } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { Headline } from '../../../LegacyV4Components';
import * as S from './styles';
import { InvestorTeam, User } from '../../../../hooks/types';

export default function CustomerTeamPreview({
  team,
  customerId,
}: {
  team: InvestorTeam[];
  customerId: string;
}) {
  const navigate = useNavigate();

  const headers: IData['headers'] = [
    {
      title: 'Nome',
      colKey: 'name',
      sortable: true,
      type: 'text',
    },
    {
      title: 'Função',
      colKey: 'role',
      type: 'text',
    },
  ];

  const rows: IData['rows'] = useMemo(() => {
    const rowsData: IData['rows'] = [];
    if (team) {
      team?.forEach((investor: { user: User }) => {
        rowsData.push({
          name: {
            text: {
              title: investor?.user.name,
              avatar: true,
            },
          },
          role: {
            text: {
              title: investor.user.mandate?.name,
            },
          },
        });
      });
    }
    return rowsData;
  }, [team]);

  return (
    <S.Container>
      <S.HeaderPreview>
        <Headline variant="h5">Time responsável</Headline>
        <S.EditStakeholder
          onClick={() => navigate(`/cliente/${customerId}/team`)}
        >
          Editar <BiEditAlt />
        </S.EditStakeholder>
      </S.HeaderPreview>
      {team?.length ? (
        <div>
          <LabDsTable data={{ headers, rows }} />
        </div>
      ) : (
        <S.Body>
          <p className="no-content">Você ainda não vinculou investidores</p>
        </S.Body>
      )}
    </S.Container>
  );
}
