import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  border-radius: 0.3rem;
  border-top-left-radius: 0;
  width: 100%;
  @media (max-width: 880px) {
    width: auto;
  }

  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

export const AddressTitle = styled.p`
  display: flex;
  .icon {
    margin-right: 0.7rem;
    padding-top: 0.1rem;
  }
  font-size: 1.4rem;
  margin: 1rem 0;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
`;

export const Observation = styled.p`
  font-size: 1rem;
  font-family: 'Inter';
  font-weight: bold;
  line-height: 1.5rem;
  margin-bottom: 8px;
`;
