import styled from 'styled-components';

export const Cards = styled.main`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;

  max-width: 19.5rem;
  padding: 2rem;

  border: 1px solid var(--grayscale-10);
  border-radius: 0.5rem;
  background-color: var(--white);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 1px 3px 1px rgba(0, 0, 0, 0.06);
`;

export const ImageIcon = styled.img`
  display: block;
  width: 8.5rem;
  height: 4rem;
`;

export const ConnectionStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-size: 0.75rem;
  font-weight: 700;
  color: var(--grayscale-20);

  &::before {
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;

    background-color: var(--grayscale-20);
  }

  &.status-connected {
    color: var(--success-2);

    &::before {
      background-color: var(--success-2);
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  text-align: center;

  &.status-pending {
    p {
      color: var(--error);
    }
  }

  & button {
    font-size: 0.875rem;
  }
`;
