import * as yup from 'yup';

export const deleteInvestorSchema = yup.object().shape({
  wouldRecommend: yup.boolean().required('Campo Obrigatório!'),
  investorChoice: yup.boolean().required('Campo Obrigatório!'),

  user: new yup.ObjectSchema({
    name: yup.string().required('Campo Obrigatório!'),
  }),
  investorLastDay: yup.string().required('Campo Obrigatório!'),

  motivationOfFired: yup.array().required('Campo Obrigatório!'),
});
