import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem 1.5rem;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;

  display: flex;
  margin-bottom: 2rem;
  flex-direction: column;
  background-color: var(--white);
`;

export const Title = styled.p`
  margin-left: 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: 'Nunito Sans', sans-serif;
  line-height: 1.5rem;
`;

export const ListWrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  padding: 1rem 0;
`;

export const HeadlineContainer = styled.div`
  margin-bottom: 1rem;
`;

export const Item = styled.div`
  gap: 1rem;
  display: flex;
  margin-bottom: 1.5rem;
`;

export const MainMessage = styled.p`
  font-size: 22px;
`;

export const SecondaryMessage = styled.p`
  margin-left: 1rem;
  max-width: 40vw;
`;

export const MessagesWrapper = styled.div``;

export const Divider = styled.hr`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2rem;

  @media (max-width: 880px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const GeneralValidationMessage = styled.p`
  margin-top: 1rem;
  margin-left: 2rem;
  line-height: 1.5rem;
`;
