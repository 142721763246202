import { useEffect, useState } from 'react';
import { MdInfoOutline, MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Headline, Paragraph } from '../../../LegacyV4Components';
import { useAuth } from '../../../../hooks/auth';

import * as S from './styles';

export function RoiNotification() {
  const [showNotification, setShowNotification] = useState(false);
  const { user } = useAuth();

  const isHeadquarterUser =
    user.unitId === '627c19e328bbf0be319d6bba' ||
    user.unitId === '627554adffa66d39ea56fe60';

  const navigate = useNavigate();

  const handleNotificationClick = () => {
    setShowNotification(false);
    navigate('/space/roi-week');
  };
  const handleCloseClick = () => {
    setShowNotification(false);
  };
  useEffect(() => {
    const today = new Date().getDate();

    if (
      today === 4 &&
      localStorage.getItem('shown-roi-week') !== 'true' &&
      !isHeadquarterUser
    ) {
      setShowNotification(true);
      localStorage.setItem('shown-roi-week', 'true');
    } else if (today !== 4) {
      localStorage.setItem('shown-roi-week', 'false');
    }
  }, [isHeadquarterUser]);

  return (
    <S.Wrapper className={showNotification ? 'show' : ''}>
      <S.CloseButton onClick={handleCloseClick}>
        <MdClose size={16} />
      </S.CloseButton>
      <S.Notification>
        <S.Header>
          <MdInfoOutline size={24} />
          <Headline variant="h6">ROI Week em andamento</Headline>
        </S.Header>
        <S.Body>
          Passando para te lembrar que estamos na semana de registro do ROI dos
          seus clientes, o preenchimentos desses dados são fundamentais!
        </S.Body>
        <S.Footer onClick={handleNotificationClick}>
          <Paragraph variants={['bold']}>IR PARA O ROI WEEK</Paragraph>
        </S.Footer>
      </S.Notification>
    </S.Wrapper>
  );
}
