import styled, { css } from 'styled-components';

export const Container = styled.section`
  display: flex;
  padding: 2.5rem 5rem;
  flex-direction: column;
  gap: 1.5rem;
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
  }
`;

export const Title = styled.div`
  font-family: Inter;
  font-size: 38px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
`;

export const InputContainer = styled.section`
  display: flex;
  flex-direction: column;
  color: var(--LabDS-semantic-color-fg-default, #333);
  width: 100%;
  background: var(--LabDS-semantic-color-bg-second-level, #fff);
  display: flex;
  padding: 2.5rem 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  border-radius: var(--LabDS-core-dimension-dimension-50, 0.5rem);
  background: var(--LabDS-semantic-color-bg-second-level, #fff);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.12);
`;

export const InputTitle = styled.h4`
  color: var(--LabDS-semantic-color-fg-default, #333);
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5rem;
`;

export const InputRow = styled.div`
  display: flex;
  gap: 2.5rem;
  justify-content: space-between;
  align-items: start;
  width: 100%;

  lab-ds-text-field {
    width: 100%;
  }

  lab-ds-select {
    width: 100%;
  }

  lab-ds-date-picker {
    width: 17rem;
    max-width: 17rem;
    gap: 1.5rem;
  }

  lab-ds-button {
    margin-top: 1.2rem;
  }

  span {
    margin-top: 1.2rem;
    display: flex;
    color: red;
    justify-content: center;
    align-items: center;
    color: var(--LabDS-semantic-color-fg-default, #333);
    font-family: Nunito Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
  }

  .input-contract {
    display: flex;
    justify-content: start;
    width: 100%;
    gap: 1rem;

    lab-ds-date-picker {
      width: 40%;
    }

    span {
      margin-bottom: 2rem;
    }

    lab-ds-button {
      margin-bottom: 2rem;
    }
  }
`;

export const InputSubTitle = styled.h5`
  color: var(--LabDS-semantic-color-fg-default, #333);
  font-family: Inter;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  width: 80%;
`;

export const InputLabDsTextField = styled.div`
  max-width: 17rem;
  width: 100%;
`;

export const PartnerRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Accordion = styled.div`
  width: 100%;
  gap: 1rem;
  align-items: start;
  justify-content: start;
`;

export const MajorPartnerRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.25rem;
`;

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.25rem;
  padding: 1.25rem;

  border: 1px solid #b4b4cf;
  border-radius: 0.4rem;
`;

export const AccordionHeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
  margin: -0.5rem;
  padding: 0.5rem;
  color: var(--LabDS-semantic-color-fg-default, #333);
  font-family: Inter;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  &:hover {
    background-color: var(--lab-ds-semantic-selectable-color-bg-default);
  }
`;

export const AccordionContent = styled.div<{ isVisible: boolean }>`
  display: none;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-height: 0;
  opacity: 0;
  cursor: pointer;

  animation: max-height 1s ease;

  ${(props) =>
    props.isVisible &&
    css`
      display: flex;
      max-height: 100%;
      opacity: 1;
    `}
`;

export const Evidence = styled.div`
  display: flex;
  padding: 1.25rem;

  align-items: center;
  gap: 1.5rem;
  border-radius: 25px;
  background: var(--lab-ds-semantic-selectable-color-bg-default);

  p {
    font: var(--lab-ds-semantic-typography-body-none-decoration-p3-bold);
  }

  lab-ds-icon-not-selectable {
    margin-right: 0.25rem;
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  width: 100%;

  h1 {
    margin-right: 0.5rem;
  }

  .material-symbols-outlined {
    margin-top: 0.2rem;
  }
`;

export const HiddenRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
  margin-top: 0.35rem;
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: right;
  gap: 0.4rem;
  margin-top: 0.35rem;
  width: 100%;

  div {
    display: flex;
    gap: 1rem;
  }
`;
