import { Mandate } from '../../../../hooks/types';
import { usersApi } from '../../../api';

export async function getAllMandates(): Promise<Mandate[]> {
  try {
    const response = await usersApi.get(`/mandates/find-all`);
    const mandates = response.data as Mandate[];

    return mandates;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar esta cobrança, atualize a página e tente novamente 😕`,
      err,
    );
    return err.response.data;
  }
}
