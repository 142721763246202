import { customerApi } from '../../api';

export interface CreateCostumerDTO {
  _id?: string;
  name: string;
  cnpj: string;
  unitId: [string];
  address: {
    country: string;
    state: string;
    city: string;
    street: string;
    number: string;
    complement: string;
    zipCode: string;
  };
}

export async function createCustomer(
  customer: CreateCostumerDTO,
): Promise<CreateCostumerDTO | boolean | any> {
  try {
    const newCostumer = {
      ...customer,
      units: [{ _id: customer.unitId }],
    };

    const response: any = await customerApi.post(`/customer/save`, newCostumer);
    const createdCustomer = response.data;

    return createdCustomer;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o dashboard, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
