import { financeApi } from '../../api';

export interface PaymentById {
  _id: string;
  recipientId: string;
  name: string;
  email: string;
  telephone: string;
  paymentMethod: string;
  paymentProviderId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  pix: Pix;
  bank_slip: BankSlip;
  secure_url: string;
  due_date: string;
  status: string;
}

export interface Pix {
  qrcode: string;
  qrcode_text: string;
  status: string;
  payer_cpf_cnpj: any;
  payer_name: any;
  end_to_end_id: any;
  end_to_end_refund_id: any;
}

export interface BankSlip {
  digitable_line: string;
  barcode_data: string;
  barcode: string;
  bank_slip_bank: number;
  bank_slip_status: string;
  bank_slip_error_code: any;
  bank_slip_error_message: any;
}

export async function getPaymentById(productId: string): Promise<any> {
  try {
    const response = await financeApi.get(`/payments/${productId}`);
    const pay: any = response.data;

    return pay;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar o pagamento, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
