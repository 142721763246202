import { MdOutlinePayment } from 'react-icons/md';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FaRegCheckCircle, FaRegCreditCard, FaSync } from 'react-icons/fa';
import {
  FiArrowUp,
  FiCheck,
  FiCopy,
  FiDownload,
  FiFileText,
  FiShoppingCart,
  FiUser,
} from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';
import { Button, Headline } from '../../../LegacyV4Components';
import * as S from './styles';
import PixIcon from '../../../../assets/icons/pix.svg';
import BoletoIcon from '../../../../assets/icons/boleto.png';
import { useToast } from '../../../../hooks/toast';
import { FormikPaymentOptions } from '../../../../pages/PaymentOptions';
import { IProductById } from '../../../../services/requests/Finance/getProductById';
import { createPaymentProps } from '../../../../services/requests/Finance/createPayment';
import { getPaymentById } from '../../../../services/requests/Finance/getVerifyPayment';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';
import { getVerifyInvoiceById } from '../../../../services/requests/MediaWallet/getVerifyInvoice';

interface FinishPaymentProps {
  responsePayment: createPaymentProps;
  values: FormikPaymentOptions;
  data: IProductById | any;
  mediaWallet?: boolean;
  couponDiscount?: any;
  quantity?: number;
}

export function CustomerFinishPayment({
  responsePayment,
  values,
  data,
  mediaWallet,
  couponDiscount,
  quantity,
}: FinishPaymentProps) {
  const { addToast } = useToast();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [paymentById, setPaymentById] = useState({} as any);
  const [searchParams] = useSearchParams();
  const isMatriz = searchParams.get('isMatriz');

  useEffect(() => {
    if (mediaWallet === true) {
      setPaymentById(responsePayment);
    }
  }, [mediaWallet, responsePayment]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);

    addToast({
      type: 'success',
      title: 'Copiado para a área de transferência!',
    });
  }

  const handlePaymentMethodText = useCallback((method: string) => {
    switch (method) {
      case 'bank_slip':
        return 'Boleto';
      case 'pix':
        return 'Pix';
      case 'credit_card':
        return 'Cartão de crédito';
      default:
        return '';
    }
  }, []);

  return (
    <S.Container>
      {!paymentById ? (
        <S.ApprovedPayment>
          <FaRegCheckCircle color="#00C49A" />
          <p>Pedido aceito, aguardando pagamento</p>
        </S.ApprovedPayment>
      ) : (
        <>
          {paymentById.status === 'paid' && (
            <S.ConfirmedPayment>
              <FaRegCheckCircle color="#00C49A" />
              <p>Pagamento confirmado com sucesso</p>
              <small>
                Você receberá um email confirmando o pagamento do{' '}
                {mediaWallet !== true ? `${data?.name}` : 'Wallet'} e as
                informações pertinentes
              </small>
            </S.ConfirmedPayment>
          )}
          {paymentById.status === 'pending' && (
            <S.ConfirmedPayment>
              <FaRegCheckCircle color="#EEAD2D" />
              <p>Pagamento pendente</p>
              <small>
                Estamos aguardando o seu pagamento do{' '}
                {mediaWallet !== true ? `${data?.name}` : 'Wallet'}, assim que
                confirmado você receberá um email.
              </small>
            </S.ConfirmedPayment>
          )}
        </>
      )}
      <S.ConfirmationPayment style={{ marginBottom: '40px' }}>
        {responsePayment?.paymentMethod === 'credit_card' && (
          <>
            <S.ContainerCreditCard>
              <button
                className="verifyPayment"
                type="button"
                onClick={async () => {
                  const pay = await getPaymentById(responsePayment?._id);
                  pay !== false && setPaymentById(pay);
                }}
              >
                <span>Atualizar página</span> <FaSync />
              </button>
              <FaRegCreditCard width="54px" height="42px" />
              <small>
                Selecione o botão abaixo para ser redirecionado ao ambiente de
                pagamento com cartão de credito.
              </small>
              <Button
                variant="secondary"
                rightIcon={<FiCheck />}
                color="green"
                size="default"
                onClick={() => window.open(responsePayment?.secure_url)}
              >
                Pagar com cartão
              </Button>
            </S.ContainerCreditCard>
          </>
        )}
        {responsePayment?.paymentMethod === 'pix' && (
          <>
            <S.ContainerPix>
              <div className="containerRefresh">
                <button
                  className="verifyPayment"
                  type="button"
                  onClick={async () => {
                    const pay = await getPaymentById(responsePayment?._id);
                    pay !== false && setPaymentById(pay);
                  }}
                >
                  <span>Atualizar página</span> <FaSync />
                </button>
                <img src={PixIcon} alt="" />
              </div>
              <small>Escaneie o código PIX QR com seu telefone</small>
            </S.ContainerPix>
            <S.QRCode>
              <img src={responsePayment.pix.qrcode} alt="QR Code" />
              <small>
                Você está tendo problemas para ler o código QR? Copie o código
                acima e insira-o manualmente
              </small>
            </S.QRCode>
            <Button
              variant="primary"
              rightIcon={<FiCopy />}
              color="green"
              size="default"
              onClick={() => copyToClipboard(responsePayment?.pix?.qrcode_text)}
            >
              Copiar código QR
            </Button>
          </>
        )}
        {responsePayment?.paymentMethod === 'bank_slip' && (
          <>
            <S.ContainerSlip>
              <button
                className="verifyPayment"
                type="button"
                onClick={async () => {
                  if (mediaWallet === true) {
                    const pay = await getVerifyInvoiceById(
                      responsePayment?._id,
                    );
                    pay !== false && setPaymentById(pay);
                  } else {
                    const pay = await getPaymentById(responsePayment?._id);
                    pay !== false && setPaymentById(pay);
                  }
                }}
              >
                <span>Atualizar página</span> <FaSync />
              </button>
              <img src={BoletoIcon} alt="Boleto" />
              <small>Código de pagamento</small>
              <S.ContainerBarCode>
                {mediaWallet === true ? (
                  <>
                    <img
                      src={responsePayment?.bankSlip?.barcode}
                      alt="Código de barras"
                    />
                    <small>{responsePayment?.bankSlip?.barcode}</small>{' '}
                  </>
                ) : (
                  <>
                    {' '}
                    <img
                      src={responsePayment?.bank_slip?.barcode}
                      alt="Código de barras"
                    />
                    <small>{responsePayment?.bank_slip?.barcode_data}</small>{' '}
                  </>
                )}
              </S.ContainerBarCode>
              <p className="descriptionBarCode">
                Você está tendo problemas para usar o código de pagamento? Copie
                o código acima e insira-o manualmente
              </p>
              <div className="containerButtonsBarCode">
                <Button
                  variant="primary"
                  rightIcon={<FiCopy />}
                  color="green"
                  size="default"
                  onClick={() =>
                    copyToClipboard(
                      mediaWallet
                        ? responsePayment?.bankSlip?.barcode
                        : responsePayment?.bank_slip?.barcode_data,
                    )
                  }
                >
                  Copiar código
                </Button>
                <Button
                  variant="secondary"
                  rightIcon={<FiDownload />}
                  color="green"
                  size="default"
                  onClick={() =>
                    window.open(
                      mediaWallet === true
                        ? responsePayment?.bankSlip?.downloadLink
                        : responsePayment?.secure_url,
                    )
                  }
                >
                  Visualizar boleto
                </Button>
              </div>
            </S.ContainerSlip>
          </>
        )}
      </S.ConfirmationPayment>

      <div>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          sx={{ border: '1px solid #00c49a' }}
        >
          <AccordionSummary
            expandIcon={<FiArrowUp />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FiFileText style={{ marginRight: '28px' }} />
              <Headline variant="h5">Ver resumo</Headline>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <S.ContainerInfo>
              <Headline variant="h5">
                <FiShoppingCart />
                {mediaWallet === true ? 'Sua fatura' : 'Seu pedido'}
              </Headline>
              <S.Details>
                {isMatriz === 'true' ? (
                  <p>
                    {quantity
                      ? convertNumberToBRL(data?.products[0]?.value * quantity)
                      : convertNumberToBRL(
                          couponDiscount?.finalValue ??
                            data?.products[0]?.value,
                        )}
                  </p>
                ) : (
                  <p>
                    {mediaWallet !== true && data
                      ? convertNumberToBRL(data?.value / 100)
                      : (data && convertNumberToBRL(data?.ammount / 100)) || ''}
                  </p>
                )}
                <small>{data?.description}</small>
              </S.Details>
            </S.ContainerInfo>

            <S.ContainerInfo>
              <Headline variant="h5">
                <FiUser />
                Sobre você
              </Headline>
              <S.Details>
                <p>Nome completo</p>
                <small>{values?.name}</small>
              </S.Details>
              <S.Details>
                <p>Email</p>
                <small>{values?.email || ''}</small>
              </S.Details>
              <S.Details>
                <p>Telefone</p>
                <small>{values?.telephone || values?.phone}</small>
              </S.Details>
            </S.ContainerInfo>

            <S.ContainerInfo>
              <Headline variant="h5">
                <MdOutlinePayment />
                Pagamento
              </Headline>
              <S.Details>
                <p>{handlePaymentMethodText(values?.paymentMethod)}</p>
              </S.Details>
            </S.ContainerInfo>
          </AccordionDetails>
        </Accordion>
      </div>
    </S.Container>
  );
}
