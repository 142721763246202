import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 704px;
  max-width: 95vw;
  height: 100vh;
  max-height: 88vh;
  border-radius: 0.5rem;
  background-color: var(--white);
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 0;
  padding: 1.75rem 1.5rem;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.12);
  & > button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 1rem;
    height: 1rem;
    background: transparent;
    border: none;
    color: #83834f;
    & > svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 1.5rem 2.5rem;
  overflow: auto;
  & > h5 {
    margin-bottom: 2rem;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 1rem;
  box-shadow: 0 -4px 8px 3px rgba(0, 0, 0, 0.06), 0 -1px 3px rgba(0, 0, 0, 0.12);
  padding: 1.5rem 2.5rem;
`;
