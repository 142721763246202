import { useState, useRef } from 'react';
import { SketchPicker } from 'react-color';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useOutsideAlerter } from '../../../../hooks/outsideClickAlert';
import * as S from './styles';

interface ColorPickerProps {
  color: string;
  setColor: (color: string) => void;
}

export function ColorPicker({ color, setColor }: ColorPickerProps) {
  const colors = ['#00C49A', '#E03131', '#2ABCFA', '#FABC2A'];
  const [isCirclePickerOpen, setIsCirclePickerOpen] = useState(false);
  const [isSketchPickerOpen, setIsSketchPickerOpen] = useState(false);

  const wrapperRef = useRef(null);

  function handleColorChange(sketchColor: any) {
    setColor(sketchColor.hex);
  }
  function handleCloseAll() {
    setIsCirclePickerOpen(false);
    setIsSketchPickerOpen(false);
  }

  useOutsideAlerter({ ref: wrapperRef, action: handleCloseAll });

  return (
    <S.ColorPicker ref={wrapperRef}>
      <S.Container
        onClick={() => {
          setIsCirclePickerOpen(!isCirclePickerOpen);
          setIsSketchPickerOpen(false);
        }}
      >
        <S.Color color={color} />
        <MdOutlineKeyboardArrowDown size={20} />
      </S.Container>

      <S.ColorsContainer isOpen={isCirclePickerOpen}>
        {colors.map((arrayColor) => (
          <S.Color
            color={arrayColor}
            onClick={() => {
              setColor(arrayColor);
              setIsCirclePickerOpen(false);
            }}
          />
        ))}
        <S.Color
          color="rainbow"
          onClick={() => setIsSketchPickerOpen(!isSketchPickerOpen)}
        />
      </S.ColorsContainer>
      {isCirclePickerOpen && isSketchPickerOpen && (
        <S.SketchContainer>
          <SketchPicker
            color={color}
            onChange={handleColorChange}
            onChangeComplete={handleColorChange}
          />
        </S.SketchContainer>
      )}
    </S.ColorPicker>
  );
}
