import styled from 'styled-components';

export const Container = styled.div`
  p {
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
  }

  p:first-child {
    margin-top: 0;
  }

  input::placeholder {
    color: var(--secondary-light);
  }
`;

export const InputField = styled.input<{ error?: boolean }>`
  width: 100%;
  height: 3rem;
  border-radius: 0.25rem;
  border: 1px solid
    ${({ error }) => (error ? 'var(--error)' : 'var(--secondary-light)')};

  padding: 1rem 0.75rem;
`;
export const ErrorTag = styled.h6`
  color: var(--error);
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 1.5rem;

  button:first-child {
    margin-right: 1rem;
  }
`;
