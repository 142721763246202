import { LabDsSelect, LabDsTable } from 'v4web-components-react';
import { IOptionsSelect } from 'v4web-components';
import * as S from '../../styles';
import { investmentInfos } from '../../../../services/requests/Unit/calculate';
import { paymentMenu } from './menu';
import {
  CONTRACT_DURATION,
  DISCOUNT_IN_CASH,
  DISCOUNT_PAY_IN_SIX_MONTHS,
  PAYMENT_METHODS,
} from '../../utils';

interface investiment {
  totalInvestment?: investmentInfos['detailedInvestmentPerMonth'];
  paymentMethod: IOptionsSelect[];
  discount: string;
  setPaymentMethod: (state: IOptionsSelect[]) => void;
  setPayment: (state: number) => void;
  contractDuration: IOptionsSelect[];
  setContractDuration: (state: IOptionsSelect[]) => void;
}

export function Payment({
  totalInvestment,
  paymentMethod,
  discount,
  contractDuration,
  setPaymentMethod,
  setPayment,
  setContractDuration,
}: investiment) {
  const DISCOUNT = parseFloat(discount.replaceAll('.', ''));
  const MAX_PERCENTAGE_DISCOUNT = 0.14;

  const totalCalc = {
    inCash: totalInvestment?.operationalInvestment
      ? totalInvestment?.operationalInvestment * DISCOUNT_IN_CASH - DISCOUNT
      : 0,
    semester: totalInvestment?.operationalInvestment
      ? totalInvestment?.operationalInvestment * DISCOUNT_PAY_IN_SIX_MONTHS -
        DISCOUNT
      : 0,
    monthly: totalInvestment?.operationalInvestment
      ? totalInvestment?.operationalInvestment - DISCOUNT
      : 0,
  };

  function getMaxDiscount(discount_pay: number) {
    if (!totalInvestment?.operationalInvestment) return 0;
    return (
      totalInvestment?.operationalInvestment *
      discount_pay *
      MAX_PERCENTAGE_DISCOUNT
    );
  }

  const MAX_DISCOUNT_VALUE = {
    inCash: getMaxDiscount(DISCOUNT_IN_CASH),
    semester: getMaxDiscount(DISCOUNT_PAY_IN_SIX_MONTHS),
    monthly: getMaxDiscount(1) || 0,
  };

  return (
    <S.ContentBox>
      <S.ContentHeader>
        <h4>Pagamento</h4>
      </S.ContentHeader>

      <S.Content>
        <S.Payment>
          <S.Select>
            <LabDsSelect
              className="select"
              avatar={false}
              type="radioButton"
              titleInput="Forma de pagamento"
              label="Forma de pagamento"
              optionsSelected={paymentMethod}
              options={PAYMENT_METHODS}
              onChangeInputOptions={({ detail }) => {
                setPaymentMethod(detail);
                setPayment(
                  totalCalc[detail[0].key as 'inCash' | 'semester' | 'monthly'],
                );
              }}
              state={paymentMethod.length ? 'default' : 'error'}
              helperText={paymentMethod.length ? '' : 'Campo obrigatório'}
            />

            <LabDsSelect
              className="select"
              avatar={false}
              type="radioButton"
              titleInput="Período do contrato (meses)"
              label="Período do contrato (meses)"
              options={CONTRACT_DURATION}
              onChangeInputOptions={({ detail }) => {
                setContractDuration(detail);
              }}
              optionsSelected={contractDuration}
              state={contractDuration.length ? 'default' : 'error'}
              helperText={contractDuration.length ? '' : 'Campo obrigatório'}
            />
          </S.Select>
          <LabDsTable
            data={paymentMenu({
              discount:
                MAX_DISCOUNT_VALUE[
                  paymentMethod[0]?.key as 'inCash' | 'semester' | 'monthly'
                ] > DISCOUNT
                  ? DISCOUNT
                  : 0,
              totalInvestment,
              paymentMethod: paymentMethod[0]?.key || '',
            })}
          />
        </S.Payment>
      </S.Content>
    </S.ContentBox>
  );
}
