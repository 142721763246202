import styled from 'styled-components';

export const QuestionAccordion = styled.div`
  padding: 1rem;

  border: 1px solid var(--grayscale-20);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 2px 6px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  user-select: none;
`;

export const QuestionHeader = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  & svg {
    color: var(--grayscale-60);

    flex-shrink: 0;

    transition: all 0.3s ease-in-out;
  }

  &.open svg {
    transform: rotate(-90deg);
  }
`;

export const QuestionTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & p {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  &.open p {
    opacity: 0;
  }
`;

interface QuestionContentWrapperProps {
  height: number;
}

export const QuestionContentWrapper = styled.div<QuestionContentWrapperProps>`
  display: block;

  height: 0;
  overflow: hidden;
  opacity: 0;

  transition: all 0.3s ease-in-out;

  &.open {
    height: ${({ height }) => height}px;
    opacity: 1;
  }
`;

export const QuestionContent = styled.div`
  display: flex;
  flex-direction: column;
`;
