import * as yup from 'yup';
import { ValidateCPF } from '../../../../utils/validateCPF';

export const createEmailInvestorSchema = yup.object().shape({
  personalEmail: yup
    .string()
    .required('Campo Obrigatório!')
    .email('Insira um email válido'),
  email: yup
    .string()
    .required('Campo Obrigatório!')
    .max(255, 'No Máximo 255 Dígitos!')
    .min(3, 'No Mínimo 3 Dígitos!')
    .matches(
      RegExp('^[A-Za-z0-9._%+-]+@v4company.com$'),
      'O email deve ser do domínio @v4company',
    ),
  wage: yup
    .number()
    .min(1, 'Campo Obrigatório!')
    .required('Campo Obrigatório!'),
  cep: yup.string().required('Campo Obrigatório!'),
  startedAt: yup.date().required('Campo Obrigatório!'),
  contract: yup.mixed().required('Nenhum Arquivo Selecionado!'),
  previousUnit: yup.mixed().nullable().required('Campo Obrigatório!'),
  investorSource: yup.string().required('Campo Obrigatório!'),
  cpf: yup
    .string()
    .min(11, 'CPF Inválido')
    .required('Insira seu CPF')
    .test('cpfValidation', 'CPF Inválido', (value) => ValidateCPF(value))
    .nullable(),
  phone: yup.string().required('Campo Obrigatório!'),
  givenName: yup.string().required('Campo Obrigatório!'),
  familyName: yup.string().required('Campo Obrigatório!'),
  mandate: yup.string().required('Campo Obrigatório!'),
  gender: yup.string().required('Campo Obrigatório!'),
  seniority: yup.string().required('Campo Obrigatório!'),
  unitRole: yup.string().required('Campo Obrigatório!'),
  headquarterRole: yup.string().required('Campo Obrigatório!'),
  dashboardRole: yup.string().required('Campo Obrigatório!'),
  leadbrokerRole: yup.string().required('Campo Obrigatório!'),
  ekyteRole: yup.string().required('Campo Obrigatório!'),
  customerRole: yup.string().required('Campo Obrigatório!'),
  paymentGatewayRole: yup.string().required('Campo Obrigatório!'),
});
