import { FaCheck } from 'react-icons/fa';
import { MdOutlineError } from 'react-icons/md';
import { Paragraph } from '../../../LegacyV4Components';
import * as S from './styles';

interface Props {
  isSuccess: boolean;
  title: string;
  actionMessage: string | undefined;
}

export function ItemListComponent({ isSuccess, title, actionMessage }: Props) {
  const iconSize = 16;
  return (
    <S.Item>
      {isSuccess ? (
        <FaCheck size={iconSize} color="var(--success-2)" />
      ) : (
        <MdOutlineError size={iconSize} color="var(--warning)" />
      )}
      <S.MessagesWrapper>
        <Paragraph>{title}</Paragraph>
        {!isSuccess && actionMessage && (
          <S.SecondaryMessage>
            <b>Ação: </b> {actionMessage}
          </S.SecondaryMessage>
        )}
      </S.MessagesWrapper>
    </S.Item>
  );
}
