import { parseCookies } from 'nookies';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { newAds } from '../../api';

export async function getAccountIntegrationByUnit(
  unitId: string,
  type?: string,
): Promise<AdsAccounts[]> {
  const cookies = parseCookies(undefined);
  const token = cookies['v4company.token'];

  const { data } = await newAds.get(
    `/customerIntegration/unit/${unitId}${type ? `?type=${type}` : ''}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  const adsAccounts = data;
  return adsAccounts as AdsAccounts[];
}

export function useGetAccountIntegrationByUnit(
  unitId: string,
  type?: string,
): UseQueryResult<AdsAccounts[]> {
  return useQuery<AdsAccounts[]>(
    ['get-account-integrations', unitId, type],
    () => getAccountIntegrationByUnit(unitId, type as string),
  );
}
