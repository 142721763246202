import styled from 'styled-components';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input-field';

interface SubtitleBuilderProps {
  isBold?: boolean;
}

export const SubtitleBuilder = (isBold: boolean) =>
  styled('small')<SubtitleBuilderProps>({
    fontSize: '0.5625rem',
    fontWeight: isBold ? 900 : 500,
  });

export const InputContent = styled.div<{
  display?: string;
  width?: string;
  direction?: string;
}>`
  width: ${({ width }) => width ?? '100%'};
  display: ${({ display }) => display ?? 'flex'};
  flex-direction: ${({ direction }) => direction ?? 'column'};
`;

export const Error = styled.span`
  font-size: 12px;
  color: #c01c30;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

export const InputLabel = styled.label`
  font-weight: 700;
  font-size: 16px;
  color: #333333;
  font-family: 'Inter', 'Nunito Sans';
  margin-bottom: 8px;
`;

export const Required = styled.span`
  font-size: 16px;
  font-family: 'Inter', 'Nunito Sans';
  font-weight: bold;
  color: #c01c30;
`;

export const Tooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  width: 24px;
  height: 24px;

  z-index: 999;

  border-radius: 50%;
  border: 0.5px solid #e6e6ef;

  background-color: #fff;

  cursor: pointer;
  position: relative;

  span {
    width: 250px;
    border-radius: 3px;
    border: 1px solid #b4b4cf;
    position: absolute;
    text-align: center;
    visibility: hidden;
    background-color: #fff;
    color: #333333;
    font-size: 14px;
    padding: 5px 10px;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -8px;
      width: 0;
      height: 0;
      border-top: 8px solid #b4b4cf;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-radius: 3px;
    }
  }

  &:hover span {
    visibility: visible;
    bottom: 30px;
    left: -17px;
  }
`;

export const InputCurrency = styled(CurrencyInput)<{ borderColor?: string }>`
  width: 100%;
  padding: 12px 16px;
  border: 1px solid ${({ borderColor }) => borderColor || '#E6E6EF'};
  font-size: 16px;
  border-radius: 3px;
  margin-bottom: 8px;
  &::placeholder {
    color: #7f7f7f;
  }
`;

export const InputToMask = styled(InputMask)<{ borderColor?: string }>`
  width: 100%;
  padding: 12px 16px;
  border: 1px solid ${({ borderColor }) => borderColor || '#E6E6EF'};
  font-size: 16px;
  border-radius: 3px;

  &::placeholder {
    color: #7f7f7f;
  }
`;

export const Input = styled.input<{ borderColor?: string }>`
  width: 100%;
  padding: 12px 16px;
  border: 1px solid ${({ borderColor }) => borderColor || '#E6E6EF'};
  font-size: 16px;
  border-radius: 3px;

  &::placeholder {
    color: #7f7f7f;
  }
`;
