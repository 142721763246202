import React, { InputHTMLAttributes, useState, SetStateAction } from 'react';
import { IoIosHelp } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import * as S from './styles';

interface SingleSelect extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  borderColor?: string;
  required?: boolean;
  placeholder: string;
  options: Array<InputItens>;
  info?: string;
  filter?: boolean;
  disabled?: boolean;
  itensSelected: Array<InputItens>;
  setItensSelected: React.Dispatch<SetStateAction<Array<InputItens>>>;
  error?: string;
}

interface InputItens {
  label: string;
  value: unknown;
  info?: string;
}

export function SingleSelect({
  label,
  required,
  placeholder,
  borderColor,
  options,
  info,
  itensSelected,
  disabled = false,
  filter = false,
  setItensSelected,
  onChange,
  value,
  error,
  ...rest
}: SingleSelect) {
  const [search, setSearch] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const searchedItems = options.filter((item) =>
    item.label.toUpperCase().includes(search.toUpperCase()),
  );

  const addItem = (index: number) => {
    const existing =
      itensSelected.filter(
        (select) => select.label === searchedItems[index].label,
      ).length > 0;

    if (existing) {
      const indexItem = itensSelected
        .map((item) => item.label)
        .indexOf(searchedItems[index].label);

      itensSelected.splice(indexItem, 1);
      setItensSelected([...itensSelected]);
      return;
    }

    const aux = searchedItems[index];
    itensSelected.push(aux);
    setItensSelected([aux]);
    setIsOpen(!isOpen);
  };

  return (
    <S.InputContent>
      <ReactTooltip />
      <S.Row>
        <S.InputLabel style={{ color: disabled ? `var(--tertiary-dark)` : '' }}>
          {label}
          {required && <S.Required>*</S.Required>}
        </S.InputLabel>
        {info ? (
          <S.Tooltip>
            <IoIosHelp size={24} data-tip={info} />
          </S.Tooltip>
        ) : null}
      </S.Row>
      <S.Container>
        <S.Input
          borderColor={borderColor}
          {...rest}
          disabled={!!disabled}
          onClick={() => {
            if (!disabled) setIsOpen(!isOpen);
          }}
        >
          <input
            {...rest}
            value={JSON.stringify(value)}
            onChange={onChange}
            hidden
          />
          <S.InputItens>
            {itensSelected.length > 0 ? (
              <S.Valeu disabled={!!disabled}>{itensSelected[0].label}</S.Valeu>
            ) : (
              <S.Placeholder>{placeholder}</S.Placeholder>
            )}
          </S.InputItens>
          {isOpen ? <MdExpandLess /> : <MdExpandMore />}
        </S.Input>
        {isOpen && !disabled ? (
          <S.ContentSearch>
            {filter && (
              <S.InputSearch
                type="text"
                placeholder="Filtrar"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            )}

            <S.Options>
              {searchedItems.map((item, index) => (
                <S.Row
                  onClick={() => addItem(index)}
                  key={Number(index)}
                  className="item"
                  checked={
                    itensSelected.filter(
                      (select) => select.label === item.label,
                    ).length > 0
                  }
                >
                  <ReactTooltip />
                  <S.Check
                    checked={
                      itensSelected.filter(
                        (select) => select.label === item.label,
                      ).length > 0
                    }
                  >
                    <div className="after" />
                  </S.Check>
                  <S.Itens>{item.label}</S.Itens>
                  {item.info ? (
                    <S.Tooltip>
                      <IoIosHelp size={24} data-tip={item.info} />
                    </S.Tooltip>
                  ) : null}
                </S.Row>
              ))}
            </S.Options>
          </S.ContentSearch>
        ) : null}
      </S.Container>
      <S.Error>{error}</S.Error>
    </S.InputContent>
  );
}
