import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { Box } from '@mui/material';
import { Smalled } from '../../../LegacyV4Components';
import * as S from './styles';

export type TextFieldStatus =
  | 'default'
  | 'focus'
  | 'filled'
  | 'error'
  | 'warning'
  | 'success';

export function FormikCpfCnpjInput({ ...props }: NumberFormatProps) {
  let inputStatus = '';

  if (props.value !== '') {
    inputStatus = 'filled';
  }

  if (props.helper) {
    inputStatus = 'error';
  }

  if (props.status === 'default') {
    inputStatus = 'default';
  }

  return (
    <S.InputWrapper className={inputStatus}>
      <label htmlFor={props.name}>
        <h6>{props.label}</h6>
        <S.InputControl
          className="input-control"
          style={{
            width: props.width ? `${props.width}` : 'auto',
            background: '#fff',
          }}
        >
          <NumberFormat
            format={props?.format}
            placeholder={props.label}
            error={props.error}
            fullWidth
            className="h-auto"
            InputProps={{}}
            onClick={props.onClick}
            allowLeadingZeros
            isNumericString={false}
            value={props.value}
            onValueChange={props.onValueChange}
            {...props}
          />
        </S.InputControl>
        <Box sx={{ height: '1rem' }}>
          {props.helper && <Smalled>{props.helper}</Smalled>}
          {props.status === 'warning' && (
            <Smalled>{props.warningMessage}</Smalled>
          )}
        </Box>
      </label>
    </S.InputWrapper>
  );
}
