import { IoMdCloseCircleOutline } from 'react-icons/io';
import { Button } from '../../../LegacyV4Components';
import { Modal } from '../../../Default/Modal';

import * as S from './styles';

interface Props {
  showDelete: boolean;
  onCancel?: () => void;
  onSubmit?: () => any;
  name: string;
  itemName?: string;
}

export function DeleteForm({
  showDelete,
  onCancel,
  onSubmit,
  name,
  itemName,
}: Props) {
  return (
    <Modal modalOpen={showDelete} onBackground={onCancel}>
      <S.DeleteForm>
        <IoMdCloseCircleOutline size={64} />
        <h3>
          Tem certeza que você deseja excluir o {name} “{itemName}”?
        </h3>

        <footer>
          <Button
            onClick={() => onCancel && onCancel()}
            variant="secondary"
            size="default"
            color="red"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              onSubmit && onSubmit();
              onCancel && onCancel();
            }}
            variant="primary"
            size="default"
            color="red"
          >
            Excluir
          </Button>
        </footer>
      </S.DeleteForm>
    </Modal>
  );
}
