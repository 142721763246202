import { Dashboard } from '../../../types/dashboard';
import { dashboardsApi } from '../../api';

export async function favoriteDashboard(
  dashboard: Dashboard,
  userId: string,
  isFavorite: boolean,
): Promise<Dashboard | false> {
  try {
    if (isFavorite) {
      const response = await dashboardsApi.delete<Dashboard>(
        `/favorite/${dashboard._id}`,
      );

      const responseDashboard: Dashboard = response.data;

      return responseDashboard;
    }

    const response = await dashboardsApi.post<Dashboard>(
      `/favorite/${dashboard._id}`,
    );

    const responseDashboard: Dashboard = response.data;

    return responseDashboard;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao atualizar o dashboard, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
