import { NumberFormatValues } from 'react-number-format';
import { FormikTouched } from 'formik';
import { AiOutlineMail } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { Button, Headline } from '../../../LegacyV4Components';
import { useToast } from '../../../../hooks/toast';

import * as S from './styles';
import { TextField } from '../../molecules/TextField';
import { FormikSubscriptionOptions } from '../../../../pages/SubscriptionOptions';
import {
  createSubscription,
  createSubscriptionV2,
} from '../../../../services/requests/Finance/createSubscription';

interface ResumeSubscriptionProps {
  values: FormikSubscriptionOptions;
  name: string;
  errors: any;
  handleBlur: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  touched: FormikTouched<FormikSubscriptionOptions>;
  subscriptionId: string;
  unitId: string;
}

export function ResumeSubscription({
  values,
  name,
  errors,
  handleBlur,
  isLoading,
  setIsLoading,
  setFieldValue,
  touched,
  subscriptionId,
  unitId,
}: ResumeSubscriptionProps) {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const url = window.location.href;
  const verifyIfIsV2 = url.includes('v2');

  const createSubscriptionHandler = useCallback(async () => {
    let subscription;
    setIsLoading(true);
    if (verifyIfIsV2 === true) {
      subscription = await createSubscriptionV2({
        ...values,
        name,
      });
    } else {
      subscription = await createSubscription(
        {
          ...values,
        },
        unitId,
        subscriptionId,
      );
    }
    setIsLoading(false);
    return subscription;
  }, [values, name, verifyIfIsV2, setIsLoading, unitId, subscriptionId]);

  const handleClick = async () => {
    const subscription = await createSubscriptionHandler();
    if (subscription?.success === true || subscription?.isSuccess === true) {
      navigate(`/assinatura/${values.planIdentifier}/sucesso`);
      addToast({
        title: 'Plano assinado com sucesso!',
        description: subscription?.message,
        type: 'success',
      });
    }
    if (subscription?.success === false || subscription?.isSuccess === false) {
      addToast({
        title: 'Ocorreu um erro!',
        description: subscription.errMessage || subscription?.message,
        type: 'error',
      });
    }
  };

  return (
    <S.Container>
      <S.Card>
        <S.Header>
          <Headline variant="h6">
            <AiOutlineMail />
            Receba os detalhes do pagamento
          </Headline>
        </S.Header>
        <S.Body>
          <TextField
            name="email"
            label="E-mail *"
            value={values.email}
            placeholder="Insira aqui seu melhor e-mail"
            helper={touched?.email && errors?.email ? errors?.email : ''}
            validateOnChange={false}
            onBlur={handleBlur}
            onValueChange={(val: NumberFormatValues) => {
              setFieldValue(`email`, val.value);
            }}
          />
        </S.Body>
      </S.Card>
      <div style={{ float: 'right' }}>
        <Button
          variant="primary"
          color="green"
          size="default"
          loading={isLoading}
          disabled={errors.email}
          onClick={handleClick}
        >
          Assinar assessoria
        </Button>
      </div>
    </S.Container>
  );
}
