import styled from 'styled-components';

export const Container = styled.main`
  background-color: var(--tertiary-dark);
  margin: 2.5% 35%;
  height: 90%;
  border-radius: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    margin: 3% 5%;

    .title {
      h2 {
        font-size: 1.2rem;
      }
    }
  }

  @media (max-width: 1200px) and (min-width: 769px) {
    margin: 3% 12%;
  }

  @media (max-width: 1920px) and (min-width: 1201px) {
    margin: 2.5% 25%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
  width: 92%;
  position: relative;
  text-align: center;

  .title {
    h2 {
      font-size: 1.8rem;
    }
  }
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: 86%;
  overflow-y: auto;
  width: 92%;
  background: url('https://i.pinimg.com/736x/8c/98/99/8c98994518b575bfd8c949e91d20548b.jpg');
  background-size: cover;
  border-radius: 1.25rem;

  ul {
    list-style: none;

    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .conversationStart {
    background-color: var(--tertiary-dark);
    border-radius: 0.3rem;
    padding: 0 0.25rem;

    margin-left: auto;
    margin-right: auto;

    margin-top: 0.5rem;
  }
`;

export const LoadingContainer = styled.div`
  width: 3rem;
  height: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto;
`;

export const NoMessages = styled.p`
  background-color: var(--tertiary-dark);
  font-size: 1.4rem;
  border-radius: 0.6rem;
  padding: 0 0.5rem;
  margin: 1rem;
  text-align: center;

  @media (max-width: 768px) {
    width: 70%;
  }
`;

export const ClientMessage = styled.li`
  background-color: var(--grayscale-40);
  color: var(--white);
  padding: 0.25rem;
  border-radius: 0.5rem 0.5rem 0.5rem 0;

  max-width: 70%;
  word-wrap: break-word;

  display: flex;
  flex-direction: column;

  span {
    font-size: 0.6rem;
    color: var() (--white);
    margin-left: auto;
  }

  margin-right: auto;
`;

export const ServerMessage = styled.li`
  background-color: var(--primary-main);
  color: var(--white);
  padding: 0.25rem;
  border-radius: 0.5rem 0.5rem 0 0.5rem;

  max-width: 70%;
  word-wrap: break-word;

  display: flex;
  flex-direction: column;

  span {
    font-size: 0.6rem;
    color: var(--white);
    margin-left: auto;
  }

  margin-left: auto;
`;

export const ReturnButton = styled.button`
  background-color: var(--primary-main);
  color: var(--white);
  border: none;
  border-radius: 0.3rem;
  padding: 0.3rem 0.6rem;

  cursor: pointer;
  transition: 0.3s;

  position: absolute;
  left: 0px;

  &:hover {
    background-color: var(--white);
    color: var(--primary-main);
  }
  :not(:hover) {
    transition: 0.25s;
  }

  @media (max-width: 768px) {
    left: 0.35rem;
    bottom: -2rem;

    border-radius: 0.45rem;
  }
`;
