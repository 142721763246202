import { unitsApi as api, leadBrokerApi } from '../../api';

import { UnitInterface } from '../../../types/units';

interface LockUnitProp {
  blockedAt: string;
}

export async function disableUnit(id: string, active: boolean) {
  try {
    const data = await api.put<UnitInterface>(`/units`, {
      _id: id,
      active,
    });
    return data;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao desabilitar a unidade, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}

export async function lockUnit(unitId: string, blockReason: string) {
  try {
    const { data } = await leadBrokerApi.post<LockUnitProp>(`/blockments`, {
      unitId,
      blockReason,
    });
    return data;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao desabilitar a unidade, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
