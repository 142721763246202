import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FiPlus } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { remove } from 'diacritics';
import { Modal, MultInputSearch } from '../../../LegacyV4Components';
import * as S from './styles';
import {
  getAllHeadquartersMandateItems,
  getAllSeniorityItems,
} from '../../organisms/ProfileForm/hooks';
import { InputForm } from '../../../Default/InputForm';
import { InputCurrencyForm } from '../../../Default/InputCurrencyForm';
import {
  CreateUserInterface,
  InputItens,
  SetPermissionsFunction,
} from './interfaces';
import { createEmailInvestorSchema } from './schemas';
import { educationalPrograms } from '../../../../utils/InviteAndCreateUserOptions/educationalPrograms';
import { workedAtOthersUnit } from '../../../../utils/InviteAndCreateUserOptions/workedAtOthersUnit';
import { genderItens } from '../../../../utils/InviteAndCreateUserOptions/gender';
import { useToast } from '../../../../hooks/toast';
import { verifyExistsEmail } from '../../../../services/requests/User/verifyExistsEmail';
import { emailSuggestions } from '../../../../services/requests/User/emailSuggestions';
import { Permissions } from './components';
import { createGoogleEmail } from '../../../../services/requests/User/createGoogleEmail';
import { useAuth } from '../../../../hooks/auth';
import { Mandate, Seniority } from '../../../../hooks/types';
import {
  ERRORS_CREATE_USER,
  errorsCreateUser,
} from './constants/errorsCreateUser';
import { useCheckAuthentication } from '../../../../hooks/useCheckAuthentication';

interface InviteNewColabProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  unitId: string;
}

export function ModalCreateUser({
  isOpen,
  setIsOpen,
  unitId,
}: InviteNewColabProps) {
  const { user } = useAuth();

  const isHeadquarter = user.unitId === process.env.REACT_APP_HEADQUARTER_ID;

  const toastContext = useToast();

  const [wage, setWage] = useState<string | number>('');
  const [mandate, setMandate] = useState<InputItens[]>([]);
  const [seniority, setSeniority] = useState<InputItens[]>([]);
  const [gender, setGender] = useState<InputItens[]>([]);

  const [investorSource, setInvestorSource] = useState<InputItens[]>([]);
  const [previousUnit, setPreviousUnit] = useState<InputItens[]>([]);
  const [mandates, setMandates] = useState<InputItens[]>([]);
  const [seniorities, setSeniorities] = useState<InputItens[]>([]);

  const [emailSugestions, setEmailSugestions] = useState<string[]>([]);

  const [customError, setCustomError] = useState<string>('');

  const [contractName, setContractName] = useState<string>('');

  const MAX_FILE_SIZE = 1000000; // 1MB

  useCheckAuthentication();

  const {
    watch,
    handleSubmit,
    control,
    setValue,
    resetField,
    formState: { errors, isSubmitting },
  } = useForm<CreateUserInterface>({
    defaultValues: {
      dashboardRole: 'READ_ONLY',
      leadbrokerRole: 'READ_ONLY',
      blackBoxRole: 'READ_ONLY',
      dealBrokerRole: 'READ_ONLY',
      unitRole: 'READ_ONLY',
      headquarterRole: 'READ_ONLY',
      customerRole: 'READ_ONLY',
      paymentGatewayRole: 'CAN_NOT_SEE',
      meUnitRole: 'READ_ONLY',
      userRole: 'READ_ONLY',
      ekyteRole: 'CAN_NOT_SEE',
    },
    resolver: yupResolver(createEmailInvestorSchema),
  });

  const onSubmit = async (data: CreateUserInterface) => {
    if (customError !== '') {
      toastContext.addToast({
        type: 'error',
        title: 'O email V4 Company não está disponível!',
        description:
          'Escolha um novo e-mail válido para o investidor para prosseguir com a solicitação.',
      });
      return;
    }

    const response = await createGoogleEmail({ ...data, unitId });

    if (response?.code) {
      toastContext.addToast({
        type: 'error',
        title:
          errorsCreateUser[`E${response.id}` as ERRORS_CREATE_USER] ??
          'Não foi possivel completar a ação!',
      });
      return;
    }

    if (response.statusCode && response.statusCode !== 200) {
      toastContext.addToast({
        type: 'error',
        title: response.name ?? 'Não foi possivel completar a ação!',
        description:
          response.message ??
          'Ocorreu algum problema ao convidar este usuário 😕',
      });
    } else {
      toastContext.addToast({
        type: 'success',
        title: 'Parabéns! O e-mail do novo investidor foi criado com sucesso!',
        description: `O novo investidor receberá o login e senha no e-mail pessoal.`,
      });
    }
  };

  useEffect(() => {
    setValue('wage', Number(wage));
  }, [setValue, wage]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file && file.size <= MAX_FILE_SIZE) {
        setValue('contract', file);
        setContractName(file.name);
      } else {
        const errorMessage =
          'Não foi possível enviar o arquivo, verifique se o tamanho dele é menor ou igual a 1MB';
        toastContext.addToast({
          type: 'error',
          title: errorMessage,
        });
      }
      e.target.value = '';
    },
    [setValue, toastContext],
  );

  const verifyIfExistsInvestor = useCallback(async () => {
    const fullName = `${watch('givenName')} ${watch('familyName')}`;
    const existsInvestor = await verifyExistsEmail(watch('email'));
    if (existsInvestor) {
      const emailSugested = await emailSuggestions(remove(fullName));
      setEmailSugestions(emailSugested || []);
      setCustomError(
        'O e-mail informado já está em uso, busque alterar o e-mail para verificarmos a disponibilidade!',
      );
    } else {
      setEmailSugestions([]);
      setCustomError('');
    }
  }, [watch]);

  const handlerGenerictEmail = useCallback(async (): Promise<string> => {
    try {
      const givenName = watch('givenName')?.toLowerCase();
      const familyName = watch('familyName')?.split(' ')[0]?.toLowerCase();

      const validCharacters = /^[a-z0-9@]+$/;

      const sanitizedGivenName = remove(givenName).replace(/[^a-z0-9@]/gi, '');
      const sanitizedFamilyName = remove(familyName).replace(
        /[^a-z0-9@]/gi,
        '',
      );

      const finalGivenName = validCharacters.test(sanitizedGivenName)
        ? sanitizedGivenName
        : '';
      const finalFamilyName = validCharacters.test(sanitizedFamilyName)
        ? sanitizedFamilyName
        : '';

      return `${finalGivenName}.${finalFamilyName}@v4company.com`;
    } catch (err) {
      return '';
    }
  }, [watch]);

  const handlerSetEmail = useCallback(async () => {
    const generectEmail = await handlerGenerictEmail();
    setValue('email', generectEmail);
  }, [handlerGenerictEmail, setValue]);

  const watchGivenName = watch('givenName');
  const watchFamilyName = watch('familyName');

  useEffect(() => {
    handlerSetEmail();
    verifyIfExistsInvestor();
  }, [
    handlerSetEmail,
    verifyIfExistsInvestor,
    watchGivenName,
    watchFamilyName,
  ]);

  const handlerSetValue: SetPermissionsFunction = (name, value) => {
    setValue(name, value);
  };

  useEffect(() => {
    const mandatesItems = getAllHeadquartersMandateItems(
      isHeadquarter || false,
    ) as InputItens[];
    setMandates(mandatesItems);
    const senioritiesItems = getAllSeniorityItems();
    setSeniorities(senioritiesItems);
  }, [isHeadquarter]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal
        show={isOpen}
        onBackground={() => setIsOpen(false)}
        closeButton
        labelSubmit="Enviar Solicitação"
        title="Solicitar Criação de Investidor"
        footer
        loading={isSubmitting}
        height="800px"
        buttonType="submit"
        handlerSubmit={() => {
          handleSubmit(onSubmit);
        }}
      >
        <S.Container style={{ marginBottom: 100 }}>
          <S.InvestorInfoRow>
            <InputForm
              nameInput="givenName"
              label="Nome"
              type="text"
              control={control}
              placeholder="Nome do Investidor"
              required
              error={errors.givenName?.message}
            />
            <InputForm
              nameInput="familyName"
              label="Sobrenome Completo"
              type="text"
              control={control}
              placeholder="Sobrenome do Investidor"
              required
              error={errors.familyName?.message}
            />
          </S.InvestorInfoRow>
          <S.InvestorInfoRow>
            <InputForm
              nameInput="cpf"
              label="CPF"
              mask="999.999.999-99"
              type="text"
              control={control}
              placeholder="CPF do Investidor"
              required
              error={errors.cpf?.message}
            />
            <MultInputSearch
              name="mandateSelected"
              label="Gênero"
              required
              maxItem={1}
              options={genderItens}
              placeholder="Gênero do Investidor"
              itensSelected={gender}
              setItensSelected={(val: any) => {
                setGender(val);
                setValue('gender', val[0]?.value || '');
              }}
              error={errors.gender?.message}
            />
          </S.InvestorInfoRow>
          <S.InvestorInfoRow>
            <InputForm
              nameInput="personalEmail"
              label="E-mail Pessoal"
              type="email"
              control={control}
              placeholder="E-mail Pessoal do Investidor"
              required
              error={errors.personalEmail?.message}
            />
            <InputForm
              nameInput="phone"
              label="Telefone (WhatsApp)"
              type="phone"
              control={control}
              mask="(99) 9.9999-9999"
              placeholder="Telefone/WhatsApp do Investidor"
              required
              error={errors.phone?.message}
            />
          </S.InvestorInfoRow>
          <S.InvestorInfoRow>
            <InputForm
              nameInput="cep"
              label="CEP"
              mask="99999-999"
              type="text"
              control={control}
              placeholder="CEP do Investidor"
              required
              error={errors.cep?.message}
            />
            <InputCurrencyForm
              name="wage"
              label="Salário"
              control={control}
              setValue={setWage}
              value={wage}
              placeholder="Salário do Investidor"
              required
              error={errors.wage?.message}
            />
          </S.InvestorInfoRow>

          <S.InvestorInfoRow>
            <MultInputSearch
              name="mandateSelected"
              label="Cargo"
              required
              maxItem={1}
              options={mandates}
              placeholder="Função/Cargo do Investidor"
              itensSelected={mandate}
              setItensSelected={(val: any) => {
                setMandate(val);
                setValue('mandate', (val[0]?.value as Mandate)._id);
              }}
              error={errors.mandate?.message}
            />
            <MultInputSearch
              name="mandateSelected"
              label="Senioridade"
              required
              maxItem={1}
              options={seniorities}
              placeholder="Senioridade do Investidor"
              itensSelected={seniority}
              setItensSelected={(val: any) => {
                setSeniority(val);
                setValue('seniority', (val[0]?.value as Seniority)._id);
              }}
              error={errors.seniority?.message}
            />
          </S.InvestorInfoRow>

          <S.InvestorInfoRow>
            <InputForm
              nameInput="startedAt"
              label="Data de início do investidor"
              type="date"
              control={control}
              required
              width="48%"
              error={errors.startedAt?.message}
            />
          </S.InvestorInfoRow>

          <S.DocumentInput style={{ marginBottom: 24 }}>
            <S.Label>Insira o contrato do investidor*</S.Label>
            <S.Description>
              Anexe aqui o contrato assinado pelo novo investidor e pela
              unidade. Utilize o formato PDF como padrão de arquivo com tamanho
              máximo de 1mb.
            </S.Description>
            <label htmlFor="contract">
              <S.Button>
                <FiPlus />
                {contractName ? 'Modificar aquivo' : 'Adicionar novo arquivo'}
                <input
                  type="file"
                  id="contract"
                  accept=".pdf"
                  onChange={handleChange}
                  style={{ display: 'none' }}
                />
              </S.Button>
            </label>
            {contractName ? (
              <S.File>
                <S.FileName>{contractName}</S.FileName>
                <MdClose
                  size={16}
                  onClick={() => {
                    resetField('contract');
                    setContractName('');
                  }}
                />
              </S.File>
            ) : null}
            <S.Error>{errors.contract?.message}</S.Error>
          </S.DocumentInput>

          <S.InvestorInfoRow>
            <MultInputSearch
              name="mandateSelected"
              label="O investidor entrou através de um de nossos Programas de Educação?"
              required
              maxItem={1}
              options={educationalPrograms}
              placeholder="Escolha uma Opção"
              itensSelected={investorSource}
              setItensSelected={(val: any) => {
                setInvestorSource(val);
                setValue('investorSource', val[0]?.value || '');
              }}
              error={errors.investorSource?.message}
            />
          </S.InvestorInfoRow>
          <S.InvestorInfoRow>
            <MultInputSearch
              name="mandateSelected"
              label="O investidor já atuou em alguma outra unidade além da atual?"
              required
              maxItem={1}
              options={workedAtOthersUnit}
              placeholder="Escolha uma Opção"
              itensSelected={previousUnit}
              setItensSelected={(val: any) => {
                setPreviousUnit(val);
                setValue('previousUnit', val[0]?.value || '');
              }}
              error={errors.previousUnit?.message}
            />
          </S.InvestorInfoRow>
          <S.InvestorInfoRow>
            <p>Demais acessos solicitar via bot.</p>
          </S.InvestorInfoRow>

          <S.Row>
            <h2>Criação do e-mail V4</h2>
          </S.Row>
          <br />

          <S.InvestorInfoRow>
            <InputForm
              nameInput="email"
              label="E-mail V4 Company"
              type="email"
              control={control}
              placeholder="Informe o E-mail do Investidor"
              required
              error={errors.email?.message ?? customError}
              info="Ex: nome.sobrenome@v4company.com"
              onBlur={() => verifyIfExistsInvestor()}
            />
          </S.InvestorInfoRow>

          {emailSugestions.length > 0 && (
            <div>
              <p>
                Sugestões:{' '}
                {emailSugestions?.map((item) => {
                  return (
                    <S.EmailSugestions
                      key={item}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handlerSetValue('email', item);
                        setCustomError('');
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handlerSetValue('email', item);
                          setCustomError('');
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      {item},{' '}
                    </S.EmailSugestions>
                  );
                })}
              </p>
            </div>
          )}

          <Permissions
            isHeadquarter={isHeadquarter}
            error={errors}
            setPermissions={handlerSetValue}
          />
        </S.Container>
      </Modal>
    </form>
  );
}
