import * as S from './styles';

interface InterfaceSwitch {
  switchOn: boolean;
  setSwitchOn: React.Dispatch<React.SetStateAction<boolean>>;
}
export function Switch({ switchOn, setSwitchOn }: InterfaceSwitch) {
  return (
    <S.ContainerSwitch>
      <S.SwitchLabel>
        <input
          type="checkbox"
          onChange={() => setSwitchOn(!switchOn)}
          checked={switchOn}
        />
        <span className="slider round" />
      </S.SwitchLabel>
    </S.ContainerSwitch>
  );
}
