import { User, deleteUserBodyProps } from '../../../hooks/types';
import { usersApi } from '../../api';

export async function deleteUser(
  id: string,
  body: deleteUserBodyProps,
): Promise<User | false> {
  try {
    const response = await usersApi.delete(`/user/${id}`, { data: body });
    const user: any = response.data;

    return user;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar  os usuários, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
