import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 64px;
  border-radius: 4px;
  border-top-left-radius: 0;
  width: 68%;
  margin-bottom: 3rem;
  @media (max-width: 880px) {
    width: auto;
  }
`;

export const ContainerInfo = styled.div`
  width: 100%;
  padding: 16px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  svg {
    margin-right: 25px;
  }

  h5 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
  }
`;

export const ContainerLoading = styled.div`
  svg {
    width: 1rem;
    height: 1rem;
  }
  span {
    width: 1rem !important;
    height: 1rem !important;
  }
`;

export const Details = styled.div`
  margin-top: 24px;
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
  }
  small {
    color: #7f7f7f;
    font-size: 12px;
  }
`;

export const Terms = styled.div`
  display: flex;
  font-size: 1.375rem;
  margin: 3rem 0;
  input {
    margin-right: 1.125rem;
    width: 1.5rem;
  }

  button,
  a {
    background: transparent;
    border: none;
    text-decoration: underline;
    font-weight: 600;
    font-size: 1.375rem;
  }

  @media (max-width: 450px) {
    p {
      font-size: 0.65rem;
    }

    button,
    a {
      font-size: 0.65rem;
    }
  }
`;
