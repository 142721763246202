import { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { parseCookies } from 'nookies';
import { useInView } from 'react-intersection-observer';
import { Loading, Button } from '../../../LegacyV4Components';
import { useAuth } from '../../../../hooks/auth';

import { DashboardCard } from '../../molecules/DashboardCardCustomer';
import { DashboardViewer } from '../../molecules/DashboardViewer';
import { Dashboard } from '../../../../types/dashboard';
import { getUserPermission } from '../../../../services/requests/Dashboards/getUserPermission';

import * as S from './styles';
import { DashboardOperationModal } from '../../molecules/DashboardOperationModal';
import { dashboardsApi } from '../../../../services/api';

type IDisplayDashboard = Dashboard | undefined;

export function CustomerDashboards({ clientId }: { clientId?: string }) {
  const [displayDashboard, setDisplayDashboard] = useState<IDisplayDashboard>();
  const [favoritedDashboards, setFavoritedDashboards] = useState<string[]>([]);
  const [dashboards, setDashboards] = useState<Dashboard[]>();
  const [operationModal] = useState<'create' | 'edit'>('create');
  const [isModalOperationOpen, setIsModalOperationOpen] = useState(false);

  const { user } = useAuth();
  const isHeadquarterUser =
    user.unitId === `${process.env.REACT_APP_HEADQUARTER_ID}`;

  const cookies = parseCookies(undefined);
  const token = cookies['v4company.token'];

  const [userPermission, setUserPermission] = useState<string>('');

  const { ref, inView } = useInView();

  const { data, fetchNextPage, error, isLoading } = useInfiniteQuery(
    ['dashboards', clientId],
    async ({ pageParam = 1 }) => {
      const customersPromise = (await dashboardsApi({
        method: 'GET',
        url: `/dashboard/${clientId}/customer`,
        params: {
          q: '',
          page: pageParam,
          limit: 20,
        },
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => res.data)) as any;
      return customersPromise;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.page - 1 ?? undefined,
      getNextPageParam: (lastPage) =>
        lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  useMemo(() => {
    const auxDashboards: any[] = [];

    data?.pages?.map((page) => {
      if (!displayDashboard) {
        setDisplayDashboard(page.data[0]);
      }

      auxDashboards.push(...page.data);

      return;
    });

    setDashboards(auxDashboards);
  }, [data?.pages, displayDashboard]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  const handleCreateModalClick = () => {
    setIsModalOperationOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOperationOpen(false);
  };

  function handleUpdateFavorites(dashboardId: string) {
    if (favoritedDashboards.includes(dashboardId)) {
      setFavoritedDashboards(
        favoritedDashboards.filter((id) => id !== dashboardId),
      );
    } else {
      setFavoritedDashboards([...favoritedDashboards, dashboardId]);
    }
  }

  useEffect(() => {
    async function getUserPerm() {
      const permission = await getUserPermission(user?._id);
      setUserPermission(permission.roleName);
    }
    getUserPerm();
  });

  return (
    <>
      <S.Container>
        <S.Content>
          <header>
            <article>
              <h3>{displayDashboard?.name}</h3>
              <p>{displayDashboard?.description}</p>
            </article>

            {isHeadquarterUser ? null : (
              <Button
                color="green"
                size="default"
                onClick={handleCreateModalClick}
                variant="primary"
              >
                Adicionar Dashboard
              </Button>
            )}
          </header>

          {isLoading && (
            <S.Loading>
              <Loading containerStyles={{ width: '2rem' }} />
            </S.Loading>
          )}

          {!!error && <p>Erro ao carregar dashboards</p>}

          <main>
            <S.Cards>
              {dashboards?.map((dashboard) => (
                <DashboardCard
                  userPermission={userPermission}
                  isFavorited={favoritedDashboards.includes(dashboard._id)}
                  updateFavorites={handleUpdateFavorites}
                  dashboard={dashboard}
                  isSelected={dashboard._id === displayDashboard?._id}
                  onClick={() => setDisplayDashboard(dashboard)}
                  hideFavorite
                />
              ))}

              <span ref={ref} />
            </S.Cards>
          </main>
          <main>
            {displayDashboard && (
              <DashboardViewer dashboard={displayDashboard} />
            )}
          </main>
        </S.Content>
      </S.Container>
      <DashboardOperationModal
        isOpen={isModalOperationOpen}
        operation={operationModal}
        closeModal={handleCloseModal}
        clientId={clientId}
      />
    </>
  );
}
