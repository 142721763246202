import styled from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  background: transparent;
  border: 1px solid var(--grayscale-4);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem 0.5rem 0.75rem;
  transition: 0.5s;
  margin: 1rem 1rem 0;

  input {
    font-size: 1rem;
    font-family: 'Nunito Sans', sans-serif;
    color: var(--secondary-light);
    border: 0;
    flex: 1;
    width: 100%;

    &::placeholder {
      color: var(--secondary-light);
    }

    &:focus {
      outline: 0;
    }
  }

  svg {
    margin-left: 1rem;
    color: var(--grayscale-60);
    stroke: var(--grayscale-60);
  }

  input:-webkit-autofill::first-line,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px var(--tertiary-main) inset !important;
    -webkit-text-fill-color: var(--white) !important;
    font-size: 1rem;
    font-family: 'Nunito Sans', sans-serif;
  }
`;
