import styled from 'styled-components';

export const Collapse = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex-direction: column;

  border-top: 1px solid #8383af;
`;

export const CollapseHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ShowBtn = styled.button<{ show: boolean }>`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 300ms;

  transform: ${({ show }) => (show ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const CollapseHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 45px;
`;

export const CollapseBody = styled.div<{ show: boolean }>`
  width: 100%;
  padding: 15px;

  transition: max-height 1s ease-in-out;
  display: ${({ show }) => (show ? 'flex' : 'none')};
`;
