import styled from 'styled-components';

export const Container = styled.div`
  width: 100% - 3rem;
  padding: 2rem 0;

  > section {
    background: var(--white);
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 0.5rem;

    h1 {
      margin-bottom: 2rem;
    }
  }
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1100px;
  background: var(--white);
  overflow-x: auto;
  min-width: 100%;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid var(--terciary-light);
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
  border-bottom: 3px solid var(--tertiary-light);

  :first-of-type {
    font-size: 16px;
    line-height: 21px;
  }
`;

export const Field = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;

  img {
    margin-right: 0.5rem;
  }

  svg {
    cursor: pointer;
  }
`;

export const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const Search = styled.input``;

export const UnHappyPath = styled.div`
  align-self: center;
  margin-top: 1rem;
  text-align: center;
`;
