import { useEffect, useState } from 'react';
import { MdBookmarkBorder, MdOutlineBookmark } from 'react-icons/md';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LabDsContextMenu } from 'v4web-components-react';
import { Dashboard } from '../../../../services/dashboardsRequests';

import * as S from './styles';
import { DashboardOperationModal } from '../DashboardOperationModal';
import { DeleteDashboardModal } from '../DeleteDashboardModal';
import { dashboardsApi } from '../../../../services/api';

interface IDashboardPermissions {
  roleName: string;
  admin: boolean;
  view: boolean;
  edit: boolean;
  delete: boolean;
}

interface Props {
  dashboard: Dashboard;
  onClick?: () => void;
  isSelected?: boolean;
  userPermission?: IDashboardPermissions;
  isFavorited: boolean;
}

export function DashboardCard({
  dashboard,
  onClick,
  isSelected,
  userPermission,
  isFavorited,
}: Props) {
  const { name, description } = dashboard;
  const [isModalOperationOpen, setIsModalOperationOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSettedFavorite, setIsSettedFavorite] = useState<boolean>();

  function handleEdit() {
    setIsModalOperationOpen(true);
  }

  function handleCloseModal() {
    setIsModalOperationOpen(false);
  }

  function handleOpenDeleteModal() {
    setIsDeleteModalOpen(true);
  }

  function handleCloseDeleteModal() {
    setIsDeleteModalOpen(false);
  }

  const queryClient = useQueryClient();
  const mutation = useMutation(
    async () =>
      isFavorited
        ? dashboardsApi.delete(`/favorite/${dashboard._id}`)
        : dashboardsApi.post(`/favorite/${dashboard._id}`),
    {
      onSuccess: async () => {
        // cancela se outra query estiver rodando
        await queryClient.invalidateQueries(['dashboards']);
        await queryClient.refetchQueries(['dashboards']);
        await queryClient.refetchQueries(['favorites']);
      },
    },
  );

  async function handleFavorite() {
    mutation.mutate();
  }

  useEffect(() => {
    setIsSettedFavorite(isFavorited);
  }, [isFavorited]);

  const dashboardOptions: Array<{
    label: string;
    key: string;
    event: () => void;
  }> = [];

  if (userPermission?.edit) {
    dashboardOptions.push({ label: 'Editar', key: 'edit', event: handleEdit });
  }

  if (userPermission?.delete) {
    dashboardOptions.push({
      label: 'Excluir',
      key: 'delete',
      event: handleOpenDeleteModal,
    });
  }

  return (
    <S.DashboardCard isSelected={isSelected} onClick={onClick}>
      <section>
        <h5>{name}</h5>
        {isSettedFavorite ? (
          <MdOutlineBookmark className="bookMarker" onClick={handleFavorite} />
        ) : (
          <MdBookmarkBorder
            className="bookMarker"
            onClick={handleFavorite}
            color="#b4b4cf"
          />
        )}
      </section>

      <h6>Publicado por: {dashboard?.unit?.name} </h6>
      <p>{description}</p>

      {userPermission?.delete || userPermission?.edit ? (
        <div className="dot">
          <LabDsContextMenu items={dashboardOptions} listPosition="right" />
        </div>
      ) : null}

      <DashboardOperationModal
        isOpen={isModalOperationOpen}
        operation="edit"
        closeModal={handleCloseModal}
        dashboard={dashboard}
      />
      <DeleteDashboardModal
        isOpen={isDeleteModalOpen}
        closeModal={handleCloseDeleteModal}
        dashboard={dashboard}
      />
    </S.DashboardCard>
  );
}
