export interface IPaymentAccountCreation {
  unit_id: string;
  company_name: string;
  cnpj: string;
  address: string;
  cep: string;
  city: string;
  state: string;
  district: string;
  telephone: string;
  bank: string;
  bank_ag: string;
  account_type: string;
  bank_cc: string;
  resp_name: string;
  physical_products: boolean;
  business_type: string;
  person_type: string;
  automatic_transfer: boolean;
}

export const removeNaNFromString = (text: string) => {
  return text.replace(/\D+/g, '').trim();
};

export const banksOptions = [
  { key: 'Banco do Brasil', label: 'Banco do Brasil', isChecked: false },
  { key: 'Santander', label: 'Santander', isChecked: false },
  {
    key: 'Caixa Econômica',
    label: 'Caixa Econômica',
    isChecked: false,
  },
  { key: 'Bradesco/Next', label: 'Bradesco/Next', isChecked: false },
  { key: 'Itaú', label: 'Itaú', isChecked: false },
  { key: 'Agibank', label: 'Agibank', isChecked: false },
  { key: 'Banpará', label: 'Banpará', isChecked: false },
  { key: 'Banrisul', label: 'Banrisul', isChecked: false },
  { key: 'Sicoob', label: 'Sicoob', isChecked: false },
  { key: 'Inter', label: 'Inter', isChecked: false },
  { key: 'BRB', label: 'BRB', isChecked: false },
  {
    key: 'Via Credi/Civia Cooperativa',
    label: 'Via Credi/Civia Cooperativa',
    isChecked: false,
  },
  { key: 'Neon/Votorantim', label: 'Neon/Votorantim', isChecked: false },
  { key: 'Nubank', label: 'Nubank', isChecked: false },
  { key: 'Pagseguro', label: 'Pagseguro', isChecked: false },
  { key: 'Banco Original', label: 'Banco Original', isChecked: false },
  { key: 'Safra', label: 'Safra', isChecked: false },
  { key: 'Modal', label: 'Modal', isChecked: false },
  { key: 'Banestes', label: 'Banestes', isChecked: false },
  { key: 'Unicred', label: 'Unicred', isChecked: false },
  { key: 'Money Plus', label: 'Money Plus', isChecked: false },
  {
    key: 'Mercantil do Brasil',
    label: 'Mercantil do Brasil',
    isChecked: false,
  },
  { key: 'JP Morgan', label: 'JP Morgan', isChecked: false },
  {
    key: 'Gerencianet Pagamentos do Brasil',
    label: 'Gerencianet Pagamentos do Brasil',
    isChecked: false,
  },
  { key: 'Banco C6', label: 'Banco C6', isChecked: false },
  { key: 'BS2', label: 'BS2', isChecked: false },
  { key: 'Banco Topazio', label: 'Banco Topazio', isChecked: false },
  { key: 'Uniprime', label: 'Uniprime', isChecked: false },
  { key: 'Stone', label: 'Stone', isChecked: false },
  { key: 'Rendimento', label: 'Rendimento', isChecked: false },
  { key: 'Banco Daycoval', label: 'Banco Daycoval', isChecked: false },
  { key: 'Banco do Nordeste', label: 'Banco do Nordeste', isChecked: false },
  { key: 'Citibank', label: 'Citibank', isChecked: false },
  { key: 'DOCK IP S.A.', label: 'DOCK IP S.A.', isChecked: false },
  {
    key: 'Cooperativa Central de Credito Noroeste Brasileiro',
    label: 'Cooperativa Central de Credito Noroeste Brasileiro',
    isChecked: false,
  },
  {
    key: 'Uniprime Norte do Paraná',
    label: 'Uniprime Norte do Paraná',
    isChecked: false,
  },
  { key: 'Global SCM', label: 'Global SCM', isChecked: false },
  { key: 'Cora', label: 'Cora', isChecked: false },
  { key: 'Mercado Pago', label: 'Mercado Pago', isChecked: false },
  { key: 'Banco da Amazonia', label: 'Banco da Amazonia', isChecked: false },
  { key: 'BNP Paribas Brasil', label: 'BNP Paribas Brasil', isChecked: false },
  { key: 'Juno', label: 'Juno', isChecked: false },
  { key: 'Cresol', label: 'Cresol', isChecked: false },
  { key: 'BRL Trust DTVM', label: 'BRL Trust DTVM', isChecked: false },
  { key: 'Banco Banese', label: 'Banco Banese', isChecked: false },
  { key: 'Banco BTG Pactual', label: 'Banco BTG Pactual', isChecked: false },
  { key: 'Banco Omni', label: 'Banco Omni', isChecked: false },
  {
    key: 'Acesso Soluções de pagamento',
    label: 'Acesso Soluções de pagamento',
    isChecked: false,
  },
  {
    key: 'CCR de São Miguel do Oeste',
    label: 'CCR de São Miguel do Oeste',
    isChecked: false,
  },
  { key: 'Polocred', label: 'Polocred', isChecked: false },
  { key: 'Ótimo', label: 'Ótimo', isChecked: false },
  { key: 'Picpay', label: 'Picpay', isChecked: false },
  { key: 'Banco Genial', label: 'Banco Genial', isChecked: false },
  { key: 'Banco Capital S.A', label: 'Banco Capital S.A', isChecked: false },
  { key: 'Sicredi', label: 'Sicredi', isChecked: false },
  {
    key: 'Banco Ribeirão Preto',
    label: 'Banco Ribeirão Preto',
    isChecked: false,
  },
  { key: 'ASAAS IP', label: 'ASAAS IP', isChecked: false },
  { key: 'Banco Pan', label: 'Banco Pan', isChecked: false },
  { key: 'Neon', label: 'Neon', isChecked: false },
  { key: 'VORTX DTVM LTDA', label: 'VORTX DTVM LTDA', isChecked: false },
  { key: 'Banco BMG', label: 'Banco BMG', isChecked: false },
  { key: 'Fitbank', label: 'Fitbank', isChecked: false },
  { key: 'Pefisa', label: 'Pefisa', isChecked: false },
  { key: 'J17 - SCD S/A', label: 'J17 - SCD S/A', isChecked: false },
  { key: 'Credisan', label: 'Credisan', isChecked: false },
  { key: 'Pinbank', label: 'Pinbank', isChecked: false },
  { key: 'XP Investimentos', label: 'XP Investimentos', isChecked: false },
  { key: 'Crefisa', label: 'Crefisa', isChecked: false },
  { key: 'Singulare', label: 'Singulare', isChecked: false },
  { key: 'SUMUP SCD S.A.', label: 'SUMUP SCD S.A.', isChecked: false },
  { key: 'Banco ABC Brasil', label: 'Banco ABC Brasil', isChecked: false },
  { key: 'Banco Letsbank S.A', label: 'Banco Letsbank S.A', isChecked: false },
  {
    key: 'HR Digital Sociedade de Crédito Direto S.A',
    label: 'HR Digital Sociedade de Crédito Direto S.A',
    isChecked: false,
  },
  { key: 'Banco XP S.A.', label: 'Banco XP S.A.', isChecked: false },
  {
    key: 'Neon Pagamentos S.A. IP',
    label: 'Neon Pagamentos S.A. IP',
    isChecked: false,
  },
  {
    key: 'CLOUD WALK MEIOS DE PAGAMENTOS E SERVICOS LTDA',
    label: 'CLOUD WALK MEIOS DE PAGAMENTOS E SERVICOS LTDA',
    isChecked: false,
  },
  { key: 'BCO SOFISA S.A.', label: 'BCO SOFISA S.A.', isChecked: false },
  { key: 'BANCO BV S.A.', label: 'BANCO BV S.A.', isChecked: false },
  {
    key: 'CELCOIN INSTITUICAO DE PAGAMENTO S.A.',
    label: 'CELCOIN INSTITUICAO DE PAGAMENTO S.A.',
    isChecked: false,
  },
  {
    key: 'SUPERDIGITAL I.P. S.A.',
    label: 'SUPERDIGITAL I.P. S.A.',
    isChecked: false,
  },
  { key: 'BANCO FIBRA S.A.', label: 'BANCO FIBRA S.A.', isChecked: false },
  { key: 'UY3 SCD S/A', label: 'UY3 SCD S/A', isChecked: false },
  {
    key: 'QI Sociedade de Crédito',
    label: 'QI Sociedade de Crédito',
    isChecked: false,
  },
  { key: 'LISTO SCD S.A', label: 'LISTO SCD S.A', isChecked: false },
  {
    key: 'ID CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A',
    label: 'ID CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A',
    isChecked: false,
  },
  { key: 'CARTOS SCD S.A.', label: 'CARTOS SCD S.A.', isChecked: false },
];

export const accountsTypesOptions = [
  { key: 'Corrente', label: 'Corrente', isChecked: false },
  { key: 'Poupança', label: 'Poupança', isChecked: false },
  { key: 'Pagamento', label: 'Pagamento', isChecked: false },
];

/* Função para formatar os erros retornados pela API do Iugu em uma string para ser utilizada no toast de erro. */
/* eslint-disable no-param-reassign */
export const formatErrorMessage = (message: string) => {
  const parsed = JSON.parse(message || '{}');
  const formatedMessage = Object.entries(parsed).reduce(
    (acc, curr: [field: string, messages: any]) => {
      const [field, messages = []] = curr;
      acc += `${field} : ${messages?.join(' - ')}`;
      return acc;
    },
    '',
  );
  return formatedMessage;
};
