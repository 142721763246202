import { ReactNode } from 'react';
import { MdRemoveCircleOutline } from 'react-icons/md';
import { Input } from '../../../../LegacyV4Components';

import {
  CustomerIntegrationsContextData,
  CustomerIntegrationsProvider,
} from '../../../../../hooks/customerIntegrations';

import { TieAccountsModal } from '../TieAccountsModal';

import { trayEcommerce as options } from '../platforms';

import * as Edit from '../components/IntegrationEditRow/styles';
import * as Summary from '../components/IntegrationSummaryRow/styles';

interface TrayEcommerceModalProps {
  show: boolean;
  onClose: () => void;
}

const trayRegex = /[0-9a-fA-F]{64}/;
const linkRegex =
  /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

function testRegex(integrationId: string): boolean {
  return trayRegex.test(integrationId);
}

function testLinkRegex(link?: string): boolean {
  return !!link && linkRegex.test(link);
}

export function ConfigTrayEcommerceModal({
  show,
  onClose,
}: TrayEcommerceModalProps) {
  const tableColumns = [
    {
      accessor: 'customer',
      label: 'Cliente',
      sizing: 25,
      sizingUnit: 'vw',
      sortable: true,
    },
    {
      accessor: 'url',
      label: 'Url do cliente',
      sizing: 1,
      sortable: true,
    },
    {
      accessor: 'code',
      label: 'Código',
      sizing: 1,
      sortable: true,
    },
    {
      accessor: 'status',
      label: 'Status',
      sizing: 0.5,
      sortable: false,
    },
  ];

  function isConnected(integration: AdsAccounts): boolean {
    return (
      testRegex(integration.integrationId) &&
      testLinkRegex(integration.link) &&
      !!integration.projectId
    );
  }

  function additionalValidation(integration: AdsAccounts): boolean {
    return isConnected(integration);
  }

  function renderEditRow(
    integration: AdsAccounts,
    error: boolean,
    context: CustomerIntegrationsContextData,
  ): ReactNode {
    const { setIntegrationId, setLink, deleteIntegration } = context;
    const linkError = error && !testLinkRegex(integration.link);
    const tokenError = error && !testRegex(integration.integrationId);
    return (
      <>
        <div title={integration.link}>
          <Input
            label=""
            value={integration.link}
            name="link"
            onChange={({ target: { value } }) => setLink(integration, value)}
            borderColor={linkError ? 'var(--error)' : ''}
            placeholder="https://www.lojadocliente.com.br"
          />
          {linkError && <Edit.Error>Link inválido</Edit.Error>}
        </div>
        <Edit.Row title={integration.integrationId}>
          <div>
            <Input
              label=""
              value={integration.integrationId}
              name="integrationId"
              onChange={({ target: { value } }) =>
                setIntegrationId(integration, value)
              }
              className={tokenError ? 'error' : ''}
              placeholder="10ef466c90542cba4f89f85eac8818df06152305bd6bae8778a5a07397b6440b"
              mask="****************************************************************"
            />
            {tokenError && <Edit.Error>ID inválido</Edit.Error>}
          </div>
          <MdRemoveCircleOutline
            size={22}
            onClick={() => deleteIntegration(integration)}
          />
        </Edit.Row>
      </>
    );
  }

  function renderSummaryRow(integration: AdsAccounts): ReactNode {
    return (
      <>
        <td title={integration.link}>
          <Summary.IntegrationParagraph>
            {integration.link}
          </Summary.IntegrationParagraph>
        </td>
        <td title={integration.integrationId}>
          <Summary.IntegrationParagraph>
            {integration.integrationId}
          </Summary.IntegrationParagraph>
        </td>
      </>
    );
  }

  return (
    <CustomerIntegrationsProvider type={options.type} show={show}>
      <TieAccountsModal
        options={options}
        show={show}
        onClose={onClose}
        tableColumns={tableColumns}
        renderEditRow={renderEditRow}
        renderSummaryRow={renderSummaryRow}
        isConnected={isConnected}
        additionalValidation={additionalValidation}
      />
    </CustomerIntegrationsProvider>
  );
}
