import * as Yup from 'yup';
import { useState } from 'react';
import { Formik } from 'formik';
import { FiArrowRight, FiX } from 'react-icons/fi';
import { CircularProgress } from '@mui/material';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query';
import { Button, Headline } from '../../../LegacyV4Components';
import * as S from './styles';
import { Modal } from '../../../Default/Modal';
import { useToast } from '../../../../hooks/toast';
import { TextField } from '../TextField';
import { EditProductHQ } from '../../../../services/requests/Finance/createProduct';

export function EditProductCheckout({
  isShown,
  setIsShown,
  data,
  productType,
  refetch,
  productId,
}: {
  isShown: boolean;
  setIsShown: (value: React.SetStateAction<boolean>) => void;
  data?: any;
  productType?: string;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<any, any>>;
  productId: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();
  const createProductSchema = Yup.object().shape({
    physicalProduct: Yup.boolean(),
    name: Yup.string().required('Insira o nome do produto'),
    description: Yup.string().required('Insira a descrição do produto'),
  });
  return (
    <Formik
      enableReinitialize
      initialValues={{
        physicalProduct: data?.physicalProduct || false,
        name: `${data?.name || ''}`,
        description: `${data?.description || ''}`,
      }}
      validationSchema={createProductSchema}
      onSubmit={() => {
        setIsShown(false);
      }}
    >
      {({ errors, values, handleBlur, isValid, dirty, setFieldValue }) => {
        async function handleSubmit() {
          const dataToSend = {
            ...values,
            productType,
          };
          setIsLoading(true);
          const editProduct = await EditProductHQ(dataToSend, productId);

          setIsLoading(false);
          if (editProduct === false) {
            addToast({
              title: 'Ocorreu um erro!',
              description: 'Ocorreu um erro durante a edição do seu produto',
              type: 'error',
            });
          }
          if (editProduct) {
            refetch();
            addToast({
              title: 'Produto editado com sucesso!',
              type: 'success',
            });
          }
          editProduct !== false && setIsShown(false);
        }
        return (
          <>
            <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
              <S.ModalWrapper>
                <S.ModalHeader>
                  <Headline variant="h4">Editar produto</Headline>
                  <button
                    type="button"
                    onClick={() => setIsShown(false)}
                    aria-label="default"
                  >
                    <FiX />
                  </button>
                </S.ModalHeader>
                <S.ModalBody>
                  <small>Todos os campos são obrigatórios</small>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Headline variant="h6">Produto físico?</Headline>
                    <S.ContainerProductType>
                      <S.ContainerRadio
                        color={
                          values.physicalProduct ? '1px solid #00C49A' : ''
                        }
                        onClick={() => {
                          setFieldValue('physicalProduct', true);
                        }}
                      >
                        <input
                          name="physicalProduct"
                          type="radio"
                          checked={values?.physicalProduct === true}
                          onChange={() => {
                            setFieldValue('physicalProduct', true);
                          }}
                        />
                        <p>Sim</p>
                      </S.ContainerRadio>
                      <S.ContainerRadio
                        color={
                          !values.physicalProduct ? '1px solid #00C49A' : ''
                        }
                        onClick={() => {
                          setFieldValue('physicalProduct', false);
                        }}
                      >
                        <input
                          name="physicalProduct"
                          type="radio"
                          checked={values?.physicalProduct === false}
                          onChange={() => {
                            setFieldValue('physicalProduct', false);
                          }}
                        />
                        <p>Não</p>
                      </S.ContainerRadio>
                    </S.ContainerProductType>
                  </div>
                  <TextField
                    status="default"
                    name="name"
                    label="Nome do produto"
                    value={values.name}
                    placeholder="Nome do produto"
                    helper={errors.name}
                    onBlur={handleBlur}
                  />
                  <strong>Descrição</strong>
                  <textarea
                    style={{
                      resize: 'none',
                      minHeight: '112px',
                      padding: '12px 16px',
                      border: '1px solid #E6E6EF',
                      borderRadius: '3px',
                      fontFamily: 'Nunito Sans',
                    }}
                    rows={10}
                    name="description"
                    placeholder="Descrição"
                    value={values?.description}
                    onChange={(value) =>
                      setFieldValue('description', value.target.value)
                    }
                  />
                </S.ModalBody>
                <S.ModalFooter>
                  <Button
                    variant="primary"
                    color="green"
                    size="default"
                    disabled={!(isValid && dirty)}
                    rightIcon={
                      !isLoading ? (
                        <FiArrowRight />
                      ) : (
                        <S.ContainerLoading>
                          <CircularProgress
                            sx={{ width: '1rem', height: '1rem' }}
                          />
                        </S.ContainerLoading>
                      )
                    }
                    onClick={async () => {
                      handleSubmit();
                    }}
                  >
                    Salvar alterações
                  </Button>
                </S.ModalFooter>
              </S.ModalWrapper>
            </Modal>
          </>
        );
      }}
    </Formik>
  );
}
