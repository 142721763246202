import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 0.3rem;
  border-top-left-radius: 0;
  width: 68%;
  margin: 10% auto;
  @media (max-width: 880px) {
    width: auto;
    margin: 10% 1rem;
  }
`;

export const ApprovedPayment = styled.section`
  display: flex;

  background: #fff;
  border: 1px solid #dadae7;
  width: 100%;
  border-radius: 0.25rem;
  margin-bottom: 2rem;
  padding: 1rem;

  svg {
    margin-right: 1rem;
  }
  p {
    font-weight: 700;
  }
`;

export const ConfirmedPayment = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  text-align: center;

  background: #fff;
  border: 1px solid #dadae7;
  width: 100%;
  border-radius: 0.25rem;
  margin-bottom: 2rem;
  padding: 5rem 1rem;

  @media (max-width: 880px) {
    padding: 1rem;
  }

  svg {
    width: 3rem;
    height: 3rem;
  }
  p {
    font-weight: 700;
    font-size: 16px;
  }
  small {
    color: #7f7f7f;
    font-size: 12px;
  }
`;

export const ContainerInfo = styled.div`
  width: 100%;
  padding: 16px;
  background: #fff;
  border: 1px solid #b4b4cf;
  border-radius: 4px;
  margin-bottom: 20px;
  svg {
    margin-right: 1rem;
  }

  h5 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
  }

  .your-request {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #b4b4cf;
    padding: 16px 10px 8px;
    img {
      margin-right: 1rem;
      width: 24px;
      height: 24px;
    }
  }

  .your-product {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    padding: 16px 10px 0px;
    h5 {
      border-bottom: none;
    }
  }

  section > p {
    padding: 10px 8px;
    font-size: 12px;
    color: #7f7f7f;
  }
`;

export const Details = styled.div`
  margin-top: 24px;
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
  }
  small {
    color: #7f7f7f;
    font-size: 12px;
  }
`;
