import styled from 'styled-components';

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(240, 240, 240, 0.4);
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
`;

export const Content = styled.div`
  position: fixed;
  top: 25%;
  left: 25%;
  background: var(--lab-ds-semantic-color-bg-second-level, #fafafa);
  width: 44rem;
  padding: 4rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9;
  border-radius: 0.375rem;
  box-shadow: 0rem 0.063rem 0.188rem 0rem rgba(0, 0, 0, 0.12),
    0rem 0.25rem 0.5rem 0.188rem rgba(0, 0, 0, 0.06);

  lab-ds-icon-not-selectable {
    position: absolute;
    top: 1rem;
    right: 1rem;

    cursor: pointer;
  }

  lab-ds-text-field {
    width: 23.1875rem;
  }

  h5 {
    font: var(--lab-ds-semantic-typography-heading-h5);
  }

  p {
    text-align: center;
    margin: 1rem 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  gap: 1.5rem;
`;
