import * as S from './styles';

interface ModalLoadingProps {
  rows: number;
}

export function ModalLoading({ rows = 1 }: ModalLoadingProps) {
  const arrayOfRows = Array.from({ length: rows }, (_, i) => i + 1);

  return (
    <S.Container>
      {arrayOfRows.map((number) => (
        <S.Row key={number}>
          <S.InputLoading />
          <S.InputLoading />
        </S.Row>
      ))}
    </S.Container>
  );
}
