import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';

import { Avatar } from '@mui/material';
import { Headline } from '../../../LegacyV4Components';
import { Modal } from '../../../Default/Modal';

import * as S from './styles';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';
import { EditPlanModal } from '../EditPlanModal';

export function HistoricPlanModal({
  isShown,
  setIsShown,
  data,
}: {
  isShown: boolean;
  setIsShown(value: boolean): any;
  data: any;
}) {
  const [showEditPlanModal, setShowEditPlanModal] = useState(false);

  return (
    <>
      <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
        <>
          <S.ModalWrapper>
            <S.ModalHeader>
              <Headline variant="h4">Histórico de edição</Headline>
              <button
                type="button"
                onClick={() => setIsShown(false)}
                aria-label="default"
              >
                <FiX />
              </button>
            </S.ModalHeader>
            <S.ModalBody>
              <S.Title>
                <Headline variant="h6">
                  Veja abaixo todas as vezes que foram alteradas as informações
                  referente ao plano
                </Headline>
              </S.Title>
              <S.ContainerHistoric>
                <S.Row>
                  <S.Row
                    style={{
                      marginBottom: 0,
                      marginTop: 0,
                      alignItems: 'center',
                    }}
                  >
                    <S.AvatarContainer>
                      <Avatar src={data.picture} alt={data.picture} />
                    </S.AvatarContainer>
                    <S.Title>
                      <Headline className="title" variant="h6">
                        João Silva
                      </Headline>
                      <span className="unit">Matriz</span>
                    </S.Title>
                  </S.Row>
                  <S.Title style={{ textAlign: 'right' }}>
                    <Headline className="title" variant="h6">
                      Data: <span>20 de mar. 2023</span>
                    </Headline>
                  </S.Title>
                </S.Row>
                <S.List>
                  <ul>
                    <li>
                      Alterou o tipo de plano de: <span>“Setup”</span> para:{' '}
                      <span>“Scaleup”</span>{' '}
                    </li>
                    <li>
                      Alterou o valor do plano de:{' '}
                      <span>{convertNumberToBRL(5000)}</span> para:{' '}
                      <span>{convertNumberToBRL(6000)}</span>
                    </li>
                    <li>
                      Alterou o dia de cobrança de: <span>Dia 10</span> para:{' '}
                      <span>Dia 15</span>
                    </li>
                  </ul>
                </S.List>
              </S.ContainerHistoric>
            </S.ModalBody>
            <S.ModalFooter />
          </S.ModalWrapper>
        </>
      </Modal>
      <EditPlanModal
        isShown={showEditPlanModal}
        setIsShown={setShowEditPlanModal}
        data={{}}
      />
    </>
  );
}
