export const permissionsUser = [
  {
    label: 'Visualizar',
    value: 'READ_ONLY',
    desc: 'Permissão para visualizar apenas os usuários',
  },
  {
    label: 'Não Visualizar',
    value: 'CAN_NOT_SEE',
    desc: 'Não tem permissão para visualizar o ambiente de usuários',
  },
  {
    label: 'Criar e Editar',
    value: 'WRITE_AND_READ',
    desc: 'Permissão para criar e editar os usuários',
  },
  {
    label: 'Adminstrar',
    value: 'ADMIN',
    desc: 'Permissão para administrar os usuários.',
  },
];
