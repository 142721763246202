import { parseCookies } from 'nookies';
import axios from 'axios';
import { FormikSubscriptionOptions } from '../../../pages/SubscriptionOptions';
import {
  IPaymentAttemptErrorProps,
  paymentAttemptErrors,
} from '../../../utils/IuguPaymentAttemptErrors';
import { federationRoiHuntersApi } from '../../api';

interface ErrorProps {
  errors: string;
  LR: string;
}

interface ErrorProps {
  errors: string;
  LR: string;
}

export interface IResponseCreateSubscriptions {
  data: IDataCreateSubscriptions;
  status: number;
  statusText: string;
  headers: Headers;
  request: Request;
}

export interface IDataCreateSubscriptions {
  message?: string;
  success?: boolean;
  url?: string;
  pdf?: string;
  identification?: any;
  invoiceId?: string;
  LR?: string;
  reversible?: any;
  token?: string;
  brand?: string;
  bin?: number;
  isSuccess?: boolean;
  err?: any;
  errMessage?: string;
}

export async function createSubscription(
  data: FormikSubscriptionOptions,
  unitId: string,
  subscriptionId: string,
): Promise<IDataCreateSubscriptions> {
  const dataToSend = {
    email: data.email,
    paymentCardToken: {
      firstName: data.paymentCardToken.first_name,
      lastName: data.paymentCardToken.last_name,
      number: data.paymentCardToken.number,
      month: data.paymentCardToken.month,
      year: data.paymentCardToken.year,
      verificationValue: data?.paymentCardToken?.verification_value,
    },
  };
  try {
    const response = await federationRoiHuntersApi.post(
      `/subscriptions/generate-payment/assessoria-${unitId}/${subscriptionId}`,
      dataToSend,
    );
    return response?.data as IDataCreateSubscriptions;
  } catch (err) {
    return err;
  }
}

export async function createSubscriptionV2(
  data: FormikSubscriptionOptions,
): Promise<IDataCreateSubscriptions> {
  const cookies = parseCookies(undefined);
  const token = cookies['v4company.token'];
  const headers = token
    ? {
        Authorization: `Bearer ${cookies['v4company.token']}`,
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': 'POST, GET',
      }
    : undefined;

  const financeApiV2 = axios.create({
    baseURL: 'https://finance.roihunters.dev.mktlab.app',
    headers,
  });
  try {
    await financeApiV2.post(`/subscriptions/create`, data);
    return {
      isSuccess: true,
    };
  } catch (err) {
    const stringError = String(err.response?.data?.message);
    const irLRError = stringError.includes('LR');
    const isSuccess = false;
    const defaultMessage = 'Erro ao realizar pagamento';

    if (irLRError) {
      const responseError: ErrorProps = JSON.parse(err.response.data.message);
      const errorFounded = paymentAttemptErrors.find(
        (error: IPaymentAttemptErrorProps) => error.LR === responseError.LR,
      );

      return {
        isSuccess,
        errMessage: errorFounded?.message || defaultMessage,
      };
    }

    return { isSuccess, errMessage: defaultMessage };
  }
}
