export const animation = {
  v: '5.6.5',
  fr: 29.9700012207031,
  ip: 0,
  op: 155.000006313279,
  w: 800,
  h: 800,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Little cirlce 2',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.847,
                y: 0.759,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 20,
              s: [261, 510, 0],
              to: [0.833, 9.5, 0],
              ti: [-3.833, -13.083, 0],
            },
            {
              i: {
                x: 0.667,
                y: 0.923,
              },
              o: {
                x: 0.394,
                y: 0.176,
              },
              t: 62,
              s: [266, 567, 0],
              to: [3.833, 13.083, 0],
              ti: [-10.5, -3.583, 0],
            },
            {
              i: {
                x: 0.667,
                y: 0.796,
              },
              o: {
                x: 0.333,
                y: 0.476,
              },
              t: 66,
              s: [284, 588.5, 0],
              to: [9.389, 3.204, 0],
              ti: [-13.629, 2.527, 0],
            },
            {
              i: {
                x: 0.667,
                y: 0.259,
              },
              o: {
                x: 0.333,
                y: 0.977,
              },
              t: 98,
              s: [324.338, 589.517, 0],
              to: [1.612, -0.299, 0],
              ti: [-1.49, 0.379, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0.207,
              },
              t: 116,
              s: [329, 588.5, 0],
              to: [14.083, -3.583, 0],
              ti: [-6.583, 3.583, 0],
            },
            {
              t: 165.000006720588,
              s: [368.5, 567, 0],
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [-143.023, -4.932, 0],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.25, 0.25, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.333, 0.333, 0.333],
                y: [0, 0, 0],
              },
              t: 24,
              s: [0, 0, 100],
            },
            {
              i: {
                x: [0.25, 0.25, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 42,
              s: [81.106, 81.106, 100],
            },
            {
              i: {
                x: [0.25, 0.25, 0.833],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 111,
              s: [81.106, 81.106, 100],
            },
            {
              t: 135.000005498663,
              s: [0, 0, 100],
            },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 0,
                k: [89.234, 89.234],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.047058823529, 0.054901960784, 0.062745098039, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 2,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.890196078431, 0.141176470588, 0.219607843137, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [-144.383, -6.383],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 0,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Little cirlce 3',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 0.506,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 33,
              s: [632.5, 364.5, 0],
              to: [0, 5.25, 0],
              ti: [12.213, -15.765, 0],
            },
            {
              i: {
                x: 0.702,
                y: 0.93,
              },
              o: {
                x: 0.333,
                y: 0.136,
              },
              t: 52,
              s: [632.5, 396, 0],
              to: [-12.213, 15.765, 0],
              ti: [20.375, -12.208, 0],
            },
            {
              i: {
                x: 0.609,
                y: 0.73,
              },
              o: {
                x: 0.256,
                y: 0.386,
              },
              t: 67,
              s: [559.221, 459.093, 0],
              to: [-10.433, 6.251, 0],
              ti: [8.808, -0.758, 0],
            },
            {
              i: {
                x: 0.69,
                y: 1,
              },
              o: {
                x: 0.323,
                y: 0.619,
              },
              t: 98,
              s: [529.531, 468.17, 0],
              to: [-8.394, 0.722, 0],
              ti: [3.983, -0.826, 0],
            },
            {
              t: 151.000006150356,
              s: [510.25, 469.25, 0],
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [-143.023, -7.398, 0],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.25, 0.25, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.333, 0.333, 0.333],
                y: [0, 0, 0],
              },
              t: 18,
              s: [0, 0, 100],
            },
            {
              i: {
                x: [0.25, 0.25, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 36,
              s: [81.106, 81.106, 100],
            },
            {
              i: {
                x: [0.25, 0.25, 0.833],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 111,
              s: [81.106, 81.106, 100],
            },
            {
              t: 135.000005498663,
              s: [0, 0, 100],
            },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 0,
                k: [89.234, 89.234],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.047058823529, 0.054901960784, 0.062745098039, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 2,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.890196078431, 0.141176470588, 0.219607843137, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [-144.383, -6.383],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 0,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -51.0000020772726,
      op: 849.000034580479,
      st: -51.0000020772726,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Little cirlce Top',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 0.092,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 17,
              s: [346, 196.5, 0],
              to: [-7.75, -2.417, 0],
              ti: [12.167, -0.708, 0],
            },
            {
              i: {
                x: 0.667,
                y: 0.9,
              },
              o: {
                x: 0.333,
                y: 0.166,
              },
              t: 48,
              s: [299.5, 182, 0],
              to: [-12.167, 0.708, 0],
              ti: [5.25, -12.625, 0],
            },
            {
              i: {
                x: 0.617,
                y: 0.87,
              },
              o: {
                x: 0.256,
                y: 0.593,
              },
              t: 52,
              s: [273, 200.75, 0],
              to: [-4.459, 10.724, 0],
              ti: [0.376, -13.644, 0],
            },
            {
              i: {
                x: 0.688,
                y: 1,
              },
              o: {
                x: 0.336,
                y: 1,
              },
              t: 98,
              s: [268.274, 251.894, 0],
              to: [-0.067, 2.419, 0],
              ti: [0.126, -1.431, 0],
            },
            {
              t: 144.00000586524,
              s: [268, 257.75, 0],
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [-130.693, 9.864, 0],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.25, 0.25, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.333, 0.333, 0.333],
                y: [0, 0, 0],
              },
              t: 18,
              s: [0, 0, 100],
            },
            {
              i: {
                x: [0.25, 0.25, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 36,
              s: [81.106, 81.106, 100],
            },
            {
              i: {
                x: [0.25, 0.25, 0.833],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 111,
              s: [81.106, 81.106, 100],
            },
            {
              t: 135.000005498663,
              s: [0, 0, 100],
            },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 0,
                k: [89.234, 89.234],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.047058823529, 0.054901960784, 0.062745098039, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 2,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.890196078431, 0.141176470588, 0.219607843137, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [-144.383, -6.383],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 0,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: -51.0000020772726,
      op: 849.000034580479,
      st: -51.0000020772726,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'center_circle',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [401, 389, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [-13.063, -22.86, 0],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.25, 0.25, 0.833],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 111,
              s: [81.106, 81.106, 100],
            },
            {
              t: 135.000005498663,
              s: [0, 0, 100],
            },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 0,
                k: [77.344, 77.344],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.898038976333, 0.223528992896, 0.207843002619, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 0,
                k: 0,
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.890196078431, 0.141176470588, 0.219607843137, 1],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [-14.328, -25.328],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 9,
                    s: [0, 0],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 30,
                    s: [160, 160],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 46,
                    s: [70, 70],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 58,
                    s: [130, 130],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 69,
                    s: [80, 80],
                  },
                  {
                    i: {
                      x: [0.667, 0.667],
                      y: [1, 1],
                    },
                    o: {
                      x: [0.333, 0.333],
                      y: [0, 0],
                    },
                    t: 83,
                    s: [110, 110],
                  },
                  {
                    t: 101.000004113814,
                    s: [100, 100],
                  },
                ],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'circle3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 118,
              s: [100],
            },
            {
              t: 128.000005213547,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: -120.543,
          ix: 10,
        },
        p: {
          a: 0,
          k: [406, 375, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [104.627, 108.478, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 0,
                k: [497.445, 195.844],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.333],
                      y: [0],
                    },
                    t: 49,
                    s: [100],
                  },
                  {
                    t: 95.0000038694293,
                    s: [0],
                  },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.333],
                      y: [0],
                    },
                    t: 49,
                    s: [100],
                  },
                  {
                    t: 95.0000038694293,
                    s: [100],
                  },
                ],
                ix: 2,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.69],
                      y: [3.268],
                    },
                    o: {
                      x: [0.294],
                      y: [0],
                    },
                    t: 49,
                    s: [1886.781],
                  },
                  {
                    t: 100.000004073084,
                    s: [1765.781],
                  },
                ],
                ix: 3,
              },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.105882354081, 0.121568627656, 0.141176477075, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.667],
                      y: [0.936],
                    },
                    o: {
                      x: [0.333],
                      y: [0],
                    },
                    t: 40,
                    s: [0],
                  },
                  {
                    i: {
                      x: [0.667],
                      y: [1.13],
                    },
                    o: {
                      x: [0.333],
                      y: [-0.043],
                    },
                    t: 59,
                    s: [23.039],
                  },
                  {
                    i: {
                      x: [0.667],
                      y: [1],
                    },
                    o: {
                      x: [0.333],
                      y: [0.158],
                    },
                    t: 70,
                    s: [3],
                  },
                  {
                    t: 84.0000034213901,
                    s: [24],
                  },
                ],
                ix: 5,
              },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [-6.277, -10.078],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [89.823, 86.077],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'circle2',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 118,
              s: [100],
            },
            {
              t: 128.000005213547,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: -59.94,
          ix: 10,
        },
        p: {
          a: 0,
          k: [413, 385, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [104.627, 108.478, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 0,
                k: [497.445, 195.844],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.667],
                      y: [1],
                    },
                    o: {
                      x: [0.333],
                      y: [0],
                    },
                    t: 31,
                    s: [100],
                  },
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.333],
                      y: [-0.084],
                    },
                    t: 58,
                    s: [84.162],
                  },
                  {
                    t: 89.0000036250443,
                    s: [100],
                  },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.324],
                      y: [0],
                    },
                    t: 31,
                    s: [100],
                  },
                  {
                    t: 89.0000036250443,
                    s: [0],
                  },
                ],
                ix: 2,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.667],
                      y: [0.953],
                    },
                    o: {
                      x: [0.327],
                      y: [0],
                    },
                    t: 31,
                    s: [50],
                  },
                  {
                    t: 90.0000036657751,
                    s: [2101.915],
                  },
                ],
                ix: 3,
              },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.105882354081, 0.121568627656, 0.141176477075, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.667],
                      y: [0.91],
                    },
                    o: {
                      x: [0.333],
                      y: [0],
                    },
                    t: 45,
                    s: [0],
                  },
                  {
                    i: {
                      x: [0.667],
                      y: [1.258],
                    },
                    o: {
                      x: [0.333],
                      y: [-0.085],
                    },
                    t: 60,
                    s: [13],
                  },
                  {
                    i: {
                      x: [0.667],
                      y: [1],
                    },
                    o: {
                      x: [0.333],
                      y: [0.183],
                    },
                    t: 72,
                    s: [2],
                  },
                  {
                    t: 89.0000036250443,
                    s: [24],
                  },
                ],
                ix: 5,
              },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [-7.277, -10.078],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [89.823, 86.077],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'circle1',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [0.833],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 118,
              s: [100],
            },
            {
              t: 128.000005213547,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [407, 397, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [104.627, 108.478, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {
                a: 0,
                k: [497.445, 195.844],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.167],
                      y: [0],
                    },
                    t: -15,
                    s: [100],
                  },
                  {
                    i: {
                      x: [0.667],
                      y: [1],
                    },
                    o: {
                      x: [0.333],
                      y: [0],
                    },
                    t: 38,
                    s: [100],
                  },
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.333],
                      y: [0.01],
                    },
                    t: 77,
                    s: [55.162],
                  },
                  {
                    t: 90.0000036657751,
                    s: [0],
                  },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.167],
                      y: [0],
                    },
                    t: -15,
                    s: [100],
                  },
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.333],
                      y: [0],
                    },
                    t: 38,
                    s: [100],
                  },
                  {
                    t: 88.0000035843135,
                    s: [100],
                  },
                ],
                ix: 2,
              },
              o: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.167],
                      y: [0.167],
                    },
                    t: -15,
                    s: [100],
                  },
                  {
                    i: {
                      x: [0.714],
                      y: [0.721],
                    },
                    o: {
                      x: [0.303],
                      y: [0],
                    },
                    t: 38,
                    s: [50],
                  },
                  {
                    t: 89.0000036250443,
                    s: [958.781],
                  },
                ],
                ix: 3,
              },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.105882354081, 0.121568627656, 0.141176477075, 1],
                ix: 3,
              },
              o: {
                a: 0,
                k: 100,
                ix: 4,
              },
              w: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.667],
                      y: [0.908],
                    },
                    o: {
                      x: [0.333],
                      y: [0],
                    },
                    t: 37,
                    s: [0],
                  },
                  {
                    i: {
                      x: [0.667],
                      y: [1.259],
                    },
                    o: {
                      x: [0.333],
                      y: [-0.085],
                    },
                    t: 56,
                    s: [16.039],
                  },
                  {
                    i: {
                      x: [0.667],
                      y: [1],
                    },
                    o: {
                      x: [0.333],
                      y: [0.184],
                    },
                    t: 67,
                    s: [6],
                  },
                  {
                    t: 81.0000032991976,
                    s: [24],
                  },
                ],
                ix: 5,
              },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [-7.277, -10.078],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [89.823, 86.077],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
