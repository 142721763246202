import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 704px;
  max-width: 95vw;

  height: 100vh;
  max-height: 88vh;

  border-radius: 0.5rem;
  background-color: var(--white);
`;

export const ContainerLoading = styled.div`
  svg {
    width: 1rem;
    height: 1rem;
  }
  span {
    width: 1rem !important;
    height: 1rem !important;
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  flex-grow: 0;

  padding: 1.75rem 1.5rem;

  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.12);

  & > button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 1rem;
    height: 1rem;

    background: transparent;
    border: none;
    color: #83834f;

    & > svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding: 1.5rem 1.5rem;

  overflow: auto;

  .label {
    margin-bottom: 1rem;
  }

  & > small {
    margin-bottom: 1rem;
  }

  h5 {
    margin-bottom: 8px;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 1rem;

  box-shadow: 0 -4px 8px 3px rgba(0, 0, 0, 0.06), 0 -1px 3px rgba(0, 0, 0, 0.12);

  min-height: 5rem;

  padding: 1.5rem 2.5rem;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  .title {
    font-family: inter;
    font-weight: 700;
    line-height: 25px;
  }

  .unit {
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 24px;
  }

  span {
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 2px 6px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  flex-grow: 1;
  padding .2rem .5rem;
  margin-left: 2rem;
  
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 16px;
  }

  svg {
    margin-left: 1rem;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }  

  .first-element {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
  }
`;

export const RowTimeline = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
`;

export const Bar = styled.div`
  width: 0.5rem;
  height: 5rem;
  background-color: #cdcddf;
`;

export const Dot = styled.div`
  position: relative;
  right: 50%;
  top: 0;
  width: 1rem;
  height: 1rem;
  border-radius: 50px;
  background-color: #cdcddf;
`;
