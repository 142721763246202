import { newAds } from '../../api';

interface ResponseData {
  access_token: string;
  refresh_token: string;
}

export async function getTokens(
  integration: AdsAccounts,
): Promise<ResponseData> {
  const { data } = await newAds.post<ResponseData>(
    `/customerIntegration/getKommoToken`,
    integration,
  );
  return data;
}
