import { BiBarChartSquare, BiEditAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { FiPlayCircle } from 'react-icons/fi';
import { Headline, Paragraph } from '../../../LegacyV4Components';
import * as S from './styles';
import { useAuth } from '../../../../hooks/auth';

export default function CustomerViewPreview({ customer }: { customer: any }) {
  const { user } = useAuth();

  const navigate = useNavigate();

  return (
    <S.Container>
      <S.HeaderPreview>
        <Headline variant="h5">Gestão Painel do Cliente</Headline>
        <S.EditCustomer
          onClick={() => navigate(`/cliente/visao/${customer._id}`)}
          disabled={!user.permissions?.customer?.edit}
        >
          Editar <BiEditAlt />
        </S.EditCustomer>
      </S.HeaderPreview>
      <S.Body>
        <Paragraph variants={['bold']}>Adicione aqui:</Paragraph>
        <S.Row>
          <span>
            <BiBarChartSquare className="dash" /> Dashboards
          </span>

          <span>
            <FiPlayCircle className="video-play" /> Vídeos
          </span>
        </S.Row>
        <p>
          Neste ambiente você estará editando a visualização que o cliente terá
          da plataforma mkt.lab.
        </p>
        <p>
          Aqui você pode publicar Dashboards e fazer upload de vídeos na
          plataforma.
        </p>
      </S.Body>
    </S.Container>
  );
}
