import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';

import Switch from '@mui/material/Switch';
import { Button, Headline } from '../../../LegacyV4Components';
import { Modal } from '../../../Default/Modal';
import { TextField } from '../../molecules/TextField';

import * as S from './styles';
import { UnitInterface } from '../../../../types/units';

export function RegisterEkyteModal({
  unit,
  isShown,
  setIsShown,
  handleRegister,
  isEkyteActive,
}: {
  unit: UnitInterface;
  isShown: boolean;
  setIsShown(value: boolean): any;
  handleRegister: (values: any) => void;
  isEkyteActive: boolean;
}) {
  const [disableSend, setDisableSend] = useState(false);

  const sendFormRef = useRef<HTMLButtonElement>(null);

  const stakeholderSchema = Yup.object().shape({
    name: Yup.string().required('O campo é obrigatório!'),
    ownerEmail: Yup.string()
      .required('O campo é obrigatório!')
      .email('Insira um endereço de e-mail válido'),
    contactEmail: Yup.string()
      .required('O campo é obrigatório!')
      .email('Insira um endereço de e-mail válido'),
  });

  return (
    <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
      <S.ModalWrapper>
        <S.ModalHeader>
          <Headline variant="h4">Habilitar Ekyte</Headline>
          <button
            type="button"
            onClick={() => setIsShown(false)}
            aria-label="default"
          >
            <FiX />
          </button>
        </S.ModalHeader>
        <S.ModalBody>
          <Formik
            initialValues={{
              active: isEkyteActive,
              name: unit.name,
              ownerEmail: (unit.stakeholders[0]?.email as string) ?? '',
              contactEmail: (unit.stakeholders[0]?.email as string) ?? '',
            }}
            validationSchema={stakeholderSchema}
            onSubmit={async (values) => {
              setIsShown(false);
              setDisableSend(true);
              handleRegister(values);
              setDisableSend(false);
            }}
          >
            {({ errors, values, handleBlur, setFieldValue }) => (
              <Form>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '2.5rem',
                    marginBottom: '2rem',
                  }}
                >
                  <p
                    style={{
                      fontSize: '22px',
                      marginRight: '2rem',
                    }}
                  >
                    Ativar Ekyte para sua unidade{' '}
                  </p>
                  <Switch
                    checked={values.active}
                    onChange={() => {
                      setFieldValue('active', !values.active);
                    }}
                    style={{ color: 'var(--success-2)' }}
                  />
                </div>
                <Headline variant="h5">Confirme seus dados</Headline>
                <TextField
                  name="name"
                  label="Nome da franquia"
                  value={values.name}
                  placeholder="Digite o nome da franquia"
                  helper={errors.name}
                  onBlur={handleBlur}
                  disabled
                />
                <TextField
                  name="ownerEmail"
                  label="E-mail do dono da franquia"
                  value={values.ownerEmail}
                  placeholder="Digite o e-mail do dono da franquia"
                  helper={errors.ownerEmail}
                  onBlur={handleBlur}
                />
                <TextField
                  name="contactEmail"
                  label="E-mail de contato"
                  value={values.contactEmail}
                  placeholder="Digite o e-mail de contato da franquia"
                  helper={errors.contactEmail}
                  onBlur={handleBlur}
                />
                <button
                  ref={sendFormRef}
                  type="submit"
                  style={{ display: 'none' }}
                >
                  Enviar
                </button>
              </Form>
            )}
          </Formik>
        </S.ModalBody>
        <S.ModalFooter>
          <Button
            variant="primary"
            color="green"
            size="default"
            onClick={() => sendFormRef.current?.click()}
            disabled={disableSend}
          >
            Confirmar
          </Button>
        </S.ModalFooter>
      </S.ModalWrapper>
    </Modal>
  );
}
