import { LabDsTable, LabDsTextField } from 'v4web-components-react';
import * as S from '../../styles';
import { Accordion } from '../../../../components/LegacyV4Components';
import { investmentInfos } from '../../../../services/requests/Unit/calculate';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';
import { investmentTable } from './menu';
import {
  DISCOUNT_IN_CASH,
  DISCOUNT_PAY_IN_SIX_MONTHS,
  percentageFee,
} from '../../utils';

interface investiment {
  detailedInvestment?: investmentInfos['detailedInvestment'];
  detailedInvestmentPerMonth?: investmentInfos['detailedInvestmentPerMonth'];
  discount: string;
  liquidDiscount: number;
  totalPayment: number;
  contractDuration: string;
  paymentMethod: string;
  setDiscount: (state: string) => void;
  setLiquidDiscount: (state: number) => void;
}

export function Investiment({
  detailedInvestmentPerMonth,
  detailedInvestment,
  discount,
  liquidDiscount,
  totalPayment,
  paymentMethod,
  contractDuration,
  setDiscount,
  setLiquidDiscount,
}: investiment) {
  const DEFAULT_CONTRACT_DURATION = '24';
  const DISCOUNT = parseFloat(discount.replaceAll('.', ''));
  const MAX_PERCENTAGE_DISCOUNT = 0.14;

  const contractTime = parseInt(
    contractDuration || DEFAULT_CONTRACT_DURATION,
    10,
  );

  function getMaxDiscount(discount_pay: number) {
    if (!detailedInvestment?.operationalInvestment) return 0;
    return (
      detailedInvestment?.operationalInvestment *
      discount_pay *
      contractTime *
      MAX_PERCENTAGE_DISCOUNT
    );
  }

  const MAX_DISCOUNT_VALUE = {
    inCash: getMaxDiscount(DISCOUNT_IN_CASH),
    semester: getMaxDiscount(DISCOUNT_PAY_IN_SIX_MONTHS),
    monthly: getMaxDiscount(1) || 0,
  };

  function getValuesToPay(discount_pay: number) {
    if (!detailedInvestment?.operationalInvestment) return 0;
    const discountValue =
      DISCOUNT <
      MAX_DISCOUNT_VALUE[paymentMethod as 'inCash' | 'semester' | 'monthly']
        ? DISCOUNT
        : 0;
    return (
      detailedInvestment?.operationalInvestment * discount_pay * contractTime -
      discountValue
    );
  }

  const showInvestmentValue = {
    inCash: convertNumberToBRL(getValuesToPay(DISCOUNT_IN_CASH)),
    semester: convertNumberToBRL(
      getValuesToPay(DISCOUNT_PAY_IN_SIX_MONTHS) / 6,
    ),
    monthly: convertNumberToBRL(getValuesToPay(1) / contractTime || 0),
  };

  return (
    <S.ContentBox>
      <S.ContentHeader>
        <h4>Investimento</h4>
      </S.ContentHeader>

      <S.Content>
        <S.TotalInvestiment>
          <S.Row>
            <p>Liquida:</p>
            <h5>{liquidDiscount || '-'}%</h5>
          </S.Row>
          <S.Row>
            <p>Investimento: </p>
            <h4>
              {showInvestmentValue[
                paymentMethod as 'inCash' | 'semester' | 'monthly'
              ] || '-'}
            </h4>
          </S.Row>
        </S.TotalInvestiment>
        <S.Accordion>
          <Accordion title="Extras">
            <S.Extras>
              <LabDsTextField
                titleInput="Aplicar desconto"
                value={discount}
                maxLength={10}
                mask="currency"
                onChangeInput={(e) => {
                  setDiscount(e.detail);
                }}
                state={
                  DISCOUNT >
                  MAX_DISCOUNT_VALUE[
                    paymentMethod as 'inCash' | 'semester' | 'monthly'
                  ]
                    ? 'error'
                    : 'default'
                }
                type="number"
              />
              <LabDsTextField
                titleInput="Liquida"
                value={liquidDiscount.toString()}
                type="number"
                onChangeInput={({ detail }) => {
                  setLiquidDiscount(parseInt(detail, 10));
                }}
                maxLength={2}
                state={
                  !liquidDiscount || liquidDiscount < 20 || liquidDiscount > 100
                    ? 'error'
                    : 'default'
                }
                helperText={
                  !liquidDiscount || liquidDiscount < 20 || liquidDiscount > 100
                    ? 'Liquida mínima de 20% e máxima de 100%'
                    : ''
                }
              />
              <h6>Economia {discount}</h6>

              <h6>
                Economia Fee:
                {detailedInvestmentPerMonth?.operationalInvestment &&
                totalPayment
                  ? ` ${percentageFee(
                      totalPayment,
                      detailedInvestmentPerMonth,
                    )} %`
                  : '0'}
              </h6>
            </S.Extras>
          </Accordion>
        </S.Accordion>
        <S.Accordion>
          <Accordion title="Investimento detalhado">
            <LabDsTable
              data={investmentTable(
                contractDuration,
                detailedInvestment,
                detailedInvestmentPerMonth,
              )}
            />
          </Accordion>
        </S.Accordion>
      </S.Content>
    </S.ContentBox>
  );
}
