import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 4rem;
  position: relative;
  z-index: 11;
`;

export const Content = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

export const Left = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  min-width: 20%;

  img {
    height: 1.8rem;
    object-fit: cover;
  }

  h4 {
    font-family: 'Montserrat';
    font-size: 1.25rem;
    font-weight: 900;
    color: var(--secondary-main);
    margin-left: 1rem;
    word-break: keep-all;
    white-space: nowrap;
  }
`;

export const Right = styled.div`
  min-width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  background-color: var(--white);
  position: relative;

  @media (max-width: 500px) {
    display: none;
  }

  svg {
    cursor: pointer;
    margin-left: 0.5rem;
  }
`;

export const RightMob = styled.div`
  @media (min-width: 501px) {
    display: none;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

export const UserInfoMoney = styled.div<{ balance: number }>`
  a {
    display: flex;
    align-items: center;
    text-decoration: none;

    span {
      color: var(--secondary-main);
      font-weight: bold;
      font-size: 1.5rem;
    }

    p {
      margin-left: 0.5rem;
      color: var(--primary-light);
      margin-right: 1rem;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  span {
    color: var(--secondary-light);
  }

  ${({ balance }) =>
    balance > 409.99 &&
    css`
      a p {
        color: var(--success-2);
      }
    `}

  ${({ balance }) =>
    balance === 0 &&
    css`
      a p {
        color: var(--secondary-main);
      }
    `}
`;

export const AvatarButton = styled.a`
  display: flex;
  margin-left: 1.5rem;
  cursor: pointer;

  img {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    width: 2rem !important;
    height: 2rem !important;
    width: auto;
    height: auto;
    background: var(--primary-dark-1);
  }
`;

export const Modal = styled.ul<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 15rem;
  background-color: var(--white);
  border-radius: 0.25rem;
  border: 1px solid var(--grayscale-20);
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
  z-index: 99;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      top: 4rem;
      opacity: 1;
      pointer-events: auto;

      > div div svg {
        transform: rotate(180deg);
      }

      p {
        opacity: 1;
        width: 8rem;
      }
    `}

  a {
    display: flex;
    text-decoration: none;
    padding: 1rem 1.5rem;

    strong {
      font-weight: 400;
      color: var(--secondary-main);
    }
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid var(--grayscale-20);
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h6 {
    text-transform: uppercase;
    flex: 1;
    font-size: 1rem;
    text-align: center;
    font-weight: normal;
  }
  svg {
    margin-right: 1rem;
    font-size: 2rem;
    color: var(--tertiary-dark);
    border: 1px solid var(--grayscale-10);
    border-spacing: 0.5rem;
    padding: 0.25rem;
    border-radius: 50%;
  }
`;

export const ModalItem = styled.div`
  &:hover {
    background-color: var(--grayscale-4);
  }
  a {
    display: flex;
    align-items: center;
    justify-content: left;
  }

  a svg {
    margin: 0;
    margin-right: 1rem;
    color: var(--grayscale-60);
  }
`;
