import { customerUsersApi } from '../../api';

interface CreateStakeholderDTO {
  name: string;
  role: string;
  phone: string;
  email: string;
  customerId: string;
}

export async function createCustomerUser(
  stakeholder: CreateStakeholderDTO,
): Promise<Stakeholder> {
  const response: any = await customerUsersApi.post(`/invite`, stakeholder);
  const createdStakeholder = response.data;

  return createdStakeholder;
}
