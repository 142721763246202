import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { newAds } from '../../api';

export async function getMetabaseUrl(
  dashboardId: number,
  params: Record<string, string>,
): Promise<string> {
  try {
    const response = await newAds.post<{ metabaseUrl: string }>('/metabase', {
      dashboardId,
      params,
    });
    return response.data.metabaseUrl;
  } catch {
    return '';
  }
}

export function useQueryGetMetabaseUrl(
  dashboardId: number,
  params: Record<string, string>,
): UseQueryResult<string> {
  return useQuery<string>(
    [`metabase-${dashboardId}-${params.customerid}`],
    () => getMetabaseUrl(dashboardId, params),
  );
}
