import React from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { AiOutlineSetting } from 'react-icons/ai';
import * as S from './styles';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { useAuth } from '../../../../hooks/auth';

interface MenuActiveUnitProps {
  onClickEkyte: () => void;
  onClickFinance: () => void;
  onClickEdit: () => void;
  onClickTransition: () => void;
  onClickBlackBox: () => void;
  isHeadquarter: boolean;
  isAdmin: boolean;
}

export default function MenuActiveUnit({
  onClickEkyte,
  onClickTransition,
  onClickFinance,
  onClickEdit,
  onClickBlackBox,
  isHeadquarter,
  isAdmin,
}: MenuActiveUnitProps) {
  const { permissionsManager } = useAuth();

  const canTransitionPermission =
    permissionsManager?.transitionPermission?.enableUnitsToTransition;
  const canBlackBoxPermission =
    permissionsManager?.blackBoxPermission?.blackBoxConfig;
  const canFundsWallet = permissionsManager?.salesPermission?.cashBack;

  return (
    <Menu
      menuButton={
        <S.MenuBtn disabled={!isAdmin && !isHeadquarter}>
          <AiOutlineSetting size={25} />
        </S.MenuBtn>
      }
      transition
    >
      {isAdmin && <MenuItem onClick={onClickEdit}>Editar informações</MenuItem>}
      {canTransitionPermission && (
        <MenuItem onClick={onClickTransition}>
          Habilitar Projetos para transição
        </MenuItem>
      )}
      {canBlackBoxPermission && (
        <MenuItem onClick={onClickBlackBox}>Habilitar Black Box</MenuItem>
      )}
      {isHeadquarter && (
        <>
          <MenuItem onClick={onClickEkyte}>Habilitar Ekyte</MenuItem>
        </>
      )}
      {canFundsWallet && (
        <MenuItem onClick={onClickFinance}>
          Adicionar fundos do Leadbroker
        </MenuItem>
      )}
    </Menu>
  );
}
