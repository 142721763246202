import { useEffect, useState } from 'react';

import { getMetabaseUrl } from '../../../../services/requests/Ads/getMetabaseUrl';

import * as S from './styles';

interface UnitManagementProps {
  unitId: string;
}

const unitManagementDashboardId = 36;

export function UnitManagement({ unitId }: UnitManagementProps) {
  const [url, setUrl] = useState('');

  useEffect(() => {
    getMetabaseUrl(unitManagementDashboardId, {
      id_franquia: unitId,
    }).then((response) => {
      setUrl(response);
    });
  });

  return (
    <S.Container>
      <S.IFrameWrapper>
        <iframe title="units-dashboard" src={url} />
      </S.IFrameWrapper>
    </S.Container>
  );
}
