import { newAds } from '../../api';

export async function createAccountIntegration(
  accountIntegration: Partial<AdsAccounts>[],
): Promise<AdsAccounts> {
  const { data } = await newAds.post<AdsAccounts>(
    `/customerIntegration`,
    accountIntegration,
  );
  const adsAccounts = data;
  return adsAccounts;
}
