import { ReactNode, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiX, FiChevronRight } from 'react-icons/fi';
import { MdOpenInNew, MdHelpOutline } from 'react-icons/md';
import { LabDsButton, LabDsSearchBar } from 'v4web-components-react';
import { Headline, Paragraph } from '../../../../LegacyV4Components';

import { useAuth } from '../../../../../hooks/auth';
import { useToast } from '../../../../../hooks/toast';
import {
  CustomerIntegrationsContextData,
  useCustomerIntegrations,
} from '../../../../../hooks/customerIntegrations';

import { ColumnProps, DataGrid, DataProps } from '../../../atoms/DataGrid';
import { IntegrationIntro } from '../components/IntegrationIntro';
import { IntegrationEditRow } from '../components/IntegrationEditRow';
import { IntegrationSummaryRow } from '../components/IntegrationSummaryRow';

import * as S from './styles';

interface TieAccountsModalProps {
  show: boolean;
  options: {
    type: string;
    name: string;
    description: string[];
    extraction: string[];
    requirements: string[];
    requirementsLinks?: { label: string; link: string }[];
    docs: string;
  };
  onClose: () => void;
  tableColumns: ColumnProps[];
  renderEditRow: (
    integration: AdsAccounts,
    error: boolean,
    context: CustomerIntegrationsContextData,
  ) => ReactNode;
  renderSummaryRow: (integration: AdsAccounts) => ReactNode;
  additionalValidation?: (integration: AdsAccounts) => boolean;
  isConnected: (integration: AdsAccounts) => boolean;
  renderAddButton?: (context: CustomerIntegrationsContextData) => ReactNode;
  addButtonText?: string;
}

export const defaultExtraction = [
  'Use a API de Dados para acessar essas informações',
  'Vincule com o Google Sheets',
];

export function TieAccountsModal({
  show,
  options,
  onClose,
  tableColumns,
  renderEditRow,
  renderSummaryRow,
  additionalValidation,
  isConnected,
  renderAddButton,
  addButtonText,
}: TieAccountsModalProps) {
  const { addToast } = useToast();
  const { user } = useAuth();

  const [phase, setPhase] = useState(1);

  const [filterQuery, setFilterQuery] = useState('');

  const [error, setError] = useState<string[]>([]);

  const customerIntegrationsContext = useCustomerIntegrations();

  const { integrations, validate, createIntegration, saveIntegrations } =
    customerIntegrationsContext;

  const {
    type,
    name,
    description,
    extraction,
    requirements,
    requirementsLinks,
    docs,
  } = options;

  const connectedAccounts = integrations.filter(isConnected).length;

  function getButtonText(): string {
    switch (phase) {
      case 2:
        return 'Seguir para o resumo';
      case 3:
        return 'Confirmar';
      case 1:
      default:
        return 'Próximo';
    }
  }

  function handleClose() {
    setPhase(1);
    setError([]);
    onClose();
  }

  function handleAdd() {
    const newIntegration = {
      _id: `-${new Date().getTime()}`,
      unitId: user.unitId,
      integrationId: '',
      type,
      isNew: true,
      status: 0,
      projectId: '',
      customerNewId: '',
      customerId: '',
    };

    createIntegration(newIntegration);
  }

  function handleValidation() {
    const errors = validate(additionalValidation);
    setError(errors);
    if (!errors.length) {
      setPhase(3);
    }
  }

  async function handleSave() {
    try {
      await saveIntegrations();

      addToast({
        title: 'Sucesso',
        description: 'Conexões salvas com sucesso',
        type: 'success',
      });

      handleClose();
    } catch (e) {
      addToast({
        title: 'Erro',
        description: 'Ocorreu um problema ao salvar as conexões',
        type: 'error',
      });
    }
  }

  function handleNext(): void {
    switch (phase) {
      case 2:
        handleValidation();
        break;
      case 3:
        handleSave();
        break;
      case 1:
      default:
        setPhase(phase + 1);
        break;
    }
  }

  const filteredIntegrations = useMemo(
    () =>
      integrations.filter(
        (integration) =>
          !integration.isDeleted &&
          (integration.integrationId.toLowerCase().includes(filterQuery) ||
            integration.value?.label?.toLowerCase()?.includes(filterQuery)),
      ),
    [integrations, filterQuery],
  );

  const tableData: DataProps[] = filteredIntegrations.map((integration) => ({
    id: integration._id,
    content:
      phase === 2 ? (
        <IntegrationEditRow
          integration={integration}
          renderEditRow={renderEditRow}
          error={error.includes(integration._id)}
        />
      ) : (
        <IntegrationSummaryRow
          integration={integration}
          renderSummaryRow={renderSummaryRow}
        />
      ),
  }));

  const isEditMode = phase === 2;

  return (
    <S.ModalOverlay className={show ? 'show' : ''} onClick={handleClose}>
      <S.ModalWrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <S.ModalHeader>
          <Headline variant="h4">{name}</Headline>
          <FiX size={16} className="close-button" onClick={handleClose} />
        </S.ModalHeader>
        <S.ProgressBar porc={`${(phase / 3) * 100}%`} />
        <S.TopWrapper>
          <S.Phase>
            <S.PhaseItem active={phase === 1}>1. Introdução</S.PhaseItem>
            <FiChevronRight size={24} />
            <S.PhaseItem active={phase === 2}>2. Vinculação</S.PhaseItem>
            <FiChevronRight size={24} />
            <S.PhaseItem active={phase === 3}>3. Resumo</S.PhaseItem>
          </S.Phase>
          {phase > 1 && (
            <S.Count
              className={connectedAccounts > 0 ? 'color-green' : 'color-red'}
            >
              {connectedAccounts}
              {connectedAccounts === 1
                ? ' conta vinculada'
                : ' contas vinculadas'}
            </S.Count>
          )}
        </S.TopWrapper>
        <S.ModalBody>
          {phase === 1 ? (
            <IntegrationIntro
              description={description}
              extraction={extraction}
              requirements={requirements}
              requirementsLinks={requirementsLinks}
            />
          ) : (
            <S.Table>
              <S.ControllersWrapper>
                <LabDsSearchBar
                  label="Pesquise por id, usuário ou cliente..."
                  value={filterQuery}
                  onChangeInput={({ detail }) => {
                    setFilterQuery(detail);
                  }}
                />
                {phase === 2 &&
                  (renderAddButton ? (
                    renderAddButton(customerIntegrationsContext)
                  ) : (
                    <LabDsButton
                      label={addButtonText || 'Adicionar novo ID'}
                      stepIcon="add"
                      onHandleButton={() => handleAdd()}
                    />
                  ))}
              </S.ControllersWrapper>
              <DataGrid
                columns={isEditMode ? tableColumns.slice(0, -1) : tableColumns}
                data={tableData}
                showModalUserInvate={false}
                setShowModalUserInvate={() => null}
                notClickable
              />
            </S.Table>
          )}
        </S.ModalBody>
        <S.ModalFooter>
          <S.ManualWrapper>
            <Link to={docs} target="_blank">
              <Paragraph>{`Documentação ${name}`}</Paragraph>
              <MdOpenInNew size={16} />
            </Link>
            <Link
              to="https://app.pipefy.com/public/form/zsc8VbvF"
              target="_blank"
            >
              <Paragraph>Precisa de ajuda</Paragraph>
              <MdHelpOutline size={16} />
            </Link>
          </S.ManualWrapper>
          <LabDsButton
            variant="outlined"
            label={phase === 1 ? 'Cancelar' : 'Voltar'}
            onHandleButton={() =>
              phase === 1 ? onClose() : setPhase(phase - 1)
            }
          />
          <LabDsButton
            label={getButtonText()}
            onHandleButton={() => handleNext()}
          />
        </S.ModalFooter>
      </S.ModalWrapper>
    </S.ModalOverlay>
  );
}
