import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  width: 100%;
  margin: 0 auto;
`;

export const Content = styled.div`
  margin-top: 1rem;
  background: var(--white);
  padding: 2rem;
  border-radius: 0.5rem;
  transition: 0.4s;

  h1 {
    font-size: 38px;
  }

  div section {
    padding: 0.75rem 1rem;
    margin: 2.75rem 0;
  }
`;

export const Tabs = styled.div`
  display: flex;
  margin: 1rem 0;
`;
