import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem 5rem 1.5rem 5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--tertiary-main);
`;

export const Header = styled.div`
  padding-top: 1.5rem;
  padding-left: 0.3rem;
  margin-bottom: 1.5rem;
  h3 {
    margin-bottom: 1rem;
    font-size: 2.375rem;
    line-height: 3rem;
  }

  p {
    font-size: 1.375rem;
    line-height: 2rem;
  }
`;

export const DetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 0.75rem;
`;

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 1.5rem;
  border-radius: var(--lab-ds-core-dimension-dimension-50, 0.5rem);
  background: var(--grayscale-white, #fff);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.12);
`;

export const TableWrapper = styled.div`
  flex: 1;

  .v4-table {
    max-height: max(calc(100vh - 22.5rem), 32rem);
  }

  td:nth-child(5) {
    .cell-row-components {
      justify-content: flex-start;
    }
  }
`;
