import { useParams } from 'react-router-dom';
import * as S from './styles';

export function KnowledgeBase() {
  const { path = '' } = useParams();

  const url = `https://base-de-conhecimento-v4.super.site/${path}`;

  return (
    <S.IFrameWrapper>
      <iframe title="Notion base de conhecimento" src={url} />
    </S.IFrameWrapper>
  );
}
