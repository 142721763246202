import { IPaymentAccountCreation } from '../../../pages/RegisterUnitAccount/constants/utils';
import { financeApi } from '../../api';

export async function createPaymentAccount(
  data: IPaymentAccountCreation,
): Promise<any> {
  const response = await financeApi.post<any>(`/invoices/create-account`, data);

  return response;
}
