import { kubernetesApi } from '../../../api';

export async function federationUnassignInvestor(
  userId: string,
  customerId: string,
) {
  try {
    const response = await kubernetesApi.put(`/users/project/unassign`, {
      userId,
      customerId,
    });
    const responseData = response.data;

    if (!responseData) {
      return false;
    }

    return responseData;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar esta cobrança, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
