import styled, { css } from 'styled-components';

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  color: var(--white) !important;

  div {
    width: 100%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    background-color: var(--black);
    height: 100%;
  }
  svg {
    transition: display 0.2s ease-in-out;
    position: absolute;
    color: var(--white) !important;
    width: 2rem;
    height: 2rem;
    z-index: 5;
    opacity: 1 !important;
    display: none;
  }
`;

export const Container = styled.div<{ loggedUser?: boolean }>`
  align-self: center;
  display: flex;
  justify-content: center;

  .ellipsis {
    position: absolute;
  }

  label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .avatar {
      display: flex;
      border-radius: 50%;
      max-width: 80px;
      max-height: 80px;
      width: auto;
      height: auto;
    }

    input {
      display: none;
    }

    ${({ loggedUser }) =>
      loggedUser &&
      css`
        ${IconContainer}:hover {
          div {
            opacity: 0.4;
          }

          svg {
            display: flex;
            stroke: var(--white);
          }
        }
      `}
  }
`;
