export interface IPaymentAttemptErrorProps {
  LR: string;
  message: string;
  action: string;
  irreversibleForFlags: string[];
}

export const paymentAttemptErrors: IPaymentAttemptErrorProps[] = [
  {
    LR: '0',
    message: 'Transação autorizada com sucesso',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '00',
    message: 'Transação autorizada com sucesso',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '1',
    message:
      'Transação não autorizada. Referida (suspeita de fraude) pelo banco emissor.',
    action: '',
    irreversibleForFlags: ['Master'],
  },
  {
    LR: '2',
    message:
      ' Transação não autorizada. Referida (suspeita de fraude) pelo banco emissor.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '3',
    message: 'Não foi possível processar a transação.',
    action: 'Entre em contato conosco',
    irreversibleForFlags: ['Master', 'Visa'],
  },
  {
    LR: '4',
    message: 'Transação não autorizada. Cartão bloqueado pelo banco emissor.',
    action: '',
    irreversibleForFlags: ['Master', 'Visa'],
  },
  {
    LR: '5',
    message: 'Transação não autorizada. Cartão inadimplente (Do not honor).',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '6',
    message: 'Transação não autorizada. Cartão cancelado.',
    action: '',
    irreversibleForFlags: ['Visa'],
  },
  {
    LR: '7',
    message: 'Transação negada.',
    action: 'Reter cartão condição especial',
    irreversibleForFlags: ['Visa'],
  },
  {
    LR: '8',
    message: 'Transação não autorizada. Código de segurança inválido.',
    action: '',
    irreversibleForFlags: ['Amex'],
  },
  {
    LR: '9',
    message: 'Transação cancelada parcialmente com sucesso.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '11',
    message: 'Transação autorizada com sucesso para cartão emitido no exterior',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '12',
    message: 'Transação inválida, erro no cartão.',
    action: '',
    irreversibleForFlags: ['Master', 'Visa', 'Elo'],
  },
  {
    LR: '13',
    message: 'Transação não permitida. Valor da transação Inválido.',
    action: '',
    irreversibleForFlags: ['Master', 'Visa', 'Elo', 'Amex'],
  },
  {
    LR: '14',
    message: 'Transação não autorizada. Cartão Inválido',
    action: '',
    irreversibleForFlags: ['Master', 'Elo'],
  },
  {
    LR: '15',
    message: 'Banco emissor indisponível ou inexistente.',
    action: '',
    irreversibleForFlags: ['Master', 'Visa'],
  },
  {
    LR: '19',
    message: '',
    action: 'Refaça a transação ou tente novamente mais tarde.',
    irreversibleForFlags: ['Visa', 'Elo'],
  },
  {
    LR: '21 ',
    message: 'Cancelamento não efetuado. Transação não localizada.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '22',
    message: 'Parcelamento inválido. Número de parcelas inválidas.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '23',
    message: 'Transação não autorizada. Valor da prestação inválido.',
    action: '',
    irreversibleForFlags: ['Elo'],
  },
  {
    LR: '24',
    message: 'Quantidade de parcelas inválido.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '25',
    message: 'Pedido de autorização não enviou número do cartão',
    action: '',
    irreversibleForFlags: ['Master'],
  },
  {
    LR: '28',
    message: 'Arquivo temporariamente indisponível.',
    action: '',
    irreversibleForFlags: ['Master'],
  },
  {
    LR: '30',
    message: 'Transação não autorizada. Decline Message',
    action: '',
    irreversibleForFlags: ['Master', 'Elo'],
  },
  {
    LR: '39',
    message: 'Transação não autorizada. Erro no banco emissor.',
    action: '',
    irreversibleForFlags: ['Master', 'Visa', 'Elo', 'Amex'],
  },
  {
    LR: '41',
    message: 'Transação não autorizada. Cartão bloqueado por perda.',
    action: '',
    irreversibleForFlags: ['Master', 'Visa', 'Elo'],
  },
  {
    LR: '43',
    message: 'Transação não autorizada. Cartão bloqueado por roubo.',
    action: '',
    irreversibleForFlags: ['Master', 'Visa', 'Elo'],
  },
  {
    LR: '46',
    message: 'Conta encerrada',
    action: '',
    irreversibleForFlags: ['Visa'],
  },
  {
    LR: '51',
    message: 'Transação não autorizada. Limite excedido/sem saldo.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '52',
    message: 'Cartão com dígito de controle inválido.',
    action: '',
    irreversibleForFlags: ['Visa'],
  },
  {
    LR: '53',
    message: 'Transação não permitida. Cartão poupança inválido',
    action: '',
    irreversibleForFlags: ['Visa'],
  },
  {
    LR: '54',
    message: 'Transação não autorizada. Cartão vencido',
    action: '',
    irreversibleForFlags: ['Master'],
  },
  {
    LR: '55',
    message: 'Transação não autorizada. Senha inválida',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '56',
    message: 'NÚMERO CARTÃO NÃO PERTENCE AO EMISSOR | NÚMERO CARTÃO INVÁLIDO',
    action: '',
    irreversibleForFlags: ['Elo'],
  },
  {
    LR: '57',
    message: 'Transação não permitida para o cartão',
    action: '',
    irreversibleForFlags: ['Master', 'Visa', 'Elo'],
  },
  {
    LR: '58',
    message: 'Transação não permitida. Opção de pagamento inválida.',
    action: '',
    irreversibleForFlags: ['Master', 'Visa', 'Elo'],
  },
  {
    LR: '59',
    message: 'Transação não autorizada. Suspeita de fraude.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '60',
    message: 'Transação não autorizada.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '61',
    message: 'Banco emissor indisponível.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '62',
    message: 'Transação não autorizada. Cartão restrito para uso doméstico',
    action: '',
    irreversibleForFlags: ['Master', 'Elo', 'Visa'],
  },
  {
    LR: '63',
    message: 'Transação não autorizada. Violação de segurança',
    action: '',
    irreversibleForFlags: ['Elo'],
  },
  {
    LR: '64',
    message:
      'Transação não autorizada. Valor abaixo do mínimo exigido pelo banco emissor.',
    action: '',
    irreversibleForFlags: ['Visa', 'Elo'],
  },
  {
    LR: '65',
    message:
      'Transação não autorizada. Excedida a quantidade de transações para o cartão.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '67',
    message: 'Transação não autorizada. Cartão bloqueado para compras hoje.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '70',
    message: 'Transação não autorizada. Limite excedido/sem saldo.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '72',
    message:
      'Cancelamento não efetuado. Saldo disponível para cancelamento insuficiente.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '74',
    message: 'Transação não autorizada. A senha está vencida.',
    action: '',
    irreversibleForFlags: ['Visa', 'Elo'],
  },
  {
    LR: '75',
    message: 'Senha bloqueada. Excedeu tentativas de cartão.',
    action: '',
    irreversibleForFlags: ['Master', 'Visa', 'Elo', 'Amex'],
  },
  {
    LR: '76',
    message:
      'Cancelamento não efetuado. Banco emissor não localizou a transação original',
    action: '',
    irreversibleForFlags: ['Visa'],
  },
  {
    LR: '77',
    message:
      'Cancelamento não efetuado. Não foi localizado a transação original',
    action: '',
    irreversibleForFlags: ['Elo'],
  },
  {
    LR: '78',
    message: 'Transação não autorizada. Cartão bloqueado primeiro uso.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '79',
    message: 'Transação não autorizada.',
    action: 'Entre em contato com o seu banco.',
    irreversibleForFlags: ['Master', 'Visa', 'Elo', 'Amex'],
  },
  {
    LR: '80',
    message:
      'Transação não autorizada. Divergencia na data de transação/pagamento.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '81',
    message: 'Transação não autorizada. A senha está vencida.',
    action: '',
    irreversibleForFlags: ['Visa'],
  },
  {
    LR: '82',
    message: 'Transação não autorizada. Cartão inválido',
    action: '',
    irreversibleForFlags: ['Visa', 'Elo'],
  },
  {
    LR: '83',
    message: 'Transação não autorizada. Erro no controle de senhas',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '85',
    message: 'Transação não permitida. Falha da operação.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '86',
    message: 'Transação não permitida. Falha da operação.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '88',
    message: 'Falha na criptografia dos dados.',
    action: '',
    irreversibleForFlags: ['Master'],
  },
  {
    LR: '89',
    message: 'Erro na transação.',
    action: '',
    irreversibleForFlags: ['Elo'],
  },
  {
    LR: '90',
    message: 'Transação não permitida. Falha da operação.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '91',
    message:
      'Transação não autorizada. Banco emissor temporariamente indisponível.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '92',
    message: 'Transação não autorizada. Tempo de comunicação excedido.',
    action: '',
    irreversibleForFlags: ['Master', 'Visa'],
  },
  {
    LR: '93',
    message:
      'Transação não autorizada. Violação de regra, possível erro no cadastro.',
    action: '',
    irreversibleForFlags: ['Visa'],
  },
  {
    LR: '94',
    message: 'Transação duplicada.',
    action: '',
    irreversibleForFlags: ['Master'],
  },
  {
    LR: '96',
    message: 'Falha no processamento.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '97',
    message: 'Valor não permitido para essa transação.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '98',
    message: 'Sistema/comunicação indisponível.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '99',
    message: 'Sistema/comunicação indisponível.',
    action: '',
    irreversibleForFlags: ['Elo'],
  },
  {
    LR: '475',
    message: 'Timeout de Cancelamento',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '999',
    message: 'Sistema/comunicação indisponível.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'A2',
    message: 'VERIFIQUE OS DADOS DO CARTÃO',
    action: '',
    irreversibleForFlags: ['Amex'],
  },
  {
    LR: 'A3',
    message: 'ERRO NO CARTÃO',
    action: 'NÃO TENTE NOVAMENTE',
    irreversibleForFlags: ['Amex'],
  },
  {
    LR: 'A5',
    message: 'TRANSAÇÃO NÃO PERMITIDA',
    action: 'NÃO TENTE NOVAMENTE',
    irreversibleForFlags: ['Amex'],
  },
  {
    LR: 'A7',
    message: 'ERRO NO CARTÃO',
    action: 'NÃO TENTE NOVAMENTE',
    irreversibleForFlags: ['Amex'],
  },
  {
    LR: 'AA',
    message: 'Tempo Excedido',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'AB',
    message: 'FUNÇÃO INCORRETA (DÉBITO)',
    action: '',
    irreversibleForFlags: ['Elo'],
  },
  {
    LR: 'AC',
    message:
      'Transação não permitida. Cartão de débito sendo usado com crédito.',
    action: 'Use a função débito.',
    irreversibleForFlags: ['Elo'],
  },
  {
    LR: 'AE',
    message: 'Tente Mais Tarde',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'AF',
    message: 'Transação não permitida. Falha da operação.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'AG',
    message: 'Transação não permitida. Falha da operação.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'AH',
    message:
      'Transação não permitida. Cartão de crédito sendo usado com débito.',
    action: 'Use a função crédito.',
    irreversibleForFlags: ['Visa'],
  },
  {
    LR: 'AI',
    message: 'Transação não autorizada. Autenticação não foi realizada.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'AJ',
    message:
      'Transação não permitida. Transação de crédito ou débito em uma operação que permite apenas Private Label. ',
    action: 'Tente novamente selecionando a opção Private Label.',
    irreversibleForFlags: ['Visa', 'Elo'],
  },
  {
    LR: 'AV',
    message: 'Transação não autorizada. Dados Inválidos',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'BD',
    message: 'Transação não permitida. Falha da operação.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'BL',
    message: 'Transação não autorizada. Limite diário excedido.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'BM',
    message: 'Transação não autorizada. Cartão Inválido',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'BN',
    message: 'Transação não autorizada. Cartão ou conta bloqueado.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'BO',
    message: 'Transação não permitida. Falha da operação.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'BP',
    message: 'Transação não autorizada. Conta corrente inexistente.',
    action: '',
    irreversibleForFlags: ['Master', 'Visa', 'Elo', 'Amex'],
  },
  {
    LR: 'BP176',
    message: 'Transação não permitida.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'BV',
    message: 'Transação não autorizada. Cartão vencido',
    action: '',
    irreversibleForFlags: ['Amex'],
  },
  {
    LR: 'CF',
    message: 'Transação não autorizada.C79:J79 Falha na validação dos dados.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'CG',
    message: 'Transação não autorizada. Falha na validação dos dados.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'DA',
    message: 'Transação não autorizada. Falha na validação dos dados.',
    action: '',
    irreversibleForFlags: ['Amex'],
  },
  {
    LR: 'DF',
    message: 'Transação não permitida. Falha no cartão ou cartão inválido.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'DM',
    message: 'Transação não autorizada. Limite excedido/sem saldo.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'DQ',
    message: 'Transação não autorizada. Falha na validação dos dados.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'DS',
    message: 'Transação não permitida para o cartão',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'EB',
    message: 'Transação não autorizada. Limite diário excedido.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'EE',
    message:
      'Transação não permitida. Valor da parcela inferior ao mínimo permitido.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'EK',
    message: 'Transação não permitida para o cartão',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'FA',
    message: 'Transação não autorizada.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'FC',
    message: 'Transação não autorizada. Ligue Emissor',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'FD',
    message: 'Transação negada. Reter cartão condição especial',
    action: '',
    irreversibleForFlags: ['Amex'],
  },
  {
    LR: 'FE',
    message:
      'Transação não autorizada. Divergencia na data de transação/pagamento.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'FF',
    message: 'Cancelamento OK',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'FG',
    message: 'Transação não autorizada. Ligue AmEx 08007285090.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'GA',
    message: 'Aguarde Contato',
    action: '',
    irreversibleForFlags: ['Master', 'Visa', 'Elo', 'Amex'],
  },
  {
    LR: 'GD',
    message: 'Transação não permitida.',
    action: '',
    irreversibleForFlags: ['Master', 'Visa', 'Elo', 'Amex'],
  },
  {
    LR: 'HJ',
    message: 'Transação não permitida. Código da operação inválido.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'IA',
    message: 'Transação não permitida. Indicador da operação inválido.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'JB',
    message: 'Transação não permitida. Valor da operação inválido.',
    action: '',
    irreversibleForFlags: ['Amex'],
  },
  {
    LR: 'P5',
    message: 'TROCA DE SENHA / DESBLOQUEIO',
    action: '',
    irreversibleForFlags: ['Elo'],
  },
  {
    LR: 'KA',
    message: 'Transação não permitida. Falha na validação dos dados.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'KB',
    message: 'Transação não permitida. Selecionado a opção incorrente.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'KE',
    message: 'Transação não autorizada. Falha na validação dos dados.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'N7',
    message: 'Transação não autorizada. Código de segurança inválido.',
    action: '',
    irreversibleForFlags: ['Master', 'Visa', 'Elo', 'Amex'],
  },
  {
    LR: 'R0',
    message: 'SUSPENSÃO DE PAGAMENTO RECORRENTE PARA UM SERVIÇO',
    action: '',
    irreversibleForFlags: ['Visa'],
  },
  {
    LR: 'R1',
    message: 'Transação não autorizada. Cartão inadimplente (Do not honor)',
    action: '',
    irreversibleForFlags: ['Visa'],
  },
  {
    LR: 'R2',
    message: 'TRANSAÇÃO NÃO QUALIFICADA PARA VISA PIN',
    action: '',
    irreversibleForFlags: ['Visa'],
  },
  {
    LR: 'R3',
    message: 'SUSPENSÃO DE TODAS AS ORDENS DE AUTORIZAÇÃO',
    action: '',
    irreversibleForFlags: ['Visa'],
  },
  {
    LR: 'U3',
    message: 'Transação não permitida. Falha na validação dos dados.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'N3',
    message: 'SAQUE NÃO DISPONÍVEL',
    action: '',
    irreversibleForFlags: ['Visa'],
  },
  {
    LR: 'N8',
    message: 'DIFERENÇA. PRÉ AUTORIZAÇÃO',
    action: '',
    irreversibleForFlags: ['Visa', 'Elo'],
  },
  {
    LR: 'NR',
    message: 'Transação não permitida.',
    action: 'Retentar a transação após 30 dias',
    irreversibleForFlags: ['Visa', 'Elo', 'Amex'],
  },
  {
    LR: 'RP',
    message: 'Transação não permitida.',
    action: 'Retentar a transação após 72 horas',
    irreversibleForFlags: [],
  },
  {
    LR: '99A',
    message: 'Token não encontrado',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '99B',
    message: 'Sistema indisponível/Falha na comunicação',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '99C',
    message: ' Sistema indisponível/Exceção no processamento',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '99Z',
    message: 'Sistema indisponível/Retorno desconhecido',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: '99TA',
    message:
      'Timeout na requisição. O tempo para receber o retorno da requisição excedeu.',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'AF01',
    message: 'Recusado manualmente em analise antifraude',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'AF02',
    message: 'Recusado automaticamente em analise antifraude',
    action: '',
    irreversibleForFlags: [],
  },
  {
    LR: 'AF03',
    message: 'Recusado pelo antifraude da adquirente de crédito',
    action:
      'Transação não permitida conforme análise de acusa por suspeita a fraude',
    irreversibleForFlags: [],
  },
  {
    LR: '126',
    message: 'A data de validade do cartão de crédito é inválida',
    action: '',
    irreversibleForFlags: [],
  },
];

export function getPaymentAttemptErrorMessage(
  LR: string,
): IPaymentAttemptErrorProps | null {
  const errorFounded = paymentAttemptErrors.find((error) => error.LR === LR);

  if (!errorFounded) return null;

  return errorFounded;
}
