import { LabDsButton } from 'v4web-components-react';
import { useState } from 'react';
import {
  Container,
  Section,
  SectionTitle,
} from '../../components/LegacyV4Components';

import * as S from './styles';
import { audiences } from './constants';
import { CreateAudienceModal } from './components/CreateAudienceModal';

export function AudienceStore() {
  const [showModal, setShowModal] = useState(false);
  const [selectedCohort, setSelectedCohort] = useState<number>();
  return (
    <>
      <Container>
        <Section>
          <S.Content>
            <SectionTitle text="Loja de Públicos" />

            <S.Cards>
              {audiences.map((audience) => (
                <S.Card>
                  <S.CardTitle>{audience.title}</S.CardTitle>
                  <S.CardDescription>{audience.description}</S.CardDescription>
                  <LabDsButton
                    label="Adicionar"
                    onHandleButton={() => {
                      setSelectedCohort(audience.value);
                      setShowModal(true);
                    }}
                  />
                </S.Card>
              ))}
            </S.Cards>
          </S.Content>
        </Section>
      </Container>
      <CreateAudienceModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        cohort={selectedCohort as number}
      />
    </>
  );
}
