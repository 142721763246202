import { FiArrowRight } from 'react-icons/fi';
import { NumberFormatValues } from 'react-number-format';
import { FormikTouched } from 'formik';
import { BiCreditCard } from 'react-icons/bi';
import { Button, Headline } from '../../../LegacyV4Components';
import * as S from './styles';
import { TextField } from '../../molecules/TextField';
import { FormikSubscriptionOptions } from '../../../../pages/SubscriptionOptions';
import { NumberField } from '../../molecules/NumberField';

interface CardSubscriptionProps {
  values: FormikSubscriptionOptions;
  errors: any;
  handleBlur: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  indexTab: number;
  setIndexTab: React.Dispatch<React.SetStateAction<number>>;
  touched: FormikTouched<FormikSubscriptionOptions>;
}

export function CardSubscription({
  values,
  errors,
  handleBlur,
  indexTab,
  setIndexTab,
  setFieldValue,
  touched,
}: CardSubscriptionProps) {
  return (
    <S.Container>
      <S.Card>
        <S.Header>
          <Headline variant="h6">
            <BiCreditCard />
            Preencha os dados do seu cartão
          </Headline>
        </S.Header>
        <S.Body>
          <TextField
            name="paymentCardToken.first_name"
            label="Nome no cartão (primeiro) *"
            value={values.paymentCardToken.first_name}
            placeholder="Informe o nome que está no cartão"
            helper={
              touched?.paymentCardToken?.first_name &&
              errors?.paymentCardToken?.first_name
                ? errors?.paymentCardToken.first_name
                : ''
            }
            validateOnChange={false}
            onBlur={handleBlur}
            onValueChange={(val: NumberFormatValues) => {
              setFieldValue(`paymentCardToken.first_name`, val.value);
            }}
          />
          <TextField
            name="paymentCardToken.last_name"
            label="Nome no cartão (último)  *"
            value={values.paymentCardToken.last_name}
            placeholder="Informe o nome que está no cartão"
            helper={
              touched?.paymentCardToken?.last_name &&
              errors?.paymentCardToken?.last_name
                ? errors?.paymentCardToken.last_name
                : ''
            }
            validateOnChange={false}
            onBlur={handleBlur}
            onValueChange={(val: NumberFormatValues) => {
              setFieldValue(`paymentCardToken.last_name`, val.value);
            }}
          />
          <NumberField
            format="#### #### #### ####"
            name="paymentCardToken.number"
            label="Número do cartão *"
            value={values.paymentCardToken.number}
            placeholder="Informe o número do cartão"
            helper={
              touched?.paymentCardToken?.number &&
              errors?.paymentCardToken?.number
                ? errors?.paymentCardToken.number
                : ''
            }
            validateOnChange={false}
            onBlur={handleBlur}
            onValueChange={(val: NumberFormatValues) => {
              setFieldValue(`paymentCardToken.number`, val.value);
            }}
          />
          <Headline variant="h6">Data de vencimento</Headline>
          <NumberField
            format="##"
            name="paymentCardToken.month"
            label="Mês *"
            value={values.paymentCardToken.month}
            placeholder="01"
            helper={
              touched?.paymentCardToken?.month &&
              errors?.paymentCardToken?.month
                ? errors?.paymentCardToken.month
                : ''
            }
            validateOnChange={false}
            onBlur={handleBlur}
            onValueChange={(val: NumberFormatValues) => {
              setFieldValue(`paymentCardToken.month`, val.value);
            }}
          />
          <NumberField
            format="####"
            name="paymentCardToken.year"
            label="Ano  *"
            value={values.paymentCardToken.year}
            placeholder="2027"
            helper={
              touched?.paymentCardToken?.year && errors?.paymentCardToken?.year
                ? errors?.paymentCardToken.year
                : ''
            }
            validateOnChange={false}
            onBlur={handleBlur}
            onValueChange={(val: NumberFormatValues) => {
              setFieldValue(`paymentCardToken.year`, val.value);
            }}
          />
          <NumberField
            format="###"
            name="paymentCardToken.verification_value"
            label="Código de Segurança  *"
            value={values.paymentCardToken.verification_value}
            placeholder="cvv"
            helper={
              touched?.paymentCardToken?.verification_value &&
              errors?.paymentCardToken?.verification_value
                ? errors?.paymentCardToken.verification_value
                : ''
            }
            validateOnChange={false}
            onBlur={handleBlur}
            onValueChange={(val: NumberFormatValues) => {
              setFieldValue(`paymentCardToken.verification_value`, val.value);
            }}
          />
        </S.Body>
      </S.Card>
      <div style={{ float: 'right' }}>
        <Button
          variant="primary"
          rightIcon={<FiArrowRight />}
          color="green"
          size="default"
          disabled={
            !(
              values.paymentCardToken.number &&
              !errors.paymentCardToken?.number &&
              values.paymentCardToken.first_name &&
              !errors.paymentCardToken?.first_name &&
              values.paymentCardToken.last_name &&
              !errors.paymentCardToken?.last_name &&
              values.paymentCardToken.month &&
              !errors.paymentCardToken?.month &&
              values.paymentCardToken.year &&
              !errors.paymentCardToken?.year &&
              values.paymentCardToken.verification_value &&
              !errors.paymentCardToken?.verification_value
            )
          }
          onClick={async () => {
            if (
              !errors.cardName &&
              !errors.cardNumber &&
              !errors.monthAvailable &&
              !errors.yearAvailable &&
              !errors.cvv
            ) {
              setIndexTab(indexTab + 1);
            }
          }}
        >
          Continuar
        </Button>
      </div>
    </S.Container>
  );
}
