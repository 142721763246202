import { useState } from 'react';
import { BiPalette } from 'react-icons/bi';
import { FiShoppingCart } from 'react-icons/fi';
import { FiExternalLink } from 'react-icons/fi';
import { Box } from '@mui/material';
import { RiErrorWarningLine, RiInformationLine } from 'react-icons/ri';
import { Button, Headline } from '../../../LegacyV4Components';

import { IProductList } from '../../../../services/requests/Finance/getProductList';
import { Tooltip } from '../../../Default/Tooltip';
import { UnHappyPath } from '../../atoms/UnhappyPath';
import CardCheckout from '../../organisms/CardCheckout';
import { ConfigBankModal } from '../../organisms/ConfigBankModal';
import { GenerateCheckoutModal } from '../../organisms/GenerateCheckoutModal';
import { CustomerThemeModal } from '../../molecules/GatewayCustomerTheme';
import * as S from './styles';
import { useAuth } from '../../../../hooks/auth';
import { useQueryGetUnitHaveAWSKey } from '../../../../services/requests/Finance/getUnitHaveAWSKey';
import { useQueryGetCustomertHaveAWSKey } from '../../../../services/requests/Finance/getCustomerHaveAWSKey';
import { KubernetesApiUnit } from '../../../../hooks/types';
import { Customer } from '../../../../types/customer';

interface InterfaceGateway {
  unit?: KubernetesApiUnit['unit'];
  customer: Customer;
  productList: any;
}

export default function CustomerGateway({
  unit,
  customer,
  productList,
}: InterfaceGateway) {
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showConfigBankModal, setShowConfigBankModal] = useState(false);

  const { user } = useAuth();

  const customerUnitId = customer.units[0]._id;

  const { data: unitData } = useQueryGetUnitHaveAWSKey(customerUnitId);
  const { data: bankResponse } = useQueryGetCustomertHaveAWSKey(customer._id);

  const unitHaveSecrets = unitData?.data.haveAWSSecrets;
  const customerHaveSecrets = bankResponse?.data.haveAWSSecrets;
  const unitAccountIsVerifiedOnIugu = unitData?.data.accountIsVerifiedOnIugu;

  const stakeholdersCustomersIds = unit?.stakeholders?.map((e) => e._id);
  const loggedUserIsStakeholder = stakeholdersCustomersIds?.some(
    (e) => user._id === e,
  );

  const modalType =
    unitHaveSecrets && unitAccountIsVerifiedOnIugu ? 'customer' : 'unit';

  function handleModalInteraction() {
    setShowCustomizeModal(!showCustomizeModal);
  }

  function handlePaletteInteraction() {
    handleModalInteraction();
  }

  function renderCheckoutComponent() {
    if (unitHaveSecrets && customerHaveSecrets && unitAccountIsVerifiedOnIugu) {
      return (
        <Button
          onClick={() => {
            setShowCreateModal(true);
          }}
          variant="primary"
          color="green"
          size="default"
          rightIcon={<FiShoppingCart />}
        >
          Gerar novo checkout
        </Button>
      );
    }

    if (
      loggedUserIsStakeholder &&
      (!unitHaveSecrets || !unitAccountIsVerifiedOnIugu)
    ) {
      return (
        <Button
          onClick={() => setShowConfigBankModal(true)}
          variant="primary"
          color="green"
          size="default"
          rightIcon={<RiInformationLine />}
        >
          Atualizar informações da unidade
        </Button>
      );
    }

    if (unitHaveSecrets && !customerHaveSecrets) {
      return (
        <Button
          onClick={() => setShowConfigBankModal(true)}
          variant="primary"
          color="green"
          size="default"
          rightIcon={<RiInformationLine />}
        >
          Atualizar informações do cliente
        </Button>
      );
    }

    return (
      <Tooltip
        title="O Checkout não está disponível pois a unidade não possui a subconta cadastrada. Entre em contato com o stakeholder"
        info
      >
        <S.CheckoutUnavailableWrapper>
          Checkout não disponível <RiErrorWarningLine size={24} />
        </S.CheckoutUnavailableWrapper>
      </Tooltip>
    );
  }

  return (
    <>
      <S.Container>
        <ConfigBankModal
          isShown={showConfigBankModal}
          setIsShown={setShowConfigBankModal}
          data={customer}
          modalType={modalType}
          userId={user._id}
        />

        <GenerateCheckoutModal
          isShown={showCreateModal}
          setIsShown={setShowCreateModal}
          data={customer}
          bankResponse={bankResponse}
        />

        <S.ContainerHead>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Headline variant="h4">Checkouts gerados</Headline>
            <Box sx={{ marginLeft: '18px', cursor: 'pointer' }}>
              <Tooltip info title="Personalizar tema com o layout do cliente">
                <BiPalette
                  color="#8383AF"
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                  onClick={handlePaletteInteraction}
                />
              </Tooltip>
            </Box>
          </div>
          {renderCheckoutComponent()}
        </S.ContainerHead>
        {!productList?.length ? (
          <>
            <UnHappyPath assetIndex={0} />
            <Headline variant="h6">
              Você ainda não gerou checkouts
            </Headline>{' '}
          </>
        ) : (
          <>
            {productList?.map((item: IProductList) => (
              <CardCheckout productList={item} />
            ))}
            <S.ButtonBankInfo
              type="button"
              onClick={() => setShowConfigBankModal(true)}
            >
              Acessar informações bancárias do cliente
              <FiExternalLink />
            </S.ButtonBankInfo>
          </>
        )}
      </S.Container>

      <CustomerThemeModal
        customer={customer}
        isOpen={showCustomizeModal}
        setIsOpen={handleModalInteraction}
      />
    </>
  );
}
