import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { IconBaseProps } from 'react-icons';
import { Oval } from 'react-loader-spinner';

import * as S from './styles';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'primary' | 'secondary' | 'tertiary';
  size: 'default' | 'small';
  width?: string;
  height?: string;
  color: 'red' | 'green';
  attention?: boolean;
  disabled?: boolean;
  children?: ReactNode[] | string;
  leftIcon?: IconBaseProps;
  rightIcon?: IconBaseProps;
  loading?: boolean;
  onClick(): any;
}

export function Button({ ...props }: ButtonProps) {
  const attention = props.attention ?? false;
  const disabled = props.disabled ?? false;
  const buttonClasses = `${props.variant} ${props.color} ${props.size}`;

  return (
    <S.Button
      width={props.width}
      height={props.height}
      className={buttonClasses}
      onClick={disabled ? () => null : props.onClick}
      disabled={disabled}
      data-attention={attention}
    >
      <>
        {props.leftIcon}
        {props.loading ? (
          <Oval height="22" width="22" color="#ffff" />
        ) : (
          props.children
        )}
        {props.rightIcon}
      </>
    </S.Button>
  );
}
