import styled, { css } from 'styled-components';

export const CustomerProfileWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 23.75rem 1fr;
  gap: 32px;
  margin: 1.5rem 0;

  section.selected {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
      0px 2px 6px 2px rgba(0, 0, 0, 0.06);
  }
`;

export const ContainerInfo = styled.div`
  width: 100%;
  min-height: 16.65rem;
  border-radius: 0.5rem;
  padding: 40px 32px;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  background-color: var(--tertiary-light);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
`;

export const TabRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;
  > p {
    margin-top: 1rem;
  }
`;

export const Text = styled.div`
  margin: 2rem 0;
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 2rem;
  gap: 2rem;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;
export const Card = styled.div`
  width: 50%;
  @media (max-width: 850px) {
    width: 100%;
    margin: 1rem;
  }
`;

export const DotMenu = styled.div`
  display: flex;
  position: relative;
  height: 4rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const DotOptions = styled.div<{ isHidden: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  left: 30px;
  transition: all 0.5s;
  border-radius: 0.5rem;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
  opacity: 0;
  max-height: 15rem;
  button {
    background: var(--white);
    padding: 1rem 1.5rem;
    border: none;
    font-weight: 400;
    text-align: left;
    pointer-events: none;
  }
  ${({ isHidden }) =>
    isHidden &&
    css`
       {
        max-height: 15rem;
        overflow: hidden;
        opacity: 1;
        button {
          pointer-events: auto;
        }
      }
    `}
  button + button {
    border-top: 1px solid var(--tertiary-dark);
  }
`;
