import React from 'react';
import { Menu, MenuItem } from '@szhsin/react-menu';
import * as S from './styles';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { handlerClick } from '../../../../hooks/actions';

interface MenuEkyteProps {
  actionAdmin: () => void;
  actionEdit: () => void;
  placeholder: string;
}

export default function MenuEkyte({
  actionEdit,
  actionAdmin,
  placeholder,
}: MenuEkyteProps) {
  return (
    <div onClick={(e) => handlerClick(e)} aria-hidden="true">
      <Menu menuButton={<S.MenuBtn>{placeholder}</S.MenuBtn>} transition>
        <MenuItem onClick={actionAdmin}>Administrar</MenuItem>
        <MenuItem onClick={actionEdit}>Editar</MenuItem>
      </Menu>
    </div>
  );
}
