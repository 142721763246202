import { useCallback } from 'react';
import { setCookie } from 'nookies';
import { FiX } from 'react-icons/fi';
import { Button, Headline } from '../../../LegacyV4Components';

import * as S from './styles';

interface ModalAcceptedCookiesProps {
  show: boolean;
  onClose: () => void;
}

export function ModalAcceptedCookies({
  show,
  onClose,
}: ModalAcceptedCookiesProps) {
  const handleClose = () => {
    onClose();
  };

  const handleAcceptedCookie = useCallback(
    async (cookie: string) => {
      setCookie(undefined, 'v4company.cookiesAccepted', cookie, {
        path: '/',
        domain: process.env.REACT_APP_DOMAIN_NAME,
      });
      onClose();
    },
    [onClose],
  );

  return (
    <S.ModalOverlay className={show ? 'show' : ''} onClick={handleClose}>
      <S.ModalWrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <S.ModalHeader>
          <FiX size={16} className="close-button" onClick={handleClose} />
        </S.ModalHeader>
        <S.ModalBody>
          <S.Text>
            <Headline variant="h5">Esta plataforma utiliza Cookies</Headline>
            <p>
              Armazenamos dados para melhorar a experiência de navegação na
              plataforma. Ao aceitar, você concorda com a nossa política de
              privacidade.
            </p>
          </S.Text>
          <S.Buttons>
            <Button
              color="green"
              variant="primary"
              size="small"
              onClick={() => {
                handleAcceptedCookie('analytical');
              }}
            >
              <S.TextMobile>Rejeitar</S.TextMobile>
              <S.TextWeb>Aceitar todos os Cookies</S.TextWeb>
            </Button>
            <Button
              color="red"
              variant="secondary"
              size="small"
              onClick={() => {
                handleAcceptedCookie('basic');
              }}
            >
              <S.TextMobile>Aceitar</S.TextMobile>
              <S.TextWeb>Rejeitar todos os Cookies</S.TextWeb>
            </Button>
          </S.Buttons>
        </S.ModalBody>
      </S.ModalWrapper>
    </S.ModalOverlay>
  );
}
