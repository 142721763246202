import React from 'react';
import * as S from './styles';

interface SectionContentp {
  children: React.ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export function SectionContent({
  children,
  style,
  disabled = false,
}: SectionContentp) {
  return (
    <S.SectionContent style={style} disabled={disabled}>
      {children}
    </S.SectionContent>
  );
}
