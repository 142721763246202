import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--secondary-main);
`;

export const TableRow = styled.tr`
  background-color: var(--white);

  border-bottom: 0.063rem solid var(--grayscale-4);

  &:hover {
    background-color: var(--grayscale-2);
    cursor: pointer;
  }
`;

export const TableHeader = styled.th`
  text-align: left;
  padding: 0.65rem;
  background-color: var(--grayscale-4);
  max-width: 3.125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    border-top-left-radius: 0.313rem;
    border-bottom-left-radius: 0.313rem;
  }

  &:last-child {
    border-top-right-radius: 0.313rem;
    border-bottom-right-radius: 0.313rem;
  }
`;

export const TableCell = styled.td<{ width?: string }>`
  width: ${({ width }) => width || 'auto'};
  padding: 0.65rem;
  max-width: 3.125rem;

  &:hover {
    .copy_button {
      display: block;
    }
  }
  &:first-child {
    border-top-left-radius: 0.313rem;
    border-bottom-left-radius: 0.313rem;
  }

  &:last-child {
    border-top-right-radius: 0.313rem;
    border-bottom-right-radius: 0.313rem;
  }
`;

export const TableWrapper = styled.div`
  overflow-x: auto;
  margin-bottom: 1rem;

  @media (max-width: 36rem) {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;

export const Cell = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  height: 2.188rem;

  > p {
    ${({ width }) => width && `min-width: ${width}`};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const CopyButtonArea = styled.div`
  display: none;
`;
