import { Headline } from '../../../LegacyV4Components';
import * as S from './styles';
import { PaymentValidate } from '../../../../types/paymentValidate';
import { ItemListComponent } from './ItemListComponent';

interface Props {
  data: PaymentValidate;
}

export function PaymentChecklistValidate({ data }: Props) {
  const { unit, customer, generalValidation } = data;

  // unit properties
  const unitStakeholderIsValid = unit?.unitStakeholderValidation?.valid;
  const unitStakeholderActionMessage = unit?.unitStakeholderValidation?.message;

  const unitSecretsIsValid = unit?.unitSecretsValidation?.valid;
  const unitSecretsActionMessage = unit?.unitSecretsValidation?.message;

  const unitAccountIuguIsVerified = unit?.unitAccountIuguValidation?.valid;
  const unitAccountIuguActionMessage = unit?.unitSecretsValidation?.message;

  const unitPaymentMethodBankSlipIsValid =
    unit?.unitPaymentMethodValidation?.bank_slip?.valid;
  const unitPaymentMethodBankSlipActionMessage =
    unit?.unitPaymentMethodValidation?.bank_slip?.message;

  const unitPaymentMethodPixIsValid =
    unit?.unitPaymentMethodValidation?.pix?.valid;
  const unitPaymentMethodPixActionMessage =
    unit?.unitPaymentMethodValidation?.pix?.message;

  const unitPaymentMethodCreditCardIsValid =
    unit?.unitPaymentMethodValidation?.credit_card?.active;
  const unitPaymentMethodCreditCardActionMessage =
    unit?.unitPaymentMethodValidation?.credit_card?.message;

  // customer properties
  const customerStakeholderIsValid =
    customer?.customerStakeholderValidation?.valid;
  const customerStakeholderActionMessage =
    customer?.customerStakeholderValidation?.message;

  const customerSecretsIsValid = customer?.customerSecretsValidation?.valid;
  const customerSecretsActionMessage =
    customer?.customerSecretsValidation?.message;

  const customerAccountIuguIsVerified =
    customer?.customerAccountIuguValidation?.valid;
  const customerAccountIuguActionMessage =
    customer?.customerSecretsValidation?.message;

  const customerPaymentMethodBankSlipIsValid =
    customer?.customerPaymentMethodValidation?.bank_slip?.valid;
  const customerPaymentMethodBankSlipActionMessage =
    customer?.customerPaymentMethodValidation?.bank_slip?.message;

  const customerPaymentMethodPixIsValid =
    customer?.customerPaymentMethodValidation?.pix?.valid;
  const customerPaymentMethodPixActionMessage =
    customer?.customerPaymentMethodValidation?.pix?.message;

  const customerPaymentMethodCreditCardIsValid =
    customer?.customerPaymentMethodValidation?.credit_card?.active;
  const customerPaymentMethodCreditCardActionMessage =
    customer?.customerPaymentMethodValidation?.credit_card?.message;

  return (
    <S.Container>
      <S.Title>Checklist pré pagamento</S.Title>

      {generalValidation?.valid ? (
        <S.Body>
          <S.ListWrapper>
            <S.HeadlineContainer>
              <Headline variant="h6">Unidade</Headline>
            </S.HeadlineContainer>

            <ItemListComponent
              isSuccess={unitStakeholderIsValid}
              title="Cadastro do stakeholder"
              actionMessage={unitStakeholderActionMessage}
            />

            <ItemListComponent
              isSuccess={unitSecretsIsValid}
              title="Secrets salvo"
              actionMessage={unitSecretsActionMessage}
            />

            <ItemListComponent
              isSuccess={unitAccountIuguIsVerified}
              title="Conta verificada"
              actionMessage={unitAccountIuguActionMessage}
            />

            <ItemListComponent
              isSuccess={unitPaymentMethodBankSlipIsValid}
              title="Pagamento por boleto"
              actionMessage={unitPaymentMethodBankSlipActionMessage}
            />

            <ItemListComponent
              isSuccess={unitPaymentMethodPixIsValid}
              title="Pagamento por pix"
              actionMessage={unitPaymentMethodPixActionMessage}
            />

            <ItemListComponent
              isSuccess={unitPaymentMethodCreditCardIsValid}
              title="Pagamento por cartão de crédito"
              actionMessage={unitPaymentMethodCreditCardActionMessage}
            />
          </S.ListWrapper>

          <S.ListWrapper>
            <S.HeadlineContainer>
              <Headline variant="h6">Cliente</Headline>
            </S.HeadlineContainer>

            <ItemListComponent
              isSuccess={customerStakeholderIsValid}
              title="Cadastro do stakeholder"
              actionMessage={customerStakeholderActionMessage}
            />

            <ItemListComponent
              isSuccess={customerSecretsIsValid}
              title="Secrets salvo"
              actionMessage={customerSecretsActionMessage}
            />

            <ItemListComponent
              isSuccess={customerAccountIuguIsVerified}
              title="Conta verificada"
              actionMessage={customerAccountIuguActionMessage}
            />

            <ItemListComponent
              isSuccess={customerPaymentMethodBankSlipIsValid}
              title="Pagamento por boleto"
              actionMessage={customerPaymentMethodBankSlipActionMessage}
            />

            <ItemListComponent
              isSuccess={customerPaymentMethodPixIsValid}
              title="Pagamento por pix"
              actionMessage={customerPaymentMethodPixActionMessage}
            />

            <ItemListComponent
              isSuccess={customerPaymentMethodCreditCardIsValid}
              title="Pagamento por cartão de crédito"
              actionMessage={customerPaymentMethodCreditCardActionMessage}
            />
          </S.ListWrapper>
        </S.Body>
      ) : (
        <S.GeneralValidationMessage>
          {generalValidation?.message}
        </S.GeneralValidationMessage>
      )}
    </S.Container>
  );
}
