import { useEffect, useState } from 'react';
import { Modal, Button } from '../../../components/LegacyV4Components';
import * as S from './styles';
import { SelectSearch } from '../../../components/AtomicDesign/atoms/SelectSearch';
import { Unit } from '../../../hooks/types';
import { getUnits } from '../../../services/requests/Unit/getUnits';
import { updateUnitOfProject } from '../../../services/requests/Projects/update-projects';
import { useToast } from '../../../hooks/toast';

export function ModalManageProjects({
  projects,
  open,
  setIsOpen,
}: {
  projects: any;
  open: boolean;
  setIsOpen: () => void;
}) {
  const [newUnitId, setNewUnitId] = useState('');
  const [units, setUnits] = useState<Unit[]>([]);

  const { addToast } = useToast();

  useEffect(() => {
    async function getUnit() {
      const response = (await getUnits(0, '', true)) as any;
      if (response) {
        setUnits(response.data);
      }
    }

    getUnit();
  }, []);

  async function handleUpdateProject() {
    const dataToSend = {
      id: projects?._id,
      body: {
        unitId: newUnitId,
      },
    };

    const response = await updateUnitOfProject(dataToSend);

    if (response?.valueOf()) {
      addToast({
        title: 'Projeto alocado com sucesso!',
        type: 'success',
      });
      setIsOpen();
    } else {
      addToast({
        title: 'Erro ao alocar projeto!',
        type: 'error',
      });
    }

    setIsOpen();
  }

  return (
    <Modal
      show={open}
      title="Gerenciar projetos"
      subtitle="Alocar projeto em unidade"
      closeButton
      onBackground={setIsOpen}
    >
      <S.Container>
        <S.Observation>
          Selecione a unidade que você deseja alocar este projeto!
        </S.Observation>
        <SelectSearch
          value={newUnitId}
          setValue={(value) => setNewUnitId(value)}
          placeholder="Selecione a unidade"
          options={units?.map((unit) => ({
            label: unit?.name,
            value: unit?._id,
          }))}
          notSearch={false}
        />
        <S.ButtonContainer>
          <Button
            color="green"
            onClick={() => handleUpdateProject()}
            size="default"
            variant="primary"
          >
            Salvar
          </Button>
        </S.ButtonContainer>
      </S.Container>
    </Modal>
  );
}
