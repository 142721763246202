import { financeApi } from '../../api';

export async function getAccountVerificationStatus(
  unitId: string,
): Promise<{ isVerified: boolean }> {
  try {
    const response = await financeApi.get<{ isVerified: boolean }>(
      `/invoices/check-account-verification/${unitId}`,
    );
    return { isVerified: response?.data?.isVerified };
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao verificar o status da conta bancária da unidade, atualize a página e tente novamente 😕`,
      err,
    );
    return { isVerified: false };
  }
}
