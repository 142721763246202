import styled from 'styled-components';

export const SectionDescription = styled.h4`
  span {
    color: var(--primary-main);
  }
`;

export const SectionImage = styled.div`
  display: flex;
  justify-content: center;
`;
