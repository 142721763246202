import { useEffect, useState } from 'react';
import { LabDsBreadcrumb, LabDsTab } from 'v4web-components-react';

import { useNavigate, useParams } from 'react-router-dom';

import { ConnectAccounts } from '../../components/AtomicDesign/organisms/ConnectAccounts';
import { ExtractData } from '../../components/AtomicDesign/organisms/ExtractData';

import * as S from './styles';
import { slugify } from '../../utils/slugify';
import { Summary } from '../../components/AtomicDesign/organisms/Summary';

export function MediaHub() {
  const [selectedTab, setSelectedTab] = useState('');

  const navigate = useNavigate();

  const handleTabChange = (tab: string) => {
    navigate(`/clientes/mediahub/${slugify(tab)}`);
  };
  const mediaHubTabs = [
    {
      title: 'Visão geral',
      key: 'Visão geral',
      event: () => {
        handleTabChange('Visão geral');
      },
    },
    {
      title: 'Conexão de contas',
      key: 'Conexão de contas',
      event: () => {
        handleTabChange('Conexão de contas');
      },
    },
    {
      title: 'Extração de dados',
      key: 'Extração de dados',
      event: () => {
        handleTabChange('Extração de dados');
      },
    },
  ];

  const breadcrumbItems = [
    {
      label: 'Meus clientes',
      link: '/clientes',
      key: 'customers',
      event: () => navigate('/clientes'),
    },
    {
      label: 'MediaHub',
      link: '/clientes/mediahub',
      key: 'mediahub',
      event: () => navigate('/clientes/mediahub'),
    },
  ];

  breadcrumbItems.push({
    label: selectedTab,
    link: `/clientes/mediahub/${slugify(selectedTab)}`,
    key: selectedTab,
    event: () => navigate(`/clientes/mediahub/${slugify(selectedTab)}`),
  });

  const { tab } = useParams();

  useEffect(() => {
    switch (tab) {
      case 'visao-geral':
      default:
        setSelectedTab('Visão geral');
        break;

      case 'conexao-de-contas':
        setSelectedTab('Conexão de contas');
        break;

      case 'extracao-de-dados':
        setSelectedTab('Extração de dados');
        break;
    }
  }, [tab]);

  return (
    <S.Container>
      <LabDsBreadcrumb breadcrumbs={breadcrumbItems} />
      <S.Content>
        <h1>MediaHub</h1>
        <S.Tabs>
          <LabDsTab value={selectedTab} tabs={mediaHubTabs} />
        </S.Tabs>
        {selectedTab === 'Visão geral' ? <Summary /> : null}
        {selectedTab === 'Conexão de contas' ? <ConnectAccounts /> : null}
        {selectedTab === 'Extração de dados' ? <ExtractData /> : null}
      </S.Content>
    </S.Container>
  );
}
