import { useEffect } from 'react';
import * as api from '../services/api';
import { useAuth } from './auth';
import { User, Unit } from './types';
import { useToast } from './toast';

export function useCheckAuthentication() {
  const { forceLogout } = useAuth();
  const { addToast } = useToast();

  async function fetchMe() {
    try {
      await api.usersApi.get<{ user: User; unit: Unit }>('/user/me');
    } catch (err) {
      if (err?.response && err?.response?.status === 401) {
        addToast({
          type: 'error',
          title: 'Sessão expirada, faça o login para continuar.',
        });
        forceLogout();
      }
    }
  }

  useEffect(() => {
    fetchMe();
  });

  return { fetchMe };
}
