import styled, { keyframes } from 'styled-components';

const loadingspinner = keyframes`
 0%{
    transform:rotate(0deg)
  }
  100%{
    transform:rotate(360deg)
  }
`;

export const Container = styled.div<{
  backgroundColor?: string;
  width?: string;
  height?: string;
  border?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  div {
    width: ${({ width }) => width || '9.38rem'};
    height: ${({ height }) => height || '9.38rem'};
    border: ${({ border }) => border || '0.4rem solid var(--success-2);'};
    border-radius: 50%;
    border-top-color: var(--tertiary-main);
    display: inline-block;
    animation: ${loadingspinner} 0.7s linear infinite;
  }
`;
