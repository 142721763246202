import React, { useRef, useState } from 'react';
import { FiArrowLeft, FiCreditCard, FiLink, FiPercent } from 'react-icons/fi';
import { FiX } from 'react-icons/fi';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { Button, Headline, Modal } from '../../../LegacyV4Components';
import { Modal as ModalDefault } from '../../../Default/Modal';

import * as S from './styles';
import { useToast } from '../../../../hooks/toast';
import { createInvoice } from '../../../../services/requests/MediaWallet/createInvoice';
import { CreateInvoiceMW } from '../../../../types/createCustomerFNC';

export interface commissionValue {
  unitComission: number;
  headquarterComission: number;
}

export interface commissions {
  comissionType: string;
  commissionValue: commissionValue;
}

export interface invoiceResponse {
  walletId: string;
}

export function ResumeChargeModal({
  isShown,
  setIsShown,
  data,
  setIsOpenResume,
  isRoyalties,
  isVariableValue,
  customerId,
}: {
  isShown: boolean;
  setIsShown: (value: React.SetStateAction<boolean>) => void;
  data: CreateInvoiceMW;
  setIsOpenResume: (value: React.SetStateAction<boolean>) => void;
  isRoyalties: boolean | undefined;
  isVariableValue: boolean | undefined;
  customerId: string;
}) {
  const [isOpenLink, setIsOpenLink] = useState(false);
  const [invoiceResponse, setInvoiceResponse] = useState({} as any);
  const sendFormRef = useRef<HTMLButtonElement>(null);
  const { addToast } = useToast();
  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);

    addToast({
      type: 'success',
      title: 'Copiado para a área de transferência!',
    });
  }

  async function handleSubmit() {
    const numberedValue = Number(data.ammount.toString().replace(',', '.'));
    const dataToSend = {
      customerId,
      ammount: numberedValue * 100,
      franchiseBrokerageFee: data.splits,
      payableWith: data.paymentMethods,
    };

    const createdProduct = await createInvoice(
      dataToSend as unknown as CreateInvoiceMW,
      customerId,
    );

    createdProduct !== false &&
      addToast({
        title: 'Sucesso',
        description: 'Produto criado com sucesso',
        type: 'success',
      });
    createdProduct === false &&
      addToast({
        title: 'error',
        description: 'Ocorreu um erro na criação deste produto.',
        type: 'error',
      });
    sendFormRef.current?.click();
    createdProduct !== false && setInvoiceResponse(createdProduct);
    createdProduct !== false && setIsOpenLink(true);
  }

  return (
    <>
      <Modal show={isOpenLink} onBackground={() => setIsOpenLink(false)}>
        <S.LinkBody>
          <AiOutlineCheckCircle color="#00C49A" size={66} />
          <p style={{ marginBottom: 32, marginTop: '2rem' }}>
            Link para fatura do Media Wallet foi gerado!
          </p>
          <span>
            O Link para realizar o pagamento de fatura do Media Wallet foi
            gerado, selecione o botão abaixo para copiar o endereço.
          </span>
        </S.LinkBody>
        <S.LinkFooter>
          <S.ButtonCopyLink
            style={{ textDecoration: 'none' }}
            onClick={() => {
              copyToClipboard(`${invoiceResponse.invoiceUrl}`);
            }}
          >
            <p>Copiar link</p>
            <FiLink />
          </S.ButtonCopyLink>
        </S.LinkFooter>
      </Modal>

      <ModalDefault modalOpen={isShown} onBackground={() => setIsShown(false)}>
        <S.ModalWrapper>
          <S.ModalHeader>
            <Headline variant="h4">Resumo da cobrança</Headline>
            <button
              type="button"
              onClick={() => setIsOpenResume(false)}
              aria-label="default"
            >
              <FiX />
            </button>
          </S.ModalHeader>
          <S.ModalBody>
            <S.SectionResume>
              <Headline variant="h5">Valor: R$ {data.ammount}</Headline>
            </S.SectionResume>
            <S.SectionResume>
              <Headline variant="h5">
                <FiPercent />
                Porcentagem de corretagem
              </Headline>
              <p>Quem irá receber a porcentagem de corretagem: Franquia</p>
              <p>
                Forma de corretagem do valor:{' '}
                {isVariableValue === true ? 'Valor variável' : 'Valor fixo'}
              </p>
              <p>
                A matriz receberá o valor em royalties:{' '}
                {isRoyalties ? 'Sim' : 'Não'}
              </p>
            </S.SectionResume>
            <S.SectionResume>
              <Headline variant="h5">
                <FiCreditCard />
                Formas de pagamento
              </Headline>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {data.paymentMethods?.map((productLists: any) => {
                  switch (productLists) {
                    case 'bank_slip':
                      return <p>Boleto</p>;
                    case 'pix':
                      return <p>Pix</p>;
                    default:
                      break;
                  }
                  return <p>{productLists}</p>;
                })}
              </div>
            </S.SectionResume>
          </S.ModalBody>
          <S.ModalFooter>
            <Button
              variant="primary"
              color="green"
              size="default"
              onClick={handleSubmit}
            >
              Gerar cobrança
              <FiLink color="#333333" />
            </Button>
            <Button
              variant="secondary"
              color="green"
              size="default"
              onClick={() => {
                setIsOpenResume(false);
                setIsShown(true);
              }}
            >
              <FiArrowLeft color="#00C49A" />
              Voltar para edição
            </Button>
          </S.ModalFooter>
        </S.ModalWrapper>
      </ModalDefault>
    </>
  );
}
