import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LabDsBreadcrumb,
  LabDsButton,
  LabDsSelect,
  LabDsTextField,
} from 'v4web-components-react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router';
import * as S from './styles';
import { paymentAccountCreationSchema } from './constants/schemas';
import {
  accountsTypesOptions,
  banksOptions,
  formatErrorMessage,
  IPaymentAccountCreation,
  removeNaNFromString,
} from './constants/utils';
import { useToast } from '../../hooks/toast';
import { IStakeholder, KubernetesApiUnit } from '../../hooks/types';
import { getUnit } from '../../services/requests/Unit/getUnit';
import { createPaymentAccount } from '../../services/requests/Finance/createPaymentAccount';

export function RegisterUnitAccount() {
  const [unit, setUnit] = useState<KubernetesApiUnit['unit'] | null>(null);

  const preFilledFormValues = useMemo(() => {
    if (unit) {
      const mainStakeHolder: IStakeholder | null =
        unit.stakeholders?.find((item) => item.isPrincipal) || null;

      return {
        unit_id: unit.id,
        company_name: unit?.name,
        cnpj: unit.cnpj,
        address: unit.address.street,
        cep: unit.address.zipCode,
        city: unit.address.city,
        state: unit.address.state,
        district: unit.address.district,
        bank: '',
        bank_ag: '',
        account_type: '',
        bank_cc: '',
        telephone: mainStakeHolder ? mainStakeHolder.phone : '',
        resp_name: mainStakeHolder ? mainStakeHolder.name : '',
        resp_cpf: mainStakeHolder ? mainStakeHolder.cpf : '',
        physical_products: false,
        business_type: 'Marketing',
        person_type: 'Pessoa Jurídica',
        automatic_transfer: false,
      };
    }

    return undefined;
  }, [unit]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IPaymentAccountCreation>({
    resolver: yupResolver(paymentAccountCreationSchema),
    values: preFilledFormValues,
  });
  const navigate = useNavigate();
  const { addToast } = useToast();

  const createUnitBreadcrumbsItems = [
    {
      label: 'Unidades Franqueadas ',
      link: '/unidades',
      key: 'pageTitle',
      event: () => navigate('/unidades'),
    },
    {
      label: `${unit?.name || '-'}`,
      link: `/unit/view/head/${unit?.id}`,
      key: 'pageTitle',
      event: () => navigate(`/unit/view/head/${unit?.id}`),
    },
    {
      label: 'Dados Bancários',
      link: '/unidades/create',
      key: 'pageTitle',
      event: () => '',
    },
  ];

  const params = useParams();

  const fetchUnit = (unitId: string) => {
    getUnit(unitId).then((response) => {
      if (response) {
        setUnit(response);
      }
    });
  };

  useEffect(() => {
    const { unitId } = params;

    if (unitId) {
      fetchUnit(unitId);
    }
  }, [params]);

  const onSubmit: SubmitHandler<IPaymentAccountCreation> = useCallback(
    async (data: IPaymentAccountCreation) => {
      if (!unit) return;

      const body = {
        unit_id: unit?.id,
        company_name: data.company_name,
        cnpj: data.cnpj,
        address: data.address,
        cep: removeNaNFromString(data.cep),
        city: data.city,
        state: data.state,
        district: data.district,
        telephone: removeNaNFromString(data.telephone),
        bank: data.bank,
        bank_ag: data.bank_ag,
        account_type: data.account_type,
        bank_cc: data.bank_cc,
        resp_name: data.resp_name,
        price_range: 'Mais que R$ 500,00',
        physical_products: false,
        business_type: data.business_type || 'tipo',
        person_type: 'Pessoa Jurídica',
        automatic_transfer: false,
      };

      await createPaymentAccount(body)
        .then(() => {
          addToast({
            type: 'success',
            title: 'Dados Bancários cadastrados com sucesso!',
          });
          navigate(`/unit/view/head/${unit?.id}`);
        })
        .catch((error) => {
          addToast({
            type: 'error',
            title: `Erro ao cadastrar dados bancários - ${formatErrorMessage(
              error.response.data.message,
            )}`,
          });
        });
    },

    [addToast, navigate, unit],
  );

  return (
    <S.Container>
      <LabDsBreadcrumb breadcrumbs={createUnitBreadcrumbsItems} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.Title>Cadastrar Dados Bancários</S.Title>
        <S.InputContainer>
          <S.InputSubTitle>Dados da empresa</S.InputSubTitle>
          <S.InputRow>
            <Controller
              name="company_name"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors.company_name ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Nome da Unidade*"
                    label="Digite o nome da unidade"
                    helperText={errors.company_name?.message}
                  />
                );
              }}
            />
            <Controller
              name="cnpj"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors.cnpj ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="CNPJ*"
                    label="00.000.000/0001-00"
                    mask="CNPJ"
                    maxLength={14}
                    helperText={errors.cnpj?.message}
                  />
                );
              }}
            />
          </S.InputRow>
          <S.InputRow>
            <Controller
              name="telephone"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors.telephone ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Telefone*"
                    label="Digite o Telefone"
                    helperText={errors.telephone?.message}
                  />
                );
              }}
            />
            <Controller
              name="resp_name"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors.resp_name ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Nome do Responsável*"
                    label="Digite o nome do Responsável"
                    helperText={errors.resp_name?.message}
                  />
                );
              }}
            />
          </S.InputRow>
          <S.InputSubTitle>Localização</S.InputSubTitle>
          <S.InputRow>
            <Controller
              name="cep"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors?.cep?.message ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="CEP *"
                    label=""
                  />
                );
              }}
            />
            <Controller
              name="state"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors?.state?.message ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Estado*"
                    label=""
                    helperText={errors.state?.message}
                  />
                );
              }}
            />
            <Controller
              name="city"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors?.city?.message ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Cidade*"
                    label="Digite a Cidade"
                    helperText={errors.city?.message}
                  />
                );
              }}
            />
          </S.InputRow>
          <S.InputRow>
            <Controller
              name="address"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors?.address ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Rua*"
                    label="Digite o bairro"
                    helperText={errors?.address?.message}
                  />
                );
              }}
            />
            <Controller
              name="district"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as unknown as string}
                    state={errors?.district ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Bairro*"
                    label="Digite o bairro"
                    helperText={errors?.district?.message}
                  />
                );
              }}
            />
          </S.InputRow>
          <S.InputSubTitle>Dados Bancários</S.InputSubTitle>
          <S.InputRow>
            <Controller
              name="bank"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsSelect
                    value={field.value as unknown as string}
                    state={errors?.bank ? 'error' : 'default'}
                    onChangeInputOptions={({ detail }) =>
                      field.onChange(detail[0].label)
                    }
                    avatar={false}
                    titleInput="Banco*"
                    label="Selecione o banco"
                    helperText={errors?.bank?.message}
                    options={banksOptions}
                    type="radioButton"
                    size="medium"
                  />
                );
              }}
            />
            <Controller
              name="account_type"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsSelect
                    value={field.value as unknown as string}
                    state={errors?.account_type ? 'error' : 'default'}
                    onChangeInputOptions={({ detail }) =>
                      field.onChange(detail[0].label)
                    }
                    titleInput="Tipo de Conta*"
                    label="Selecione o tipo de conta"
                    helperText={errors?.account_type?.message}
                    options={accountsTypesOptions}
                    type="radioButton"
                    size="medium"
                    avatar={false}
                    isSearchable={false}
                  />
                );
              }}
            />
            <Controller
              name="bank_ag"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as unknown as string}
                    state={errors?.bank ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Agência*"
                    label="Digite a agência"
                    helperText={errors?.bank_ag?.message}
                  />
                );
              }}
            />
            <Controller
              name="bank_cc"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as unknown as string}
                    state={errors?.bank_cc ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Número da Conta*"
                    label="Digite o número da Conta"
                    helperText={errors?.bank_cc?.message}
                  />
                );
              }}
            />
          </S.InputRow>
          <S.ButtonsRow>
            <div>
              <LabDsButton
                label="Cancelar"
                variant="danger-outlined"
                type="button"
                onHandleButton={() => {
                  navigate(`/unit/view/head/${params?.unitId}`);
                }}
              />
              <LabDsButton
                label="Salvar"
                variant="primary"
                stepIcon="save"
                type="submit"
                onHandleButton={() => {
                  handleSubmit(onSubmit)();
                }}
              />
            </div>
          </S.ButtonsRow>
        </S.InputContainer>
      </form>
    </S.Container>
  );
}
