interface timeOptions {
  hour: 'numeric';
  minute: 'numeric';
  day: 'numeric';
  month: 'numeric';
  year: '2-digit';
  timeZone: 'America/Sao_Paulo';
}

export const UnixToReadableDate = (timestamp: number) => {
  const date = new Date(timestamp);

  const options: timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    timeZone: 'America/Sao_Paulo',
  };

  const formattedDate = new Intl.DateTimeFormat('pt-BR', options).format(date);
  return formattedDate;
};
