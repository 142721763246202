import { IData } from 'v4web-components';

export const tableColumnsCoupons = [
  {
    accessor: 'name',
    label: 'Nome',
    sizing: 1,
    sortable: true,
  },
  {
    accessor: 'desconto',
    label: 'Desconto',
    sizing: 1,
    sortable: true,
  },
  {
    accessor: 'validate',
    label: 'Validade',
    sizing: 2,
    sortable: true,
  },
  {
    accessor: 'Uso de cupons',
    label: 'Uso de cupons',
    sizing: 1,
    sortable: true,
  },
  {
    accessor: 'status',
    label: 'Status',
    sizing: 1,
    sortable: true,
  },
  {
    accessor: 'dotMenu',
    label: '',
    sizing: 1,
    sortable: true,
  },
];

export const tableColumnsFature = [
  {
    accessor: 'produto',
    label: 'Produto',
    sizing: 1,
    sortable: true,
  },
  {
    accessor: 'paymentMethod',
    label: 'Forma de pagamento',
    sizing: 1,
    sortable: true,
  },
  {
    accessor: 'valor',
    label: 'Valor',
    sizing: 1,
    sortable: true,
  },
  {
    accessor: 'pagador',
    label: 'Pagador',
    sizing: 1,
    sortable: true,
  },
  {
    accessor: 'telephone',
    label: 'Telefone',
    sizing: 1,
    sortable: true,
  },
  {
    accessor: 'data',
    label: 'Data',
    sizing: 1,
    sortable: true,
  },
  {
    accessor: 'hora',
    label: 'Hora',
    sizing: 1,
    sortable: true,
  },
  {
    accessor: 'status',
    label: 'Status',
    sizing: 1,
    sortable: true,
  },
];

export const headerTableHistoric: IData['headers'] = [
  {
    title: 'Investidor',
    colKey: 'name',
    type: 'text',

    sortable: false,
  },
  {
    colKey: 'mandate',
    title: 'Cargo',
    type: 'text',
    sortable: false,
  },
  {
    colKey: 'seniority',
    title: 'Senioridade',
    type: 'text',

    sortable: false,
  },
  {
    colKey: 'capacity',
    title: 'Capacidade',
    type: 'badge',
    sortable: false,
  },
  {
    colKey: 'productivity',
    title: 'Produtividade',
    type: 'select',
    sortable: false,
  },
  {
    colKey: 'productivityType',
    title: 'Tipo de Produtividade',
    type: 'text',
  },
];
