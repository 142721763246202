import React, { InputHTMLAttributes } from 'react';
import { IoIosHelp } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';

import * as S from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  borderColor?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  required?: boolean;
  mask?: string;
  info?: string;
  error?: string;
  width?: string;
}

export function Input({
  label,
  borderColor,
  leftIcon,
  rightIcon,
  required,
  mask,
  info,
  width,
  error,
  ...rest
}: InputProps) {
  return (
    <S.InputContent width={width}>
      <ReactTooltip />
      <S.Row>
        <S.InputLabel>
          {label}
          {required && <S.Required>*</S.Required>}
        </S.InputLabel>
        {info ? (
          <S.Tooltip>
            <IoIosHelp size={24} data-tip={info} />
          </S.Tooltip>
        ) : null}
      </S.Row>
      <S.Row>
        {leftIcon && <S.IconArea iconLeft>{leftIcon}</S.IconArea>}
        {mask ? (
          <S.InputToMask
            mask={mask}
            iconLeft={!!leftIcon}
            iconRight={!!rightIcon}
            borderColor={borderColor}
            {...rest}
          />
        ) : (
          <S.Input
            iconLeft={!!leftIcon}
            iconRight={!!rightIcon}
            borderColor={borderColor}
            {...rest}
          />
        )}
        {rightIcon && <S.IconArea iconRight>{rightIcon}</S.IconArea>}
      </S.Row>
      <S.Error>{error}</S.Error>
    </S.InputContent>
  );
}
