import { CSSProperties } from 'styled-components';

import { Container } from './styles';

interface LoadingBidProps {
  style?: CSSProperties;
  backgroundColor?: string;
  width?: string;
  height?: string;
  border?: string;
}

export function GlobalLoading({
  style,
  backgroundColor,
  width,
  height,
  border,
}: LoadingBidProps): JSX.Element {
  return (
    <Container
      className="loadingspinner"
      data-testid="loadingbid-container"
      style={style}
      backgroundColor={backgroundColor}
      width={width}
      height={height}
      border={border}
    >
      <div />
    </Container>
  );
}
