import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const DotMenu = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 1.5rem;

  svg {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
`;

export const DotOptions = styled.div<{ isHidden: boolean; reversed?: boolean }>`
  display: none;
  flex-direction: column;
  position: absolute;
  top: 2rem;
  ${({ reversed }) => reversed && 'left: -6rem;'}
  width: 8rem;
  transition: all 0.5s;
  border-radius: 0.2rem;
  border: 1px solid #b4b4cf;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);

  max-height: 15rem;
  z-index: 2;

  button {
    background: var(--white);
    padding: 1rem 1.5rem;
    border: none;
    font-weight: 400;
    text-align: left;
    pointer-events: none;
  }

  button + button {
    border-top: 1px solid var(--tertiary-dark);
  }

  ${({ isHidden }) =>
    isHidden &&
    css`
       {
        max-height: 15rem;
        overflow: hidden;
        display: flex;

        button {
          pointer-events: auto;
        }
      }
    `}
`;
