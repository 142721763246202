import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-basis: 33.3%;

  padding: 1.5rem 2rem;
  min-width: 22.75rem;

  border-radius: 8px;

  border: 1px solid var(--grayscale-10);
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.12);

  & > svg {
    margin-bottom: 1.5rem;
  }

  button {
    width: 10.1875rem;
    padding: 0.5rem;
    height: 2rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  flex-grow: 1;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 1rem;

  & p.bold {
    font-weight: 700;
  }
`;
