import React, { HTMLProps } from 'react';

import * as S from './styles';

interface SwitchProps extends HTMLProps<HTMLButtonElement> {
  active: boolean;
  sizing?: 'small';
}

export function Switch({ ...props }: SwitchProps) {
  const styles: string[] = [];

  if (props.active) {
    styles.push('active');
  }

  if (props.sizing !== undefined) {
    styles.push(props.sizing);
  }

  return (
    <S.SwitchWrapper className={styles.join(' ')} onClick={props.onClick}>
      <div className="switch-track" />
      <div className="switch-button" />
    </S.SwitchWrapper>
  );
}
