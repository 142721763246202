export const bankList = [
  {
    label: 'Banco do Brasil',
    id: '001',
    maxAgency: 6,
    maxAccount: 10,
    placeholderAgency: '9999-*',
    placeholderAccount: '99999999-*',
    icon: '1234455',
  },
  {
    label: 'Santander',
    id: '033',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '99999999-*',
  },
  {
    label: 'Caixa Econômica',
    id: '104',
    maxAgency: 4,
    maxAccount: 13,
    placeholderAgency: '9999',
    placeholderAccount: '99999999999-*',
  },
  {
    label: 'Bradesco',
    id: '237',
    maxAgency: 6,
    maxAccount: 9,
    placeholderAgency: '9999-*',
    placeholderAccount: '9999999-*',
  },
  {
    label: 'Itaú',
    id: '341',
    maxAgency: 4,
    maxAccount: 7,
    placeholderAgency: '9999',
    placeholderAccount: '99999-*',
  },
  {
    label: 'Itaú (Itaú Iti)',
    id: '341',
    maxAgency: 4,
    maxAccount: 11,
    placeholderAgency: '9999',
    placeholderAccount: '999999999-*',
  },
  {
    label: 'Banrisul',
    id: '041',
    maxAgency: 4,
    maxAccount: 11,
    placeholderAgency: '9999',
    placeholderAccount: '999999999-*',
  },
  {
    label: 'Sicredi',
    id: '748',
    maxAgency: 4,
    maxAccount: 9,
    placeholderAgency: '9999',
    placeholderAccount: '99999999*',
  },
  {
    label: 'Sicoob',
    id: '756',
    maxAgency: 4,
    maxAccount: 11,
    placeholderAgency: '9999',
    placeholderAccount: '999999999-*',
  },
  {
    label: 'Safra',
    id: '422',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '99999999-*',
  },
  {
    label: 'Votorantim',
    id: '655',
    maxAgency: 4,
    maxAccount: 9,
    placeholderAgency: '9999',
    placeholderAccount: '9999999-*',
  },
  {
    label: 'Inter',
    id: '077',
    maxAgency: 4,
    maxAccount: 11,
    placeholderAgency: '9999',
    placeholderAccount: '999999999-*',
  },
  {
    label: 'Nubank',
    id: '260',
    maxAgency: 4,
    maxAccount: 12,
    placeholderAgency: '9999',
    placeholderAccount: '9999999999-*',
  },
  {
    label: 'Agibank',
    id: '121',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '9999999999',
  },
  {
    label: 'Banpará',
    id: '037',
    maxAgency: 4,
    maxAccount: 11,
    placeholderAgency: '9999',
    placeholderAccount: '999999999-*',
  },
  {
    label: 'BRB',
    id: '070',
    maxAgency: 4,
    maxAccount: 11,
    placeholderAgency: '9999',
    placeholderAccount: '999999999-*',
  },
  {
    label: 'Via Credi',
    id: '085',
    maxAgency: 4,
    maxAccount: 13,
    placeholderAgency: '9999',
    placeholderAccount: '99999999999-*',
  },
  {
    label: 'Pagseguro (PagBank)',
    id: '290',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '99999999-*',
  },
  {
    label: 'Banco Original',
    id: '212',
    maxAgency: 4,
    maxAccount: 9,
    placeholderAgency: '9999',
    placeholderAccount: '9999999-*',
  },
  {
    label: 'Modal',
    id: '746',
    maxAgency: 4,
    maxAccount: 11,
    placeholderAgency: '9999',
    placeholderAccount: '999999999-*',
  },
  {
    label: 'Banestes',
    id: '021',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '99999999-*',
  },
  {
    label: 'Unicred',
    id: '136',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '99999999-*',
  },
  {
    label: 'Money Plus',
    id: '274',
    maxAgency: 1,
    maxAccount: 10,
    placeholderAgency: '9',
    placeholderAccount: '99999999-*',
  },
  {
    label: 'Mercantil do Brasil',
    id: '389',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '99999999-*',
  },
  {
    label: 'JP Morgan',
    id: '376',
    maxAgency: 4,
    maxAccount: 13,
    placeholderAgency: '9999',
    placeholderAccount: '99999999999-*',
  },
  {
    label: 'Gerencianet Pagamentos do Brasil',
    id: '364',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '99999999-*',
  },
  {
    label: 'Banco C6',
    id: '336',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '99999999-*',
  },
  {
    label: 'BS2',
    id: '218',
    maxAgency: 4,
    maxAccount: 9,
    placeholderAgency: '9999',
    placeholderAccount: '9999999-*',
  },
  {
    label: 'Banco Topazio',
    id: '082',
    maxAgency: 4,
    maxAccount: 7,
    placeholderAgency: '9999',
    placeholderAccount: '99999-*',
  },
  {
    label: 'Stone',
    id: '197',
    maxAgency: 4,
    maxAccount: 9,
    placeholderAgency: '9999',
    placeholderAccount: '9999999-*',
  },
  {
    label: 'Rendimento',
    id: '633',
    maxAgency: 6,
    maxAccount: 10,
    placeholderAgency: '9999-*',
    placeholderAccount: '9999999999',
  },
  {
    label: 'Banco Daycoval',
    id: '707',
    maxAgency: 4,
    maxAccount: 8,
    placeholderAgency: '9999',
    placeholderAccount: '999999-*',
  },
  {
    label: 'Banco do Nordeste',
    id: '004',
    maxAgency: 4,
    maxAccount: 8,
    placeholderAgency: '9999',
    placeholderAccount: '999999-*',
  },
  {
    label: 'Citibank',
    id: '745',
    maxAgency: 4,
    maxAccount: 8,
    placeholderAgency: '9999',
    placeholderAccount: '99999999',
  },
  {
    label: 'PJBank',
    id: '301',
    maxAgency: 4,
    maxAccount: 12,
    placeholderAgency: '9999',
    placeholderAccount: '9999999999-*',
  },
  {
    label: 'Cooperativa Central de Credito Noroeste Brasileiro',
    id: '097',
    maxAgency: 4,
    maxAccount: 9,
    placeholderAgency: '9999',
    placeholderAccount: '9999999-*',
  },
  {
    label: 'Uniprime',
    id: '099',
    maxAgency: 4,
    maxAccount: 6,
    placeholderAgency: '9999',
    placeholderAccount: '9999-*',
  },
  {
    label: 'Uniprime Norte do Paraná',
    id: '084',
    maxAgency: 4,
    maxAccount: 8,
    placeholderAgency: '9999',
    placeholderAccount: '999999-*',
  },
  {
    label: 'Global SCM',
    id: '384',
    maxAgency: 4,
    maxAccount: 11,
    placeholderAgency: '9999',
    placeholderAccount: '99999999999',
  },
  {
    label: 'Next',
    id: '237',
    maxAgency: 6,
    maxAccount: 9,
    placeholderAgency: '9999-*',
    placeholderAccount: '9999999-*',
  },
  {
    label: 'Cora',
    id: '403',
    maxAgency: 4,
    maxAccount: 9,
    placeholderAgency: '9999',
    placeholderAccount: '9999999-*',
  },
  {
    label: 'Mercado Pago',
    id: '323',
    maxAgency: 4,
    maxAccount: 12,
    placeholderAgency: '9999',
    placeholderAccount: '9999999999-*',
  },
  {
    label: 'Banco da Amazonia',
    id: '003',
    maxAgency: 4,
    maxAccount: 8,
    placeholderAgency: '9999',
    placeholderAccount: '999999-*',
  },
  {
    label: 'BNP Paribas Brasil',
    id: '752',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '999999-***',
  },
  {
    label: 'Juno',
    id: '383',
    maxAgency: 4,
    maxAccount: 12,
    placeholderAgency: '9999',
    placeholderAccount: '9999999999-*',
  },
  {
    label: 'Cresol',
    id: '133',
    maxAgency: 4,
    maxAccount: 11,
    placeholderAgency: '9999',
    placeholderAccount: '99999999-*',
  },
  {
    label: 'BRL Trust DTVM',
    id: '173',
    maxAgency: 4,
    maxAccount: 8,
    placeholderAgency: '9999',
    placeholderAccount: '999999-*',
  },
  {
    label: 'Banco Banese',
    id: '047',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '99999999-*',
  },
  {
    label: 'Banco BTG Pactual',
    id: '208',
    maxAgency: 4,
    maxAccount: 9,
    placeholderAgency: '9999',
    placeholderAccount: '9999999-*',
  },
  {
    label: 'Banco Omni',
    id: '613',
    maxAgency: 4,
    maxAccount: 8,
    placeholderAgency: '9999',
    placeholderAccount: '999999-*',
  },
  {
    label: 'Acesso Soluções de pagamento',
    id: '332',
    maxAgency: 4,
    maxAccount: 8,
    placeholderAgency: '9999',
    placeholderAccount: '99999999',
  },
  {
    label: 'CCR de São Miguel do Oeste',
    id: '273',
    maxAgency: 4,
    maxAccount: 5,
    placeholderAgency: '9999',
    placeholderAccount: '99999',
  },
  {
    label: 'Polocred',
    id: '093',
    maxAgency: 4,
    maxAccount: 8,
    placeholderAgency: '9999',
    placeholderAccount: '999999-*',
  },
  {
    label: 'Ótimo',
    id: '355',
    maxAgency: 4,
    maxAccount: 7,
    placeholderAgency: '9999',
    placeholderAccount: '99999-*',
  },
  {
    label: 'Picpay',
    id: '380',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '99999999-*',
  },
  {
    label: 'Banco Genial',
    id: '125',
    maxAgency: 4,
    maxAccount: 6,
    placeholderAgency: '9999',
    placeholderAccount: '9999-*',
  },
  {
    label: 'Banco Capital S.A',
    id: '412',
    maxAgency: 4,
    maxAccount: 9,
    placeholderAgency: '9999',
    placeholderAccount: '999999999',
  },
  {
    label: 'Banco Ribeirão Preto',
    id: '741',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '9999999999',
  },
  {
    label: 'ASAAS IP',
    id: '461',
    maxAgency: 4,
    maxAccount: 9,
    placeholderAgency: '9999',
    placeholderAccount: '9999999-*',
  },
  {
    label: 'Banco Pan',
    id: '623',
    maxAgency: 4,
    maxAccount: 11,
    placeholderAgency: '9999',
    placeholderAccount: '999999999-*',
  },
  {
    label: 'Neon',
    id: '655',
    maxAgency: 4,
    maxAccount: 12,
    placeholderAgency: '9999',
    placeholderAccount: '9999999999-*',
  },
  {
    label: 'VORTX DTVM LTDA',
    id: '310',
    maxAgency: 6,
    maxAccount: 5,
    placeholderAgency: '9999-*',
    placeholderAccount: '999-*',
  },
  {
    label: 'Banco BMG',
    id: '318',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '99999999-*',
  },
  {
    label: 'Fitbank',
    id: '450',
    maxAgency: 4,
    maxAccount: 10,
    placeholderAgency: '9999',
    placeholderAccount: '99999999-*',
  },
  {
    label: 'Pefisa',
    id: '174',
    maxAgency: 4,
    maxAccount: 9,
    placeholderAgency: '9999',
    placeholderAccount: '9999999-*',
  },
];
