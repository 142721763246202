import { AxiosError } from 'axios';
import { useMemo, useRef, useState } from 'react';

import { FiPhone, FiMail } from 'react-icons/fi';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';

import { useParams } from 'react-router-dom';
import { Button, Headline, Smalled } from '../../../../../LegacyV4Components';
import { Modal } from '../../../../../Default/Modal';
import { TextField } from '../../../../molecules/TextField';

import * as S from './styles';

import { SelectField } from '../../../../molecules/SelectField';

import { useToast } from '../../../../../../hooks/toast';
import { createStakeholder } from '../../../../../../services/requests/Customers/createStakeholder';
import { createCustomerUser } from '../../../../../../services/requests/Customers/createCustomerUser';

interface ModalProps {
  isShown: boolean;
  setIsShown(value: boolean): void;
  closeModal: () => void;
  openSwapModal: () => void;
  getStakeholders: () => void;
  stakeholders: Stakeholder[];
  setSelectedStakeholder: (value: Stakeholder) => void;
}

interface ICreateStakeholder {
  name: string;
  email: string;
  role: string;
  phone: string;
  customerId?: string;
}
export function CreateStakeholderModal({
  isShown,
  setIsShown,
  closeModal,
  openSwapModal,
  getStakeholders,
  setSelectedStakeholder,
  stakeholders,
}: ModalProps) {
  const { id } = useParams<{ id: string }>();

  const { addToast } = useToast();
  const [disableSend, setDisableSend] = useState(false);

  const stakeholderTypes = useMemo(
    () => [
      { value: 'Stakeholder principal', label: 'Stakeholder principal' },
      { value: 'Responsável financeiro', label: 'Responsável financeiro' },
      { value: 'Usuário adicional', label: 'Usuário adicional' },
      { value: 'Usuário teste', label: 'Usuário teste' },
    ],
    [],
  );

  const sendFormRef = useRef<HTMLButtonElement>(null);

  const stakeholderSchema = Yup.object().shape({
    name: Yup.string().required('O campo é obrigatório!'),
    phone: Yup.string()
      .required('O campo é obrigatório!')
      .matches(/\(\d{2}\)\s\d{5}-\d{4}/, {
        message: 'Insira um número de telefone válido',
      }),
    email: Yup.string()
      .required('O campo é obrigatório!')
      .email('Insira um endereço de e-mail válido'),
    role: Yup.string().required('O campo é obrigatório'),
  });

  async function createCustomerStakeholder(values: ICreateStakeholder) {
    const customerId = id as string;
    await createStakeholder(customerId, {
      name: values.name,
      role: values.role,
      tel: values.phone,
      email: values.email,
      mktLabUser: false,
    });

    await createCustomerUser({
      name: values.name,
      role: values.role,
      phone: values.phone,
      email: values.email,
      customerId,
    });
  }

  async function handleCreateStakeholder(values: ICreateStakeholder) {
    if (
      stakeholders.some((stakeholder) => stakeholder.email === values.email)
    ) {
      addToast({
        type: 'error',
        title: 'Erro ao criar!',
        description: 'Stakeholder já cadastrado',
      });
      return;
    }

    if (
      values.role === 'Stakeholder principal' &&
      stakeholders.some((s) => s.role === 'Stakeholder principal')
    ) {
      setSelectedStakeholder({ ...values, customerId: id as string });
      openSwapModal();
      closeModal();

      return;
    }

    await createCustomerStakeholder(values)
      .then(() => {
        addToast({
          title: 'Sucesso',
          description: 'Stakeholder criado com sucesso!',
          type: 'success',
        });

        getStakeholders();
        closeModal();
      })
      .catch((err: AxiosError) => {
        const error = err?.response?.data as { message: string };

        addToast({
          title: 'Atenção',
          description: `${error?.message ?? 'Erro ao adicionar stakeholder'}`,
          type: 'error',
        });
      });
  }

  return (
    <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
      <S.ModalWrapper>
        <S.ModalHeader>
          <Headline variant="h4">Adicionar stakeholder</Headline>
          <button
            type="button"
            onClick={() => setIsShown(false)}
            aria-label="default"
          >
            <FiX />
          </button>
        </S.ModalHeader>
        <S.ModalBody>
          <Smalled>Campos obrigatórios*</Smalled>
          <Formik
            initialValues={{
              name: '',
              role: '',
              phone: '',
              email: '',
            }}
            validationSchema={stakeholderSchema}
            onSubmit={async (values) => {
              setDisableSend(true);
              handleCreateStakeholder(values);
              setDisableSend(false);
            }}
          >
            {({ errors, values, handleBlur, setFieldValue }) => (
              <Form>
                <TextField
                  name="name"
                  label="Nome*"
                  value={values.name}
                  placeholder="Digite o nome do stakeholder"
                  helper={errors.name}
                  validateOnChange={false}
                  onBlur={handleBlur}
                />

                <SelectField
                  name="role"
                  label="Tipo de Stakeholder"
                  value={values.role}
                  placeholder="Selecione o tipo de stakeholder"
                  helper={errors.role}
                  options={stakeholderTypes}
                  onChange={({ value }: { value: string }) => {
                    setFieldValue('role', value);
                  }}
                  containerStyle={{ marginBottom: '2rem' }}
                />

                <TextField
                  name="phone"
                  label="Telefone*"
                  value={values.phone}
                  placeholder="Digite o telefone do stakeholder"
                  mask="+99 (99) 99999-9999"
                  rightIcon={<FiPhone />}
                  helper={errors.phone}
                  onBlur={handleBlur}
                />

                <TextField
                  name="email"
                  label="Email"
                  value={values.email}
                  placeholder="Digite o email do stakeholder"
                  rightIcon={<FiMail />}
                  helper={errors.email}
                  onBlur={handleBlur}
                />
                <button
                  ref={sendFormRef}
                  type="submit"
                  style={{ display: 'none' }}
                >
                  Enviar
                </button>
              </Form>
            )}
          </Formik>
        </S.ModalBody>
        <S.ModalFooter>
          <Button
            variant="primary"
            color="green"
            size="default"
            onClick={() => sendFormRef.current?.click()}
            disabled={disableSend}
          >
            Adicionar
          </Button>
          <Button
            variant="secondary"
            color="red"
            size="default"
            onClick={() => setIsShown(false)}
          >
            Cancelar
          </Button>
        </S.ModalFooter>
      </S.ModalWrapper>
    </Modal>
  );
}
