import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 64px;
  border-radius: 4px;
  border-top-left-radius: 0;
  width: 68%;
  margin-bottom: 12.75rem;
  @media (max-width: 880px) {
    width: auto;
  }
`;

export const OptionMethod = styled.div`
  padding: 8px;
  background: #f6f6f6;
  border: 1px solid #dadae7;
  small {
    font-size: 12px;
    font-weight: 400;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 65rem;
  margin: 0 auto;

  h3 {
    font-size: 1.75rem;
  }
`;

export const ContainerRadio = styled.div`
  display: flex;
  margin-right: 16px;
  align-items: center;
  width: 132px;
  height: 48px;
  border-radius: 4px;
  padding: 0px 10px 0px;
  background: #fff;
  border-bottom: 1px solid #dadae7;
  width: 100%;
  // border: ${(props) => props.color};

  input {
    background: #8383af;
  }

  .radio label input[type='radio'] {
    all: unset;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  input[type='radio']:checked {
    background-color: red;
  }

  h6 {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  img {
    width: 1.25rem;
    height: 1.25rem;
  }
`;
