import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;

  input {
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  p {
    margin-top: 1rem;
  }
`;
