import { useEffect, useState } from 'react';
import { FiCreditCard, FiDollarSign } from 'react-icons/fi';
import { Button } from '../../../LegacyV4Components';
import { Headline, Paragraph } from '../../../LegacyV4Components';
import { DataGrid, DataProps } from '../../atoms/DataGrid';
import { MidiaWalletGenerateChargeModal } from '../../organisms/MidiaWalletGenerateCharge';
import { PayTicketModal } from '../../molecules/PayTicketModal';

import * as S from './styles';
import {
  IDataHistoryWallet,
  useQueryWalletBalance,
  useQueryWalletHistory,
} from '../../../../services/requests/MediaWallet/getWallet';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';

export default function MediaWallet({ customer }: any) {
  const [isOpenGenerate, setIsOpenGenerate] = useState(false);
  const [isOpenModalPayTicket, setIsOpenModalPayTicket] = useState(false);
  const [tableData, setTableData] = useState<DataProps[]>([]);

  const { data: history } = useQueryWalletHistory(customer?._id);
  const { data: balance } = useQueryWalletBalance(customer?._id);
  const [walletHistory, setWalletHistory] = useState<IDataHistoryWallet[]>();

  const tableColumns = [
    {
      accessor: 'value',
      label: 'Valor',
      sizing: 2,
    },
    {
      accessor: 'type',
      label: 'Tipo',
      sizing: 2,
    },
    {
      accessor: 'status',
      label: 'Status',
      sizing: 1,
    },
  ];

  useEffect(() => {
    if (history?.data?.length !== 0) {
      setWalletHistory(history?.data);
    }
  }, [customer?._id, history?.data]);

  function statusDescription(status: string): string {
    switch (status) {
      case 'paid':
        return 'Pago';
      case 'pending':
        return 'Pendente';
      case 'canceled':
        return 'Cancelado';
      case 'expired':
        return 'Expirado';
      default:
        break;
    }
    return status;
  }

  useEffect(() => {
    const rows =
      walletHistory?.map((histories: IDataHistoryWallet) => {
        const content = (
          <>
            <div className="avatar-column" title={histories?._id}>
              {convertNumberToBRL(histories?.amount / 100)}
            </div>
            <div title={histories?.type}>
              <Paragraph>
                {histories?.type === 'exit' ? 'Saida' : 'Deposito'}
              </Paragraph>
            </div>
            <div className={histories?.status} title={histories?.status}>
              <Paragraph>{statusDescription(histories.status)}</Paragraph>
            </div>
            <div className="delete-column" title={histories.status} />
          </>
        );
        return {
          id: histories._id,
          content,
        };
      }) ?? [];

    setTableData(rows);
  }, [walletHistory]);

  return (
    <>
      <MidiaWalletGenerateChargeModal
        isShown={isOpenGenerate}
        setIsShown={setIsOpenGenerate}
        customerId={customer?._id}
      />
      <S.Container>
        <S.Header>
          <S.topLineHeader>
            <Headline variant="h4">Media Wallet</Headline>
            <S.Saldo>
              <p>Saldo:</p>
              <Headline variant="h5">
                {convertNumberToBRL(balance?.data ? balance?.data / 100 : 0) ||
                  0}
              </Headline>
            </S.Saldo>
          </S.topLineHeader>
          <S.Buttons>
            <Button
              size="default"
              color="red"
              variant="secondary"
              onClick={() => {
                setIsOpenModalPayTicket(true);
              }}
              // disabled={!data?.data.balance}
            >
              Realizar pagamento de mídia <FiCreditCard />
            </Button>
            <Button
              variant="primary"
              color="green"
              size="default"
              onClick={() => {
                setIsOpenGenerate(true);
              }}
            >
              Realizar deposito
              <FiDollarSign />
            </Button>
          </S.Buttons>
        </S.Header>
        <Headline variant="h5">Historico de solicitações</Headline>
        <DataGrid
          {...{
            columns: tableColumns,
            data: tableData,
          }}
          showModalUserInvate={false}
          setShowModalUserInvate={() => null}
          notClickable
        />
      </S.Container>
      <PayTicketModal
        isOpen={isOpenModalPayTicket}
        setIsOpen={setIsOpenModalPayTicket}
        customerId={customer?._id}
      />
    </>
  );
}
