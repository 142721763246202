import React from 'react';
import { FiX, FiChevronLeft } from 'react-icons/fi';
import { Button } from '../Button';

import * as S from './styles';

interface ModalProps {
  children: React.ReactNode;
  onBackground?: () => void;
  title?: string;
  subtitle?: string;
  closeButton?: boolean;
  show: boolean;
  disabled?: boolean;
  loading?: boolean;
  containerStyle?: React.CSSProperties;
  size?: 'sm' | 'md' | 'lg';
  handlerSubmit?: () => void;
  labelSubmit?: string;
  footer?: boolean;
  width?: string;
  height?: string;
  boxShadow?: string;
  zIndex?: string;
  visibleButtonFooter?: string;
  maxHeightModal?: string;
  mhModalBase?: string;
  mtModalHeader?: string;
  ptModalHeader?: string;
  arrowLeftButton?: boolean;
  alignItemContentHeader?: string;
  goBack?: () => void;
  buttonType?: string;
  visibleHeader?: boolean;
}

export function Modal({
  children,
  onBackground,
  show,
  disabled,
  loading,
  title,
  subtitle,
  closeButton,
  containerStyle,
  handlerSubmit,
  labelSubmit,
  footer,
  size = 'md',
  width,
  height,
  boxShadow,
  zIndex,
  visibleButtonFooter,
  maxHeightModal,
  mhModalBase,
  mtModalHeader,
  ptModalHeader,
  arrowLeftButton,
  alignItemContentHeader,
  goBack,
  buttonType,
  visibleHeader,
}: ModalProps) {
  return (
    <S.Container
      width={width}
      height={height}
      show={show}
      style={containerStyle}
    >
      <S.ModalBase
        size={size}
        width={width}
        height={height}
        mhModalBase={mhModalBase}
      >
        {(title || closeButton) && (
          <S.ModalHeader
            boxShadow={boxShadow}
            mtModalHeader={mtModalHeader}
            ptModalHeader={ptModalHeader}
            visibleHeader={visibleHeader}
          >
            <S.LeftModalContent alignItemContentHeader={alignItemContentHeader}>
              {arrowLeftButton && (
                <FiChevronLeft
                  fontSize={0}
                  className="arrowLeftButton"
                  style={{
                    marginTop: '10px',
                    marginLeft: 0,
                    color: 'var(--secondary-main)',
                  }}
                  onClick={goBack}
                />
              )}
              <S.ModalTextHeader>
                <h3>{title}</h3>
                <p>{subtitle}</p>
              </S.ModalTextHeader>
            </S.LeftModalContent>
            {closeButton && (
              <FiX style={{ color: '#8383AF' }} onClick={onBackground} />
            )}
          </S.ModalHeader>
        )}

        <S.ModalContent zIndex={zIndex} maxHeightModal={maxHeightModal}>
          <main>{children}</main>
        </S.ModalContent>
        {footer && (
          <S.ModalFooter visibleButtonFooter={`${visibleButtonFooter}`}>
            <div style={{ marginRight: 10 }}>
              {onBackground && (
                <Button
                  size="default"
                  color="red"
                  variant="secondary"
                  onClick={onBackground}
                  disabled={loading}
                >
                  Cancelar
                </Button>
              )}
            </div>
            {buttonType === 'submit' ? (
              <Button
                size="default"
                color="green"
                variant="primary"
                type="submit"
                onClick={() => null}
                loading={loading}
                disabled={disabled}
              >
                {labelSubmit || 'Enviar convite'}
              </Button>
            ) : (
              handlerSubmit && (
                <Button
                  size="default"
                  color="green"
                  variant="primary"
                  onClick={() => handlerSubmit()}
                  loading={loading}
                  disabled={disabled}
                >
                  {labelSubmit || 'Enviar convite'}
                </Button>
              )
            )}
          </S.ModalFooter>
        )}
      </S.ModalBase>

      <S.Background onClick={onBackground} />
    </S.Container>
  );
}
