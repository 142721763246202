import { Routes, Route } from 'react-router-dom';
import { NotFound } from '../components/LegacyV4Components';
import { HomeWithoutUnit } from '../pages/HomeWithoutUnit';
import NotFoundImage from '../assets/not_found.svg';
import { PaymentOptions } from '../pages/PaymentOptions';
import { PaymentSuccess } from '../pages/PaymentSuccess';
import { SubscriptionOptions } from '../pages/SubscriptionOptions';
import { SubscriptionSuccess } from '../pages/SubscriptionSuccess';
import { WalletPaymentOptions } from '../pages/WalletPaymentOptions';
import { PlanOptions } from '../pages/PlanOptions';
import { PaymentLookingAhead } from '../pages/PaymentLookingAhead';
import { BackofficeProjects } from '../pages/BackofficeProjects';

export default function LoggedInWithoutUnit() {
  return (
    <Routes>
      <Route index element={<HomeWithoutUnit />} />
      <Route path="*" element={<NotFound img={NotFoundImage} />} />
      <Route path="/pagamento/:id" element={<PaymentOptions />} />
      <Route path="/backoffice/projects" element={<BackofficeProjects />} />
      <Route path="/payment/LookingAhead" element={<PaymentLookingAhead />} />
      <Route path="/plan/:id" element={<PlanOptions />} />
      <Route path="payment/:id/success" element={<PaymentSuccess />} />
      <Route
        path="/carteira/pagamento/:id"
        element={<WalletPaymentOptions />}
      />
      <Route path="/assinatura/:id" element={<SubscriptionOptions />} />
      <Route path="/assinatura/:id/sucesso" element={<SubscriptionSuccess />} />
    </Routes>
  );
}
