import styled, { css } from 'styled-components';

export const Container = styled.div`
  border-radius: var(--lab-ds-core-dimension-dimension-50, 0.5rem);
  background: var(--lab-ds-semantic-color-bg-second-level, #fafafa);

  padding: 1rem;
  margin-top: 2.5rem;
  width: 100%;
`;

export const ContentBox = styled.div`
  border-radius: var(--lab-ds-core-dimension-dimension-50, 0.5rem);

  background: var(--lab-ds-semantic-color-bg-second-level, #fafafa);
  box-shadow: 0rem 0.125rem 0.188rem 0rem rgba(0, 0, 0, 0.12),
    0rem 0.375rem 0.625rem 0.25rem rgba(0, 0, 0, 0.06);

  padding: 1.25rem;
  width: 100%;
  margin-bottom: 2.5rem;

  h4 {
    font: var(--lab-ds-semantic-typography-heading-h4);
  }
  h5 {
    font: var(--lab-ds-semantic-typography-heading-h5);
    text-align: center;
    margin: 1.25rem 0;
  }
`;

export const Accordion = styled.div`
  margin-top: 2rem;
`;

export const TotalInvestiment = styled.div`
  border-radius: var(--lab-ds-core-dimension-dimension-50, 0.5rem);
  border: 0.063rem solid
    var(--lab-ds-semantic-placeholder-color-border-border-default, #bebec1);
  padding: 1.25rem;
  display: flex;
  display: flex;
  align-items: center;
`;
export const Extras = styled.div`
  display: flex;

  lab-ds-text-field {
    margin-right: 2rem;

    width: 16rem;
  }

  h6 {
    font: var(--lab-ds-semantic-typography-heading-h6);
    margin-right: 2rem;
    margin-top: 2.4rem;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  P {
    margin-right: 0.62rem;
  }

  h5 {
    margin-right: 1.25rem;
  }

  lab-ds-table {
    width: 100%;
  }
`;

export const Payment = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  lab-ds-table {
    width: 100%;
    max-width: 71rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  background-color: white;
  width: 100%;
  padding: 1.13rem;
  left: 0;
  bottom: 0;
  z-index: 99;
  border-radius: var(--lab-ds-core-dimension-dimension-0, 0rem);

  border-top: 0.063rem solid
    var(--lab-ds-semantic-selectable-color-border-default, #c6c6c6);
  background: var(--lab-ds-semantic-color-bg-second-level, #fafafa);

  box-shadow: 0rem 0.125rem 0.188rem 0rem rgba(0, 0, 0, 0.12),
    0rem 0.375rem 0.625rem 0.25rem rgba(0, 0, 0, 0.06);
`;

export const Observation = styled.div`
  width: 100%;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DescriptionHeader = styled.div`
  display: flex;
  gap: 2.5rem;
  margin: 0.5rem 0rem 2rem 0rem;

  .ContainerDescription {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
`;

export const Select = styled.div`
  margin-right: 2rem;
`;

export const Input = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

export const ButtonAction = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 0.75rem;
`;

export const Page = styled.div`
  width: 100%;
  padding: 2.5rem 5rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2.5rem 0;

  color: var(--lab-ds-semantic-color-fg-default, #070304);
`;

export const HeaderInfo = styled.div`
  display: flex;
  h1 {
    margin-right: 1rem;
  }

  svg {
    height: 2rem;
    margin: auto 0;
  }
`;

export const Content = styled.div<{ overflow?: boolean }>`
  margin-top: 2rem;

  .select,
  lab-ds-select {
    display: block;
    width: 27.875rem;
  }

  .select {
    margin-bottom: 2rem;
  }

  lab-ds-table {
    td {
      .cell-row-components {
        justify-content: start;
      }
    }

    ${({ overflow }) =>
      overflow &&
      css`
        .v4-table {
          overflow-x: visible;
          overflow-y: visible;
        }
      `}
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;

  align-self: stretch;
`;

export const RowContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;

  align-self: stretch;
`;

export const LabelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

export const TitleContent = styled.h6`
  color: #7f7f7f;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 0.875rem;
`;

export const DescriptionContent = styled.p`
  color: var(--secondary-light);
  font-family: Nunito Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
`;
