import { parseCookies } from 'nookies';
import axios from 'axios';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { federationRoiHuntersApi, financeApi } from '../../api';

export interface IPlans {
  _id: string;
  paymentProviderId: string;
  name: string;
  identifier: string;
  interval: number;
  intervalType: string;
  payableWith: string[];
  valueCents: number;
  royalties: number;
  customerId: string;
  subscriptions: Subscription[];
  disabled: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Subscription {
  _id: string;
  planIdentifier: string;
  planId: string;
  name: string;
  email: string;
  customerId: string;
  paymentCardToken: string;
  paymentProviderId: string;
  splitRulesId: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  maxCycles: number;
  invoices: Invoice[];
  cyclesCount: number;
  isActive: boolean;
  customerName: string;
  priceCents: number;
}

export interface Invoice {
  id: string;
  due_date: string;
  status: string;
  total: string;
  secure_url: string;
}

export interface IPlanList {
  _id: string;
  expiresAt: string;
  unitInformations: UnitInformations;
  customerInformations: CustomerInformations;
  accountId: string;
  cycledAt: string;
  suspended: boolean;
  planIdentifier: string;
  priceCents: number;
  customerEmail: string;
  payableWith: string;
  ignoreDueEmail: any;
  maxCycles: number;
  cyclesCount: number;
  recentInvoices: RecentInvoice[];
  subitems: Subitem[];
  logs: Log[];
  customVariables: CustomVariable[];
  externalCustomerId: string;
  planName: string;
  active: boolean;
  suspendOnInvoiceExpired: boolean;
  __v: number;
}

export interface UnitInformations {
  id: string;
}

export interface CustomerInformations {
  id: string;
  name: string;
}

export interface RecentInvoice {
  id: string;
  dueDate: string;
  status: string;
  total: number;
  secureUrl: string;
}

export interface Subitem {
  id: string;
  description: string;
  quantity: number;
  priceCents: number;
  recurrent: boolean;
  price: number;
  total: number;
}

export interface Log {
  id: string;
  description: string;
  notes: string;
  subscriptionChanges: string;
  createdAt: string;
}

export interface CustomVariable {
  name: string;
  value: string;
}

export async function getPlanList(
  recipientId: string | undefined,
): Promise<IPlanList | false> {
  try {
    const response = await federationRoiHuntersApi.get(
      `/subscriptions/${recipientId}`,
    );
    const plan: any = response.data;

    return plan;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar o plano, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}

export async function getPlanListV2(
  recipientId: string | undefined,
): Promise<IPlanList | false> {
  const cookies = parseCookies(undefined);
  const token = cookies['v4company.token'];
  const headers = token
    ? {
        Authorization: `Bearer ${cookies['v4company.token']}`,
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': 'POST, GET',
      }
    : undefined;

  const financeApiV2 = axios.create({
    baseURL: 'https://finance.roihunters.dev.mktlab.app',
    headers,
  });

  try {
    const response = await financeApiV2.get(
      `/subscriptions/plans?identifier=${recipientId}`,
    );
    const plan: any = response.data;

    return plan;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar o plano, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}

export function useQueryGetPlanList(
  planId: string | undefined,
): UseQueryResult<{ data: IPlans }, { data: IPlans }> {
  return useQuery<{ data: IPlans }, { data: IPlans }>(
    ['Customer', planId],
    () => financeApi.get<IPlans>(`/subscriptions/plans?planId=${planId}`),
    {},
  );
}
