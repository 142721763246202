import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { financeApi } from '../../api';

export interface CustomerFinance {
  _id: string;
  verifiedByProvider: boolean;
  haveAWSSecrets?: boolean;
  accountIsVerifiedOnIugu?: boolean;
  name: string;
  cnpj: string;
  customerId: string;
  telephone: string;
  bank: string;
  bankAgency: string;
  accountType: string;
  businessType: string;
  physicalProducts: boolean;
  bankCc: string;
  responsibleName: string;
  responsibleCpf: string;
  usePix: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export async function getFinanceCustomer(
  customerId: string,
): Promise<CustomerFinance | false> {
  try {
    const response = await financeApi.get(`/customers/${customerId}`);
    const customer: any = response.data;

    return customer;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar o pagamento, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}

export function useQueryGetFinanceCustomer(
  customerId: string | undefined,
): UseQueryResult<{ data: CustomerFinance }, { data: CustomerFinance }> {
  return useQuery<{ data: CustomerFinance }, { data: CustomerFinance }>(
    ['CustomerFinance', customerId],
    () => financeApi.get(`/customers/${customerId}`),
    {},
  );
}
