import styled from 'styled-components';

export const ModalBody = styled.div`
  text-align: center;
  padding: 3rem 0rem;

  & .close-button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;

    color: var(--grayscale-60);
  }

  .Headline {
    margin-bottom: 1rem;
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  flex-grow: 0;

  padding: 1.75rem 1.5rem;

  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.12);

  & > button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 1rem;
    height: 1rem;

    background: transparent;
    border: none;
    color: #83834f;

    & > svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  padding: 3.25rem 2.5rem 0rem 2.5rem;
  height: 100%;
`;
