import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 73.75rem;
  max-width: 95vw;

  height: 100%;
  max-height: calc(100vh - 2rem);

  border-radius: 0.5rem;
  background-color: var(--white);

  svg {
    color: var(--grayscale-60);
    margin-right: 1rem;
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  flex-grow: 0;

  padding: 1.75rem 1.5rem;

  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.12);

  & > button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 1rem;
    height: 1rem;

    background: transparent;
    border: none;
    color: #83834f;

    & > svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding: 1.5rem 2.5rem;

  overflow: auto;

  & > small {
    margin-bottom: 1rem;
  }
  max-height: 60vh;
  overflow-y: auto;

  .input-control {
    margin-right: 2rem;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 1rem;

  box-shadow: 0 -4px 8px 3px rgba(0, 0, 0, 0.06), 0 -1px 3px rgba(0, 0, 0, 0.12);

  padding: 1.5rem 2.5rem;
  height: 100%;
`;

export const MatrizRadio = styled.div`
  display: flex;
  margin-right: 1rem;
  align-items: center;
  width: 16.25rem;
  height: 3rem;
  border-radius: 0.25rem;
  padding: 1rem, 2rem, 1rem, 2rem;
  background: var(--tertiary-main);
  border: ${(props) => props.color};
  input {
    background: var(--grayscale-60);
    margin-left: 2rem;
    margin-right: 1.562rem;
  }

  p {
    color: var(--secondary-main);
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 2rem;
  }
`;

export const ContainerRadio = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  cursor: pointer;
  width: fit-content;

  input {
    appearance: none;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
    outline: none;
    transition: border-color 0.2s;
    position: relative;
    background: var(--white);
    border: 1px solid var(--grayscale-60);

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: var(--white);
    }
  }

  input:checked {
    border: 1px solid var(--success-2);
    background-color: var(--white);

    &::before {
      background-color: var(--success-2);
    }

    & ~ & {
      border-color: var(--success-2);
      box-shadow: 0px 2px 6px 2px rgba(0, 196, 154, 0.2),
        0px 1px 2px 0px rgba(0, 196, 154, 0.4);
    }
  }

  p {
    color: var(--secondary-main);
    font-size: 1rem;
    font-weight: 400;
  }

  &.containerRadio {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 3rem;
    padding: 1rem 2rem;
    gap: 0.5rem;
    margin-right: 1.5rem;

    border-radius: 0.25rem;
    background: var(--tertiary-main, #f6f6f6);

    border: ${(props) => props.color};
  }
`;

export const ContainerCheckbox = styled.div`
  border: ${(props) => props.color};
  display: flex;
  margin-right: 1rem;
  justify-content: space-evenly;
  align-items: center;
  width: max-content;
  padding: 2.5rem 2rem 2.5rem 2rem;
  height: 6rem;
  border-radius: 0.25rem;
  padding: 1rem 2rem 1rem 2rem;
  background: var(--tertiary-main);
  margin-bottom: 2rem;

  input {
    background: var(--grayscale-60);
    margin-right: 1.5rem;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    all: unset;
    margin: 0;
  }

  input[type='number'] {
    background: #e6e6ef;
    border: 0.5px solid var(--grayscale-6);
    border-radius: 0.125rem;
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    margin-right: 0rem;
    padding-left: 0rem;
  }

  p {
    color: var(--secondary-main);
    font-size: 1rem;
    font-weight: 400;
  }

  .ContainerSelectNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 2rem;
    margin-left: 0.625rem;
    border: 0.5px solid var(--grayscale-6);
    border-radius: 0.125rem;
    background: var(--grayscale-4);
  }

  .ContainerInstallments {
    display: flex;
  }
`;

export const CrescentNumber = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  background: var(--grayscale-4);
  border: none;
  padding-left: 0.313rem;
`;
export const DecrescentNumber = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  background: var(--grayscale-4);
  border: none;
`;
export const ContainerProductType = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  .couponsByProduct {
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
  }
  span,
  input {
    cursor: pointer;
  }
`;

export const SectionGenerate = styled.section`
  border-bottom: 1px solid #bbbbbb;
  padding-bottom: 2rem;
  margin-bottom: 2rem;

  small {
    display: flex;
    svg {
      margin-left: 1rem;
    }
  }

  .TooltipCheckout {
    span {
      background: var(--white);
      border: var(--grayscale-20);
      color: var(--secondary-main);
      font-size: 0.75rem;
      font-weight: 400;
    }
  }

  .ContainerDiscount {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    span {
      font-weight: 700;
      font-size: 1rem;
    }
  }

  .RowSection {
    display: flex;
    margin-top: 1.5rem;
    margin-bottom: -1.5rem;
  }
`;

export const CurrencyContainer = styled.div`
  .input-currency {
    height: 2.6rem;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid var(--grayscale-4);
    border-radius: 0.25rem;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 12%), 0px 2px 6px 2px rgb(0 0 0 / 6%);
  }
`;

export const DatePicker = styled.div`
  display: flex;
  align-items: center;

  border: 1px solid var(--grayscale-4, #e6e6ef);

  cursor: pointer;

  color: #7f7f7f;
  width: auto;
  min-height: 40px;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  margin-top: 1rem;
  border-radius: 4px;

  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;

  svg {
    cursor: pointer;
    margin: 0 0 0 1rem;
    color: var(--success-2);
  }

  &.focus {
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.06),
      0px 1px 2px 0px rgba(0, 0, 0, 0.12);
  }

  small {
    color: var(--secondary-light, #7f7f7f);

    font-family: Nunito Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
`;

export const Options = styled.div`
  display: flex;
`;

export const Option = styled.div<{ isSelect: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid var(--grayscale-8);
  border-radius: 1rem;
  padding: 0.25rem 0.8rem;
  cursor: pointer;
  background-color: ${({ isSelect }) =>
    isSelect ? 'var(--success-2)' : 'var(--grayscale-4)'};
  margin-inline: 0.25rem;
  svg {
    margin-left: 0.5rem;
    display: ${({ isSelect }) => !isSelect && 'none'};
  }
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 4.05rem;
  @media (max-width: 880px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const ButtonsPicker = styled.div`
  display: flex;
  justify-content: right;
  span {
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 14px;
  }
  .clean {
    color: var(--secondary-1);
    margin-right: 2rem;
  }
  .apply {
    color: var(--success-1);
  }
`;

export const ButtonPicker = styled.span`
  .clean {
    color: var(--secondary-1);
    margin-right: 2rem;
  }
  .apply {
    color: var(--success-1);
  }
`;

export const CardPicker = styled.div`
  position: absolute;
  border-radius: 0.25rem;
  padding: 1rem 1rem;
  top: 7rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 999;

  background-color: var(--tertiary-light);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 2px 6px 2px rgba(0, 0, 0, 0.06);
  border: 1px solid var(--grayscale-10);

  h6 {
    display: flex;
    align-items: center;
    color: #7f7f7f;
    svg {
      margin-right: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 0.875rem;
    border-bottom: 1px solid var(--grayscale-20);
  }

  word-break: break-all;

  .clean {
    color: var(--secondary-1);
    margin-right: 2rem;
  }

  .apply {
    color: var(--success-1);
  }

  &.ContainerDate {
    margin-top: -2rem;
    width: 35rem;

    @media (max-width: 880px) {
      width: fit-content;
      margin-top: 0.8rem;
    }
  }

  &.ContainerTime {
    display: flex;
    flex-direction column:
    justify-content: center;
    
    width: 100%;
    padding: 1rem;
    margin-top: -2rem;

    @media (max-width: 880px) {
      width: fit-content;
      margin-top: 0.8rem;
    }
  }

  .SelectorTime {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-right: 1.5rem;
    svg {
      margin-left: 1rem;
    }
  }

  @media (max-width: 880px) {
    margin: 1rem 0;
    min-width: 100%;
    min-height: 0%;
    top: 10rem;
  }

  @media (max-width: 1130px) {
    top: 8.5rem;
  }

`;

export const BoxTime = styled.input`
  width: 52px;
  height: 48px;
  border-radius: 3px;
  border: 1px solid var(--grayscale-4);
  padding: 0.5rem 1rem 0.5rem 1rem;

  background: var(--white);

  &:focus {
    outline: none;
  }

  &[type='number'] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;
