import React, { useEffect, useState } from 'react';

import { FiClock, FiCreditCard } from 'react-icons/fi';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { RiCalendarTodoLine, RiCoupon3Line } from 'react-icons/ri';
import { Box } from '@mui/material';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query';
import { NumberFormatValues } from 'react-number-format';
import {
  Button,
  Headline,
  Input,
  MultInputSearch,
} from '../../../LegacyV4Components';
import { Modal } from '../../../Default/Modal';
import { TextField } from '../../molecules/TextField';

import * as S from './styles';
import { FormikPercentageInput } from '../../molecules/FormikPercentageInput';
import { FormikCurrencyInput } from '../../molecules/FormikCurrencyInput';
import {
  IDataProductsByCoupons,
  ResumeCouponsModal,
} from '../ResumeCouponsModal';
import { DataCoupons } from '../../../../services/requests/Finance/getCouponsList';
import { NumberField } from '../../molecules/NumberField';
// import { SelectSearch } from '../../atoms/SelectSearch';

export interface ICouponsSelect {
  _id: string;
  name: string;
  initialValidity: string;
  endValidity: string;
  discountType: string;
  value: number;
  disable: boolean;
  products?: string[];
  createdAt: string;
  updatedAt: string;
  maximumQuantityAllowed: number;
  __v: number;
}

export type IProductByType = IProductByItem[];

export interface IProductByItem {
  _id: string;
  name: string;
  description: string;
  physicalProduct: boolean;
  // productType: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export function GenerateCouponsHQModal({
  isShown,
  setIsShown,
  productByType,
  refetch,
  couponsSelect,
  isEditCoupons,
}: {
  isShown: boolean;
  setIsShown: (value: React.SetStateAction<boolean>) => void;
  productByType: IProductByType;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<DataCoupons, DataCoupons>>;
  couponsSelect?: ICouponsSelect;
  isEditCoupons: boolean;
}) {
  const [isOpenResume, setIsOpenResume] = useState(false);
  const [showFilterByDate, setShowFilterByDate] = useState(false);
  const [showFilterByTime, setShowFilterByTime] = useState(false);
  const [select, setSelect] = useState('');
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [allProducts, setAllProducts] = useState(true);
  const [productSelect, setProductSelect] = useState<
    Array<IDataProductsByCoupons>
  >([]);
  const [productList, setProductList] = useState<Array<IDataProductsByCoupons>>(
    [],
  );
  const [firstDate, setFirstDate] = useState('');
  const [secondDate, setSecondDate] = useState('');
  const intervalDate = () => {
    if (firstDate && secondDate) {
      select && setSelect('');
      return `${format(parseISO(firstDate), 'dd/MM/yyyy', {
        locale: ptBR,
      })} até ${format(parseISO(secondDate), 'dd/MM/yyyy', {
        locale: ptBR,
      })}`;
    }
    if (firstDate === '' && couponsSelect?.initialValidity) {
      select && setSelect('');
      return `${format(
        parseISO(couponsSelect?.initialValidity),
        'dd MMMM yyyy',
        {
          locale: ptBR,
        },
      )} até ${format(parseISO(couponsSelect?.endValidity), 'dd MMMM yyyy', {
        locale: ptBR,
      })}`;
    }
    return 'Escolha um período';
  };

  const intervalTime = () => {
    if (hours || minutes) {
      return `Às ${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:00`;
    }
    return 'Escolha um horário';
  };

  const cleanAllDate = () => {
    setSelect('');
    setFirstDate('');
    setSecondDate('');
  };

  const cleanAllHours = () => {
    setHours(0);
    setMinutes(0);
  };

  const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (value <= 23) {
      setHours(value);
    } else {
      setHours(23);
    }
  };

  const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (value <= 60) {
      setMinutes(value);
    } else {
      setMinutes(60);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),
    initialValidity: Yup.date().required('A validade inicial é obrigatória'),
    discountType: Yup.string().required('O tipo de desconto é obrigatório'),
    endValidity: Yup.date().required('A validade final é obrigatória'),
  });

  useEffect(() => {
    const mappedItems = productByType?.map((item: IProductByItem) => ({
      value: item._id,
      label: item.name,
    }));

    if (productByType !== null || productByType !== undefined) {
      setProductList(mappedItems);
    }
  }, [productByType]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          name: `${couponsSelect?.name || ''}`,
          products: [`${couponsSelect?.products || ''}`],
          value: couponsSelect?.value || 0,
          initialValidity: `${couponsSelect?.initialValidity || ''}`,
          endValidity: `${couponsSelect?.endValidity || ''}`,
          discountType: `${couponsSelect?.discountType || ''}`,
          maximumQuantityAllowed: `${
            couponsSelect?.maximumQuantityAllowed || 0
          }`,
        }}
        validationSchema={validationSchema}
        onSubmit={async () => {
          null;
        }}
      >
        {({ errors, values, handleBlur, setFieldValue, isValid, dirty }) => (
          <>
            <>
              {isOpenResume ? (
                <ResumeCouponsModal
                  isShown={isOpenResume}
                  setIsOpenResume={setIsOpenResume}
                  setIsShown={setIsShown}
                  data={values}
                  products={productSelect}
                  hours={hours}
                  minutes={minutes}
                  refetch={refetch}
                  isEditCoupons={isEditCoupons}
                  IdCoupons={couponsSelect?._id || ''}
                />
              ) : null}
            </>
            <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
              <S.ModalWrapper>
                <S.ModalHeader>
                  <Headline variant="h4">
                    {' '}
                    {isEditCoupons === true ? 'Editar cupom' : 'Criar cupom'}
                  </Headline>
                  <button
                    type="button"
                    onClick={() => setIsShown(false)}
                    aria-label="default"
                  >
                    <FiX />
                  </button>
                </S.ModalHeader>
                <Form>
                  <S.ModalBody>
                    <S.SectionGenerate>
                      <Headline variant="h5">
                        <RiCoupon3Line />
                        {isEditCoupons === true
                          ? 'Editar cupom'
                          : 'Criar cupom'}
                      </Headline>
                      <Box className="RowSection">
                        <TextField
                          width="25rem"
                          name="name"
                          label="Nome do cupom"
                          value={values.name}
                          placeholder="EX: CUPOM10OFF"
                          validateOnChange={false}
                          onBlur={handleBlur}
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: '48px',
                            position: 'relative',
                          }}
                        >
                          <h4>Período de vigência</h4>
                          <S.DatePicker
                            onClick={() => {
                              setShowFilterByDate(!showFilterByDate);
                              setShowFilterByTime(false);
                            }}
                            className={`${showFilterByDate && 'focus'}`}
                          >
                            {intervalDate()}
                            <RiCalendarTodoLine color="var(--grayscale-60)" />
                          </S.DatePicker>
                          {showFilterByDate ? (
                            <S.CardPicker className="ContainerDate">
                              <Headline variant="h6">
                                <RiCalendarTodoLine />
                                Filtre por data
                              </Headline>
                              <p>Informe uma data</p>
                              <S.InfoRow>
                                <Input
                                  value={firstDate}
                                  onChange={(e) => {
                                    setFirstDate(e.target.value);
                                    setFieldValue(
                                      'initialValidity',
                                      e.target.value,
                                    );
                                  }}
                                  label=""
                                  name="initialValidity"
                                  type="date"
                                />
                                <Input
                                  value={secondDate}
                                  onChange={(e) => {
                                    setSecondDate(e.target.value);
                                    setFieldValue(
                                      'endValidity',
                                      e.target.value,
                                    );
                                  }}
                                  label=""
                                  name="endValidity"
                                  type="date"
                                />
                              </S.InfoRow>
                              <S.ButtonsPicker>
                                <S.ButtonPicker
                                  onClick={() => cleanAllDate()}
                                  className="clean"
                                >
                                  LIMPAR
                                </S.ButtonPicker>
                                <S.ButtonPicker
                                  onClick={() => setShowFilterByDate(false)}
                                  className="apply"
                                >
                                  APLICAR
                                </S.ButtonPicker>
                              </S.ButtonsPicker>
                            </S.CardPicker>
                          ) : null}
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                          }}
                        >
                          <h4>Horário de encerramento</h4>
                          <S.DatePicker
                            onClick={() => {
                              setShowFilterByTime(!showFilterByTime);
                              setShowFilterByDate(false);
                            }}
                            className={`${showFilterByTime && 'focus'}`}
                          >
                            {intervalTime()}{' '}
                            <FiClock color="var(--grayscale-60)" />
                          </S.DatePicker>
                          {showFilterByTime ? (
                            <S.CardPicker className="ContainerTime">
                              <p>Informe um horário</p>
                              <S.InfoRow>
                                <Box
                                  sx={{ display: 'flex', flexDirection: 'row' }}
                                >
                                  <Box className="SelectorTime">
                                    <FaChevronUp
                                      color="#00c49a"
                                      onClick={() => {
                                        if (hours < 23) setHours(hours + 1);
                                      }}
                                    />
                                    <S.BoxTime
                                      type="number"
                                      min="0"
                                      max="23"
                                      value={hours.toString().padStart(2, '0')}
                                      onChange={handleHoursChange}
                                    />
                                    <FaChevronDown
                                      color="#00c49a"
                                      onClick={() => {
                                        if (hours > 0) setHours(hours - 1);
                                      }}
                                    />
                                  </Box>
                                  <Box className="SelectorTime">
                                    <FaChevronUp
                                      color="#00c49a"
                                      onClick={() => {
                                        if (minutes < 60)
                                          setMinutes(minutes + 1);
                                      }}
                                    />
                                    <S.BoxTime
                                      type="number"
                                      min="0"
                                      max="60"
                                      value={minutes
                                        .toString()
                                        .padStart(2, '0')}
                                      onChange={handleMinutesChange}
                                    />
                                    <FaChevronDown
                                      color="#00c49a"
                                      onClick={() => {
                                        if (minutes > 0)
                                          setMinutes(minutes - 1);
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </S.InfoRow>
                              <S.ButtonsPicker>
                                <S.ButtonPicker
                                  onClick={() => cleanAllHours()}
                                  className="clean"
                                >
                                  LIMPAR
                                </S.ButtonPicker>
                                <S.ButtonPicker
                                  onClick={() => setShowFilterByTime(false)}
                                  className="apply"
                                >
                                  APLICAR
                                </S.ButtonPicker>
                              </S.ButtonsPicker>
                            </S.CardPicker>
                          ) : null}
                        </Box>
                      </Box>
                      <NumberField
                        format="####"
                        name="maximumQuantityAllowed"
                        label="Limite de cupons *"
                        value={values?.maximumQuantityAllowed || undefined}
                        placeholder="Informe o número de cupons"
                        validateOnChange={false}
                        onBlur={handleBlur}
                        allowLeadingZeros
                        isNumericString={false}
                        onValueChange={(e: NumberFormatValues) =>
                          setFieldValue('maximumQuantityAllowed', e.floatValue)
                        }
                      />
                    </S.SectionGenerate>
                    <S.SectionGenerate>
                      <Headline variant="h5">
                        <FiCreditCard />
                        Cadastrar produto
                      </Headline>
                      <S.ContainerProductType>
                        <S.ContainerRadio
                          onClick={() => {
                            setAllProducts(true);
                          }}
                        >
                          <input type="radio" checked={allProducts} />
                          <span>Aplicar para todos os produtos</span>
                        </S.ContainerRadio>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: '1rem',
                            alignItems: 'center',
                          }}
                        >
                          <S.ContainerRadio
                            onClick={() => {
                              setAllProducts(false);
                            }}
                          >
                            <input
                              type="radio"
                              checked={allProducts === false}
                              onChange={() => setAllProducts(false)}
                            />
                            <span>Selecionar produtos específicos </span>
                          </S.ContainerRadio>
                          <Box
                            className={allProducts ? 'couponsByProduct' : ''}
                          >
                            <MultInputSearch
                              label=""
                              maxItem={100}
                              options={
                                productList !== undefined ? productList : []
                              }
                              placeholder="Selecione o(s) produto(s) que você deseja definir para uso do cupom"
                              itensSelected={productSelect}
                              setItensSelected={setProductSelect}
                            />
                          </Box>
                        </Box>
                      </S.ContainerProductType>
                    </S.SectionGenerate>
                    <S.SectionGenerate>
                      <Headline variant="h5">
                        <FiCreditCard />
                        Desconto
                      </Headline>
                      <Box
                        sx={{
                          display: 'flex',
                          margin: '1.5rem 0 0 0',
                        }}
                      >
                        <S.ContainerRadio
                          className="containerRadio"
                          color={
                            values?.discountType === 'percentage'
                              ? '1px solid #00C49A'
                              : ''
                          }
                          onClick={() => {
                            setFieldValue('discountType', 'percentage');
                          }}
                        >
                          <input
                            name="discountType"
                            type="radio"
                            checked={values?.discountType === 'percentage'}
                            onChange={() => {
                              setFieldValue('discountType', 'percentage');
                            }}
                          />
                          <strong>Percentual</strong>
                        </S.ContainerRadio>
                        <S.ContainerRadio
                          className="containerRadio"
                          color={
                            values?.discountType === 'value'
                              ? '1px solid #00C49A'
                              : ''
                          }
                          onClick={() => {
                            setFieldValue('discountType', 'value');
                          }}
                        >
                          <input
                            name="discountType"
                            type="radio"
                            checked={values?.discountType === 'value'}
                            onChange={() => {
                              setFieldValue('discountType', 'value');
                            }}
                          />
                          <strong>Preço fixo</strong>
                        </S.ContainerRadio>
                      </Box>
                      {values?.discountType === 'percentage' ? (
                        <Box className="ContainerDiscount">
                          <span>Informe a porcentagem de desconto</span>
                          <FormikPercentageInput
                            number
                            width="9.375rem"
                            name="value"
                            label=""
                            value={values.value}
                            placeholder="0%"
                            helper={errors.value}
                            validateOnChange={false}
                            onBlur={handleBlur}
                            status={
                              values.value >= 40 && values.value <= 70
                                ? 'warning'
                                : 'default'
                            }
                            onValueChange={(value) => {
                              setFieldValue('value', value);
                            }}
                            warningMessage="O valor informado é relativamente alto. Verifique se esse é o valor realmente acordado."
                          />
                        </Box>
                      ) : (
                        values?.discountType === 'value' && (
                          <Box className="ContainerDiscount">
                            <span>Informe o valor de desconto</span>
                            <FormikCurrencyInput
                              width="8rem"
                              onValueChange={(value) => {
                                setFieldValue('value', value);
                              }}
                              value={values?.value}
                            />
                          </Box>
                        )
                      )}
                    </S.SectionGenerate>
                  </S.ModalBody>
                </Form>
                <S.ModalFooter>
                  <Button
                    variant="primary"
                    color="green"
                    size="default"
                    onClick={async () => {
                      setIsOpenResume(true);
                      setIsShown(false);
                    }}
                    disabled={!(isValid && dirty)}
                  >
                    Prosseguir para tela de resumo
                  </Button>

                  <Button
                    variant="secondary"
                    color="red"
                    size="default"
                    onClick={() => setIsShown(false)}
                  >
                    Cancelar
                  </Button>
                </S.ModalFooter>
              </S.ModalWrapper>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
}
