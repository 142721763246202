import { parseCookies } from 'nookies';
import { unitsApi as api } from '../../api';

export async function getUnits(
  page: number,
  query?: string,
  unlimited?: boolean,
) {
  const cookies = parseCookies(undefined);
  const token = cookies['v4company.token'];
  try {
    const { data } = await api({
      method: 'GET',
      url: `/units?q=${query}&withPartners=true&page=${page}&${
        unlimited ? '' : 'limit=10'
      }`,
      headers: { Authorization: `Bearer ${token}` },
    });
    const units = data;
    return units;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os usuários, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
