import { ReactNode } from 'react';
import { MdRemoveCircleOutline } from 'react-icons/md';
import { Input, Paragraph } from '../../../../LegacyV4Components';

import {
  CustomerIntegrationsContextData,
  CustomerIntegrationsProvider,
} from '../../../../../hooks/customerIntegrations';

import { TieAccountsModal } from '../TieAccountsModal';

import { bitrix as options } from '../platforms';

import * as Edit from '../components/IntegrationEditRow/styles';

interface BitrixModalProps {
  show: boolean;
  onClose: () => void;
}

export function ConfigBitrixModal({ show, onClose }: BitrixModalProps) {
  const tableColumns = [
    {
      accessor: 'customer',
      label: 'Cliente',
      sizing: 25,
      sizingUnit: 'vw',
      sortable: true,
    },
    {
      accessor: 'link',
      label: 'URL',
      sizing: 1,
      sortable: true,
    },
    {
      accessor: 'actions',
      label: '',
      sizing: 0.075,
      sortable: false,
    },
    {
      accessor: 'status',
      label: 'Status',
      sizing: 0.75,
      sortable: false,
    },
  ];

  function isConnected(integration: AdsAccounts): boolean {
    return (
      !!integration.link && !!integration.projectId && !integration.isDeleted
    );
  }

  function validate(link?: string): boolean {
    if (!link) return false;
    try {
      const url = new URL(link);
      const accountName = url.hostname.split('.')[0];
      const authKey = url.pathname
        .split('/')
        .reverse()
        .find((item) => item.length);

      return !!accountName && !!authKey;
    } catch (e) {
      return false;
    }
  }

  function additionalValidation(integration: AdsAccounts): boolean {
    return !!integration.link && validate(integration?.link);
  }

  function renderEditRow(
    integration: AdsAccounts,
    error: boolean,
    context: CustomerIntegrationsContextData,
  ): ReactNode {
    const { setBitrixFields, deleteIntegration } = context;
    const linkError = error && !validate(integration.link);
    return (
      <>
        <Edit.Row id={integration.link}>
          <div title={integration.link}>
            <Input
              label=""
              value={integration.link}
              name="link"
              placeholder="https://example.bitrix24.com.br/rest/29/1q2w3e4r5t6y7u8"
              onChange={({ target: { value } }) =>
                setBitrixFields(integration, value)
              }
              borderColor={linkError ? 'var(--error)' : ''}
            />
            {linkError && <Edit.Error>URL inválida</Edit.Error>}
          </div>
        </Edit.Row>
        <Edit.Row>
          <MdRemoveCircleOutline
            size={22}
            onClick={() => deleteIntegration(integration)}
          />
        </Edit.Row>
      </>
    );
  }

  function renderSummaryRow(integration: AdsAccounts): ReactNode {
    return (
      <>
        <td title={integration.link}>
          <Paragraph>{integration.link}</Paragraph>
        </td>
        <td aria-label="default" />
      </>
    );
  }

  return (
    <CustomerIntegrationsProvider type={options.type} show={show}>
      <TieAccountsModal
        options={options}
        show={show}
        onClose={onClose}
        tableColumns={tableColumns}
        renderEditRow={renderEditRow}
        renderSummaryRow={renderSummaryRow}
        isConnected={isConnected}
        additionalValidation={additionalValidation}
      />
    </CustomerIntegrationsProvider>
  );
}
