import { kubernetesApi as api } from '../../api';
import { UnitCreationData } from '../../../pages/CreateUnit/constants/utils';

export async function CreateUnitFederation(
  data: UnitCreationData,
): Promise<UnitCreationData | { error: string }> {
  try {
    const response = await api.post<UnitCreationData | { error: string }>(
      `/unit`,
      data,
    );
    const unit = response.data;
    return unit;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o unidade, atualize a página e tente novamente 😕`,
      err,
    );
    return { error: 'Erro ao criar unidade' };
  }
}
