import { ReactNode } from 'react';
import { Paragraph } from '../../../../../LegacyV4Components';

import { IntegrationStatus } from '../IntegrationStatus';

interface IntegrationSummaryRowProps {
  integration: AdsAccounts;
  renderSummaryRow: (integration: AdsAccounts) => ReactNode;
}

export function IntegrationSummaryRow({
  integration,
  renderSummaryRow,
}: IntegrationSummaryRowProps) {
  const selectedCustomer = integration?.value?.label ?? '-';

  return (
    <>
      <td title={selectedCustomer}>
        <Paragraph>{selectedCustomer}</Paragraph>
      </td>

      {renderSummaryRow(integration)}

      <IntegrationStatus integration={integration} />
    </>
  );
}
