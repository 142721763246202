import { v4 } from 'uuid';

import { Team as TeamProps } from '../../../types/team';

export const teamMock: () => TeamProps = () => {
  return {
    id: v4(),
    name: 'Unidade XPTO',
    description: 'Sem descrição',
    nps: '6,5 - 3/20 clientes',
    fee: 4000,
    ltv: 12000,
  };
};
