import { kubernetesApi } from '../../api';

interface IDataToSend {
  id: string;
  body: {
    unitId: string;
  };
}

export async function updateUnitOfProject(dataToSend: IDataToSend) {
  try {
    const response = await kubernetesApi.put<{
      data: any;
    }>(`/customers/update-project`, {
      id: dataToSend?.id,
      body: dataToSend?.body,
    });

    const customers = response.data;

    return customers;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os dados dos clientes, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
