import { usersApi } from '../../api';

export interface ConfirmRegisterResponse {
  email: string;
  name: string;
  unitId: string;
  inviteToken?: string;
  isCostumer?: boolean;
}

export async function confirmRegister(
  token: string,
): Promise<ConfirmRegisterResponse | false> {
  try {
    const response = (await usersApi.post(`/user/validateInvite`, {
      token,
    })) as ConfirmRegisterResponse;

    return response;
  } catch (err) {
    console.error(err.response.data.message || err);
    return false;
  }
}
