import { SetStateAction, useState } from 'react';
import {
  LabDsButton,
  LabDsDatePicker,
  LabDsIconNotSelectable,
  LabDsSelect,
  LabDsTextArea,
  LabDsTextField,
} from 'v4web-components-react';
import { MdClose } from 'react-icons/md';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useToast } from '../../../../hooks/toast';
import { deleteUser } from '../../../../services/requests/User/delete';
import { User, deleteUserBodyProps } from '../../../../hooks/types';

import * as S from './styles';
import { useAuth } from '../../../../hooks/auth';
import { deleteInvestorSchema } from './schemas';
import { Modal } from '../../../LegacyV4Components';

interface InviteNewColabProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  modalProps: ModalProps;
}

interface ModalProps {
  disableUnitProps: {
    active?: boolean;
    id: string;
    email: string;
    unitId: string;
    name: string;
  };
  user: User;
  getUser: React.Dispatch<SetStateAction<User | undefined>>;
}
export function ActionUser({
  isOpen,
  setIsOpen,
  modalProps,
}: InviteNewColabProps) {
  const { addToast } = useToast();
  const { user } = useAuth();

  const [observation, setObservation] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorName, setErrorName] = useState<boolean>(false);

  function closeModal() {
    setObservation('');
    setIsOpen(false);
  }

  const {
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<deleteUserBodyProps>({
    resolver: async (data, context, options) => {
      const result = await yupResolver(deleteInvestorSchema)(
        data,
        context,
        options,
      );
      yupResolver(deleteInvestorSchema);
      const nameIsDifferent =
        getValues('user.name') !== modalProps.disableUnitProps?.name &&
        !!getValues('user.name');
      setErrorName(nameIsDifferent);
      if (Object.keys(result.errors).length || nameIsDifferent) {
        addToast({
          type: 'error',
          title: 'Verifique se todos os dados foram preenchidos corretamente!',
        });
      }
      return result;
    },
  });

  const handlerAction = async () => {
    setLoading(true);

    const body: deleteUserBodyProps = {
      user: {
        userId: modalProps.disableUnitProps.id,
        emailV4: modalProps.disableUnitProps.email,
        unitId: modalProps.disableUnitProps.unitId,
        name: modalProps.disableUnitProps.name,
      },
      wouldRecommend: getValues('wouldRecommend'),
      investorChoice: getValues('investorChoice'),
      investorLastDay: getValues('investorLastDay'),
      motivationOfFired: getValues('motivationOfFired'),
      observations: observation,
      whoDeleted: {
        name: user.name,
        userWhoDeletedId: user._id,
        emailV4: user.email,
      },
    };

    const userDelete = await deleteUser(modalProps.disableUnitProps.id, body);

    setLoading(false);
    modalProps.getUser(userDelete as User);
    if (userDelete) {
      addToast({
        type: 'success',
        title: `Usuário Deletado com sucesso`,
      });
      closeModal();
      return;
    }
    addToast({
      type: 'error',
      title: 'Não foi possivel concluir a ação no momento!',
      description: 'Tente novamente mais tarde!',
    });
  };

  const [optionsWhoTakeTheIniciative] = useState([
    { label: 'Unidade', isChecked: false, key: 'unidade' },
    { label: 'Investidor', isChecked: false, key: 'investidor' },
  ]);

  const [optionsRecomend] = useState([
    { label: 'Sim', isChecked: false, key: 'sim' },
    { label: 'Não', isChecked: false, key: 'não' },
  ]);

  const [optionsMotiveToBeFired] = useState([
    {
      label: 'Desalinhamento cultural',
      isChecked: false,
      key: 'culture',
    },
    {
      label: 'Mudança de ciclos/objetivos',
      isChecked: false,
      key: 'change_Objective',
    },
    {
      label: 'Oferta externa',
      isChecked: false,
      key: 'external_Offer',
    },
    {
      label: 'Oferta interna (troca de franquia)',
      isChecked: false,
      key: 'intern_Offer',
    },
    {
      label: 'Performance abaixo do esperado',
      isChecked: false,
      key: 'Performance',
    },
    {
      label: 'Falta de profissionalismo/conduta ética',
      isChecked: false,
      key: 'no_Ethic',
    },
  ]);

  return (
    <Modal show={isOpen} onBackground={closeModal}>
      <S.Modal>
        <S.CloseButton onClick={closeModal}>
          <MdClose size={16} />
        </S.CloseButton>
        <S.ModalHeader>
          <LabDsIconNotSelectable icon="cancel" size="x-large" />
          <h1>Deseja excluir o perfil {modalProps.disableUnitProps?.name}</h1>
          <p>
            Para excluir o investidor é necessário preencher os campos abaixo
          </p>
        </S.ModalHeader>

        <form onSubmit={handleSubmit(handlerAction)}>
          <S.Row>
            <LabDsTextField
              titleInput="Nome do investidor*"
              label="Nome do investidor"
              value={getValues('user.name')}
              onChangeInput={(e) => {
                setValue('user.name', e.detail);
              }}
              helperText={
                errors.user?.name || errorName ? 'Prencha o campo' : ''
              }
              state={errors.user?.name || errorName ? 'error' : 'default'}
            />
            <LabDsSelect
              titleInput="Quem tomou a iniciativa do desligamento?*"
              label="Selecione uma opção"
              type="radioButton"
              avatar={false}
              size="medium"
              isSearchable={false}
              options={optionsWhoTakeTheIniciative}
              onChangeInputOptions={(e) => {
                setValue('investorChoice', e.detail[0].key === 'investidor');
              }}
              helperText={errors.investorChoice ? 'Selecione uma opção' : ''}
              state={errors.investorChoice ? 'error' : 'default'}
            />
          </S.Row>

          <S.Row>
            <LabDsSelect
              className="SelectWithBigTitle"
              titleInput="Você recomendaria o investidor para atuar em outra unidade da rede?*"
              label="Selecione uma opção"
              type="radioButton"
              avatar={false}
              size="medium"
              isSearchable={false}
              options={optionsRecomend}
              onChangeInputOptions={(e) => {
                setValue('wouldRecommend', e.detail[0].key === 'sim');
              }}
              helperText={errors.wouldRecommend ? 'Selecione uma opção' : ''}
              state={errors.wouldRecommend ? 'error' : 'default'}
            />

            <LabDsDatePicker
              titleInput="Qual o último dia do investidor na unidade?*"
              value={date}
              onChangeDatePicker={(e) => {
                setDate(e.detail);
                setValue('investorLastDay', e.detail);
              }}
              size="medium"
              helperText={errors.investorLastDay ? 'Selecione uma data' : ''}
              state={errors.investorLastDay ? 'error' : 'default'}
            />
          </S.Row>

          <S.Motive>
            <LabDsSelect
              titleInput="Qual o motivo do desligamento?*"
              label="Selecione uma opção"
              avatar={false}
              size="medium"
              isSearchable={false}
              options={optionsMotiveToBeFired}
              onChangeInputOptions={(e) => {
                const motives = e.detail.map((item) => {
                  return item.key;
                });
                setValue('motivationOfFired', motives);
              }}
              helperText={errors.motivationOfFired ? 'Selecione uma opção' : ''}
              state={errors.motivationOfFired ? 'error' : 'default'}
            />
          </S.Motive>

          <LabDsTextArea
            titleInput="Observações"
            value={observation}
            onChangeInput={(e) => {
              setObservation(e.detail);
            }}
            cols={100}
          />

          <S.ModalFooter>
            <div style={{ marginRight: 10 }}>
              <LabDsButton
                label="Cancelar"
                variant="danger-outlined"
                onClick={closeModal}
              />
            </div>
            <LabDsButton
              label="Excluir"
              variant="danger-primary"
              type="submit"
              onClick={handleSubmit(handlerAction)}
              loading={loading}
            />
          </S.ModalFooter>
        </form>
      </S.Modal>
    </Modal>
  );
}
