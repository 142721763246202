import styled from 'styled-components';

export const Modal = styled.div`
  padding: 1.5rem;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  flex-direction: row;
  justify-content: space-between;

  lab-ds-select,
  lab-ds-date-picker,
  lab-ds-text-field {
    max-width: 18.84rem;
    width: 100%;
    height: 4.75rem;
  }
  .SelectWithBigTitle {
    height: 5.81rem;
  }
`;
export const Motive = styled.div`
  margin-bottom: 2rem;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-bottom: 3.25rem;

  lab-ds-icon-not-selectable {
    margin-bottom: 0.75rem;
    color: var(--lab-ds-core-color-red-arterial-50);

    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3.125rem;
`;

export const CloseButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1rem;
  right: 1rem;

  cursor: pointer;
`;
