import { serverlessGateway } from '../../api';

interface CreatedUserEletromidia {
  id: number;
  name: string;
  email: string;
  type: string;
  document: string;
  phone: string;
  date_of_birth: string | null;
  profile: string | null;
  addresses: Address[];
}

export async function createUser(
  user: CreateUserData,
): Promise<CreatedUserEletromidia | false> {
  try {
    const response: any = await serverlessGateway.post(
      'users/eletromidia/create-user',
      user,
    );

    const userData: CreatedUserEletromidia = response.data.user;

    return userData;
  } catch (error) {
    return false;
  }
}
