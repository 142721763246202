export const genderItens: GenderTypes[] = [
  {
    label: 'Masculino',
    value: 'MALE',
  },
  {
    label: 'Feminino',
    value: 'FEMALE',
  },
  {
    label: 'Outros',
    value: 'OTHERS',
  },
  {
    label: 'Prefiro não dizer',
    value: 'NOT_TO_SAY',
  },
];

export interface GenderTypes {
  label: string;
  value?: string;
  info?: string;
}
