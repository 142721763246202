import { useState } from 'react';
import { Breadcrumb, Headline } from '../../components/LegacyV4Components';
import { useAuth } from '../../hooks/auth';

import * as S from './styles';

export function RoiWeek() {
  const { user } = useAuth();
  const [isFullScreen] = useState(false);

  const breadcrumbsItems = [
    { title: 'Minha Unidade', link: `/unit/read/${user.unitId}` },
    { title: 'Space | ROI Week', link: '' },
  ];

  return (
    <>
      <S.RoiContainer style={{ flexGrow: 1 }}>
        <Breadcrumb breadcrumbs={breadcrumbsItems} />
        <S.Content>
          <Headline variant="h3">Space | ROI Week</Headline>
          <S.IFrameWrapper className={isFullScreen ? 'fullscreen' : ''}>
            <iframe
              title="Space | ROI Week"
              src="https://apps.powerapps.com/play/e/default-8cc350ef-7ed4-4252-bc1d-dcd9f3809cf9/a/b919133f-692b-4c0e-9bde-cd9aea0d4683?tenantId=8cc350ef-7ed4-4252-bc1d-dcd9f3809cf9"
              allowFullScreen
            />
          </S.IFrameWrapper>
        </S.Content>
      </S.RoiContainer>
    </>
  );
}
