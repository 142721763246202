import { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { formatPhoneWithCountryCode } from '../../utils/formatPhone';
import { UnixToReadableDate } from '../../utils/UnixToReadableDate';
import { getSdrLeads } from '../../services/requests/sdrWhatsappBot/getLeads';
import { ISdrLeads } from '../../types/sdrBotMessages';
import { LoadingContainer } from '../MessageHistory/styles';
import { TableSearch } from '../../components/AtomicDesign/atoms/TableSearch';
import { SearchContainer } from './styles';
import {
  Breadcrumb,
  Container,
  SectionContent,
  Section,
  SectionTitle,
  Table,
} from '../../components/LegacyV4Components';

interface TableItem {
  id?: string;
  text: string;
  phoneNumber: string;
  createdAt: string;
  status?: string;
  goToPage?: string;
  goToAccessor?: string;
  cluster?: string | null;
}

export function MessageHistoryList() {
  const [allLeads, setAllLeads] = useState<ISdrLeads[]>([]);
  const [filteredLeads, setFilteredLeads] = useState<ISdrLeads[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const getLeads = useCallback(async () => {
    setIsLoading(true);

    const leads = await getSdrLeads();

    if (leads) {
      setAllLeads(leads);
    }

    setIsLoading(false);
  }, [setAllLeads, setIsLoading]);

  useEffect(() => {
    getLeads();
  }, [getLeads]);

  useEffect(() => {
    if (searchValue) {
      const removeSpecialChars = searchValue.replace(/[^0-9]/g, '');

      const filtered = allLeads.filter((lead) =>
        lead.phoneNumber.includes(removeSpecialChars),
      );

      setFilteredLeads(filtered);
    }
  }, [searchValue, allLeads, setFilteredLeads]);

  const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSearchValue(value);
  };

  const allMessagesHistory = useMemo(() => {
    const preHistory: TableItem[] = [];

    const leads = filteredLeads.length > 0 ? filteredLeads : allLeads;

    leads.forEach((message) => {
      const status =
        message.leadStatus === 'qualified' ? 'Qualificado' : 'Não qualificado';

      preHistory.push({
        id: message.id,
        status,
        phoneNumber: formatPhoneWithCountryCode(message.phoneNumber),
        createdAt: UnixToReadableDate(message.createdAt),
        goToAccessor: 'phoneNumber',
        goToPage: `/historico-mensagens`,
        text: '',
      });
    });
    return preHistory;
  }, [allLeads, filteredLeads]);

  return (
    <>
      <Container>
        <Breadcrumb
          breadcrumbs={[
            {
              title: 'Históricos de conversas via SDR Bot',
              link: '/historicos',
            },
          ]}
        />
        <Section>
          <SectionContent>
            <SectionTitle text="Histórico de conversas" />
            <SearchContainer>
              <TableSearch
                value={searchValue}
                handleValueChange={(e) => {
                  handleSearchValue(e);
                }}
                placeholder="Buscar por telefone"
              />
            </SearchContainer>
            {isLoading ? (
              <LoadingContainer>
                <CircularProgress
                  sx={{ width: '1rem', height: '1rem', color: 'red' }}
                />
              </LoadingContainer>
            ) : (
              <Table
                columns={[
                  {
                    label: 'Número de telefone',
                    accessor: 'phoneNumber',
                    isSorted: false,
                    sortable: true,
                    sortByOrder: 'asc',
                    maxWidth: 100,
                  },
                  {
                    label: 'Id',
                    accessor: 'id',
                    isSorted: false,
                    sortable: true,
                    sortByOrder: 'asc',
                    maxWidth: 100,
                  },
                  {
                    label: 'Status',
                    accessor: 'status',
                    isSorted: false,
                    sortable: true,
                    sortByOrder: 'asc',
                    maxWidth: 100,
                  },
                  {
                    label: 'Data da primeira mensagem',
                    accessor: 'createdAt',
                    isSorted: false,
                    sortable: true,
                    sortByOrder: 'asc',
                    maxWidth: 100,
                  },
                ]}
                data={allMessagesHistory || []}
              />
            )}
          </SectionContent>
        </Section>
      </Container>
    </>
  );
}
