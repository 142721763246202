import { getCustomerResponse } from '../../../types/customer';
import { usersApi as api } from '../../api';

export async function getCustomersByUserId(
  userId: string,
): Promise<getCustomerResponse[] | false> {
  try {
    const response = await api.get<getCustomerResponse[]>(
      `/capacity/manage/find-all/customers/${userId}`,
    );

    const customers: getCustomerResponse[] = response.data;
    return customers;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os dados dos clientes, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
