import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MdCheckCircleOutline } from 'react-icons/md';
import { LabDsButton } from 'v4web-components-react';
import { Modal, Container } from '../../../LegacyV4Components';
import { dashboardsApi } from '../../../../services/api';

import * as S from './styles';

interface DeleteDashboardModalProps {
  isOpen: boolean;
  closeModal: () => void;
  dashboard?: {
    _id?: string;
    name: string;
    description: string;
    link: string;
  };
}

export function EditDashboardModal({
  isOpen,
  closeModal,
  dashboard,
}: DeleteDashboardModalProps) {
  const editData = {
    name: dashboard?.name as string,
    description: dashboard?.description as string,
    link: dashboard?.link as string,
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    async () => dashboardsApi.put(`/dashboard/${dashboard?._id}`, editData),
    {
      onSuccess: async () => {
        // cancela se outra query estiver rodando

        await queryClient.invalidateQueries(['dashboards']);
        await queryClient.refetchQueries(['dashboards']);
        await queryClient.refetchQueries(['favorites']);
      },
    },
  );
  async function handleUpdateDashboard() {
    if (editData) {
      mutation.mutateAsync();
      closeModal();
    }
  }
  return (
    <Modal show={isOpen} onBackground={closeModal}>
      <Container style={{ backgroundColor: 'transparent' }}>
        <S.Container>
          <MdCheckCircleOutline size={64} />
          <h3>Salvar alterações</h3>
          <p>Deseja prosseguir com as alterações</p>
          <div className="buttons">
            <LabDsButton
              label="Cancelar"
              variant="danger-outlined"
              onHandleButton={closeModal}
            />
            <LabDsButton
              label="Salvar"
              onHandleButton={handleUpdateDashboard}
            />
          </div>
        </S.Container>
      </Container>
    </Modal>
  );
}
