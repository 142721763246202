import { IData } from 'v4web-components';

export const accountsDataHeaders: IData['headers'] = [
  {
    title: 'Ferramenta',
    colKey: 'type',
    type: 'text',
  },
  {
    title: 'ID da integração',
    colKey: 'integrationId',
    type: 'text',
  },
  {
    title: 'Data de criação',
    colKey: 'createdAt',
    type: 'text',
  },
  {
    title: 'Data da última atualização',
    colKey: 'updatedAt',
    type: 'text',
  },
  {
    title: 'Status',
    colKey: 'status',
    type: 'badge',
  },
];
