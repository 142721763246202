import styled, { css } from 'styled-components';

interface ContentAccordionProps {
  close: boolean;
  children: React.ReactNode;
}

export const ContainerAccordion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 0.5rem 0rem;
  border: 1px solid var(--grayscale-6);
`;

export const HeaderAccordion = styled.div<ContentAccordionProps>`
  display: flex;
  padding: 0.75rem 1.25rem;

  align-items: center;
  gap: 1rem;
  align-self: stretch;
  border-bottom: 1px solid var(--grayscale-6);
  justify-content: space-between;

  svg {
    transition: transform 0.2s;
    cursor: pointer;
  }
  ${({ close }) =>
    close &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const TitleAccordion = styled.h6`
  color: var(--secondary-main);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
`;

export const ContentAccordion = styled.div<ContentAccordionProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;

  align-self: stretch;

  border-radius: 0rem;

  overflow: hidden;

  max-height: 18.75rem;
  ${({ close }) =>
    close &&
    css`
      max-height: 0rem;
    `}
  transition: max-height 0.2s ease-in-out;
`;
