import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const RedirectToKnowledgeBaseButton = styled.button`
  position: fixed;
  z-index: 50;
  bottom: 0;
  border: 0;
  outline: 0;
  right: 0;
  margin: 1rem;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.12);
  background: var(--grayscale-6);
  border-radius: 0.5rem;
  transition: 0.2s;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    p {
      margin-left: 0.5rem;
      color: var(--secondary-main, #333);
      font-size: 0.875rem;
      font-weight: 500;
    }
  }

  :hover {
    background: var(--grayscale-8, #cdcddf);
  }
`;

export const Content = styled.div`
  margin-top: 1rem;
  background: var(--white);
  padding: 2rem;
  border-radius: 0.5rem;
  transition: 0.4s;
  display: flex;
  flex: 1;
  flex-direction: column;

  lab-ds-search-bar {
    width: 25%;
  }

  h1 {
    font-size: 38px;
  }

  > section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    min-height: 3.25rem;
  }

  section > div {
    margin: 0;
  }

  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }

  > header h3 {
    font-size: 28px;
  }

  > header p {
    margin-top: 0.5rem;
  }

  > main {
    margin-top: 1rem;
    display: flex;
    width: 100%;
  }

  > main > div {
    flex: 1;
  }
`;

export const Tabs = styled.div`
  display: flex;

  > p {
    margin: 0.4rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid var(--success-1);
    opacity: 0.5;
    cursor: not-allowed;
  }

  > p:first-child {
    font-weight: bold;
    opacity: 1;
    cursor: pointer;
    margin-left: 0;
    margin-right: 1rem;
  }
`;

export const Cards = styled.div`
  max-width: 22rem;
  margin-top: 1rem;
  margin-left: 1rem;
  max-height: 29rem;
  overflow: auto;
  padding-right: 0.5rem;

  > div {
    margin-bottom: 1rem;
  }

  @media (min-width: 1599px) {
    max-height: 40rem;
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12rem;
  padding-right: 10rem;
  margin-top: 1rem;

  svg {
    width: 14rem !important;
    height: 14rem !important;

    &:last-child {
      display: none;
    }
  }
`;
