import { useState } from 'react';
import { BiBarChartSquare } from 'react-icons/bi';
import { LabDsButton } from 'v4web-components-react';

import { FiX } from 'react-icons/fi';
import { Headline, Paragraph } from '../../../LegacyV4Components';
import * as S from './styles';

import { MediaTemplate } from '../MediaTemplate';

export function DashboardModels() {
  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <S.Card>
        <BiBarChartSquare size={20} />
        <S.Description>
          <Paragraph variants={['bold']}>Modelos de dashboards</Paragraph>
          <Paragraph>
            Faça download de modelos de dashboard personalizáveis
          </Paragraph>
        </S.Description>
        <S.ButtonWrapper>
          <LabDsButton
            label="Acessar"
            size="small"
            onHandleButton={() => handleButtonClick()}
          />
        </S.ButtonWrapper>
      </S.Card>
      <S.ModalOverlay
        className={showModal ? 'show' : ''}
        onClick={handleCloseModal}
      >
        <S.ModalWrapper
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <S.ModalHeader>
            <Headline variant="h4">Modelos de dashboards</Headline>
            <FiX
              size={16}
              className="close-button"
              onClick={handleCloseModal}
            />
          </S.ModalHeader>
          <S.ModalBody>
            <S.TopRow>
              <Paragraph>
                Escolha um modelo de dashboard e clique em baixar para ter
                acesso ao arquivo e integrar seus dados ao Mkt.Lab
              </Paragraph>
              <LabDsButton
                variant="danger-outlined"
                label="Enviar modelo"
                onHandleButton={() =>
                  window.open(
                    'https://app.pipefy.com/public/form/QzSpZlFJ',
                    '_blank',
                  )
                }
              />
            </S.TopRow>
            <S.TemplatesWrapper>
              <MediaTemplate />
            </S.TemplatesWrapper>
          </S.ModalBody>
        </S.ModalWrapper>
      </S.ModalOverlay>
    </>
  );
}
