import {
  ReactNode,
  useCallback,
  useContext,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IOptionsSelect } from 'v4web-components';
import { getAllMandates } from '../services/requests/User/Mandates/getAllMandates';
import { getAllSeniorities } from '../services/requests/User/Seniorities/getAllSeniorities';
import { useAuth } from './auth';
import { InputItens } from '../components/AtomicDesign/molecules/ModalPermissionsEdit/interfaces';
import { Mandate, Seniority } from './types';

interface UserProps {
  mandate: InputItens[] | IOptionsSelect[];
  seniority: InputItens[] | IOptionsSelect[];
}
const UserContext = createContext({} as UserProps);

export function UserProvider({ children }: { children: ReactNode }) {
  const { user } = useAuth();
  const [mandate, setMandate] = useState<InputItens[] | IOptionsSelect[]>([]);
  const [seniority, setSeniority] = useState<InputItens[] | IOptionsSelect[]>(
    [],
  );

  const getAllMandateItems = useCallback(() => {
    const mandateItemsAux: InputItens[] | IOptionsSelect[] = [];
    getAllMandates().then((result) => {
      result?.forEach((mandateResult: Mandate) => {
        mandateItemsAux.push({
          label: mandateResult.name,
          value: mandateResult,
          key: mandateResult._id,
          isChecked: false,
        });
      });
    });
    setMandate(mandateItemsAux);
  }, []);

  const getAllSeniorityItems = useCallback(() => {
    const seniorityItemsAux: InputItens[] | IOptionsSelect[] = [];
    getAllSeniorities().then((result) => {
      result?.forEach((seniorityResult: Seniority) => {
        seniorityItemsAux.push({
          label: seniorityResult.name,
          value: seniorityResult,
          key: seniorityResult._id,
          isChecked: false,
        });
      });
    });
    setSeniority(seniorityItemsAux);
  }, []);

  useEffect(() => {
    if (!user._id) return;

    getAllMandateItems();
    getAllSeniorityItems();
  }, [user, getAllMandateItems, getAllSeniorityItems]);

  const contextValue = useMemo(
    () => ({
      mandate,
      seniority,
    }),
    [mandate, seniority],
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  return context;
}

export const responsibilityItems = [
  {
    label: 'Coach',
    value: 'Coach',
  },
  {
    label: 'Cap',
    value: 'Cap',
  },
  {
    label: 'Player',
    value: 'Player',
  },
];
