import { Dashboard } from '../../../types/dashboard';
import { CreateDashboardDTO } from '../../../types/createDashboardDTO';
import { dashboardsApi } from '../../api';

export async function createDashboard(
  dashboardDTO: CreateDashboardDTO,
): Promise<Dashboard | false> {
  try {
    const response = await dashboardsApi.post<Dashboard>(
      `/dashboard`,
      dashboardDTO,
    );
    const dashboard = response.data;

    return dashboard;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o dashboard, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
