import { useEffect, useState } from 'react';

import { getMetabaseUrl } from '../../../../services/requests/Ads/getMetabaseUrl';

import * as S from './styles';

interface UnitManagementProps {
  unitId: string;
}

const unitManagementDashboardId = 114;

export function LeadBroker({ unitId }: UnitManagementProps) {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMetabaseUrl(unitManagementDashboardId, {
          id: unitId,
        });
        setUrl(response);
      } catch (error) {
        console.error('Erro ao buscar a URL do Metabase:', error);
      }
    };

    fetchData();
  });

  return (
    <S.Container>
      <S.FrameWrapper>
        <iframe title="units-dashboard" src={url} />
      </S.FrameWrapper>
    </S.Container>
  );
}
