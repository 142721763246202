export function ValidateCPF(cpfNumber?: string): boolean {
  if (!cpfNumber) return false;

  const cpfAux = cpfNumber.replace(/[\s.-]*/gim, '');
  let sum = 0;
  let remainder;

  if (
    cpfNumber === '00000000000' ||
    cpfNumber === '11111111111' ||
    cpfNumber === '22222222222' ||
    cpfNumber === '33333333333' ||
    cpfNumber === '44444444444' ||
    cpfNumber === '55555555555' ||
    cpfNumber === '66666666666' ||
    cpfNumber === '77777777777' ||
    cpfNumber === '88888888888' ||
    cpfNumber === '99999999999'
  ) {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(cpfAux.substring(i - 1, i), 10) * (11 - i);
  }

  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;

  if (remainder !== parseInt(cpfAux.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(cpfAux.substring(i - 1, i), 10) * (12 - i);
  }

  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) remainder = 0;

  if (remainder !== parseInt(cpfAux.substring(10, 11), 10)) {
    return false;
  }

  return true;
}
