import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import { useState } from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';
import { FiArrowUp, FiFileText, FiShoppingCart } from 'react-icons/fi';
import { Headline } from '../../components/LegacyV4Components';
import { useGetProductById } from '../../services/requests/Finance/getProductById';
import * as S from './styles';

export function PaymentSuccess() {
  const url = window.location.href;
  const regex = /payment\/(.*?)\/success/;
  const match = url.match(regex);
  const id = match ? match[1] : '';

  const { data: product } = useGetProductById(id);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <S.Container>
      <S.ConfirmedPayment>
        <FaRegCheckCircle color="#00C49A" />
        <p>Pagamento confirmado com sucesso</p>
        <small>
          Você receberá um email confirmando o pagamento do{' '}
          {product?.data?.name} e as informações pertinentes
        </small>
      </S.ConfirmedPayment>
      <div>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          sx={{ border: '1px solid #00c49a' }}
        >
          <AccordionSummary
            expandIcon={<FiArrowUp />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FiFileText style={{ marginRight: '28px' }} />
              <Headline variant="h5">Ver resumo</Headline>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <S.ContainerInfo>
              <Headline variant="h5">
                <FiShoppingCart />
                Seu pedido
              </Headline>
              <S.Details>
                <p>{product?.data?.name}</p>
                <small>{product?.data?.description}</small>
              </S.Details>
            </S.ContainerInfo>
          </AccordionDetails>
        </Accordion>
      </div>
    </S.Container>
  );
}
