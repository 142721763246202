import styled from 'styled-components';

export const TemplateCard = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  padding: 1.5rem;

  border-radius: 8px;
  border: 1px solid var(--grayscale-10);
`;

export const TemplateCardHeader = styled.div`
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--grayscale-20);
  margin-bottom: 1.5rem;
`;

export const Carrousel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;

  margin-bottom: 1rem;
`;

export const CarrouselImage = styled.div<{ background: string }>`
  aspect-ratio: 1.56;
  transition: all 0.3s ease-in-out;
  background-image: url(${(props) => props.background});
  background-size: contain;
  max-height: 50vh;
  max-width: calc(50vh * 1.56);
`;

export const CarrouselDots = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
`;

export const CarrouselDot = styled.div<{ active?: boolean }>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? 'var(--success-2)' : 'var(--grayscale-30)'};
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    width: 10.1875rem;
    padding: 0.5rem;
    height: 2rem;
  }
`;
