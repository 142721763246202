import { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { RiPlantLine } from 'react-icons/ri';
import {
  Container,
  SectionContent,
  Breadcrumb,
  Section,
  Button,
  Paragraph,
  Headline,
  DotMenu,
} from '../../components/LegacyV4Components';
import * as S from './styles';
import {
  ColumnProps,
  DataGrid,
  DataProps,
} from '../../components/AtomicDesign/atoms/DataGrid';
import { getUsersByUnitId } from '../../services/requests/Unit/getUsersByUnitId';
import { NumberInput } from '../../components/Default/NumberInput';
import {
  updateCapacity,
  updateCapacityOfManyInvestors,
} from '../../services/requests/User/Capacities/updateCapacityOfManyInvestors';
import { ModalRemoveOnboarding } from '../../components/AtomicDesign/organisms/ModalRemoveOnboarding';
import { useToast } from '../../hooks/toast';
import { User } from '../../hooks/types';

export function EditOperationalCapacity() {
  const { addToast } = useToast();
  const navigate = useNavigate();

  const minDaysOnboardingToRemoveInvestor = 30;
  const [query] = useState('');
  const [value, setValue] = useState(true);
  const [maxCapacity, setMaxCapacity] = useState<number[]>([]);
  const [removingInvestor, setRemovingInvestor] = useState<User>({} as User);
  const [daysInOnboarding, setdaysInOnboarding] = useState<number[]>([]);
  const [isOnboarding, setIsOnboarding] = useState<boolean[]>([]);
  const [showModalRemoveOnboarding, setShowModalRemoveOnboarding] =
    useState(false);

  const { id } = useParams<{ id: string }>();

  const { data, refetch } = useInfiniteQuery(['users', query], async () => {
    const usersPromise = await getUsersByUnitId({
      unitId: id || '',
      isActive: true,
      showDeleted: false,
      search: query,
    });
    return usersPromise;
  });

  useEffect(() => {
    refetch({ refetchPage: (_page, index) => index === 0 });
  }, [refetch, showModalRemoveOnboarding]);

  const tableColumns = [
    {
      accessor: 'name',
      label: 'Investidor',
    },
    {
      accessor: 'mandate',
      label: 'Cargo',
    },
    {
      accessor: 'seniority',
      label: 'Senioridade',
    },
    {
      accessor: 'totalCapacity',
      label: 'Capacidade total',
      message: 'Projetos que atende x capacidade total',
      sizing: 1,
    },
    {
      accessor: 'onboardingCapacity',
      label: 'Dias em onboarding',
      message:
        'Dias que o investidor está em onboarding x dias totais em onboarding',
      sizing: 1,
    },
    {
      accessor: 'action',
      label: '',
      sizing: 0.1,
    },
  ] as ColumnProps[];

  useMemo(() => {
    const maxCapacityAux: number[] = [];
    const daysInOnboardingAux: number[] = [];
    const isOnboardingAux: boolean[] = [];
    data?.pages?.forEach((page) => {
      const { users } = page;
      if (users) {
        users?.forEach((currentUser) => {
          maxCapacityAux.push(currentUser.capacity?.maxWorkload || 0);
          daysInOnboardingAux.push(currentUser.capacity?.daysInOnboarding || 0);
          isOnboardingAux.push(currentUser.capacity?.isOnboarding);
        });
      }
    });
    setdaysInOnboarding(daysInOnboardingAux);
    setIsOnboarding(isOnboardingAux);
    return setMaxCapacity(maxCapacityAux);
  }, [data?.pages]);

  async function UpdateCapacity() {
    const info: updateCapacity[] = [];
    data?.pages?.forEach((page) => {
      const { users } = page;
      if (users) {
        users?.forEach((currentUser, index) => {
          if (currentUser.capacity?._id)
            info.push({
              _id: currentUser.capacity?._id,
              unitId: currentUser.unitId,
              maxWorkload: maxCapacity[index],
              daysInOnboarding: daysInOnboarding[index],
            });
        });
      }
    });
    const response = await updateCapacityOfManyInvestors(info);
    if (response.statusCode && response.statusCode !== 200) {
      addToast({
        type: 'error',
        title: response.name ?? 'Não foi possivel completar a ação!',
        description:
          response.message ??
          'Ocorreu algum problema ao convidar este usuário 😕',
      });
    } else {
      addToast({
        title: 'Sucesso',
        description: 'Alterações salvas com sucesso!',
        type: 'success',
      });
      navigate(`/unit/view/capacidade-operacional/${id}`);
    }
  }

  async function removeFromOnboarding() {
    const response = await updateCapacityOfManyInvestors([
      {
        _id: removingInvestor?.capacity?._id || '',
        unitId: removingInvestor?.unitId,
        maxWorkload: removingInvestor?.capacity?.maxWorkload,
        daysInOnboarding: removingInvestor?.capacity.daysInOnboarding,
        isOnboarding: false,
      },
    ]);
    if (response.statusCode && response.statusCode !== 200) {
      addToast({
        type: 'error',
        title: response.name ?? 'Não foi possivel completar a ação!',
        description:
          response.message ??
          'Ocorreu algum problema ao convidar este usuário 😕',
      });
    } else {
      addToast({
        title: 'Sucesso',
        description: 'Alterações salvas com sucesso!',
        type: 'success',
      });
    }
  }

  const tableContent = useMemo(() => {
    const tableData: DataProps[] = [];
    data?.pages?.forEach((page) => {
      const { users } = page;
      if (users) {
        users?.forEach((currentUser, index) => {
          const minDaysOnboarding =
            currentUser.seniority?.name === 'Júnior' ||
            currentUser.seniority?.name === 'Trainee'
              ? 60
              : 30;
          if (currentUser.mandate?.isNewPrice)
            tableData.push({
              id: currentUser._id,
              user: currentUser,
              content: (
                <>
                  <td title={currentUser.name} className="hidden">
                    <S.RowTable>
                      <S.Avatar
                        onClick={() => null}
                        src={currentUser.picture}
                        alt={currentUser.picture}
                        width="0.6rem"
                        height="0.6rem"
                      />
                      <Paragraph>{currentUser.name}</Paragraph>
                      {currentUser.capacity?.isOnboarding ? (
                        <S.Infomation>
                          <div className="icon">
                            <RiPlantLine />
                            <div className="message">
                              <p>
                                Investidor em processo de onboarding. <br />
                                Faltam{' '}
                                {currentUser.capacity?.daysInOnboarding -
                                  currentUser.capacity?.daysPast}{' '}
                                dias para conclusão.
                              </p>
                            </div>
                          </div>
                        </S.Infomation>
                      ) : null}
                    </S.RowTable>
                  </td>
                  <td
                    title={currentUser.mandate?.name || '-'}
                    className="hidden"
                  >
                    <S.RowTable>
                      <Paragraph>{currentUser.mandate?.name || '-'}</Paragraph>
                    </S.RowTable>
                  </td>
                  <td
                    title={currentUser.seniority?.name || '-'}
                    className="hidden"
                  >
                    <Paragraph>{currentUser.seniority?.name || '-'}</Paragraph>
                  </td>
                  <td
                    title={currentUser.mandate?.name || '-'}
                    className="hidden"
                  >
                    {currentUser.capacity?.isOnboarding ? (
                      <p className="max-workload">
                        {Math.ceil(currentUser.capacity?.maxWorkload / 2)}
                      </p>
                    ) : (
                      <div className="max-workload">
                        <NumberInput
                          value={maxCapacity[index]}
                          min={currentUser.capacity?.currentWorkload || 0}
                          onValueChange={(val) => {
                            maxCapacity[index] = val;
                            setMaxCapacity(maxCapacity);
                            setValue(!value);
                          }}
                        />
                      </div>
                    )}
                  </td>
                  <td
                    title={currentUser.capacity?._id || '-'}
                    className="hidden"
                  >
                    {isOnboarding[index] ? (
                      <div className="max-workload">
                        <NumberInput
                          value={daysInOnboarding[index]}
                          min={minDaysOnboarding}
                          onValueChange={(val) => {
                            daysInOnboarding[index] = val;
                            setdaysInOnboarding(daysInOnboarding);
                            setValue(!value);
                          }}
                        />
                      </div>
                    ) : (
                      <Paragraph className="max-workload">-</Paragraph>
                    )}
                  </td>
                  <td>
                    {isOnboarding[index] &&
                    currentUser.capacity.daysPast >=
                      minDaysOnboardingToRemoveInvestor ? (
                      <DotMenu
                        options={[
                          {
                            name: 'Remover do onboarding',
                            action: () => {
                              setRemovingInvestor(currentUser);
                              setShowModalRemoveOnboarding(true);
                            },
                          },
                        ]}
                        reversed
                      />
                    ) : null}
                  </td>
                </>
              ),
            });
        });
      }
    });

    return tableData.length ? tableData : null;
  }, [data?.pages, value, maxCapacity, daysInOnboarding, isOnboarding]);

  return (
    <Container>
      <Breadcrumb
        breadcrumbs={[
          { title: 'Minha unidade', link: `/unit/read/${id}` },
          {
            title: 'Capacidade operacional',
            link: `/unit/view/capacidade-operacional/${id}`,
          },
          {
            title: 'Editar capacidade',
            link: ``,
          },
        ]}
      />
      <Section>
        <SectionContent>
          <>
            <S.Row style={{ justifyContent: 'space-between' }}>
              <Headline variant="h4">
                Editar capacidade
                <S.Text>
                  Ao definir a capacidade da sua equipe, lembre-se que o
                  processo de onboarding tem no mínimo de 45 dias. A capacidade
                  operacional não pode ser alterada enquanto o valor mínimo de
                  dias não for encerrado.
                </S.Text>
              </Headline>
            </S.Row>
            <S.Row />
          </>

          <S.DataGrid>
            <DataGrid
              showModalUserInvate={false}
              setShowModalUserInvate={() => null}
              columns={tableColumns}
              data={tableContent || []}
            />
          </S.DataGrid>
          <S.Action>
            <Link
              className="Link"
              to={`/unit/view/capacidade-operacional/${id}`}
            >
              <Button
                variant="secondary"
                width="12.5rem"
                size="default"
                color="red"
                onClick={() => null}
              >
                Cancelar
              </Button>
            </Link>
            <Button
              width="12.5rem"
              variant="primary"
              size="default"
              color="green"
              onClick={() => UpdateCapacity()}
            >
              Confirmar
            </Button>
          </S.Action>
        </SectionContent>
      </Section>
      <ModalRemoveOnboarding
        isShown={showModalRemoveOnboarding}
        setIsShown={() => setShowModalRemoveOnboarding(false)}
        removeFromOnboarding={removeFromOnboarding}
      />
    </Container>
  );
}
