import { useState } from 'react';
import { BiLinkAlt } from 'react-icons/bi';
import { LabDsButton } from 'v4web-components-react';

import { FiCopy, FiX } from 'react-icons/fi';

import { Link } from 'react-router-dom';
import { MdHelpOutline } from 'react-icons/md';
import { Headline, Paragraph } from '../../../LegacyV4Components';
import * as S from './styles';

import googleSheets from '../../../../assets/images/google_sheets.png';
import { generateExternalToken } from '../../../../services/requests/User/generateExternalToken';
import { useToast } from '../../../../hooks/toast';
import { useAuth } from '../../../../hooks/auth';

export function GoogleSheets() {
  const { addToast } = useToast();
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  async function handleGenerateTokenClick() {
    setIsLoading(true);
    const token = await generateExternalToken();

    if (token) {
      navigator.clipboard.writeText(token);
      addToast({
        type: 'success',
        title: 'Token ID gerado com sucesso!',
      });
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao gerar Token ID!',
      });
    }

    setIsLoading(false);
  }

  function handleCopyUnitId() {
    const { unitId } = user;
    navigator.clipboard.writeText(unitId);
    addToast({
      type: 'success',
      title: 'Id da unidade copiado para a área de transferência!',
    });
  }

  return (
    <>
      <S.Card>
        <BiLinkAlt size={20} />
        <S.Description>
          <Paragraph variants={['bold']}>Vincular ao Google Sheets</Paragraph>
          <Paragraph>
            Vincule seus clientes ao Google Sheets para exportar seus dados
          </Paragraph>
        </S.Description>
        <S.ButtonWrapper>
          <LabDsButton
            label="Vincular"
            size="small"
            onHandleButton={() => handleButtonClick()}
          />
        </S.ButtonWrapper>
      </S.Card>
      <S.ModalOverlay
        className={showModal ? 'show' : ''}
        onClick={handleCloseModal}
      >
        <S.ModalWrapper
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <S.ModalHeader>
            <Headline variant="h4">Google Sheets</Headline>
            <FiX
              size={16}
              className="close-button"
              onClick={handleCloseModal}
            />
          </S.ModalHeader>
          <S.ModalBody>
            <Paragraph variants={['plus']}>
              Mediahub vincula as suas contas para o Google Sheets para que você
              tenha os seus dashboards e painel de resultados atualizados
              diariamente.
            </Paragraph>

            <S.Content>
              <S.Left>
                <div>
                  <Headline variant="h5">Features disponíveis:</Headline>
                  <li>Vinculação com o Facebook Ads</li>
                  <li>Vinculação com o Google Ads</li>
                </div>
                <div>
                  <Headline variant="h5">O que você vai precisar:</Headline>
                  <S.Token>
                    <li>Token da sua franquia</li>
                    <S.GenerateTokenWrapper>
                      <FiCopy
                        className={isLoading ? 'loading' : ''}
                        size={16}
                        onClick={
                          isLoading ? undefined : handleGenerateTokenClick
                        }
                      />
                    </S.GenerateTokenWrapper>
                  </S.Token>
                  <S.Token>
                    <li>Id da sua unidade</li>
                    <S.GenerateTokenWrapper>
                      <FiCopy
                        className={isLoading ? 'loading' : ''}
                        size={16}
                        onClick={isLoading ? undefined : handleCopyUnitId}
                      />
                    </S.GenerateTokenWrapper>
                  </S.Token>
                  <li>Id da sua conta do Google ou Facebook</li>
                </div>
              </S.Left>
              <S.Right>
                <S.Image src={googleSheets} />
              </S.Right>
            </S.Content>
            <Link
              to="/baseconhecimento/2f32644c46c048549a18695c77b18589"
              target="_blank"
            >
              <S.HelpLink>Precisa de ajuda?</S.HelpLink>
              <MdHelpOutline size={16} />
            </Link>
          </S.ModalBody>
          <S.ModalFooter>
            <LabDsButton
              variant="danger-outlined"
              label="Cancelar"
              onHandleButton={() => handleCloseModal()}
            />
            <LabDsButton
              label="Instalar extensão"
              onHandleButton={() =>
                window.open(
                  'https://workspace.google.com/u/0/marketplace/app/mediahub_data_extractor/168293617043',
                  '_blank',
                  'noopener,noreferrer',
                )
              }
            />
          </S.ModalFooter>
        </S.ModalWrapper>
      </S.ModalOverlay>
    </>
  );
}
