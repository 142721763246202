import { useState } from 'react';
import axios from 'axios';
import { Button } from '../../components/LegacyV4Components';

import * as S from './styles';

export function PipefyRequest() {
  const [isLoading, setIsLoading] = useState(false);

  const [financeId, setFinanceId] = useState('');
  const [financeMessage, setFinanceMessage] = useState('');

  const [financeBillingId, setFinanceBillingId] = useState('');
  const [financeBillingMessage, setFinanceBillingMessage] = useState('');

  // Ignore a qualidade deste codigo, fiz rapidao pra agradar o financeiro, perdao!
  async function handleCreateFinanceCard() {
    const response = await axios
      .post<{ message: string }>(
        'https://api.laboratoriodomarketing.com/clients/pipefy',
        {
          data: {
            action: 'card.create',
            card: {
              id: financeId,
              pipe_id: '797696',
            },
          },
        },
        {
          headers: {
            Authorization:
              'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTAsIm5hbWUiOiJSb2RyaWdvIiwibGFzdF9uYW1lIjoiU2NoaWVjayIsImVtYWlsIjoicm9kcmlnby5zY2hpZWNrQHY0Y29tcGFueS5jb20iLCJ1bml0X2lkIjoxLCJpYXQiOjE2NjgwODQxNDUsImV4cCI6MTY2ODUxNjE0NX0.186w959wCgGOYHHIdJ9UNqADquCkRXDM5hUXBYvNi6k',
          },
        },
      )
      .catch(() => {
        setFinanceMessage('Erro ao criar card');
        return;
      });

    if (response?.data?.message === 'error') {
      setFinanceMessage('Erro ao criar card');
      return;
    }

    setFinanceMessage(`Card ${financeId} criado com sucesso!`);
  }

  async function handleRecreateBilling() {
    setIsLoading(true);
    const url = 'https://api.laboratoriodomarketing.com/billing/secondary';
    const token =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NTAsIm5hbWUiOiJSb2RyaWdvIiwibGFzdF9uYW1lIjoiU2NoaWVjayIsImVtYWlsIjoicm9kcmlnby5zY2hpZWNrQHY0Y29tcGFueS5jb20iLCJ1bml0X2lkIjoxLCJpYXQiOjE2NjgwODQxNDUsImV4cCI6MTY2ODUxNjE0NX0.186w959wCgGOYHHIdJ9UNqADquCkRXDM5hUXBYvNi6k';

    try {
      const response = await axios.post(url, {
        iugu_id: financeBillingId,
        token,
      });

      setFinanceBillingMessage(`Sucesso, ${JSON.stringify(response.data)}`);
      setIsLoading(false);
    } catch (err) {
      setFinanceBillingMessage(JSON.stringify(err));
      setIsLoading(false);
    }
  }

  return (
    <S.Container>
      <h1>Recriar usuário lab financeiro</h1>
      <input
        placeholder="Id do card"
        value={financeId}
        onChange={(e) => setFinanceId(e.target.value)}
      />
      <Button
        variant="primary"
        size="small"
        color="green"
        onClick={() => handleCreateFinanceCard()}
      >
        Enviar
      </Button>

      <p>{financeMessage}</p>

      <h1>Recriar boleto lab financeiro</h1>
      <input
        placeholder="Id iugu do boleto"
        value={financeBillingId}
        onChange={(e) => setFinanceBillingId(e.target.value)}
      />
      <Button
        variant="primary"
        size="small"
        color="green"
        onClick={() => handleRecreateBilling()}
        disabled={isLoading}
      >
        Enviar
      </Button>

      <p>{financeBillingMessage}</p>
    </S.Container>
  );
}
