import styled, { css } from 'styled-components';

export const Container = styled.div<{ isFullscreen: boolean }>`
  display: flex !important;
  flex-direction: column !important;

  > header {
    margin: 0 3rem;
  }

  > header h3 {
    font-size: 1.25rem;
    color: var(--secondary-dark);
  }

  > header p {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1rem;
  }

  svg {
    margin-right: 3.5rem;
    margin-left: auto;
    cursor: pointer;
    color: var(--primary-dark);
    margin: 1rem;
    cursor: pointer;
    min-width: 1.5rem;
    min-height: 1.5rem;
    user-select: none;
  }

  > section {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Dashboard = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  svg {
    color: var(--success-1);
    margin: 1rem;
    margin-right: 0;
    cursor: pointer;
    min-width: 1rem;
    min-height: 1rem;
    user-select: none;
    align-self: flex-end;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 0.5rem;
    background: var(--tertiary-dark);
  }

  .fullscreen {
    height: 27rem;

    @media (min-width: 1600px) {
      height: 38rem;
    }
  }
`;

export const ViewBlocker = styled.div<{ isFullscreen: boolean }>`
  position: absolute;
  width: 5rem;
  height: 2.3rem;
  background: var(--secondary-light);
  bottom: 0;
  right: 0;

  ${({ isFullscreen }) =>
    isFullscreen &&
    css`
      width: 40rem;
    `}
`;
