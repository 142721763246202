import React, { ChangeEvent } from 'react';
import { FiSearch } from 'react-icons/fi';

import * as S from './styles';

interface TableSearchProps {
  value: string;
  handleValueChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  width?: string;
}

export function TableSearch({ ...props }: TableSearchProps) {
  const elementClass = props.value !== '' ? 'filled' : '';

  return (
    <S.SearchElement className={elementClass} width={props.width}>
      <FiSearch size={16} />
      <S.SearchInput
        value={props.value}
        onChange={props.handleValueChange}
        placeholder={props.placeholder}
      />
    </S.SearchElement>
  );
}
