export const UnitsList = [
  {
    id: '6286574d0fb9bb733c2b021a',
    label: 'Rodrigo Pereira',
  },
  {
    id: '6286574d0fb9bb733c2b021d',
    label: 'Almeida & Associados - Carlos Eduardo de Almeida',
  },
  {
    id: '6286574d0fb9bb733c2b0222',
    label: 'Souza Santos & Associados - Victor Hugo Souza Santos',
  },
  {
    id: '6286574d0fb9bb733c2b0228',
    label: 'Alex Peretto & Associados',
  },
  {
    id: '6286574d0fb9bb733c2b022b',
    label: 'Vinicius Colli & Associados',
  },
  {
    id: '6286574d0fb9bb733c2b022e',
    label: 'Souza Araujo & Associados - Helio de Souza',
  },
  {
    id: '6286574d0fb9bb733c2b0231',
    label: 'Rodrigo Bayer Medeiros',
  },
  {
    id: '6286574e0fb9bb733c2b0234',
    label: 'Felipe Saman & Associados',
  },
  {
    id: '6286574e0fb9bb733c2b023d',
    label: 'Mesquita & Associados - Augusto de Morais Mesquita',
  },
  {
    id: '6286574e0fb9bb733c2b0249',
    label: 'Jonathan Dias Cipulla',
  },
  {
    id: '6286574e0fb9bb733c2b024c',
    label: 'Anselmo Barbosa & Associados - Marcelo Barbosa Silva',
  },
  {
    id: '6286574e0fb9bb733c2b024f',
    label: 'Lucas Domingues & Associados',
  },
  {
    id: '6286574e0fb9bb733c2b0252',
    label: 'Piovesan & Associados',
  },
  {
    id: '6286574e0fb9bb733c2b0255',
    label: 'Dimer Silva e Associados',
  },
  {
    id: '6286574e0fb9bb733c2b0258',
    label: 'Edgard Baldo - Baldo & Co.',
  },
  {
    id: '6286574e0fb9bb733c2b025e',
    label: 'Zortea & Associados - Gelsner Zortea',
  },
  {
    id: '6286574e0fb9bb733c2b0261',
    label: 'Eduardo Fernandes Begnami',
  },
  {
    id: '6286574e0fb9bb733c2b026d',
    label: 'Barros & Associados',
  },
  {
    id: '6286574e0fb9bb733c2b0276',
    label: 'Alencar & Associados',
  },
  {
    id: '6286574e0fb9bb733c2b0285',
    label: 'Sylvio Guilherme França Junior ',
  },
  {
    id: '6286574e0fb9bb733c2b0288',
    label: 'Ricardo Brunhauser Karsten',
  },
  {
    id: '6286574e0fb9bb733c2b028b',
    label: 'Thomas Romano',
  },
  {
    id: '6286574f0fb9bb733c2b028e',
    label: 'Nelson Vieira Guimarães Júnior',
  },
  {
    id: '6286574f0fb9bb733c2b029a',
    label: 'Lucas Bilinski Rodrigues Cioffoletti',
  },
  {
    id: '6286574f0fb9bb733c2b02a6',
    label: 'Viana Palavre & Associados - Gregori Frezza Palavre',
  },
  {
    id: '6286574f0fb9bb733c2b02ac',
    label: 'Cleiton Carlos de Sousa Duarte',
  },
  {
    id: '6286574f0fb9bb733c2b02bb',
    label: 'Matheus Mundim',
  },
  {
    id: '6286574f0fb9bb733c2b02c4',
    label: 'V4 Company Silva Anchieta & Co.',
  },
  {
    id: '6286574f0fb9bb733c2b02c7',
    label: 'Muniz & Associados ',
  },
  {
    id: '6286574f0fb9bb733c2b02cd',
    label: 'Antunes Martin & Associados - Mariangela Adriane Antunes Martin',
  },
  {
    id: '6286574f0fb9bb733c2b02d0',
    label: 'Ágata Laylis Affonso Moreira',
  },
  {
    id: '6286574f0fb9bb733c2b02d3',
    label: 'Ferraz & Co.',
  },
  {
    id: '6286574f0fb9bb733c2b02d6',
    label: 'Batista & Associados - Isaias Alexandre Batista',
  },
  {
    id: '628657500fb9bb733c2b02e5',
    label: 'Venturini & Associados ',
  },
  {
    id: '628657500fb9bb733c2b02eb',
    label: 'Wuesley Godoy Luz',
  },
  {
    id: '628657500fb9bb733c2b02fa',
    label: 'Duarte Cunha & Co - Lucas Everson Duarte Cunha',
  },
  {
    id: '628657500fb9bb733c2b02fd',
    label: 'Gersson Junior de Sá',
  },
  {
    id: '628657500fb9bb733c2b0300',
    label: 'Joery Oliveira',
  },
  {
    id: '628657500fb9bb733c2b0306',
    label: 'Eduardo Torres  & Associados',
  },
  {
    id: '628657500fb9bb733c2b0309',
    label: 'Lencina Oliveira & Associados - Magnus Lencina de Oliveira',
  },
  {
    id: '628657500fb9bb733c2b031a',
    label: 'Carvalhaes Maioli & Associados - André Felipe Carvalhaes',
  },
  {
    id: '628657500fb9bb733c2b0320',
    label: 'Luis Fernando Fernandez Gulias',
  },
  {
    id: '628657500fb9bb733c2b0323',
    label: 'Leonardo Amaral',
  },
  {
    id: '628657500fb9bb733c2b032f',
    label: 'Nunes & Associados',
  },
  {
    id: '628657500fb9bb733c2b0335',
    label: 'Costa & Associados',
  },
  {
    id: '628657500fb9bb733c2b0338',
    label: 'Lisboa Vieira & Associados - Eduardo Lisboa',
  },
  {
    id: '628657500fb9bb733c2b033b',
    label: 'Wendling & Associados',
  },
  {
    id: '628657510fb9bb733c2b0341',
    label: 'Camargo Freitas & Associados - Danilo Camargo',
  },
  {
    id: '628657510fb9bb733c2b0347',
    label: 'Luiz Fillipi da Silveira',
  },
  {
    id: '628657510fb9bb733c2b0356',
    label: 'Jamile de Matos Cardotte',
  },
  {
    id: '628657510fb9bb733c2b035c',
    label: 'Rabelo & Associados - Laís Mari Rabelo',
  },
  {
    id: '628657510fb9bb733c2b035f',
    label: 'Sávio Moreira Leite Loureiro - Loureiro & Associados',
  },
  {
    id: '628657510fb9bb733c2b0362',
    label: ' Boaro& Passadore |Associados',
  },
  {
    id: '628657510fb9bb733c2b0365',
    label: 'Kuerten Ávila & Associados',
  },
  {
    id: '628657510fb9bb733c2b0368',
    label: 'Gomes Oliveira & Associados - Leandro Luis da Silva Xavier',
  },
  {
    id: '628657510fb9bb733c2b036e',
    label: 'Edison Aparecido Piai - Piai & Co',
  },
  {
    id: '628657510fb9bb733c2b0371',
    label: 'ALVES, LUSTOSA & ASSOCIADOS - Jaiki Alves',
  },
  {
    id: '628657510fb9bb733c2b0374',
    label: 'V4 Company RPZ & Co. - Bruno Henrique dos Santos Ribeiro',
  },
  {
    id: '628657510fb9bb733c2b0380',
    label: 'Sousa Ferraz & Co.',
  },
  {
    id: '628657510fb9bb733c2b0383',
    label: 'Garcia & Co',
  },
  {
    id: '628657510fb9bb733c2b0389',
    label: 'Rodrigues & Menezes Associados',
  },
  {
    id: '628657520fb9bb733c2b039b',
    label: 'Ricardo Euclydes Nazar',
  },
  {
    id: '628657520fb9bb733c2b03aa',
    label: 'Kloh & Associados',
  },
  {
    id: '628657520fb9bb733c2b03ad',
    label: 'Juliano Damaso Cardoso de Oliveira',
  },
  {
    id: '628657520fb9bb733c2b03bc',
    label: 'Kuri & Associados',
  },
  {
    id: '628657520fb9bb733c2b03d1',
    label: 'THIAGO CUSTODIO PEREIRA',
  },
  {
    id: '628657520fb9bb733c2b03d4',
    label: 'Freitas Ramos & Associados - Yuri',
  },
  {
    id: '628657520fb9bb733c2b03d7',
    label: 'Henrique Santana do Vale',
  },
  {
    id: '628657520fb9bb733c2b03e0',
    label: 'Aguiar & Associados',
  },
  {
    id: '628657520fb9bb733c2b03e3',
    label: 'Alfradique & Associados',
  },
  {
    id: '628657530fb9bb733c2b03e9',
    label: 'Sousa & CO',
  },
  {
    id: '628657530fb9bb733c2b03f5',
    label: 'Telles Freire & Associados',
  },
  {
    id: '628657530fb9bb733c2b03f8',
    label: 'Lorensi & Associados',
  },
  {
    id: '628657530fb9bb733c2b03fb',
    label: 'Sodré Antunes & Co. - Evando Sodré / Renato Antunes',
  },
  {
    id: '628657530fb9bb733c2b0401',
    label: 'Pereira Lima & Associados - Gustavo Lima',
  },
  {
    id: '628657530fb9bb733c2b040d',
    label: 'Lima & Associados',
  },
  {
    id: '628657530fb9bb733c2b0410',
    label: 'Rodrigo Moraes',
  },
  {
    id: '628657530fb9bb733c2b0413',
    label: 'Matta & Associados',
  },
  {
    id: '628657530fb9bb733c2b0425',
    label: 'Lopes Santana & Associados',
  },
  {
    id: '628657530fb9bb733c2b042b',
    label: 'Wagner Felix de Freitas Barbosa ',
  },
  {
    id: '628657530fb9bb733c2b042e',
    label: 'Canovas Mendes & Associados - Rafael Mendes ',
  },
  {
    id: '628657530fb9bb733c2b0434',
    label: 'Jesus Oliveira & Associados - Jessika Beatriz Jesus De Oliveira',
  },
  {
    id: '628657530fb9bb733c2b043d',
    label: 'Gustavo Henrique Veloso Vieira - Vieira & Associados',
  },
  {
    id: '628657540fb9bb733c2b044e',
    label: 'Christian Soares de Souza - Coimbra & Co',
  },
  {
    id: '628657540fb9bb733c2b0451',
    label: 'Villaça & Associados - Vinícius Guimarães Villaça',
  },
  {
    id: '628657540fb9bb733c2b0454',
    label: 'Project Manager & Associados',
  },
  {
    id: '628657540fb9bb733c2b0457',
    label: 'Elxai & Associados - Murilo Elxai de Oliveira',
  },
  {
    id: '628657540fb9bb733c2b045d',
    label: 'Borges Duarte & Associados - Thaís Borges Duarte',
  },
  {
    id: '628657540fb9bb733c2b0463',
    label: 'Custódio e Aguiar & Associados - Guilherme Lucas Souza Custódio',
  },
  {
    id: '628657540fb9bb733c2b046c',
    label: 'Baier & Associados - Gilson Baier',
  },
  {
    id: '628657540fb9bb733c2b0475',
    label: 'TERÇARIOL & ASSOCIADOS - Romildo Gonçalves Terçariol Junior',
  },
  {
    id: '628657540fb9bb733c2b0481',
    label: 'Fontanella & Associados - Rafael Yamane Cardoso',
  },
  {
    id: '628657540fb9bb733c2b048d',
    label: 'Kalil & Associados - Mateus Kalil Brigatto  ',
  },
  {
    id: '628657540fb9bb733c2b0490',
    label: 'Vieira Machado & Associados - Alan Vieira Machado',
  },
  {
    id: '628657540fb9bb733c2b0493',
    label: 'Bessa & Co - Gabriel Bessa',
  },
  {
    id: '628657550fb9bb733c2b04ae',
    label: 'DF & Associados - Diego de Freitas',
  },
  {
    id: '628657550fb9bb733c2b04b4',
    label: 'Soares Silva & Associados - João Carlos Pereira Soares',
  },
  {
    id: '628657550fb9bb733c2b04ba',
    label: 'Amaral Pacheco & Associados - Thiago José Anastacio do Amaral',
  },
  {
    id: '628657550fb9bb733c2b04c0',
    label: 'Santos Dias & Associados - Airton José dos Santos Junior',
  },
  {
    id: '628657550fb9bb733c2b04c3',
    label: 'Duarte Marzari & Associados - Alexandre Duarte',
  },
  {
    id: '628657550fb9bb733c2b04c6',
    label: 'Ives Rogério Moller - Ives & Co',
  },
  {
    id: '628657550fb9bb733c2b04cc',
    label: 'Azevedo Mendes & Associados - Danubia Angélica Machado Azevedo',
  },
  {
    id: '628657550fb9bb733c2b04d2',
    label: 'Calvão & Associados - Luiz Calvão de Andrade ',
  },
  {
    id: '628657550fb9bb733c2b04d8',
    label: 'Rondina & Associados - Pedro Henrique Rondina Corrêa',
  },
  {
    id: '628657550fb9bb733c2b04db',
    label: 'Gabriela Neves',
  },
  {
    id: '628657550fb9bb733c2b04e1',
    label: 'Martelli Tomazoni & Associados - Eduardo Gonçalves Martelli',
  },
  {
    id: '628657550fb9bb733c2b04e4',
    label: 'FAGUNDES PRIMO & ASSOCIADOS',
  },
  {
    id: '628657550fb9bb733c2b04e7',
    label: 'Bete & Co. - Thiago Bete',
  },
  {
    id: '628657560fb9bb733c2b04f0',
    label: 'Oliveira Rocha & Associados',
  },
  {
    id: '628657560fb9bb733c2b04f9',
    label: 'Resende Hauffe & Associados',
  },
  {
    id: '628657560fb9bb733c2b04fc',
    label: 'ALCON & ASSOCIADOS',
  },
  {
    id: '628657560fb9bb733c2b04ff',
    label: 'CARDOSO & ASSOCIADOS',
  },
  {
    id: '628657560fb9bb733c2b0505',
    label: 'Fávaro & Associados - Lucas Gabriel Freire Favaro',
  },
  {
    id: '628657560fb9bb733c2b0508',
    label:
      'Cristorey & Associados - Eduardo Jorge de Albuquerque Bezerra Filho',
  },
  {
    id: '628657560fb9bb733c2b050b',
    label: 'WILLRICH & ASSOCIADOS',
  },
  {
    id: '628657560fb9bb733c2b050e',
    label: 'Sousa Gonçalves & Associados - Thiago Henrique Gonçalves',
  },
  {
    id: '628657560fb9bb733c2b0511',
    label: 'Fares Araujo & Associados - Lucas Fares Matias',
  },
  {
    id: '628657560fb9bb733c2b051a',
    label: 'Opis & Associados',
  },
  {
    id: '62c2f758d48423631c86bcfc',
    label: 'Guido Dias & Associados - Tasso da Cunha Dias',
  },
  {
    id: '62c2f758d48423631c86bd05',
    label: 'CWJ & Associados - Wallace Salgado Bandeira',
  },
  {
    id: '62c2f758d48423631c86bd08',
    label: 'BJ Santos & Associados - Bruno José Santos',
  },
  {
    id: '62c2f758d48423631c86bd11',
    label: 'Costa Silva Faria & Associados - Deyvidson Carlos da Silva',
  },
  {
    id: '62c2f758d48423631c86bd17',
    label: "Benedetti & Associados - Renata d'Avila Amaral Benedetti ",
  },
  {
    id: '62cc3d0a2b00fad791f63f60',
    label: 'Rafaela Morais Alarcon',
  },
  {
    id: '62dfd868a5e7ec9145a86c0c',
    label: 'Gabriel Fragoso',
  },
  {
    id: '62dfda8ca5e7ec9145a86c15',
    label: 'Elias Rodrigues & Associados - Pandora Rodrigues da Silva Rebello',
  },
  {
    id: '62dfdbb1a5e7ec9145a86c1a',
    label: 'Araujo Saavedra & Associados - Leticia Lorena Sousa Araujo',
  },
  {
    id: '62e28eeb70958b29878c1664',
    label: 'V4 Tech & Associados - Teconolgia Desenvolvimento & Design',
  },
  {
    id: '63061e2147623cdd40dcb6b3',
    label: 'V4 Dreissig Bicca & Co - William Dreissig Bicca',
  },
  {
    id: '6306201147623cdd40dcb6bd',
    label: 'CTC & Associados - Caina Tojeiro Coube',
  },
  {
    id: '631b676127d2a70db52dfe55',
    label: 'Nascimento & Associados',
  },
  {
    id: '631ca7df27d2a70db52e0cce',
    label: 'Rodrigues Volpato & Associados',
  },
  {
    id: '631cb57227d2a70db52e0cd7',
    label: 'Calzavara & Associados',
  },
  {
    id: '631cb6af27d2a70db52e0cdb',
    label: 'Dittert & Meister Associados',
  },
  {
    id: '631cb8bd27d2a70db52e0ce0',
    label: 'Paiva & Associados',
  },
  {
    id: '631cb9d427d2a70db52e0ce3',
    label: 'Pellizzari & Associados',
  },
  {
    id: '631cf2b327d2a70db52e0d08',
    label: 'Santos Luz & Associados',
  },
  {
    id: '631cf53127d2a70db52e0d0f',
    label: 'Luize & Associados',
  },
  {
    id: '631d004227d2a70db52e0d19',
    label: 'V4 Ames & Co',
  },
  {
    id: '631d030e27d2a70db52e0d22',
    label: 'Oliveira de Marchi Gasparetto & Associados',
  },
  {
    id: '633db58227d2a70db52fa779',
    label: 'Lucio Antônio Neves Furtado II',
  },
  {
    id: '635007d24c7c2ff66b1be6c3',
    label: 'ED.DI & CO.',
  },
  {
    id: '6350094b4c7c2ff66b1be6e7',
    label: 'Nilo & Co.',
  },
  {
    id: '63528aba4c7c2ff66b1bf522',
    label: 'Melo & Co.',
  },
  {
    id: '636574237b0d987616c508aa',
    label: 'Thamiris Natielli Ismail',
  },
  {
    id: '63657a0a7b0d987616c50923',
    label: 'Beatriz de Castro Fernandes Teles Marins',
  },
  {
    id: '6369b65c7b0d987616c520fb',
    label: 'Vinicius Ferreira Mendes',
  },
  {
    id: '6369bc867b0d987616c52102',
    label: 'Matheus Pereira Lopes',
  },
  {
    id: '639322a00dfe59471dc28d62',
    label: 'Atos Rodrigues  Leite ',
  },
  {
    id: '63a1acf7ddb0743515a49d90',
    label: 'V4 Company Veloso & Co.',
  },
  {
    id: '63a1b419ddb0743515a49fbd',
    label: 'V4 Company UP & Co.',
  },
  {
    id: '63a1c294ddb0743515a4a38e',
    label: 'V4 Company Siqueira & Co.',
  },
  {
    id: '63a20874ddb0743515a4b307',
    label: 'V4 Company Scholz, Fortes & Co.',
  },
  {
    id: '63a4a672ddb0743515a4f608',
    label:
      'Diguinho & Associados - ESSE CNPJ NÃO É O CERTO, COLOQUEI O DE OUTRA FRANQUIA PARA TESTAR OS ROYALTIES DO LAB ANTIGO\nCNPJ CORRETO 48.829.582/0001-40',
  },
  {
    id: '63b479c1ddb0743515a58b62',
    label: 'V4 Company Fron & Co.',
  },
  {
    id: '63c2ea54996b533f85110fbc',
    label: 'Maurício de Assunção Schaussard',
  },
  {
    id: '63cec897696f31ee04e81d8a',
    label: 'Gabriel Rodrigues Barbosa',
  },
  {
    id: '63d2c1bd696f31ee04e89377',
    label: 'Cristiano Olandim Placeres',
  },
  {
    id: '63da9eea1983653279a39654',
    label: 'Paulo Roberto Hoeldtke',
  },
  {
    id: '63dd57927d29a0c682d39f08',
    label: 'Andrey Vicente Verderosi',
  },
  {
    id: '63dd613a7d29a0c682d3a0a7',
    label: 'Alessandro Galdino Nunes',
  },
  {
    id: '63dfe2157d29a0c682d3b750',
    label: 'Daniel Carnales',
  },
  {
    id: '63e130497d29a0c682d3cd31',
    label: 'Vicente Alves Rodrigues Rosa Junior',
  },
  {
    id: '63e3b6337d29a0c682d42f39',
    label: 'Lander Fernandes Tavares',
  },
  {
    id: '63e526367d29a0c682d4656c',
    label: 'Ramon Dias Abel',
  },
  {
    id: '63ea3af94087029fa4410e4c',
    label: 'André Luis Rodrigues Victolla',
  },
  {
    id: '63ea78784087029fa4412375',
    label: 'Roberto Dias Silva',
  },
  {
    id: '63ea7c214087029fa4412567',
    label: 'Gabriel Gomes Coelho Leão',
  },
  {
    id: '63ee60694087029fa441b67c',
    label: 'Max Wolff',
  },
  {
    id: '63ee615d4087029fa441b717',
    label: 'Luiz Gustavo Turmina',
  },
  {
    id: '6400ae2c401be7f365d19a37',
    label: 'Flávia Westphal',
  },
  {
    id: '6405f070fbb630f614650317',
    label: 'Pedro Felipe Pizolato DAbruzzo',
  },
  {
    id: '640a9de796d77b6aacf7a061',
    label: 'Falcon - ISaaS',
  },
  {
    id: '6438563ab27c133c6d4707a7',
    label: 'V4 Company Jesus Souza & Co. - Yan Moreira de Jesus',
  },
  {
    id: '64385be5b27c133c6d472e8c',
    label: 'Emerson Dias Batista',
  },
  {
    id: '64386a20b27c133c6d478f89',
    label: 'Sidimar Marcolan dos Santos',
  },
  {
    id: '64386c31b27c133c6d479f9a',
    label: 'Tales Inácio Martins',
  },
  {
    id: '64393c7db27c133c6d4882f1',
    label: 'Julio César Alves Mendes',
  },
  {
    id: '64394031b27c133c6d4886b1',
    label: 'Murilo Rosolem',
  },
  {
    id: '64394868b27c133c6d489541',
    label: 'Raul Oscar de Bessa Marinho',
  },
  {
    id: '64395426b27c133c6d48cca2',
    label: 'Ednel Felix de Macedo',
  },
  {
    id: '64395700b27c133c6d48e180',
    label: 'Henrique Rodrigues Orsi',
  },
  {
    id: '643983d7b27c133c6d49db8d',
    label: 'Fernando Barcellos',
  },
  {
    id: '643acf2eb27c133c6d4b185f',
    label: 'Antonio Marques Moreira Junior',
  },
  {
    id: '64468009e458c932f38f03b3',
    label: 'Manuel Antonio Arredondo Suarez',
  },
  {
    id: '6451835241b006e7fe447515',
    label: 'Felipe Carvalho',
  },
  {
    id: '64550d0f41b006e7fe4e03a2',
    label: 'Unidade Teste_Prod_De_Tal',
  },
  {
    id: '646f967bdedf4449462f65a5',
    label: 'Deyvid Scalcon',
  },
  {
    id: '647a31d64006a82d3c96c034',
    label: 'Gabriel Fragnan Villela',
  },
  {
    id: '647a3d764006a82d3c96e2fe',
    label: 'LUIS FELIPE BORGES MORAO DE OLIVEIRA',
  },
  {
    id: '647f361b4006a82d3c9aad7b',
    label: 'Anderson Augusto Francisco',
  },
  {
    id: '647f3cae4006a82d3c9ade9d',
    label: 'Márcio Rafael Hideyoshi Nóbrega Nakamura',
  },
  {
    id: '647f42494006a82d3c9b0c84',
    label: 'Hilel & Co. - Phillippe Augusto Hilel Cardoso',
  },
  {
    id: '647f580c4006a82d3c9b6f0a',
    label: 'Douglas Schimelfenig',
  },
  {
    id: '64887a0d4006a82d3ca84a0b',
    label: 'Kessler & Co. - Fernando Kessler',
  },
  {
    id: '649355384122e0fb0098be60',
    label: 'Graziela Valin Barbosa',
  },
  {
    id: '649469b14122e0fb009d68eb',
    label: 'Maurício Honorato',
  },
  {
    id: '64946a7f4122e0fb009d713c',
    label: 'Vinícius Henrique de Souza Oliveira',
  },
  {
    id: '64a317264122e0fb00c321ab',
    label: 'Jônata Santos Amaral',
  },
  {
    id: '64a31d084122e0fb00c330f7',
    label: 'Anselmo José Marangoni Brandão Bueno',
  },
  {
    id: '64a31ff84122e0fb00c337e7',
    label: 'MICHAEL SANDES DE CARVALHO',
  },
  {
    id: '64a5aa07e03cb77cecf2ac57',
    label: 'José Kleydir Pereira da Silva',
  },
  {
    id: '64abf8f2e03cb77cecfe2931',
    label: 'Rodolfo Leonardo Rodrigues',
  },
  {
    id: '64ac021ae03cb77cecfe5598',
    label: 'Douglas Rafael do Nascimento Ribeiro',
  },
  {
    id: '64ac58e4e03cb77cec0034e3',
    label: 'Fernando César de Oliveira Alves ',
  },
  {
    id: '64ad490be03cb77cec024c4e',
    label: 'Gustavo Andrade dos Santos',
  },
  {
    id: '64ad51e4e03cb77cec028dcd',
    label: 'Lucas da Silva Lopes',
  },
  {
    id: '64ad7c5de03cb77cec03f7ce',
    label: 'Mateus Esteves Ribeiro',
  },
  {
    id: '64af05d2e03cb77cec0a42ff',
    label: 'ALEF JUNIO PEREIRA DA SILVA',
  },
  {
    id: '64afe6ebe03cb77cec0bd6cc',
    label: 'Mauro Enrique Dos Santos Costa',
  },
  {
    id: '64b05cdce03cb77cec1106cb',
    label: 'Vinicius Furlan Silva',
  },
  {
    id: '64b70f3ee03cb77cec22a08e',
    label: 'Carlos de Castro La Yunta',
  },
  {
    id: '64c81038e03cb77cec4e189f',
    label: 'Marcus Vinicius Lopes Chaves',
  },
  {
    id: '64d0fd2ce03cb77cec64fdc5',
    label: 'Denis Lopes de Souza',
  },
  {
    id: '64de6535e03cb77cec8fc698',
    label: 'Weverton Almeida dos Santos',
  },
  {
    id: '64e38d8ce03cb77cec9b81ed',
    label: 'Alencar Carvalho & Co. - Tales Carvalho da Paixão',
  },
  {
    id: '64e78f9b0277af72bf1eb272',
    label: 'Olivio Francisco de Oliveira Neto',
  },
  {
    id: '64e795670277af72bf1eef31',
    label: 'Danilo de Campos Araújo',
  },
  {
    id: '64e7a7d20277af72bf1fb694',
    label: 'Bruno Augusto Chagas Alves',
  },
  {
    id: '64e87ec10277af72bf22c64c',
    label: 'Caio Santos Davi',
  },
  {
    id: '64e884950277af72bf22d044',
    label: 'Adriano Vieira Coelho ',
  },
  {
    id: '64ec966f0277af72bf295c35',
    label: 'Ellen Guimarães Santos Lourenço Martins',
  },
  {
    id: '64ecc1c00277af72bf2a5526',
    label: 'Cícero José Ferreira Neto',
  },
  {
    id: '64ee3b980277af72bf3262c1',
    label: 'Nicolas Nunes Ruston',
  },
  {
    id: '64ef77480277af72bf36ad9d',
    label: 'Diogo de Sousa Novaes',
  },
  {
    id: '64f7c45b0277af72bf508d7d',
    label: 'RICARDO KIOSHI TAJIMA',
  },
  {
    id: '64fb65910277af72bf5b4122',
    label: 'Rafael da Cunha Araújo',
  },
  {
    id: '64fb68f30277af72bf5b6351',
    label: 'Renan da Silva Barros',
  },
  {
    id: '64fcbd250277af72bf5d4bf3',
    label: 'Oliveira Ramalho & Co - Gustavo Oliveira',
  },
  {
    id: '6500b6e80277af72bf67ea28',
    label: 'RAPHAEL ALVES BRUNINI',
  },
  {
    id: '6502ffae0277af72bf6fa6df',
    label: 'Talline Hannah de Lima Fernandes dos Santos',
  },
  {
    id: '650347560277af72bf727d7c',
    label: 'Moacir Pereira Junior',
  },
  {
    id: '650846570277af72bf7b7d9b',
    label: 'Alexandre André da Silveira',
  },
  {
    id: '650985b90277af72bf7ff114',
    label: 'Filipe Enrico Stefano da Silva',
  },
  {
    id: '650ae3d20277af72bf86547b',
    label: 'Antonio Guimaraes Campos',
  },
  {
    id: '650ae4570277af72bf8657ed',
    label: 'Thomas Rabelo Andrade',
  },
  {
    id: '650c79e90277af72bf8d83fd',
    label: 'Jessica Nogueira Gonçalves',
  },
  {
    id: '650cc1ae0277af72bf8f7c3d',
    label: 'Leonardo Jasson de Oliveira',
  },
  {
    id: '650dc75a0277af72bf924500',
    label: 'Lisiani Nunes',
  },
  {
    id: '651577e85f58a3f8aa597934',
    label: 'Jonathan Costa Ribeiro',
  },
  {
    id: '6515a5a35f58a3f8aa5ac4bc',
    label: 'Vitor Fernando de Paula',
  },
  {
    id: '652426405f58a3f8aa8a652f',
    label: 'Patricia Euzebio da Silva',
  },
  {
    id: '652d1c7e5f58a3f8aaa7d122',
    label: 'Talita Cruzera Ferreira Warcken',
  },
  {
    id: '6529af7ce6ec5ebe5db83134',
    label: 'Meeting Broker [TESTE] Homolog',
  },
  {
    id: '652ed4635f58a3f8aab06a16',
    label: 'Sanzovo & Co. - Rodrigo Sanzovo',
  },
  {
    id: '653a9b5d7a7c676cab39133d',
    label: 'Juliana Dantas Tavares Cardinot',
  },
  {
    id: '653a9ba07a7c676cab39143a',
    label: 'Carlos Henrique Lobato Arruda',
  },
  {
    id: '653a9bf57a7c676cab3915a9',
    label: 'Gabriel isidoro Trevisan',
  },
  {
    id: '654a762a5d8c11227e16b7be',
    label: 'Fabiano Podgurski Borges',
  },
  {
    id: '654bc27d5d8c11227e1b8724',
    label: 'Felipe Inocencio Martins',
  },
  {
    id: '65844524745cc3acb07e8585',
    label: 'SLN & Co. - Suélen Suzana Silva',
  },
  {
    id: '65844bbc745cc3acb07e9f94',
    label: 'Rico & Co. - Paulo Ricardo Rico Tavares',
  },
  {
    id: '65845446745cc3acb07ebc1c',
    label: 'CSA & CO. - Priscila Silva Alencar',
  },
  {
    id: '65956b2f745cc3acb097f644',
    label: 'Lamb & Co. - Álvaro Ehlers Lamb',
  },
  {
    id: '65956ea7745cc3acb09803b2',
    label: 'Vila Santos & Co. - Gustavo Martins Vila',
  },
  {
    id: '659d74b7de146d73c2466cb2',
    label: 'Driemeyer & Co. - Larissa Marques Driemeyer',
  },
  {
    id: '659d7e4fde146d73c24676e7',
    label: 'Alimari & Co. - Silvia Aparecida Tolentino Alimari',
  },
  {
    id: '65a19e7cde146d73c249dc21',
    label: 'V4 Company - USA',
  },
  {
    id: '65affc35de8360ea892166e6',
    label: 'Vulcano Arantes & Co. - Natalia Vulcano Nogueira',
  },
  {
    id: '65b4233506a04bd1018db458',
    label: 'Carvalho Santos & Co. - Miriane Carvalho de Paula ',
  },
  {
    id: '65b4341d06a04bd1018dbdc1',
    label: 'KTM & Co. - Rafael Kleiton de Oliveira',
  },
  {
    id: '65b436ca06a04bd1018dbf31',
    label: 'BBC & Co. - Jânio Cordeiro dos Santos',
  },
  {
    id: '65b7ec4806a04bd1018fefc7',
    label: 'LFC & Co. - Breno Ferreira Leite',
  },
  {
    id: '65b7ef8606a04bd1018ff370',
    label: 'MAP & Co. - Antonio Carlos Fonseca de Menezes Filho',
  },
  {
    id: '65bd694906a04bd10193de46',
    label: 'RB & Co. - João Pedro Fernandes Ribeiro',
  },
  {
    id: '65e0cf4ca44763d218301543',
    label: 'Calebe Pacheco Ferreira',
  },
  {
    id: '65e20078a44763d21830de9e',
    label: 'Thiago Henrique Krüger Queiroz',
  },
  {
    id: '65e5c68ea44763d2183303d3',
    label: 'Fernando Rodrigo Jesus Candido',
  },
  {
    id: '65e8aa58a44763d2183528af',
    label: 'Francisco Luciano Ferreira da Silva ',
  },
];
