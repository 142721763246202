import styled from 'styled-components';

interface SectionProps {
  disabled?: boolean;
}

export const Section = styled.section<SectionProps>`
  position: relative;
  display: flex;
  background-color: var(--white);
  border-radius: 0.5rem;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
  margin: 1.5rem 0;
`;
