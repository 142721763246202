export enum ERRORS_CREATE_USER {
  'E80000' = 'E80000',
  'E80001' = 'E80001',
  'E80002' = 'E80002',
  'E80004' = 'E80004',
  'E80005' = 'E80005',
  'E80006' = 'E80006',
  'E80007' = 'E80007',
  'E80008' = 'E80008',
  'E80009' = 'E80009',
  'E80010' = 'E80010',
  'E80011' = 'E80011',
  'E80012' = 'E80012',
  'E80013' = 'E80013',
  'E80014' = 'E80014',
  'E80015' = 'E80015',
  'E80016' = 'E80016',
  'E80017' = 'E80017',
  'E80018' = 'E80018',
  'E80019' = 'E80019',
  'E80020' = 'E80020',
  'E80021' = 'E80021',
}

export const errorsCreateUser = {
  E80000: 'Falha ao ativar usuário',
  E80001: 'Falha ao criar permissões do usuário',
  E80002: 'Falha ao convidar usuário',
  E80004: 'Falha ao encontrar unidade',
  E80005: 'Falha ao encontrar usuário',
  E80006: 'Unidade não está ativa',
  E80007:
    'Você não pode convidar um usuário para outra unidade que não seja a sua',
  E80008: 'Não foi possível encontrar ou criar o usuário',
  E80009: 'Falha ao encontrar permissões do usuário pelo usuário e a unidade',
  E80010: 'Falha ao ENCONTRAR permissões do usuário pelo ID',
  E80011: 'Falha ao atualizar permissões do usuário',
  E80012: 'O e-mail informado já está em uso',
  E80013: 'Nenhum contrato foi encontrado',
  E80014: 'Não foi possível subir o contrato',
  E80015: 'Nenhuma solicitação aprovada foi encontrada',
  E80016: 'CPF informado já está em uso',
  E80017: 'Falha ao atualizar permissões do usuário',
  E80018: 'Falha ao criar permissões do usuário',
  E80019: 'Usuário já foi convidado',
  E80020: 'Cargo especificado não foi encontrado',
  E80021: 'Senioridade especificada não foi encontrada',
};
