import { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  LabDsButton,
  LabDsSearchBar,
  LabDsTab,
  LabDsBreadcrumb,
  LabDsTooltip,
  LabDsTable,
} from 'v4web-components-react';
import { IData } from 'v4web-components';
import { useInfiniteQuery } from '@tanstack/react-query';
import { parseCookies } from 'nookies';
import {
  Container,
  Section,
  SectionContent,
  Headline,
} from '../../components/LegacyV4Components';
import { customerApi } from '../../services/api';

import { useAuth } from '../../hooks/auth';

import * as S from './styles';
import { CustomersColumns, MatrixCustomersColumns } from './columns';
import useDebounce from '../../hooks/useDebounce';
import { CreateCustomerModal } from '../../components/AtomicDesign/molecules/CreateCustomerModal';
import { Customer } from '../../types/customer';

enum Tabs {
  active = 'Ativos',
  inactive = 'Inativos',
}

interface CustomerRequest {
  limit: number;
  page: number;
  total: number;
  totalPages: number;
  data: Customer[];
}

export function Customers() {
  const [tab, setTab] = useState(Tabs.active.toString());
  const [showCreateCustomerModal, setShowCreateCustomerModal] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const { user } = useAuth();

  const isUserAllowedToCreateCustomer = user.permissions?.customer?.edit;
  const isHeadquarterUser =
    user.unitId === `${process.env.REACT_APP_HEADQUARTER_ID}`;

  const headers = isHeadquarterUser ? MatrixCustomersColumns : CustomersColumns;

  const pageTitle = isHeadquarterUser ? 'Clientes V4' : 'Meus clientes';
  const breadcrumbsItems = [
    { label: pageTitle, link: '/clientes', key: pageTitle, event: () => '' },
  ];

  const searchPlaceholder = isHeadquarterUser
    ? 'Busque por cliente, unidade...'
    : 'Busque por cliente';

  const unitsURL = isHeadquarterUser
    ? '/customer'
    : `/customer/unit/${user.unitId}`;

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce<string>(query, 500);

  const cookies = parseCookies(undefined);
  const token = cookies['v4company.token'];

  const { data, fetchNextPage } = useInfiniteQuery(
    ['customers', debouncedQuery, tab],
    async ({ pageParam = 1 }) => {
      const customersPromise = (await customerApi({
        method: 'GET',
        url: unitsURL,
        params: {
          page: pageParam,
          limit: 20,
          q: debouncedQuery,
          isActive: tab === 'Ativos',
        },
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => res.data)) as unknown as CustomerRequest;
      return customersPromise;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.page - 1 ?? undefined,
      getNextPageParam: (lastPage) =>
        lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const rows: IData['rows'] = useMemo(() => {
    const rowsData: IData['rows'] = [];
    data?.pages?.forEach((page) => {
      page.data?.forEach((customer) => {
        rowsData.push({
          name: {
            text: {
              title: customer.name,
            },
            handleRow: () => {
              navigate(`/cliente/${customer._id}`);
            },
          },
          unit: {
            handleRow: () => {
              navigate(`/cliente/${customer._id}`);
            },
          },
          nps: {
            handleRow: () => {
              navigate(`/cliente/${customer._id}`);
            },
          },
          csat: {
            handleRow: () => {
              navigate(`/cliente/${customer._id}`);
            },
          },
          roi: {
            handleRow: () => {
              navigate(`/cliente/${customer._id}`);
            },
          },
          lifetime: {
            handleRow: () => {
              navigate(`/cliente/${customer._id}`);
            },
          },
        });
      });
    });
    return rowsData;
  }, [data, navigate]);

  const [disabledDownload, setDisabledDownload] = useState(false);

  async function handleDownloadClick() {
    try {
      setDisabledDownload(true);
      const response = await customerApi({
        method: 'GET',
        url: `/customer/export/csv?active=${tab === 'Ativos'}`,
        responseType: 'blob',
      }).then((res) => res.data);

      const downloadUrl = URL.createObjectURL(response as Blob);

      window.open(downloadUrl);

      URL.revokeObjectURL(downloadUrl);
    } finally {
      setDisabledDownload(false);
    }
  }

  return (
    <>
      <Container>
        <LabDsBreadcrumb breadcrumbs={breadcrumbsItems} />

        <Section>
          <SectionContent>
            <S.SectionHeader>
              <Headline variant="h3">{pageTitle}</Headline>
              <S.ButtonsWraper>
                <Link to="/clientes/mediahub">
                  <LabDsButton
                    variant="primary"
                    color="green"
                    size="medium"
                    stepIcon="menu"
                    onHandleButton={() => null}
                    label="MediaHub"
                  />
                </Link>
                <Link to="/clientes/stackdigital">
                  <LabDsButton
                    variant="primary"
                    color="green"
                    size="medium"
                    leadingIcon="Handyman"
                    onClick={() => null}
                    label="Stack Digital"
                  />
                </Link>
                {isUserAllowedToCreateCustomer && (
                  <LabDsButton
                    variant="outlined"
                    size="medium"
                    leadingIcon="add"
                    onHandleButton={() => setShowCreateCustomerModal(true)}
                    label="Adicionar"
                  />
                )}
              </S.ButtonsWraper>
            </S.SectionHeader>
            <S.Row>
              <LabDsTab
                value="active"
                tabs={[
                  {
                    title: 'Ativas',
                    key: 'active',
                    event: () => setTab(Tabs.active),
                  },
                  {
                    title: 'Inativas',
                    key: 'inactive',
                    event: () => setTab(Tabs.inactive),
                  },
                ]}
              />
            </S.Row>
            <S.Filters>
              <div>
                <LabDsSearchBar
                  value={query}
                  onChangeInput={({ detail }) => setQuery(detail)}
                  placeholder={searchPlaceholder}
                  label={searchPlaceholder}
                />
              </div>
              <LabDsTooltip
                label="Baixar planilha .CSV !"
                tooltipPosition="seven-hours"
              >
                <LabDsButton
                  variant="outlined"
                  color="green"
                  size="medium"
                  leadingIcon="Download"
                  disabled={disabledDownload}
                  onClick={handleDownloadClick}
                  label=""
                />
              </LabDsTooltip>
            </S.Filters>
            <LabDsTable
              onFinalLineNextPage={() => fetchNextPage()}
              data={{ headers, rows }}
            />
          </SectionContent>
        </Section>
      </Container>
      <CreateCustomerModal
        isOpen={showCreateCustomerModal}
        setIsOpen={() => setShowCreateCustomerModal(!showCreateCustomerModal)}
      />
    </>
  );
}
