import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LabDsBreadcrumb } from 'v4web-components-react';
import { MdOutlineHistory } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { LabDsButton } from 'v4web-components-react';
import {
  Container,
  Headline,
  Section,
  SectionContent,
} from '../../components/LegacyV4Components';

import { customerApi } from '../../services/api';
import { getInvestorsByCustomersId } from '../../services/requests/Customers/getInvestorsByCustomersId';

import * as S from './styles';

import { ListTeam } from './layout/listTeam';

import { Customer } from '../../types/customer';
import { InvestorTeam } from '../../hooks/types';

export function CustomerTeam() {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const [investorsOnProjetct, setInvestorsOnProjetct] = useState<
    InvestorTeam[]
  >([]);
  const [customer, setCustomer] = useState<any>();

  useEffect(() => {
    async function getInvestors() {
      const usersPromise = await getInvestorsByCustomersId(id || '');
      if (usersPromise) setInvestorsOnProjetct(usersPromise);
      return usersPromise;
    }
    getInvestors();
  }, [id]);

  const getCustomer = useCallback(async () => {
    await customerApi({
      url: `/customer/${id}`,
      method: 'GET',
    })
      .then((res) => setCustomer(res.data as Customer))
      .catch(() => {
        return;
      });
  }, [id]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  const breadcrumbsItems = [
    {
      label: 'Meus Clientes',
      link: '/clientes',
      key: 'customers',
      event: () => navigate('/clientes'),
    },
    {
      label: `${customer?.name ?? ''}`,
      link: `/cliente/${customer?._id}`,
      key: 'customer',
      event: () => navigate(`/cliente/${customer?._id}`),
    },
    { label: 'Time responsável', link: '', key: 'team', event: () => null },
  ];

  return (
    <>
      <Container>
        <LabDsBreadcrumb breadcrumbs={breadcrumbsItems} />

        <Section>
          <SectionContent>
            <S.Row>
              <Headline variant="h3">
                Time responsável
                <Link to={`/unit/view/historic/${id}`}>
                  <MdOutlineHistory size={28} onClick={() => undefined} />
                </Link>
              </Headline>
              <LabDsButton
                label="Editar"
                variant="primary"
                size="medium"
                color="green"
                stepIcon="edit"
                onClick={() => {
                  navigate('edit');
                }}
              />
            </S.Row>
            <S.Row>
              {customer?.isNewPrice ? (
                <p>
                  Esse é um projeto de <b>New Price</b>, por isso alumas
                  posições são fixas e devem estar sempre preenchidas.
                </p>
              ) : (
                <p>
                  Edite o time resposável, se for preciso acrescente mais
                  investidores.
                </p>
              )}
            </S.Row>

            <ListTeam data={investorsOnProjetct || []} />
          </SectionContent>
        </Section>
      </Container>
    </>
  );
}
