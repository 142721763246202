import styled from 'styled-components';

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3 3 0;
  gap: 1rem;
  padding-right: 3rem;
`;

export const Info = styled.div`
  flex-direction: column;
  display: flex;
  flex: 2;
  gap: 1rem;
  padding-left: 3rem;
`;

export const HelpLink = styled.p`
  margin-left: 1.25rem;
  color: var(--success-2);
  line-height: 1.5rem;
  text-decoration-line: underline;
`;
