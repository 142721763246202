import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 5rem;
  left: -20rem;

  &.show {
    left: 5rem;
  }

  transition: left 0.3s;
`;

export const Notification = styled.div`
  display: flex;
  flex-direction: column;

  background-color: var(--grayscale-2);
  border-radius: 4px;
  border-left: 0.25rem solid var(--warning);
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.12);

  width: 18rem;
  padding: 1rem;
  padding-left: calc(1rem - 0.25rem);
  padding-bottom: 0.625rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  margin-bottom: 1rem;

  svg {
    color: var(--warning);
  }
`;

export const Body = styled.div`
  font-size: 0.75rem;
`;

export const Footer = styled.div`
  text-align: right;

  p {
    display: block;
    font-size: 0.875rem;
    cursor: pointer;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -11px;
  right: -11px;

  cursor: pointer;

  background-color: var(--white);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 2px 6px 2px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  padding: 0.25rem;
`;
