import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 0rem;
  border-radius: 0.3rem;
  border-top-left-radius: 0;
  width: 68%;
  @media (max-width: 880px) {
    width: auto;
  }
`;
