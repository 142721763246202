export const typeToConvert = (
  value: string,
  type: 'date' | 'hour' | 'coin' | string,
) => {
  if (type === 'date') return new Date(value).toLocaleDateString();
  if (type === 'hour') return new Date(value).toLocaleTimeString();
  if (type === 'coin')
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(value || 0));
  return value;
};
