import { HistoricParticipationInterface } from '../../../types/customer';
import { usersApi as api } from '../../api';

export async function getCustomerHistoric(customerId: string) {
  try {
    const response = await api.get(
      `capacity/manage/find/historic/customer/${customerId}`,
    );
    return response.data as HistoricParticipationInterface[];
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os dados dos clientes, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
