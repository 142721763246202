import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const CookieMenu = styled.div`
  display: flex;
  position: relative;
  // margin-bottom: 1.5rem;
  // div svg {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  .cookie-menu {
    border-radius: 50%;
    width: 3.2rem;
    height: 3.2rem;
    background-color: #00c49a;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #0ddeb1;
    }
  }
`;

export const Options = styled.div`
  display: flex;
  margin: 0.5rem 0;
  justify-content: space-between;
  border: 1px solid #c1c1d7;
  padding: 0.7rem 1rem;
  border-radius: 0.5rem;
`;

export const CookieOptions = styled.div<{
  isHidden: boolean;
  reversed?: boolean;
}>`
  display: none;
  flex-direction: column;
  position: absolute;
  bottom: 3rem;
  right: 0;
  width: 20rem;
  background: var(--white);
  padding: 1rem 1.5rem;

  transition: all 0.5s;
  border-radius: 0.3rem;
  border: 1px solid #b4b4cf;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);

  h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
  }

  span {
    font-family: 'Nunito Sans';
    font-weight: 400;
    font-size: 0.8rem;
  }

  ${({ isHidden }) =>
    isHidden &&
    css`
       {
        max-height: 15rem;
        overflow: hidden;
        display: flex;

        button {
          pointer-events: auto;
        }
      }
    `}
`;
