import { Dashboard } from '../../../types/dashboard';
import { CreateInvoiceMW } from '../../../types/createCustomerFNC';
import { midiaWalletApi } from '../../api';

interface ICreateInvoice {
  data: CreateInvoiceMW;
  customerId: string;
}

export async function createInvoice(
  data: CreateInvoiceMW,
  customerId: string,
): Promise<ICreateInvoice | unknown> {
  try {
    const response = await midiaWalletApi.post<Dashboard>(
      `/media-wallet/transaction/entry/mediawallet-${customerId}`,
      data,
    );
    const product = response.data;

    return product;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar esta cobrança, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
