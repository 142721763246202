import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  & > button {
    position: absolute;
    top: 0.8rem;
    right: 1.3rem;
    width: 1rem;
    height: 1rem;

    background: transparent;
    border: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 0.5rem;
  background-color: var(--white);

  border-bottom: 1px;
  padding: 1.5rem;

  hr {
    margin: 1.5rem 0;
  }
`;

export const Footer = styled.div`
  display: flex;
  lab-ds-button {
    :first-of-type {
      margin-right: 1.5rem;
    }
  }
`;
