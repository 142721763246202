import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaInstagram, FaWhatsapp, FaLinkedinIn } from 'react-icons/fa';
import { AiOutlineSetting } from 'react-icons/ai';
import { LabDsTextField } from 'v4web-components-react';
import { Link } from 'react-router-dom';
import { Button } from '../../../LegacyV4Components';
import { useAuth } from '../../../../hooks/auth';
import { User } from '../../../../hooks/types';

import * as S from './styles';
// import { formatStringToBRLDate } from '../../../../utils/formatStringToBRLDate';
import { ProfileCard } from '../../../Default/ProfileCard';
import { createUser } from '../../../../services/requests/Eletromidia/createUser';
import { useToast } from '../../../../hooks/toast';
import { useEletromidiaUser } from '../../../../hooks/eletromidiaUser';

interface UserCardProps {
  profileUser: User;
}

const eletromidiaUrl = process.env.REACT_APP_ELETROMIDIA_WEB_URL;

export function UserCard({ profileUser }: UserCardProps) {
  const [userCard] = useState(profileUser);
  const { user } = useAuth();
  const { permissions } = user;

  const [userCpf, setUserCpf] = useState<string>('');
  const [cpfError, setCpfError] = useState<boolean>(true);

  const {
    eletromidiaUser,
    eletromidiaToken,
    setEletromidiaUser,
    eletromidiaLoading,
    setEletromidiaLoading,
  } = useEletromidiaUser();

  const { addToast } = useToast();

  const [dotMenuIsOpen, setDotMenuIsOpen] = useState<boolean>(false);
  const [porc, setPorc] = useState<string>('60%');
  const navigate = useNavigate();

  async function goToEdit() {
    navigate(`/perfil/edit/${userCard._id}`);
  }

  const handler_porc_profile = () => {
    const numberOfInfosCompleted = [];

    if (userCard.links?.length > 0) {
      if (!userCard.links[0]?.url) numberOfInfosCompleted.push('instagram');
      if (!userCard.links[1]?.url) numberOfInfosCompleted.push('linkedin');
    } else {
      numberOfInfosCompleted.push('instagram', 'linkedin');
    }

    if (!userCard.phone) numberOfInfosCompleted.push('phone');

    if (numberOfInfosCompleted.length > 0) {
      setPorc(
        `${Math.round(100 - (numberOfInfosCompleted.length * 100) / 7)}%`,
      );
    } else {
      setPorc('100%');
    }
  };

  const convert_currency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };
  useEffect(() => {
    handler_porc_profile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCard]);

  const formatPhone = (phone: string) => {
    return String(phone).replace(
      /(\d{2})(\d{1})(\d{4})(\d{4})/,
      '($1) $2.$3-$4',
    );
  };

  function extractNumbers(value: string) {
    return value.replace(/\D/g, '');
  }

  function formatCEP(cep: string) {
    if (cep.length === 8) {
      return `${cep.substring(0, 5)}-${cep.substring(5)}`;
    }
    return cep;
  }

  const createEletromidiaAccount = useCallback(async () => {
    setEletromidiaLoading(true);

    const newEletromidiaUser: CreateUserData = {
      name: user.name,
      email: user.email,
      password: '12345678',
      type: 'cpf',
      document: user.documents?.cpf ? user.documents?.cpf : userCpf, // cpf unico
      phone: extractNumbers(user.phone)
        ? extractNumbers(user.phone)
        : '99999999999',
      cel: extractNumbers(user.phone), // telefone unidade
      comercial_address: {
        city: user.unit?.address.city
          ? user.unit?.address.city
          : 'São Leopoldo',
        country: 'Brasil',
        district: user.unit?.address.district
          ? user.unit?.address.district
          : 'Cristo Rei',
        number: user.unit?.address.number
          ? String(user.unit?.address.number)
          : '3101',
        postal_code: user.unit?.address.zipCode
          ? formatCEP(user.unit?.address.zipCode)
          : '93022-715',
        state: user.unit?.address.state ? user.unit?.address.state : 'RS',
        street: user.unit?.address.street
          ? user.unit?.address.street
          : 'Av. Teodomiro Porto da Fonseca',
      },
    };

    const eletromidiaUserResponse = await createUser(newEletromidiaUser);

    if (eletromidiaUserResponse) {
      addToast({
        title: 'Cliente criado com sucesso',
        type: 'success',
        description:
          'Faça login clicando no botão abaixo ou na opção Eletromidia no menu lateral',
      });

      setEletromidiaUser(eletromidiaUserResponse);
      setEletromidiaLoading(false);
    } else {
      addToast({
        title: 'Erro ao criar cliente',
        type: 'error',
        description: 'Entre em contato com o suporte na sua unidade.',
      });
      setEletromidiaLoading(false);
    }
  }, [user, userCpf, addToast, setEletromidiaUser, setEletromidiaLoading]);

  const permissionEdit =
    user._id === userCard._id ||
    permissions?.users?.edit ||
    permissions?.unit?.admin ||
    permissions?.headquarter?.admin;

  return (
    <S.Container>
      {permissionEdit && userCard.isActive ? (
        <S.DotMenu>
          <AiOutlineSetting onClick={() => setDotMenuIsOpen(!dotMenuIsOpen)} />

          <S.DotOptions isHidden={dotMenuIsOpen}>
            <button
              style={{
                borderBottomLeftRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
              }}
              type="button"
              onClick={goToEdit}
            >
              Editar Informações
            </button>
          </S.DotOptions>
        </S.DotMenu>
      ) : null}

      <ProfileCard profile={userCard} />

      <h3>{userCard.name}</h3>
      <S.Links>
        {userCard.links && userCard.links[0] && userCard.links[0].url && (
          <a
            href={`https://www.instagram.com/${userCard?.links[0]?.url}/`}
            target="_blank"
            rel="noreferrer"
            aria-label="default"
          >
            <FaInstagram size={30} color="#BC2A8D" />
          </a>
        )}

        {userCard.links && userCard.links[1] && userCard.links[1].url && (
          <a
            href={userCard?.links[1]?.url}
            target="_blank"
            rel="noreferrer"
            aria-label="default"
          >
            <FaLinkedinIn
              size={30}
              style={{ marginLeft: 30, marginRight: 30 }}
              color="#0077B5"
            />
          </a>
        )}

        {userCard.phone && userCard.phone.length > 0 && (
          <a
            href={`https://wa.me/55${userCard.phone}`}
            target="_blank"
            rel="noreferrer"
            aria-label="default"
          >
            <FaWhatsapp size={30} color="#25D366" />
          </a>
        )}
      </S.Links>
      <>
        {porc !== '100%' && user._id === userCard._id && (
          <>
            <h4 className="info__card">Complete seu perfil em poucos passos</h4>
            <h5 className="sub__info__card">
              Ao completar seu perfil você poderá ser encontrado com mais
              facilidade pela rede V4Company
            </h5>

            <S.ProgressContent>
              <S.ProgressBar porc={porc} />
            </S.ProgressContent>

            <h5 className="sub__info__card margin__top">
              {porc} do perfil completo
            </h5>
            <S.RowLeft>
              <Button
                variant="tertiary"
                color="red"
                size="default"
                onClick={() => navigate(`/perfil/edit/${userCard._id}`)}
              >
                Completar informações
              </Button>
            </S.RowLeft>
          </>
        )}
      </>

      <S.Infos>
        <>
          <S.Row>
            <h4>Função / cargo</h4>
            <p>
              {userCard.mandate?.name
                ? userCard.mandate?.name
                : 'Sem Função / cargo'}
            </p>
          </S.Row>
          <S.Row>
            <h4>Senioridade</h4>
            <p>
              {userCard.seniority?.name
                ? userCard.seniority?.name
                : 'Sem senioridade'}
            </p>
          </S.Row>
          {user._id === userCard._id && (
            <S.Row>
              <h4>Salário</h4>
              <p>
                {userCard.wage
                  ? convert_currency(userCard.wage as number)
                  : 'Salário não informado!'}
              </p>
            </S.Row>
          )}

          <S.Row>
            <h4>E-mail</h4>
            <p>{userCard.email}</p>
          </S.Row>
          <S.Row>
            <h4>Telefone</h4>
            <p>
              {userCard.phone
                ? formatPhone(userCard.phone)
                : 'Telefone Não informado!'}
            </p>
          </S.Row>
          {/* <S.Row>
            <h4>Data de início na V4 Company</h4>
            <p>{formatStringToBRLDate(userCard.createdAt)}</p>
          </S.Row> */}
          <S.Row>
            {!eletromidiaUser.email ? (
              <>
                {!user.documents?.cpf && (
                  <LabDsTextField
                    value=""
                    state={cpfError ? 'error' : 'default'}
                    onChangeInput={({ detail }) => {
                      if (detail.length >= 11) {
                        setUserCpf(detail);
                        setCpfError(false);
                      }
                    }}
                    titleInput="Digite seu cpf para ativar sua conta na Eletromidia*"
                    label="000.000.000-00"
                    mask="CPF"
                    maxLength={11}
                    style={{ marginBottom: 10 }}
                  />
                )}

                <Button
                  variant="primary"
                  color="red"
                  size="default"
                  onClick={() => createEletromidiaAccount()}
                  disabled={user.documents?.cpf ? false : cpfError}
                  loading={eletromidiaLoading}
                >
                  Ativar conta na Eletromidia
                </Button>
              </>
            ) : (
              <Link
                to={`${eletromidiaUrl}/parceiro/login?token=${eletromidiaToken}`}
              >
                <Button
                  variant="primary"
                  color="green"
                  size="default"
                  loading={eletromidiaLoading}
                  onClick={() => true}
                >
                  Acessar Eletromidia
                </Button>
              </Link>
            )}
          </S.Row>
        </>
      </S.Infos>
    </S.Container>
  );
}
