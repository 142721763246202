import styled, { css } from 'styled-components';

export const SelectWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  & .disabled {
    cursor: no-drop;
    opacity: 0.6;
    pointer-events: none;
  }
`;

export const SelectPlaceholder = styled.div`
  position: relative;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: 1px solid var(--grayscale-4);
  background-color: var(--white);

  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: var(--secondary-light);

    width: calc(100% + 1.5rem);
    width: 100%;
    margin-right: 3rem;
  }

  &.filled p {
    color: var(--secondary-main);
    font-weight: 700;
  }

  & .select-arrow {
    position: absolute;

    color: var(--secondary-light);

    transition: opacity 0.3s;
  }

  & .select-arrow.arrow-down {
    opacity: 1;
  }

  & .select-arrow.arrow-up {
    opacity: 0;
  }

  &.open {
    & .select-arrow.arrow-down {
      opacity: 0;
    }

    & .select-arrow.arrow-up {
      opacity: 1;
    }
  }

  &:hover,
  &.open {
    border-color: var(--grayscale-20);

    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.06);
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
  }
`;

export const SelectArrows = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectOptionsWrapper = styled.div`
  position: absolute;
  top: calc(100% + 0.5rem);

  width: 100%;

  background-color: var(--white);

  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.06);
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));

  display: none;
  flex-direction: column;

  &.open {
    display: flex;
  }

  z-index: 2;

  border-radius: 4px;
  border: 1px solid var(--grayscale-20);
`;

export const SelectSearchWrapper = styled.div`
  display: flex;
  gap: 1rem;

  padding: 1rem 1.5rem 0.5rem;

  border-bottom: 1px solid var(--grayscale-4);
`;

export const SelectSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-grow: 1;

  padding: 0.5rem 1rem;

  border-radius: 4px;
  border: 1px solid var(--grayscale-4);

  & input {
    flex-grow: 1;

    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--secondary-light);

    border: none;
  }

  & svg {
    flex-shrink: 0;

    color: var(--secondary-light);
  }
`;

export const SelectOptionsBody = styled.div`
  padding: 0.5rem 1.5rem 1rem;
`;

export const SelectOptions = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 11rem;

  overflow: auto;
`;

export const SelectOption = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  cursor: pointer;

  padding: 0.75rem 1rem;

  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.selected {
    & .radio {
      border-color: var(--success-2);

      &::after {
        background-color: var(--success-2);
      }
    }
  }

  &:hover {
    background-color: var(--grayscale-4);
  }
`;

export const SelectOptionRadio = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 1rem;
  height: 1rem;

  border-radius: 50%;

  border: 1px solid var(--grayscale-60);

  &::after {
    display: block;
    content: '';
    position: absolute;

    width: 0.5rem;
    height: 0.5rem;

    background-color: transparent;

    border-radius: 50%;
  }
`;

export const SelectOptionCheckbox = styled.div<{ checked?: boolean }>`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 1rem;
  height: 1rem;

  border: 1px solid var(--grayscale-60);

  &::after {
    display: block;
    content: '';
    position: absolute;

    width: 0.5rem;
    height: 0.5rem;

    ${(props) =>
      props.checked &&
      css`
        background-color: var(--success-2);
      `}
    border-radius: 50%;
  }
`;
