import styled from 'styled-components';

export const Table = styled.div`
  width: 100%;
  overflow: auto;
`;

export const TableHead = styled.tr`
  display: flex;
  user-select: none;
  margin-bottom: 1rem;

  & > th {
    display: inline-flex;
    flex: 1;

    text-align: left;
    line-height: 1.5rem;
    background-color: var(--grayscale-2);
    color: var(--secondary-main);

    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 0.25rem 1rem;
    gap: 1rem;
    cursor: pointer;

    & > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 1rem;
      height: 1rem;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &.asc::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='18' viewBox='0 0 14 18' fill='none'%3E%3Cpath d='M14 11L7 18L0 11H14ZM10.375 12.5H3.625L7 15.875L10.375 12.5ZM14 7H0L7 0L14 7Z' fill='%238383AF'/%3E%3C/svg%3E");
    }

    &.desc::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='18' viewBox='0 0 14 18' fill='none'%3E%3Cpath d='M14 11L7 18L0 11H14ZM14 7H0L7 0L14 7ZM10.375 5.5L7 2.125L3.625 5.5H10.375Z' fill='%238383AF'/%3E%3C/svg%3E");
    }

    &:first-of-type {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    &:last-of-type {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
`;

export const TableBody = styled.ul`
  display: flex;
  flex-direction: column;
  max-height: 520px;
`;

export const TableRow = styled.tr`
  display: flex;
  flex: 1;
  align-items: center;

  cursor: pointer;
  user-select: none;

  border-radius: 0.25rem;
  border: 1px solid transparent;
  transition: border-color 0.3s, background-color 0.3s;

  &:hover {
    background-color: var(--grayscale-2);
    border-color: var(--grayscale-40);
  }

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }

  & > td {
    display: inline-flex;
    flex: 1;

    text-align: left;

    padding: 0.75rem 1rem;

    & > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const ControllsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;
