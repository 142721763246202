import { Seniority } from '../../../../hooks/types';
import { usersApi } from '../../../api';

export async function getAllSeniorities(): Promise<Seniority[]> {
  try {
    const response = await usersApi.get(`/seniority/find-all`);
    const seniorities = response.data as Seniority[];

    return seniorities;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar esta cobrança, atualize a página e tente novamente 😕`,
      err,
    );
    return err.response.data;
  }
}
