import React from 'react';
import * as S from './styles';

export interface FileListProps {
  file: File;
}

export const FileList = React.memo(({ file }: FileListProps) => (
  <S.FileList>
    <li key={`${file}_${file.lastModified}`}>
      <span>{file.name}</span> <span>({Math.round(file.size / 1000)}kb)</span>
    </li>
  </S.FileList>
));

FileList.displayName = 'FileList';
