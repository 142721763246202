import { useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Button, Headline, Table } from '../../../LegacyV4Components';
import { UnHappyPath } from '../../atoms/UnhappyPath';
import { GeneratePlanModal } from '../../organisms/GeneratePlanModal';
import * as S from './styles';
import CardPlan from '../../organisms/CardPlan';
import { AttemptsPlanModal } from '../../organisms/AttemptsPlanModal';
import { ISubscriptionList } from '../../../../services/requests/Finance/getSubscriptionList';
import { IPlanList } from '../../../../services/requests/Finance/getPlanByCustomer';
import CardSubscription from '../../organisms/CardSubscription';
import { ShareLinkPlanModal } from '../../organisms/ShareLinkPlanModal';

interface InterfacePlans {
  customer: {
    _id: string;
    hexColor: string;
    financialContacts: any[];
  };
  subscriptions: ISubscriptionList[];
  plans?: IPlanList[];
}

export default function CustomerPlans({
  customer,
  subscriptions,
  plans,
}: InterfacePlans) {
  const [showConfigBankModal, setShowConfigBankModal] = useState(false);
  const [showAttemptsPlanModal, setShowAttemptsPlanModal] = useState(false);
  const [dataPlan, setDataPlan] = useState<any>();
  const [isOpenGenerate, setIsOpenGenerate] = useState(false);

  return (
    <>
      <S.Container>
        <GeneratePlanModal
          isShown={showConfigBankModal}
          setIsShown={setShowConfigBankModal}
          setIsOpenSharedLink={setIsOpenGenerate}
          setDataPlan={setDataPlan}
          customerId={customer._id}
        />

        <S.ContainerHead>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Headline variant="h4">Planos</Headline>
          </div>
          <Button
            onClick={() => {
              setShowConfigBankModal(true);
            }}
            variant="primary"
            color="green"
            size="default"
            rightIcon={<FiPlus />}
          >
            Criar Novo Plano
          </Button>
        </S.ContainerHead>

        {!plans?.length ? (
          <>
            <UnHappyPath assetIndex={6} />
            <Headline variant="h6">
              Você ainda não cadastrou um novo plano
            </Headline>{' '}
          </>
        ) : (
          <>
            {plans?.map((item: any) => (
              <CardPlan plan={item} />
            ))}
          </>
        )}
        {subscriptions?.length > 0 && (
          <>
            <S.ContainerHead>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Headline variant="h4">Assinaturas</Headline>
              </div>
            </S.ContainerHead>
            {subscriptions?.map((item: any) => (
              <CardSubscription subscriptions={item} />
            ))}
          </>
        )}
        {/* {Parte referente a visualização do histórico de cobrança, definido display: none pois já está pronto necessitando
        apenas de ajustes e integração} */}
        <div style={{ display: 'none' }}>
          <S.ContainerHead>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Headline variant="h4">Cobranças</Headline>
            </div>
            <S.Buttonexplanations
              onClick={() => {
                setShowAttemptsPlanModal(true);
              }}
            >
              <AiOutlineQuestionCircle />
              <p>Como funcionam as tentativas de cobrança?</p>
            </S.Buttonexplanations>
          </S.ContainerHead>
          {!subscriptions?.length ? (
            <>
              <UnHappyPath assetIndex={0} />
              <Headline variant="h6">
                Você ainda não cadastrou um novo plano
              </Headline>{' '}
            </>
          ) : (
            <>
              <Table
                columns={[
                  {
                    label: 'Nome do Plano',
                    accessor: 'unity_name',
                    isSorted: false,
                    sortable: true,
                    sortByOrder: 'asc',
                    maxWidth: 100,
                  },
                  {
                    label: 'Data de pagamento',
                    accessor: 'cluster',
                    isSorted: false,
                    sortable: true,
                    sortByOrder: 'asc',
                    maxWidth: 100,
                  },
                  {
                    label: 'Status',
                    accessor: 'status',
                    isSorted: false,
                    sortable: true,
                    sortByOrder: 'asc',
                    maxWidth: 200,
                  },
                  {
                    label: 'Valor do Fee',
                    accessor: 'mrr',
                    isSorted: false,
                    sortable: true,
                    sortByOrder: 'asc',
                    maxWidth: 100,
                  },
                ]}
                data={subscriptions || []}
              />
            </>
          )}
        </div>
      </S.Container>
      <AttemptsPlanModal
        isShown={showAttemptsPlanModal}
        setIsShown={setShowAttemptsPlanModal}
      />
      <ShareLinkPlanModal
        isShown={isOpenGenerate}
        setIsShown={setIsOpenGenerate}
        data={dataPlan}
      />
    </>
  );
}
