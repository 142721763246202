import React, { SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

import { AiOutlineInfoCircle } from 'react-icons/ai';
import * as S from './styles';
import { User } from '../../../../hooks/types';

export interface ColumnProps {
  label: string | JSX.Element;
  accessor: string;
  sortable?: boolean;
  sizing?: number;
  sizingUnit?: string;
  message?: string;
}

export interface DataProps {
  id: string;
  role?: any;
  isAdmin?: boolean;
  goToPage?: string;
  goToAccessor?: string;
  content: React.ReactNode;
  user?: User;
  routes?: string;
  dontRedirect?: boolean;
}

export interface DataGridProps {
  columns: ColumnProps[];
  sorting?: {
    column: string;
    order: 'asc' | 'desc';
  };
  withOptions?: boolean;
  data: DataProps[];
  append?: JSX.Element;
  showModalUserInvate?: boolean;
  setShowModalUserInvate: React.Dispatch<SetStateAction<boolean>>;
  setUser?: React.Dispatch<SetStateAction<User | undefined>>;
  isAdmin?: boolean;
  notClickable?: boolean;
  routes?: string;
  dontRedirect?: boolean;
}

export function DataGrid({ withOptions = false, ...props }: DataGridProps) {
  const gridSizing = [];
  const navigate = useNavigate();
  const columns = props.columns.map((col) => {
    const colClass: string[] = [];

    if (col.sortable) {
      colClass.push('sortable');
    }

    if (props?.sorting?.column === col.accessor) {
      colClass.push('sorted', props.sorting.order);
    }

    const sizingUnit = col.sizingUnit ?? 'fr';
    if (col.sizing) {
      gridSizing.push(`${col.sizing}${sizingUnit}`);
    } else {
      gridSizing.push(`1fr`);
    }

    return (
      <S.HeaderColumn className={colClass.join(' ')} key={col.accessor}>
        {col.label}{' '}
        {col.message ? (
          <S.Infomation>
            <div className="icon">
              <AiOutlineInfoCircle />
              <div className="message">
                <p>{col.message}</p>
              </div>
            </div>
          </S.Infomation>
        ) : null}
      </S.HeaderColumn>
    );
  });

  if (withOptions) {
    gridSizing.push('80px');
  }

  const gridStyle = { gridTemplateColumns: gridSizing.join(' ') };

  return (
    <S.Wrapper>
      <S.Header style={gridStyle}>{columns}</S.Header>
      <S.Body>
        <>
          {props.data.length ? (
            props.data.map((row) => (
              <S.Row
                className="row"
                key={row.id}
                style={gridStyle}
                onClick={() => {
                  if (!props.dontRedirect) {
                    if (!props.isAdmin) {
                      navigate(row.routes as string);
                    } else if (props.setUser) {
                      props.setShowModalUserInvate(!props.showModalUserInvate);
                      props.setUser(row.user as User);
                    }
                    if (props.notClickable) {
                      return;
                    }
                    if (props.routes) {
                      navigate(`/${props.routes}/${row.id}`);
                    }
                  }
                }}
                notClickable={props.notClickable}
              >
                {row.content}
              </S.Row>
            ))
          ) : (
            <p className="NoInformation">Nenhum dado para ser mostrado</p>
          )}
          {props.append ? props.append : null}
        </>
      </S.Body>
    </S.Wrapper>
  );
}
