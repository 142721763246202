import { customerApi } from '../../api';

interface CreateStakeholderDTO {
  name: string;
  role: string;
  tel: string;
  email: string;
  mktLabUser: boolean;
}

export async function createStakeholder(
  customerId: string,
  stakeholder: CreateStakeholderDTO,
): Promise<Stakeholder> {
  const response: any = await customerApi.post(
    `/customer/${customerId}/stakeholder`,
    stakeholder,
  );
  const createdStakeholder = response.data;

  return createdStakeholder;
}
