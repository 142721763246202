import React, { useRef, useEffect, useCallback } from 'react';
import * as lottie from 'lottie-web';

import { animation } from './animation';

import * as S from './styles';

interface ILoadingProps {
  containerStyles?: React.CSSProperties;
}

export function Loading({ containerStyles }: ILoadingProps) {
  const lottieAnimation = useRef<HTMLDivElement>(null);

  const handleLottieAnimatio = useCallback(() => {
    if (!lottieAnimation.current) {
      return;
    }

    lottie.default.loadAnimation({
      container: lottieAnimation.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    });
  }, []);

  useEffect(() => handleLottieAnimatio(), [handleLottieAnimatio]);

  return (
    <S.Container style={containerStyles || {}}>
      <div ref={lottieAnimation} />
    </S.Container>
  );
}
