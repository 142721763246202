import { financeApi } from '../../api';

export async function disableProduct(
  productId: string,
  disabled: boolean,
): Promise<any> {
  try {
    const response = await financeApi.put<any>(
      `/checkouts/update/${productId}`,
      { disabled },
    );

    return response.data;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o produto, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
