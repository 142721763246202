import { serverlessGateway } from '../../api';

export async function createJwtToken(
  type: string,
  email?: string,
): Promise<string | false> {
  try {
    const response: { data: { token: string } } = await serverlessGateway.post(
      'users/eletromidia/create-token',
      {
        type,
        email,
      },
    );

    return response.data.token;
  } catch (error) {
    return false;
  }
}
