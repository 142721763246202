import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { financeApi } from '../../api';

export interface IProductById {
  _id: string;
  recipientId: string;
  name: string;
  value: number;
  ammount: number;
  description: string;
  commissionPercentage: number;
  payableWith: string[];
  paymentMethods: string[];
  feeResponsible: string;
  creditCardInstallments: number;
  physicalProduct: boolean;
  disabled: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  paymentUrl: string;
}

export function useGetProductById(
  productId: string | undefined,
): UseQueryResult<{ data: IProductById }, { data: IProductById }> {
  return useQuery<{ data: IProductById }, { data: IProductById }>(
    ['financeProduct', productId],
    () => financeApi.get(`/products/${productId}`),
    {},
  );
}
