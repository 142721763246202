import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const formatStringToBRLDate = (stringDate: string) => {
  const formatedDate = format(new Date(stringDate), 'dd/MM/yyyy', {
    locale: ptBR,
  }).toString();
  return formatedDate;
};

const addHours = (date: Date, hours: number) => {
  date.setHours(date.getHours() + hours);

  return date;
};

export { formatStringToBRLDate, addHours };
