import styled, { css } from 'styled-components';

export const Container = styled.div<{ isOpen: boolean }>`
  position: relative;

  span {
    background: #ffffff;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);

    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;
    z-index: 10;

    position: absolute;
    top: calc(100% + 1rem);
    left: 50%;
    transform: translateX(-50%);

    color: var(--white);

    ${({ isOpen }) =>
      isOpen &&
      css`
        opacity: 1;
        visibility: visible;
      `}
  }

  section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.5rem;
    border-bottom: 2px solid rgba(18, 18, 18, 0.1);

    &:hover {
      opacity: 0.5;
    }
  }

  > svg {
    z-index: 0;
  }

  svg {
    cursor: pointer;
  }
`;
