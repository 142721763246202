import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { financeApi } from '../../api';

export interface ICheckoutById {
  _id: string;
  recipientId: string;
  name: string;
  value: number;
  ammount: number;
  description: string;
  commissionPercentage: number;
  products: Product[];
  payableWith: string[];
  paymentMethods: string[];
  feeResponsible: string;
  creditCardInstallments: number;
  physicalProduct: boolean;
  disabled: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  paymentUrl: string;
}

export interface Product {
  id: string;
  name: string;
  description: string;
  physicalProduct: boolean;
  productType: string;
  value: number;
}

export function useGetCheckoutById(
  productId: string | undefined,
): UseQueryResult<{ data: ICheckoutById }, { data: ICheckoutById }> {
  return useQuery<{ data: ICheckoutById }, { data: ICheckoutById }>(
    ['financeCheckout', productId],
    () => financeApi.get(`/checkouts/${productId}`),
    {},
  );
}
