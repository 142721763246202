import { IData } from 'v4web-components';

export const MatrixCustomersColumns: IData['headers'] = [
  {
    colKey: 'name',
    title: 'Nome do cliente',
    type: 'text',
  },
  {
    colKey: 'unit',
    title: 'Unidade',
    type: 'text',
  },
];

export const CustomersColumns: IData['headers'] = [
  {
    colKey: 'name',
    title: 'Nome do cliente',
    type: 'text',
  },
  {
    colKey: 'unit',
    title: 'Unidade',
    type: 'text',
  },
];
