import { useState } from 'react';
import { FiLink2 } from 'react-icons/fi';
import { DotMenu, Headline, Tooltip } from '../../../LegacyV4Components';
import { useToast } from '../../../../hooks/toast';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';
import { ActivePlanModal } from '../ActivePlanModal';
import { CancelPlanModal } from '../CancelPlanModal';
import { DetailsPlanModal } from '../DetailsPlanModal';
import { EditPlanModal } from '../EditPlanModal';
import { HistoricPlanModal } from '../HistoricPlanModal';
import * as S from './styles';

export default function CardPlan({ plan }: any) {
  const [showEditPlanModal, setShowEditPlanModal] = useState(false);
  const [showHistoricPlanModal, setShowHistoricPlanModal] = useState(false);
  const [showDetailsPlanModal, setShowDetailsPlanModal] = useState(false);
  const [showCancelPlanModal, setShowCancelPlanModal] = useState(false);
  const [showActivePlanModal, setShowActivePlanModal] = useState(false);

  const { addToast } = useToast();

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);

    addToast({
      type: 'success',
      title: 'Copiado para a área de transferência!',
    });
  }
  const planActive = [
    {
      name: 'Ver detalhes',
      action: () => setShowDetailsPlanModal(true),
    },
    {
      name: 'Editar',
      action: () => setShowEditPlanModal(true),
    },
    {
      name: 'Histórico de edição',
      action: () => setShowHistoricPlanModal(true),
    },
    {
      name: 'Cancelar',
      action: () => setShowCancelPlanModal(true),
    },
  ];
  const planInactive = [
    {
      name: 'Histórico de edição',
      action: () => setShowHistoricPlanModal(true),
    },
    {
      name: 'Ativar',
      action: () => setShowActivePlanModal(true),
    },
  ];

  return (
    <div key={plan._id}>
      <S.Container>
        <S.ContainerHead>
          <S.ContainerTitle color="1">
            <Headline variant="h6" className="title">
              {plan.name}
              {plan.identifier ? (
                <S.ButtonCopyLink
                  onClick={() => {
                    copyToClipboard(
                      `${process.env.REACT_APP_URL_NAME}/assinatura/${plan?.identifier}`,
                    );
                  }}
                >
                  <div className="tooltipBox">
                    <Tooltip
                      title="Copiar link de compartilhamento"
                      position="left"
                    >
                      <FiLink2 />
                    </Tooltip>
                  </div>
                </S.ButtonCopyLink>
              ) : null}
            </Headline>
            <p>
              {convertNumberToBRL(Number(plan.valueCents) / 100)} -{' '}
              {plan.intervalType === 'months' ? 'mensal' : 'semanal'} - Cartão
            </p>
          </S.ContainerTitle>
          <S.DotMenu>
            {/* Comentado pois ainda não estão disponíveis as funções */}
            <div style={{ display: 'none' }}>
              <DotMenu
                reversed
                options={!plan.active ? planActive : planInactive}
              />
            </div>
          </S.DotMenu>
        </S.ContainerHead>
      </S.Container>
      <DetailsPlanModal
        isShown={showDetailsPlanModal}
        setIsShown={setShowDetailsPlanModal}
        data={plan}
      />
      <EditPlanModal
        isShown={showEditPlanModal}
        setIsShown={setShowEditPlanModal}
        data={plan}
      />
      <HistoricPlanModal
        isShown={showHistoricPlanModal}
        setIsShown={setShowHistoricPlanModal}
        data={plan}
      />
      <CancelPlanModal
        isShown={showCancelPlanModal}
        setIsShown={setShowCancelPlanModal}
        data={plan}
      />
      <ActivePlanModal
        isShown={showActivePlanModal}
        setIsShown={setShowActivePlanModal}
        data={plan}
      />
    </div>
  );
}
