import React from 'react';

import { useNavigate } from 'react-router-dom';
import { ColumnProps, Data, TableProps } from './Table.types';

import * as S from './styles';

function TableHead({ columns }: { columns: ColumnProps[] }) {
  return (
    <S.TableHead>
      {columns.map(({ label, accessor, isSorted, sortByOrder, maxWidth }) => {
        const cl = isSorted ? sortByOrder : '';

        return (
          <th
            style={{ maxWidth: maxWidth ? `${maxWidth}%` : '10%' }}
            key={accessor}
            className={cl}
            title={label}
          >
            <p>{label}</p>
          </th>
        );
      })}
    </S.TableHead>
  );
}

function TableBody({
  tableData,
  columns,
}: {
  tableData: any;
  columns: ColumnProps[];
}) {
  const navigation = useNavigate();
  const goTo = (to: string) => {
    navigation(to);
  };
  const handlerClick = (e: any) => {
    e.stopPropagation();
  };

  const handlerTitle = (accesso: string, data: string | any) => {
    if (accesso === 'action') return 'Opções';
    if (accesso === 'avatar') return 'Usuário';
    return data;
  };
  return (
    <S.TableBody>
      {tableData.map((data: Data) => (
        <S.TableRow
          key={data.id}
          onClick={() => {
            if (data.goToPage) {
              goTo(`${data.goToPage}/${data[data.goToAccessor]}`);
            }
          }}
        >
          {columns.map(({ accessor, maxWidth }) => {
            const tData = data[accessor] ? data[accessor] : '——';
            return (
              <td
                onClick={(e) => {
                  if (accessor === 'action') handlerClick(e);
                }}
                aria-hidden="true"
                key={accessor}
                title={handlerTitle(accessor, tData)}
                style={{ maxWidth: maxWidth ? `${maxWidth}%` : '10%' }}
              >
                {tData}
              </td>
            );
          })}
        </S.TableRow>
      ))}
    </S.TableBody>
  );
}

export function Table({ columns, data, sortingHandler }: TableProps) {
  return (
    <S.Table>
      <TableHead {...{ columns, sortingHandler }} />
      <TableBody {...{ columns, tableData: data }} />
    </S.Table>
  );
}
