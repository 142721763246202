import { financeApi } from '../../api';
import { FormikPaymentOptions } from '../../../pages/PaymentOptions';

export interface dataToCreatePlansProps {
  planId: string | undefined;
  name: string;
  email: string;
  cpfCnpj: string | undefined;
  telephone: string | undefined;
  paymentCardToken: {
    first_name: string;
    last_name: string;
    number: string;
    month: string;
    year: string;
    verification_value: string;
  };
  address: {
    district: string;
    number: null;
    street: string;
    zipCode: string | undefined;
    complement: string;
  };
}

export interface IResponsePlans {
  id: string;
  suspended: boolean;
  plan_identifier: string;
  price_cents: number;
  currency: string;
  customer_name: string;
  customer_email: string;
  cycled_at: string;
  credits_min: number;
  credits_cycle: any;
  payable_with: string;
  ignore_due_email: any;
  max_cycles: number;
  cycles_count: number;
  recent_invoices: RecentInvoice[];
  subitems: Subitem[];
  logs: Log[];
  custom_variables: CustomVariable[];
  expires_at: string;
  created_at: string;
  updated_at: string;
  customer_id: string;
  plan_name: string;
  customer_ref: string;
  plan_ref: string;
  active: boolean;
  two_step: boolean;
  suspend_on_invoice_expired: boolean;
  in_trial: any;
  credits: number;
  credits_based: boolean;
}

export interface RecentInvoice {
  id: string;
  due_date: string;
  status: string;
  total: string;
  secure_url: string;
}

export interface Subitem {
  id: string;
  description: string;
  quantity: number;
  price_cents: number;
  recurrent: boolean;
  price: string;
  total: string;
}

export interface Log {
  id: string;
  description: string;
  notes: string;
  subscription_changes: string;
  created_at: string;
}

export interface CustomVariable {
  name: string;
  value: string;
}

export interface createPaymentProps {
  _id: string;
  recipientId: string;
  name: string;
  email: string;
  telephone: string;
  paymentMethod: string;
  paymentProviderId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  pix: Pix;
  bank_slip: BankSlip;
  secure_url: string;
  due_date: string;
  bankSlip: BankSlip;
}

export interface Pix {
  qrcode: string;
  qrcode_text: string;
  status: string;
  payer_cpf_cnpj: any;
  payer_name: any;
  end_to_end_id: any;
  end_to_end_refund_id: any;
}

export interface BankSlip {
  digitable_line: string;
  barcode_data: string;
  barcode: string;
  bank_slip_bank: number;
  bank_slip_status: string;
  bank_slip_error_code: any;
  bank_slip_error_message: any;
  downloadLink?: string;
}

export async function createPayment(
  data: FormikPaymentOptions,
): Promise<createPaymentProps | any> {
  try {
    const response = await financeApi.post<createPaymentProps>(
      `/payments/create`,
      data,
    );
    const payment = response.data;

    return payment;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o produto, atualize a página e tente novamente 😕`,
      err,
    );
    return err.response.data;
  }
}

export async function createPaymentHQ(
  data: FormikPaymentOptions,
): Promise<createPaymentProps | any> {
  try {
    const response = await financeApi.post<createPaymentProps>(
      `/payments/create/hq`,
      data,
    );
    const payment = response.data;

    return payment;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o produto, atualize a página e tente novamente 😕`,
      err,
    );
    return err.response.data;
  }
}

export async function createPaymentKommoStack(
  data: FormikPaymentOptions,
): Promise<createPaymentProps | any> {
  try {
    const response = await financeApi.post<createPaymentProps>(
      `/payments/create/stack`,
      data,
    );
    const payment = response.data;

    return payment;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o produto, atualize a página e tente novamente 😕`,
      err,
    );
    return err.response.data;
  }
}

export async function createPaymentPlans(
  data: dataToCreatePlansProps,
): Promise<IResponsePlans> {
  try {
    const response = await financeApi.post<IResponsePlans>(
      `/subscriptions/create/stack`,
      data,
    );
    const payment = response.data;

    return payment;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o produto, atualize a página e tente novamente 😕`,
      err,
    );
    return err.response.data;
  }
}
