import { usersApi as api } from '../../api';

export async function verifyExistsEmail(email: string): Promise<boolean> {
  try {
    const response: any = (await api.get(`/workspace/exist/${email}`)).data;

    return response.exist;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao verifica se o email existe 😕`,
      err,
    );
    return false;
  }
}
