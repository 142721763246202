import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Input, Modal } from '../../../LegacyV4Components';

import { SelectSearch } from '../../atoms/SelectSearch';
import * as S from './styles';
import { getUnits } from '../../../../services/requests/Unit/getUnits';
import { Unit } from '../../../../hooks/types';
import { updateCustomer } from '../../../../services/requests/Customers/updateCustomer';
import { useToast } from '../../../../hooks/toast';

export function EditCustomerModal({
  customer,
  isOpen,
  setIsOpen,
}: {
  customer: any;
  isOpen: boolean;
  setIsOpen: () => void;
}) {
  const { addToast } = useToast();
  const [units, setUnits] = useState<Unit[]>([]);

  const [customerInfo, setCustomerInfo] = useState({
    unitId: '',
    country: '',
    state: '',
    city: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    zipCode: '',
  });

  useEffect(() => {
    if (customer?._id) {
      setCustomerInfo({
        unitId: customer?.units?.length ? customer?.units[0]?._id : '',
        country: customer.address.country,
        state: customer.address.state,
        city: customer.address.city,
        street: customer.address.street,
        number: customer.address.number,
        complement: customer.address.complement,
        zipCode: customer.address.zipCode,
        district: customer.address.district,
      });
    }
  }, [customer]);

  useEffect(() => {
    async function getUnit() {
      const response = (await getUnits(0, '', true)) as any;
      if (response) {
        setUnits(response.data);
      }
    }

    getUnit();
  }, []);

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;

    setCustomerInfo({
      ...customerInfo,
      [name]: value,
    });
  }

  function handleClose() {
    addToast({
      title: 'Cliente atualizado com sucesso',
      type: 'success',
      description: '',
    });
    setIsOpen();
  }

  async function handleUpdateCustomer() {
    const response = await updateCustomer(
      customer?._id,
      customerInfo,
      customerInfo.unitId,
    );

    if (response && response._id) {
      handleClose();
    }
  }

  return (
    <Modal
      show={isOpen}
      title="Editar cliente"
      closeButton
      onBackground={setIsOpen}
    >
      <S.Container>
        <p>Unidade</p>
        <SelectSearch
          value={customerInfo.unitId}
          setValue={(value) =>
            setCustomerInfo({
              ...customerInfo,
              unitId: value,
            })
          }
          placeholder="Selecione a unidade"
          options={units?.map((unit) => ({
            label: unit.name,
            value: unit._id,
          }))}
          notSearch={false}
        />

        <S.InputList>
          <Input
            type="text"
            name="country"
            label="País"
            onChange={(e) => handleInputChange(e)}
            value={customerInfo.country}
          />
          <Input
            type="text"
            name="state"
            label="Estado"
            onChange={(e) => handleInputChange(e)}
            value={customerInfo.state}
          />
          <Input
            type="text"
            name="district"
            label="Bairro"
            onChange={(e) => handleInputChange(e)}
            value={customerInfo.district}
          />

          <Input
            type="text"
            name="city"
            label="Cidade"
            onChange={(e) => handleInputChange(e)}
            value={customerInfo.city}
          />
          <Input
            type="text"
            name="street"
            label="Rua"
            onChange={(e) => handleInputChange(e)}
            value={customerInfo.street}
          />
          <Input
            type="text"
            name="number"
            label="Número"
            onChange={(e) => handleInputChange(e)}
            value={customerInfo.number}
          />
          <Input
            type="text"
            name="complement"
            label="Complemento"
            onChange={(e) => handleInputChange(e)}
            value={customerInfo.complement}
          />

          <Input
            type="text"
            name="zipCode"
            label="CEP"
            onChange={(e) => handleInputChange(e)}
            value={customerInfo.zipCode}
          />
          <Button
            color="green"
            onClick={() => handleUpdateCustomer()}
            size="default"
            variant="primary"
          >
            Salvar
          </Button>
        </S.InputList>
      </S.Container>
    </Modal>
  );
}
