import styled from 'styled-components';

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  .close-button {
    position: absolute;
    right: 0;
    top: 0;

    color: var(--tertiary-dark);

    cursor: pointer;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;

  button {
    :first-of-type {
      margin-right: 1rem;
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0.5rem;
`;
