import { useCallback, useMemo } from 'react';
import { IData } from 'v4web-components';
import { InvestorTeam } from '../../../hooks/types';

export function useDataTableHistoricList(
  data: InvestorTeam[],
  handleSelectChange: (
    userId: string,
    points: number,
    clientId: string,
  ) => void,
  createProductivityOptions: (
    userProductivityNumber: number,
    maxWorkload: number,
    userInvestorProductivity: number,
    isOnboarding: boolean,
  ) => {
    label: string;
    key: string;
    isChecked: boolean;
  }[],
  setModalRemoveInvestor: React.Dispatch<React.SetStateAction<boolean>>,
  setRemoveInvestor: React.Dispatch<React.SetStateAction<string>>,
) {
  const handlerRemoveUserFromProject = useCallback(
    (idUser: string) => {
      setRemoveInvestor(idUser);
      setModalRemoveInvestor(true);
      return idUser;
    },
    [setModalRemoveInvestor, setRemoveInvestor],
  );

  const rows: IData['rows'] = useMemo(() => {
    const dataRows: IData['rows'] = [];
    data?.map((item: InvestorTeam) => {
      const newValue = createProductivityOptions(
        item?.user.capacity?.productivity || 1,
        item?.user.capacity?.maxWorkload || 1,
        item?.productivityInvested || 1,
        item?.user.capacity?.isOnboarding,
      );

      const [itemSelected] = newValue.filter(
        (value) => value.isChecked === true,
      );
      const label = itemSelected ? itemSelected.label : 'Default Label';
      const isOnboarding = item?.user.capacity?.isOnboarding;
      const maxWorkload = isOnboarding
        ? Math.floor(item?.user.capacity?.maxWorkload / 2)
        : item?.user.capacity?.maxWorkload;

      return dataRows.push({
        mandate: {
          text: {
            title: item?.user.mandate?.name,
          },
        },
        seniority: {
          text: {
            title: item?.user.seniority?.name,
          },
        },
        capacity: {
          badge: {
            label: `${
              item?.user.capacity?.currentWorkload
            }/${maxWorkload.toString()}`,
            state:
              item?.user.capacity?.currentWorkload >
              item?.user.capacity?.maxWorkload
                ? 'error'
                : 'success',
            variant: 'ghost',
            size: 'medium',
          },
        },
        name: {
          text: {
            title: item?.user.name,
            avatar: true,
            avatarImgSRC: item?.user.picture,
          },
        },
        productivity: {
          select: {
            label,
            type: 'radioButton',
            isSearchable: false,
            avatar: false,
            options: createProductivityOptions(
              item?.user.capacity?.productivity || 1,
              item?.user.capacity?.maxWorkload || 1,
              item?.productivityInvested || 1,
              item?.user.capacity?.isOnboarding,
            ),
            onChangeInputOptions({ detail }) {
              handleSelectChange(
                item?.user.capacity._id,
                Number(detail[0].key),
                item?.customerId,
              );
            },
            value: `${item?.productivityInvested}`,
          },
        },
        productivityType: {
          text: {
            title: item?.user.capacity?.productivityType || 'Produção',
          },
        },
        action: {
          action: {
            items: [
              {
                key: 'removeFromProject',
                icon: 'menu',
                label: 'Remover do projeto',
                event: () => {
                  handlerRemoveUserFromProject(item?.user._id as string);
                },
              },
            ],
          },
        },
      });
    });

    return dataRows;
  }, [
    createProductivityOptions,
    data,
    handleSelectChange,
    handlerRemoveUserFromProject,
  ]);
  const dataTable: IData = {
    rows,
    headers: [
      {
        title: 'Investidor',
        colKey: 'name',
        type: 'text',

        sortable: false,
      },
      {
        colKey: 'mandate',
        title: 'Cargo',
        type: 'text',
        sortable: false,
      },
      {
        colKey: 'seniority',
        title: 'Senioridade',
        type: 'text',

        sortable: false,
      },
      {
        colKey: 'capacity',
        title: 'Capacidade',
        type: 'badge',
        sortable: false,
      },
      {
        colKey: 'productivity',
        title: 'Produtividade',
        type: 'select',
        sortable: false,
      },
      {
        colKey: 'productivityType',
        title: 'Tipo de Produtividade',
        type: 'text',
        sortable: false,
      },
    ],
  };
  return dataTable;
}
