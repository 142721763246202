import { IData } from 'v4web-components';

export const MatrixCustomersColumns: IData['headers'] = [
  {
    colKey: 'name',
    title: 'Nome do cliente',
    type: 'text',
  },
  {
    colKey: 'unit',
    title: 'Unidade que atende',
    type: 'text',
  },
  {
    colKey: 'nps',
    title: 'NPS',
    type: 'text',
  },
  {
    colKey: 'csat',
    title: 'CSAT',
    type: 'text',
  },
  {
    colKey: 'roi',
    title: 'ROI',
    type: 'text',
  },
  {
    colKey: 'lifetime',
    title: 'Lifetime',
    type: 'text',
  },
];

export const CustomersColumns: IData['headers'] = [
  {
    colKey: 'name',
    title: 'Nome do cliente',
    type: 'text',
  },
  {
    colKey: 'nps',
    title: 'NPS',
    type: 'text',
  },
  {
    colKey: 'csat',
    title: 'CSAT',
    type: 'text',
  },
  {
    colKey: 'roi',
    title: 'ROI',
    type: 'text',
  },
  {
    colKey: 'lifetime',
    title: 'Lifetime',
    type: 'text',
  },
];
