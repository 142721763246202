import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export function KommoIntegration() {
  const [params] = useSearchParams();

  useEffect(() => {
    if (window.opener) {
      const code = params.get('code');
      const referer = params.get('referer');
      const subdomain = referer?.split('.')[0];
      const clientId = params.get('client_id');
      const state = params.get('state');

      window.opener.postMessage(
        {
          error: undefined,
          status: 'ok',
          code,
          referer,
          subdomain,
          clientId,
          state,
        },
        '*',
      );
    }
  }, [params]);

  return <div>Você já pode fechar essa guia</div>;
}
