import { stackApi } from '../../api';

export interface InterfaceEkyteUser {
  page: number;
  totalPages: number;
  data: Data[];
}

export interface Data {
  _id: string;
  userId: string;
  accessLevel?: number;
  active: number;
  __v?: number;
}

export async function getEkyteUser(
  userId: string,
): Promise<InterfaceEkyteUser | undefined> {
  try {
    const response = await stackApi.get<InterfaceEkyteUser>(
      `/ekyte/unit/${userId}/users`,
    );

    return response.data;
  } catch (err) {
    return undefined;
  }
}
