import { MdOutlinePayment } from 'react-icons/md';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import { useState } from 'react';
import { FaRegCheckCircle, FaRegCreditCard, FaSync } from 'react-icons/fa';
import {
  FiArrowUp,
  FiCheck,
  FiFileText,
  FiShoppingCart,
  FiUser,
} from 'react-icons/fi';
import { Button, Headline } from '../../../LegacyV4Components';
import * as S from './styles';
import { IProductById } from '../../../../services/requests/Finance/getProductById';
import { IResponsePlans } from '../../../../services/requests/Finance/createPayment';
import { getPaymentById } from '../../../../services/requests/Finance/getVerifyPayment';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';
import { FormikPlansOptions } from '../../../../pages/PlanOptions';

interface FinishPaymentProps {
  responsePayment: IResponsePlans;
  values: FormikPlansOptions;
  data: IProductById | any;
}

export function FinishPlanPayment({
  responsePayment,
  values,
  data,
}: FinishPaymentProps) {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [paymentById, setPaymentById] = useState({} as any);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <S.Container>
      {!paymentById ? (
        <S.ApprovedPayment>
          <FaRegCheckCircle color="#00C49A" />
          <p>Pedido aceito, aguardando pagamento</p>
        </S.ApprovedPayment>
      ) : (
        <>
          {paymentById.status === 'paid' && (
            <S.ConfirmedPayment>
              <FaRegCheckCircle color="#00C49A" />
              <p>Pagamento confirmado com sucesso</p>
              <small>
                Você receberá um email confirmando o pagamento e as informações
                pertinentes
              </small>
            </S.ConfirmedPayment>
          )}
          {paymentById.status === 'pending' && (
            <S.ConfirmedPayment>
              <FaRegCheckCircle color="#EEAD2D" />
              <p>Pagamento pendente</p>
              <small>
                Estamos aguardando o seu pagamento assim que confirmado você
                receberá um email.
              </small>
            </S.ConfirmedPayment>
          )}
        </>
      )}
      <S.ConfirmationPayment style={{ marginBottom: '40px' }}>
        {responsePayment?.plan_identifier && (
          <>
            <S.ContainerCreditCard>
              <button
                className="verifyPayment"
                type="button"
                onClick={async () => {
                  const pay = await getPaymentById(responsePayment?.id);
                  pay !== false && setPaymentById(pay);
                }}
              >
                <span>Atualizar página</span> <FaSync />
              </button>
              <FaRegCreditCard width="54px" height="42px" />
              <small>
                Você acaba de assinar a assinatura de
                {responsePayment?.subitems[0].description} no valor de{' '}
                {convertNumberToBRL(
                  responsePayment?.subitems[0].price_cents / 100,
                )}
              </small>
              <Button
                variant="secondary"
                rightIcon={<FiCheck />}
                color="green"
                size="default"
                onClick={() =>
                  window.open(responsePayment?.recent_invoices[0].secure_url)
                }
              >
                Verifique sua fatura
              </Button>
            </S.ContainerCreditCard>
          </>
        )}
      </S.ConfirmationPayment>

      <div>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          sx={{ border: '1px solid #00c49a' }}
        >
          <AccordionSummary
            expandIcon={<FiArrowUp />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FiFileText style={{ marginRight: '28px' }} />
              <Headline variant="h5">Ver resumo</Headline>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <S.ContainerInfo>
              <Headline variant="h5">
                <FiShoppingCart />
                Sua fatura
              </Headline>
              <S.Details>
                <p>
                  {(data && convertNumberToBRL(data?.valueCents / 100)) || ''}
                </p>
                <small>{data?.description}</small>
              </S.Details>
            </S.ContainerInfo>

            <S.ContainerInfo>
              <Headline variant="h5">
                <FiUser />
                Sobre você
              </Headline>
              <S.Details>
                <p>Nome completo</p>
                <small>{values?.name}</small>
              </S.Details>
              <S.Details>
                <p>Email</p>
                <small>{values?.email || ''}</small>
              </S.Details>
              <S.Details>
                <p>Telefone</p>
                <small>{values?.telephone || values?.phone}</small>
              </S.Details>
            </S.ContainerInfo>

            <S.ContainerInfo>
              <Headline variant="h5">
                <MdOutlinePayment />
                Pagamento
              </Headline>
              <S.Details>
                <p>Recorrência no cartão de crédito</p>
              </S.Details>
            </S.ContainerInfo>
          </AccordionDetails>
        </Accordion>
      </div>
    </S.Container>
  );
}
