import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Container,
  Section,
  SectionContent,
} from '../../components/LegacyV4Components';
import { ProfileForm } from '../../components/AtomicDesign/organisms/ProfileForm';
import { CustomLoading } from '../../components/Default/Loadings/CustomLoading';
import { User } from '../../hooks/types';
import { getUser } from '../../services/requests/User/getUser';

export function ProfileEdit() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const [profileUser, setProfileUser] = useState<User>();

  const setUserInfo = useCallback(async () => {
    setLoading(true);

    if (id) {
      const newUser = await getUser(id);

      if (newUser) {
        setProfileUser(newUser);
      }
    }

    setLoading(false);
  }, [id]);

  useEffect(() => {
    setUserInfo();
  }, [setUserInfo]);

  const routes = [
    {
      title: 'Unidade Investidor',
      link: `/unit/read/${profileUser?.unitId}`,
    },
    {
      title: profileUser?.name,
      link: '/',
    },
  ];

  return (
    <Container>
      {loading ? (
        <CustomLoading />
      ) : (
        <>
          <Breadcrumb
            breadcrumbs={[
              {
                title: 'Meu Perfil',
                link: `/perfil/${profileUser?._id}?origin=${encodeURI(
                  JSON.stringify(routes),
                )}`,
              },
              {
                title: 'Editar Perfil',
                link: `/perfil/edit/${profileUser?._id}`,
              },
            ]}
          />
          <Section
            style={{
              justifyContent: 'center',
              background: 'transparent',
              boxShadow: 'none',
            }}
          >
            <SectionContent style={{ width: 'auto', display: 'flex' }}>
              {profileUser && <ProfileForm profileUser={profileUser} />}
            </SectionContent>
          </Section>
        </>
      )}
    </Container>
  );
}
