import styled from 'styled-components';

export const ModalOverlay = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  z-index: 30;
  background-color: rgb(0 0 0 / 25%);

  &.show {
    display: flex;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  bottom: 0.5rem;

  max-height: 30vh;
  width: 97vw;

  border-radius: 8px;
  background-color: var(--white);
`;

export const TextMobile = styled.div`
  display: none;
  @media (max-width: 850px) {
    display: flex;
  }
`;
export const TextWeb = styled.div`
  display: flex;
  @media (max-width: 850px) {
    display: none;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
  margin-top: 0.5rem;

  button {
    margin-bottom: 1.3rem;
    // margin-inline: 0;
    @media (max-width: 850px) {
      margin: 0.5rem !important;
      width: 80vw;
    }
  }
  @media (max-width: 850px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    width: 85vw;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  & h5 {
    color: var(--error);
    font-family: 'Inter';
    font-style: normal;
    font-size: 1.2rem;
    font-weight: 700;
    @media (max-width: 850px) {
      color: #333333;
      font-size: 1rem !important;
      font-weight: 700;
      line-height: 24px !important;
    }
  }

  & p {
    color: #333333;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 32px;
    width: 60vw;
    @media (max-width: 850px) {
      font-weight: 400 !important;
      font-size: 0.75rem !important;
      line-height: 16px !important;
    }
  }

  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;

    & p {
      color: #333333;
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 32px;
      width: 90vw;
    }
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 10px 10px 0px 10px;

  & .close-button {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    cursor: pointer;

    color: var(--grayscale-60);
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;

  padding: 0.7rem 1.5rem;
  padding-bottom: 0;

  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  padding: 1.75rem 2.5rem;
`;
