import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { financeApi } from '../../api';
import { CustomerFinance } from './getCustomer';

export function useQueryGetCustomertHaveAWSKey(
  id: string,
): UseQueryResult<{ data: CustomerFinance }> {
  return useQuery<{ data: CustomerFinance }>(
    ['CustomerHaveAWSKeys', id],
    () => financeApi.get(`checkouts/verificationPreCheckout/customer/${id}`),
    {},
  );
}
