import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 1.5rem 2rem;
  width: 100%;
  background-color: var(--white);
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
  h6 {
    margin: 0 auto;
  }
  svg {
    margin: 0 auto;
    margin-left: 0.5rem;
    width: 1.2rem;
    height: 1.2rem;
  }
  .active {
    display: flex;
    padding: 0.25rem 1rem 0.25rem 1rem;
    align-items: center;
    border-radius: 2.375rem;
    width: fit-content;
    border: 2px solid #00ab86;
  }
  .disabled {
    display: flex;
    padding: 0.25rem 1rem 0.25rem 1rem;
    align-items: center;
    border-radius: 2.375rem;
    width: fit-content;
    border: 2px solid #bbbbbb;
  }
  .paid {
    display: flex;
    padding: 0.25rem 1rem 0.25rem 1rem;
    align-items: center;
    border-radius: 2.375rem;
    width: fit-content;
    border: 2px solid #00ab86;
    p {
      font-size: 0.8rem;
      font-weight: 700;
      font-family: 'Nunito Sans', sans-serif;
    }
  }
  .pending {
    display: flex;
    justify-content: center;
    padding: 0.25rem 1rem;
    border-radius: 2.625rem;
    width: 6.875rem;
    border: 2px solid var(--warning);
    p {
      font-size: 0.8rem;
      font-weight: 700;
      font-family: 'Nunito Sans', sans-serif;
    }
  }
  .expired {
    display: flex;
    padding: 0.25rem 1rem;
    align-items: center;
    border-radius: 2.375rem;
    width: fit-content;
    border: 2px solid var(--error);
    p {
      font-size: 0.8rem;
      font-weight: 700;
      font-family: 'Nunito Sans', sans-serif;
    }
  }
  .canceled {
    display: flex;
    justify-content: center;
    padding: 0.25rem 1rem;
    border-radius: 2.625rem;
    width: 6.875rem;
    border: 2px solid var(--grayscale-80);
    p {
      font-size: 0.8rem;
      font-weight: 700;
      font-family: 'Nunito Sans', sans-serif;
    }
  }
`;

export const ContainerHead = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
  }
`;
