import { useEffect, useState } from 'react';
import {
  LabDsButton,
  LabDsSelect,
  LabDsTextField,
} from 'v4web-components-react';
import { Modal } from '../../../../components/LegacyV4Components';
import { getFacebookAdsIntegrations } from '../../../../services/requests/Ads/getFacebookAdsIntegrations';

import * as S from './styles';
import { createAudience } from '../../../../services/requests/Ads/createAudience';
import { useToast } from '../../../../hooks/toast';

interface IInputItens {
  label: string;

  isChecked: boolean;
  key: string;
}
interface IAudience {
  name: string;
  description: string;
}

export function CreateAudienceModal({
  showModal,
  closeModal,
  cohort,
}: {
  showModal: boolean;
  closeModal: () => void;
  cohort: number;
}) {
  const [audience, setAudience] = useState<IAudience>({
    name: '',
    description: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState<IInputItens[]>([]);

  const [termsUrl, setTermsUrl] = useState('');

  const [selectedAccount, setSelectedAccount] = useState('');

  const { addToast } = useToast();

  function cleanInfo() {
    setAudience({
      name: '',
      description: '',
    });
    setSelectedAccount('');
    setTermsUrl('');
    setIsLoading(false);
  }

  function handleModalClose() {
    cleanInfo();
    setIsLoading(false);
    closeModal();
  }

  async function handleAddAudience() {
    const data = {
      name: audience.name,
      description: audience.description,
      integrationId: selectedAccount,
      cohort_id: cohort,
    };

    try {
      setIsLoading(true);
      const response = await createAudience(data);

      if (response.url) {
        setTermsUrl(response.url);
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      handleModalClose();
      addToast({
        type: 'success',
        title: 'Público criado com sucesso',
      });
      handleModalClose();
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Erro ao criar público',
        description: 'Tente novamente',
      });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function loadAccounts() {
      const response = await getFacebookAdsIntegrations();

      if (response) {
        const auxAccounts = [];

        const { registeredAccounts } = response;

        auxAccounts.push(
          ...registeredAccounts?.map((account) => {
            return {
              label: account.name,
              isChecked: false,
              key: account.integrationId,
            };
          }),
        );

        setAccounts(auxAccounts);
      }
    }

    loadAccounts();
  }, []);

  return (
    <Modal
      show={showModal}
      closeButton
      title="Adicionar público"
      onBackground={() => handleModalClose()}
    >
      <S.Container>
        <LabDsSelect
          titleInput="Selecionar conta do Facebook Ads"
          options={accounts}
          onChangeInputOptions={(e) => setSelectedAccount(e.detail[0].key)}
          defaultValue={selectedAccount}
          placeholder="Selecione a conta do Facebook Ads"
          isSearchable={false}
          avatar={false}
          type="radioButton"
        />
        <LabDsTextField
          titleInput="Nome do público"
          value={audience.name}
          onChangeInput={(e) => {
            setAudience({ ...audience, name: e.detail });
          }}
        />

        <LabDsTextField
          titleInput="Descrição do público"
          value={audience.description}
          onChangeInput={(e) => {
            setAudience({ ...audience, description: e.detail });
          }}
        />

        {termsUrl && (
          <S.TermsContainer>
            <p>
              Para criar o público será necessário aceitar os termos para essa
              conta de anúncio.
              <br /> Você pode aceitá-los
              <a href={termsUrl} target="_blank" rel="noreferrer">
                {' '}
                clicando aqui
              </a>
            </p>
          </S.TermsContainer>
        )}

        <LabDsButton
          label="Adicionar"
          onHandleButton={handleAddAudience}
          className="button"
          loading={isLoading}
        />
      </S.Container>
    </Modal>
  );
}
