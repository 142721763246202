import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;

  svg {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  h2 {
    margin-bottom: 0.5rem;
  }
  p {
    margin-bottom: 2rem;
  }
`;
