import styled, { css } from 'styled-components';

export const ToolsWrapper = styled.div`
  position: relative;
`;

export const ToggleTools = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2rem;
  height: 2rem;

  border-radius: 0.25rem;

  transition: background-color 0.3s;

  svg {
    width: 1.5rem;
    height: 1.5rem;

    margin: 0 !important;

    color: #231f20;

    transition: color 0.3s;
  }

  &:hover,
  &.active {
    background: var(--grayscale-4);
  }

  &.active {
    > svg {
      color: var(--success-2);
    }
  }
`;

export const ToolsModal = styled.div<{ isExpanded: boolean }>`
  position: absolute;
  top: 2.5rem;
  right: 0;

  width: 20rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  background-color: var(--white);
  border: 1px solid var(--grayscale-20);
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;

  padding: 1rem;

  opacity: 0;
  pointer-events: none;
  user-select: none;

  transition: opacity 0.3s;

  > .modal-header {
    display: flex;
    flex-grow: 0;
  }

  > .modal-body {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    gap: 1rem 1.5rem;
    flex-wrap: wrap;

    min-height: 18.5rem;

    > .tool-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  > .modal-footer {
    display: flex;
    justify-content: center;
    flex-grow: 0;
  }

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
`;
