import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 24rem;
    height: 21rem;
  }

  h3 {
    margin-top: 1rem;
  }

  ul {
    margin-top: 1rem;
  }

  li {
    font-family: 'Nunito Sans', sans-serif;
  }
  .animation {
    height: 15.625rem;
    width: 15.625rem;
  }
`;
