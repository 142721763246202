import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Container } from '../../components/LegacyV4Components';
import * as S from './styles';

export function GatewayHQSeletor() {
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <Breadcrumb
          breadcrumbs={[
            { title: 'Central de dashboards', link: '/' },
            { title: 'Selecione o local', link: '/gateway/headquarter' },
          ]}
        />
        <div style={{ margin: '0 auto', marginTop: '145px' }}>
          <h1>Selecione o local de cobrança desejado</h1>
        </div>
        <S.Container>
          <S.CardProduct
            onClick={() => {
              navigate('/gateway/headquarter/checkout?type=education');
            }}
          >
            <S.ContainerContent>
              <strong>Education</strong>
            </S.ContainerContent>
          </S.CardProduct>
          <S.CardProduct
            onClick={() => {
              navigate(
                '/gateway/headquarter/checkout?type=headquarter-products',
              );
            }}
          >
            <S.ContainerContent>
              <strong>Gestão matriz</strong>
            </S.ContainerContent>
          </S.CardProduct>
        </S.Container>
      </Container>
    </>
  );
}
