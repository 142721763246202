import { Customer } from '../../../types/customer';
import { customerApi as api } from '../../api';

export async function updateCustomerColor(
  customerId: string,
  color: string,
): Promise<Customer[] | false> {
  try {
    const response = await api.put<{ data: Customer[] }>(
      `/customer/update/${customerId}`,
      {
        hexColor: color,
      },
    );

    const customers: Customer[] = response.data.data;

    return customers;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os dados dos clientes, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
