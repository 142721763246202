import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import { Modal, Button } from '../../../../../LegacyV4Components';

import * as S from './styles';
import { deleteCustomerUser } from '../../../../../../services/requests/User/deleteUser';

import { createCustomerUser } from '../../../../../../services/requests/Customers/createCustomerUser';
import { useToast } from '../../../../../../hooks/toast';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  newStakeholder: Stakeholder;
  stakeholders: Stakeholder[];
  getStakeholders: () => void;
}

export function SwapStakeholderModal({
  isOpen,
  closeModal,
  newStakeholder,
  stakeholders,
  getStakeholders,
}: ModalProps) {
  const { addToast } = useToast();

  async function SwapStakeholder() {
    const i = stakeholders.findIndex((s) => s.role === 'Stakeholder principal');
    const mainStakeholder = stakeholders[i];

    if (mainStakeholder._id) {
      await deleteCustomerUser(mainStakeholder._id);

      await createCustomerUser(newStakeholder);

      addToast({
        title: '',
        description: 'Stakeholder substituido com sucesso',
        type: 'success',
      });

      getStakeholders();
      closeModal();

      return;
    }

    closeModal();
  }

  return (
    <Modal show={isOpen}>
      <S.ModalBody>
        <AiOutlineCloseCircle size={66} color="#E92E30" />
        <MdClose
          size={16}
          className="close-button"
          onClick={() => closeModal()}
        />

        <h1
          style={{
            marginTop: 16,
            fontSize: 24,
            textAlign: 'center',
            width: 500,
          }}
        >
          Deseja substituir o stakeholder principal?
        </h1>

        <p
          style={{
            marginTop: 16,
            fontSize: 16,
            textAlign: 'center',
            width: 500,
          }}
        >
          Não é possível adicionar um novo stakeholder principal pois o mesmo já
          existe.
        </p>
      </S.ModalBody>
      <S.ModalFooter>
        <div style={{ marginRight: 10 }}>
          <Button
            size="default"
            color="red"
            variant="secondary"
            onClick={() => closeModal()}
          >
            Cancelar
          </Button>
        </div>
        <Button
          onClick={() => SwapStakeholder()}
          size="default"
          color="red"
          variant="primary"
        >
          Confirmar
        </Button>
      </S.ModalFooter>
    </Modal>
  );
}
