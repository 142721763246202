import { LabDsSwitch } from 'v4web-components-react';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Modal } from '../../../LegacyV4Components';

import * as S from './styles';
import { UnitInterface } from '../../../../types/units';

import { useToast } from '../../../../hooks/toast';
import { grantUnitToBuyBlackBox } from '../../../../services/requests/Unit/grantUnitToBlackBox';

interface ModalFieldsIncompleteProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  data: UnitInterface;
  readUnit: () => void;
}

export function ModalBlackBox({
  isOpen,
  setIsOpen,
  data,
  readUnit,
}: ModalFieldsIncompleteProps) {
  const [onStatus, setOnStatus] = useState(data.canBuyBlackBox);

  const { addToast } = useToast();

  const handleStatus = () => {
    if (onStatus) {
      setOnStatus(false);
    } else {
      setOnStatus(true);
    }
  };

  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    const body = {
      unitId: data.id,
      canBuyBlackBox: onStatus as boolean,
    };

    const createPermissionsBlackBox = await grantUnitToBuyBlackBox(body);

    if (createPermissionsBlackBox === 'error') {
      addToast({
        type: 'error',
        title: 'Erro ao habilitar unidade',
        description: 'Ocorreu um erro ao habilitar',
      });
      return;
    }

    if (!onStatus) {
      addToast({
        type: 'success',
        title: 'Unidade Desabilitada',
        description: 'Unidade desabilitada com sucesso',
      });
    } else {
      addToast({
        type: 'success',
        title: 'Unidade habilitada',
        description: 'Unidade habilitada com sucesso',
      });
    }

    readUnit();

    setIsOpen(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal
        show={isOpen}
        onBackground={() => setIsOpen(false)}
        closeButton
        labelSubmit="Confirmar"
        title="Habilitar Black Box"
        footer
        height="800px"
        handlerSubmit={() => {
          handleSubmit(onSubmit);
        }}
        buttonType="submit"
      >
        <S.Container>
          <S.ContainerStatus>
            <S.Subtitle>Permitir Acesso</S.Subtitle>
            <S.ContainerSwitch>
              <LabDsSwitch
                size="small"
                onHandleSwitch={handleStatus}
                checked={onStatus}
              />
            </S.ContainerSwitch>
          </S.ContainerStatus>
          <S.ContainerCheckbox>
            <S.TextInfo disabled>
              A unidade {data.name} terá acesso à página de ofertas de Black Box
              que estarão para venda, e assim realizar a aquisição de Black Box.
            </S.TextInfo>
          </S.ContainerCheckbox>
        </S.Container>
      </Modal>
    </form>
  );
}
