import * as Yup from 'yup';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiCalendar } from 'react-icons/fi';
import { BiCreditCard, BiEditAlt, BiFile } from 'react-icons/bi';

import { parseCookies } from 'nookies';
import { format } from 'date-fns';
import { Button, Headline } from '../../components/LegacyV4Components';
import v4CompanyLogo from '../../assets/logos/v4-company-logo.svg';
import { ResumeSubscription } from '../../components/AtomicDesign/templates/ResumeSubscription';
import { CardSubscription } from '../../components/AtomicDesign/templates/CardSubscription';
import { convertNumberToBRL } from '../../utils/convertNumberToBRL';

import * as S from './styles';
import v4Logo from '../../assets/logos/V4-Logo.svg';
import { ModalCookies } from '../../components/AtomicDesign/molecules/ModalCookies';
import { ModalAcceptedCookies } from '../../components/AtomicDesign/molecules/ModalAcceptedCookies';
import { GatewayTerms } from '../../components/AtomicDesign/molecules/GatewayTerms';
import {
  IPlanList,
  getPlanList,
  getPlanListV2,
} from '../../services/requests/Finance/getPlanList';

export interface FormikSubscriptionOptions {
  // recipientId: string;
  name: string;
  email: string;
  planIdentifier: string;
  paymentCardToken: {
    first_name?: string;
    last_name?: string;
    number?: string;
    month?: string;
    year?: string;
    verification_value?: string;
  };
  parcelas?: string;
}

export function SubscriptionOptions() {
  const [isLoading, setIsLoading] = useState(false);
  const [indexTab, setIndexTab] = useState<number>(1);
  const [plan, setPlan] = useState<IPlanList>();
  const [showTerms, setShowTerms] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(true);
  const [isCookiesAccepted, setIsCookiesAccepted] = useState<string>();
  const url = window.location.href;
  const verifyIfIsV2 = url.includes('v2');
  const { id } = useParams<{ id: string }>();
  const configBankSchema = Yup.object().shape({
    name: Yup.string().required('O campo é obrigatório!'),
    email: Yup.string()
      .email('E-mail inválido')
      .required('Formato de e-mail inválido.'),
    paymentCardToken: Yup.object().shape({
      first_name: Yup.string().required('O campo é obrigatório!'),
      last_name: Yup.string().required('O campo é obrigatório!'),
      number: Yup.string().required('O campo é obrigatório!'),
      month: Yup.string().required('O campo é obrigatório!'),
      year: Yup.string().required('O campo é obrigatório!'),
      verification_value: Yup.string().required('O campo é obrigatório!'),
    }),
  });

  useEffect(() => {
    async function getPlansList() {
      let plans;
      if (verifyIfIsV2) {
        plans = await getPlanListV2(id);
      } else {
        plans = await getPlanList(id);
      }
      if (plans) {
        setPlan(plans);
      }
    }
    getPlansList();
  }, [id, verifyIfIsV2]);

  useEffect(() => {
    const { 'v4company.cookiesAccepted': cookiesAccepted } = parseCookies();

    if (!cookiesAccepted) {
      setIsOpenModal(true);
      return;
    }
    setIsOpenModal(false);
    setIsCookiesAccepted(cookiesAccepted);
  }, []);

  return (
    <>
      {isLoading && <div>Loading</div>}
      <Formik
        initialValues={{
          // recipientId: `${id}`,
          name: ``,
          email: '',
          planIdentifier: `${id}`,
          paymentCardToken: {
            first_name: '',
            last_name: '',
            number: '',
            month: '',
            year: '',
            verification_value: '',
          },
        }}
        validationSchema={configBankSchema}
        onSubmit={async () => {
          setIndexTab(indexTab + 1);
        }}
      >
        {({ errors, values, handleBlur, setFieldValue, touched }) => (
          <>
            <S.Container>
              {indexTab === 1 ? (
                <S.About theme="center">
                  <img src={v4Logo} alt="logo v4" />
                  <p>
                    Sua assinatura para o plano
                    <span
                      style={{
                        fontWeight: '700',
                        fontFamily: 'Inter',
                        fontSize: '1.375rem',
                        marginInline: '0.4rem',
                      }}
                    >
                      {plan?.planName}
                    </span>
                    será de:
                  </p>
                  <p
                    style={{
                      fontWeight: '700',
                      fontFamily: 'Inter',
                      fontSize: '1.375rem',
                    }}
                  >
                    {convertNumberToBRL(
                      Number(plan?.recentInvoices[0].total) / 100,
                    )}
                  </p>
                  <p style={{ display: 'flex', alignItems: 'center' }}>
                    <FiCalendar /> Você pagará todo dia{' '}
                    {format(new Date(), 'dd')}
                  </p>
                  <S.Terms>
                    <input
                      type="checkbox"
                      checked={acceptTerms}
                      onChange={() => setAcceptTerms(!acceptTerms)}
                    />
                    <p>
                      Li e estou de acordo com os{' '}
                      <button type="button" onClick={() => setShowTerms(true)}>
                        Termos de Condições e de uso
                      </button>
                    </p>
                  </S.Terms>
                  <Button
                    variant="primary"
                    color="green"
                    size="default"
                    onClick={async () => {
                      setIndexTab(2);
                    }}
                    disabled={!acceptTerms}
                    rightIcon={<BiCreditCard />}
                  >
                    Cadastrar cartão de crédito
                  </Button>
                </S.About>
              ) : (
                <>
                  <S.Navbar>
                    {indexTab !== 5 ? (
                      <>
                        <S.ContainerTabs>
                          <section
                            style={{
                              border: `${
                                indexTab === 1 && `1px solid #00c49a`
                              }`,
                              background: `${
                                indexTab === 1 ? `#fff` : `#00c49a`
                              }`,
                            }}
                            aria-hidden
                            className={`${
                              (indexTab === 1 && `selected`) ||
                              (indexTab !== 1 && 'NotSelected')
                            }`}
                            key={1}
                            onClick={() => {
                              indexTab === 1 && setIndexTab(1);
                              indexTab === 2 && setIndexTab(indexTab - 1);
                            }}
                          >
                            <BiEditAlt
                              color={indexTab === 1 ? `#00c49a` : '#333333'}
                            />
                            <span>Sobre a assinatura</span>
                          </section>
                        </S.ContainerTabs>
                        <S.ContainerTabs>
                          <section
                            style={{
                              border: `${
                                indexTab === 2
                                  ? `1px solid #00c49a`
                                  : '1px solid #dadae7'
                              }`,
                              background: `${
                                (indexTab === 2 && `#fff`) ||
                                (indexTab > 2 && `#00c49a`)
                              }`,
                            }}
                            aria-hidden
                            className={`${
                              (indexTab === 2 && `selected`) ||
                              (indexTab > 2 && 'NotSelected')
                            }`}
                            key={2}
                            onClick={() => {
                              if (acceptTerms) {
                                indexTab === 1 && setIndexTab(2);
                                indexTab === 3 && setIndexTab(indexTab - 1);
                              }
                            }}
                          >
                            <BiCreditCard
                              color={indexTab === 2 ? `#00c49a` : ''}
                            />
                            <span>Pagamento</span>
                          </section>
                        </S.ContainerTabs>
                        <S.ContainerTabs>
                          <section
                            style={{
                              border: `${
                                indexTab === 3
                                  ? `1px solid #00c49a`
                                  : '1px solid #dadae7'
                              }`,
                              background: `${
                                (indexTab === 3 && `#fff`) ||
                                (indexTab > 3 && `#00c49a`)
                              }`,
                            }}
                            aria-hidden
                            className={`${
                              (indexTab === 3 && `selected`) ||
                              (indexTab > 3 && 'NotSelected')
                            }`}
                            key={3}
                            onClick={() => {
                              if (
                                values.paymentCardToken.number &&
                                !errors.paymentCardToken?.number &&
                                values.paymentCardToken.first_name &&
                                !errors.paymentCardToken?.first_name &&
                                values.paymentCardToken.last_name &&
                                !errors.paymentCardToken?.last_name &&
                                values.paymentCardToken.month &&
                                !errors.paymentCardToken?.month &&
                                values.paymentCardToken.year &&
                                !errors.paymentCardToken?.year &&
                                values.paymentCardToken.verification_value &&
                                !errors.paymentCardToken?.verification_value
                              ) {
                                indexTab === 2 && setIndexTab(3);
                                indexTab === 4 && setIndexTab(indexTab - 1);
                              }
                            }}
                          >
                            <BiFile color={indexTab === 3 ? `#00c49a` : ''} />
                            <span>Resumo</span>
                          </section>
                        </S.ContainerTabs>
                      </>
                    ) : null}
                  </S.Navbar>

                  <S.Body theme={indexTab === 5 ? 'center' : 'space-between'}>
                    {indexTab !== 5 ? (
                      <S.RequestsMobile
                        color={indexTab === 4 ? 'none' : 'block'}
                        style={{ border: `1px solid #00c49a` }}
                      >
                        <div className="your-request">
                          <img src={v4Logo} alt="logo v4" />
                          <Headline variant="h6">
                            Detalhes da sua assinatura
                          </Headline>
                        </div>
                        <section>
                          <div className="your-product">
                            <p
                              style={{ fontWeight: '700', fontFamily: 'Inter' }}
                            >
                              Plano {plan?.planName}
                            </p>
                            <p>
                              {plan?.recentInvoices[0].total
                                ? convertNumberToBRL(
                                    plan?.recentInvoices[0].total / 100,
                                  )
                                : ''}
                            </p>
                          </div>
                          <p
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '-0.5rem',
                            }}
                          >
                            <FiCalendar /> Você pagará todo dia{' '}
                            {format(new Date(), 'dd')}
                          </p>
                        </section>
                      </S.RequestsMobile>
                    ) : null}

                    {indexTab === 2 && (
                      <CardSubscription
                        touched={touched}
                        indexTab={indexTab}
                        setIndexTab={setIndexTab}
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                        handleBlur={handleBlur}
                      />
                    )}

                    {indexTab === 3 && (
                      <ResumeSubscription
                        isLoading={isLoading}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        values={values}
                        name={`${plan?.planName} ${format(
                          new Date(),
                          'dd/MM/yyyy',
                        )}`}
                        errors={errors}
                        handleBlur={handleBlur}
                        setIsLoading={setIsLoading}
                        subscriptionId={id || ''}
                        unitId={plan?.unitInformations?.id || ''}
                      />
                    )}

                    {indexTab !== 5 ? (
                      <S.RequestsDesktop
                        color={indexTab === 4 ? 'none' : 'block'}
                        style={{ border: `1px solid #00c49a` }}
                      >
                        <div className="your-request">
                          <img src={v4Logo} alt="logo v4" />
                          <Headline variant="h6">
                            Detalhes da sua assinatura
                          </Headline>
                        </div>
                        <section>
                          <div className="your-product">
                            <p
                              style={{ fontWeight: '700', fontFamily: 'Inter' }}
                            >
                              Plano {plan?.planName}
                            </p>
                            <p>
                              {plan?.recentInvoices[0]?.total
                                ? convertNumberToBRL(
                                    plan?.recentInvoices[0]?.total / 100,
                                  )
                                : ''}
                            </p>
                          </div>
                          <p
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '-0.5rem',
                            }}
                          >
                            <FiCalendar /> Você pagará todo dia{' '}
                            {format(new Date(), 'dd')}
                          </p>
                        </section>
                      </S.RequestsDesktop>
                    ) : null}
                  </S.Body>
                </>
              )}
              <S.Footer>
                <p>
                  Desenvolvido por: <img src={v4CompanyLogo} alt="" />
                </p>
                <span>2022 © Todos os direitos reservados. V4 Company</span>
              </S.Footer>
              <GatewayTerms
                show={showTerms}
                onClose={() => setShowTerms(false)}
              />
            </S.Container>
          </>
        )}
      </Formik>
      {!isOpenModal ? (
        <S.Cookie>
          <ModalCookies />
        </S.Cookie>
      ) : null}
      {!isCookiesAccepted ? (
        <ModalAcceptedCookies
          show={isOpenModal}
          onClose={() => setIsOpenModal(false)}
        />
      ) : null}
    </>
  );
}
