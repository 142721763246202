import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 1.5rem 2rem;
  margin: 1rem auto;
  width: 90%;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  h6 {
    margin: 0 auto;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 2rem;
  }
`;

export const HeaderOptions = styled.section``;

export const PageInfo = styled.section`
  margin-top: 1rem;
  padding: 1rem 1rem;

  border-radius: 0.25rem;

  background-color: var(--grayscale-4);
  p {
    display: flex;
    align-items: center;
    font-weight: 400;

    svg {
      margin-right: 0.625rem;
    }

    :first-child {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }
`;

export const PageInfoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`;

export const UpdatesWarning = styled.p`
  font-weight: bold;

  margin-top: 2.5rem;

  color: var(--error);
  text-align: right;
`;
