import styled from 'styled-components';

export const TooltipText = styled.div`
  font-weight: 400;
  font-family: 'Nunito Sans';
  font-size: 12px;
`;
export const TooltipBox = styled.div`
  position: absolute;
  bottom: calc(100% + 0.5rem);
  &.right {
    right: 0px;
  }
  &.left {
    left: 0px;
  }
  visibility: hidden;
  background-color: #ffffff;
  color: #333333;
  width: 150px;
  padding: 5px 5px;
  border-radius: 4px;
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s fade-in-out;
  &:before {
    content: '';
    width: 0.9rem;
    height: 0.9rem;
    right: 1rem;
    bottom: -0.5rem;
    position: absolute;
    border: 1px solid #b4b4cf;
    transform: rotate(135deg);
    border-color: #b4b4cf #b4b4cf transparent transparent;
    transition: border 0.1s ease-in-out;
  }
`;
export const TooltipCard = styled.div`
  position: relative;
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    background-color: #ffffff;
    border-color: #b4b4cf;
    border: 1px solid #b4b4cf;
    width: 230px;
    padding: 8px 8px;
    &:before {
      background-color: #ffffff;
      border-color: #b4b4cf #b4b4cf transparent transparent;
    }
  }
`;
