import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { financeApi } from '../../api';

export interface IProductList {
  _id: string;
  recipientId: string;
  name: string;
  value: number;
  description: string;
  commissionPercentage: number;
  payableWith: string[];
  feeResponsible?: string;
  creditCardInstallments?: number;
  physicalProduct: boolean;
  disabled: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface ProductListByType {
  userId: number;
  id: number;
  title: string;
  completed: boolean;
}

export function useGetProductList(
  recipientId: string | undefined,
): UseQueryResult<IProductList[], IProductList[]> {
  return useQuery<IProductList[], IProductList[]>(
    ['checkoutsList', recipientId],
    () =>
      financeApi
        .get<IProductList[]>(`checkouts/recipient/${recipientId}`)
        .then(async (r) => {
          return r.data;
        }),
    {},
  );
}

export function useQueryProductListType(
  productType: string | undefined,
): UseQueryResult<any, any> {
  return useQuery<any, any>(
    ['productList', productType],
    () =>
      financeApi.get<any>(`products/type/${productType}`).then(async (r) => {
        return r.data;
      }),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
      refetchOnWindowFocus: true,
    },
  );
}
