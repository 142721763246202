import styled from 'styled-components';

export const RowTable = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
export const Header = styled.div`
  h5 {
    margin: 2rem 0;
  }
`;

export const OnboardingProcessInfo = styled.div`
  display: flex;
  font-weight: 700;
  padding: 0.625rem;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  gap: 0.625rem;
  background: var(--grayscale-4, #e6e6ef);
`;

export const Close = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;
