import { CSSProperties } from 'styled-components';

import { Container } from './styles';

interface LoadingProps {
  style?: CSSProperties;
}

export function CustomLoading({ style }: LoadingProps) {
  return (
    <Container
      className="ellipsis"
      data-testid="loading-container"
      style={style}
    >
      <div />
      <div />
      <div />
      <div />
    </Container>
  );
}
