import { useState, useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { v4 } from 'uuid';

import { TeamCard } from '../../components/AtomicDesign/molecules/TeamCard';
import { Button } from '../../components/Default/Button';
import { Input } from '../../components/Default/Input';
import { Modal } from '../../components/Default/Modal';
import { Textarea } from '../../components/Default/Textarea';

import { Team as TeamProps } from '../../types/team';
import { teamMock } from './mocks';
import { getValidationErrors } from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';

import * as S from './styles';

export function Teams() {
  const [teams, setTeams] = useState<TeamProps[]>([teamMock()]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addToast } = useToast();

  const teamForm = useRef<FormHandles>(null);

  const removeTeam = useCallback((id: string) => {
    setTeams((previousTeams) => previousTeams.filter((team) => team.id !== id));
  }, []);

  const handleTeamEdit = useCallback((team: TeamProps) => {
    setTeams((previousTeams) => {
      const newTeams = previousTeams;
      const teamIndex = previousTeams.findIndex(
        (teamItem) => teamItem.id === team.id,
      );

      newTeams[teamIndex] = team;

      return [...newTeams];
    });
  }, []);

  const addTeam = useCallback(
    async (data: { name: string; description: string }) => {
      try {
        teamForm.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Nome obrigatório.')
            .max(60, 'No máximo 60 dígitos.'),
          description: Yup.string()
            .required('Descrição obrigatório.')
            .max(250, 'No máximo 250 dígitos.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        teamForm.current?.setFieldValue('name', '');
        teamForm.current?.setFieldValue('description', '');

        const newTeam = {
          id: v4(),
          name: data.name,
          description: data.description,
        };

        setTeams((previousTeams) => [...previousTeams, newTeam]);
        setIsModalOpen(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          teamForm.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na criação de time',
          description: 'Ocorreu um erro ao criar time, tente novamente',
        });
      }
    },
    [addToast],
  );

  return (
    <S.Container>
      <h1>Meus Times</h1>

      <Modal modalOpen={isModalOpen}>
        <S.Form ref={teamForm} onSubmit={addTeam}>
          <h3>Cadastrar novo time</h3>

          <p>Nome do time</p>
          <Input placeholder="Digite o nome do time" name="name" />

          <p>Descrição</p>
          <Textarea
            placeholder="Dê uma descrição ao time Ex:  Foco no desenvolvimento de um produto que atenda dores dos clientes alinhados com os objetivos da empresa"
            name="description"
          />

          <footer>
            <Button
              onClick={() => setIsModalOpen(!isModalOpen)}
              small
              secondary
            >
              Cancelar
            </Button>
            <Button small type="submit">
              Salvar
            </Button>
          </footer>
        </S.Form>
      </Modal>

      <Button small onClick={() => setIsModalOpen(!isModalOpen)}>
        Cadastrar novo time
      </Button>

      <ul>
        {teams.map((team, i) => (
          <TeamCard
            key={team.id}
            selected={i === 0}
            team={team}
            handleDeleteTeam={removeTeam}
            handleTeamEdit={handleTeamEdit}
          />
        ))}
      </ul>
    </S.Container>
  );
}
