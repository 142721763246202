import * as yup from 'yup';

export const createEmailInvestorSchema = yup.object().shape({
  unitRole: yup.string().required('Campo Obrigatório!'),
  userRole: yup.string().required('Campo Obrigatório!'),
  dashboardRole: yup.string().required('Campo Obrigatório!'),
  leadbrokerRole: yup.string().required('Campo Obrigatório!'),
  blackBoxRole: yup.string().required('Campo Obrigatório!'),
  dealBrokerRole: yup.string().required('Campo Obrigatório!'),
  customerRole: yup.string().required('Campo Obrigatório!'),
  headquarterRole: yup.string().required('Campo Obrigatório!'),
  paymentGatewayRole: yup.string().required('Campo Obrigatório!'),
});
