import { financeApi } from '../../api';

interface DataToSend {
  unitId: string;
  bank: string;
  accountType: string;
  bankCc: string;
  bankAgency: string;
}

export async function createUnitAWS(data: DataToSend): Promise<boolean> {
  try {
    await financeApi.post(`/payments/unitValidate`, data);
    return true;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar subconta da unidade, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
