import styled from 'styled-components';

interface SubtitleBuilderProps {
  isBold?: boolean;
}

export const SubtitleBuilder = (isBold: boolean) =>
  styled('small')<SubtitleBuilderProps>({
    fontSize: '0.5625rem',
    fontWeight: isBold ? 900 : 500,
  });

export const InputContent = styled.div<{
  display?: string;
  w?: string;
  direction?: string;
}>`
  width: ${({ w }) => w || '100%'};
  display: ${({ display }) => display || 'flex'};
  flex-direction: ${({ direction }) => direction || 'column'};
  position: relative;
`;

export const Error = styled.span`
  font-size: 12px;
  color: #c01c30;
`;

export const InputLabel = styled.label`
  font-weight: 700;
  font-size: 16px;
  color: #333333;
  font-family: 'Inter', 'Nunito Sans';
  margin-bottom: 8px;
`;

export const Required = styled.span`
  font-size: 16px;
  font-family: 'Inter', 'Nunito Sans';
  font-weight: bold;
  color: #c01c30;
`;

export const Placeholder = styled.span`
  font-size: 14px;
  font-family: 'Inter', 'Nunito Sans';
  font-weight: 400;
  color: #7f7f7f;
`;

export const Input = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding: 2px 16px;
  border: 1px solid #e6e6ef;
  font-size: 16px;
  border-radius: 3px;
  background-color: #ffff;
  cursor: pointer;
  margin-bottom: 8px;

  &::placeholder {
    color: #7f7f7f;
  }
`;

export const InputItens = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
`;

export const Tooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  width: 24px;
  height: 24px;

  z-index: 999;

  border-radius: 50%;
  border: 0.5px solid #e6e6ef;

  background-color: #fff;

  cursor: pointer;
  position: relative;
`;

export const ContentSearch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffff;
  position: absolute;
  top: 4.8rem;
  z-index: 99;

  padding: 5px;

  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  border: 1px solid #e6e6ef;
  border-top: none;
`;

export const InputSearch = styled.input<{ borderColor?: string }>`
  width: 100%;
  padding: 12px 16px;
  border: 1px solid ${({ borderColor }) => borderColor || '#E6E6EF'};
  font-size: 16px;
  border-radius: 3px;

  margin-bottom: 5px;

  &::placeholder {
    color: #7f7f7f;
  }

  &:focus {
    border-color: #00c49a;
  }
`;

export const Options = styled.div`
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;

  position: relative;

  padding: 5px;
  &::-webkit-scrollbar {
    width: 4px;
    margin-left: 2px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c01c30;
  }
`;

export const Itens = styled.button<{ checked: boolean }>`
  width: 100%;
  padding: 8px 16px;
  transition: 500ms;
  border-radius: 3px;
  margin-bottom: 3px;

  border: none;
  text-align: start;

  background-color: ${({ checked }) => (checked ? '#e6e6ef' : 'transparent')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: #e6e6ef;
  }
`;
