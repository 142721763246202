import { useCallback, useMemo } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  OptionsOrGroups,
  StylesConfig,
  Theme,
} from 'react-select';

import * as S from './styles';

interface ComponentProps {
  marginBottom?: string;
  containerStyle?: React.CSSProperties;
  label?: string;
  name?: string;
  onBlur?: any;
  onChange?: any;
  value?: string;
  placeholder?: string;
  options?: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined;
  width?: string;
  helper?: string;
  styledInSelect?: string;
}

export function SelectField({
  marginBottom = '0rem',
  containerStyle,
  label,
  name,
  onBlur,
  onChange,
  value,
  placeholder,
  helper,
  options,
  width,
  styledInSelect,
  ...rest
}: ComponentProps) {
  const customTheme = useCallback((theme: Theme) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: 'var(--grayscale-10)',
        primary25: 'var(--white)',
        primary50: 'var(--white)',
        primary75: 'var(--white)',
        neutral0: 'var(--white)',
        neutral5: 'var(--white)',
        neutral10: 'var(--white)',
        neutral20: 'var(--white)',
        neutral30: 'var(--secondary-light)',
        neutral40: 'var(--secondary-light)',
        neutral50: 'var(--secondary-light)',
        neutral60: 'var(--secondary-light)',
        neutral70: 'var(--secondary-light)',
        neutral80: 'var(--secondary-light)',
        neutral90: 'var(--secondary-light)',
      },
    };
  }, []);

  const customStyles: StylesConfig = useMemo(() => {
    return {
      container: (provided) => ({
        ...provided,
        marginBottom,
        borderRadius: '0rem',
        width: '100%',
        background: `${styledInSelect}`,
        boxShadow: `${
          styledInSelect &&
          'rgba(0, 0, 0, 0.12) 0px 1px 2px, rgba(0, 0, 0, 0.06) 0px 2px 6px 2px'
        }`,
      }),
      input: () => ({
        width: '100%',
        marginLeft: '10px',
        position: 'absolute',
      }),
      valueContainer: () => ({
        // ...provided,
        maxWidth: `${width ? `${width}` : 'auto'}`,
        width: '100%',
        height: `2.5rem`,
        border: `1xp solid #ccc`,
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'center',
      }),
      option: (provided, state) => ({
        ...provided,
        borderRadius: '0rem',
        cursor: 'pointer',
        backgroundColor: state.isFocused ? '#fff' : '#fff',
        color: '#333333',
        padding: '14px 16px',
      }),
      menu: (provided) => ({
        ...provided,
        marginTop: '0px',
        border: '1px solid #E6E6EF',
        borderRadius: '0rem 0rem 0.5rem 0.5rem',
        boxShadow: 'none',
      }),
      menuList: (provided) => ({
        ...provided,
        color: 'var(--secondary-main)',
        fontSize: '1rem',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'var(--secondary-light)',
        overflow: 'inherit',
        marginLeft: '1rem',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        cursor: 'pointer',
        color: 'var(--secondary-light)',
        marginLeft: '1rem',
      }),
      placeholder: (provided) => ({
        ...provided,
        fontSize: '1rem',
        fontWeight: '400',
        marginLeft: '1rem',
        fontFamily: 'Nunito Sans',
        color: `${placeholder !== '' ? '#333333' : 'var(--grayscale-10)'}`,
      }),
      control: (state) => ({
        maxWidth: `${width ? `${width}` : 'auto'}`,
        width: '100%',
        boxShadow: 'none',
        display: 'flex',
        border: state.isFocused ? '##B4B4CF' : '1px solid #E6E6EF',
        borderRadius: '4px',
        ...(helper && { border: '1px solid red' }),
      }),
    };
  }, [marginBottom, width, placeholder, helper, styledInSelect]);

  const CaretDownIcon = useCallback(() => {
    return <MdKeyboardArrowDown size={18} />;
  }, []);

  const DropdownIndicator = useCallback(
    (props: DropdownIndicatorProps) => {
      return (
        <components.DropdownIndicator {...props}>
          <CaretDownIcon />
        </components.DropdownIndicator>
      );
    },
    [CaretDownIcon],
  );

  return (
    <S.Container style={containerStyle} className="select-container">
      <h6>{label}</h6>
      <Select
        theme={customTheme}
        styles={customStyles}
        components={{ DropdownIndicator }}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        placeholder={value !== '' ? value : placeholder}
        options={options}
        {...rest}
      />
      {helper && <S.Error>O campo é obrigatório!</S.Error>}
    </S.Container>
  );
}
