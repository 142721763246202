import { SetStateAction, useCallback, useState } from 'react';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { Button, Input, Modal } from '../../../LegacyV4Components';
import { useToast } from '../../../../hooks/toast';
import { Unit } from '../../../../hooks/types';
import { disableUnit } from '../../../../services/requests/Unit/disableUnit';
import { deleteUnit } from '../../../../services/requests/Unit/deleteUnit';

import * as S from './styles';

interface InviteNewColabProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  modalProps: ModalProps;
}

interface ModalProps {
  action: string;
  disableUnitProps: {
    name: string;
    id: string;
    active?: boolean;
  };
  unit: Unit;
  getUnit: React.Dispatch<SetStateAction<Unit | undefined>>;
}
export function ActionUnit({
  isOpen,
  setIsOpen,
  modalProps,
}: InviteNewColabProps) {
  const { addToast } = useToast();
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handlerAction = useCallback(async () => {
    setLoading(true);
    if (modalProps.action === 'excluir' && name !== '') {
      try {
        const res = await deleteUnit(modalProps.disableUnitProps.id);
        if (res) {
          modalProps?.getUnit(res as any);
          addToast({
            type: 'success',
            title: `Unidade deletada com sucesso`,
            description: '',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Não foi possivel concluir a ação no momento!',
            description: 'Tente novamente mais tarde!',
          });
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Não foi possivel concluir a ação no momento!',
          description: 'Tente novamente mais tarde!',
        });
      } finally {
        setName('');
        setLoading(false);
        setIsOpen(false);
      }
    } else if (
      (modalProps.action === 'ativar' || modalProps.action === 'desativar') &&
      modalProps.disableUnitProps.active
    ) {
      try {
        const res = await disableUnit(
          modalProps.disableUnitProps.id,
          modalProps.disableUnitProps.active,
        );
        if (res) {
          modalProps?.getUnit(res as any);
          addToast({
            type: 'success',
            title: `Unidade ${
              modalProps.action === 'desativar' ? 'desativada' : 'ativada'
            } com sucesso`,
            description: '',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Não foi possivel concluir a ação no momento!',
            description: 'Tente novamente mais tarde!',
          });
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Não foi possivel concluir a ação no momento!',
          description: 'Tente novamente mais tarde!',
        });
      } finally {
        setName('');
        setLoading(false);
        setIsOpen(false);
      }
    } else {
      try {
        const res = await disableUnit(
          modalProps.disableUnitProps.id,
          modalProps.disableUnitProps.active as boolean,
        );
        if (res && res !== null) {
          modalProps?.getUnit(res as any);
          addToast({
            type: 'success',
            title: `Unidade ${
              modalProps.action === 'desativar' ? 'desativada' : 'ativada'
            } com sucesso`,
            description: '',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Não foi possivel concluir a ação no momento!',
            description: 'Tente novamente mais tarde!',
          });
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Não foi possivel concluir a ação no momento!',
          description: 'Tente novamente mais tarde!',
        });
      } finally {
        setName('');
        setLoading(false);
        setIsOpen(false);
      }
    }
  }, [addToast, modalProps, name, setIsOpen]);

  return (
    <Modal show={isOpen} onBackground={() => setIsOpen(false)} closeButton>
      <S.ModalBody>
        {modalProps?.action === 'ativar' ? (
          <AiOutlineCheckCircle size={66} color="#00C49A" />
        ) : (
          <AiOutlineCloseCircle size={66} color="#E92E30" />
        )}

        <h1
          style={{
            marginBottom: 16,
            marginTop: 35,
            fontSize: 24,
            textAlign: 'center',
          }}
        >
          Deseja {modalProps?.action as string} a Unidade “
          {modalProps?.disableUnitProps?.name as string}”?
        </h1>
        <p style={{ marginBottom: 32 }}>
          Digite o nome da Unidade para {modalProps?.action} no mkt.lab
        </p>
        <S.InputArea>
          <Input
            label=""
            placeholder="Nome da unidade"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </S.InputArea>
      </S.ModalBody>
      <S.ModalFooter>
        <div style={{ marginRight: 10 }}>
          <Button
            size="default"
            color="red"
            variant="secondary"
            onClick={() => setIsOpen(false)}
            loading={loading}
          >
            Cancelar
          </Button>
        </div>
        <Button
          onClick={() => handlerAction()}
          size="default"
          color={modalProps?.action === 'ativar' ? 'green' : 'red'}
          variant="primary"
          disabled={!(modalProps?.disableUnitProps?.name === name)}
          loading={loading}
        >
          {modalProps?.action[0].toUpperCase() +
            modalProps?.action.slice(1).toLowerCase()}
        </Button>
      </S.ModalFooter>
    </Modal>
  );
}
