export const unitPermissionOptions = [
  {
    label: 'Visualizar',
    value: 'READ_ONLY',
    desc: 'Permissão para visualizar a unidade e seus investidores.',
  },
  {
    label: 'Visualizar e editar',
    value: 'WRITE_AND_READ',
    desc: 'Permissão para visualizar e editar dados da unidade.',
  },
  {
    label: 'Não visualizar',
    value: 'CAN_NOT_SEE',
    desc: 'Não possui permissão para visualizar as informações da unidade.',
  },
  {
    label: 'Administrar',
    value: 'ADMIN',
    desc: 'Permissão para gerenciar dados da unidade, investidores e definir permissões.',
  },
];
