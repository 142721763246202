import React, { SetStateAction, useState, useEffect } from 'react';
import { CurrencyInputProps } from 'react-currency-input-field';
import { IoIosHelp } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import * as S from './styles';

interface InputProps extends CurrencyInputProps {
  label: string;
  borderColor?: string;
  required?: boolean;
  info?: string;
  setCurrency: React.Dispatch<SetStateAction<string | number>>;
  error?: string;
  width?: string;
}

export function InputCurrency({
  label,
  required,
  info,
  setCurrency,
  error,
  width,
  ...rest
}: InputProps) {
  const [className, setClassName] = useState('');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setClassName('');
    }, 2000);

    return () => clearTimeout(timeout);
  }, [className]);

  const validateValue = (value: string | undefined): void => {
    if (!value) {
      setClassName('');
    } else if (Number.isNaN(Number(value))) {
      setClassName('is-invalid');
    } else {
      setCurrency(value);
      setClassName('is-valid');
    }
  };

  return (
    <S.InputContent width={width}>
      <ReactTooltip />

      <S.Row>
        <S.InputLabel>
          {label}
          {required && <S.Required>*</S.Required>}
        </S.InputLabel>
        {info ? (
          <S.Tooltip>
            <IoIosHelp size={24} data-tip={info} />
          </S.Tooltip>
        ) : null}
      </S.Row>
      <S.InputCurrency
        prefix="R$ "
        id="validationCustom01"
        name="input-1"
        className={`form-control ${className}`}
        onValueChange={validateValue}
        decimalSeparator=","
        groupSeparator="."
        step={1}
        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
        {...rest}
      />
      <S.Error>{error}</S.Error>
    </S.InputContent>
  );
}
