import React, {
  ReactNode,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LabDsNavbar, NavbarItem } from 'v4web-components-react';
import { menus } from './menus';
import { verifyLbUser } from '../../../services/requests/User/verifyLbUser';
import { Header } from '../../../components/Default/Header';
import { useAuth } from '../../../hooks/auth';
import v4Logo from '../../../assets/logos/V4-Logo.svg';
import { RoiNotification } from '../../../components/AtomicDesign/atoms/RoiNotification';
import { ekyteAuth } from '../../../services/requests/Stack/ekyte';
import { getEkyteCompany } from '../../../services/requests/Stack/getEkyteCompany';
import version from '../../../../package.json';
import * as S from './styles';
import { createJwtToken } from '../../../services/requests/Eletromidia/createJwtToken';
import { getUser } from '../../../services/requests/Eletromidia/getUser';
import { useEletromidiaUser } from '../../../hooks/eletromidiaUser';

export interface DefaultProps extends React.HTMLAttributes<Element> {
  children: ReactNode;
}

interface SubMenu {
  title: string;
  icon: string;
  active: boolean;
  event: () => void;
  conditional?: boolean;
}

export default function Default() {
  const headquarterId = process.env.REACT_APP_HEADQUARTER_ID;

  const { user, permissionsManager } = useAuth();
  const [isManagerUser, setIsManagerUser] = useState(false);
  const [lbUser, setLbUser] = useState<boolean>();
  const [ekyteActive, setEkyteActive] = useState<boolean>();
  const isHeadquarter = user.unitId === headquarterId;
  const [token, setToken] = useState<string>('');

  const {
    eletromidiaToken,
    setEletromidiaToken,
    setEletromidiaUser,
    eletromidiaUser,
    setEletromidiaLoading,
  } = useEletromidiaUser();

  const [showNavbar, setShowNavbar] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  Object.keys(permissionsManager || {})?.forEach((key) => {
    Object.keys(
      permissionsManager?.[key as keyof typeof permissionsManager],
    )?.forEach((subKey) => {
      if (
        permissionsManager?.[key as keyof typeof permissionsManager]?.[
          subKey as keyof (typeof permissionsManager)[keyof typeof permissionsManager]
        ] &&
        !isManagerUser
      ) {
        setIsManagerUser(true);
      }
    });
  });

  const verifyUser = useCallback(async () => {
    const response = await verifyLbUser(user._id);
    response && setLbUser(true);
  }, [user._id]);

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  useEffect(() => {
    const hideNavbarIn = ['/perfil', '/space/roi-week'];
    const isNavbarHidden = !hideNavbarIn.some(
      (path) => location.pathname.indexOf(path) === 0,
    );

    setShowNavbar(isNavbarHidden);
  }, [location]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      `%c Version Mktlab: ${version.version}`,
      'color: black; background-color: white; padding: 5px 10px;',
    );
  }, []);

  const setEkyteToken = useCallback(async () => {
    const ekyteToken = await ekyteAuth();

    if (!ekyteToken) return;

    setToken(ekyteToken);
    getEkyteCompany(user.unitId).then((response) => {
      setEkyteActive(!!response?.active);
    });
  }, [user]);

  const getEletromidiaUser = useCallback(async () => {
    setEletromidiaLoading(true);
    const newEletromidiaUser = await getUser(user.email);

    if (!newEletromidiaUser) return;
    setEletromidiaUser(newEletromidiaUser);

    setEletromidiaLoading(false);
  }, [user.email, setEletromidiaUser, setEletromidiaLoading]);

  const getEletromidiaTokenLogin = useCallback(async () => {
    const newEletromidiaToken = await createJwtToken('user_login', user.email);

    if (!newEletromidiaToken) return;

    setEletromidiaToken(newEletromidiaToken);
  }, [user.email, setEletromidiaToken]);

  const menusLab = useMemo(() => {
    return menus({
      unitId: user?.unitId,
      lbUser,
      managerUser: isManagerUser,
      ekyteActive,
      isHeadquarter,
      transition: !!user?.permissions?.transition,
      token,
      navigate,
      location,
      eletromidiaToken,
      userId: user?._id,
    });
  }, [
    user?.unitId,
    user?.permissions?.transition,
    user?._id,
    lbUser,
    isManagerUser,
    ekyteActive,
    isHeadquarter,
    token,
    navigate,
    location,
    eletromidiaToken,
  ]);

  useEffect(() => {
    setEkyteToken();
  }, [setEkyteToken]);

  useEffect(() => {
    getEletromidiaUser();
  }, [getEletromidiaUser]);

  useEffect(() => {
    if (eletromidiaUser.email) {
      getEletromidiaTokenLogin();
    }
  }, [getEletromidiaTokenLogin, eletromidiaUser.email]);

  const verifySubmenus = (submenus: SubMenu[]) => {
    return submenus?.filter((submenu) => submenu.conditional);
  };

  return (
    <>
      <Header />
      {showNavbar && user?.unitId && (
        <LabDsNavbar
          logo={v4Logo}
          user={{
            name: user.name,
            unitName:
              user.unitId === headquarterId
                ? 'Headquarter'
                : user.unit?.name || '',
          }}
          activeProductsV4={
            location.pathname.includes('/leadbroker') ||
            location.pathname === '/' ||
            location.pathname.includes('/dashboards') ||
            location.pathname.includes('/payments') ||
            location.pathname.includes('/clientes/mediahub') ||
            location.pathname.includes('/gateway/headquarter')
          }
          activeRedeV4={
            location.pathname.includes('/unidades') ||
            (location.pathname.includes('/headquarter') &&
              !location.pathname.includes('/gateway')) ||
            (location.pathname.includes('/cliente') &&
              !location.pathname.includes('mediahub')) ||
            location.pathname.includes('/unit')
          }
        >
          {menusLab.map((menu) => {
            if (menu.conditional) {
              return (
                <NavbarItem
                  slot={menu.slot}
                  submenus={verifySubmenus(menu.subMenus as SubMenu[]) || []}
                  item={{
                    title: menu.title,
                    icon: menu.icon,
                    active: menu.active,
                    event: menu.event,
                  }}
                />
              );
            }
            return null;
          })}
        </LabDsNavbar>
      )}
      <S.Main className={!showNavbar ? 'full-width' : ''}>
        <Outlet />
      </S.Main>
      <RoiNotification />
    </>
  );
}
