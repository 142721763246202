import { useState } from 'react';
import { Modal, Container, Button } from '../../../LegacyV4Components';

import * as S from './styles';

interface DashboardOperationProps {
  isOpen: boolean;
  closeModal: () => void;
  customerId?: string | undefined;
  handleCreateDashboard: (data: {
    dashName: string;
    link: string;
    description: string;
  }) => void;
}

interface DataProps {
  name: string;
  link: string;
  description: string;
  _id?: string;
  customerId?: string;
}

export function CreateCustomerDashboard({
  isOpen,
  closeModal,
  customerId,
  handleCreateDashboard,
}: DashboardOperationProps) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [id, setId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    name: false,
    description: false,
    link: false,
  });

  const data: DataProps = {
    name,
    link,
    description,
    _id: id,
    customerId,
  };

  function handleCloseModal() {
    setName('');
    setLink('');
    setDescription('');
    setId('');
    setError({
      name: false,
      description: false,
      link: false,
    });
    closeModal();
  }

  async function handleSubmit() {
    delete data._id;
    if (!name || !link || !description) {
      setError({ name: !name, description: !description, link: !link });
      return;
    }
    setIsLoading(true);

    handleCreateDashboard({ dashName: name, description, link });

    setIsLoading(false);

    handleCloseModal();
  }
  return (
    <>
      <Modal
        show={isOpen}
        title="Anexar Dashboard"
        closeButton
        onBackground={handleCloseModal}
        size="sm"
      >
        <Container
          style={{
            backgroundColor: 'transparent',
            padding: '1rem',
          }}
        >
          <S.Container>
            <p>Nome</p>

            <S.InputField
              type="text"
              placeholder="Nome do dashboard"
              onChange={(e) => {
                setError({
                  ...error,
                  name: false,
                });
                setName(e.target.value);
              }}
              value={name}
              error={error.name}
            />
            {error.name && <S.ErrorTag>*Preencha o campo nome</S.ErrorTag>}

            <p>Descrição</p>
            <S.InputField
              type="text"
              placeholder="Descrição do dashboard"
              onChange={(e) => {
                setError({
                  ...error,
                  description: false,
                });
                setDescription(e.target.value);
              }}
              value={description}
              error={error.description}
            />
            {error.description && (
              <S.ErrorTag>*Preencha o campo descrição</S.ErrorTag>
            )}

            <p>Link</p>
            <S.InputField
              type="text"
              placeholder="Link do dashboard"
              onChange={(e) => {
                setError({
                  ...error,
                  link: false,
                });
                setLink(e.target.value);
              }}
              value={link}
              error={error.link}
            />

            {error.link && <S.ErrorTag>*Preencha o campo link</S.ErrorTag>}
          </S.Container>
          <S.ButtonContainer>
            <Button
              size="default"
              color="red"
              onClick={handleCloseModal}
              variant="secondary"
            >
              Cancelar
            </Button>
            <Button
              size="default"
              color="green"
              onClick={handleSubmit}
              variant="primary"
              loading={isLoading}
              disabled={isLoading}
            >
              Salvar
            </Button>
          </S.ButtonContainer>
        </Container>
      </Modal>
    </>
  );
}
