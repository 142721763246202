import { kubernetesApi as api } from '../../api';

export type CalculateAllUsersCapacityResponse = {
  id: string;
  value: number;
  clientId?: string;
  userId?: string;
};

export async function calculateAllUsersCapacity(
  data: CalculateAllUsersCapacityResponse[],
) {
  try {
    const response = await api.put<CalculateAllUsersCapacityResponse[]>(
      `/users/capacity/calculate`,
      data,
    );

    const customers: CalculateAllUsersCapacityResponse[] = response.data;
    return customers;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os dados dos clientes, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
