import { Dashboard } from '../../../types/dashboard';
import { dashboardsApi } from '../../api';

export async function getDashboards(
  customerId?: string,
): Promise<Dashboard[] | false> {
  try {
    const response = await dashboardsApi.get(
      `/dashboard/${customerId}/customer`,
    );
    const dashboards: any = response.data;

    return dashboards.data;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os dashboards, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
