import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1.5rem;

  width: 16.75rem;
  height: 8rem;
  padding: 2rem;

  p {
    font-weight: 700;
    white-space: nowrap;
  }

  border: 1px solid var(--grayscale-10);
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 1px 3px 1px rgba(0, 0, 0, 0.06);
`;

export const ImageIcon = styled.img`
  display: block;
  width: 3rem;
`;

export const ConnectionStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-size: 0.75rem;
  font-weight: 700;
  color: var(--grayscale-20);

  &::before {
    content: '';
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;

    background-color: var(--grayscale-20);
  }

  &.status-connected {
    color: var(--success-2);

    &::before {
      background-color: var(--success-2);
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  text-align: center;

  & button {
    font-size: 0.875rem;
  }
`;
