import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MdOutlineAdd, MdOutlineClose } from 'react-icons/md';
import { BiBarChartSquare } from 'react-icons/bi';
import { CreateCustomerDashboard } from '../CreateCustomerDashboard';
import { useToast } from '../../../../hooks/toast';

import { getDashboards } from '../../../../services/requests/Dashboards/getDashboardsCustomers';

import * as S from './styles';
import { Dashboard } from '../../../../types/dashboard';

interface CustomerDashboardsProps {
  dashboards: {
    newDashboards: Dashboard[];
    removedDashboards: string[];
    loadedDashboards: Dashboard[];
  };
  setDashboards: (dashboards: {
    newDashboards: Dashboard[];
    removedDashboards: string[];
    loadedDashboards: Dashboard[];
  }) => void;
  updates: boolean;
}

export function CustomerDashboards({
  dashboards,
  setDashboards,
  updates,
}: CustomerDashboardsProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const customerId = id;

  function handleCreateTemporaryDashboard(data: {
    dashName: string;
    link: string;
    description: string;
  }) {
    const dashboard = {
      name: data.dashName,
      link: data.link,
      description: data.description,
      customerId,
      _id: '',
    };
    setDashboards({
      ...dashboards,
      newDashboards: [...dashboards.newDashboards, dashboard],
    });

    addToast({
      title: 'Dashboard anexado!',
      type: 'warning',
      description:
        'Para confirmar as alterações feitas, clique no botão publicar no canto superior direito. Assim as informações ficarão visíveis para o cliente!',
    });
  }

  function handleTemporaryDashboardDelete(dashId: string, i: number) {
    if (!dashId) {
      dashboards.newDashboards.splice(i, 1);

      setDashboards({
        ...dashboards,
        newDashboards: [...dashboards.newDashboards],
      });
      return;
    }

    dashboards.loadedDashboards.splice(i, 1);

    setDashboards({
      ...dashboards,
      loadedDashboards: [...dashboards.loadedDashboards],
      removedDashboards: [...dashboards.removedDashboards, dashId],
    });
  }

  useEffect(() => {
    async function getCustomerDashboards() {
      const response = await getDashboards(customerId);

      if (response && !updates) {
        setDashboards({
          loadedDashboards: response,
          newDashboards: [],
          removedDashboards: [],
        });
      }
    }

    getCustomerDashboards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDashboards, customerId, updates]);

  return (
    <>
      <S.CustomerDashboards>
        <S.Header>
          <S.HeaderText>
            <h3>Dashboards de resultados</h3>
            <p>
              Adicione aqui dashboards para o cliente visualizar sempre que
              precisar.
            </p>
          </S.HeaderText>

          <S.AddDashboardButton onClick={() => setIsModalOpen(true)}>
            Adicionar novo <MdOutlineAdd />
          </S.AddDashboardButton>
        </S.Header>
        <S.Dashboards>
          {dashboards.loadedDashboards.map((dashboard, i) => (
            <S.Dashboard>
              <BiBarChartSquare />
              <S.DashboardText>
                <p>{dashboard.name}</p>
                <p>
                  Link:{' '}
                  <a href={dashboard.link} target="blank">
                    {dashboard.link}
                  </a>
                </p>
              </S.DashboardText>
              <MdOutlineClose
                onClick={() => handleTemporaryDashboardDelete(dashboard._id, i)}
                style={{ cursor: 'pointer' }}
              />
            </S.Dashboard>
          ))}
          {dashboards.newDashboards.map((dashboard, i) => (
            <S.Dashboard>
              <BiBarChartSquare />
              <S.DashboardText>
                <p>{dashboard.name}</p>
                <p>
                  Link:{' '}
                  <a href={dashboard.link} target="blank">
                    {dashboard.link}
                  </a>
                </p>
              </S.DashboardText>
              <MdOutlineClose
                onClick={() => handleTemporaryDashboardDelete(dashboard._id, i)}
                style={{ cursor: 'pointer' }}
              />
            </S.Dashboard>
          ))}
        </S.Dashboards>
      </S.CustomerDashboards>
      <CreateCustomerDashboard
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        customerId={customerId}
        handleCreateDashboard={handleCreateTemporaryDashboard}
      />
    </>
  );
}
