import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { LabDsButton } from 'v4web-components-react';
import { Modal, Container } from '../../../LegacyV4Components';
import { dashboardsApi } from '../../../../services/api';

import * as S from './styles';

interface DeleteDashboardModalProps {
  isOpen: boolean;
  closeModal: () => void;
  dashboard: {
    name: string;
    _id: string;
  };
}

export function DeleteDashboardModal({
  isOpen,
  closeModal,
  dashboard,
}: DeleteDashboardModalProps) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async () => dashboardsApi.delete(`/dashboard/${dashboard?._id}`),
    {
      onSuccess: async () => {
        // cancela se outra query estiver rodando

        await queryClient.invalidateQueries(['dashboards']);
        await queryClient.refetchQueries(['dashboards']);
        await queryClient.refetchQueries(['favorites']);
      },
    },
  );
  async function handleDeleteDashboard() {
    mutation.mutate();
    closeModal();
  }
  return (
    <Modal show={isOpen} onBackground={closeModal}>
      <Container style={{ backgroundColor: 'transparent' }}>
        <S.Container>
          <IoMdCloseCircleOutline size={64} />
          <h3>Você está prestes a excluir um dashboard</h3>
          <p>Tem certeza que deseja excluir o dashboard {dashboard.name}?</p>
          <div className="buttons">
            <LabDsButton
              label="Cancelar"
              variant="danger-outlined"
              onHandleButton={closeModal}
            />
            <LabDsButton
              label="Excluir"
              variant="danger-primary"
              onHandleButton={handleDeleteDashboard}
            />
          </div>
        </S.Container>
      </Container>
    </Modal>
  );
}
