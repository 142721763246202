import styled from 'styled-components';

interface RowProps {
  notClickable?: boolean;
}
export const Infomation = styled.div`
  .icon {
    display: flex;
    align-items: center;
  }

  .message {
    display: none;
  }
  :hover {
    cursor: pointer;
    .message {
      display: inline-block;
      position: absolute;
      top: 2.5rem;
      p {
        display: inline-block;
        position: relative;
        background-color: white;
        border: solid 1px var(--grayscale-4);
        padding: 0.2rem 0.5rem;
        right: 95%;
      }
    }
  }
`;

export const Wrapper = styled.table`
  display: flex;
  flex-direction: column;
  min-height: 12.5rem;
  max-height: 32.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
  position: sticky;
`;

export const Header = styled.thead`
  position: sticky;
  top: 0;
  display: grid;
  grid-gap: 4px;

  padding: 0 1rem;
  margin-bottom: 1rem;

  background-color: var(--grayscale-4);
  border-radius: 4px;

  z-index: 5;
`;

export const HeaderColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  padding: 4px 0;

  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.sortable {
    cursor: pointer;
  }

  &::after {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &.asc::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='18' viewBox='0 0 14 18' fill='none'%3E%3Cpath d='M14 11L7 18L0 11H14ZM10.375 12.5H3.625L7 15.875L10.375 12.5ZM14 7H0L7 0L14 7Z' fill='%238383AF'/%3E%3C/svg%3E");
  }
  &.desc::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='18' viewBox='0 0 14 18' fill='none'%3E%3Cpath d='M14 11L7 18L0 11H14ZM14 7H0L7 0L14 7ZM10.375 5.5L7 2.125L3.625 5.5H10.375Z' fill='%238383AF'/%3E%3C/svg%3E");
  }
`;

export const Body = styled.tbody`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .NoInformation {
    width: 100%;
    text-align: center;
  }
`;

export const Row = styled.tr<RowProps>`
  display: grid;
  grid-gap: 4px;
  align-items: center;

  border: 1px solid transparent;
  border-radius: 4px;
  padding: 0.5rem 1rem;

  transition: 0.3s;

  &:hover {
    ${({ notClickable }) =>
      notClickable
        ? ''
        : 'background-color: var(--grayscale-4); border: 1px solid var(--grayscale-20); box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12), 0px 2px 6px 2px rgba(0, 0, 0, 0.06);'}
  }

  & div.copy-column {
    display: grid;
    grid-template-columns: 40fr 10fr;
  }

  & div.avatar-column {
    display: grid;
    grid-template-columns: 2rem 1fr;
    grid-gap: 0.5rem;
    align-items: center;

    & .avatar {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 0.875rem;
      font-weight: 700;
      font-family: 'Inter';

      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      border: 1px solid var(--grayscale-10);
    }
  }

  & p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
