import { managerPermissions } from '../../../types/managerPermissions';
import { kubernetesApi } from '../../api';

export async function getPermissionsByUser() {
  try {
    const response = await kubernetesApi.get<{
      data: { permissions: managerPermissions };
    }>(`/manager-broker-permissions/find/by-user`);

    const { permissions } = response.data.data;

    return permissions;
  } catch (err) {
    return { error: err.response.data };
  }
}
