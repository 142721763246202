import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
  LabDsBreadcrumb,
  LabDsButton,
  LabDsCheckbox,
  LabDsDatePicker,
  LabDsSelect,
  LabDsTextField,
} from 'v4web-components-react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import * as S from './styles';
import { createUnitSchema } from './constants/schemas';
import {
  CepInfo,
  UnitCreationData,
  bytesToMB,
  dedicationOptions,
} from './constants/utils';
import { cepApi } from '../../services/api';
import { useToast } from '../../hooks/toast';
import { sendFile } from '../../services/requests/storage-service/sendFile';
import { CreateUnitFederation } from '../../services/requests/Unit/createUnitFederation';

export function CreateUnitPage() {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<UnitCreationData>({ resolver: yupResolver(createUnitSchema) });
  const navigate = useNavigate();
  const [contractName, setContractName] = useState<string>('');
  const MAX_FILE_SIZE = 1000000; // 1MB
  const [mainStakeholder, setMainStakeholder] = useState(1);
  const inputRef = useRef(null);
  const [contractSize, setContractSize] = useState<number>();
  const { addToast } = useToast();
  const createUnitBreadcrumbsItems = [
    {
      label: 'Unidades Franqueadas ',
      link: '/unidades',
      key: 'pageTitle',
      event: () => navigate('/unidades'),
    },
    {
      label: 'Criar nova unidade',
      link: '/unidades/create',
      key: 'pageTitle',
      event: () => '',
    },
  ];

  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file && file.size <= MAX_FILE_SIZE) {
        setValue('contract', file);
        setContractName(file.name);
        setContractSize(file.size);
        const fileSent = await sendFile(file as File);
        setValue('contractUrl', fileSent.url);
      } else {
        const errorMessage =
          'Não foi possível enviar o arquivo, verifique se o arquivo enviado é um PDF e se o tamanho dele é menor ou igual a 1MB';
        addToast({
          type: 'error',
          title: errorMessage,
        });
      }

      e.target.value = '';
    },
    [setValue, addToast],
  );

  const handleDeleteContract = () => {
    setValue('contract', null as unknown as File);
    setContractName('');
    setContractSize(undefined);
  };

  const [partners, setPartners] = useState([
    {
      name: '',
      email: '',
      rg: '',
      cpf: '',
      phone: '',
      isPrincipal: true,
      partnerId: 1,
    },
  ]);

  useEffect(() => {
    setValue(`stakeholders.${0}.isPrincipal`, true);
  }, [setValue]);

  const [accordionsView, setAccordionsView] = useState<Array<boolean>>(
    partners.map(() => true),
  );

  const getCepInfo = async (cep: string) => {
    if (cep.length >= 8) {
      const { data } = await cepApi.get(`/${cep}`);

      const dataFormatted = data as CepInfo;

      const { street, district, city, state } = dataFormatted.result;

      return {
        street,
        district,
        city,
        state,
      };
    }
    return {
      street: '',
      district: '',
      city: '',
      state: '',
    };
  };

  const addPartner = () => {
    setPartners((prevPartners) => [
      ...prevPartners,
      {
        name: '',
        email: '',
        rg: '',
        cpf: '',
        phone: '',
        isPrincipal: false,
        partnerId: Math.floor(Math.random() * 10000),
      },
    ]);
    setAccordionsView((prevAccordions) => [...prevAccordions, true]);
  };

  const removePartner = (partnerId: number) => {
    if (mainStakeholder === partnerId && !partners[0].isPrincipal) {
      const newMainStakeholder = partners[0].partnerId;
      setMainStakeholder(newMainStakeholder);
    }

    const updatedPartners = partners.filter(
      (partner) => partner.partnerId !== partnerId,
    );

    if (mainStakeholder === partnerId && updatedPartners[0]) {
      setMainStakeholder(updatedPartners[0].partnerId);
    }

    setPartners(updatedPartners);
    setValue('stakeholders', updatedPartners);
  };

  const onSubmit: SubmitHandler<UnitCreationData> = useCallback(
    async (data: UnitCreationData) => {
      await createUnitSchema.validate(data, {
        abortEarly: false,
      });

      const body = {
        name: data.name,
        royalties: data.royalties,
        taxRegime: data.taxRegime,
        v4Email: data.v4Email,
        contractUrl: data.contractUrl,
        contractSignatureDate: new Date(data.contractSignatureDate) as Date,
        fantasyName: data.fantasyName,
        socialName: data.socialName,
        cnpj: data.cnpj,
        companyNumber: data.companyNumber,
        address: {
          zipCode: data.address.zipCode,
          state: data.address.state,
          city: data.address.city,
          street: data.address.street,
          district: data.address.district,
          number: String(data.address.number),
          complement: data.address.complement,
        },
        stakeholders: data.stakeholders,
      };

      const response = await CreateUnitFederation(body);
      if (response.error) {
        addToast({
          type: 'error',
          title: `Erro ao criar unidade`,
        });
        return;
      }
      addToast({
        type: 'success',
        title: 'Unidade criada com sucesso!',
      });
      navigate('/unidades');
    },
    [addToast, navigate],
  );

  return (
    <S.Container>
      <LabDsBreadcrumb breadcrumbs={createUnitBreadcrumbsItems} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.Title>Criar nova unidade</S.Title>
        <S.InputContainer>
          <S.InputTitle>Unidade V4</S.InputTitle>
          <S.InputRow>
            <Controller
              name="name"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors.name ? 'error' : 'default'}
                    helperText={errors.name?.message}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Nome da unidade V4*"
                    label="Digite o nome da unidade V4"
                  />
                );
              }}
            />
            <Controller
              name="royalties"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as unknown as string}
                    state={
                      errors.royalties || field.value < 20 || field.value > 100
                        ? 'error'
                        : 'default'
                    }
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    helperText={
                      errors.royalties?.message ||
                      Number(field.value) < 20 ||
                      Number(field.value) > 100
                        ? 'royalties mínimo de 20% e máximo de 100%'
                        : ''
                    }
                    titleInput="royalties*"
                    label="0%"
                    type="number"
                  />
                );
              }}
            />
            <Controller
              name="taxRegime"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsSelect
                    titleInput="Regime tributário*"
                    label="Regime tributário*"
                    avatar={false}
                    state={errors.taxRegime ? 'error' : 'default'}
                    helperText={errors.taxRegime?.message}
                    onChangeInputOptions={({ detail }) =>
                      field.onChange(detail[0].label)
                    }
                    options={dedicationOptions}
                    type="radioButton"
                    size="medium"
                    isSearchable={false}
                  />
                );
              }}
            />
            <Controller
              name="v4Email"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors.v4Email ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="E-mail V4"
                    label="Digite o e-mail do responsável"
                    helperText={errors.v4Email?.message}
                  />
                );
              }}
            />
          </S.InputRow>
          <S.InputSubTitle>Contrato</S.InputSubTitle>
          <S.InputRow>
            <div className="input-contract">
              <Controller
                name="contractSignatureDate"
                control={control}
                render={({ field }) => {
                  return (
                    <LabDsDatePicker
                      value={field.value as Date as unknown as string}
                      state={errors.contractSignatureDate ? 'error' : 'default'}
                      onChangeDatePicker={({ detail }) =>
                        field.onChange(detail)
                      }
                      titleInput="Data da Assinatura*"
                      helperText={errors.contractSignatureDate?.message}
                    />
                  );
                }}
              />
              <LabDsButton
                label={
                  contractName
                    ? `${contractName}`
                    : 'Adicionar contrato assinado'
                }
                variant="link-button"
                stepIcon={contractName ? '' : 'Download'}
                leadingIcon={contractName ? 'file_copy' : ''}
                onHandleButton={() => {
                  if (inputRef.current) {
                    (inputRef.current as HTMLInputElement).click();
                  }
                }}
              />
              <S.HiddenRow>
                <input
                  type="file"
                  id="contract"
                  accept=".pdf"
                  onChange={handleChange}
                  style={{ display: 'none' }}
                  ref={inputRef}
                />
                <span>{contractName ? 'PDF' : ''}</span>
                <span>{contractName ? '•' : ''}</span>
                <span>
                  {contractName
                    ? `${bytesToMB(contractSize || 0).toFixed(2)} MB`
                    : ''}
                </span>
                <LabDsButton
                  label=""
                  variant="link-button"
                  leadingIcon={contractName ? 'delete' : ''}
                  onHandleButton={handleDeleteContract}
                  disabled={!contractName}
                />
              </S.HiddenRow>
            </div>
          </S.InputRow>
          <S.InputTitle>Informações da empresa</S.InputTitle>
          <S.InputSubTitle>Dados da empresa</S.InputSubTitle>
          <S.InputRow>
            <Controller
              name="fantasyName"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors.fantasyName ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Nome fantasia*"
                    label="Digite o nome fantasia"
                    helperText={errors.fantasyName?.message}
                  />
                );
              }}
            />
            <Controller
              name="socialName"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors.socialName ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Razão Social*"
                    label="Digite a razão social"
                    helperText={errors.socialName?.message}
                  />
                );
              }}
            />
            <Controller
              name="cnpj"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors.cnpj ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="CNPJ*"
                    label="00.000.000/0001-00"
                    mask="CNPJ"
                    maxLength={14}
                    helperText={errors.cnpj?.message}
                  />
                );
              }}
            />
            <Controller
              name="companyNumber"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as unknown as string}
                    state={errors.companyNumber ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Contato"
                    label="(00) 0 0000-0000"
                    mask="phone"
                    maxLength={11}
                    helperText={errors.companyNumber?.message}
                  />
                );
              }}
            />
          </S.InputRow>
          <S.InputSubTitle>Localização da unidade</S.InputSubTitle>
          <S.InputRow>
            <Controller
              name="address.zipCode"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as unknown as string}
                    state={errors?.address?.zipCode ? 'error' : 'default'}
                    onChangeInput={async ({ detail }) => {
                      field.onChange(detail);
                    }}
                    titleInput="CEP*"
                    onBlur={async () => {
                      const { city, street, state, district } =
                        await getCepInfo(field.value);

                      setValue('address.state', state);
                      setValue('address.city', city);
                      setValue('address.street', street);
                      setValue('address.district', district);
                    }}
                    label="00000-000"
                    mask="CEP"
                    maxLength={8}
                    helperText={errors?.address?.zipCode?.message}
                  />
                );
              }}
            />
            <Controller
              name="address.state"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors?.address?.state ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Estado*"
                    label=""
                    disabled
                  />
                );
              }}
            />
            <Controller
              name="address.city"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors?.address?.city ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Cidade*"
                    label=""
                    disabled
                  />
                );
              }}
            />
            <Controller
              name="address.street"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors?.address?.street ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Endereço*"
                    label="Digite a Endereço"
                    helperText={errors.address?.street?.message}
                  />
                );
              }}
            />
          </S.InputRow>
          <S.InputRow>
            <Controller
              name="address.district"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors?.address?.district ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Bairro*"
                    label="Digite o bairro"
                    helperText={errors?.address?.district?.message}
                  />
                );
              }}
            />
            <Controller
              name="address.number"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as unknown as string}
                    state={errors?.address?.number ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Número*"
                    label="Digite o número"
                    helperText={errors?.address?.number?.message}
                  />
                );
              }}
            />
            <Controller
              name="address.complement"
              control={control}
              render={({ field }) => {
                return (
                  <LabDsTextField
                    value={field.value as string}
                    state={errors?.address?.complement ? 'error' : 'default'}
                    onChangeInput={({ detail }) => field.onChange(detail)}
                    titleInput="Complemento"
                    label="Digite o complemento"
                  />
                );
              }}
            />
          </S.InputRow>
          <S.PartnerRow>
            <S.InputSubTitle>Dados do Sócio</S.InputSubTitle>
            <LabDsButton
              label="Adicionar sócio"
              variant="outlined"
              stepIcon="add"
              onHandleButton={addPartner}
            />
          </S.PartnerRow>
          {partners.map((partner, index) => (
            <S.AccordionContainer>
              <S.AccordionHeaderContainer
                onClick={() => {
                  const newAccordionsView = [...accordionsView];
                  newAccordionsView[index] = !newAccordionsView[index];
                  setAccordionsView(newAccordionsView);
                }}
              >
                <S.AccordionHeader>
                  <h1>Sócio {[index + 1]}</h1>
                  <span className="material-symbols-outlined">
                    {partner.partnerId === mainStakeholder ? 'License' : ''}
                  </span>
                </S.AccordionHeader>
                <span className="material-symbols-outlined">
                  {accordionsView[index] ? 'expand_less' : 'expand_more'}
                </span>
              </S.AccordionHeaderContainer>
              <S.AccordionContent isVisible={accordionsView[index]}>
                <div slot="body-accordion">
                  <S.InputRow>
                    <Controller
                      name={`stakeholders.${index}.name`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <LabDsTextField
                            value={partners[index].name}
                            state={
                              errors.stakeholders?.[index]?.name
                                ? 'error'
                                : 'default'
                            }
                            onChangeInput={({ detail }) => {
                              field.onChange(detail);
                              setPartners((prevPartners) => {
                                const newPartners = [...prevPartners];
                                newPartners[index].name = detail;
                                return newPartners;
                              });
                            }}
                            titleInput="Nome do sócio*"
                            label="Digite o nome do sócio"
                            helperText={
                              errors?.stakeholders?.[index]?.name?.message
                            }
                          />
                        );
                      }}
                    />
                    <Controller
                      name={`stakeholders.${index}.cpf`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <LabDsTextField
                            value={partners[index].cpf}
                            state={
                              errors.stakeholders?.[index]?.cpf
                                ? 'error'
                                : 'default'
                            }
                            onChangeInput={({ detail }) => {
                              field.onChange(detail);
                              setPartners((prevPartners) => {
                                const newPartners = [...prevPartners];
                                newPartners[index].cpf = detail;
                                return newPartners;
                              });
                            }}
                            titleInput="CPF*"
                            label="000.000.000-00"
                            helperText={
                              errors?.stakeholders?.[index]?.cpf?.message
                            }
                            mask="CPF"
                            maxLength={11}
                          />
                        );
                      }}
                    />
                    <Controller
                      name={`stakeholders.${index}.rg`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <LabDsTextField
                            value={partners[index].rg}
                            onChangeInput={({ detail }) => {
                              field.onChange(detail);
                              setPartners((prevPartners) => {
                                const newPartners = [...prevPartners];
                                newPartners[index].rg = detail;
                                return newPartners;
                              });
                            }}
                            titleInput="RG*"
                            label="000.000.00-0"
                            maxLength={10}
                          />
                        );
                      }}
                    />
                    <Controller
                      name={`stakeholders.${index}.email`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <LabDsTextField
                            value={partners[index].email}
                            state={
                              errors.stakeholders?.[index]?.email
                                ? 'error'
                                : 'default'
                            }
                            onChangeInput={({ detail }) => {
                              field.onChange(detail);
                              setPartners((prevPartners) => {
                                const newPartners = [...prevPartners];
                                newPartners[index].email = detail;
                                return newPartners;
                              });
                            }}
                            titleInput="E-mail pessoal*"
                            label="exemplo@exemplo.com"
                            helperText={
                              errors?.stakeholders?.[index]?.email?.message
                            }
                          />
                        );
                      }}
                    />
                    <Controller
                      name={`stakeholders.${index}.phone`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <LabDsTextField
                            value={partners[index].phone}
                            state={
                              errors.stakeholders?.[index]?.phone
                                ? 'error'
                                : 'default'
                            }
                            mask="phone"
                            maxLength={11}
                            onChangeInput={({ detail }) => {
                              field.onChange(detail);
                              setPartners((prevPartners) => {
                                const newPartners = [...prevPartners];
                                newPartners[index].phone = detail;
                                return newPartners;
                              });
                            }}
                            titleInput="Número do Sócio*"
                            label="(xx) x xxxx-xxxx"
                            helperText={
                              errors?.stakeholders?.[index]?.phone?.message
                            }
                          />
                        );
                      }}
                    />
                  </S.InputRow>
                  <S.MajorPartnerRow>
                    <Controller
                      name={`stakeholders.${index}.isPrincipal`}
                      control={control}
                      render={() => {
                        return (
                          <LabDsCheckbox
                            label="Sócio majoritário"
                            checked={
                              mainStakeholder === partners[index].partnerId
                            }
                            onHandleCheckbox={({ detail }) => {
                              setMainStakeholder(partners[index].partnerId);
                              getValues('stakeholders')?.forEach(
                                (__, stakeholderIndex) => {
                                  if (index !== stakeholderIndex) {
                                    setValue(
                                      `stakeholders.${stakeholderIndex}.isPrincipal`,
                                      false,
                                    );
                                  } else {
                                    setValue(
                                      `stakeholders.${stakeholderIndex}.isPrincipal`,
                                      true,
                                    );
                                  }
                                },
                              );
                              setPartners((prevPartners) => {
                                const newPartners = [...prevPartners];
                                newPartners[index].isPrincipal = detail;
                                return newPartners;
                              });
                            }}
                            disabled={
                              mainStakeholder === partners[index].partnerId
                            }
                          />
                        );
                      }}
                    />
                    <LabDsButton
                      label="Excluir sócio"
                      variant="link-button"
                      stepIcon="delete"
                      onHandleButton={() =>
                        removePartner(partners[index].partnerId)
                      }
                      disabled={partners.length === 1}
                    />
                  </S.MajorPartnerRow>
                </div>
              </S.AccordionContent>
            </S.AccordionContainer>
          ))}
          <S.ButtonsRow>
            <div>
              <LabDsButton
                label="Cancelar"
                variant="danger-outlined"
                type="submit"
                onHandleButton={() => {
                  navigate('/unidades');
                }}
              />
              <LabDsButton
                label="Criar Unidade"
                variant="primary"
                stepIcon="save"
                type="submit"
                onHandleButton={() => {
                  handleSubmit(onSubmit)();
                }}
              />
            </div>
          </S.ButtonsRow>
        </S.InputContainer>
      </form>
    </S.Container>
  );
}
