import { useNavigate } from 'react-router-dom';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { Headline } from '../../../LegacyV4Components';
import * as S from './styles';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';
import { useQueryWallet } from '../../../../services/requests/MediaWallet/getWallet';
import { useGetProductList } from '../../../../services/requests/Finance/getProductList';
import { useQueryGetFinanceCustomer } from '../../../../services/requests/Finance/getCustomer';

export default function CustomerToolsPreview({ customer }: { customer: any }) {
  const navigate = useNavigate();
  const { data } = useQueryWallet(customer?._id);
  const { data: productList } = useGetProductList(customer?._id);
  const { data: bankResponse } = useQueryGetFinanceCustomer(customer?._id);

  return (
    <S.Container>
      <S.HeaderPreview>
        <Headline variant="h5">Ferramentas</Headline>
        <S.EditStakeholder
          onClick={() => navigate(`/cliente/${customer._id}/ferramentas`)}
        >
          Ver tudo <HiOutlineExternalLink />
        </S.EditStakeholder>
      </S.HeaderPreview>
      {bankResponse?.data?.verifiedByProvider === true ? (
        <S.Body>
          <Headline variant="h6">Saldo Media Wallet</Headline>
          <Headline variant="h4">
            {convertNumberToBRL(data?.data ? data?.data?.balance / 100 : 0) ||
              0}
          </Headline>
          <Headline variant="h6">Checkouts gerados no Gateway</Headline>
          <Headline variant="h4">{productList?.length}</Headline>
        </S.Body>
      ) : (
        <S.Body>
          <p className="no-content">
            Você ainda não configurou Gateway de Pagamento e Media Wallet para o
            cliente
          </p>
        </S.Body>
      )}
    </S.Container>
  );
}
