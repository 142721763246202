import { useState, useRef, useCallback } from 'react';
import { FiAlertTriangle, FiArrowDown, FiUsers } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Button } from '../../../Default/Button';
import { Input } from '../../../Default/Input';
import { Menu } from '../../../Default/Menu';
import { Modal } from '../../../Default/Modal';
import { Textarea } from '../../../Default/Textarea';

import * as S from './styles';
import { useToast } from '../../../../hooks/toast';
import { getValidationErrors } from '../../../../utils/getValidationErrors';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';
import { Team } from '../../../../types/team';

interface Props {
  selected?: boolean;
  handleDeleteTeam: (id: string) => void;
  handleTeamEdit: (team: Team) => void;
  team: Team;
}

export function TeamCard({
  selected,
  team,
  handleDeleteTeam,
  handleTeamEdit,
}: Props) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { addToast } = useToast();

  function handleDelete(teamId: string) {
    handleDeleteTeam(teamId);
    setIsDeleteModalOpen(false);
  }

  const teamForm = useRef<FormHandles>(null);

  const editTeam = useCallback(
    async (data: { name: string; description: string }) => {
      try {
        teamForm.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Nome obrigatório.')
            .max(60, 'No máximo 60 dígitos.'),
          description: Yup.string()
            .required('Descrição obrigatório.')
            .max(250, 'No máximo 250 dígitos.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        teamForm.current?.setFieldValue('name', '');
        teamForm.current?.setFieldValue('description', '');

        const editedTeam = {
          id: team.id,
          name: data.name,
          description: data.description,
        };

        handleTeamEdit(editedTeam);
        setIsEditModalOpen(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          teamForm.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro ao atualizar o time, tente novamente',
        });
      }
    },
    [addToast, team, handleTeamEdit],
  );

  return (
    <S.Container selected={selected}>
      <header>
        <FiUsers />
        <h3>{team.name}</h3>
      </header>

      {selected && (
        <>
          <section>
            <strong>NPS Médio:</strong>
            <div>
              <FiArrowDown color="var(--primary-main)" />
              <p>{team.nps}</p>
            </div>
          </section>
          <section>
            <strong>fee Médio:</strong>
            <div>
              <FiArrowDown color="var(--primary-main)" />
              <p>{team.fee && convertNumberToBRL(team.fee)}</p>
            </div>
          </section>
          <section>
            <strong>ltv Médio:</strong>
            <div>
              <FiArrowDown color="var(--primary-main)" />
              <p>{team.ltv && convertNumberToBRL(team.ltv)}</p>
            </div>
          </section>
        </>
      )}

      {!selected && (
        <Menu>
          <p aria-hidden onClick={() => setIsDeleteModalOpen(true)}>
            Excluir
          </p>

          <Modal modalOpen={isDeleteModalOpen}>
            <S.DeleteForm>
              <FiAlertTriangle />

              <h3>Você está prestes a deletar um time</h3>

              <p>Tem certeza que você deseja excluir o Time “{team.name}”?</p>

              <footer>
                <Button
                  onClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                  small
                  secondary
                >
                  Cancelar
                </Button>
                <Button small onClick={() => handleDelete(team.id)}>
                  Excluir
                </Button>
              </footer>
            </S.DeleteForm>
          </Modal>

          <p aria-hidden onClick={() => setIsEditModalOpen(true)}>
            Editar
          </p>

          <Modal modalOpen={isEditModalOpen}>
            <S.Form ref={teamForm} onSubmit={editTeam}>
              <h3>Cadastrar novo time</h3>

              <p>Nome do time</p>
              <Input
                defaultValue={team.name}
                placeholder="Digite o nome do time"
                name="name"
              />

              <p>Descrição</p>
              <Textarea
                defaultValue={team.description}
                placeholder="Dê uma descrição ao time Ex:  Foco no desenvolvimento de um produto que atenda dores dos clientes alinhados com os objetivos da empresa"
                name="description"
              />

              <footer>
                <Button
                  onClick={() => setIsEditModalOpen(!isEditModalOpen)}
                  small
                  secondary
                >
                  Cancelar
                </Button>
                <Button small type="submit">
                  Salvar
                </Button>
              </footer>
            </S.Form>
          </Modal>
        </Menu>
      )}
    </S.Container>
  );
}
