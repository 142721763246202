import { kubernetesApi as api } from '../../api';

export async function grantUnitToBuyBlackBox(data: GrantUnitToBuyBlackBox) {
  try {
    const response = await api.post(
      `/manager-broker-permissions/grant-unit-to-black-box`,
      { ...data },
    );
    const unit = response.data;
    return unit;
  } catch (err) {
    console.error(
      `Ocorreu algum problema, atualize a página e tente novamente 😕`,
      err,
    );
    return err.response.data.status ? 'error' : err.response.data.message;
  }
}
