import { LabDsTable } from 'v4web-components-react';
import { IData } from 'v4web-components';
import { useMemo } from 'react';
import { Paragraph } from '../../../components/LegacyV4Components';
import * as S from '../styles';
import { InvestorTeam } from '../../../hooks/types';

export function ListTeam({ ...data }: { data: InvestorTeam[] }) {
  const headers: IData['headers'] = [
    {
      title: 'Investidor',
      colKey: 'name',
      type: 'text',
    },
    {
      title: 'Cargo',
      colKey: 'mandate',
      type: 'text',
    },
    {
      title: 'Senioridade',
      colKey: 'seniority',
      type: 'text',
    },
    {
      title: 'Capacidade',
      colKey: 'capacity',
      type: 'text',
    },
    {
      title: 'Dedicação',
      colKey: 'dedication',
      type: 'text',
    },
  ];
  const rows: IData['rows'] = useMemo(() => {
    const rowsData: IData['rows'] = [];
    if (data) {
      data?.data?.forEach((currentUser: InvestorTeam) => {
        rowsData.push({
          name: {
            text: {
              title: currentUser.user.name,
              avatar: true,
              avatarImgSRC: currentUser.user.picture,
            },
          },
          mandate: {
            text: {
              title: currentUser.user.mandate?.name,
            },
          },
          seniority: {
            text: {
              title: currentUser.user.seniority?.name,
            },
          },
          capacity: {
            number: {
              title: String(
                currentUser.user.capacity
                  ? `${currentUser.user.capacity?.currentWorkload} / ${currentUser.user.capacity?.maxWorkload}`
                  : '-',
              ),
            },
          },
          dedication: {
            text: {
              title: currentUser.isDedicated ? 'Dedicado' : 'Compartilhado',
            },
          },
        });
      });
    }
    return rowsData;
  }, [data]);

  return (
    <>
      <LabDsTable data={{ headers, rows }} />
      {data?.data?.length === 0 && (
        <S.EmptyTable>
          <Paragraph>Nenhum investidor cadastrado</Paragraph>
        </S.EmptyTable>
      )}
    </>
  );
}
