import { useState } from 'react';
import { Button, ModalRight } from '../../../LegacyV4Components';
import { updateCustomerAvatar } from '../../../../services/requests/Customers/updateCustomerAvatar';
import { updateCustomerColor } from '../../../../services/requests/Customers/updateCustomerColor';
import { ColorPicker } from '../ColorPicker';
import { DragNDrop } from '../FileDragAndDrop/index';

import * as S from './styles';

interface CustomerInterface {
  customer: { _id: string; hexColor: string };
  isOpen: boolean;
  setIsOpen: () => void;
}

export function CustomerThemeModal({
  customer,
  isOpen,
  setIsOpen,
}: CustomerInterface) {
  const [color, setColor] = useState(customer?.hexColor || '#00C49A');
  const [file, setFile] = useState<File>();

  async function handleUpdate() {
    if (file && color !== customer?.hexColor) {
      await updateCustomerAvatar(customer?._id, file);
      await updateCustomerColor(customer?._id, color);
      setIsOpen();
    }

    if (color !== customer?.hexColor) {
      await updateCustomerColor(customer?._id, color);
      setIsOpen();
    }

    if (file) {
      await updateCustomerAvatar(customer?._id, file);
      setIsOpen();
    }
  }

  return (
    <ModalRight
      show={isOpen}
      header="Personalizar Layout"
      closeButton
      size="md"
      onBackground={setIsOpen}
    >
      <S.Container>
        <S.ColorPicker>
          <h4>Cor do tema do gateway</h4>
          <ColorPicker color={color} setColor={setColor} />
        </S.ColorPicker>

        <S.ImageDropContainer>
          <h4>Adicionar logo do cliente</h4>
          <DragNDrop setFile={setFile} file={file} />
        </S.ImageDropContainer>

        <S.ButtonContainer>
          <Button
            color="green"
            variant="primary"
            onClick={handleUpdate}
            size="default"
            disabled={!file && color === customer.hexColor}
          >
            Salvar
          </Button>
        </S.ButtonContainer>
      </S.Container>
    </ModalRight>
  );
}
