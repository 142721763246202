import React, { useState } from 'react';

import {
  FiShoppingCart,
  FiCreditCard,
  FiPlus,
  FiMinus,
  FiInfo,
} from 'react-icons/fi';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';

import { Box } from '@mui/material';
import { Button, Headline, Smalled } from '../../../LegacyV4Components';
import { Modal } from '../../../Default/Modal';
import { FormikCurrencyInput } from '../../molecules/FormikCurrencyInput';

import * as S from './styles';
import { Tooltip } from '../../../Default/Tooltip';
import { SelectSearch } from '../../atoms/SelectSearch';
import { CheckoutProps, ResumeCheckoutHQModal } from '../ResumeCheckoutHQModal';

export function GenerateCheckoutHQModal({
  isShown,
  setIsShown,
  productByType,
}: {
  isShown: boolean;
  setIsShown: (value: React.SetStateAction<boolean>) => void;
  productByType: any;
}) {
  const [installments, setIsInstallments] = useState(1);
  const [creditChecked, setCreditCheck] = useState(false);
  const [boletoChecked, setBoletoCheck] = useState(false);
  const [pixChecked, setPixCheck] = useState(false);
  const [isOpenResume, setIsOpenResume] = useState(false);
  const [clientV4, setIsClientV4] = useState('customer');
  const GenerateSchema = Yup.object().shape({
    products: Yup.array(
      Yup.object().shape({
        value: Yup.number()
          .notOneOf([0], 'Número precisa ser maior que zero')
          .required('O campo é obrigatório!'),
        id: Yup.string().required('O campo é obrigatório!'),
      }),
    ),
    payableWith: Yup.array()
      .of(Yup.string().required())
      .required('O campo é obrigatório!'),
    feeResponsible: Yup.string().required('O campo é obrigatório!'),
    creditCardInstallments: Yup.number().required('O campo é obrigatório!'),
  });
  return (
    <>
      <Formik
        initialValues={{
          products: [{ id: '', value: '' }],
          payableWith: [''],
          feeResponsible: `${clientV4}`,
          creditCardInstallments: installments,
        }}
        validationSchema={GenerateSchema}
        onSubmit={async () => {
          setIsOpenResume(true);
        }}
      >
        {({ values, setFieldValue, isValid, dirty }) => (
          <>
            <>
              {isOpenResume ? (
                <>
                  <ResumeCheckoutHQModal
                    isShown={isOpenResume}
                    setIsOpenResume={setIsOpenResume}
                    setIsShown={setIsShown}
                    data={values as unknown as CheckoutProps}
                  />
                </>
              ) : null}
            </>
            <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
              <S.ModalWrapper>
                <S.ModalHeader>
                  <Headline variant="h4">Gerar checkout</Headline>
                  <button
                    type="button"
                    onClick={() => setIsShown(false)}
                    aria-label="default"
                  >
                    <FiX />
                  </button>
                </S.ModalHeader>
                <Form>
                  <S.ModalBody>
                    <S.SectionGenerate>
                      <Headline variant="h5">
                        <FiShoppingCart />
                        Cadastrar produto
                      </Headline>
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '1.875rem',
                          marginBottom: '48px',
                          gap: '52px',
                        }}
                        className="containerProduct"
                      >
                        <Box sx={{ minWidth: '230px' }}>
                          <h4 style={{ marginBottom: '1rem' }}>Produto</h4>
                          <SelectSearch
                            value={values?.products[0]?.id}
                            setValue={(value) =>
                              setFieldValue('products[0].id', value)
                            }
                            placeholder="Nome do produto"
                            options={
                              productByType !== undefined
                                ? productByType?.map((item: any) => ({
                                    value: item?._id,
                                    label: item?.name,
                                  }))
                                : []
                            }
                            notSearch
                          />
                        </Box>
                        <FormikCurrencyInput
                          label="Valor"
                          onValueChange={(value) => {
                            setFieldValue('products[0].value', value);
                            setFieldValue('products[0].value', value);
                          }}
                        />
                      </div>
                      <button type="button" style={{ display: 'none' }}>
                        Enviar
                      </button>
                    </S.SectionGenerate>
                    <S.SectionGenerate>
                      <Headline variant="h5">
                        <FiCreditCard />
                        Habilitar formas de pagamento
                      </Headline>
                      <S.ContainerProductType>
                        <S.ContainerCheckbox
                          color={pixChecked ? '1px solid #00C49A' : ''}
                        >
                          <input
                            type="checkbox"
                            name="payableWith"
                            value="pix"
                            onChange={(e) => {
                              const { checked } = e.target;
                              if (values.payableWith[0] === '') {
                                values.payableWith.splice(0, 1);
                              }
                              const valueIncluded =
                                values.payableWith.includes('pix');
                              if (valueIncluded && !checked) {
                                setPixCheck(false);
                                const position = values.payableWith.indexOf(
                                  e.target.value,
                                );
                                values.payableWith.splice(position, 1);
                              }
                              if (!valueIncluded && checked) {
                                setPixCheck(true);
                                setFieldValue('payableWith', [
                                  ...values.payableWith,
                                  e.target.value,
                                ]);
                              }
                            }}
                          />
                          <Headline variant="h5">PIX</Headline>
                        </S.ContainerCheckbox>
                        <S.ContainerCheckbox
                          color={boletoChecked ? '1px solid #00C49A' : ''}
                        >
                          <input
                            type="checkbox"
                            name="payableWith"
                            value="bank_slip"
                            onChange={(e) => {
                              const { checked } = e.target;
                              if (values.payableWith[0] === '') {
                                values.payableWith.splice(0, 1);
                              }
                              const valueIncluded =
                                values.payableWith.includes('bank_slip');
                              if (valueIncluded && !checked) {
                                setBoletoCheck(false);
                                const position = values.payableWith.indexOf(
                                  e.target.value,
                                );
                                values.payableWith.splice(position, 1);
                              }
                              if (!valueIncluded && checked) {
                                setBoletoCheck(true);
                                setFieldValue('payableWith', [
                                  ...values.payableWith,
                                  e.target.value,
                                ]);
                              }
                            }}
                          />
                          <Headline variant="h5">Boleto</Headline>
                        </S.ContainerCheckbox>
                        <S.ContainerCheckbox
                          color={creditChecked ? '1px solid #00C49A' : ''}
                        >
                          <input
                            type="checkbox"
                            name="payableWith"
                            value="credit_card"
                            onChange={(e) => {
                              const { checked } = e.target;
                              if (values.payableWith[0] === '') {
                                values.payableWith.splice(0, 1);
                              }
                              const valueIncluded =
                                values.payableWith.includes('credit_card');
                              if (valueIncluded && !checked) {
                                setCreditCheck(false);
                                const position = values.payableWith.indexOf(
                                  e.target.value,
                                );
                                values.payableWith.splice(position, 1);
                              }
                              if (!valueIncluded && checked) {
                                setCreditCheck(true);
                                setFieldValue('payableWith', [
                                  ...values.payableWith,
                                  e.target.value,
                                ]);
                              }
                            }}
                          />
                          <div style={{ flexDirection: 'column' }}>
                            <Headline variant="h5">Cartão</Headline>
                            <div className="ContainerInstallments">
                              <p>n° de parcelas</p>
                              <div className="ContainerSelectNumber">
                                <S.DecrescentNumber
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsInstallments(installments - 1);
                                    setFieldValue(
                                      'creditCardInstallments',
                                      installments - 1,
                                    );
                                  }}
                                  disabled={installments === 0}
                                >
                                  <FiMinus />
                                </S.DecrescentNumber>
                                <input type="number" value={installments} />
                                <S.CrescentNumber
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsInstallments(installments + 1);
                                    setFieldValue(
                                      'creditCardInstallments',
                                      installments + 1,
                                    );
                                  }}
                                  disabled={installments === 12}
                                >
                                  <FiPlus />
                                </S.CrescentNumber>
                              </div>
                            </div>
                          </div>
                        </S.ContainerCheckbox>
                      </S.ContainerProductType>
                    </S.SectionGenerate>
                    {installments === 13 ? (
                      <S.SectionGenerate>
                        <Headline variant="h5">
                          {/* <Fi /> */}
                          Juros
                        </Headline>
                        <Smalled>
                          Quem irá assumir os juros das parcelas do cartão de
                          credito?
                          <Tooltip
                            info
                            title="Valor de juros referente para quando o pagamento não ocorrer até a data de vencimento. O valor de juros é de 2% ao mês."
                            className="TooltipCheckout"
                          >
                            <FiInfo />
                          </Tooltip>
                        </Smalled>
                        <S.ContainerProductType>
                          <div
                            style={{ marginBottom: '1.5rem', display: 'flex' }}
                          >
                            <S.ContainerRadio
                              color={
                                clientV4 === 'customer'
                                  ? '1px solid #00C49A'
                                  : ''
                              }
                            >
                              <input
                                value="customer"
                                type="radio"
                                checked={clientV4 === 'customer'}
                                onChange={(e) => setIsClientV4(e.target.value)}
                              />
                              <Headline variant="h6">Cliente V4</Headline>
                            </S.ContainerRadio>
                            <S.ContainerRadio
                              color={
                                clientV4 !== 'customer'
                                  ? '1px solid #00C49A'
                                  : ''
                              }
                            >
                              <input
                                value="clientfinal"
                                type="radio"
                                checked={clientV4 !== 'customer'}
                                onChange={(e) => setIsClientV4(e.target.value)}
                              />
                              <Headline variant="h6">Cliente final</Headline>
                            </S.ContainerRadio>
                          </div>
                        </S.ContainerProductType>
                      </S.SectionGenerate>
                    ) : (
                      ''
                    )}
                  </S.ModalBody>
                </Form>
                <S.ModalFooter>
                  <Button
                    variant="primary"
                    color="green"
                    size="default"
                    onClick={async () => {
                      setIsOpenResume(true);
                      setIsShown(false);
                    }}
                    disabled={!(isValid && dirty)}
                  >
                    Prosseguir para tela de resumo
                  </Button>
                  <Button
                    variant="secondary"
                    color="red"
                    size="default"
                    onClick={() => setIsShown(false)}
                  >
                    Cancelar
                  </Button>
                </S.ModalFooter>
              </S.ModalWrapper>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
}
