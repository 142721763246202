export const mediaPlatformsDictionary = {
  FACEBOOK_ADS: 'Facebook Ads',
  GOOGLE_ADS: 'Google Ads',
  LANDING_PAGE: 'Unbounce',
  ACTIVE_CAMPAIGN: 'Active Campaign',
  GOOGLE_ANALYTICS_UNIVERSAL: 'Google Analytics',
  GOOGLE_ANALYTICS_4: 'Google Analytics',
  TRAY_ECOMMERCE: 'Tray',
  KOMMO_CRM: 'Kommo CRM',
  VTEX: 'Vtex',
  TIKTOK_ADS: 'TikTok Ads',
  BITRIX: 'Bitrix24',
  RDSTATION: 'RD Station CRM',
  LOJA_INTEGRADA: 'Loja Integrada',
  PIPEDRIVE: 'Pipedrive',
};
