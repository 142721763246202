import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
`;

export const FrameWrapper = styled.div`
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-grow: 1;
  background-color: var(--white);

  iframe {
    border: none;
    flex: 1;
  }
`;
