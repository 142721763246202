import styled from 'styled-components';

interface ProfileProps {
  width: string;
  height: string;
}

export const Profile = styled.div<ProfileProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: 50%;
    object-fit: cover;
    background: var(--tertiary-dark);
  }

  div {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: 50%;
    border: 2px solid var(--grayscale-2);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ImagePlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--white);
  background: var(--primary-dark);

  width: 8rem;
  height: 8rem;
  border-radius: 50%;

  p {
    font-size: 0.5rem;
  }
`;
