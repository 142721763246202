import styled from 'styled-components';

export const Container = styled.div`
  height: 22rem;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  padding: 0.5rem;

  .button {
    text-align: end;
  }
`;

export const InputField = styled.input<{ error?: boolean }>`
  width: 25rem;
  height: 3rem;
  border-radius: 0.25rem;
  border: 1px solid
    ${({ error }) => (error ? 'var(--error)' : 'var(--secondary-light)')};

  padding: 1rem 0.75rem;
`;

export const TermsContainer = styled.div`
  width: 100%;
  background-color: var(--lab-ds-core-color-red-100);

  margin: 1rem 0;
  padding: 0.5rem;

  border-radius: 0.25rem;

  a {
    color: var(--success-3);
    text-decoration: underline;
  }
`;
