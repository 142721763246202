export function reorganizeStakeholders(
  stakeholders: Stakeholder[],
): Stakeholder[] {
  const mainStakeholder = stakeholders.findIndex(
    (item) => item.role === 'Stakeholder principal',
  );

  if (mainStakeholder !== -1) {
    const responsavelPrincipal = stakeholders.splice(mainStakeholder, 1)[0];
    stakeholders.unshift(responsavelPrincipal);
  }

  return stakeholders;
}
