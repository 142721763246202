// Generated with util/create-component.js
import React from 'react';

import * as S from './styles';

export interface SectionTitleProps {
  text: string;
  textColor?: string;
}

export function SectionTitle({ text, textColor }: SectionTitleProps) {
  return (
    <S.TitleContainer data-testid="SectionTitle" color={textColor}>
      <S.Title textColor={textColor}>{text}</S.Title>
    </S.TitleContainer>
  );
}
