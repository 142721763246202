import { ReactNode } from 'react';

import * as S from './styles';

interface TooltipProps {
  title: string;
  className?: string;
  children: ReactNode;
  info?: boolean;
  leftContainer?: boolean;
  leftBefore?: boolean;
  bonus?: boolean;
  extract?: boolean;
}

export function Tooltip({
  title,
  className,
  children,
  info,
  leftContainer,
  leftBefore,
  bonus,
  extract,
}: TooltipProps) {
  return (
    <S.Container
      className={className}
      info={info}
      leftContainer={leftContainer}
      leftBefore={leftBefore}
      bonus={bonus}
      extract={extract}
    >
      {children}
      <span>{title}</span>
    </S.Container>
  );
}
