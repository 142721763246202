import styled from 'styled-components';

export const Container = styled.div`
  width: 100% - 3rem;
  > section {
    background: var(--white);
    max-width: 87.5rem;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 0.5rem;

    h1 {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 2.5rem;
    }
  }
`;

export const Paragraph = styled.div`
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 2.5rem;
`;
export const Select = styled.div`
  margin: 2.5rem 0;
  width: 17.4rem;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1rem;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    margin-left: 1rem;
  }
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 68.75rem;
  background: var(--white);
  overflow-x: auto;
  min-width: 100%;
`;

export const RowTable = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const Avatar = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  width: 2rem !important;
  height: 2rem !important;
  width: auto;
  height: auto;
  background: var(--primary-dark);
  margin-right: 0.313rem;
  cursor: pointer;
`;

export const Field = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;

  img {
    margin-right: 0.5rem;
  }

  svg {
    cursor: pointer;
  }
`;

export const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-between;
  margin: 2.5rem 0;

  button {
    margin: 0 !important;
  }
`;

export const Action = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 0.5rem;
  margin-left: 3.125rem;

  button:first-of-type {
    margin-right: 1rem;
  }
`;

export const EmptyTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
`;
