import { Link } from 'react-router-dom';

import * as S from './styles';

interface IntegrationIntroProps {
  description: string[];
  extraction: string[];
  requirements: string[];
  requirementsLinks?: { label: string; link: string }[];
}

export function IntegrationIntro({
  description,
  extraction,
  requirements,
  requirementsLinks,
}: IntegrationIntroProps) {
  return (
    <>
      <S.Description>
        <h4>Descrição</h4>
        {description.map((text) => (
          <p>{text}</p>
        ))}
      </S.Description>

      <S.Info>
        {extraction.length && (
          <>
            <h4>Possibilidade de extração de dados</h4>
            {extraction.map((text) => (
              <li>{text}</li>
            ))}
          </>
        )}
        {requirements.length && (
          <>
            <h4>Pré-requisitos</h4>
            {requirements.map((text) => (
              <li>{text}</li>
            ))}
            {requirementsLinks && requirementsLinks.length && (
              <>
                {requirementsLinks.map((l) => (
                  <Link to={l.link} target="_blank">
                    <S.HelpLink>{l.label}</S.HelpLink>
                  </Link>
                ))}
              </>
            )}
          </>
        )}
      </S.Info>
    </>
  );
}
