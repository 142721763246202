import { useQuery } from '@tanstack/react-query';

import { SelectValue } from '../../../components/AtomicDesign/atoms/SelectQuery';

function useQueryInitialValue<T>(
  id: string,
  fetchInitialValue?: () => Promise<SelectValue<T> | undefined>,
) {
  async function handleFetchInitialValue() {
    if (fetchInitialValue) {
      return fetchInitialValue();
    }
    return undefined;
  }

  return useQuery(['select-initial', id], handleFetchInitialValue);
}

export { useQueryInitialValue };
