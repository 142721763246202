import { useCallback } from 'react';
import { FiTrash } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../hooks/auth';
import { User } from '../../../../hooks/types';

import * as S from './styles';

export function TableUser({ user }: { user: User }) {
  const handleGetUserPicture = useCallback(() => {
    if (user?.picture) {
      return <img src={user.picture} alt={user?.name} />;
    }

    if (user?.name?.split(' ')[0].length > 10) {
      return <h1> </h1>;
    }

    return <h1>{user?.name?.split(' ')[0]}</h1>;
  }, [user]);

  const { user: loggedUser } = useAuth();

  return (
    <S.Row
      key={user._id}
      disableHover={loggedUser.franchiseAdmin || loggedUser._id === user._id}
    >
      {loggedUser.franchiseAdmin || loggedUser._id === user._id ? (
        <Link to={`/perfil/${user._id}`}>
          <S.Field style={{ flex: '1' }}>
            {handleGetUserPicture()}
            {user?.name}
          </S.Field>
          <S.Field style={{ flex: '1' }}>{user?.email}</S.Field>
        </Link>
      ) : (
        <>
          <S.Field style={{ flex: '1' }}>
            {handleGetUserPicture()}
            {user?.name}
          </S.Field>
          <S.Field style={{ flex: '1' }}>{user?.email}</S.Field>
        </>
      )}
      <S.Field trash>
        <FiTrash color="var(--primary-main)" />
      </S.Field>
    </S.Row>
  );
}
