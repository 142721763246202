import styled from 'styled-components';

export const Container = styled.div``;

export const ColorPicker = styled.section`
  display: flex;
  align-items: center;
  min-width: 20rem;
  position: relative;
  justify-content: space-between;
  ::after {
    content: '';
    width: 100%;
    border-bottom: solid 1px var(--tertiary-dark);
    position: absolute;
    left: 0;
    bottom: -1rem;
    z-index: 1;
  }
`;

export const ImageDropContainer = styled.div`
  display: flex;

  flex-direction: column;
  min-width: 20rem;

  h4 {
    margin-top: 2rem;
  }
`;

export const ButtonContainer = styled.section`
  display: flex;
  justify-content: end;
  margin-top: 1.5rem;
`;
