import { useState } from 'react';
import { FiLink2 } from 'react-icons/fi';
import { DotMenu, Headline, Tooltip } from '../../../LegacyV4Components';
import { useToast } from '../../../../hooks/toast';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';
import { ActivePlanModal } from '../ActivePlanModal';
import { CancelPlanModal } from '../CancelPlanModal';
import { DetailsPlanModal } from '../DetailsPlanModal';
import { EditPlanModal } from '../EditPlanModal';
import { HistoricPlanModal } from '../HistoricPlanModal';
import * as S from './styles';

export default function CardSubscription({ subscriptions }: any) {
  const [showEditPlanModal, setShowEditPlanModal] = useState(false);
  const [showHistoricPlanModal, setShowHistoricPlanModal] = useState(false);
  const [showDetailsPlanModal, setShowDetailsPlanModal] = useState(false);
  const [showCancelPlanModal, setShowCancelPlanModal] = useState(false);
  const [showActivePlanModal, setShowActivePlanModal] = useState(false);

  const { addToast } = useToast();

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);

    addToast({
      type: 'success',
      title: 'Copiado para a área de transferência!',
    });
  }

  return (
    <div key={subscriptions._id}>
      <S.Container>
        <S.ContainerHead>
          <S.ContainerTitle color="1">
            <Headline variant="h6" className="title">
              {subscriptions.name}
              {subscriptions.paymentUrl ? (
                <S.ButtonCopyLink
                  onClick={() => {
                    copyToClipboard(subscriptions.paymentUrl);
                  }}
                >
                  <Tooltip title="Copiar link de compartilhamento">
                    <FiLink2 />
                  </Tooltip>
                </S.ButtonCopyLink>
              ) : null}
            </Headline>
            {subscriptions.plan ? (
              <p>
                {convertNumberToBRL(subscriptions.plan.valueCents)} -{' '}
                {subscriptions.plan?.intervalType === 'months'
                  ? 'mensal'
                  : 'semanal'}{' '}
                - Cartão
              </p>
            ) : null}
          </S.ContainerTitle>
          <S.DotMenu>
            {subscriptions.status === 'pending' ? (
              <S.Status color="#FABC2A">Pagamento pendente</S.Status>
            ) : (
              <S.Status color="#00C49A">Pago</S.Status>
            )}
            {/* Comentado pois ainda não estão disponíveis as funções */}
            <div style={{ display: 'none' }}>
              <DotMenu
                reversed
                options={
                  !subscriptions.active
                    ? [
                        {
                          name: 'Ver detalhes',
                          action: () => setShowDetailsPlanModal(true),
                        },
                        {
                          name: 'Editar',
                          action: () => setShowEditPlanModal(true),
                        },
                        {
                          name: 'Histórico de edição',
                          action: () => setShowHistoricPlanModal(true),
                        },
                        {
                          name: 'Cancelar',
                          action: () => setShowCancelPlanModal(true),
                        },
                      ]
                    : [
                        {
                          name: 'Histórico de edição',
                          action: () => setShowHistoricPlanModal(true),
                        },
                        {
                          name: 'Ativar',
                          action: () => setShowActivePlanModal(true),
                        },
                      ]
                }
              />
            </div>
          </S.DotMenu>
        </S.ContainerHead>
      </S.Container>
      <DetailsPlanModal
        isShown={showDetailsPlanModal}
        setIsShown={setShowDetailsPlanModal}
        data={subscriptions}
      />
      <EditPlanModal
        isShown={showEditPlanModal}
        setIsShown={setShowEditPlanModal}
        data={subscriptions}
      />
      <HistoricPlanModal
        isShown={showHistoricPlanModal}
        setIsShown={setShowHistoricPlanModal}
        data={subscriptions}
      />
      <CancelPlanModal
        isShown={showCancelPlanModal}
        setIsShown={setShowCancelPlanModal}
        data={subscriptions}
      />
      <ActivePlanModal
        isShown={showActivePlanModal}
        setIsShown={setShowActivePlanModal}
        data={subscriptions}
      />
    </div>
  );
}
