import { useState, useCallback } from 'react';

import * as S from './styles';

interface ClientTabsProps {
  tabs: string[];
  onChange: (newValue: string) => void;
}

export function ClientTabs({ tabs, onChange }: ClientTabsProps) {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const handleTabChange = useCallback(
    (tabName: string) => {
      setSelectedTab(tabName);
      onChange(tabName);
    },
    [onChange],
  );

  return (
    <S.Container>
      {tabs.map((tab) => (
        <section
          aria-hidden
          className={tab === selectedTab ? 'selected' : ''}
          onClick={() => handleTabChange(tab)}
          key={tab}
        >
          <span>{tab}</span>
        </section>
      ))}
    </S.Container>
  );
}
