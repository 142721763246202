import * as Yup from 'yup';

export const paymentAccountCreationSchema = Yup.object().shape({
  unit_id: Yup.string().required(),
  company_name: Yup.string().when('person_type', {
    is: 'Pessoa Jurídica',
    then: Yup.string().required('Campo Obrigatório'),
    otherwise: Yup.string().nullable(true),
  }),
  cnpj: Yup.string().when('person_type', {
    is: 'Pessoa Jurídica',
    then: Yup.string().required('Campo Obrigatório'),
    otherwise: Yup.string().nullable(true),
  }),
  address: Yup.string().required('Campo Obrigatório'),
  cep: Yup.string().required('Campo Obrigatório'),
  city: Yup.string().required('Campo Obrigatório'),
  district: Yup.string().nullable(true),
  state: Yup.string().required('Campo Obrigatório'),
  telephone: Yup.string().required('Campo Obrigatório'),
  bank: Yup.string().required('Campo Obrigatório'),
  bank_ag: Yup.string().required('Campo Obrigatório'),
  account_type: Yup.string().required('Campo Obrigatório'),
  bank_cc: Yup.string().required('Campo Obrigatório'),
  resp_name: Yup.string().when('person_type', {
    is: 'Pessoa Jurídica',
    then: Yup.string().required('Campo Obrigatório'),
    otherwise: Yup.string().nullable(true),
  }),
  physical_products: Yup.boolean().required('Campo Obrigatório'),
  person_type: Yup.string()
    .required()
    .oneOf(['Pessoa Jurídica', 'Pessoa Física']),
  automatic_transfer: Yup.boolean().required('Campo Obrigatório'),
});
