import styled from 'styled-components';

export const DataGrid = styled.div`
  .hidden {
    max-width: 13rem;
    overflow: hidden;
    .max-workload {
      display: flex;
      justify-content: center;
    }
  }
`;

export const Avatar = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  width: 2rem !important;
  height: 2rem !important;
  background: var(--primary-dark);
  margin-right: 5px;
`;

export const Infomation = styled.div`
  .icon {
    display: flex;
    align-items: center;
  }

  .message {
    display: none;
  }
  :hover {
    cursor: pointer;
    .message {
      display: inline-block;
      position: relative;
      top: -2.5rem;
      p {
        display: inline-block;
        position: absolute;
        min-width: fit-content;
        background-color: white;
        border: solid 1px var(--grayscale-4);
        padding: 0.2rem 0.5rem;
        left: 95%;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
  :first-of-type {
    font-size: 1rem;
    line-height: 1.313rem;
  }
  a {
    font-weight: bold;
    text-decoration: underline;

    display: flex;
    align-items: center;

    svg {
      margin-right: 0.625rem;
    }
  }
`;
export const Text = styled.div`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
`;

export const RowTable = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 15rem;
`;

export const Action = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 1rem;
  margin-left: 3.125rem;

  .Link {
    text-decoration: none;
    align-items: start;
    width: 200px;
    height: 45px;
    margin-right: 1rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;

  margin-top: 2.5rem;
  div {
    width: 23.125rem;
  }
`;

export const Input = styled.div`
  p {
    font-weight: bold;
  }
`;

export const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-between;
  margin: 2.5rem 0;

  button {
    margin: 0 !important;
  }
`;
