import styled, { css } from 'styled-components';

import { Tooltip } from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;

  background: var(--white);
  border: 2px solid #e7e7e7;
  color: #818181;
  border-radius: 0.25rem;
  padding: 1rem;
  width: 100%;
  transition: 0.5s;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.04));

  input {
    flex: 1;
    border: 0;
    background: transparent;
    color: #818181;
    color: #818181 !important;

    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;

    &::placeholder {
      color: var(--grayscale-40);
    }

    &:focus {
      outline: 0;
    }
  }

  & + div {
    margin-top: 1rem;
  }

  svg {
    margin-left: 1rem;
  }

  :hover {
    background: var(--white);
    border-color: var(--primary-dark);
  }

  p {
    margin-right: 0.5rem;
    color: var(--white);
  }

  ${(props) =>
    props.isFocused &&
    css`
      background: #e7e7e7 !important;
      color: #818181;
      border-color: var(--primary-dark);
    `}

  ${(props) =>
    props.isFilled &&
    css`
      input {
        color: #818181;
      }
    `}

    ${(props) =>
    props.isErrored &&
    css`
      color: var(--error);
      border-color: var(--error);
    `}

  input:-webkit-autofill::first-line,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px var(--white) inset !important;
    -webkit-text-fill-color: #818181 !important;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
  }
`;

export const Error = styled(Tooltip)`
  display: flex;
  align-items: center;
  margin-left: 1rem;

  svg {
    margin: 0;
  }

  span {
    background: var(--primary-main);
    color: var(--white);

    &::before {
      border-color: var(--primary-main) transparent;
    }
  }
`;
