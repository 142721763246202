import * as Yup from 'yup';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import {
  FiCreditCard,
  FiFile,
  FiMapPin,
  FiShoppingCart,
  FiUser,
} from 'react-icons/fi';

import { parseCookies } from 'nookies';
import { Box } from '@mui/material';
import { IoTicketOutline } from 'react-icons/io5';
import * as S from './styles';
import { Headline } from '../../components/LegacyV4Components';
import v4CompanyLogo from '../../assets/logos/v4-company-logo.svg';
import { AboutYou } from '../../components/AtomicDesign/templates/AboutYou';
import { MethodPayment } from '../../components/AtomicDesign/templates/MethodPayment';
import { ResumePayment } from '../../components/AtomicDesign/templates/ResumePayment';
import { AddressPayment } from '../../components/AtomicDesign/templates/AddressPayment';
import { convertNumberToBRL } from '../../utils/convertNumberToBRL';
import { CustomerFinishPayment } from '../../components/AtomicDesign/templates/CustomerFinishPayment';
import { createPaymentProps } from '../../services/requests/Finance/createPayment';

import { ModalCookies } from '../../components/AtomicDesign/molecules/ModalCookies';
import { ModalAcceptedCookies } from '../../components/AtomicDesign/molecules/ModalAcceptedCookies';
import { useGetCheckoutById } from '../../services/requests/Finance/getCheckoutById';
import { ValidateCPFOrCNPJ } from '../../utils/validateCPFOrCNPJ';
import { UnHappyPath } from '../../components/AtomicDesign/atoms/UnhappyPath';
import { TicketsLookingAhead } from '../../components/AtomicDesign/templates/TicketsLookingAhead';
import BannerLookingAhead from '../../assets/logos/LOOKING_AHEAD.svg';

export interface FormikPaymentOptions {
  recipientId?: string;
  ticketUnitId?: string;
  paymentMethod: string;
  name: string;
  email: string;
  telephone?: string;
  finalValue?: number;
  couponApplied?: string;
  discount?: number;
  cpf?: string;
  address: {
    district: string;
    number: null;
    street: string;
    zipCode?: string;
    zip_code?: string;
    complement: string;
  };
  cardName?: string;
  cardNumber?: string;
  monthAvailable?: string;
  yearAvailable?: string;
  cvv?: string;
  parcelas?: string;
  cpf_cnpj?: string;
  phone?: string;
}

export function PaymentLookingAhead() {
  const [isLoading, setIsLoading] = useState(false);
  const [indexTab, setIndexTab] = useState<number>(1);
  const [paymentIsCreate, setPaymentIsCreate] = useState(false);
  const [selectMethod, setSelectMethod] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(true);
  const [isCookiesAccepted, setIsCookiesAccepted] = useState<string>();
  const [responsePayment, setResponsePayment] = useState(
    {} as createPaymentProps,
  );

  const customerAvatar = BannerLookingAhead;

  const checkoutId = `${process.env.REACT_APP_CHECKOUT_ID_LOOKINGAHEAD}`;

  const { data: product } = useGetCheckoutById(checkoutId);

  const configBankSchema = Yup.object().shape({
    ticketUnitId: Yup.string().required('O campo é obrigatório!'),
    name: Yup.string().required('O campo é obrigatório!'),
    email: Yup.string()
      .email('E-mail inválido')
      .required('Formato de e-mail inválido.'),
    telephone: Yup.string()
      .min(11, 'Número inválido')
      .required('Insira seu número'),
    cpf: Yup.string()
      .max(14, 'CPF inválido')
      .required('Insira seu cpf')
      .test((value) => ValidateCPFOrCNPJ(value))
      .nullable(),
    cardName: Yup.number()
      .max(70, 'O valor da porcentagem de comissão não pode ser maior que 70%')
      .required('O campo é obrigatório!'),
    paymentMethod: Yup.array().required('O campo é obrigatório!'),
    cardNumber: Yup.string().required('O campo é obrigatório!'),
    monthAvailable: Yup.string().required('O campo é obrigatório!'),
    parcelas: Yup.string(),
    cvv: Yup.string(),
    address: Yup.object().shape({
      district: Yup.string().required('O campo é obrigatório!'),
      number: Yup.number().required('O campo é obrigatório'),
      street: Yup.string().required('O campo é obrigatório!'),
      zipCode: Yup.string().required('O campo é obrigatório!'),
      complement: Yup.string().required('O campo é obrigatório!'),
    }),
  });

  const hexColor = 'var(--success-2)';

  useEffect(() => {
    const { 'v4company.cookiesAccepted': cookiesAccepted } = parseCookies();

    if (!cookiesAccepted) {
      setIsOpenModal(true);
      return;
    }
    setIsOpenModal(false);
    setIsCookiesAccepted(cookiesAccepted);
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          recipientId: `${checkoutId}`,
          paymentMethod: 'pix',
          ticketUnitId: '',
          quantity: 1,
          name: '',
          email: '',
          telephone: '',
          cpf: '',
          address: {
            district: '',
            number: null,
            street: '',
            zipCode: '',
            complement: '',
          },
        }}
        validationSchema={configBankSchema}
        onSubmit={async () => {
          setIndexTab(indexTab + 1);
        }}
      >
        {({ errors, values, handleBlur, setFieldValue, touched }) => (
          <Box>
            {product?.data?.disabled === true ? (
              <S.LinkDisabled>
                <Box className="ContainerDisabled">
                  <UnHappyPath
                    assetIndex={1}
                    title="Este link de pagamento está desabilitado ou não existe."
                  />
                </Box>
              </S.LinkDisabled>
            ) : (
              <>
                <S.Container>
                  {customerAvatar ? (
                    <S.Header>
                      <S.CustomerAvatar
                        alt={product?.data?.name}
                        src={customerAvatar}
                      />
                    </S.Header>
                  ) : (
                    ''
                  )}

                  <S.Navbar>
                    {indexTab !== 6 ? (
                      <>
                        <S.ContainerTabs>
                          <section
                            style={{
                              border: `${
                                indexTab === 1 && `1px solid ${hexColor}`
                              }`,
                              background: `${
                                indexTab === 1 ? `#fff` : `${hexColor}`
                              }`,
                            }}
                            aria-hidden
                            className={`${
                              (indexTab === 1 && `selected`) ||
                              (indexTab !== 1 && 'NotSelected')
                            }`}
                            key={1}
                            onClick={() => {
                              indexTab === 1 && setIndexTab(2);
                              indexTab === 2 && setIndexTab(indexTab - 1);
                            }}
                          >
                            <IoTicketOutline
                              color={indexTab === 1 ? `${hexColor}` : '#333333'}
                            />
                            <span>Ingressos</span>
                          </section>
                        </S.ContainerTabs>
                        <S.ContainerTabs>
                          <section
                            style={{
                              border: `${
                                indexTab === 2
                                  ? `1px solid ${hexColor}`
                                  : '1px solid #dadae7'
                              }`,
                              background: `${
                                (indexTab === 2 && `#fff`) ||
                                (indexTab > 2 && `${hexColor}`)
                              }`,
                            }}
                            aria-hidden
                            className={`${
                              (indexTab === 2 && `selected`) ||
                              (indexTab > 2 && 'NotSelected')
                            }`}
                            key={2}
                            onClick={() => {
                              indexTab === 1 && setIndexTab(indexTab + 1);
                              indexTab === 3 && setIndexTab(indexTab - 1);
                            }}
                          >
                            <FiUser
                              color={indexTab === 2 ? `${hexColor}` : ''}
                            />
                            <span>Sobre você</span>
                          </section>
                        </S.ContainerTabs>
                        <S.ContainerTabs>
                          <section
                            style={{
                              border: `${
                                indexTab === 3
                                  ? `1px solid ${hexColor}`
                                  : '1px solid #dadae7'
                              }`,
                              background: `${
                                (indexTab === 3 && `#fff`) ||
                                (indexTab > 3 && `${hexColor}`)
                              }`,
                            }}
                            aria-hidden
                            className={`${
                              (indexTab === 3 && `selected`) ||
                              (indexTab > 3 && 'NotSelected')
                            }`}
                            key={3}
                            onClick={() => {
                              if (
                                values.name &&
                                !errors.name &&
                                !errors.email &&
                                values.email &&
                                values.cpf &&
                                !errors.cpf &&
                                values.telephone
                                // !errors.tel
                              ) {
                                indexTab === 2 && setIndexTab(3);
                                indexTab === 4 && setIndexTab(indexTab - 1);
                              }
                            }}
                          >
                            <FiMapPin
                              color={indexTab === 3 ? `${hexColor}` : ''}
                            />
                            <span>Endereço</span>
                          </section>
                        </S.ContainerTabs>
                        <S.ContainerTabs>
                          <section
                            style={{
                              border: `${
                                indexTab === 4
                                  ? `1px solid ${hexColor}`
                                  : '1px solid #dadae7'
                              }`,
                              background: `${
                                (indexTab === 4 && `#fff`) ||
                                (indexTab > 4 && `${hexColor}`)
                              }`,
                            }}
                            aria-hidden
                            className={`${
                              (indexTab === 4 && `selected`) ||
                              (indexTab > 4 && 'NotSelected')
                            }`}
                            key={3}
                            onClick={() => {
                              if (
                                !errors.address?.zipCode &&
                                !errors.address?.complement &&
                                !errors.address?.district &&
                                !errors.address?.number &&
                                !errors.address?.street
                              ) {
                                indexTab === 3 && setIndexTab(4);
                                indexTab === 5 && setIndexTab(indexTab - 1);
                              }
                            }}
                          >
                            <FiCreditCard
                              color={indexTab === 4 ? `${hexColor}` : ''}
                            />
                            <span>Pagamento</span>
                          </section>
                        </S.ContainerTabs>
                        <S.ContainerTabs>
                          <section
                            style={{
                              border: `${
                                indexTab === 5
                                  ? `1px solid ${hexColor}`
                                  : '1px solid #dadae7'
                              }`,
                              background: `${indexTab > 5 && `${hexColor}`}`,
                            }}
                            aria-hidden
                            className={`${
                              (indexTab === 5 && `selected`) ||
                              (indexTab > 5 && 'NotSelected')
                            }`}
                            key={5}
                            onClick={() => {
                              indexTab === 4 && setIndexTab(5);
                            }}
                          >
                            <FiFile
                              color={indexTab === 5 ? `${hexColor}` : ''}
                            />
                            <span>Resumo</span>
                          </section>
                        </S.ContainerTabs>
                      </>
                    ) : null}
                  </S.Navbar>
                  {indexTab === 1 && (
                    <Box className="titleLookingAhead">
                      <h1 className="title">
                        Looking Ahead | Assembléia Estratégica
                      </h1>
                    </Box>
                  )}
                  <S.Body theme={indexTab === 6 ? 'center' : 'space-between'}>
                    {indexTab !== 5 ? (
                      <S.RequestsMobile
                        color={indexTab === 5 ? 'none' : 'block'}
                        style={{ border: `1px solid ${hexColor}` }}
                      >
                        <div className="your-request">
                          <FiShoppingCart />
                          <Headline variant="h5">Seu pedido</Headline>
                        </div>
                        <section>
                          <div className="your-product">
                            <p>{product?.data?.products[0]?.name}</p>
                            <p>
                              {product?.data
                                ? convertNumberToBRL(
                                    product?.data?.value * values?.quantity,
                                  )
                                : ''}
                            </p>
                          </div>
                          <p>{product?.data?.products[0]?.description}</p>
                        </section>
                      </S.RequestsMobile>
                    ) : null}

                    {indexTab === 1 && (
                      <TicketsLookingAhead
                        values={values}
                        dataCheckout={product?.data || undefined}
                        indexTab={indexTab}
                        setIndexTab={setIndexTab}
                        setFieldValue={setFieldValue}
                      />
                    )}

                    {indexTab === 2 && (
                      <AboutYou
                        touched={touched}
                        setFieldValue={setFieldValue}
                        indexTab={indexTab}
                        setIndexTab={setIndexTab}
                        values={values}
                        errors={errors}
                        handleBlur={handleBlur}
                        lookingAhead
                      />
                    )}

                    {indexTab === 3 && (
                      <AddressPayment
                        touched={touched}
                        indexTab={indexTab}
                        setIndexTab={setIndexTab}
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                        handleBlur={handleBlur}
                      />
                    )}

                    {indexTab === 4 && (
                      <MethodPayment
                        indexTab={indexTab}
                        setIndexTab={setIndexTab}
                        setFieldValue={setFieldValue}
                        values={values}
                        selectMethod={selectMethod}
                        setSelectMethod={setSelectMethod}
                        product={product?.data || undefined}
                      />
                    )}
                    {indexTab === 5 && (
                      <ResumePayment
                        data={product?.data}
                        indexTab={indexTab}
                        setIndexTab={setIndexTab}
                        values={values}
                        setPaymentIsCreate={setPaymentIsCreate}
                        setResponsePayment={setResponsePayment}
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        selectMethod={selectMethod}
                        customerHexColor={hexColor}
                        quantity={values?.quantity}
                      />
                    )}
                    {indexTab === 6 && paymentIsCreate ? (
                      <CustomerFinishPayment
                        responsePayment={responsePayment}
                        values={values}
                        data={product?.data}
                        quantity={values?.quantity}
                      />
                    ) : null}

                    {indexTab !== 6 ? (
                      <S.RequestsDesktop
                        color={indexTab === 5 ? 'none' : 'block'}
                        style={{ border: `1px solid ${hexColor}` }}
                      >
                        <div className="your-request">
                          <FiShoppingCart />
                          <Headline variant="h5">Seu pedido</Headline>
                        </div>
                        <section>
                          <div className="your-product">
                            <p>{product?.data?.products[0]?.name || ''}</p>
                            <p>
                              {product?.data
                                ? convertNumberToBRL(
                                    product?.data?.products[0]?.value *
                                      values?.quantity,
                                  )
                                : ''}
                            </p>
                          </div>
                          <p>{product?.data?.products[0]?.description}</p>
                        </section>
                      </S.RequestsDesktop>
                    ) : null}
                  </S.Body>

                  <S.Footer>
                    <p>
                      Desenvolvido por: <img src={v4CompanyLogo} alt="" />
                    </p>
                    <span>2022 © Todos os direitos reservados. V4 Company</span>
                  </S.Footer>
                </S.Container>
              </>
            )}
          </Box>
        )}
      </Formik>
      {!isOpenModal ? (
        <S.Cookie>
          <ModalCookies />
          {/* <BiCookie size={33} /> */}
        </S.Cookie>
      ) : null}
      {!isCookiesAccepted ? (
        <ModalAcceptedCookies
          show={isOpenModal}
          onClose={() => setIsOpenModal(false)}
        />
      ) : null}
    </>
  );
}
