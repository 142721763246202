import { Box } from '@mui/material';
import { FiAlertTriangle, FiShoppingCart } from 'react-icons/fi';

import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineExclamationCircle,
  AiOutlineInfoCircle,
} from 'react-icons/ai';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  Breadcrumb,
  Button,
  Container,
  DotMenu,
  Headline,
  Paragraph,
} from '../../components/LegacyV4Components';
import { UnHappyPath } from '../../components/AtomicDesign/atoms/UnhappyPath';
import * as S from './styles';
import { ClientTabs } from '../../components/AtomicDesign/atoms/ClientTabs';
import { useQueryProductListType } from '../../services/requests/Finance/getProductList';
import { ListProductsCheckout } from '../../components/AtomicDesign/organisms/ListProductsCheckout';
import { GenerateCheckoutHQModal } from '../../components/AtomicDesign/organisms/GenerateCheckoutHQModal';
import {
  DataGrid,
  DataProps,
} from '../../components/AtomicDesign/atoms/DataGrid';
import {
  GenerateCouponsHQModal,
  ICouponsSelect,
} from '../../components/AtomicDesign/organisms/GenerateCouponsHQModal';
import { useGetCheckoutHQList } from '../../services/requests/Finance/getCheckoutHQList';
import CardCheckoutHQ, {
  CardCheckoutHQProps,
} from '../../components/AtomicDesign/organisms/CardCheckoutHQ';
import {
  ICouponsList,
  useGetCouponsList,
} from '../../services/requests/Finance/getCouponsList';
import { financeApi } from '../../services/api';
import { DataCouponsById } from '../../types/coupons';
import { useToast } from '../../hooks/toast';
import {
  tableColumnsCoupons,
  tableColumnsFature,
} from '../../utils/tableColumns';
import {
  IExtract,
  useGetExtractList,
} from '../../services/requests/Finance/getExtract';
import { formatPhone } from '../../utils/formatPhone';

enum Tabs {
  tab1 = 'Links gerados',
  tab2 = 'Cupons',
  tab3 = 'Extrato',
}

interface DotMenuOptions {
  name: string;
  action: () => void;
}

export function GatewayHQCheckout() {
  const [searchParams] = useSearchParams();
  const [clientTab, setClientTab] = useState<string>(Tabs.tab1);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [showCreateCheckout, setShowCreateCheckout] = useState(false);
  const [showCreateCoupons, setShowCreateCoupons] = useState(false);
  const [showEditCoupons, setShowEditCoupons] = useState(false);
  const [couponsSelect, setCouponsSelect] = useState({} as ICouponsSelect);
  const [tableDataCoupons, setTableDataCoupons] = useState<DataProps[]>([]);
  const [tableDataFature, setTableDataFature] = useState<DataProps[]>([]);
  const [productType, setProductType] = useState('');

  const { addToast } = useToast();

  const clientTabComponent = () => {
    return (
      <ClientTabs
        tabs={[Tabs.tab1, Tabs.tab2, Tabs.tab3]}
        onChange={(tab) => setClientTab(tab)}
      />
    );
  };

  const { data } = useGetCheckoutHQList(searchParams.get('type')?.toString());

  const { data: couponsList, refetch } = useGetCouponsList();
  function getTypeDescription(): string {
    const type = searchParams.get('type');

    switch (type) {
      case 'education':
        return 'Education';
      case 'headquarter-products':
        return 'Gestão Matriz';
      default:
        break;
    }
    return type as string;
  }

  useMemo(async () => {
    const isProductType = searchParams.get('type');
    if (isProductType) {
      setProductType(isProductType);
    }
  }, [searchParams]);

  const { data: productByType, refetch: refetchList } =
    useQueryProductListType(productType);

  const { data: extractList } = useGetExtractList(
    searchParams.get('type')?.toString(),
  );

  const handleDeleteCoupons = useCallback(
    (coupons: DataCouponsById) => {
      financeApi({ url: `/coupons/${coupons?._id}`, method: 'DELETE' })
        .then(async () => {
          refetch();
          addToast({
            title: 'Sucesso',
            description: 'Cupom excluído com sucesso!',
            type: 'success',
          });
        })
        .catch(() =>
          addToast({
            title: 'Atenção',
            description: 'Houve um erro ao excluir o stakeholder.',
            type: 'error',
          }),
        );
      refetch();
    },
    [addToast, refetch],
  );

  const dotMenuOptions = useCallback(
    (coupons: ICouponsList): DotMenuOptions[] => {
      return [
        {
          name: 'Editar',
          action: () => {
            setCouponsSelect(coupons);
            setShowEditCoupons(true);
          },
        },
        {
          name: 'Remover',
          action: () => {
            handleDeleteCoupons(coupons);
          },
        },
      ];
    },
    [handleDeleteCoupons],
  );

  useEffect(() => {
    const rows =
      couponsList?.map((coupons: ICouponsList) => {
        const content = (
          <>
            <div className="avatar-column" title={coupons?.name}>
              {coupons?.name}
            </div>
            <div title="desconto">
              <Paragraph>
                {coupons?.discountType === 'value'
                  ? `R$ ${coupons?.value}`
                  : `${coupons?.value}%`}
              </Paragraph>
            </div>
            <div title={coupons?.endValidity}>
              <Paragraph>{`${format(
                parseISO(coupons?.initialValidity),
                'dd/MM/yyyy',
                {
                  locale: ptBR,
                },
              )} até ${format(parseISO(coupons?.endValidity), 'dd/MM/yyyy', {
                locale: ptBR,
              })}`}</Paragraph>
            </div>
            <div title="Limite">
              <Paragraph>
                {coupons?.maximumQuantityAllowed !== 0
                  ? `${coupons?.quantityAlreadyUsed} / ${coupons?.maximumQuantityAllowed}`
                  : 'Ilimitado'}
              </Paragraph>
            </div>
            <div
              title="status"
              className={coupons?.disable === false ? 'active' : 'disabled'}
            >
              <Paragraph>
                {coupons?.disable === false ? 'Ativo' : 'Inativo'}
              </Paragraph>
              {coupons?.disable === false ? (
                <AiOutlineCheckCircle color="#00ab86" />
              ) : (
                <AiOutlineCloseCircle color="#bbbbbb" />
              )}
            </div>
            <div title={coupons?.name}>
              <DotMenu reversed options={dotMenuOptions(coupons)} />
            </div>
          </>
        );
        return {
          id: coupons?._id,
          content,
        };
      }) ?? [];

    setTableDataCoupons(rows);
  }, [couponsList, dotMenuOptions]);

  useEffect(() => {
    const rows: DataProps[] =
      extractList?.map((invoice: IExtract) => {
        let methodPayment = '';
        let status = '';
        let iconStatus = null;
        switch (invoice?.paymentMethod) {
          case 'credit_card':
            methodPayment = 'Cartão de crédito';
            break;
          case 'bank_slip':
            methodPayment = 'Boleto';
            break;
          case 'pix':
            methodPayment = 'Pix';
            break;
          default:
            break;
        }

        switch (invoice?.status) {
          case 'pending':
            status = 'Pendente';
            break;
          case 'paid':
            status = 'Pago';
            break;
          case 'expired':
            status = 'Expirado';
            break;
          case 'canceled':
            status = 'Cancelado';
            break;
          default:
            break;
        }

        switch (invoice?.status) {
          case 'pending':
            iconStatus = <FiAlertTriangle color="#FABC2A" />;
            break;
          case 'paid':
            iconStatus = <AiOutlineCheckCircle color="#00AB86" />;
            break;
          case 'expired':
            iconStatus = <AiOutlineExclamationCircle color="var(--error)" />;
            break;
          case 'canceled':
            iconStatus = <AiOutlineInfoCircle color="#5f5f95" />;
            break;
          default:
            break;
        }

        const content = (
          <>
            <div className="avatar-column" title={invoice?.itemDescription}>
              {invoice?.itemDescription}
            </div>
            <div title="Forma de pagamento">
              <Paragraph>{methodPayment}</Paragraph>
            </div>
            <div title="value">
              <Paragraph>
                R${' '}
                {invoice?.originalValue
                  ? invoice?.originalValue
                  : invoice?.finalValue / 100}
              </Paragraph>
            </div>
            <div title="pagador">
              <Paragraph>{invoice?.name}</Paragraph>
            </div>
            <div title="telephone">
              <Paragraph>{formatPhone(invoice?.telephone)}</Paragraph>
            </div>
            <div title={invoice?.createdAt}>
              <Paragraph>{`${format(
                parseISO(invoice?.createdAt),
                'dd/MM/yyyy',
                {
                  locale: ptBR,
                },
              )}`}</Paragraph>
            </div>
            <div title="value">
              <Paragraph>{`${format(parseISO(invoice?.createdAt), 'HH:mm:ss', {
                locale: ptBR,
              })}`}</Paragraph>
            </div>
            <div title="status" className={invoice?.status}>
              <Paragraph>{status}</Paragraph>
              {iconStatus}
            </div>
          </>
        );
        return {
          id: invoice?._id,
          content,
        };
      }) ?? [];

    setTableDataFature(rows);
  }, [extractList]);

  return (
    <>
      <Container>
        <GenerateCouponsHQModal
          isShown={showCreateCoupons}
          setIsShown={setShowCreateCoupons}
          productByType={productByType}
          refetch={refetch}
          isEditCoupons={false}
        />
        <GenerateCouponsHQModal
          isShown={showEditCoupons}
          setIsShown={setShowEditCoupons}
          productByType={productByType}
          refetch={refetch}
          couponsSelect={couponsSelect}
          isEditCoupons
        />
        <GenerateCheckoutHQModal
          isShown={showCreateCheckout}
          setIsShown={setShowCreateCheckout}
          productByType={productByType}
        />
        <ListProductsCheckout
          isShown={showProductsModal}
          setIsShown={setShowProductsModal}
          productType={productType}
          data={productByType}
          refetch={refetchList}
        />
        <Breadcrumb
          breadcrumbs={[
            { title: 'Central de dashboards', link: '/' },
            { title: 'Selecione o local', link: '/gateway/headquarter' },
            {
              title: `${getTypeDescription()}`,
              link: `/gateway/headquarter/checkout?type=${searchParams.get(
                'type',
              )}`,
            },
          ]}
        />
        <S.Container>
          <S.ContainerHead>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Headline variant="h4">
                Gateway de pagamento: {getTypeDescription()}
              </Headline>
            </div>
            {clientTab === Tabs.tab1 && (
              <Box sx={{ display: 'flex', gap: '24px' }}>
                <Button
                  onClick={() => {
                    setShowProductsModal(true);
                  }}
                  variant="secondary"
                  color="green"
                  size="default"
                  width="230px"
                >
                  Produtos
                </Button>
                <Button
                  onClick={() => {
                    setShowCreateCheckout(true);
                  }}
                  disabled={productByType === null}
                  variant="primary"
                  color="green"
                  size="default"
                  rightIcon={<FiShoppingCart />}
                >
                  Gerar novo checkout
                </Button>
              </Box>
            )}
            {clientTab === Tabs.tab2 && (
              <Box>
                <Button
                  onClick={() => {
                    setShowCreateCoupons(true);
                  }}
                  variant="primary"
                  color="green"
                  size="default"
                >
                  Criar cupom +
                </Button>
              </Box>
            )}
          </S.ContainerHead>
          <div style={{ display: 'flex' }}>{clientTabComponent()}</div>
          {clientTab === Tabs.tab1 && (
            <>
              {!Array.isArray(data) || data.length === 0 ? (
                <>
                  <UnHappyPath assetIndex={0} />
                  <Headline variant="h6">
                    Você ainda não gerou checkouts
                  </Headline>
                </>
              ) : (
                <>
                  {data?.map((item: CardCheckoutHQProps) => (
                    <CardCheckoutHQ productList={item} />
                  ))}
                </>
              )}
            </>
          )}
          {clientTab === Tabs.tab2 && (
            <Box sx={{ marginTop: '32px' }}>
              <>
                {couponsList?.length ? (
                  <DataGrid
                    columns={tableColumnsCoupons}
                    data={tableDataCoupons}
                    showModalUserInvate={false}
                    setShowModalUserInvate={() => null}
                    dontRedirect
                  />
                ) : (
                  <Box sx={{ textAlign: 'center' }}>
                    <UnHappyPath assetIndex={6} />
                    <Headline variant="h6">
                      Você ainda não gerou cupons
                    </Headline>
                  </Box>
                )}
              </>
            </Box>
          )}
          {clientTab === Tabs.tab3 && (
            <Box sx={{ marginTop: '2rem' }}>
              {extractList?.length ? (
                <>
                  <DataGrid
                    columns={tableColumnsFature}
                    data={tableDataFature}
                    showModalUserInvate={false}
                    setShowModalUserInvate={() => null}
                    dontRedirect
                  />
                </>
              ) : (
                <Box sx={{ textAlign: 'center' }}>
                  <UnHappyPath assetIndex={6} />
                  <Headline variant="h6">
                    Você ainda não possui extratos gerados
                  </Headline>
                </Box>
              )}
            </Box>
          )}
        </S.Container>
      </Container>
    </>
  );
}
