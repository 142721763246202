import { InvestorTeam } from '../../../hooks/types';
import { usersApi as api } from '../../api';

export async function getInvestorsByCustomersId(customerId: string) {
  try {
    const response = await api.get<InvestorTeam[]>(
      `/capacity/manage/find-all/users/${customerId}`,
    );
    return response.data;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os dados dos clientes, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
