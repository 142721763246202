import { FC, useCallback, useState, ChangeEvent } from 'react';
import { FiCamera } from 'react-icons/fi';
import { ProfileImage } from '../ProfileImage';
import * as S from './styles';
import { uploadPicture } from '../../../services/requests/ProfileCard/uploadPicture';
import { useAuth } from '../../../hooks/auth';
import { User } from '../../../hooks/types';
import { useToast } from '../../../hooks/toast';
import { CustomLoading } from '../Loadings/CustomLoading';

interface ProfileCardProps {
  profile: User;
}

const ProfileCard: FC<ProfileCardProps> = ({ profile }) => {
  const [avatarUrl, setAvatarUrl] = useState(profile.picture);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const toastContext = useToast();

  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      const data = new FormData();

      const file = e.target.files?.[0];

      if (file) {
        data.append('avatar', file);

        const newPictureUrl = await uploadPicture(data);

        if (!newPictureUrl.error && newPictureUrl) {
          setAvatarUrl(newPictureUrl.picture);
        } else {
          toastContext.addToast({
            type: 'error',
            title: 'Não foi possível editar a foto',
          });
        }

        e.target.value = '';
        setLoading(false);
      }
    },
    [toastContext],
  );

  return (
    <S.Container loggedUser={user._id === profile._id}>
      <label htmlFor="avatar" aria-label="default">
        <ProfileImage size="6rem" img={avatarUrl} name={`${profile.name} `} />

        <input
          type="file"
          id="avatar"
          accept=".jpg, .png, .jpeg"
          onChange={handleChange}
          disabled={user._id !== profile._id}
        />
        <S.IconContainer>
          <FiCamera />
          <div />
        </S.IconContainer>
      </label>
      {loading && <CustomLoading />}
    </S.Container>
  );
};

export { ProfileCard };
