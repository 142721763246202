import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';
import { Button, Headline } from '../../../LegacyV4Components';

import { Modal } from '../../../Default/Modal';

import * as S from './styles';
import { FormikCurrencyInput } from '../../molecules/FormikCurrencyInput';
import { useToast } from '../../../../hooks/toast';
import { SelectSearch } from '../../atoms/SelectSearch';
import { ShareLinkPlanModal } from '../ShareLinkPlanModal';
import { FormikPercentageInput } from '../../molecules/FormikPercentageInput';
import { createPlanFNC } from '../../../../types/createPlanFNC';

export function EditPlanModal({
  isShown,
  setIsShown,
  data,
}: {
  isShown: boolean;
  setIsShown(value: boolean): any;
  data: any;
}) {
  const [isOpenGenerate, setIsOpenGenerate] = useState(false);
  const [selectMethod, setSelectMethod] = useState('');
  const [selectPlan, setSelectPlan] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const sendFormRef = useRef<HTMLButtonElement>(null);
  const { addToast } = useToast();
  data;

  const configBankSchema = Yup.object().shape({
    value: Yup.string().required('O campo é obrigatório'),
    royalties: Yup.number().required('O campo é obrigatório'),
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          value: '',
          royalties: 15,
          paymentMethod: '',
          paymentMethodCredit: false,
        }}
        validationSchema={configBankSchema}
        onSubmit={() => {
          setIsLoading(false);
          isLoading;
        }}
      >
        {({ errors, values, handleBlur, isValid, dirty, setFieldValue }) => {
          async function handleSubmit() {
            const dataToSend = {
              ...values,
            };
            dataToSend;

            setIsLoading(true);
            setIsLoading(false);
            setIsOpenGenerate(true);
            addToast({
              title: 'Atualização feita com sucesso!',
              type: 'success',
            });
          }
          return (
            <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
              <>
                <S.ModalWrapper>
                  <S.ModalHeader>
                    <Headline variant="h4">Editar Plano</Headline>
                    <button
                      type="button"
                      onClick={() => setIsShown(false)}
                      aria-label="default"
                    >
                      <FiX />
                    </button>
                  </S.ModalHeader>
                  <S.ModalBody>
                    <Headline variant="h6">
                      Preencha as informações abaixo para que possa cadastrar o
                      novo plano
                    </Headline>
                    <Form>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          marginBottom: '1rem',
                          marginTop: '0.5rem',
                        }}
                      >
                        <div
                          style={{
                            maxWidth: '50%',
                            width: '100%',
                          }}
                        >
                          <Headline className="label" variant="h6">
                            Selecione o plano
                          </Headline>
                          <SelectSearch
                            customStyles={{ selectWrapper: 'select-search' }}
                            placeholder="Selecione o plano"
                            value={selectPlan}
                            options={[
                              {
                                value: 'Corrente',
                                label: 'Corrente',
                                selected: false,
                              },
                              {
                                value: 'Poupança',
                                label: 'Poupança',
                                selected: false,
                              },
                            ]}
                            setValue={(e: any) => {
                              setSelectPlan(e);
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ display: 'flex', marginBottom: '1rem' }}>
                        <FormikCurrencyInput
                          label="Valor"
                          width="100%"
                          helper={errors.value}
                          onBlur={handleBlur}
                          onValueChange={(value) => {
                            setFieldValue('value', value);
                          }}
                        />
                      </div>
                      <div style={{ display: 'flex', marginBottom: '1rem' }}>
                        <FormikPercentageInput
                          number
                          width="100%"
                          name="royalties"
                          label="Royalties"
                          value={values.royalties}
                          placeholder="0%"
                          helper={errors.royalties}
                          validateOnChange={false}
                          onBlur={handleBlur}
                          onValueChange={(value) => {
                            setFieldValue('royalties', value);
                          }}
                        />
                      </div>
                      <div style={{ marginBottom: '1rem' }}>
                        <Headline className="label" variant="h6">
                          Selecione um ou mais meios de pagamento
                        </Headline>

                        <S.ContainerRadio
                          color={
                            selectMethod === 'cartão de credito'
                              ? '1px solid #00C49A'
                              : ''
                          }
                          onClick={() => {
                            setSelectMethod('cartão de credito');
                            setFieldValue(
                              'paymentMethodCredit',
                              !values.paymentMethodCredit,
                            );
                          }}
                        >
                          <input
                            value="paymentMethodCredit"
                            type="checkbox"
                            checked={values.paymentMethodCredit}
                            onChange={() => {
                              setSelectMethod('cartão de credito');
                              setFieldValue(
                                'paymentMethodCredit',
                                !values.paymentMethodCredit,
                              );
                            }}
                          />
                          <span>Cartão de crédito</span>
                        </S.ContainerRadio>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          marginBottom: '1rem',
                          marginTop: '0.5rem',
                        }}
                      >
                        <div
                          style={{
                            maxWidth: '50%',
                            width: '100%',
                          }}
                        >
                          <Headline className="label" variant="h6">
                            Data de Cobrança
                          </Headline>
                          <SelectSearch
                            customStyles={{ selectWrapper: 'select-search' }}
                            placeholder="Selecione a data"
                            value={selectPlan}
                            options={[
                              {
                                value: 'Corrente',
                                label: 'Corrente',
                                selected: false,
                              },
                              {
                                value: 'Poupança',
                                label: 'Poupança',
                                selected: false,
                              },
                            ]}
                            setValue={(e: any) => {
                              setSelectPlan(e);
                            }}
                          />
                        </div>
                      </div>
                      <button
                        ref={sendFormRef}
                        type="submit"
                        style={{ display: 'none' }}
                      >
                        Enviar
                      </button>
                    </Form>
                  </S.ModalBody>
                  <S.ModalFooter>
                    <>
                      <Button
                        variant="primary"
                        color="green"
                        size="default"
                        onClick={async () => {
                          handleSubmit();
                        }}
                        disabled={!(isValid && dirty)}
                      >
                        Alterar e Compartilhar
                      </Button>
                    </>
                  </S.ModalFooter>
                </S.ModalWrapper>
              </>
            </Modal>
          );
        }}
      </Formik>
      <ShareLinkPlanModal
        isShown={isOpenGenerate}
        setIsShown={setIsOpenGenerate}
        data={{} as createPlanFNC}
      />
    </>
  );
}
