import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  text-align: center;
  gap: 24px;
  justify-content: center;
`;

export const CardProduct = styled.div`
  max-width: 290px;
  width: 100%;
  height: 208px;
  border: 1px solid #c1c1d7;
  border-radius: 8px;
  margin-top: 80px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    padding: 16px;
    background: #f4f4f8;
    border: 1px solid #b4b4cf;
    box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.06),
      0px 2px 3px rgba(0, 0, 0, 0.12);
  }
`;

export const ContainerContent = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid #8383af;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
`;
