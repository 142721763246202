import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type ClientContextData = {
  client: FranchiseBusinessUnit;
  setClient: Dispatch<SetStateAction<FranchiseBusinessUnit>>;
  clients: FranchiseBusinessUnit[];
  setClients: Dispatch<SetStateAction<FranchiseBusinessUnit[]>>;
  columns: Column[];
  setColumns: Dispatch<SetStateAction<Column[]>>;
};

export const ClientContext = createContext({} as ClientContextData);

type ClientContextProviderProps = {
  children: ReactNode;
};

export const ClientProvider: React.FC<ClientContextProviderProps> = ({
  children,
}: ClientContextProviderProps) => {
  const [client, setClient] = useState({} as FranchiseBusinessUnit);
  const [columns, setColumns] = useState({} as Column[]);
  const [clients, setClients] = useState({} as FranchiseBusinessUnit[]);

  return (
    <ClientContext.Provider
      value={{
        client,
        setClient,
        columns,
        setColumns,
        clients,
        setClients,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = (): ClientContextData => {
  return useContext(ClientContext);
};
