import { dashboardsApi } from '../../api';

interface Permission {
  roleName: string;
  userId: string;
}

export async function getUserPermission(userId: string): Promise<Permission> {
  const response = await dashboardsApi.get(`/permissions?userId=${userId}`);
  const permission = response.data as Permission;

  return permission;
}
