import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  h6 {
    font-size: 1rem;
    font-family: 'Inter';
    line-height: 1.5rem;
    margin-bottom: 8px;
  }

  small {
    display: inline-block;
    margin-top: 1rem;
    line-height: 1rem;
  }
  .input-control {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      all: unset;
      margin: 0;
      width: -webkit-fill-available;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
  &:not(.default) .input-control {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
      0px 2px 6px 2px rgba(0, 0, 0, 0.06);
  }

  &:focus-within {
    .input-control {
      border-color: var(--grayscale-20);
    }
  }

  &.success {
    .input-control {
      border-color: var(--success-2);

      & > svg {
        color: var(--success-2);
      }
    }

    small {
      color: var(--success-2);
    }
  }

  &.error {
    .input-control {
      border-color: var(--error);

      & > svg {
        color: var(--error);
      }
    }

    small {
      color: var(--error);
    }
  }

  &.warning {
    .input-control {
      border-color: #fabc2a;

      & > svg {
        color: #fabc2a;
      }
    }

    small {
      color: #fabc2a;
    }
  }

  &.inputStatus {
    .input-control {
      border-color: #8383af;

      & > svg {
        color: #fabc2a;
      }
    }

    small {
      color: #fabc2a;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
`;

export const InputControl = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0.5rem 1rem;

  border: 1px solid var(--grayscale-4);
  border-radius: 0.25rem;

  transition: box-shadow 0.3s, border-color 0.3s;

  & > svg {
    width: 1rem;
    height: 1rem;

    flex-shrink: 0;
  }

  & > input {
    flex-grow: 1;

    font-family: 'Nunito Sans';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      background-color: initial;
    }

    border: none;
  }
`;
