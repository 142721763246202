import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { unitsApi } from '../../api';
import { Unit } from '../../../hooks/types';

export function useQueryGetUnit(
  unitId: string | undefined,
): UseQueryResult<{ data: Unit }, { data: Unit }> {
  return useQuery<{ data: Unit }, { data: Unit }>(
    ['Unit', unitId],
    () => unitsApi.get<Unit>(`/units/${unitId}`),
    {},
  );
}
