import { FiArrowRight } from 'react-icons/fi';
import { FaRegCreditCard } from 'react-icons/fa';
import { Button, Headline } from '../../../LegacyV4Components';
import PixIcon from '../../../../assets/icons/pix.svg';
import BoletoIcon from '../../../../assets/icons/boleto.png';
import * as S from './styles';
import { FormikPaymentOptions } from '../../../../pages/PaymentOptions';
import { IProductById } from '../../../../services/requests/Finance/getProductById';
import { IPlans } from '../../../../services/requests/Finance/getPlanList';

interface MethodPaymentProps {
  values: FormikPaymentOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  indexTab: number;
  setIndexTab: React.Dispatch<React.SetStateAction<number>>;
  selectMethod: string;
  setSelectMethod: React.Dispatch<React.SetStateAction<string>>;
  product: IProductById | undefined | IPlans;
}
export function MethodPayment({
  values,
  setFieldValue,
  indexTab,
  setIndexTab,
  selectMethod,
  setSelectMethod,
  product,
}: MethodPaymentProps) {
  return (
    <>
      <S.Container>
        {product?.payableWith?.includes('pix') && (
          <S.ContainerRadio
            color={selectMethod === 'pix' ? '1px solid #00C49A' : ''}
            onClick={() => {
              setSelectMethod('pix');
              setFieldValue('paymentMethod', 'pix');
            }}
          >
            <span>
              <input
                value="pix"
                name={values.paymentMethod}
                type="radio"
                checked={selectMethod === 'pix'}
                onChange={(e) => {
                  setSelectMethod(e.target.value);
                  setFieldValue('paymentMethod', e.target.value);
                }}
              />
            </span>
            <Headline variant="h6">
              <img src={PixIcon} alt="Ícone do PIX" />
              Pix
            </Headline>
          </S.ContainerRadio>
        )}
        {product?.payableWith?.includes('bank_slip') && (
          <S.ContainerRadio
            onClick={() => {
              setSelectMethod('boleto');
              setFieldValue('paymentMethod', 'bank_slip');
            }}
            color={selectMethod === 'boleto' ? '1px solid #00C49A' : ''}
          >
            <input
              value="bank_slip"
              type="radio"
              checked={selectMethod === 'boleto'}
              onChange={(e) => {
                setSelectMethod('boleto');
                setFieldValue('paymentMethod', e.target.value);
              }}
            />
            <Headline variant="h6">
              <img src={BoletoIcon} alt="Boleto" /> Boleto
            </Headline>
          </S.ContainerRadio>
        )}
        {product?.payableWith.includes('credit_card') && (
          <S.ContainerRadio
            color={
              selectMethod === 'cartão de credito' ? '1px solid #00C49A' : ''
            }
            onClick={() => {
              setSelectMethod('cartão de credito');
              setFieldValue('paymentMethod', 'credit_card');
            }}
          >
            <input
              value="credit_card"
              type="radio"
              checked={selectMethod === 'cartão de credito'}
              onChange={(e) => {
                setSelectMethod('cartão de credito');
                setFieldValue('paymentMethod', e.target.value);
              }}
            />
            <Headline variant="h6">
              <FaRegCreditCard width="1rem" height="1rem" />
              Cartão de credito
            </Headline>
          </S.ContainerRadio>
        )}
        {selectMethod === 'pix' ||
        selectMethod === 'boleto' ||
        selectMethod === 'cartão de credito' ? (
          <S.OptionMethod>
            <small>Pague sua compra com {selectMethod}</small>
          </S.OptionMethod>
        ) : (
          ''
        )}
        <div style={{ float: 'right', marginTop: '48px' }}>
          <Button
            variant="primary"
            rightIcon={<FiArrowRight />}
            color="green"
            size="default"
            onClick={async () => {
              if (selectMethod) {
                setIndexTab(indexTab + 1);
              }
            }}
          >
            Continuar
          </Button>
        </div>
      </S.Container>
    </>
  );
}
