import { useMemo, useState } from 'react';
import {
  LabDsSearchBar,
  LabDsTab,
  LabDsBreadcrumb,
  LabDsTable,
} from 'v4web-components-react';
import { IData } from 'v4web-components';
import { useInfiniteQuery } from '@tanstack/react-query';
import { parseCookies } from 'nookies';
import {
  Container,
  Section,
  SectionContent,
  Headline,
} from '../../components/LegacyV4Components';
import { kubernetesApi } from '../../services/api';

import * as S from './styles';
import { CustomersColumns } from './columns';
import useDebounce from '../../hooks/useDebounce';
import { ProjectList } from '../../types/projects';
import { ModalManageProjects } from './components/ModalManageProjects';

enum Tabs {
  active = 'Ativos',
  inactive = 'Inativos',
}

interface CustomerRequest {
  limit: number;
  page: number;
  total: number;
  totalPages: number;
  projects: ProjectList[];
}

export function BackofficeProjects() {
  const [tab, setTab] = useState(Tabs.active.toString());
  const [showManageProjects, setShowManageProjects] = useState<boolean>(false);
  const [projectSelected, setProjectSelected] = useState({});
  const headers = CustomersColumns;

  const pageTitle = 'Clientes V4';
  const breadcrumbsItems = [
    { label: pageTitle, link: '/clientes', key: pageTitle, event: () => '' },
  ];

  const searchPlaceholder = 'Busque por nome do projeto';

  const unitsURL = '/customers/find-project-all';

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce<string>(query, 500);

  const cookies = parseCookies(undefined);
  const token = cookies['v4company.token'];

  const { data, fetchNextPage } = useInfiniteQuery(
    ['projects', debouncedQuery, tab],
    async ({ pageParam = 1 }) => {
      const customersPromise = (await kubernetesApi({
        method: 'GET',
        url: unitsURL,
        params: {
          page: pageParam,
          limit: 20,
          q: debouncedQuery,
          isActive: tab === 'Ativos',
        },
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => res.data)) as unknown as CustomerRequest;
      return customersPromise;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.page - 1 ?? undefined,
      getNextPageParam: (lastPage) =>
        lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const rows: IData['rows'] = useMemo(() => {
    const rowsData: IData['rows'] = [];
    data?.pages?.forEach((page) => {
      page.projects?.forEach((project: any) => {
        rowsData.push({
          name: {
            text: {
              title: project.name,
            },
          },
          unit: {
            text: {
              title: project.unitId[0],
            },
            handleRow: () => {
              setProjectSelected(project);
              setShowManageProjects(true);
            },
          },
        });
      });
    });
    return rowsData;
  }, [data]);

  return (
    <>
      <Container>
        <LabDsBreadcrumb breadcrumbs={breadcrumbsItems} />

        <Section>
          <SectionContent>
            <S.SectionHeader>
              <Headline variant="h3">{pageTitle}</Headline>
            </S.SectionHeader>
            <S.Row>
              <LabDsTab
                value="active"
                tabs={[
                  {
                    title: 'Ativas',
                    key: 'active',
                    event: () => setTab(Tabs.active),
                  },
                  {
                    title: 'Inativas',
                    key: 'inactive',
                    event: () => setTab(Tabs.inactive),
                  },
                ]}
              />
            </S.Row>
            <S.Filters>
              <div>
                <LabDsSearchBar
                  value={query}
                  onChangeInput={({ detail }) => setQuery(detail)}
                  placeholder={searchPlaceholder}
                  label={searchPlaceholder}
                />
              </div>
            </S.Filters>
            <LabDsTable
              onFinalLineNextPage={() => fetchNextPage()}
              data={{ headers, rows }}
            />
          </SectionContent>
        </Section>
      </Container>
      {projectSelected && (
        <ModalManageProjects
          projects={projectSelected}
          open={showManageProjects}
          setIsOpen={() => setShowManageProjects(!showManageProjects)}
        />
      )}
    </>
  );
}
