import { Dashboard } from '../../../types/dashboard';
import { createPlanFNC } from '../../../types/createPlanFNC';
import { financeApi } from '../../api';

export async function createPlan(
  data: createPlanFNC,
): Promise<Dashboard | false> {
  try {
    const response = await financeApi.post<Dashboard>(
      `/subscriptions/plans`,
      data,
    );
    const plan = response.data;

    return plan;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o plano, atualize a página e tente novamente 😕`,
      err,
    );
    return err.response.data;
  }
}
