import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  background-color: rgb(246, 246, 246);

  @media (min-width: 1024px) {
    padding: 1.5rem 3rem;
  }

  @media (min-width: 1440px) {
    padding: 1.5rem 5rem;
  }
`;
