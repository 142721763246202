import React, { InputHTMLAttributes } from 'react';

import * as S from './styles';

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  width?: string;
  height?: string;
  beforeWidth?: string;
  beforeHeight?: string;
  border?: string;
}

export function RadioButton({
  label,
  width,
  height,
  beforeWidth,
  beforeHeight,
  border,
  ...rest
}: RadioButtonProps) {
  return (
    <S.Container>
      <S.Label>
        <S.Input
          type="radio"
          width={width}
          height={height}
          beforeWidth={beforeWidth}
          beforeHeight={beforeHeight}
          border={border}
          {...rest}
        />
        {label}
      </S.Label>
    </S.Container>
  );
}
