import { SelectValue } from '../../../components/AtomicDesign/atoms/SelectQuery';
import { customerNewApi } from '../../api';

async function handleFetchInitialValue(
  projectId: string,
): Promise<SelectValue<IProject> | undefined> {
  if (!projectId) return undefined;
  const { data } = await customerNewApi.get<IProject>(
    `/find-project/${projectId}`,
  );

  if (!data) {
    return undefined;
  }

  const result = {
    value: data,
    label: data.name,
  };

  return result;
}

export { handleFetchInitialValue };
