import React, { useEffect, useState } from 'react';

import { parseCookies, setCookie } from 'nookies';
import { BiCookie } from 'react-icons/bi';
import { useToast } from '../../../../hooks/toast';
import { Switch } from '../../atoms/Switch';

import * as S from './styles';

export function ModalCookies() {
  const [isOpen, setIsOpen] = useState(false);
  const [switchOn, setSwitchOn] = useState(false);
  const { addToast } = useToast();
  window.onclick = (e) => {
    const { target } = e;

    if (target) {
      if (!(target as HTMLButtonElement).matches('.cookie-menu')) {
        setIsOpen(false);
      }
    }
  };

  async function handleSwitchInteraction() {
    setCookie(
      undefined,
      'v4company.cookiesAccepted',
      switchOn ? 'basic' : 'analytical',
      {
        path: '/',
        domain: process.env.REACT_APP_DOMAIN_NAME,
      },
    );
    addToast({
      type: 'success',
      title: `Cookies analíticos ${
        switchOn ? 'habilitados' : 'desabilitados'
      } com sucesso!`,
    });
    setSwitchOn(!switchOn);
  }

  useEffect(() => {
    const { 'v4company.cookiesAccepted': cookiesAccepted } = parseCookies();

    if (!cookiesAccepted) {
      return;
    }
    if (cookiesAccepted === 'basic') {
      setSwitchOn(false);
    } else {
      setSwitchOn(true);
    }
  }, []);

  return (
    <S.CookieMenu>
      <div
        className="cookie-menu"
        aria-hidden="true"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <BiCookie size={33} />
      </div>
      <S.CookieOptions isHidden={isOpen}>
        <h5>Opções</h5>
        <span>Selecione os tipos de cookies</span>

        <S.Options>Essenciais</S.Options>
        <S.Options>
          Analíticos{' '}
          <Switch
            active={switchOn}
            onClick={(e) => {
              e.stopPropagation();
              handleSwitchInteraction();
            }}
          />
        </S.Options>
      </S.CookieOptions>
    </S.CookieMenu>
  );
}
