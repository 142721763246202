import styled from 'styled-components';

export const IntegrationParagraph = styled.p`
  width: 90%;
`;

export const IntegrationLink = styled.p`
  word-wrap: break-word;
  white-space: normal !important;
  max-width: 15rem;
`;

export const BreakContent = styled.div`
  & p {
    word-break: break-all;
    white-space: normal;
  }
`;

export const TableData = styled.td`
  display: flex;
`;
