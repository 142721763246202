import styled from 'styled-components';

export const SwitchWrapper = styled.button`
  position: relative;
  display: flex;
  align-items: center;

  width: 2.125rem;
  height: 1.25rem;

  & > .switch-track {
    position: absolute;

    width: 100%;
    height: 0.875rem;

    z-index: 1;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.33);

    transition: left 0.3s, background-color 0.3s;
  }

  & > .switch-button {
    position: absolute;
    left: 0;

    width: 1.25rem;
    height: 1.25rem;

    z-index: 2;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: var(--white);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);

    transition: left 0.3s, background-color 0.3s;
  }

  &.active {
    & > .switch-track {
      background: var(--grayscale-2);
      border: 1px solid rgba(0, 0, 0, 0.12);
    }

    & > .switch-button {
      background-color: var(--success-2);
      left: calc(100% - 1.25rem);
    }
  }

  &.small {
    width: 1.5rem;
    height: 0.875rem;

    & > .switch-track {
      width: 100%;
      height: 0.625rem;
    }

    & > .switch-button {
      width: 0.875rem;
      height: 0.875rem;
    }

    &.active > .switch-button {
      left: calc(100% - 0.875rem);
    }
  }

  border: none;
  background-color: transparent;
`;
