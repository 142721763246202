import { usersApi as api } from '../../../api';

export interface updateCapacity {
  _id: string;
  defaultCapacityId?: string;
  unitId?: string;
  maxWorkload?: number;
  currentWorkload?: number;
  daysInOnboarding: number;
  isOnboarding?: boolean;
}

export async function updateCapacityOfManyInvestors(data: updateCapacity[]) {
  try {
    const response: updateCapacity[] = await api.put(
      `/capacity/user/update-many`,
      { data },
    );
    const createdCustomer = response;

    return createdCustomer;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao atualizar a capacidade dos investidores, atualize a página e tente novamente 😕`,
      err.response.data,
    );
    return err.response.data;
  }
}
