export const workedAtOthersUnit = [
  {
    label: 'Não',
    value: 'false',
  },
  {
    label: 'Sim',
    value: 'true',
  },
  {
    label: 'Não sei dizer',
    value: null,
  },
];
