import styled, { keyframes } from 'styled-components';

import signInBackgroundImg from '../../assets/sign-in-background.jpg';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;

  background: #f6f6f6;
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 36rem;
`;

export const Terms = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: absolute;
  padding: 0 4rem;
  bottom: 1rem;

  a {
    color: black;
    text-decoration: underline;
  }
`;

const apperFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(4rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12rem;
  padding-right: 10rem;
  margin-top: 1rem;

  svg {
    width: 14rem !important;
    height: 14rem !important;

    &:last-child {
      display: none;
    }
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${apperFromRight} 1s;

  h3 {
    font-size: 1.5rem;
    font-family: 'Poppins', sans-serif;
    margin-top: 0.5rem;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  form {
    margin: 4rem 0;
    width: 22rem;
    text-align: center;

    svg {
      margin-right: 0.5rem;
    }

    a {
      color: var(--grayscale-2);
      display: block;
      margin-top: 1rem;
      text-decoration: none;
      transition: 0.4s;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  button {
    width: 100%;
    margin-top: 1rem;
  }

  > a {
    color: var(--primary-main);
    display: block;
    text-decoration: none;
    transition: 0.4s;

    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.5;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)),
    url(${signInBackgroundImg}) no-repeat center;
  background-size: cover;
`;
