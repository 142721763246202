import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-basis: 33.3%;

  padding: 1.5rem 2rem;
  min-width: 22.75rem;

  border-radius: 8px;

  border: 1px solid var(--grayscale-10);
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.12);

  svg {
    margin-bottom: 1.5rem;
  }

  button {
    width: 10.1875rem;
    padding: 0.5rem;
    height: 2rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  flex-grow: 1;
`;

export const ModalOverlay = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  z-index: 30;

  background-color: rgb(0 0 0 / 25%);

  &.show {
    display: flex;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  max-height: calc(100vh - 2rem);

  border-radius: 8px;
  background-color: var(--white);
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 27px 40px 29px 40px;

  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);

  & .close-button {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    cursor: pointer;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.5rem;

  padding: 40px;

  overflow: auto;

  & table {
    flex-grow: 1;

    tr {
      height: 3.5rem;

      border-bottom: 1px solid #afafaf;
      border-radius: 0;

      p.color-error {
        color: var(--error);
      }

      &:hover {
        background-color: initial;
        border-color: transparent;
        border-bottom-color: #afafaf;
        box-shadow: none;
      }
    }

    & tbody tr .text-wrapper {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  padding: 1.75rem 2.5rem;
`;

export const TemplatesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  padding: 0 1.5rem;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 1rem;

  & p.bold {
    font-weight: 700;
  }
`;

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: calc(50vh * 1.56);
  margin: 0 1.5rem;
  gap: 1.5rem;

  p {
    flex: 1;
  }
`;
