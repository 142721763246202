import { LastUpdates } from '../../../types/lastUpdates';
import { customerApi as api } from '../../api';

export async function createLastUpdate(
  formData: FormData,
): Promise<LastUpdates | false> {
  try {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const response = await api.post<{ data: LastUpdates }>(
      `/lastUpdates`,
      formData,
      config,
    );

    const lastUpdates: LastUpdates = response.data.data;

    return lastUpdates;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os dados dos clientes, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
