import {
  Container,
  Breadcrumb,
  Section,
  SectionContent,
  Headline,
  Paragraph,
} from '../../components/LegacyV4Components';

import * as S from './styles';
import { questions, IQuestion } from './questions';

import { QuestionAccordion } from '../../components/AtomicDesign/molecules/QuestionAccordion';

export function IntegrationsManual() {
  const breadcrumbsItems = [
    {
      title: 'MediaHub',
      link: '/clientes/mediahub',
    },
    {
      title: 'Manual',
      link: '/clientes/mediahub/duvidas-frequentes',
    },
  ];

  return (
    <Container>
      <Breadcrumb breadcrumbs={breadcrumbsItems} />
      <Section>
        <SectionContent>
          <Headline variant="h3">Dúvidas frequentes</Headline>
          <S.ManualContainer>
            <S.ManualTitle>
              <Headline variant="h4">Manual de uso</Headline>
              <div>
                <Headline variant="h5">
                  Você pode conectar as contas de anúncio de seus clientes no
                  Mkt.Lab de dois modos:
                </Headline>
                <Paragraph>
                  1. Por meio da página dos clientes da sua unidade, no menu
                  &quot;Configurações&quot;;
                  <br />
                  2. Por meio da página de um cliente específico, no bloco
                  &quot;Contas de anúncio&quot;, acessando o menu &quot;Ver
                  todas&quot;.
                </Paragraph>
              </div>
            </S.ManualTitle>
            <S.QuestionsWrapper>
              {questions.map((item: IQuestion) => (
                <QuestionAccordion
                  question={item.question}
                  description={item.description}
                >
                  {item.content}
                </QuestionAccordion>
              ))}
            </S.QuestionsWrapper>
          </S.ManualContainer>
        </SectionContent>
      </Section>
    </Container>
  );
}
