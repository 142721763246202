import styled from 'styled-components';

export const Container = styled.div`
  padding: 4rem;
  margin: 4rem;
  border-radius: 0.5rem;
  background: var(--white);
  font-family: 'Nunito Sans';

  p {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }

  button {
    margin-top: 1rem;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    padding: 2rem;
  }
`;

export const ModalAddsCredits = styled.div`
  width: 44rem;
  height: 22rem;
  background: var(--white);
  border-radius: 0.4rem;
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.5rem;

  h4 {
    font-size: 1.38rem;
    font-weight: 700;
    color: var(--secondary-main);
    margin-top: 2rem;
  }

  strong {
    width: 100%;
    overflow: auto;
    margin-top: 1.8rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
  }

  small {
    font-weight: 600;
    font-family: 'Nunito Sans';
  }

  .closeButton {
    position: absolute;
    right: 2rem;
    top: 1.2rem;
    cursor: pointer;
  }
`;

export const ButtonsModal = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  gap: 1rem;

  .buttonCancel {
    width: 13.9rem;
    height: 2.5rem;
    background: transparent;
    border-radius: 0.25rem;
    border: 0.15rem solid var(--primary-dark-2);
    color: var(--primary-dark-2);
    font-weight: 600;

    &:hover,
    &:active {
      color: var(--white);
      background-color: var(--primary-dark-2);
    }
  }

  .buttonAddCredits {
    width: 13.9rem;
    height: 2.5rem;
    background-color: var(--success-2);
    border-radius: 0.25rem;
    border: 0;
    color: var(--secondary-main);
    font-weight: 600;

    &:hover,
    &:active {
      color: var(--white);
    }
  }
`;

export const Form = styled.form`
  width: max-content;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const AreaRadioButton = styled.button`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 2.8rem;
  padding: 0.62rem;
  border-radius: 0.31rem;
  border: 0.07rem solid var(--tertiary-dark);
  cursor: pointer;
  background: none;

  svg {
    margin-left: 0.6rem;
    margin-right: 0.3rem;
    margin-top: 0.3rem;
  }
  #financeiro {
    border: 0.08rem solid var(--grayscale-60);

    ::before {
      width: 0.63rem;
      height: 0.59rem;
    }
  }

  #bonus {
    border: 0.08rem solid var(--grayscale-60);

    ::before {
      width: 0.63rem;
      height: 0.59rem;
    }
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.7rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  button {
    width: 100%;
    max-width: 13.4rem;
    min-width: 13.4rem;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;

    button {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
  }
`;

export const AllContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1.8rem;

  button {
    margin-top: 2.3rem;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const InputsContainer = styled.div<{
  creditType: string;
}>`
  width: 100%;
  display: ${({ creditType }) => creditType === 'BONUS' && 'flex'};
  gap: 1.8rem;

  div {
    width: 90%;
  }

  input {
    height: 2.9rem;
  }

  @media screen and (max-width: 768px) {
    div {
      width: 100%;
    }

    display: block;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CreditDescription = styled.textarea<{
  creditType: string;
}>`
  width: ${({ creditType }) => (creditType === 'BONUS' ? '100%' : '44rem')};
  height: 5.2rem;
  resize: none;
  border-radius: 0.18rem;
  border: 0.06rem solid var(--grayscale-4);
  padding: 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--secondary-light);
  font-family: 'Nunito Sans';

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FieldsBelow = styled.div`
  display: flex;
`;

export const Info = styled.div`
  width: 45rem;
  padding: 0.6rem;
  border-radius: 0.31rem;
  border: 0.06rem solid var(--success-2);
`;

export const Required = styled.samp`
  color: var(--error);
`;

export const Error = styled.samp`
  color: var(--error);
  margin-top: 0.5rem;
`;

export const Label = styled.p`
  font-weight: bold;
  font-size: 1rem;
  font-family: Inter;
`;

export const Title = styled.h1`
  margin-top: 1.5rem;
  margin-bottom: 1.2rem;
`;
