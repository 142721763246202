import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type EletromidiaUserContextData = {
  eletromidiaUser: UserData;
  setEletromidiaUser: Dispatch<SetStateAction<UserData>>;
  eletromidiaToken: string;
  setEletromidiaToken: Dispatch<SetStateAction<string>>;
  eletromidiaLoading: boolean;
  setEletromidiaLoading: Dispatch<SetStateAction<boolean>>;
};

export const EletromidiaUserContext = createContext(
  {} as EletromidiaUserContextData,
);

type EletromidiaUserContextProviderProps = {
  children: ReactNode;
};

export const EletromidiaUserProvider: React.FC<
  EletromidiaUserContextProviderProps
> = ({ children }: EletromidiaUserContextProviderProps) => {
  const [eletromidiaUser, setEletromidiaUser] = useState({} as UserData);

  const [eletromidiaToken, setEletromidiaToken] = useState('' as string);

  const [eletromidiaLoading, setEletromidiaLoading] = useState<boolean>(true);

  return (
    <EletromidiaUserContext.Provider
      value={{
        eletromidiaUser,
        setEletromidiaUser,
        eletromidiaToken,
        setEletromidiaToken,
        eletromidiaLoading,
        setEletromidiaLoading,
      }}
    >
      {children}
    </EletromidiaUserContext.Provider>
  );
};

export const useEletromidiaUser = (): EletromidiaUserContextData => {
  return useContext(EletromidiaUserContext);
};
