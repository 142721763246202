import React, { ReactNode } from 'react';
import * as S from './styles';

interface SmalledProps {
  variant?: 'plus' | 'bold';
  className?: string;
  children?: ReactNode;
}

export function Smalled({ variant, className = '', children }: SmalledProps) {
  const elementClass = `${variant || ''} ${className}`;

  return <S.Smalled className={elementClass}>{children}</S.Smalled>;
}
