import styled from 'styled-components';

export const Container = styled.div`
  width: 100% - 3rem;
  > section {
    background: var(--white);
    max-width: 87.5rem;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 0.5rem;

    h1 {
      margin-bottom: 2rem;
    }

    lab-ds-table {
      width: 12.5rem;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 1.5rem;
  padding-top: 2rem;
  align-content: end;
  align-items: end;

  lab-ds-button {
    width: 13rem;
  }
`;

export const Paragraph = styled.div`
  font-size: 1.375rem;
  font-weight: normal;
  margin-bottom: 2.5rem;
`;
export const Select = styled.div`
  margin: 2.5rem 0;
  width: 17.4rem;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1rem;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-left: 1rem;
  }

  button {
    text-decoration: underline;
  }
`;

export const Subtitle = styled.div`
  margin-bottom: 2rem;
`;

export const RowTable = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const Avatar = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  width: 2rem !important;
  height: 2rem !important;
  width: auto;
  height: auto;
  background: var(--primary-dark);
  margin-right: 0.313rem;
  cursor: pointer;
`;

export const Table = styled.div`
  .v4-table {
    max-height: 31rem;
    padding-bottom: 0.75rem;
    margin-bottom: 5rem;
  }
  .cell-row-components {
    align-items: left;
    justify-content: left;
  }
  .cell {
    padding-bottom: 0.75rem;
  }
`;

export const Field = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;

  img {
    margin-right: 0.5rem;
  }

  svg {
    cursor: pointer;
  }
`;

export const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-between;
  margin: 2.5rem 0;

  button {
    margin: 0 !important;
  }
`;
