import styled from 'styled-components';

export const Container = styled.main`
  background-color: var(--tertiary-dark);
  margin: 2.5% 35%;
  max-height: 90%;
  border-radius: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 40%;

  margin-bottom: 0.75rem;

  @media (max-width: 500px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 75%;
  }
`;
