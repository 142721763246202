import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem 2rem 2rem;
  width: 680px;
  margin-bottom: 1.5rem;

  .info__card {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
  }

  .sub__info__card {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    margin-top: 10px;
    margin-bottom: 25px;
  }

  .margin__top {
    margin-top: 15px;
  }

  h3 {
    margin-top: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  background: var(--white);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1), 0px 7px 32px rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;

  @media (max-width: 1225px) {
    max-width: 100%;
  }
`;

export const ProgressContent = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 20px;
  border-radius: 20px;

  background-color: #c1c1d7;
`;

export const ProgressBar = styled.div<{ porc: string }>`
  width: ${({ porc }) => porc};
  height: 20px;
  border-radius: 20px;
  background-color: #00c49a;
`;

export const DotMenu = styled.div`
  display: flex;
  position: relative;

  svg {
    right: 0;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
`;

export const DotOptions = styled.div<{ isHidden: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 1.5rem;
  right: -130px;
  transition: all 0.5s;
  border-radius: 0.5rem;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
  opacity: 0;
  max-height: 15rem;

  button {
    background: var(--white);
    padding: 1rem 1.5rem;
    border: none;
    font-weight: 400;
    text-align: left;
    pointer-events: none;
  }

  button + button {
    border-top: 1px solid var(--tertiary-dark);
  }

  ${({ isHidden }) =>
    isHidden &&
    css`
       {
        max-height: 15rem;
        overflow: hidden;
        opacity: 1;

        button {
          pointer-events: auto;
        }
      }
    `}
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RowLeft = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-bottom: 30px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 1rem;
  }

  h4 {
    margin-bottom: 0.25rem;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--secondary-many);
  }

  p {
    margin-right: 0.5rem;
    padding: 0.5rem 0;
    flex: 1;
  }
`;

export const Text = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  font-weight: 400;
  border: 1px solid transparent;
  width: 100%;

  p {
    text-align: left;
  }
`;

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid var(--tertiary-dark);
  background-color: transparent;
  border-radius: 0.25rem;

  svg {
    color: var(--primary-main);
  }
`;

export const Select = styled.select`
  display: flex;
  border: 1px solid var(--tertiary-dark);
  background-color: transparent;
  padding: 1rem;
  border-radius: 0.5rem;
  color: var(--secondary-light);
  width: 60%;
`;

export const Teams = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--tertiary-dark);
  padding: 1rem 1rem 0.5rem 1rem;
  flex-wrap: wrap;
  max-height: 10rem;
  overflow: auto;
  border-radius: 0.5rem;
`;

export const Button = styled.div<{ active?: true }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46%;
  padding: 0.5rem;
  background-color: var(--primary-light);
  margin: 0 0.25rem;
  color: var(--white);
  border: none;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  position: relative;

  small {
    text-align: left;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 14ch;
    flex: 1;
  }

  button {
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    padding: 0.1rem;
    opacity: 0;
    transition: all 0.2s ease;

    svg {
      font-size: 1rem;
    }
  }

  &:hover button {
    opacity: 1;
  }
`;
