import { useState } from 'react';
import { MdOutlineWarningAmber } from 'react-icons/md';
import { Modal } from '../../../LegacyV4Components';
import { lockUnit } from '../../../../services/requests/Unit/disableUnit';
import { AllContent, ButtonsContainer, Container } from './styles';
import { GlobalLoading } from '../../../Default/Loadings/GlobalLoading';
import { getUnitBlock } from '../../../../services/requests/Unit/getUnit';
import { useToast } from '../../../../hooks/toast';
import { useAuth } from '../../../../hooks/auth';

export interface ModalLockUnitProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  unitId: string;
  unitName?: string;
}

export default function ModalLockUnit({
  isOpen,
  setIsOpen,
  unitId,
  unitName,
}: ModalLockUnitProps) {
  const { addToast } = useToast();
  const { setIsNowBlocked } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const handleUnitLock = () => {
    setIsLoading(true);
    lockUnit(unitId, '').then((data) => {
      if (data) {
        if (data?.blockedAt) {
          setIsNowBlocked(true);
        }
        getUnitBlock(unitId);
        setIsOpen(false);
        setIsLoading(false);
        return addToast({
          type: 'success',
          title: 'Unidade bloqueada',
          description:
            'Unidade bloqueada para operações no Leadbroker e não poderá ser desbloqueada no período de 180 dias.',
        });
      }
      setIsLoading(false);

      return addToast({
        type: 'error',
        title: 'Erro no bloqueio da unidade',
        description: `Ocorreu um erro ao bloquear a unidade ${unitName || ''}.`,
      });
    });
  };

  return (
    <Container id="modalLockUnit">
      <Modal
        show={isOpen}
        onBackground={() => setIsOpen(false)}
        closeButton
        labelSubmit="Salvar Permissões"
      >
        <AllContent>
          <MdOutlineWarningAmber size={45} color="var(--error" />

          <h2>Tem certeza que deseja bloquear a unidade?</h2>

          <strong>
            Esta unidade não poderá ser desbloqueada no período de 180 dias.
          </strong>

          <ButtonsContainer>
            <button
              type="button"
              className="cancel"
              onClick={() => setIsOpen(false)}
            >
              Cancelar
            </button>

            <button type="button" className="block" onClick={handleUnitLock}>
              {isLoading ? (
                <GlobalLoading
                  backgroundColor="var(--white)"
                  width="1.60rem"
                  height="1.60rem"
                  border="0.2rem solid var(--success-2);"
                />
              ) : (
                'Bloquear unidade'
              )}
            </button>
          </ButtonsContainer>
        </AllContent>
      </Modal>
    </Container>
  );
}
