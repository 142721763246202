import React from 'react';

import CurrencyInput from 'react-currency-input-field';

import { NumberFormatProps } from 'react-number-format';
import { Smalled } from '../../../LegacyV4Components';
import * as S from './styles';

export type TextFieldStatus =
  | 'default'
  | 'focus'
  | 'filled'
  | 'error'
  | 'warning'
  | 'success';

export function FormikCurrencyInput({ ...props }: NumberFormatProps) {
  let inputStatus = '';

  if (props.value !== '') {
    inputStatus = 'filled';
  }

  if (props.helper) {
    inputStatus = 'error';
  }

  if (props.status === 'default') {
    inputStatus = 'default';
  }

  return (
    <S.InputWrapper
      className={inputStatus}
      style={{ width: props.width ? `${props.width}` : 'auto' }}
    >
      <label htmlFor={props.name}>
        <h6>{props.label}</h6>
        <S.InputControl
          className="input-control"
          style={{
            width: props.width ? `${props.width}` : 'auto',
            background: '#fff',
          }}
        >
          <CurrencyInput
            style={{ width: '-webkit-fill-available' }}
            name="value"
            placeholder="R$0,00"
            defaultValue={0.0}
            decimalsLimit={2}
            onValueChange={props.onValueChange as any}
            value={props.value}
            prefix="R$"
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            className="input-currency"
          />
        </S.InputControl>
        {props.helper ? <Smalled>{props.helper}</Smalled> : null}
        {props.status === 'warning' ? (
          <Smalled>{props.warningMessage}</Smalled>
        ) : null}
      </label>
    </S.InputWrapper>
  );
}
