import { AiOutlineInfoCircle, AiOutlineUser } from 'react-icons/ai';
import { FiCopy } from 'react-icons/fi';
import { VscLocation } from 'react-icons/vsc';

import { useToast } from '../../../../hooks/toast';
import { cepWithSeparator } from '../../../../utils/cepWithSeparator';
import * as S from './styles';

export default function CustomerInformation({ customer }: { customer: any }) {
  const { addToast } = useToast();

  const missingStakeholderInfo =
    !customer?.stakeholders ||
    !customer?.stakeholders[0] ||
    !customer?.stakeholders[0]?.name ||
    !customer?.stakeholders[0]?.email ||
    !customer?.stakeholders[0]?.tel;

  const dontHaveAddressNumber = Number.isNaN(
    parseInt(customer?.address?.number, 10),
  );

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);

    addToast({
      type: 'success',
      title: 'Copiado para a área de transferência!',
    });
  }

  return (
    <S.CardRow>
      <S.CardInfo style={{ marginLeft: 0 }}>
        <S.CardHeader>
          <AiOutlineInfoCircle />
          <S.CardTitle>Informações empresa</S.CardTitle>
        </S.CardHeader>

        <S.CardBody>
          <S.CardItem>
            <S.CardLabel>Razão Social:</S.CardLabel>
            <span>{customer?.name || '-'}</span>
          </S.CardItem>

          <S.CardItem>
            <S.CardLabel>CNPJ:</S.CardLabel>
            <span>
              {customer?.cnpj}{' '}
              <FiCopy onClick={() => copyToClipboard(customer.cnpj)} />
            </span>
          </S.CardItem>
        </S.CardBody>
      </S.CardInfo>

      <S.CardInfo
        style={{
          width: '50%',
          color: `${
            dontHaveAddressNumber || !customer?.address?.district
              ? 'red'
              : 'black'
          }`,
        }}
      >
        <S.CardHeader>
          <VscLocation />
          <S.CardTitle>Localização</S.CardTitle>
        </S.CardHeader>

        <p style={{ color: 'red', fontWeight: 'bold' }}>
          {dontHaveAddressNumber ? 'FALTA INFORMAÇÕES ' : ''}
        </p>

        <S.CardBody>
          <S.CardRow>
            <S.CardItem>
              <S.CardLabel>Endereço:</S.CardLabel>
              <span>
                {`${customer?.address?.street || ''}`}{' '}
                {`${customer?.address?.city || ''} - `}
                {`${customer?.address?.state || ''}`}
              </span>
            </S.CardItem>
          </S.CardRow>

          {customer?.address?.district?.length > 10 ? (
            <S.CardRow>
              <S.CardItem className="sm">
                <S.CardLabel className="sm-label">Bairro:</S.CardLabel>
                <span className="sm-span">
                  {customer?.address?.district || '-'}
                </span>
              </S.CardItem>
            </S.CardRow>
          ) : (
            <></>
          )}

          {customer?.address?.complement?.length > 20 ? (
            <S.CardRow>
              <S.CardItem className="sm">
                <S.CardLabel className="sm-label">Complemento:</S.CardLabel>
                <span className="sm-span">
                  {customer?.address?.complement || '-'}
                </span>
              </S.CardItem>
            </S.CardRow>
          ) : (
            <></>
          )}

          <S.CardRow>
            <S.CardItem className="sm">
              <S.CardLabel className="sm-label">Número:</S.CardLabel>
              <span className="sm-span">
                {dontHaveAddressNumber ? '-' : customer?.address?.number}
              </span>
            </S.CardItem>

            <S.CardItem className="sm">
              <S.CardLabel className="sm-label">Cep:</S.CardLabel>
              <span className="sm-span">
                {customer?.address?.zipCode &&
                  cepWithSeparator(customer?.address?.zipCode)}
                <FiCopy
                  onClick={() => copyToClipboard(customer?.address?.zipCode)}
                />
              </span>
            </S.CardItem>

            {customer?.address?.complement?.length <= 20 ? (
              <S.CardItem className="sm">
                <S.CardLabel className="sm-label">Complemento:</S.CardLabel>
                <span className="sm-span">
                  {customer?.address?.complement || '-'}
                </span>
              </S.CardItem>
            ) : (
              <></>
            )}

            {customer?.address?.district?.length <= 10 ||
            !customer?.address?.district ? (
              <S.CardItem className="sm">
                <S.CardLabel className="sm-label">Bairro:</S.CardLabel>
                <span className="sm-span">
                  {customer?.address?.district || '-'}
                </span>
              </S.CardItem>
            ) : (
              <></>
            )}
          </S.CardRow>
        </S.CardBody>
      </S.CardInfo>

      <S.CardInfo
        style={{
          marginRight: 0,
          borderColor: `${missingStakeholderInfo ? 'red' : ''}`,
          color: `${missingStakeholderInfo ? 'red' : ''}`,
        }}
      >
        <S.CardHeader>
          <AiOutlineUser />
          <S.CardTitle>Stakeholder Principal</S.CardTitle>
        </S.CardHeader>

        <p style={{ color: 'red', fontWeight: 'bold' }}>
          {missingStakeholderInfo ? 'FALTA INFORMAÇÕES ' : ''}
        </p>

        {customer?.stakeholders && (
          <S.CardBody>
            <S.CardItem>
              <S.CardLabel>Nome:</S.CardLabel>
              <span>{customer?.stakeholders[0]?.name || '-'}</span>
            </S.CardItem>

            <S.CardItem>
              <S.CardLabel>E-mail:</S.CardLabel>
              <span>{customer?.stakeholders[0]?.email || '-'}</span>
            </S.CardItem>

            <S.CardItem>
              <S.CardLabel>Telefone:</S.CardLabel>
              <span>
                {customer?.stakeholders[0]?.tel || '-'}{' '}
                <FiCopy
                  onClick={() =>
                    copyToClipboard(customer?.stakeholders[0]?.tel)
                  }
                />
              </span>
            </S.CardItem>
          </S.CardBody>
        )}
      </S.CardInfo>
    </S.CardRow>
  );
}
