import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../../../LegacyV4Components';
import * as S from './styles';
import {
  BodyPermissions,
  EditPermissions,
  EditPermissionsProps,
  SetPermissionsFunction,
} from './interfaces';
import { createEmailInvestorSchema } from './schemas';
import { useToast } from '../../../../hooks/toast';
import { Permissions } from './components';
import { formatPhone } from '../../../../utils/formatPhone';
import { getPermissionUser } from '../../../../services/requests/Unit/getPermissionUser';
import { updatePermissionUser } from '../../../../services/requests/Unit/updatePermissionUser';
import { useAuth } from '../../../../hooks/auth';

export default function ModalPermissionsEdit({
  isOpen,
  setIsOpen,
  user,
  isNowBlocked,
}: EditPermissionsProps) {
  const toastContext = useToast();
  const navigate = useNavigate();

  const { user: userAuth } = useAuth();

  const isHeadquarter =
    userAuth.unitId === process.env.REACT_APP_HEADQUARTER_ID;

  const [userPermissions, setUserPermissions] = useState<BodyPermissions>(
    {} as BodyPermissions,
  );

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<EditPermissions>({
    defaultValues: {
      dashboardRole: 'READ_ONLY',
      leadbrokerRole: 'READ_ONLY',
      blackBoxRole: 'READ_ONLY',
      dealBrokerRole: 'READ_ONLY',
      transitionRole: 'CAN_NOT_SEE',
      unitRole: 'READ_ONLY',
      customerRole: 'READ_ONLY',
      headquarterRole: 'CAN_NOT_SEE',
      paymentGatewayRole: 'CAN_NOT_SEE',
      userRole: 'READ_ONLY',
    },
    resolver: yupResolver(createEmailInvestorSchema),
  });

  useEffect(() => {
    const handlerGetPermissions = async () => {
      const permissions = await getPermissionUser({
        unitId: user.unitId,
        userId: user._id,
      });

      setUserPermissions(permissions);
    };

    handlerGetPermissions();
  }, [user]);

  const onSubmit = async (data: EditPermissions) => {
    const response = await updatePermissionUser({
      ...data,
      unitId: user.unitId,
      userId: user._id,
    });

    if (response.statusCode && response.statusCode !== 200) {
      toastContext.addToast({
        type: 'error',
        title: response.name ?? 'Não foi possivel completar a ação!',
        description:
          response.message ??
          'Ocorreu algum problema ao convidar este usuário 😕',
      });
    } else {
      toastContext.addToast({
        type: 'success',
        title: 'Permissões atualizadas com sucesso!',
        description: `As permissões do Investidor ${user.name} foram atualizadas com sucesso.`,
      });
    }
  };

  const handlerSetPermissions: SetPermissionsFunction = (name, value) => {
    setValue(name, value);
  };

  const handlerGoProfile = (id: string, name: string) => {
    const routes = [
      {
        title: name,
        link: `/`,
      },
    ];

    return navigate(
      `/perfil/${id}?origin=${encodeURI(JSON.stringify(routes))}`,
    );
  };

  const handlerClick = (e: any) => {
    e.stopPropagation();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal
        show={isOpen}
        onBackground={() => setIsOpen(false)}
        closeButton
        labelSubmit="Salvar Permissões"
        title={user?.name}
        footer
        height="800px"
        loading={isSubmitting}
        buttonType="submit"
        handlerSubmit={() => handleSubmit(onSubmit)}
      >
        <S.Container style={{ marginBottom: 100 }}>
          <S.Row>
            <div onClick={(e) => handlerClick(e)} aria-hidden="true">
              <S.Avatar
                onClick={() => handlerGoProfile(user?._id, user?.name)}
                src={user?.picture}
                alt={user?.picture}
              />
            </div>
            <S.Coll>
              <div className="item">
                <h4>Email: </h4>
                <p> {user?.email}</p>
              </div>
              <div className="item">
                <h4>Telefone:</h4>
                <p>
                  {user?.phone
                    ? formatPhone(user?.phone)
                    : 'Telefone não informado'}
                </p>
              </div>
            </S.Coll>

            <div>
              <h4>Data de início</h4>
              <p>
                {user?.startedAt &&
                  new Date(user?.startedAt).toLocaleDateString()}
              </p>
            </div>
          </S.Row>

          <Permissions
            isHeadquarter={isHeadquarter}
            userPermissionsUser={userPermissions}
            error={errors}
            setPermissions={handlerSetPermissions}
            isNowBlocked={isNowBlocked}
          />
        </S.Container>
      </Modal>
    </form>
  );
}
