import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';
import { v4 } from 'uuid';

import { NumberFormatValues } from 'react-number-format';
import { Button, Headline } from '../../../LegacyV4Components';
import { Modal } from '../../../Default/Modal';

import * as S from './styles';
import { FormikCurrencyInput } from '../../molecules/FormikCurrencyInput';
import { useToast } from '../../../../hooks/toast';
import { SelectField } from '../../molecules/SelectField';
import { FormikPercentageInput } from '../../molecules/FormikPercentageInput';
import { NumberField } from '../../molecules/NumberField';
import { TextField } from '../../molecules/TextField';
import { createPlan } from '../../../../services/requests/Finance/createPlan';

export function GeneratePlanModal({
  isShown,
  setIsShown,
  setIsOpenSharedLink,
  setDataPlan,
  customerId,
}: {
  isShown: boolean;
  setIsShown(value: boolean): any;
  setIsOpenSharedLink(value: boolean): any;
  setDataPlan(value: object): any;
  customerId: string;
}) {
  const [selectMethod, setSelectMethod] = useState('');
  const [selectIntervalType, setSelectedIntervalType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const sendFormRef = useRef<HTMLButtonElement>(null);
  const { addToast } = useToast();

  const configBankSchema = Yup.object().shape({
    name: Yup.string().required('O campo é obrigatório'),
    value: Yup.number().required('O campo é obrigatório'),
    royalties: Yup.number().required('O campo é obrigatório'),
    billing_days: Yup.number(),
    max_cycles: Yup.number().required('O campo é obrigatório'),
    interval: Yup.number().required('o campo é obrigatório'),
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          name: '',
          value: '',
          royalties: 15,
          paymentMethod: '',
          billing_days: '',
          intervalType: '',
          interval: '',
          max_cycles: '',
          paymentMethodCredit: false,
        }}
        validationSchema={configBankSchema}
        onSubmit={() => {
          setIsLoading(true);
          isLoading;
        }}
      >
        {({
          errors,
          values,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
          touched,
        }) => {
          async function handleSubmit() {
            const dataToSend = {
              name: values.name,
              identifier: v4(),
              interval: values.interval,
              intervalType: selectIntervalType,
              valueCents: Number(values.value) * 100,
              royalties: values.royalties,
              payableWith: [values.paymentMethodCredit ? 'credit_card' : ''],
              customerId,
              maxCycles: Number(values.max_cycles),
            };

            setIsLoading(true);
            const plan = (await createPlan(dataToSend)) as any;

            if (plan?.status === 'error') {
              if (
                plan?.message ===
                'Failed to get unit data, check customers unity'
              ) {
                addToast({
                  title: 'Falha ao encontrar unidade do cliente!',
                  description:
                    'Atualize a unidade do cliente e tente novamente',
                  type: 'error',
                });
              } else {
                addToast({
                  title: 'Ocorreu um problema ao criar o plano!',
                  description: 'Atualize a página e tente novamente',
                  type: 'error',
                });
              }
            } else {
              addToast({
                title: 'Plano criado com sucesso!',
                type: 'success',
              });

              setDataPlan(plan);
              setIsOpenSharedLink(true);
              setIsShown(false);
            }

            setIsLoading(false);
          }
          return (
            <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
              <>
                <S.ModalWrapper>
                  <S.ModalHeader>
                    <Headline variant="h4">Criar Novo Plano</Headline>
                    <button
                      type="button"
                      onClick={() => setIsShown(false)}
                      aria-label="default"
                    >
                      <FiX />
                    </button>
                  </S.ModalHeader>
                  <S.ModalBody>
                    <Headline variant="h6">
                      Preencha as informações abaixo para que possa cadastrar o
                      novo plano
                    </Headline>
                    <Form>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          marginBottom: '1rem',
                          marginTop: '0.5rem',
                        }}
                      >
                        <div
                          style={{
                            maxWidth: '100%',
                            width: '100%',
                          }}
                        >
                          <TextField
                            name="name"
                            label="Nome do plano *"
                            value={values.name}
                            width="100%"
                            placeholder="Informe o nome do plano"
                            helper={
                              touched?.name && errors?.name ? errors?.name : ''
                            }
                            validateOnChange={false}
                            onBlur={handleBlur}
                            onValueChange={(val: NumberFormatValues) => {
                              setFieldValue(`name`, val.value);
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          marginBottom: '1rem',
                          marginTop: '0.5rem',
                        }}
                      >
                        <FormikCurrencyInput
                          label="Valor"
                          width="100%"
                          helper={errors.value}
                          onBlur={handleBlur}
                          onValueChange={(value) => {
                            setFieldValue('value', value);
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          marginBottom: '1rem',
                          maxWidth: '100%',
                        }}
                      >
                        <FormikPercentageInput
                          number
                          width="100%"
                          name="royalties"
                          label="Royalties"
                          value={values.royalties}
                          placeholder="0%"
                          helper={errors.royalties}
                          validateOnChange={false}
                          onBlur={handleBlur}
                          onValueChange={(value) => {
                            setFieldValue('royalties', value);
                          }}
                        />
                      </div>
                      <div style={{ marginBottom: '1rem' }}>
                        <Headline className="label" variant="h6">
                          Selecione um ou mais meios de pagamento
                        </Headline>

                        <S.ContainerRadio
                          color={
                            selectMethod === 'credit_card'
                              ? '1px solid #00C49A'
                              : ''
                          }
                          onClick={() => {
                            setSelectMethod('credit_card');
                            setFieldValue(
                              'paymentMethodCredit',
                              !values.paymentMethodCredit,
                            );
                          }}
                        >
                          <input
                            value="paymentMethodCredit"
                            type="checkbox"
                            checked={values.paymentMethodCredit}
                            onChange={() => {
                              setSelectMethod('credit_card');
                              setFieldValue(
                                'paymentMethodCredit',
                                !values.paymentMethodCredit,
                              );
                            }}
                          />
                          <span>Cartão de crédito</span>
                        </S.ContainerRadio>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          marginBottom: '1rem',
                          marginTop: '0.5rem',
                        }}
                      >
                        <div
                          style={{
                            maxWidth: '100%',
                            width: '100%',
                          }}
                        >
                          <Headline className="label" variant="h6">
                            Selecione o intervalo de cada pagamento *
                          </Headline>
                          <SelectField
                            width="100%"
                            name="intervalType"
                            value={values.intervalType}
                            placeholder="Selecione um intervalo"
                            options={[
                              { label: 'Mensal', id: 'months' },
                              { label: 'Semanal', id: 'weeks' },
                            ]}
                            onChange={(e: any) => {
                              const changeObject = e as {
                                id: string;
                                label: string;
                              };
                              setFieldValue('intervalType', changeObject.label);
                              setSelectedIntervalType(changeObject.id);
                            }}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          marginBottom: '1rem',
                          marginTop: '0.5rem',
                        }}
                      >
                        <div
                          style={{
                            maxWidth: '100%',
                            width: '100%',
                          }}
                        >
                          <NumberField
                            format="###"
                            name="interval"
                            label="Intervalo até a próxima cobrança. *"
                            value={values.interval}
                            placeholder="Informe o tempo"
                            helper={
                              touched?.interval && errors?.interval
                                ? errors?.interval
                                : ''
                            }
                            validateOnChange={false}
                            onBlur={handleBlur}
                            onValueChange={(val: NumberFormatValues) => {
                              setFieldValue(`interval`, val.value);
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          marginBottom: '1rem',
                          marginTop: '0.5rem',
                        }}
                      >
                        <div
                          style={{
                            maxWidth: '100%',
                            width: '100%',
                          }}
                        >
                          <NumberField
                            format="###"
                            name="max_cycles"
                            label="Limite de ciclos"
                            value={values.max_cycles}
                            placeholder="Informe o número"
                            helper={
                              touched?.max_cycles && errors?.max_cycles
                                ? errors?.max_cycles
                                : ''
                            }
                            validateOnChange={false}
                            onBlur={handleBlur}
                            onValueChange={(val: NumberFormatValues) => {
                              setFieldValue(`max_cycles`, val.value);
                            }}
                          />
                        </div>
                      </div>
                      <button
                        ref={sendFormRef}
                        type="submit"
                        style={{ display: 'none' }}
                      >
                        Enviar
                      </button>
                    </Form>
                  </S.ModalBody>
                  <S.ModalFooter>
                    <>
                      <Button
                        variant="primary"
                        color="green"
                        size="default"
                        onClick={async () => {
                          handleSubmit();
                        }}
                        disabled={!(isValid && dirty)}
                      >
                        Criar e Compartilhar
                      </Button>
                    </>
                  </S.ModalFooter>
                </S.ModalWrapper>
              </>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
}
