import { UnitInterface } from '../../../types/units';
import { createUnit } from '../../../types/createUnit';
import { unitsApi as api } from '../../api';

export async function createNewUnit(
  data: createUnit,
): Promise<UnitInterface | string> {
  try {
    const response = await api.post<UnitInterface>(`/units`, data);
    const unit = response.data;
    return unit;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o unidade, atualize a página e tente novamente 😕`,
      err,
    );
    return err.response.data.status ? 'error' : err.response.data.message;
  }
}
