import { useState } from 'react';
import { MdOpenInFull } from 'react-icons/md';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import { Dashboard } from '../../../../services/dashboardsRequests';

import * as S from './styles';

interface Props {
  dashboard: Dashboard;
}

export function DashboardViewer({ dashboard }: Props) {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const handle = useFullScreenHandle();

  return (
    <S.Container isFullscreen={isFullscreen}>
      <S.Dashboard>
        <MdOpenInFull
          onClick={() => {
            handle.enter();
            setIsFullscreen(true);
          }}
        />

        <FullScreen handle={handle}>
          <iframe id="myiFrame" title="DashboardView" src={dashboard.link} />

          {dashboard.link.includes('powerbi') && (
            <S.ViewBlocker isFullscreen={isFullscreen} />
          )}
        </FullScreen>
      </S.Dashboard>
    </S.Container>
  );
}
