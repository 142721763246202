import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { RiPencilLine, RiPlantLine } from 'react-icons/ri';
import { MdOutlineHub } from 'react-icons/md';
import {
  Container,
  SectionContent,
  Breadcrumb,
  Section,
  SectionTitle,
  Paragraph,
  Button,
} from '../../components/LegacyV4Components';
import * as S from './styles';
import {
  ColumnProps,
  DataGrid,
  DataProps,
} from '../../components/AtomicDesign/atoms/DataGrid';
import { getUsersByUnitId } from '../../services/requests/Unit/getUsersByUnitId';
import { ProjectsModal } from '../../components/AtomicDesign/molecules/ProjectsModal';
import { convertNumberToBRL } from '../../utils/convertNumberToBRL';
import { User } from '../../hooks/types';
import { useAuth } from '../../hooks/auth';
import { AllCapacitiesModal } from '../../components/AtomicDesign/organisms/AllCapacitiesModal';

export function OperationalCapacity() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [showProjectsModal, setShowProjectsModal] = useState<boolean>(false);
  const [investorSelected, setInvestorSelected] = useState<string>('');
  const [userInfos, setUserInfos] = useState<User>();
  const [modalShowAllCapacities, setModalShowAllCapacities] =
    useState<boolean>(false);
  const { id } = useParams<{ id: string }>();

  const { data } = useInfiniteQuery(['users'], async () => {
    const usersPromise = await getUsersByUnitId({
      unitId: id || '',
      isActive: true,
      showDeleted: false,
    });
    return usersPromise;
  });

  const tableColumns = [
    {
      accessor: 'name',
      label: 'Investidor',
      sizing: 1,
    },
    {
      accessor: 'mandate',
      label: 'Cargo',
      sizing: 1,
    },
    {
      accessor: 'seniority',
      label: 'Senioridade',
      sizing: 1,
    },
    {
      accessor: 'wage',
      label: 'Salário',
      sizing: 1,
    },
    {
      accessor: 'mandate',
      label: 'Disponibilidade',
      sizing: 1,
    },
    {
      accessor: 'capacity',
      label: 'Capacidade',
      message: 'Projetos que atende x capacidade total',
      sizing: 1,
    },
    {
      accessor: 'onBoarding',
      label: 'Em onboarding',
      message:
        'Dias que o investidor está em onboarding x dias totais em onboarding',
      sizing: 1,
    },
  ] as ColumnProps[];

  const canNotEditCapacity = !(
    user.permissions?.headquarter?.admin || user.permissions?.unit?.admin
  );

  const tableContent = useMemo(() => {
    const tableData: DataProps[] = [];
    data?.pages?.forEach((page) => {
      const { users } = page;
      if (users) {
        users?.forEach((currentUser) => {
          if (currentUser.mandate?.isNewPrice)
            tableData.push({
              id: currentUser._id,

              user: currentUser,
              content: (
                <>
                  <td title={currentUser.name}>
                    <S.RowTable
                      onClick={() => {
                        setInvestorSelected(currentUser._id);
                        setUserInfos(currentUser);
                        setShowProjectsModal(true);
                      }}
                    >
                      <S.Row>
                        <S.Avatar
                          onClick={() => null}
                          src={currentUser.picture}
                          alt={currentUser.picture}
                          width="0.6rem"
                          height="0.6rem"
                        />
                        <Paragraph>{currentUser.name}</Paragraph>
                        {currentUser.capacity?.isOnboarding ? (
                          <S.Infomation>
                            <div className="icon">
                              <RiPlantLine />
                              <div className="message">
                                <p>
                                  Investidor em processo de onboarding. <br />
                                  Faltam{' '}
                                  {currentUser.capacity?.daysInOnboarding -
                                    currentUser.capacity?.daysPast}{' '}
                                  dias para conclusão.
                                </p>
                              </div>
                            </div>
                          </S.Infomation>
                        ) : null}
                      </S.Row>
                    </S.RowTable>
                  </td>
                  <td title={currentUser.mandate?.name || '-'}>
                    <S.Row>
                      <Paragraph>{currentUser.mandate?.name || '-'}</Paragraph>
                      {currentUser.mandate?.name === 'Cientista' ||
                      currentUser.mandate?.name === 'Cientista do Marketing' ? (
                        <S.Infomation>
                          <div className="icon">
                            <MdOutlineHub />
                            <div className="message">
                              <p>
                                O investidor é certificado como Cientista do
                                Marketing
                              </p>
                            </div>
                          </div>
                        </S.Infomation>
                      ) : null}
                    </S.Row>
                  </td>
                  <td title={currentUser.seniority?.name || '-'}>
                    <div>
                      <Paragraph>
                        {currentUser.seniority?.name || '-'}{' '}
                      </Paragraph>
                    </div>
                  </td>
                  <td title={currentUser.wage as string}>
                    <Paragraph>
                      {currentUser.wage
                        ? convertNumberToBRL(currentUser.wage as number)
                        : 'Salário não informado!'}
                    </Paragraph>
                  </td>
                  <td title="">
                    <S.RowTable>
                      <Paragraph>-</Paragraph>
                    </S.RowTable>
                  </td>
                  <td title="">
                    <S.RowTable>
                      <Paragraph>
                        {currentUser.capacity ? (
                          <>
                            {currentUser.capacity?.currentWorkload}/
                            {currentUser.capacity.isOnboarding
                              ? Math.ceil(currentUser.capacity?.maxWorkload / 2)
                              : currentUser.capacity?.maxWorkload}
                          </>
                        ) : (
                          '-'
                        )}
                      </Paragraph>
                    </S.RowTable>
                  </td>
                  <td title="">
                    <S.RowTable>
                      <Paragraph>
                        {currentUser.capacity?.isOnboarding
                          ? `${currentUser.capacity?.daysPast} /
                          ${currentUser.capacity?.daysInOnboarding}`
                          : '-'}
                      </Paragraph>
                    </S.RowTable>
                  </td>
                </>
              ),
            });
        });
      }
    });

    return tableData.length ? tableData : null;
  }, [data?.pages]);

  return (
    <Container>
      <Breadcrumb
        breadcrumbs={[
          { title: 'Minha unidade', link: `/unit/view/head/${id}` },
          {
            title: 'Capacidade operacional',
            link: '',
          },
        ]}
      />
      <Section>
        <SectionContent>
          <S.Row style={{ justifyContent: 'space-between' }}>
            <SectionTitle text="Capacidade Operacional" />
            <Button
              size="default"
              variant="primary"
              color="green"
              onClick={() => {
                navigate(`/unit/view/editar-capacidade-operacional/${id}`);
              }}
              disabled={canNotEditCapacity}
            >
              Editar <RiPencilLine />
            </Button>
          </S.Row>
          <S.Row>
            <p>Visualize a capacidade operacional do seu time</p>
          </S.Row>
          <S.Filters>
            <S.Link
              onClick={() => {
                setModalShowAllCapacities(true);
              }}
            >
              Lista de capacidades
            </S.Link>
          </S.Filters>
          <S.DataGrid>
            <DataGrid
              setShowModalUserInvate={() => null}
              showModalUserInvate={showProjectsModal}
              columns={tableColumns}
              setUser={() => null}
              isAdmin
              data={tableContent || []}
            />
          </S.DataGrid>
        </SectionContent>
      </Section>
      {showProjectsModal && (
        <ProjectsModal
          isOpen={showProjectsModal}
          setIsOpen={setShowProjectsModal}
          userInfos={userInfos || ({} as User)}
          investorSelected={investorSelected}
        />
      )}
      {modalShowAllCapacities && (
        <AllCapacitiesModal
          isOpen={modalShowAllCapacities}
          setIsOpen={setModalShowAllCapacities}
        />
      )}
    </Container>
  );
}
