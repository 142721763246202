import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { FiArrowUp, FiCheckCircle, FiFileText } from 'react-icons/fi';
import { MdPersonOutline } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { Headline } from '../../components/LegacyV4Components';
import v4Logo from '../../assets/logos/V4-Logo.svg';
import {
  getPlanList,
  getPlanListV2,
  IPlanList,
} from '../../services/requests/Finance/getPlanList';
import { convertNumberToBRL } from '../../utils/convertNumberToBRL';
import * as S from './styles';

export function SubscriptionSuccess() {
  const { id } = useParams<{ id: string }>();
  const [plan, setPlan] = useState<IPlanList>();
  const url = window.location.href;
  const verifyIfIsV2 = url.includes('v2');
  const [expanded, setExpanded] = useState<string | false>(false);

  useEffect(() => {
    async function getPlansList() {
      let plans;
      if (verifyIfIsV2) {
        plans = await getPlanListV2(id);
      } else {
        plans = await getPlanList(id);
      }
      if (plans) {
        setPlan(plans);
      }
    }
    getPlansList();
  }, [id, verifyIfIsV2]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <S.Container>
      <S.ConfirmedPayment>
        <FiCheckCircle color="#00C49A" />
        <p style={{ fontWeight: '700', fontSize: '1.375rem' }}>
          Você assinou o plano {plan?.planName} da nossa assessoria!{' '}
        </p>
        <small>
          Em breve você receberá um e-mail confirmando a assinatura do plano e
          as informações pertinentes
        </small>
      </S.ConfirmedPayment>
      <div>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          sx={{ border: '1px solid #00c49a' }}
        >
          <AccordionSummary
            expandIcon={<FiArrowUp />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FiFileText style={{ marginRight: '28px' }} />
              <Headline variant="h5">Ver resumo</Headline>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <S.ContainerInfo>
              <div className="your-request">
                <img src={v4Logo} alt="logo v4" />
                <Headline variant="h6">Detalhes da sua assinatura</Headline>
              </div>
              <section>
                <div className="your-product">
                  <p style={{ fontWeight: '700', fontFamily: 'Inter' }}>
                    Plano {plan?.planName}
                  </p>
                  <p>
                    {plan?.recentInvoices[0]?.total
                      ? convertNumberToBRL(plan?.recentInvoices[0]?.total / 100)
                      : ''}
                  </p>
                </div>
              </section>
            </S.ContainerInfo>
            {/* Adicionado display none para quando essas informações forem retornadas do back só adicionar aqui */}
            <S.ContainerInfo style={{ display: 'none' }}>
              <div className="your-request">
                <MdPersonOutline />
                <Headline variant="h6">Sobre você</Headline>
              </div>
              <section>
                <div className="your-product">
                  <p>Nome completo</p>
                </div>
                <p style={{ fontSize: '0.75rem' }}>Você pagará todo dia 10</p>
              </section>
              <section>
                <div className="your-product">
                  <p>E-mail</p>
                </div>
                <p style={{ fontSize: '0.75rem' }}>andreischopf@gmail.com</p>
              </section>
              <section>
                <div className="your-product">
                  <p>Telefone</p>
                </div>
                <p style={{ fontSize: '0.75rem' }}>andreischopf@gmail.com</p>
              </section>
              <section>
                <div className="your-product">
                  <p>Celular</p>
                </div>
                <p style={{ fontSize: '0.75rem' }}>andreischopf@gmail.com</p>
              </section>
            </S.ContainerInfo>
            <S.ContainerInfo style={{ display: 'none' }}>
              <div className="your-request">
                <MdPersonOutline />
                <Headline variant="h6">Pagamento</Headline>
              </div>
              <section>
                <div className="your-product">
                  <Headline variant="h6">Cartão de crédito</Headline>
                </div>
                <p style={{ fontSize: '0.75rem' }}>Você pagará todo dia 10</p>
              </section>
            </S.ContainerInfo>
            <S.ContainerInfo style={{ display: 'none' }}>
              <div className="your-request">
                <MdPersonOutline />
                <Headline variant="h6">
                  Receba os detalhes do pagamento
                </Headline>
              </div>
              <section>
                <div className="your-product">
                  <Headline variant="h6">E-mail</Headline>
                </div>
                <p style={{ fontSize: '0.75rem' }}>Você pagará todo dia 10</p>
              </section>
            </S.ContainerInfo>
          </AccordionDetails>
        </Accordion>
      </div>
    </S.Container>
  );
}
