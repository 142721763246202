import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  svg {
    color: var(--primary-main);
  }

  h3 {
    margin-top: 1rem;
  }

  p {
    margin: 1.5rem 0;
  }

  .buttons {
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    width: 100%;
    padding: 0 6rem;
  }
`;
