import { useState } from 'react';
import * as S from './styles';

interface ProfileImageProps {
  img?: string;
  name?: string;
  size?: string;
  backgroundColor?: string;
}

export function ProfileImage({
  img,
  name,
  size = '3rem',
  backgroundColor,
}: ProfileImageProps) {
  const [isErrored, setIsErrored] = useState(!!img);

  return (
    <S.Profile width={size} height={size}>
      {isErrored ? (
        <img onError={() => setIsErrored(true)} src={img} alt="profile" />
      ) : (
        <S.ImagePlaceholder style={{ backgroundColor }}>
          <h3>
            {name &&
              name
                .toUpperCase()
                .split(' ')
                .map((space, i) => i <= 2 && space[0])}
          </h3>
        </S.ImagePlaceholder>
      )}
    </S.Profile>
  );
}
