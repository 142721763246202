import { IData } from 'v4web-components';

export const teamStructureHeader: IData['headers'] = [
  {
    title: 'Cargo',
    colKey: 'mandate',
    type: 'select',
  },
  {
    title: 'Senioridade',
    colKey: 'seniority',
    type: 'select',
  },
  {
    title: 'Dedicação',
    colKey: 'dedication',
    type: 'select',
  },
  {
    title: 'Quantidade',
    colKey: 'quantity',
    type: 'quantify',
  },
];

export const DEDICATION_OPTIONS = [
  {
    label: 'Dedicado',
    isChecked: false,
    key: 'dedicated',
  },
  {
    label: 'Semi-dedicado',
    isChecked: false,
    key: 'semi_dedicated',
  },
];
