import styled from 'styled-components';

export const Container = styled.div`
  width: 70%;

  height: 60vh;

  margin: 0 auto;
`;

export const InputList = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-end;
`;
