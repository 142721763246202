import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 5000;
  right: 0;
  top: 0;
  padding: 1rem;
  padding-top: 2rem;
  overflow: hidden;
`;
