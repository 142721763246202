import { IData } from 'v4web-components';

type Header = IData['headers'];

export const headerFranchiseTable: Header = [
  {
    title: 'Unidade',
    colKey: 'unity_name',
    sortable: true,
    type: 'text',
  },
  {
    title: 'Cluster',
    colKey: 'cluster',
    sortable: true,
    type: 'text',
  },
  {
    title: 'MRR',
    colKey: 'mrr',
    sortable: true,
    type: 'text',
  },
  {
    title: 'NPS',
    colKey: 'nps',
    sortable: true,
    type: 'text',
  },
  {
    title: 'Taxa de churn',
    colKey: 'taxa_churn',
    sortable: true,
    type: 'text',
  },
  {
    title: 'Lifetime',
    colKey: 'lifetime',
    sortable: true,
    type: 'text',
  },
];
