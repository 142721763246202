export const permissionsCustomer = [
  {
    label: 'Visualizar',
    value: 'READ_ONLY',
    desc: 'Permissão para visualizar todos os clientes, stakeholders e suas informações.',
  },
  {
    label: 'Editar',
    value: 'WRITE_AND_READ',
    desc: 'Permissão para visualizar, adicionar stakeholders e editar todas informações do cliente.',
  },

  {
    label: 'Adminstrar',
    value: 'ADMIN',
    desc: 'Permissão para adicionar e editar clientes, stakeholders e informações.',
  },
];
