import styled from 'styled-components';

export const Smalled = styled.small`
  font-family: 'Nunito Sans';
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.875rem;

  &.plus {
    font-weight: 100;
  }

  &.bold {
    font-weight: 900;
  }
`;
