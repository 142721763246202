import styled, { css } from 'styled-components';

interface SectionProps {
  disabled?: boolean;
}

export const SectionContent = styled.div<SectionProps>`
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      filter: blur(2px);
    `}
`;
