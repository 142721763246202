import { usersApi as api } from '../../../api';

export async function getAllCapacities() {
  try {
    const response = await api.get(`/capacity/all`);
    const capacities = response;

    return capacities.data;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao atualizar a capacidade dos investidores, atualize a página e tente novamente 😕`,
      err.response.data,
    );
    return err.response.data;
  }
}
