import { financeApi } from '../../api';

export interface ISubscriptionList {
  _id: string;
  name: string;
  planIdentifier: string;
  email: string;
  customerId: string;
  paymentCardToken: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export async function getSubscriptionList(
  customerId: string | undefined,
): Promise<ISubscriptionList | false> {
  try {
    const response = await financeApi.get(
      `/subscriptions/list?customerId=${customerId}`,
    );
    const subscriptions: any = response.data;

    return subscriptions;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar as assinaturas, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
