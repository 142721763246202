import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 2rem;
  margin: 0 1rem;
  border-radius: 0.5rem;
  background: white;

  h2 {
    font-size: 2rem;
    margin-right: 4rem;
  }

  > section {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;

    > span {
      font-weight: bold;
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      line-height: 25px;
      color: var(--secondary-light);
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;

  button {
    :first-of-type {
      margin-right: 1rem;
    }
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 1120px;
  width: 100%;

  padding: 3rem 4rem;
  margin: 0 1rem;
  border-radius: 0.5rem;
  background: var(--white);

  h2 {
    font-size: 2rem;
    margin: 40px 0;
  }
`;

export const SuccessButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  button {
    white-space: nowrap;
    width: auto;
    padding: 1rem 2rem;

    :first-of-type {
      margin-right: 1rem;
    }
  }
`;

export const Loading = styled.div`
  width: 400px;
`;
