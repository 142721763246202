import { PagedResponse } from '../../../components/AtomicDesign/atoms/SelectQuery';
import { User } from '../../../hooks/types';
import { findAllProjects } from './findAllProjects';

async function handleFetchOptions(
  query: string,
  page: number,
  user: User,
): Promise<PagedResponse<IProject>> {
  const response = await findAllProjects(user.unitId, query, page);
  const { projects } = response;

  const options = projects.map((project) => {
    return { label: project.name, value: project };
  });

  const pagedResponse = {
    totalPages: response?.totalPages ?? 1,
    page: response?.page ?? 1,
    data: options ?? [],
  };

  return pagedResponse;
}

export { handleFetchOptions };
