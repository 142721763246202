import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 1.5rem 2rem;
  width: 100%;
  background-color: var(--white);
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
  h6 {
    margin: 0 auto;
  }
  svg {
    margin: 0 auto;
  }
`;

export const CheckoutUnavailableWrapper = styled.p`
  gap: 0.5rem;
  font-family: 'Inter';
  font-weight: 800;
  color: var(--tertiary-dark);
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: underline;
  padding-bottom: 0.5rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const ContainerHead = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ButtonBankInfo = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  max-width: 360px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 8px;
  background: #f6f6f6;
  border: 1px solid #bbbbbb;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 1px 3px 1px rgba(0, 0, 0, 0.06);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  svg {
    margin: 0;
  }
`;
