import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem 5rem 1.5rem 5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--tertiary-main);
`;

export const Header = styled.div`
  padding-top: 1.5rem;
  padding-left: 0.3rem;
  margin-bottom: 1.5rem;
  h3 {
    margin-bottom: 1rem;
    font-size: 2.375rem;
    line-height: 3rem;
  }

  p {
    font-size: 1.375rem;
    line-height: 2rem;
  }
`;

export const DetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 0.75rem;
`;

export const IFrameWrapper = styled.div`
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-grow: 1;
  background-color: var(--white);

  iframe {
    border: none;
    flex: 1;
  }
`;
