/* eslint-disable react/no-unescaped-entities */
import { Modal } from '../../../LegacyV4Components';
import * as S from './styles';

interface ModalProps {
  show: boolean;
  onClose: () => void;
}
export function GatewayTerms({ show, onClose }: ModalProps) {
  return (
    <Modal
      show={show}
      title="Termos e condições de uso Gateway de pagamento V4 Company"
      size="lg"
      onBackground={onClose}
      closeButton
    >
      <S.Container>
        <p>
          Estes termos e condi&ccedil;&otilde;es de uso (os &quot;Termos&quot;)
          regem o uso do servi&ccedil;o de gateway de pagamento (o
          &quot;Servi&ccedil;o&quot;) oferecido pela V4 Company
          (&quot;n&oacute;s&quot;, &quot;nosso&quot; ou &quot;nos&quot;). Ao
          usar o Servi&ccedil;o, voc&ecirc; concorda em cumprir todos os termos
          e condi&ccedil;&otilde;es aqui estabelecidos.
        </p>

        <p>Defini&ccedil;&otilde;es</p>

        <p>
          1.1. &quot;Usu&aacute;rio&quot; refere-se a qualquer pessoa que acessa
          ou usa o Servi&ccedil;o.
        </p>

        <p>
          1.2. &quot;Transa&ccedil;&atilde;o&quot; refere-se a qualquer
          pagamento ou recebimento de dinheiro realizado atrav&eacute;s do
          Servi&ccedil;o.
        </p>

        <p>
          1.3. &ldquo;Gateway&rdquo; &eacute; a plataforma de servi&ccedil;o
          oferecida que viabiliza pagamentos online.
        </p>

        <p>Descri&ccedil;&atilde;o do Servi&ccedil;o</p>

        <p>
          2.1. O Servi&ccedil;o &eacute; um gateway de pagamento online que
          permite aos usu&aacute;rios processar transa&ccedil;&otilde;es de
          pagamento e recebimento de dinheiro para faturamento de seus
          d&eacute;bitos e cr&eacute;ditos.
        </p>

        <p>
          2.2 O Servi&ccedil;o tamb&eacute;m inclui recursos de gerenciamento de
          fraudes e prote&ccedil;&atilde;o do usu&aacute;rio.
        </p>

        <p>Responsabilidades do usu&aacute;rio</p>

        <p>
          3.1. Constituem obriga&ccedil;&otilde;es do usu&aacute;rios, sem
          preju&iacute;zo de outras obriga&ccedil;&otilde;es que estejam
          previstas neste termo:
        </p>

        <p>
          3.1.1 O usu&aacute;rio &eacute; obrigado a fornecer &agrave; V4
          Company todas as informa&ccedil;&otilde;es relativas ao gerenciamento
          e pagamento que sejam necess&aacute;rias para a adequada
          presta&ccedil;&atilde;o dos servi&ccedil;os objeto do TERMO.
        </p>

        <p>
          3.1.2. O usu&aacute;rio &eacute; respons&aacute;vel por manter as
          informa&ccedil;&otilde;es de login e senha confidenciais e por
          notificar imediatamente o Servi&ccedil;o de qualquer atividade
          suspeita ou n&atilde;o autorizada em sua conta. O repasse da senha a
          terceiro n&atilde;o autorizado exonera a V4 Company de quaisquer
          responsabilidades por inconsist&ecirc;ncias na plataforma.
        </p>

        <p>
          3.1.3. O usu&aacute;rio &eacute; respons&aacute;vel por todas as
          transa&ccedil;&otilde;es realizadas em sua conta, independentemente de
          terem sido autorizadas ou n&atilde;o por ele. 3.1.4. Dever&aacute; o
          usu&aacute;rio submeter os membros de sua equipe que tenham acesso
          &agrave;s informa&ccedil;&otilde;es a obriga&ccedil;&otilde;es de
          confidencialidade e uso restrito n&atilde;o menos severas do que
          aquelas previstas neste termo, permanecendo o usu&aacute;rio
          respons&aacute;vel por tais membros.
        </p>

        <p>
          3.1.5. O usu&aacute;rio, a partir da contrata&ccedil;&atilde;o da
          plataforma, dever&aacute; indicar exclusivamente o servi&ccedil;o para
          recebimento das receitas designadas, sob pena de
          caracteriza&ccedil;&atilde;o de fraude a credores.
        </p>

        <p>
          3.1.6. Manter a V4 Company isenta de quaisquer
          reclama&ccedil;&otilde;es ou reivindica&ccedil;&otilde;es relativas a
          direitos autorais, propriedade intelectual e personalidade de
          terceiros, sobre os materiais e informa&ccedil;&otilde;es fornecidos
          pelo usu&aacute;rio, pugnando pela imediata exclus&atilde;o da V4
          Company do polo passivo de quaisquer demandas administrativas ou
          judiciais advindas em rela&ccedil;&atilde;o ao disposto neste TERMO;
        </p>

        <p>
          3.1.7. Comprometer-se a manter sigilo sobre quaisquer dados de
          natureza pessoal, sens&iacute;vel e empresarial que obtiver contato em
          decorr&ecirc;ncia desta contrata&ccedil;&atilde;o, oriundos e/ou de
          interesse da V4 Company.
        </p>

        <p>
          3.1.8 Permitir que a V4 Company utilize, sem custos, seu nome
          comercial, sua marca ou outros elementos distintos de sua marca para
          fins de divulga&ccedil;&atilde;o de case.
        </p>

        <p>
          3.1.9 Permanecer cumprindo as suas obriga&ccedil;&otilde;es previstas
          neste TERMO em caso de suspens&atilde;o tempor&aacute;ria do
          servi&ccedil;o por instabilidades ocorridas sem culpa da V4 Company.
        </p>

        <p>
          3.1.10 O usu&aacute;rio tem exclusiva responsabilidade por todas as
          obriga&ccedil;&otilde;es fiscais, diretas ou indiretas, trabalhistas,
          previdenci&aacute;rias e sociais decorrentes dos contratos de trabalho
          que mant&eacute;m com seus empregados, a&iacute; inclu&iacute;das as
          relativas aos eventuais acidentes de trabalho, devendo efetuar por sua
          conta e exclusiva responsabilidade o pagamento dos sal&aacute;rios,
          remunera&ccedil;&atilde;o indireta, adicionais de qualquer
          esp&eacute;cie, impostos e contribui&ccedil;&otilde;es atualmente
          existentes ou que venham a ser criados.
        </p>

        <p>
          3.1.11 O usu&aacute;rio concede imunidade total e irrestrita &agrave;
          V4 Company em raz&atilde;o de eventuais reclama&ccedil;&otilde;es
          trabalhistas ajuizadas por empregados e/ou prestadores de
          servi&ccedil;o do usu&aacute;rio que contenham a V4 Company no polo
          passivo e tenham conex&atilde;o com o presente contrato.
        </p>

        <p>Responsabilidades da V4 Company</p>

        <p>
          4.1. S&atilde;o responsabilidades da V4 Company, sem exclus&atilde;o
          de outras previstas neste termo:
        </p>

        <p>
          4.1.1. Disponibilizar acesso &agrave; plataforma contratada para
          presta&ccedil;&atilde;o de servi&ccedil;o, fazendo o cadastro devido e
          aplicando os treinamentos necess&aacute;rios para
          utiliza&ccedil;&atilde;o pelo usu&aacute;rio e sua equipe.
        </p>

        <p>
          4.1.2. Realizar a manuten&ccedil;&atilde;o da plataforma para
          viabilizar, pelos melhores esfor&ccedil;os, o processamento das
          transa&ccedil;&otilde;es de forma r&aacute;pida e precisa.
        </p>

        <p>
          4.1.3. Empregar ferramentas de trabalho pr&oacute;prias para a
          presta&ccedil;&atilde;o dos servi&ccedil;os.
        </p>

        <p>
          4.1.4 Considerando que a V4 Company ter&aacute; acesso a dados
          sens&iacute;veis e pessoais em nome do usu&aacute;rio no acesso e na
          utiliza&ccedil;&atilde;o das m&iacute;dias sociais e das outras contas
          de sistemas que sejam de titularidade desta, tais como, mas n&atilde;o
          limitado a logins, senhas, nome, CPF, RG, por si e por suas
          subsidi&aacute;rias, controladas e coligadas, bem como por seus
          respectivos s&oacute;cios, diretores, conselheiros, administradores,
          executivos, empregados, prepostos, subcontratados, representantes e
          procuradores, dever&aacute; cumprir e respeitar de forma ampla e
          geral, inclusive no que se refere &agrave;s penas, &agrave;s leis e
          regulamenta&ccedil;&otilde;es relativas &agrave;
          prote&ccedil;&atilde;o dos dados pessoais, incluindo, mas n&atilde;o
          se limitando, &agrave; Lei Federal n&ordm; 13.709/2018 (Lei Geral de
          Prote&ccedil;&atilde;o de Dados - LGPD) e &agrave;s demais leis
          brasileiras relacionadas &agrave; prote&ccedil;&atilde;o dos dados
          pessoais.
        </p>

        <p>
          4.1.5. Possuir uma Pol&iacute;tica de Privacidade (cujo link &eacute;
          https://v4company.com/politica-de-privacidade/) contemplando regras de
          boas pr&aacute;ticas e de governan&ccedil;a que estabele&ccedil;am as
          condi&ccedil;&otilde;es de organiza&ccedil;&atilde;o, o regime de
          funcionamento, os procedimentos, incluindo reclama&ccedil;&otilde;es e
          peti&ccedil;&otilde;es de titulares, as normas de seguran&ccedil;a, os
          padr&otilde;es t&eacute;cnicos, as obriga&ccedil;&otilde;es
          espec&iacute;ficas para os diversos envolvidos no tratamento, as
          a&ccedil;&otilde;es educativas, os mecanismos internos de
          supervis&atilde;o e de mitiga&ccedil;&atilde;o de riscos e outros
          aspectos relacionados ao tratamento de dados pessoais.
        </p>

        <p>
          4.1.6. A V4 Company tem exclusiva responsabilidade por todas as
          obriga&ccedil;&otilde;es fiscais, diretas ou indiretas, trabalhistas,
          previdenci&aacute;rias e sociais decorrentes dos contratos de trabalho
          que mant&eacute;m com seus empregados, ou dos contratos que
          mant&eacute;m com seus prestadores de servi&ccedil;os, empregados ou
          n&atilde;o, a&iacute; inclu&iacute;das as relativas aos eventuais
          acidentes de trabalho, devendo efetuar por sua conta e exclusiva
          responsabilidade o pagamento dos sal&aacute;rios,
          remunera&ccedil;&atilde;o indireta, adicionais de qualquer
          esp&eacute;cie, impostos e contribui&ccedil;&otilde;es atualmente
          existentes ou que venham a ser criados.
        </p>

        <p>
          4.1.7. A V4 Company concede imunidade total e irrestrita ao
          usu&aacute;rio em raz&atilde;o de eventuais reclama&ccedil;&otilde;es
          trabalhistas ajuizadas por empregados e/ou prestadores de
          servi&ccedil;o da V4 Company que contenham o usu&aacute;rio no polo
          passivo e tenham conex&atilde;o com o presente contrato
        </p>

        <p>Taxas e encargos</p>

        <p>
          5.1. Pela disponibiliza&ccedil;&atilde;o da plataforma e
          servi&ccedil;o ser&aacute; cobrada uma taxa de processamento de
          pagamentos de taxa por transa&ccedil;&atilde;o, no valor de XXXX.
        </p>

        <p>
          5.2. O Servi&ccedil;o pode reter fundos em caso de disputa de
          transa&ccedil;&atilde;o por um per&iacute;odo de at&eacute;
          [per&iacute;odo de reten&ccedil;&atilde;o]. Uma taxa de
          reten&ccedil;&atilde;o de fundos de [taxa] por dia ser&aacute; cobrada
          durante este per&iacute;odo.
        </p>

        <p>
          5.3. Em caso de disputa de transa&ccedil;&atilde;o, o Servi&ccedil;o
          pode cobrar uma taxa de resolu&ccedil;&atilde;o de disputas de [taxa].
        </p>

        <p>
          5.4. No que couber, os pagamentos das taxas observar&atilde;o as
          disposi&ccedil;&otilde;es da pol&iacute;tica de pagamento da V4
          Company (dispon&iacute;vel em:
          https://drive.google.com/file/d/1hJipoWDnbfuHoc1Ms7-we3c1WzX_L14L/view)
        </p>

        <p>Resolu&ccedil;&atilde;o de disputas</p>

        <p>
          6.1. As PARTES concordam em acionar o setor Customer Success da V4
          Company, pelo e-mail cs@v4company.com ou por WhatsApp em contato com
          respons&aacute;vel do setor indicado para o usu&aacute;rio pela V4
          Company, para tentativa de resolu&ccedil;&atilde;o consensual de
          eventuais conflitos envolvendo as obriga&ccedil;&otilde;es previstas
          neste termo, previamente &agrave; ado&ccedil;&atilde;o de quaisquer
          medidas judiciais ou administrativas externas.
        </p>

        <p>
          6.2. As PARTES comprometem-se em agir dentro dos par&acirc;metros de
          boa-f&eacute; contratual e da transpar&ecirc;ncia no cumprimento das
          suas obriga&ccedil;&otilde;es, contribuindo para a
          resolu&ccedil;&atilde;o amig&aacute;vel de qualquer
          intercorr&ecirc;ncia contratual.
        </p>

        <p>Modifica&ccedil;&otilde;es dos termos</p>

        <p>
          7.1. A V4 Company reserva-se o direito de modificar estes Termos a
          qualquer momento.
        </p>

        <p>
          7.2. Qualquer modifica&ccedil;&atilde;o ser&aacute; efetiva
          imediatamente ap&oacute;s a publica&ccedil;&atilde;o dos novos termos
          no Servi&ccedil;o e ser&aacute; comunicada de forma digital ao
          usu&aacute;rio, que ter&aacute; o prazo de 24h para apresentar
          questionamentos, sob pena de aceita&ccedil;&atilde;o plena
          t&aacute;cita. Termina&ccedil;&atilde;o do servi&ccedil;o
        </p>

        <p>
          8.1. A V4 Company reserva-se o direito de encerrar ou suspender o
          Servi&ccedil;o a qualquer momento, sem aviso pr&eacute;vio, no caso de
          atraso de pagamento da plataforma pelo usu&aacute;rio.
        </p>

        <p>
          8.2 O usu&aacute;rio concorda que, no caso de suspens&atilde;o ou
          encerramento do servi&ccedil;o, a V4 Company n&atilde;o ser&aacute;
          respons&aacute;vel perante ele ou a terceiros por problemas de
          faturamento da empresa.
        </p>

        <p>Isen&ccedil;&atilde;o de responsabilidade</p>

        <p>
          9.1. O relacionamento estabelecido por este TERMO &eacute;
          exclusivamente de prestador e tomador de servi&ccedil;os. A V4 Company
          ou quaisquer de seus agentes, representantes e/ou empregados
          n&atilde;o &eacute;, nem poder&atilde;o ser considerados agentes,
          empregados ou representantes do usu&aacute;rio.
        </p>

        <p>Sigilo e Confidencialidade</p>

        <p>
          10.1. Todas as informa&ccedil;&otilde;es e dados de natureza
          t&eacute;cnica e comercial tornados de conhecimento a qualquer das
          PARTES em virtude do presente TERMO e da Ordem de Servi&ccedil;o
          constituem mat&eacute;ria sigilosa, obrigando-se, assim, sob as penas
          da lei, a guardar integral e absoluto segredo a seu respeito, bem como
          a advertir seus empregados e prestadores de servi&ccedil;os sobre a
          fei&ccedil;&atilde;o sigilosa dos mesmos, comprometendo-se, ainda, a
          deles n&atilde;o fazer uso em proveito pr&oacute;prio, diretamente ou
          por interposta pessoa, sob pena de responder civil e penalmente pelo
          descumprimento de dita obriga&ccedil;&atilde;o, al&eacute;m das
          penalidades na Lei Geral de Prote&ccedil;&atilde;o de Dados
          (&quot;LGPD&quot;).
        </p>

        <p>
          10.2. A obriga&ccedil;&atilde;o de sigilo e confidencialidade
          estabelecida neste TERMO e da Ordem de Servi&ccedil;o n&atilde;o se
          aplica para os casos de divulga&ccedil;&atilde;o de
          informa&ccedil;&otilde;es da usu&aacute;rio para cumprimento da
          presta&ccedil;&atilde;o de servi&ccedil;o pela V4 Company.
        </p>

        <p>Prote&ccedil;&atilde;o de Dados</p>

        <p>
          11.1. As PARTES dever&atilde;o observar todas as regras legais sobre
          prote&ccedil;&atilde;o de dados, especialmente, mas n&atilde;o se
          limitando, &agrave;quelas contidas na Lei Geral de
          Prote&ccedil;&atilde;o de Dados Pessoais (Lei n&ordm; 13.709/2018) e
          nos regulamentos que vierem a ser editados pela Autoridade Nacional de
          Prote&ccedil;&atilde;o de Dados.
        </p>

        <p>
          11.2. As PARTES devem investir esfor&ccedil;os para a
          implanta&ccedil;&atilde;o de medidas de seguran&ccedil;a,
          t&eacute;cnicas e administrativas a fim de proteger os dados pessoais
          de acessos n&atilde;o autorizados e de situa&ccedil;&otilde;es
          acidentais ou il&iacute;citas de destrui&ccedil;&atilde;o, perda,
          altera&ccedil;&atilde;o, comunica&ccedil;&atilde;o ou qualquer forma
          de tratamento inadequado ou il&iacute;cito.
        </p>

        <p>
          11.3. A V4 Company, na qualidade de operadora, dever&aacute; adotar
          medidas que visem assegurar a integridade e a confidencialidade dos
          dados pessoais utilizados no &acirc;mbito do tratamento de dados
          pessoais em nome do usu&aacute;rio para a execu&ccedil;&atilde;o deste
          contrato.
        </p>

        <p>
          11.4. &Eacute; vedada a realiza&ccedil;&atilde;o de tratamento de
          dados pessoais pela V4 Company, na qualidade de operadora, em
          desacordo com os crit&eacute;rios e com as finalidades de
          execu&ccedil;&atilde;o deste Contrato, e em concord&acirc;ncia com a
          pol&iacute;tica de privacidade da controladora.
        </p>

        <p>
          11.5. A V4 Company se compromete a n&atilde;o subcontratar nenhum dos
          servi&ccedil;os prestados neste contrato que impacte no tratamento de
          dados pelo subcontratado sem a pr&eacute;via autoriza&ccedil;&atilde;o
          do usu&aacute;rio.
        </p>

        <p>
          11.6. A V4 Company dever&aacute; manter o registro de todas as
          opera&ccedil;&otilde;es de tratamento de dados pessoais que
          realizarem.
        </p>

        <p>
          11.7. A V4 Company dever&aacute; reportar, de modo detalhado, nos
          termos da lei e regulamentos aplic&aacute;veis, a ocorr&ecirc;ncia de
          qualquer incidente, irregularidade no funcionamento do Sistema e/ou
          quaisquer eventos que possam colocar em risco a integridade e/ou a
          confidencialidade dos dados.
        </p>

        <p>
          11.8. Em caso de incidente de seguran&ccedil;a as Partes
          dever&atilde;o adotar medidas de assist&ecirc;ncia e
          coopera&ccedil;&atilde;o m&uacute;tuas, compartilhando todas as
          informa&ccedil;&otilde;es solicitadas e que sejam necess&aacute;rias
          &agrave; elucida&ccedil;&atilde;o do incidente, ressalvados os seus
          segredos comerciais.
        </p>

        <p>
          11.9. A V4 Company dever&aacute; assistir e colaborar fornecendo os
          dados e informa&ccedil;&otilde;es que auxiliem ao usu&aacute;rio: (i)
          ao atendimento tempestivamente as solicita&ccedil;&otilde;es dos
          titulares dos dados pessoais, relativas &agrave;s
          rela&ccedil;&otilde;es provenientes deste contrato, e &agrave;s
          solicita&ccedil;&otilde;es de autoridades, garantindo aos titulares,
          exatid&atilde;o, clareza, relev&acirc;ncia e atualiza&ccedil;&atilde;o
          dos dados, de acordo com a necessidade e para o cumprimento da
          finalidade de seu tratamento; (ii) cumprimento e
          adequa&ccedil;&atilde;o da destina&ccedil;&atilde;o para finalidades
          espec&iacute;ficas do recolhimento dos dados; (iii) recolhimento
          somente dos dados necess&aacute;rios para realiza&ccedil;&atilde;o do
          servi&ccedil;o previsto no contrato; (iv) viabilizar e garantir a
          transpar&ecirc;ncia no compartilhamento e tratamento de
          informa&ccedil;&otilde;es; (v) assegurar mecanismos de
          seguran&ccedil;a de dados, tais como, limita&ccedil;&atilde;o de
          agentes autorizados no acesso de dados e compartilhamento somente
          entre pessoas previamente autorizadas; (vi) elaborar os
          relat&oacute;rios de impactos &agrave; prote&ccedil;&atilde;o de
          dados, quando solicitados pela Autoridade Nacional de
          Prote&ccedil;&atilde;o de Dados.
        </p>

        <p>
          11.11. O descumprimento das medidas dispostas neste instrumento
          contratual, nas pol&iacute;ticas de privacidade de cada parte, bem
          como na Lei n&ordm; 13.709/2018, por qualquer das partes,
          ensejar&aacute; na aferi&ccedil;&atilde;o de responsabilidade e
          aplica&ccedil;&atilde;o das penas e comina&ccedil;&otilde;es legais.
        </p>

        <p>
          11.12. A V4 Company n&atilde;o se responsabilizar&aacute; por qualquer
          delito, neglig&ecirc;ncia, descumprimento contratual ou outra
          hip&oacute;tese de il&iacute;cito civil ou penal que o usu&aacute;rio
          ou seus parceiros, prestadores de servi&ccedil;os, provedores de
          conta, funcion&aacute;rios, fornecedores, distribuidores ou agentes,
          causarem a terceiros ou aos titulares dos Dados Pessoais, incluindo o
          uso n&atilde;o autorizado de seus dados, sendo de total
          responsabilidade do usu&aacute;rio arcar com todos e quaisquer danos,
          monet&aacute;rios ou de outra natureza, decorrentes de sua
          atua&ccedil;&atilde;o indireta, direta ou incidental, cabendo &agrave;
          V4 Company direito de regresso contra o usu&aacute;rio por tais
          situa&ccedil;&otilde;es.
        </p>

        <p>Lei aplic&aacute;vel</p>

        <p>
          11.1. Estes Termos ser&atilde;o regidos e interpretados de acordo com
          as leis do Estado de S&atilde;o Leopoldo/RS, independentemente de
          quaisquer disposi&ccedil;&otilde;es de conflito de leis.
        </p>

        <p>Disponibilidade do servi&ccedil;o</p>

        <p>
          12.1. A V4 Company n&atilde;o garante a disponibilidade ininterrupta
          do Servi&ccedil;o, pois a plataforma poder&aacute; estar
          temporariamente indispon&iacute;vel devido a manuten&ccedil;&atilde;o,
          problemas t&eacute;cnicos ou outros fatores al&eacute;m do controle da
          V4 Company.
        </p>

        <p>
          12.2 A V4 Company compromete-se a empregar os melhores esfor&ccedil;os
          para manter a qualidade do servi&ccedil;o e garantir, de modo geral, o
          atendimento das demandas do usu&aacute;rio.
        </p>

        <p>Entrada em vigor</p>

        <p>
          13.1. Estes Termos entram em vigor imediatamente ap&oacute;s a
          aceita&ccedil;&atilde;o pelo usu&aacute;rio.
        </p>

        <p>Contato</p>

        <p>
          14.1. Qualquer d&uacute;vida ou preocupa&ccedil;&atilde;o sobre estes
          Termos ou o Servi&ccedil;o deve ser encaminhada para o endere&ccedil;o
          de e-mail compliance@v4company.com.
        </p>
      </S.Container>
    </Modal>
  );
}
