import axios from 'axios';

interface AddressByCep {
  uf: string;
  bairro: string;
  localidade: string;
  logradouro: string;
}

export async function getAddresByCep(
  updateValuesFunction: (values: string, newValues: string) => void,
  cep: string,
) {
  const url = `https://viacep.com.br/ws/${cep}/json/`;
  await axios
    .get(url)
    .then((res) => {
      const result = res.data as AddressByCep;

      updateValuesFunction(`address.state`, result.uf);
      updateValuesFunction(`address.district`, result.bairro);
      updateValuesFunction(`address.street`, result.logradouro);
      updateValuesFunction(`address.city`, result.localidade);

      return res.data as AddressByCep;
    })
    .catch(() => {
      return null;
    });
}
