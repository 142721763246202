import styled from 'styled-components';
import {
  SelectTrigger,
  SelectIcon,
  Content,
  Viewport,
  Item,
  ItemIndicator,
  ScrollUpButton,
  ScrollDownButton,
} from '@radix-ui/react-select';

export const Container = styled.div`
  width: 100%;
`;

export const StyledTrigger = styled(SelectTrigger)`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  background: var(--white);
  border: 2px solid var(--grayscale-20);

  font-size: 1rem;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
`;

export const StyledIcon = styled(SelectIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grayscale-60);
  margin-left: 0.5rem;
`;

export const StyledContent = styled(Content)`
  overflow: hidden;
  background-color: var(--white);
  border-radius: 0.25rem;
  border: 2px solid var(--grayscale-20);
`;

export const StyledViewPort = styled(Viewport)`
  padding: 0.25rem;
`;

export const StyledItem = styled(Item)`
  font-size: 1rem;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  position: relative;
  user-select: none;
  cursor: default;

  :hover {
    background: var(--grayscale-10);
  }
`;

export const StyledItemIndicator = styled(ItemIndicator)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--success-2);
`;

const scrollButtonStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  color: var(--primary-dark-1);
  background: var(--white);
  padding: 0.5rem;

`;

export const StyledScrollUpButton = styled(ScrollUpButton)`
  ${scrollButtonStyles}
`;

export const StyledScrollDownButton = styled(ScrollDownButton)`
  ${scrollButtonStyles}
`;

export const SelectedItemIcon = styled.div`
  height: 1rem;
  background: var(--success-2);
  border-radius: 0.25rem;
  width: 0.25rem;
  margin-right: 0.5rem;
`;
