import { ReactNode } from 'react';
import { MdRemoveCircleOutline } from 'react-icons/md';
import { Input, Paragraph } from '../../../../LegacyV4Components';

import {
  CustomerIntegrationsContextData,
  CustomerIntegrationsProvider,
} from '../../../../../hooks/customerIntegrations';

import { TieAccountsModal } from '../TieAccountsModal';

import { pipedrive as options } from '../platforms';

import * as Edit from '../components/IntegrationEditRow/styles';

interface PipedriveModalProps {
  show: boolean;
  onClose: () => void;
}

export function ConfigPipedriveModal({ show, onClose }: PipedriveModalProps) {
  const tableColumns = [
    {
      accessor: 'customer',
      label: 'Cliente',
      sizing: 25,
      sizingUnit: 'vw',
      sortable: true,
    },
    {
      accessor: 'integrationId',
      label: 'App Key',
      sizing: 1,
      sortable: true,
    },
    {
      accessor: 'authToken',
      label: 'Token',
      sizing: 1,
      sortable: true,
    },
    {
      accessor: 'actions',
      label: '',
      sizing: 0.075,
      sortable: false,
    },
    {
      accessor: 'status',
      label: 'Status',
      sizing: 0.75,
      sortable: false,
    },
  ];

  function isConnected(integration: AdsAccounts): boolean {
    return (
      !!integration.authToken &&
      !!integration.projectId &&
      !integration.isDeleted
    );
  }

  function additionalValidation(integration: AdsAccounts): boolean {
    return !!integration.authToken;
  }

  function renderEditRow(
    integration: AdsAccounts,
    error: boolean,
    context: CustomerIntegrationsContextData,
  ): ReactNode {
    const { setIntegrationId, setIntegration, deleteIntegration } = context;
    const integrationError = error && !integration.integrationId;
    const authTokenError = error && !integration.authToken;
    return (
      <>
        <Edit.Row id={integration.integrationId}>
          <div title={integration.integrationId}>
            <Input
              label=""
              value={integration.integrationId}
              name="integrationId"
              onChange={({ target: { value } }) =>
                setIntegrationId(integration, value)
              }
              className={integrationError ? 'error' : ''}
            />
            {integrationError && <Edit.Error>Token inválido</Edit.Error>}
          </div>
        </Edit.Row>
        <Edit.Row id={integration.authToken}>
          <div title={integration.authToken}>
            <Input
              label=""
              value={integration.authToken}
              name="authToken"
              onChange={({ target: { value } }) =>
                setIntegration({ ...integration, authToken: value })
              }
              className={authTokenError ? 'error' : ''}
            />
            {authTokenError && <Edit.Error>Token inválido</Edit.Error>}
          </div>
        </Edit.Row>
        <Edit.Row>
          <MdRemoveCircleOutline
            size={22}
            onClick={() => deleteIntegration(integration)}
          />
        </Edit.Row>
      </>
    );
  }

  function renderSummaryRow(integration: AdsAccounts): ReactNode {
    return (
      <>
        <td title={integration.integrationId}>
          <Paragraph>{integration.integrationId}</Paragraph>
        </td>
        <td title={integration.authToken}>
          <Paragraph>{integration.authToken}</Paragraph>
        </td>
        <td aria-label="default" />
      </>
    );
  }

  return (
    <CustomerIntegrationsProvider type={options.type} show={show}>
      <TieAccountsModal
        options={options}
        show={show}
        onClose={onClose}
        tableColumns={tableColumns}
        renderEditRow={renderEditRow}
        renderSummaryRow={renderSummaryRow}
        isConnected={isConnected}
        additionalValidation={additionalValidation}
        addButtonText="Adicionar loja"
      />
    </CustomerIntegrationsProvider>
  );
}
