import { ReactNode } from 'react';
import { MdRemoveCircleOutline, MdOutlineVerifiedUser } from 'react-icons/md';
import { Input } from '../../../../LegacyV4Components';

import { useToast } from '../../../../../hooks/toast';
import {
  CustomerIntegrationsContextData,
  CustomerIntegrationsProvider,
} from '../../../../../hooks/customerIntegrations';

import { TieAccountsModal } from '../TieAccountsModal';
import { kommo as options } from '../platforms';

import * as Edit from '../components/IntegrationEditRow/styles';
import * as Summary from '../components/IntegrationSummaryRow/styles';

interface ActiveCampaignModalProps {
  show: boolean;
  onClose: () => void;
}

export function ConfigKommoModal({ show, onClose }: ActiveCampaignModalProps) {
  const { addToast } = useToast();
  const tableColumns = [
    {
      accessor: 'customer',
      label: 'Cliente',
      sizing: 25,
      sizingUnit: 'vw',
      sortable: false,
    },
    {
      accessor: 'clientSecret',
      label: 'Chave secreta',
      sizing: 1,
      sortable: false,
    },
    {
      accessor: 'integrationId',
      label: 'ID da integração',
      sizing: 1,
      sortable: false,
    },
    {
      accessor: 'actions',
      label: '',
      sizing: 0.15,
      sortable: false,
    },
    {
      accessor: 'status',
      label: 'Status',
      sizing: 0.5,
      sortable: false,
    },
  ];

  function isApproved(integration: AdsAccounts): boolean {
    const approved = !!integration.authKey && !!integration.refreshKey;
    return approved;
  }

  function isConnected(integration: AdsAccounts): boolean {
    return (
      !!integration.projectId &&
      !integration.isDeleted &&
      isApproved(integration)
    );
  }

  function additionalValidation(integration: AdsAccounts): boolean {
    if (!isApproved(integration)) {
      addToast({
        type: 'error',
        title: 'É necessário permitir o acesso da integração na Kommo',
        description: '',
      });
    }
    return isConnected(integration);
  }

  function renderEditRow(
    integration: AdsAccounts,
    error: boolean,
    context: CustomerIntegrationsContextData,
  ): ReactNode {
    const {
      setIntegrationId,
      setClientSecret,
      deleteIntegration,
      handleAddKommo,
    } = context;
    const secretError = error && !integration.clientSecret;
    const idError = error && !integration.integrationId;

    return (
      <>
        <Edit.Row title={integration.integrationId}>
          <div>
            <Input
              label=""
              value={integration.clientSecret || ''}
              name="clientSecret"
              onChange={({ target: { value } }) =>
                setClientSecret(integration, value)
              }
              disabled={isApproved(integration)}
              className={secretError ? 'error' : ''}
            />
            {idError && <Edit.Error>ID inválido</Edit.Error>}
          </div>
        </Edit.Row>
        <Edit.Row title={integration.integrationId}>
          <div>
            <Input
              label=""
              value={integration.integrationId}
              name="integrationId"
              onChange={({ target: { value } }) =>
                setIntegrationId(integration, value)
              }
              disabled={isApproved(integration)}
              className={idError ? 'error' : ''}
            />
            {idError && <Edit.Error>ID inválido</Edit.Error>}
          </div>
        </Edit.Row>
        <Edit.Row title={integration.integrationId}>
          {!isApproved(integration) && (
            <MdOutlineVerifiedUser
              size={22}
              className="verify"
              onClick={() => handleAddKommo(integration.integrationId)}
            />
          )}
          <MdRemoveCircleOutline
            size={22}
            onClick={() => deleteIntegration(integration)}
          />
        </Edit.Row>
      </>
    );
  }

  function renderSummaryRow(integration: AdsAccounts): ReactNode {
    return (
      <>
        <td title={integration.clientSecret}>
          <Summary.IntegrationParagraph>
            {integration.clientSecret}
          </Summary.IntegrationParagraph>
        </td>
        <td title={integration.integrationId}>
          <Summary.IntegrationParagraph>
            {integration.integrationId}
          </Summary.IntegrationParagraph>
        </td>
        <td aria-label="default" />
      </>
    );
  }

  return (
    <CustomerIntegrationsProvider type={options.type} show={show}>
      <TieAccountsModal
        options={options}
        show={show}
        onClose={onClose}
        tableColumns={tableColumns}
        renderEditRow={renderEditRow}
        renderSummaryRow={renderSummaryRow}
        isConnected={isConnected}
        additionalValidation={additionalValidation}
      />
    </CustomerIntegrationsProvider>
  );
}
