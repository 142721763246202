import styled from 'styled-components';

export const Container = styled.div`
  width: 68%;
  @media (max-width: 880px) {
    width: auto;
  }
`;
export const Card = styled.div`
  margin: 4rem 0rem;
  padding-bottom: 1rem;
  border: 1px solid #dadae7;
  border-radius: 4px;
  background-color: #f6f6f6;
`;

export const Body = styled.div`
  padding: 1rem 1rem;
`;

export const Header = styled.div`
  padding: 1rem 1rem;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  svg {
    width: 20px;
    height: 20px;
    margin-bottom: -0.2rem;
    margin-right: 1rem;
  }
`;
