import { useEffect, useState } from 'react';
import { MdBookmarkBorder, MdOutlineBookmark } from 'react-icons/md';
import { DotMenu } from '../../../LegacyV4Components';
import { useAuth } from '../../../../hooks/auth';
import { Dashboard } from '../../../../services/dashboardsRequests';

import { favoriteDashboard } from '../../../../services/requests/Dashboards/favoriteDashboard';

import * as S from './styles';
import { DashboardOperationModal } from '../DashboardOperationModal';
import { DeleteDashboardModal } from '../DeleteDashboardModal';

interface Props {
  dashboard: Dashboard;
  onClick?: () => void;
  isSelected?: boolean;
  userPermission: string;
  isFavorited: boolean;
  updateFavorites: (dashboardId: string) => void;
  hideFavorite?: boolean;
}

export function DashboardCard({
  dashboard,
  onClick,
  isSelected,
  userPermission,
  isFavorited,
  updateFavorites,
  hideFavorite,
}: Props) {
  const { name, description } = dashboard;
  const [isModalOperationOpen, setIsModalOperationOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSettedFavorite, setIsSettedFavorite] = useState<boolean>();

  const { user } = useAuth();
  function handleEdit() {
    setIsModalOperationOpen(true);
  }

  function handleCloseModal() {
    setIsModalOperationOpen(false);
  }

  function handleOpenDeleteModal() {
    setIsDeleteModalOpen(true);
  }

  function handleCloseDeleteModal() {
    setIsDeleteModalOpen(false);
  }

  async function handleFavorite() {
    const response = await favoriteDashboard(dashboard, user._id, !isFavorited);
    if (response) {
      setIsSettedFavorite(!isSettedFavorite);
      updateFavorites(dashboard._id);
    }
  }

  useEffect(() => {
    setIsSettedFavorite(isFavorited);
  }, [isFavorited]);

  const favoriteButton = isSettedFavorite ? (
    <MdOutlineBookmark
      className="bookMarker"
      onClick={() => {
        handleFavorite();
      }}
    />
  ) : (
    <MdBookmarkBorder
      className="bookMarker"
      onClick={() => {
        handleFavorite();
      }}
      color="#b4b4cf"
    />
  );

  return (
    <S.DashboardCard isSelected={isSelected} onClick={onClick}>
      <section>
        <h5>{name}</h5>
        {hideFavorite ? null : favoriteButton}
        {}
      </section>

      <h6>Publicado por: Matriz </h6>
      <p>{description}</p>

      {userPermission === 'ADMIN' && (
        <div className="dot">
          <DotMenu
            options={[
              { name: 'Editar', action: handleEdit },
              { name: 'Excluir', action: handleOpenDeleteModal },
            ]}
            reversed
          />
        </div>
      )}

      <DashboardOperationModal
        isOpen={isModalOperationOpen}
        operation="edit"
        closeModal={handleCloseModal}
        dashboard={dashboard}
      />
      <DeleteDashboardModal
        isOpen={isDeleteModalOpen}
        closeModal={handleCloseDeleteModal}
        dashboard={dashboard}
      />
    </S.DashboardCard>
  );
}
