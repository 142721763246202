import styled from 'styled-components';

interface SearchElementProps {
  width?: string;
}

export const SearchElement = styled.div<SearchElementProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0.5rem 1rem;

  ${({ width }) => width && `width: ${width};`}

  border-radius: 3px;
  border: 1px solid var(--grayscale-4);

  transition: border 0.3s, box-shadow 0.3s;

  &.filled {
    border-color: var(--grayscale-60);
  }

  & svg {
    color: var(--grayscale-60);
  }

  &:focus-within {
    border: 1px solid var(--grayscale-20);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
      0px 2px 6px 2px rgba(0, 0, 0, 0.06);
  }
`;

export const SearchInput = styled.input`
  font-family: 'Nunito Sans';
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  color: var(--secondary-main);

  transition: color 0.3s;

  border: none;

  flex-grow: 1;

  &:focus {
    color: var(--secondary-main);
  }

  &::placeholder {
    color: var(--secondary-light);
  }
`;
