import React from 'react';
import { Controller } from 'react-hook-form';
import { Input } from '../../LegacyV4Components';

export const InputForm = ({
  nameInput,
  control,
  label,
  type = 'text',
  placeholder,
  required = false,
  mask,
  width,
  error,
  info,
  onBlur,
}: {
  nameInput: string;
  control: any;
  label: string;
  type: string;
  placeholder?: string;
  required?: boolean;
  mask?: string;
  width?: string;
  error?: string;
  info?: string;
  onBlur?: () => void;
}) => {
  return (
    <Controller
      name={nameInput}
      control={control}
      render={({ field: { name, onChange, value } }) => {
        return (
          <Input
            label={label}
            type={type}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            required={required}
            mask={mask}
            width={width}
            error={error}
            info={info}
            onBlur={onBlur}
          />
        );
      }}
    />
  );
};
