import { Customer } from '../../../types/customer';
import { customerApi as api } from '../../api';

interface IUpdateCustomer {
  country: string;
  state: string;
  city: string;
  street: string;
  number: string;
  complement: string;

  zipCode: string;
}

export async function updateCustomer(
  customerId: string,
  data: IUpdateCustomer,
  unitId: string,
): Promise<Customer | false> {
  try {
    const response = await api.put(`/customer/update/${customerId}`, {
      units: [{ _id: unitId }],
      address: data,
    });

    const customer = response.data as Customer;

    return customer;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os dados dos clientes, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
