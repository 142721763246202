import { LabDsTable } from 'v4web-components-react';
import { IData } from 'v4web-components';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { IoPeopleOutline } from 'react-icons/io5';
import { BiEditAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { Headline, Paragraph } from '../../../LegacyV4Components';
import { useAuth } from '../../../../hooks/auth';
import * as S from './styles';
import { customerUsersApi } from '../../../../services/api';

export default function CustomerStakeholdersPreview({
  customer,
}: {
  customer: any;
}) {
  const [stakeholders, setStakeholders] = useState<any[]>([]);
  const navigate = useNavigate();

  const { user } = useAuth();

  const headers: IData['headers'] = [
    {
      title: 'Nome',
      colKey: 'name',
      sortable: true,
      type: 'text',
    },
    {
      title: 'Função',
      colKey: 'role',
      type: 'text',
    },
  ];

  const getStakeholders = useCallback(async () => {
    const response = await customerUsersApi(`/customer/${customer._id}`);
    if (response) {
      setStakeholders(response.data as Stakeholder[]);
    }
  }, [customer]);

  useEffect(() => {
    getStakeholders();
  }, [customer, getStakeholders]);

  const rows: IData['rows'] = useMemo(() => {
    const rowsData: IData['rows'] = [];
    if (stakeholders) {
      stakeholders?.forEach((currentUser: Stakeholder) => {
        rowsData.push({
          name: {
            text: {
              title: currentUser.name,
              avatar: true,
            },
          },
          role: {
            text: {
              title: currentUser.role,
            },
          },
        });
      });
    }
    return rowsData;
  }, [stakeholders]);

  return (
    <S.Container>
      <S.HeaderPreview>
        <Headline variant="h5">
          <IoPeopleOutline style={{ marginRight: '.5rem' }} /> Stakeholders do
          projeto
        </Headline>
        <S.EditStakeholder
          onClick={() => navigate(`/cliente/${customer._id}/stakeholders`)}
          disabled={!user.permissions?.customer?.edit}
        >
          Editar <BiEditAlt />
        </S.EditStakeholder>
      </S.HeaderPreview>
      <div>
        <LabDsTable data={{ headers, rows }} />
      </div>
      {stakeholders.length === 0 && (
        <S.EmptyTable>
          <Paragraph>Nenhum stakeholder cadastrado</Paragraph>
        </S.EmptyTable>
      )}
    </S.Container>
  );
}
