export interface UnitCreationData {
  name: string;
  royalties: number;
  taxRegime: string;
  v4Email: string;
  contractSignatureDate: Date;
  contract?: File;
  contractUrl?: string;
  fantasyName: string;
  socialName: string;
  cnpj: string;
  companyNumber: string;
  error?: string;
  address: {
    zipCode: string;
    state: string;
    city: string;
    street: string;
    district: string;
    number: string;
    complement?: string;
  };
  stakeholders: {
    name: string;
    email: string;
    rg: string;
    cpf: string;
    isPrincipal: boolean;
    phone?: string;
  }[];
}

export interface AddressInfo {
  key: string;
  label: string;
  isChecked: boolean;
}

export interface CepInfo {
  result: {
    street: string;
    district: string;
    city: string;
    state: string;
  };
}

export interface UrlInfo {
  url: string;
}

export const dedicationOptions = [
  { key: 'dedicated', label: 'Dedicado', isChecked: false },
  { key: 'shared', label: 'Compartilhado', isChecked: false },
];

export function bytesToMB(bytes: number): number {
  return bytes / (1024 * 1024);
}
