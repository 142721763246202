import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineLink, AiOutlineInfoCircle } from 'react-icons/ai';
import { FiExternalLink } from 'react-icons/fi';

import { LabDsSearchBar, LabDsTable } from 'v4web-components-react';
import { IDataRow } from 'v4web-components/dist/types/components/layout/lab-ds-table/lab-ds-table';
import useDebounce from '../../../../hooks/useDebounce';
import { useAuth } from '../../../../hooks/auth';

import { useGetUnitIntegrations } from '../../../../services/requests/Ads/getUnitIntegrations';
import { useGetAccountIntegrationByUnit } from '../../../../services/requests/Ads/getAccountIntegrationsByUnit';
import { useFindAllProjects } from '../../../../services/requests/CustomersNew/findAllProjects';

import { summaryDataHeaders } from './columns';

import * as S from './styles';

export function Summary() {
  const { user } = useAuth();

  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search.toLocaleLowerCase(), 500);

  const { data, fetchNextPage } = useFindAllProjects(
    user.unitId,
    debouncedSearch,
  );

  const { data: summaryData } = useGetUnitIntegrations(user.unitId);

  const { data: integrationData } = useGetAccountIntegrationByUnit(user.unitId);

  const flatProjects = data?.pages.flatMap((res) => {
    return res.projects;
  });

  const projects = flatProjects?.map((project) => {
    const integrations = integrationData?.filter(
      (integration) => integration.projectId === project._id,
    );

    return { ...project, integrations };
  });

  const headers = summaryDataHeaders;
  const rows: Record<string, IDataRow>[] = useMemo(() => {
    if (!projects) return [];
    return projects?.map((project) => {
      return {
        name: {
          text: {
            title: project.name,
          },
        },
        accounts: {
          text: {
            icon: 'open_in_new',
            title: `${project.integrations?.length.toString() ?? 0}`,
          },
          handleRow: () =>
            navigate(`/clientes/mediahub/accounts/${project._id}`, {
              state: { project },
            }),
        },
        button: {
          text: {
            title: 'Acessar painel',
          },
          handleRow: () =>
            navigate(`/clientes/mediahub/resultados/${project._id}`),
        },
      };
    });
  }, [projects, navigate]);

  return (
    <>
      <S.Container>
        <S.InfoWrapper>
          <S.InfoCard>
            <S.IconWrapper>
              <AiOutlineLink />
              <S.DetailsWrapper>
                <p>Contas conectadas</p>
              </S.DetailsWrapper>
            </S.IconWrapper>
            <S.ContentWrapper>
              <h3>{summaryData?.total}</h3>
            </S.ContentWrapper>
          </S.InfoCard>
          <S.InfoCard>
            <S.IconWrapper>
              <AiOutlineInfoCircle />
              <S.DetailsWrapper>
                <p>Como utilizar o MediaHub?</p>
              </S.DetailsWrapper>
            </S.IconWrapper>
            <S.LineWrapper>
              <S.VideoContainer>
                <iframe
                  src="https://www.loom.com/embed/63a85387fa804163bd5b9c75abd425fe?sid=a88521b2-784d-47b5-8b79-365fd4989b46"
                  title="O que a V4 Company faz?"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                />
              </S.VideoContainer>
              <S.Section>
                <p>
                  Veja dicas importantes para que você tenha o melhor
                  aproveitamento da ferramenta
                </p>
              </S.Section>
              <S.Section>
                <S.Link href="https://v4company.canny.io/" target="_blank">
                  Sugestão de novas integrações
                  <FiExternalLink />
                </S.Link>
                <S.Link
                  href="/baseconhecimento/5a21c34cf706422ca02e043ab8c80b7d"
                  target="_blank"
                >
                  Documentação
                  <FiExternalLink />
                </S.Link>
                <S.Link
                  href="https://app.pipefy.com/public/form/zsc8VbvF"
                  target="_blank"
                >
                  Helpdesk
                  <FiExternalLink />
                </S.Link>
              </S.Section>
            </S.LineWrapper>
          </S.InfoCard>
        </S.InfoWrapper>
        <S.TableWrapper>
          <S.FiltersWrapper>
            <LabDsSearchBar
              label="Busque por cliente"
              value={search}
              onChangeInput={({ detail }) => setSearch(detail)}
            />
          </S.FiltersWrapper>
          <LabDsTable
            data={{ headers, rows }}
            onFinalLineNextPage={() => fetchNextPage()}
          />
        </S.TableWrapper>
      </S.Container>
    </>
  );
}
