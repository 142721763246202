import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import '../node_modules/react-vis/dist/style.css';

import 'v4design-tokens/dist/Internal-Domain/HQ_FC/css/variables.css';
import GlobalStyle from './styles/global';
import 'v4design-tokens/dist/Internal-Domain/HQ_FC/css/variables.css';
import Routes from './routes';
import { Hooks } from './hooks';

const queryClient = new QueryClient();

function App() {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  return (
    <GoogleOAuthProvider clientId={clientId as string}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Hooks>
            <ReactQueryDevtools />
            <Routes />
            <GlobalStyle />
          </Hooks>
        </QueryClientProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
