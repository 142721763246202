import { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  small?: boolean;
  secondary?: boolean;
  outline?: boolean;
  success?: boolean;
};

export function Button({
  children,
  loading,
  small = false,
  secondary = false,
  outline = false,
  success = false,
  ...rest
}: ButtonProps) {
  return (
    <Container
      small={small}
      secondary={secondary}
      outline={outline}
      success={success}
      type="button"
      {...rest}
    >
      {loading ? 'Carregando...' : children}
    </Container>
  );
}
