import { usersApi as api } from '../../api';

export async function generateExternalToken(): Promise<string | false> {
  try {
    const response = await api
      .get<{ data: string }>(`/auth/generateExternalToken`)
      .then((res) => res.data);

    const externalToken: any = response.data;

    return externalToken;
  } catch {
    return false;
  }
}
