import { IData } from 'v4web-components';
import { convertNumberToBRL } from '../../../../../utils/convertNumberToBRL';
import { investmentInfos } from '../../../../../services/requests/Unit/calculate';

export function investmentTable(
  contractDuration: string,
  detailedInvestment?: investmentInfos['detailedInvestment'],
  detailedInvestmentPerMonth?: investmentInfos['detailedInvestmentPerMonth'],
): IData {
  const HEADER: IData['headers'] = [
    {
      title: '',
      colKey: 'name',
      type: 'text',
    },
    {
      title: 'Mês',
      colKey: 'month',
      type: 'text',
    },
    {
      title: contractDuration === '12' ? '12 meses' : '24 meses',
      colKey: 'twoYears',
      type: 'text',
    },
  ];

  const ROWS = [
    {
      name: { text: { title: 'Investimento operacional' } },
      month: {
        text: {
          title: `${
            detailedInvestment?.operationalInvestment
              ? convertNumberToBRL(detailedInvestment?.operationalInvestment)
              : '-'
          }`,
        },
      },
      twoYears: {
        text: {
          title: `${
            detailedInvestmentPerMonth?.operationalInvestment
              ? convertNumberToBRL(
                  detailedInvestmentPerMonth?.operationalInvestment,
                )
              : '-'
          }`,
        },
      },
    },
    {
      name: { text: { title: 'Imposto' } },
      month: { text: { title: '10%' } },
      twoYears: { text: { title: '10%' } },
    },
    {
      name: { text: { title: 'Imposto valor' } },
      month: {
        text: {
          title: `${
            detailedInvestment?.taxValue
              ? convertNumberToBRL(detailedInvestment?.taxValue)
              : '-'
          }`,
        },
      },
      twoYears: {
        text: {
          title: `${
            detailedInvestmentPerMonth?.taxValue
              ? convertNumberToBRL(detailedInvestmentPerMonth?.taxValue)
              : '-'
          }`,
        },
      },
    },
    {
      name: { text: { title: 'Despesas diretas $' } },
      month: {
        text: {
          title: `${
            detailedInvestment?.directExpenses
              ? convertNumberToBRL(detailedInvestment?.directExpenses)
              : '-'
          }`,
        },
      },
      twoYears: {
        text: {
          title: `${
            detailedInvestmentPerMonth?.directExpenses
              ? convertNumberToBRL(detailedInvestmentPerMonth?.directExpenses)
              : '-'
          }`,
        },
      },
    },
    {
      name: { text: { title: 'Sálarios direto operação $' } },
      month: {
        text: {
          title: `${
            detailedInvestment?.directOperationSalary
              ? convertNumberToBRL(detailedInvestment?.directOperationSalary)
              : '-'
          }`,
        },
      },
      twoYears: {
        text: {
          title: `${
            detailedInvestmentPerMonth?.directOperationSalary
              ? convertNumberToBRL(
                  detailedInvestmentPerMonth?.directOperationSalary,
                )
              : '-'
          }`,
        },
      },
    },
    {
      name: { text: { title: 'Royalties %' } },
      month: { text: { title: '20%' } },
      twoYears: { text: { title: '20%' } },
    },
    {
      name: { text: { title: 'Margem $' } },
      month: {
        text: {
          title: `${
            detailedInvestment?.margin
              ? `${convertNumberToBRL(detailedInvestment?.margin)}`
              : '-'
          }`,
        },
      },
      twoYears: {
        text: {
          title: `${
            detailedInvestmentPerMonth?.margin
              ? `${convertNumberToBRL(detailedInvestmentPerMonth?.margin)}`
              : '-'
          }`,
        },
      },
    },
    {
      name: { text: { title: 'Margem %' } },
      month: {
        text: {
          title: `${detailedInvestmentPerMonth?.marginPercentage || 0}%`,
        },
      },
      twoYears: {
        text: {
          title: `${detailedInvestmentPerMonth?.marginPercentage || 0}%`,
        },
      },
    },
  ];

  return {
    headers: HEADER,
    rows: ROWS,
  };
}
