import { ReactNode } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import {
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
} from 'react-icons/ai';
import { LabDsTooltip } from 'v4web-components-react';
import { slugify } from '../../../../../../utils/slugify';
import * as S from './styles';

interface IntegrationStatusProps {
  integration: AdsAccounts;
}

export function getConnectionLabel(
  isDeleted?: boolean,
  status?: number,
): string {
  if (isDeleted) return 'Removido';
  switch (status) {
    case 1:
      return 'Vinculado';
    case 2:
      return 'Problema';
    case 3:
      return 'Problema';
    default:
      return 'Integrando';
  }
}

function getHelperIcon(className: string): ReactNode {
  switch (className) {
    case 'vinculado':
      return <AiOutlineCheckCircle size={16} />;
    case 'problema':
      return <AiOutlineExclamationCircle size={16} />;
    default:
      return <FiAlertTriangle size={16} />;
  }
}

export function IntegrationStatus({ integration }: IntegrationStatusProps) {
  const { isDeleted } = integration;
  const connectionLabel = getConnectionLabel(!!isDeleted, integration.status);
  const className = slugify(connectionLabel);

  const content = (
    <S.Status title={connectionLabel}>
      <S.StatusLabel className={className}>
        <p>{connectionLabel}</p>
        {getHelperIcon(className)}
      </S.StatusLabel>
    </S.Status>
  );

  return className === 'integrando' ? (
    <LabDsTooltip
      tooltipPosition="twelve-hours"
      label="Estamos coletando os seus dados, pode levar algumas horas até concluir. Você pode concluir que a sua integração irá continuar."
    >
      {content}
    </LabDsTooltip>
  ) : (
    content
  );
}
