import styled from 'styled-components';

export const Container = styled.div<{ show: boolean }>`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  margin: 10px;
  display: ${({ show }) => (show ? 'flex' : 'none')};
`;

export const ModalContent = styled.div<{ size: 'sm' | 'md' | 'lg' }>`
  min-width: 600px;
  min-height: 250px;
  transform: translate(0, 0);
  position: relative;
  background: #f4f4f8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-left: 5px solid #00c49a;
  border-radius: 5px;
  padding: 2rem;

  main {
    position: relative;
    padding: 1rem;
    width: 100%;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalHeader = styled.header`
  display: flex;
  flex-direction: column;
`;

export const ModalFooter = styled.header`
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  padding: 2rem;

  width: 100%;

  border-top-width: 1px;
  border-top-color: var(--primary-dark-1);
  border-top-style: solid;

  position: absolute;
  bottom: 0;
  z-index: 1;
`;

export const ModalTitle = styled.h1`
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
`;
export const ModalSubTitle = styled.h3`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
  margin-top: 16px;
`;

export const ProgressContent = styled.div`
  margin-top: 25px;
  display: flex;
  width: 100%;
  height: 20px;

  background-color: #c1c1d7;
  border-radius: 20px;
`;

export const Progress = styled.div<{ porc: string }>`
  width: ${({ porc }) => porc};
  height: 20px;
  border-radius: 20px;

  background-color: #00c49a;
`;

export const Close = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;

  width: 25px;
  height: 25px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 2px 6px 2px rgba(0, 0, 0, 0.06);
  border: none;
  background-color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
