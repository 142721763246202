import { LabDsCheckbox, LabDsSwitch } from 'v4web-components-react';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Modal } from '../../../LegacyV4Components';

import * as S from './styles';
import { UnitInterface } from '../../../../types/units';

import { useToast } from '../../../../hooks/toast';
import { grantUnitToTransition } from '../../../../services/requests/Unit/grantUnitToTransition';

interface ModalFieldsIncompleteProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  data: UnitInterface;
  readUnit: () => void;
}

export function ModalTransition({
  isOpen,
  setIsOpen,
  data,
  readUnit,
}: ModalFieldsIncompleteProps) {
  const [onStatus, setOnStatus] = useState(data.canTransition || data.canOffer);

  const { addToast } = useToast();

  const handleStatus = () => {
    setOnStatus(!onStatus);
  };

  const [canTransition, setCantTransition] = useState<boolean>(
    !!data?.canTransition,
  );

  const [canOffer, setCantOffer] = useState<boolean>(!!data?.canOffer);

  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    const body = {
      unitId: data.id,
      canTransition,
      canOffer,
    };

    const transition = await grantUnitToTransition(body);

    if (transition === 'error') {
      addToast({
        type: 'error',
        title: 'Erro ao habilitar unidade',
        description: 'Ocorreu um erro ao habilitar',
      });
      return;
    }

    addToast({
      type: 'success',
      title: 'Unidade habilitada',
      description: 'Unidade habilitada com sucesso',
    });

    readUnit();

    setIsOpen(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal
        show={isOpen}
        onBackground={() => setIsOpen(false)}
        closeButton
        labelSubmit="Habilitar funções"
        title="Habilitar Projetos para transição"
        footer
        height="800px"
        handlerSubmit={() => {
          handleSubmit(onSubmit);
        }}
        buttonType="submit"
        disabled={!onStatus}
      >
        <S.Container>
          <S.ContainerStatus>
            <S.Title>Permissões</S.Title>
            <S.ContainerSwitch>
              <S.Status>Status do ambiente</S.Status>
              <LabDsSwitch
                size="small"
                onHandleSwitch={handleStatus}
                checked={onStatus}
              />
            </S.ContainerSwitch>
          </S.ContainerStatus>
          <S.ContainerCheckbox>
            <LabDsCheckbox
              label="Habilitar disponibilização de projetos para transição"
              avatar={false}
              disabled={!onStatus}
              checked={canTransition}
              onHandleCheckbox={() => setCantTransition(!canTransition)}
            />

            <S.TextInfo disabled={!onStatus}>
              A unidade {data.name} terá acesso à página de projetos para
              transição e conseguirá disponibilizar os mesmos para o processo.
            </S.TextInfo>
          </S.ContainerCheckbox>
          <S.ContainerCheckbox>
            <LabDsCheckbox
              label="Habilitar demonstração de interesse aos projetos para transição."
              avatar={false}
              checked={canOffer}
              disabled={!onStatus}
              onHandleCheckbox={() => setCantOffer(!canOffer)}
            />

            <S.TextInfo disabled={!onStatus}>
              A unidade {data.name} terá acesso à página de ofertas dos projetos
              que estão para transição e assim demonstrar interesse.
            </S.TextInfo>
          </S.ContainerCheckbox>
        </S.Container>
      </Modal>
    </form>
  );
}
