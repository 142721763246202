import { FiArrowRight } from 'react-icons/fi';
import { NumberFormatValues } from 'react-number-format';
import { FormikTouched } from 'formik';
import { Button } from '../../../LegacyV4Components';
import * as S from './styles';
import { TextField } from '../../molecules/TextField';
import { FormikCepInput } from '../../molecules/FormikCepInput';
import { FormikPaymentOptions } from '../../../../pages/PaymentOptions';
import { NumberField } from '../../molecules/NumberField';
import { FormikPlansOptions } from '../../../../pages/PlanOptions';

interface AddressPaymentProps {
  values: FormikPaymentOptions | FormikPlansOptions;
  errors: any;
  handleBlur: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  indexTab: number;
  setIndexTab: React.Dispatch<React.SetStateAction<number>>;
  setFieldsIncomplete?: React.Dispatch<React.SetStateAction<boolean>>;
  touched: FormikTouched<FormikPaymentOptions>;
  mediaWallet?: boolean;
}

export function AddressPayment({
  values,
  errors,
  handleBlur,
  indexTab,
  setIndexTab,
  setFieldsIncomplete,
  setFieldValue,
  touched,
  mediaWallet,
}: AddressPaymentProps) {
  return (
    <S.Container>
      {mediaWallet === true ? (
        <FormikCepInput
          format="#####-###"
          name="address.zip_code"
          label="Cep *"
          value={values.address.zip_code}
          placeholder="00000-000"
          helper={
            touched?.address?.zip_code && errors?.address?.zip_code
              ? errors?.address?.zip_code
              : ''
          }
          validateOnChange={false}
          onBlur={handleBlur}
          onValueChange={(val: NumberFormatValues) => {
            setFieldValue(`address.zip_code`, val.value);
          }}
        />
      ) : (
        <FormikCepInput
          format="#####-###"
          name="address.zipCode"
          label="Cep *"
          value={values.address.zipCode}
          placeholder="00000-000"
          helper={
            touched?.address?.zipCode && errors?.address?.zipCode
              ? errors?.address?.zipCode
              : ''
          }
          validateOnChange={false}
          onBlur={handleBlur}
          onValueChange={(val: NumberFormatValues) => {
            setFieldValue(`address.zipCode`, val.value);
          }}
        />
      )}
      <TextField
        name="address.street"
        label="Rua *"
        value={values.address.street}
        placeholder="Informe seu endereço"
        helper={
          touched?.address?.street && errors?.address?.street
            ? errors?.address?.street
            : ''
        }
        validateOnChange={false}
        onBlur={handleBlur}
        onValueChange={(val: NumberFormatValues) => {
          setFieldValue(`address.street`, val.value);
        }}
      />
      <NumberField
        format="########"
        name="address.number"
        label="Número *"
        value={values.address.number || undefined}
        placeholder="Informe o número do endereço de cobrança"
        helper={
          touched?.address?.number && errors?.address?.number
            ? errors?.address?.number
            : ''
        }
        validateOnChange={false}
        onBlur={handleBlur}
        onValueChange={(val: NumberFormatValues) => {
          setFieldValue(`address.number`, val.value);
        }}
      />
      <TextField
        name="address.district"
        label="Bairro *"
        value={values.address.district}
        placeholder="Informe o bairro do endereço de cobrança"
        helper={
          touched?.address?.district && errors?.address?.district
            ? errors?.address?.district
            : ''
        }
        validateOnChange={false}
        onBlur={handleBlur}
        onValueChange={(val: NumberFormatValues) => {
          setFieldValue(`address.district`, val.value);
        }}
      />

      <TextField
        name="address.complement"
        label="Complemento *"
        value={values.address.complement}
        placeholder="Ex: Próximo ao mercadinho avenida"
        helper={
          touched?.address?.complement && errors?.address?.complement
            ? errors?.address?.complement
            : ''
        }
        validateOnChange={false}
        onBlur={handleBlur}
        onValueChange={(val: NumberFormatValues) => {
          setFieldValue(`address.complement`, val.value);
        }}
      />
      <div style={{ float: 'right' }}>
        <Button
          variant="primary"
          rightIcon={<FiArrowRight />}
          color="green"
          size="default"
          onClick={async () => {
            if (
              (mediaWallet
                ? !errors.address?.zip_code
                : !errors.address?.zipCode) &&
              !errors.address?.complement &&
              !errors.address?.district &&
              !errors.address?.number &&
              !errors.address?.street
            ) {
              setIndexTab(indexTab + 1);
            } else if (setFieldsIncomplete) setFieldsIncomplete(true);
          }}
        >
          Continuar
        </Button>
      </div>
    </S.Container>
  );
}
