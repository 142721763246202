import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  SetStateAction,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  LabDsButton,
  LabDsBreadcrumb,
  LabDsSearchBar,
  LabDsTab,
  LabDsTable,
} from 'v4web-components-react';
import { IData } from 'v4web-components';

import {
  IDataHeader,
  IDataRow,
} from 'v4web-components/dist/types/components/layout/lab-ds-table/lab-ds-table';
import {
  Container,
  SectionContent,
  SectionTitle,
  Section,
} from '../../components/LegacyV4Components';
import { CreateNewUnit } from '../../components/AtomicDesign/molecules/CreateNewUnit';
import { useAuth } from '../../hooks/auth';

import { getUnits } from '../../services/requests/Unit/getUnits';

import { headerFranchiseTable } from './constants/table';
import { Unit } from '../../hooks/types';
import useDebounce from '../../hooks/useDebounce';

import * as S from './styles';
import { ActionUnit } from '../../components/AtomicDesign/molecules/actionUnit';

interface UnitRequest {
  limit: number;
  page: number;
  total: number;
  totalPages: number;
  data: Unit[];
}

enum Tabs {
  active = 'Ativas',
  inactive = 'Inativas',
}

interface MyDataRow extends IDataRow {
  active?: boolean;
  handleRowClick?: () => void;
  _id?: string;
}
export interface MyData {
  headers: IDataHeader[];
  rows: Record<string, MyDataRow>[];
}
interface ModalProps {
  action: string;
  disableUnitProps: {
    name: string;
    id: string;
    active?: boolean;
  };
  unit: Unit;
  getUnit: React.Dispatch<SetStateAction<Unit | undefined>>;
}

export function Units() {
  const [tab, setTab] = useState(Tabs.active.toString());
  const { user } = useAuth();
  const { permissions } = user;

  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce<string>(query, 500);

  const [openModal, setOpenModal] = useState(false);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [modalProps, setModalProps] = useState<ModalProps>();
  const [unitUpd, getUnitUpd] = useState<Unit>();
  const { data, fetchNextPage, refetch } = useInfiniteQuery(
    ['units'],
    async ({ pageParam = 1 }) => {
      const unitsPromise = (await getUnits(
        pageParam,
        debouncedQuery,
        true,
      )) as unknown as UnitRequest;
      return unitsPromise;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.page - 1 ?? undefined,
      getNextPageParam: (lastPage) => lastPage.page + 1 ?? undefined,
    },
  );

  const handlerDisableUser = useCallback(
    async (id: string, active: boolean, name: string, action: string) => {
      setModalProps({
        action,
        disableUnitProps: {
          id,
          active,
          name,
        },
        unit: unitUpd as Unit,
        getUnit: getUnitUpd,
      });
      setOpenModalAction(true);
    },
    [unitUpd],
  );

  const handlerDeleteUser = useCallback(
    async (id: string, name: string, action: string) => {
      setModalProps({
        action,
        disableUnitProps: {
          id,
          name,
        },
        unit: unitUpd as Unit,
        getUnit: getUnitUpd,
      });
      setOpenModalAction(true);
    },
    [unitUpd],
  );

  useEffect(() => {
    refetch({ refetchPage: (_page, index) => index === 0 });
  }, [debouncedQuery, refetch, unitUpd]);

  const units = useMemo(() => {
    const navigateToFranchise = (id: string) => {
      navigate(`/unit/view/head/${id}`);
    };
    const unitsData: MyData['rows'] = [];
    data?.pages?.forEach((page) =>
      page.data?.forEach((unit) => {
        unitsData.push({
          unity_name: {
            text: {
              title: unit.name,
            },
            handleRow: () => navigateToFranchise(unit._id),
          },
          active: { active: unit.active },
          cluster: {
            text: {
              title: '-',
            },
            handleRow: () => navigateToFranchise(unit._id),
          },
          mrr: {
            text: {
              title: '-',
            },
            handleRow: () => navigateToFranchise(unit._id),
          },
          nps: {
            text: {
              title: '-',
            },
            handleRow: () => navigateToFranchise(unit._id),
          },
          taxa_churn: {
            text: {
              title: '-',
            },
            handleRow: () => navigateToFranchise(unit._id),
          },
          lifetime: {
            text: {
              title: '-',
            },
            handleRow: () => navigateToFranchise(unit._id),
          },
          action: {
            action: {
              items: [
                {
                  label: unit.active ? 'Desativar' : 'Ativar',
                  key: 'disable',
                  event: () => {
                    handlerDisableUser(
                      unit._id as string,
                      !unit.active,
                      unit.name,
                      unit.active ? 'Desativar' : 'Ativar',
                    );
                  },
                },
              ],
              destructiveItem: {
                label: 'Excluir',
                key: 'delete',
                event: () => {
                  handlerDeleteUser(unit._id, unit.name, 'excluir');
                },
              },
            },
          },
        });
      }),
    );
    return unitsData;
  }, [data?.pages, handlerDeleteUser, handlerDisableUser, navigate]);

  const filteredUnits = useMemo(() => {
    const tabFiltered = units.filter((unit) => {
      if (unit.id === process.env.REACT_APP_HEADQUARTER_ID) {
        return false;
      }

      if (tab === Tabs.active.toString()) {
        return unit.active?.active;
      }
      return !unit.active?.active;
    });
    return tabFiltered;
  }, [tab, units]);

  const headers = headerFranchiseTable;

  const dataTableSingle: IData = {
    headers,
    rows: filteredUnits,
  };

  return (
    <>
      <Container>
        <LabDsBreadcrumb
          breadcrumbs={[
            {
              label: 'Unidades Franqueadas',
              key: 'units',
              link: '/unidades',
              event: () => '',
            },
          ]}
        />

        <Section>
          <SectionContent>
            <SectionTitle text="Unidades Franqueadas" />
            <S.Row>
              <LabDsTab
                value="active"
                tabs={[
                  {
                    title: 'Ativas',
                    key: 'active',
                    event: () => setTab(Tabs.active),
                  },
                  {
                    title: 'Inativas',
                    key: 'inactive',
                    event: () => setTab(Tabs.inactive),
                  },
                ]}
              />
            </S.Row>
            <S.Filters>
              <LabDsSearchBar
                label="Busque por nome ou publicação"
                onChangeInput={({ detail }) => setQuery(detail)}
                value={query}
                placeholder="Busque por investidor, mandato..."
              />
              <LabDsButton
                size="medium"
                variant="primary"
                onHandleButton={() => navigate('/unidades/create')}
                disabled={!permissions?.headquarter?.admin}
                type="submit"
                leadingIcon="add"
                label="Criar nova unidade"
              />
            </S.Filters>

            <LabDsTable
              data={dataTableSingle}
              actionField={permissions?.headquarter?.admin}
              onFinalLineNextPage={() => {
                fetchNextPage();
              }}
              headerPosition="left"
            />
          </SectionContent>
        </Section>
      </Container>

      {openModal && (
        <CreateNewUnit
          getUnit={getUnitUpd}
          setIsOpen={setOpenModal}
          isOpen={openModal}
        />
      )}
      <ActionUnit
        modalProps={modalProps as ModalProps}
        setIsOpen={setOpenModalAction}
        isOpen={openModalAction}
      />
    </>
  );
}
