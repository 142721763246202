import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import {
  LabDsButton,
  LabDsRadioButton,
  LabDsTextField,
} from 'v4web-components-react';
import { Modal, Container } from '../../../LegacyV4Components';
import { useToast } from '../../../../hooks/toast';
import { dashboardsApi } from '../../../../services/api';

import { EditDashboardModal } from '../EditDashboardModal';
import * as S from './styles';

interface DashboardOperationProps {
  isOpen: boolean;
  operation: 'create' | 'edit';
  closeModal: () => void;
  dashboard?: {
    _id: string;
    name: string;
    description: string;
    link: string;
  };
  clientId?: string | undefined;
  isHeadquarterUser?: boolean;
}

interface DataProps {
  name: string;
  description: string;
  link: string;
  _id?: string;
  customerId?: string;
  type?: string;
}

export function DashboardOperationModal({
  isOpen,
  operation,
  closeModal,
  dashboard,
  clientId,
  isHeadquarterUser,
}: DashboardOperationProps) {
  const [isConfirmEditOpen, setIsConfirmEditOpen] = useState(false);
  const { addToast } = useToast();
  const [onlyHeadaquarter, setOnlyHeadquarter] = useState(true);
  const [general, setGeneral] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [type, setType] = useState<string | undefined>(undefined);
  const [id, setId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    name: false,
    description: false,
    link: false,
  });

  const data: DataProps = {
    name,
    description,
    link,
    _id: id,
    customerId: clientId,
    type,
  };

  function handleCloseModal() {
    setName('');
    setDescription('');
    setLink('');
    setId('');
    setError({
      name: false,
      description: false,
      link: false,
    });
    closeModal();
  }

  const queryClient = useQueryClient();
  const mutation = useMutation(
    async () => dashboardsApi.post('/dashboard', data),
    {
      onSuccess: async () => {
        handleCloseModal();
        setIsLoading(false);
        // cancela se outra query estiver rodando
        await queryClient.invalidateQueries(['dashboards']);
        await queryClient.refetchQueries(['dashboards']);
        await queryClient.refetchQueries(['favorites']);
      },
      onError: () => {
        addToast({
          type: 'error',
          title: 'Erro na criação de dashboard',
          description: 'Ocorreu um erro ao adicionar dashboard.',
        });
        setIsLoading(false);
      },
    },
  );

  useEffect(() => {
    if (dashboard) {
      setName(dashboard.name);
      setDescription(dashboard.description);
      setLink(dashboard.link);
      setId(dashboard._id);
    }
  }, [dashboard]);

  function handleConfirmEdit() {
    setIsConfirmEditOpen(true);
  }

  function handleCloseConfirmEdit() {
    setIsConfirmEditOpen(false);
  }

  useEffect(() => {
    if (clientId) {
      setType('CUSTOMER');
    } else if (!isHeadquarterUser) {
      setType('UNIT');
    } else {
      setType('HQ');
    }
  }, [clientId, isHeadquarterUser]);

  async function handleSubmit() {
    if (operation === 'create') {
      delete data._id;
      if (!name || !description || !link) {
        setError({ name: !name, description: !description, link: !link });
        return;
      }
      setIsLoading(true);

      mutation.mutateAsync();
    }

    if (operation === 'edit') {
      closeModal();
      handleConfirmEdit();
    }
  }
  return (
    <>
      <Modal
        show={isOpen}
        title={
          operation === 'create' ? 'Adicionar Dashboard' : 'Editar Dashboard'
        }
        closeButton
        onBackground={handleCloseModal}
      >
        <Container style={{ backgroundColor: 'transparent' }}>
          <S.Container>
            <LabDsTextField
              value={name}
              titleInput="Nome"
              label="Nome do dashboard"
              state={error.name ? 'error' : 'default'}
              helperText={error.name ? '*Preencha o campo nome' : ''}
              onChangeInput={({ detail }) => {
                setName(detail);
              }}
            />
            <LabDsTextField
              value={description}
              titleInput="Descrição"
              label="Dê uma descrição ao Dashboard"
              state={error.description ? 'error' : 'default'}
              helperText={
                error.description ? '*Preencha o campo descrição' : ''
              }
              onChangeInput={({ detail }) => {
                setDescription(detail);
              }}
            />
            <LabDsTextField
              value={link}
              titleInput="Link"
              label="Link"
              state={error.link ? 'error' : 'default'}
              helperText={error.link ? '*Preencha o campo link' : ''}
              onChangeInput={({ detail }) => {
                setLink(detail);
              }}
            />

            {isHeadquarterUser && operation === 'create' ? (
              <>
                <p>Quem pode visualizar?</p>
                <S.RadioButtons>
                  <LabDsRadioButton
                    label="Somente HQ"
                    checked={onlyHeadaquarter}
                    onHandleRadioButton={() => {
                      setOnlyHeadquarter(!onlyHeadaquarter);
                      setGeneral(false);
                      setType('HQ');
                    }}
                  />
                  <LabDsRadioButton
                    label="HQ e Franqueados"
                    checked={general}
                    onHandleRadioButton={() => {
                      setGeneral(!general);
                      setOnlyHeadquarter(false);
                      setType('UNITS');
                    }}
                  />
                </S.RadioButtons>
              </>
            ) : null}
          </S.Container>
          <S.ButtonContainer>
            <LabDsButton
              label="Cancelar"
              variant="danger-outlined"
              onHandleButton={handleCloseModal}
            />
            <LabDsButton
              label="Salvar"
              loading={isLoading}
              onHandleButton={handleSubmit}
            />
          </S.ButtonContainer>
        </Container>
      </Modal>

      <EditDashboardModal
        isOpen={isConfirmEditOpen}
        closeModal={handleCloseConfirmEdit}
        dashboard={data}
      />
    </>
  );
}
