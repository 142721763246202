import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0;
  border-bottom: none;

  h3 {
    font: var(--lab-ds-semantic-typography-heading-h3);
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  right: 1rem;
  bottom: 1rem;

  lab-ds-button {
    margin-left: 1rem;
  }
`;

export const Table = styled.div`
  margin-top: 2.5rem;
  .cell-row-components {
    justify-content: start;
  }
`;
