import { unitsApi as api } from '../../api';

export async function getUserUnit(token: string, unitId?: string) {
  try {
    const data = await api.get(`/units/${unitId}/`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (err) {
    console.error('Erro ao listar informações da unidade', err);
    return err;
  }
}
