import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const LoadingTable = ({ coll, row }: { coll: number; row: number }) => {
  return (
    <table style={{ width: '100%' }}>
      <thead>
        <tr>
          {[...Array(coll)].map((_, index) => (
            <th key={Number(index)} aria-label="default">
              <Skeleton
                width="%100"
                height={45}
                style={{ marginBottom: '10px' }}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...Array(row)].map((_row, indexRow) => (
          <tr key={Number(indexRow)}>
            {[...Array(coll)].map((_coll, index) => (
              <td key={Number(index)} aria-label="default">
                <Skeleton width="%100" height={50} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
