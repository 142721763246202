import styled from 'styled-components';

export const ContainerSwitch = styled.div`
  align-items: center;
  display: flex;
  margin-left: 24px;
  span {
    color: #727578;
    font-size: 0.75rem;
    font-weight: 400;
  }
`;

export const SwitchLabel = styled.label`
  display: inline-block;
  height: 0.875rem;
  margin-right: 0.5rem;
  position: relative;
  width: 2.5rem;
  input {
    height: 0;
    opacity: 0;
    width: 0;
  }
  .slider {
    background: #f4f4f8;
    border: 1px solid rgba(0, 0, 0, 0.12);
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
  }
  .slider:before {
    background: #00c49a;
    bottom: -4px;
    content: '';
    height: 1.25rem;
    // left: -10px;
    position: absolute;
    transition: 0.4s;
    width: 1.25rem;
  }
  input:checked + .slider {
    background-color: rgba(0, 0, 0, 0.33);
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(-26px);
    transform: translateX(-26px);
  }
  .slider.round {
    border-radius: 2.125rem;
  }
  .slider.round:before {
    border-radius: 50%;
  }
`;

export const ButtonToDisable = styled.button`
  cursor: auto;
  background: #f4f4f8;
  border-radius: 4px;
  padding: 4px 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 12px;
    color: #333333;
    font-weight: 400;
  }
`;
