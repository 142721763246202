import styled, { css } from 'styled-components';

export const Cards = styled.main`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1.5rem;

  width: 16.75rem;
  height: 8rem;
  padding: 2rem;

  p {
    font-weight: 700;
    white-space: nowrap;
  }

  border: 1px solid var(--grayscale-10);
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 1px 3px 1px rgba(0, 0, 0, 0.06);
`;

export const ImageIcon = styled.img`
  display: block;
  width: 3rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;

  text-align: center;

  &.status-pending {
    p {
      color: var(--error);
    }
  }

  & button {
    font-size: 0.875rem;
  }
`;

export const Blur = styled.div<{ blur: boolean }>`
  ${({ blur }) =>
    blur &&
    css`
      -webkit-filter: blur(1px) grayscale(100%);
      filter: blur(1px) grayscale(100%);
      opacity: 0.6;
      pointer-events: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    `}
`;
