import styled from 'styled-components';

export const Container = styled.div`
  width: 100% - 3rem;
  padding: 2rem 0;
  > section {
    background: var(--white);
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 0.5rem;
    h1 {
      margin-bottom: 2rem;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  lab-ds-tab {
    padding-bottom: 1rem;
  }
`;

export const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const InputArea = styled.div`
  min-width: 20rem;
`;

export const TdAction = styled.td`
  display: flex;
  justify-content: flex-end;
  padding-right: 6.25rem;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  a {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    text-decoration: underline;
    svg {
      margin-right: 0.5rem;
    }
  }
`;
