import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Input,
  InputCurrency,
  MultInputSearch,
} from '../../../LegacyV4Components';
import { useAuth } from '../../../../hooks/auth';
import { Seniority, User } from '../../../../hooks/types';
import { useToast } from '../../../../hooks/toast';

import * as S from './styles';
import { updateUserApi } from '../../../../services/requests/User/updateUserApi';
import { ProfileCard } from '../../../Default/ProfileCard';
import { getAllHeadquartersMandateItems, getAllSeniorityItems } from './hooks';
import { updateInvestorSchemaCreator } from './schemas';
import { updateUser as updateUserInterface } from './interface';
import { InputForm } from '../../../Default/InputForm';
import { InputItens } from '../../molecules/ModalPermissionsEdit/interfaces';

interface UserCardProps {
  profileUser: User;
}

export interface ISeniorityAndMandate {
  label: string;
  value?: string;
}

export function ProfileForm({ profileUser }: UserCardProps) {
  const { user } = useAuth();
  const isHeadquarter = user.unitId === process.env.REACT_APP_HEADQUARTER_ID;

  const [userCard, setUserCard] = useState(profileUser);

  const [mandate, setMandate] = useState<Array<ISeniorityAndMandate>>([]);
  const [seniority, setSeniority] = useState<Array<ISeniorityAndMandate>>([]);
  const [mandates, setMandates] = useState<InputItens[]>([]);
  const [seniorities, setSeniorities] = useState<InputItens[]>([]);
  const [instagram, setInstagram] = useState<string>('');
  const [linkedin, setLinkedin] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [wage, setWage] = useState<string | number>(0);

  const [porc, setPorc] = useState<string>('60%');

  const { addToast } = useToast();
  const navigate = useNavigate();

  const canEditSensibleInformartion =
    user.franchiseAdmin ||
    user.permissions?.unit?.admin ||
    user.permissions?.headquarter?.admin;

  const updateInvestorSchema = updateInvestorSchemaCreator(
    canEditSensibleInformartion || false,
  );

  const routes = [
    {
      title: 'Unidade Investidor',
      link: `/unit/read/${profileUser.unitId}`,
    },
    {
      title: profileUser.name,
      link: '/',
    },
  ];

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<updateUserInterface>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: async (data, context, options) => {
      const result = await yupResolver(updateInvestorSchema)(
        data,
        context,
        options,
      );
      if (Object.keys(result.errors).length) {
        addToast({
          type: 'error',
          title: 'Não foi possivel completar a ação!',
          description:
            'Verifique se todas as informações estão inseridas corretamente',
        });
      }
      return result;
    },
  });

  const handlerPropsProfile = () => {
    const aux = [];
    setValue('wage', userCard.wage ? (userCard.wage as number) : 0);
    setWage(userCard.wage || 0);
    if (userCard.mandate) {
      setMandate([{ label: userCard.mandate.name }]);
      setValue('mandateId', userCard.mandate._id);
    }
    if (userCard.seniority) {
      setSeniority([{ label: userCard.seniority.name }]);
      setValue('seniorityId', userCard.seniority._id);
    }

    setInstagram(userCard.links ? userCard.links[0]?.url : '');
    setLinkedin(userCard.links ? userCard.links[1]?.url : '');
    setValue('phone', userCard.phone);

    if (userCard.links && userCard.links.length > 0) {
      if (!userCard.links[0].url) aux.push('instagram');
      if (!userCard.links[1]?.url) aux.push('linkedin');
    } else {
      aux.push('instagram', 'linkedin');
    }

    if (!userCard.phone) aux.push('phone');

    if (aux.length > 0) {
      setPorc(`${100 - (aux.length * 100) / 8}%`);
    } else {
      setPorc('100%');
    }
  };

  const handleUpdateProp = async (data: updateUserInterface) => {
    setLoading(true);
    try {
      const body = {
        ...data,
        links: [
          {
            local: 'instagram',
            url: instagram,
          },
          {
            local: 'linkedin',
            url: linkedin,
          },
        ],
      };
      const newUser = await updateUserApi(userCard._id, body);
      setUserCard(newUser);
      addToast({
        title: 'Sucesso',
        description: 'Informações atualizadas com sucesso!',
        type: 'success',
      });
      navigate(
        `/perfil/${userCard._id}?origin=${encodeURI(JSON.stringify(routes))}`,
      );
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Não foi possivel completar a ação!',
        description:
          'Verifique se todas as informações estão inseridas corretamente',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const mandatesItems = getAllHeadquartersMandateItems(
      isHeadquarter || false,
    ) as InputItens[];
    setMandates(mandatesItems);
    const senioritiesItems = getAllSeniorityItems();
    setSeniorities(senioritiesItems);
  }, [isHeadquarter]);

  const mandateIsCientista = (mandateSelected: string) => {
    return (
      mandateSelected === 'Cientista do Marketing' ||
      mandateSelected === 'Cientista'
    );
  };

  const senioritiesItemsFiltered = mandateIsCientista(mandate[0]?.label)
    ? (seniorities.filter((seniorityFilter: InputItens) => {
        const value = seniorityFilter.value as Seniority;
        return value.name === 'TRAINEE' || value.name === 'JUNIOR';
      }) as InputItens[])
    : (seniorities as InputItens[]);

  useEffect(() => {
    handlerPropsProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCard]);

  return (
    <S.Container>
      <ProfileCard profile={userCard} />

      <h3>{userCard.name}</h3>
      {porc !== '100%' ? (
        <>
          <h4 className="info__card">Complete as informações do seu perfil!</h4>
          <S.ProgressContent>
            <S.ProgressBar porc={porc} />
          </S.ProgressContent>

          <h5 className="sub__info__card margin__top">
            {porc} do perfil completo
          </h5>
        </>
      ) : null}

      <S.Infos>
        <form onSubmit={handleSubmit(handleUpdateProp)}>
          <S.Row>
            {canEditSensibleInformartion ? (
              <MultInputSearch
                name="mandateId"
                label="Função/Cargo"
                required
                maxItem={1}
                options={mandates}
                placeholder="Selecione uma Função/Cargo"
                itensSelected={mandate}
                setItensSelected={(val: any) => {
                  setMandate(val);
                  setValue('mandateId', val[0]?.value._id);
                  if (mandateIsCientista(val[0]?.label)) {
                    setSeniority([]);
                    setValue('seniorityId', null as any);
                  }
                }}
                error={errors.mandateId?.message}
              />
            ) : (
              <>
                <h4>Função/Cargo</h4>
                <p>{mandate[0]?.label}</p>
              </>
            )}
          </S.Row>
          <S.Row>
            {canEditSensibleInformartion ? (
              <MultInputSearch
                name="seniorityId"
                label="Senioridade"
                required
                maxItem={1}
                options={senioritiesItemsFiltered}
                placeholder="Selecione a sua Senioridade"
                itensSelected={seniority}
                setItensSelected={(val: any) => {
                  setSeniority(val);
                  setValue('seniorityId', val[0]?.value._id);
                }}
                error={errors.seniorityId?.message}
              />
            ) : (
              <>
                <h4>Senioridade</h4>
                <p>{seniority[0]?.label}</p>
              </>
            )}
          </S.Row>
          <S.Row>
            <div className="Wage">
              <InputCurrency
                info="Apenas você e seu gestor podem ver essa informação. Caso tenha algum reajuste, peça para seu gestor atualizar essa informação"
                placeholder="Sálario"
                label="Sálario"
                value={wage}
                setCurrency={(e) => {
                  setValue('wage', e as number);
                  setWage(e);
                }}
                disabled={!canEditSensibleInformartion}
              />
            </div>
          </S.Row>
          <S.Row>
            <Input
              leftIcon={<FaLinkedin />}
              placeholder="URL do linkedin"
              label="Linkedin"
              value={linkedin}
              onChange={(e) => setLinkedin(e.target.value)}
            />
          </S.Row>
          <S.Row>
            <Input
              leftIcon={<FaInstagram />}
              label="Instagram"
              value={instagram}
              placeholder="@ do seu perfil"
              onChange={(e) => setInstagram(e.target.value)}
            />
          </S.Row>
          <S.Row>
            <InputForm
              nameInput="phone"
              type="tel"
              label="Telefone"
              placeholder="(xx) x.xxxx-xxxx"
              control={control}
              mask="(99) 9.9999-9999"
              error={errors.phone?.message}
            />
          </S.Row>
          <S.RowLeft>
            <div>
              <Button
                color="red"
                variant="secondary"
                size="default"
                onClick={() => {
                  navigate(
                    `/perfil/${userCard._id}?origin=${encodeURI(
                      JSON.stringify(routes),
                    )}`,
                  );
                }}
                loading={loading}
              >
                Cancelar
              </Button>
            </div>
            <Button
              color="green"
              variant="primary"
              size="default"
              type="submit"
              onClick={() => null}
              loading={loading}
            >
              Salvar
            </Button>
          </S.RowLeft>
        </form>
      </S.Infos>
    </S.Container>
  );
}
