import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import {
  LabDsButton,
  LabDsContextMenu,
  LabDsBadge,
} from 'v4web-components-react';

import { MdPersonOutline } from 'react-icons/md';

import {
  IoCheckmarkCircleOutline,
  IoAlertCircleOutline,
  IoStarOutline,
} from 'react-icons/io5';

import { format, parseISO } from 'date-fns';
import { Headline } from '../../../LegacyV4Components';

import * as S from './styles';
import { CreateStakeholderModal } from './components/CreateStakeholderModal';
import { SwapStakeholderModal } from './components/SwapStakeholderModal.ts';
import { DeleteStakeholderModal } from './components/DeleteStakeholderModal';

import { useAuth } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';

import { customerUsersApi } from '../../../../services/api';
import { createCustomerUser } from '../../../../services/requests/Customers/createCustomerUser';
import { reorganizeStakeholders } from './utils/reorganizeStakeholders';
import { mainStakeholderTag } from '../../../../utils/hardCodedVariables';
import { Customer } from '../../../../types/customer';

interface DotMenuOptions {
  label: string;
  key: string;
  event: () => void;
}

export default function CustomerStakeholders({
  customer,
}: {
  customer: Customer;
}) {
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();

  const { user } = useAuth();

  const [stakeholders, setStakeholders] = useState<Stakeholder[]>([]);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSwapModal, setShowSwapModal] = useState(false);

  const [selectedStakeholder, setSelectedStakeholder] = useState<Stakeholder>();

  const { permissions } = user;

  const getStakeholders = useCallback(async () => {
    const response = await customerUsersApi(`/customer/${customer._id}`);
    if (response) {
      const reorganizedStakeholders = reorganizeStakeholders(
        response.data as Stakeholder[],
      );

      setStakeholders(reorganizedStakeholders);
    }
  }, [customer]);

  useEffect(() => {
    getStakeholders();
  }, [getStakeholders]);

  const resendMktLabInvite = useCallback(
    async (stakeholder: Stakeholder) => {
      const customerUserData = {
        name: stakeholder.name,
        role: stakeholder.role,
        phone: stakeholder.phone,
        email: stakeholder.email,
        customerId: id as string,
      };
      (await createCustomerUser(customerUserData).catch(() => {
        addToast({
          title: 'Atenção',
          description: 'Houve um erro ao reenviar o convite.',
          type: 'error',
        });
        return;
      })) as Stakeholder;

      addToast({
        title: 'Convite reenviado com sucesso',
        description: '',
        type: 'success',
      });
    },
    [addToast, id],
  );

  const dotMenuOptions = useCallback(
    (stakeholder: Stakeholder): DotMenuOptions[] => {
      return [
        {
          label: 'Remover',
          key: 'remove',
          event: () => {
            setShowDeleteModal(true);
            setSelectedStakeholder(stakeholder);
          },
        },

        {
          label: 'Reenviar',
          key: 'resend',
          event: async () => {
            await resendMktLabInvite(stakeholder);
          },
        },
      ];
    },
    [resendMktLabInvite],
  );

  return (
    <S.Container>
      <S.Header>
        <Headline variant="h4">Stakeholders do projeto</Headline>

        <LabDsButton
          type="submit"
          label="Adicionar stakeholder"
          leadingIcon="add"
          disabled={!permissions?.customer?.edit}
          onHandleButton={() => setShowCreateModal(true)}
        />
      </S.Header>

      <S.Content>
        {stakeholders.map((stakeholder) => (
          <S.StakeholderCard main={stakeholder.role === mainStakeholderTag}>
            <S.PersonalInfo main={stakeholder.role === mainStakeholderTag}>
              <MdPersonOutline size={48} className="profile_outline" />
              <h4>
                <p>{stakeholder.name}</p>

                {stakeholder.role === mainStakeholderTag && (
                  <IoStarOutline size={64} className="start-icon" />
                )}
              </h4>
              <p>{stakeholder.role}</p>
            </S.PersonalInfo>

            <S.Info>
              <h5>Dados de contato</h5>
              <S.Data>
                <p>
                  <b>Função: </b>&nbsp; {stakeholder.role}
                </p>
                <p>
                  <b>Telefone:&nbsp; </b>
                  {stakeholder.phone}
                </p>
                <p>
                  <b>E-mail:&nbsp;</b>
                  {stakeholder.email}
                </p>
              </S.Data>
            </S.Info>

            <S.Info>
              <h5>
                Informações de acesso{' '}
                <LabDsContextMenu
                  items={dotMenuOptions(stakeholder)}
                  listPosition="right"
                />
              </h5>
              <S.Data completedGrowthClass={stakeholder.completedGrowthClass}>
                <p>
                  <b>Data de criação:&nbsp; </b>
                  {format(
                    parseISO(stakeholder.createdAt as string),
                    'dd/MM/yyyy',
                  )}
                </p>

                <p>
                  <b>Último acesso:&nbsp; </b>
                  {stakeholder.lastAccess &&
                    format(
                      parseISO(stakeholder.lastAccess),
                      'dd/MM/yyyy HH:mm',
                    )}
                </p>
                <p>
                  <b>Conclusão da Growth Class </b>
                  {stakeholder.completedGrowthClass ? (
                    <IoCheckmarkCircleOutline size={24} />
                  ) : (
                    <IoAlertCircleOutline size={24} />
                  )}
                </p>

                <S.InviteStatus lastAccess={!!stakeholder.lastAccess}>
                  <LabDsBadge
                    state={stakeholder.lastAccess ? 'success' : 'error'}
                    label={`Convite ${
                      stakeholder.lastAccess ? 'aceito' : 'pendente'
                    }`}
                  />
                </S.InviteStatus>

                <p />
              </S.Data>
            </S.Info>
          </S.StakeholderCard>
        ))}
      </S.Content>

      <CreateStakeholderModal
        isShown={showCreateModal}
        setIsShown={setShowCreateModal}
        closeModal={() => setShowCreateModal(false)}
        openSwapModal={() => setShowSwapModal(true)}
        stakeholders={stakeholders}
        setSelectedStakeholder={(value) => setSelectedStakeholder(value)}
        getStakeholders={getStakeholders}
      />

      <SwapStakeholderModal
        isOpen={showSwapModal}
        closeModal={() => setShowSwapModal(false)}
        newStakeholder={selectedStakeholder as Stakeholder}
        stakeholders={stakeholders}
        getStakeholders={getStakeholders}
      />

      {selectedStakeholder && (
        <DeleteStakeholderModal
          stakeholder={selectedStakeholder as Stakeholder}
          isOpen={showDeleteModal}
          closeModal={() => setShowDeleteModal(false)}
          getStakeholders={getStakeholders}
        />
      )}
    </S.Container>
  );
}
