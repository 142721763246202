import { addHours } from '../utils/formatStringToBRLDate';
import { kubernetesApi, leadBrokerApi } from './api';

export interface Balance {
  unitId?: string;
  data: PropsBalanceData;
  dataIp: {
    ip: string;
  };
}

export interface Extract {
  unitId?: string;
}

export interface PropsBalanceData {
  credit_type: string;
  justification: string;
  amountCents: number;
  expiresAt?: string;
  sourceIp?: string;
}

interface Manage {
  data: ManageDate[];
  status: number;
  statusText: string;
  headers: Headers;
  request: Request;
}
interface ManageDate {
  whoEnvy: WhoEnvy;
  whoInjected: WhoInjected;
  whoReceived: WhoReceived;
  _id: string;
  sourceIp: string;
  initialAmount: number;
  amount: number;
  amountBR: string;
  credit_type: string;
  status: boolean;
  justification: string;
  walletId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  expiresAt?: string;
}
interface WhoEnvy {
  userId: string;
  name: string;
  email: string;
}

interface WhoInjected {
  userId: string;
  name: string;
  email: string;
}
interface WhoReceived {
  unitId: string;
  socialName: string;
  cnpj: string;
  stakeholderEmail: string;
}
interface FormattedData {
  name: string;
  email: string;
  value: string;
  ip: string;
  justification: string;
  creationDate: string;
  creationTime: string;
  credit_type: string;
  expiresAt: string;
}

export async function getGeolocationIp() {
  const result = await fetch('https://api.ipify.org/?format=json');
  return result.json();
}

export async function putBalanceInject({ unitId, data, dataIp }: Balance) {
  const result = await kubernetesApi.post('/broker-wallet/bonus/add', {
    unitId,
    creditType: data.credit_type,
    justification: data.justification,
    amountCents: data.amountCents * 100,
    expiresAt: data.credit_type === 'FINANCIAL' ? null : data.expiresAt,
    sourceIp: dataIp.ip,
  });

  return result;
}

export async function handlerExtract(unitId?: string, setExtract?: any) {
  try {
    const result: Manage = await leadBrokerApi.get(
      `/wallet/balance/extract/credit/${unitId}`,
    );

    const auxData: FormattedData[] = [];

    result.data.forEach((item: ManageDate) => {
      auxData.push({
        name: item?.whoEnvy?.name || item?.whoInjected?.name,
        email: item?.whoEnvy?.email || item?.whoInjected?.email,
        value: item.amountBR,
        ip: item.sourceIp,
        justification: item?.justification,
        creationDate: new Date(item.createdAt).toLocaleDateString(),
        creationTime: new Date(item.createdAt).toLocaleTimeString(),
        credit_type: item.credit_type,
        expiresAt: item.expiresAt
          ? addHours(new Date(item.expiresAt), 3).toLocaleDateString()
          : 'Vitalício ',
      });
    });
    return setExtract(auxData);
  } catch (error) {
    return error;
  }
}
