import styled from 'styled-components';

export const Container = styled.div`
  border-top: 1px solid #c1c1d7;
  padding: 24px 16px 24px 16px;
  margin-bottom: 1rem;
`;

export const ContainerHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  opacity: ${(props) => props.color};
  .title {
    margin: 0px;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => props.color};
  h6 {
    display: flex;
    align-items: center;
  }
  p {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
  }
`;

export const Status = styled.div`
  background-color: ${(props) => props.color};
  padding: 0.2rem 1rem;
  margin-right: 1rem;
  border-radius: 4px;
`;

export const DotMenu = styled.div`
  display: flex;
  align-items: center;
  cursor: auto;
`;

export const ContainerInfo = styled.section`
  opacity: ${(props) => props.color};
  display: flex;
  flex-direction: column;
  p {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
  }
`;

export const ButtonCopyLink = styled.span`
  border: none;
  margin-left: 8px;
  svg {
    color: #00c49a;
  }
`;
