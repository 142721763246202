import { useNavigate, useParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { formatPhoneWithCountryCode } from '../../utils/formatPhone';
import { UnixToReadableDate } from '../../utils/UnixToReadableDate';
import { getSdrBotMessages } from '../../services/requests/sdrWhatsappBot/getMessages';
import { IClientMessage, IServerMessage } from '../../types/sdrBotMessages';

import {
  Container,
  MessagesContainer,
  ClientMessage,
  ServerMessage,
  LoadingContainer,
  NoMessages,
  ReturnButton,
  Header,
} from './styles';

export function MessageHistory() {
  const { phone_number } = useParams<{ phone_number: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [allMessages, setAllMessages] = useState<
    (IServerMessage | IClientMessage)[]
  >([]);
  const navigate = useNavigate();

  const getMessages = useCallback(async () => {
    setIsLoading(true);

    if (!phone_number) {
      setIsLoading(false);
      return;
    }

    const response = await getSdrBotMessages(phone_number.replace(/\D/g, ''));

    if (response) {
      setAllMessages(response.messages);
    }

    setIsLoading(false);
  }, [phone_number]);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  const renderMessages = useCallback(() => {
    if (allMessages.length <= 0) {
      return <NoMessages> Mensagens nao encontradas. </NoMessages>;
    }

    return (
      <>
        <span className="conversationStart">Início da conversa</span>
        <ul>
          {allMessages.map((message) => {
            const time = UnixToReadableDate(message.createdAt);
            if ('messageId' in message) {
              return (
                <ClientMessage key={message.id}>
                  <p>{message.text}</p>
                  <span>{time}</span>
                </ClientMessage>
              );
            }
            return (
              <ServerMessage key={message.id}>
                <p>{message.text}</p>
                <span>{time}</span>
              </ServerMessage>
            );
          })}
        </ul>
      </>
    );
  }, [allMessages]);

  return (
    <Container>
      <Header>
        <ReturnButton onClick={() => navigate('/historicos')}>
          Voltar
        </ReturnButton>
        <div className="title">
          <h2>Histórico: {formatPhoneWithCountryCode(phone_number!)}</h2>
        </div>
      </Header>
      <MessagesContainer>
        {isLoading ? (
          <LoadingContainer>
            <CircularProgress
              sx={{ width: '1rem', height: '1rem', color: 'red' }}
            />
          </LoadingContainer>
        ) : (
          renderMessages()
        )}
      </MessagesContainer>
    </Container>
  );
}
