import { Box } from '@mui/material';
import { LabDsBadge } from 'v4web-components-react';
import { AccordionDS } from '../../../../components/AtomicDesign/atoms/AccordionDS';
import * as S from '../../styles';
import {
  useGetSalesforceForm,
  useGetSalesforceLead,
} from '../../../../services/requests/Salesforce/useGetSalesforceLead';
import {
  IDataSalesforceLead,
  IDataSalesforceLeadForm,
} from '../../../../types/salesforce';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';

const contentAccordion = (
  dataLead: IDataSalesforceLead | undefined,
  dataForm: IDataSalesforceLeadForm | undefined,
) => {
  return (
    <S.ContainerContent>
      <S.RowContent>
        <S.LabelContent>
          <S.TitleContent>Nome do responsável</S.TitleContent>
          <S.DescriptionContent>
            {dataLead?.Name || dataForm?.Nome_do_Lead__c || 'Não informado'}
          </S.DescriptionContent>
        </S.LabelContent>
        <S.LabelContent>
          <S.TitleContent>Email</S.TitleContent>
          <S.DescriptionContent>
            {dataLead?.Email || 'Não informado'}
          </S.DescriptionContent>
        </S.LabelContent>
        <S.LabelContent>
          <S.TitleContent>Telefone</S.TitleContent>
          <S.DescriptionContent>
            {dataLead?.Phone ||
              dataLead?.MobilePhone ||
              dataForm?.Telefone_do_Lead__c ||
              'Não informado'}
          </S.DescriptionContent>
        </S.LabelContent>
        <S.LabelContent>
          <S.TitleContent>Tipo de operação</S.TitleContent>
          <S.DescriptionContent>
            {dataLead?.Tipo_de_Opera_o__c ||
              dataForm?.QLTipoOperacao__c ||
              'Não informado'}
          </S.DescriptionContent>
        </S.LabelContent>
      </S.RowContent>
      <S.RowContent>
        <S.LabelContent>
          <S.TitleContent>CPF</S.TitleContent>
          <S.DescriptionContent>
            {dataLead?.DocumentoCPFCNPJ__c || 'Não informado'}
          </S.DescriptionContent>
        </S.LabelContent>
        <S.LabelContent>
          <S.TitleContent>Estado</S.TitleContent>
          <S.DescriptionContent>
            {dataLead?.Qual_seu_estado__c ||
              dataLead?.State ||
              dataForm?.Qual_seu_estado__c ||
              'Não informado'}
          </S.DescriptionContent>
        </S.LabelContent>
        <S.LabelContent>
          <S.TitleContent>CEP</S.TitleContent>
          <S.DescriptionContent>
            {dataLead?.PostalCode || 'Não informado'}
          </S.DescriptionContent>
        </S.LabelContent>
        <S.LabelContent>
          <S.TitleContent>Modelo de negócio</S.TitleContent>
          <S.DescriptionContent>
            {dataLead?.ModeloNegocio__c ||
              dataForm?.ModeloNegocios__c ||
              'Não informado'}
          </S.DescriptionContent>
        </S.LabelContent>
      </S.RowContent>
      <S.RowContent>
        <S.LabelContent>
          <S.TitleContent>Setor da economia</S.TitleContent>
          <S.DescriptionContent>
            {dataLead?.Setor_da_Economia__c ||
              dataForm?.QLSetorEconomia__c ||
              'Não informado'}
          </S.DescriptionContent>
        </S.LabelContent>
        <S.LabelContent>
          <S.TitleContent>Segmento/Ramo de atividade</S.TitleContent>
          <S.DescriptionContent>
            {dataLead?.Setor_Segmento__c ||
              dataLead?.Qual_o_segmento_do_lead__c ||
              dataLead?.Em_qual_dos_doze_segmentos_se_enquadram__c ||
              'Não informado'}
          </S.DescriptionContent>
        </S.LabelContent>
        <S.LabelContent>
          <S.TitleContent>Segmento/Venda de projeto</S.TitleContent>
          <S.DescriptionContent>
            {dataForm?.Em_qual_dos_doze_segmentos_se_enquadram__c}
          </S.DescriptionContent>
        </S.LabelContent>
        <S.LabelContent>
          <S.TitleContent>Faturamento</S.TitleContent>
          <S.DescriptionContent>
            {dataLead?.Faturamento_Mensal_N_mero_Exato__c
              ? convertNumberToBRL(dataLead?.Faturamento_Mensal_N_mero_Exato__c)
              : 'Não informado'}
          </S.DescriptionContent>
        </S.LabelContent>
      </S.RowContent>
      <S.RowContent>
        <S.LabelContent>
          <S.TitleContent>Principais canais de venda</S.TitleContent>
          <S.DescriptionContent>
            {dataLead?.Por_quais_canais_voc_fez_contato__c ||
              dataLead?.Principal_canal_de_venda__c ||
              dataForm?.Quais_os_principais_canais_de_vendas__c ||
              dataLead?.Canal__c}
          </S.DescriptionContent>
        </S.LabelContent>
        <S.LabelContent>
          <S.TitleContent>Endereço</S.TitleContent>
          <S.DescriptionContent>
            {dataLead?.Address || 'Não informado'}
          </S.DescriptionContent>
        </S.LabelContent>
      </S.RowContent>
      <S.LabelContent>
        <S.TitleContent>Sites/redes sociais</S.TitleContent>
        <S.DescriptionContent>
          {dataLead?.Website || dataLead?.Contato_1_Redes_Sociais__c}
        </S.DescriptionContent>
      </S.LabelContent>
    </S.ContainerContent>
  );
};

export function OpportunityDetails({ leadId }: { leadId: string }) {
  const { data } = useGetSalesforceLead(leadId);

  const formId = data?.data?.FormularioQualificacao__c;

  const { data: dataForm } = useGetSalesforceForm(formId || '');

  const leadName =
    data?.data?.Name ||
    data?.data?.FirstName ||
    data?.data?.LastName ||
    data?.data?.Company;

  const dateStartNegotiation = new Date(
    data?.data?.DataHoraReuniaoComLead__c ||
      data?.data?.DataAbertura__c ||
      data?.data?.Data_de_Cadastro_do_Lead__c ||
      '',
  );

  const day = dateStartNegotiation.getDate().toString().padStart(2, '0');
  const month = (dateStartNegotiation.getMonth() + 1)
    .toString()
    .padStart(2, '0'); // +1 pois no getMonth Janeiro começa com zero.
  const year = dateStartNegotiation.getFullYear();
  const formatDateToBrl =
    day && month && year ? `${day}/${month}/${year}` : 'Não informado';

  return (
    <S.ContentBox>
      <S.ContentHeader>
        <h4>Detalhes da oportunidade</h4>
      </S.ContentHeader>
      <S.DescriptionHeader>
        <Box className="ContainerDescription">
          <strong>Projeto:</strong>
          <p>{leadName || 'Não informado'}</p>
        </Box>
        <Box className="ContainerDescription">
          <strong>Data de início das negociações:</strong>
          <p>{formatDateToBrl || 'Não informado'}</p>
        </Box>
        <Box className="ContainerDescription">
          <strong>Status:</strong>
          <p>
            <LabDsBadge
              label={data?.data?.StatusReuniaoLead__c || 'Não informado'}
            />
          </p>
        </Box>
      </S.DescriptionHeader>
      <div>
        <AccordionDS
          titleAccordion="Sobre a empresa"
          contentAccordion={() => contentAccordion(data?.data, dataForm?.data)}
        />
      </div>
    </S.ContentBox>
  );
}
