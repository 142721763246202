import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0.5rem 0;
  margin-top: 1.5rem;

  border: 1px dashed var(--secondary-light);

  /* background-color: var(--secondary-light); */
  border-radius: 0.5rem;

  min-width: 22.5rem;

  h2 {
    font-size: 1rem;
  }

  svg {
    color: var(--grayscale-60);
    margin: 1rem 0;
  }

  p {
    text-align: center;
    font-size: 0.75rem;
  }

  p:last-child {
    font-size: 1rem;
  }
`;

export const FileList = styled.ul`
  margin-top: 1rem;
  list-style: none;
`;
