import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';

import { useCallback } from 'react';
import { Modal, Button } from '../../../../../LegacyV4Components';
import * as S from './styles';
import { useToast } from '../../../../../../hooks/toast';
import { deleteCustomerUser } from '../../../../../../services/requests/User/deleteUser';

interface ModalProps {
  stakeholder: Stakeholder;

  isOpen: boolean;
  closeModal: () => void;
  getStakeholders: () => void;
}

export function DeleteStakeholderModal({
  stakeholder,

  isOpen,
  closeModal,

  getStakeholders,
}: ModalProps) {
  const { addToast } = useToast();

  const handleDeleteStakeholder = useCallback(
    async (stakeholderId: string) => {
      if (stakeholder?.role === 'Stakeholder principal') {
        addToast({
          type: 'error',
          title: 'Erro ao remover!',
          description: 'Não é permitido remover o Stakeholder principal',
        });
        closeModal();
        return;
      }

      const response = await deleteCustomerUser(stakeholderId);

      if (response.status !== 200) {
        addToast({
          type: 'error',
          title: 'Erro ao remover!',
          description: 'Erro ao remover stakeholder',
        });
      }

      getStakeholders();

      addToast({
        title: 'Sucesso',
        description: 'Stakeholder excluído com sucesso!',
        type: 'success',
      });

      closeModal();
    },
    [getStakeholders, addToast, stakeholder.role, closeModal],
  );

  return (
    <Modal show={isOpen}>
      <S.ModalBody>
        <AiOutlineCloseCircle size={66} color="#E92E30" />
        <MdClose
          size={16}
          className="close-button"
          onClick={() => closeModal()}
        />

        <h1
          style={{
            marginTop: 16,
            fontSize: 24,
            textAlign: 'center',
            width: 500,
          }}
        >
          Você realmente deseja remover esse stakeholder do projeto?
        </h1>

        <p
          style={{
            marginTop: 16,
            fontSize: 16,
            textAlign: 'center',
            width: 500,
          }}
        >
          Ao confirmar essa ação, o stakeholder {stakeholder.name} será removido
          do projeto e caso tenha acesso ao mkt.lab, perderá.
        </p>
      </S.ModalBody>
      <S.ModalFooter>
        <div style={{ marginRight: 10 }}>
          <Button
            size="default"
            color="red"
            variant="secondary"
            onClick={() => closeModal()}
          >
            Cancelar
          </Button>
        </div>
        <Button
          onClick={() => handleDeleteStakeholder(stakeholder._id as string)}
          size="default"
          color="red"
          variant="primary"
        >
          Confirmar
        </Button>
      </S.ModalFooter>
    </Modal>
  );
}
