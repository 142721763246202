import { customerApi as api } from '../../api';

export async function deleteLastUpdate(
  lastUpdateId: string,
): Promise<number | false> {
  try {
    const response = await api.delete(`/lastUpdates/${lastUpdateId}`);

    const lastUpdates = response.status;

    return lastUpdates;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os dados dos clientes, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
