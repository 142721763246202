import { financeApi } from '../../api';

export interface ICoupons {
  name: string;
  initialValidity: string;
  endValidity: string;
  discountType: string;
  value: number;
}

export async function editCoupons(
  data: ICoupons,
  id: string,
): Promise<ICoupons | false> {
  try {
    const response = await financeApi.patch<ICoupons>(`/coupons/${id}`, data);
    const coupons = response.data;
    return coupons;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao atualizar o cupom, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
