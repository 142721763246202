import styled from 'styled-components';

export const Container = styled.div<{ show: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 900;
  justify-content: center;
  align-items: center;

  display: ${({ show }) => (show ? 'flex' : 'none')};
`;

export const ModalContent = styled.div<{ size: 'sm' | 'md' | 'lg' }>`
  max-width: ${({ size }) => {
    switch (size) {
      case 'sm':
        return '500px';
      case 'md':
        return '700px';
      case 'lg':
        return '80vw';
      default:
        return '700px';
    }
  }};

  position: absolute;
  right: 0;
  transform: translate(0, 0);
  height: 100%;
  overflow: auto;
  background: var(--white);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  main {
    position: relative;
    padding: 1rem;
    width: 100%;
  }
`;

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  padding: 2rem;

  border-bottom-width: 1px;
  border-bottom-color: var(--primary-dark-1);
  border-bottom-style: solid;

  h3 {
    font-family: 'Inter', sans-serif !important;
    font-size: 1.2rem;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  svg {
    cursor: pointer;
    color: var(--primary-dark-1);
    height: 1.3rem;
    width: 1.3rem;
    margin-left: 1rem;
  }
`;

export const ModalFooter = styled.header`
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  padding: 2rem;

  width: 100%;

  border-top-width: 1px;
  border-top-color: var(--primary-dark-1);
  border-top-style: solid;

  position: absolute;
  bottom: 0;
  z-index: 1;
`;
