import { Unit } from '../../../hooks/types';
import { TeamStructureInterface } from '../../../pages/NewPriceCalculatorEdit/interface';
import { kubernetesApi as api } from '../../api';

export async function getUnitsByWorkload(
  teamStructure: TeamStructureInterface[],
  workload: number,
): Promise<Unit[] | false> {
  try {
    const response: { data: Unit[] } = await api.post(
      `/calculator/new-price/get-units-by-workload`,
      {
        teamStructure,
        workload,
      },
    );

    const user = response.data;

    return user;
  } catch (err) {
    console.error(`Ocorreu algum problema ao atualizar este usuário 😕`, err);
    return false;
  }
}
