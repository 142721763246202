import styled, { css } from 'styled-components';

export const DashboardCard = styled.div<{
  isSelected?: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: center;

  flex-direction: column;
  user-select: none;
  cursor: pointer;
  transition: 0.5s;

  width: 100%;
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid var(--grayscale-10);

  > h6 {
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 95%;
  }

  > p {
    font-size: 12px;
    margin-top: 0.5rem;
  }

  section {
    > h5 {
      font-size: 22px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
  }

  .bookMarker {
    color: var(--grayscale-20);
    min-height: 24px;
    min-width: 24px;
    transition: 0.5s;

    :hover {
      opacity: 0.5;
    }
  }

  .dot {
    position: absolute;
    right: 0.5rem;
    height: 0rem;
    top: 4rem;
    bottom: 1.25rem;
    z-index: 20;
  }

  /* svg:last-child { */
  /*   bottom: 0; */
  /*   top: auto; */
  /* } */

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: var(--grayscale-2);
    `}
`;
