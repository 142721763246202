import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap");

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;

    ::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.4rem;
    }

    ::-webkit-scrollbar-track {
      background: #ebebeb;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #c4c4c4;
      border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #353333;
    }
  }

  [data-reach-dialog-overlay] {
    z-index: 15;
    margin-bottom: 0;
  }

  @media (max-height: 700px) {
    [data-reach-dialog-content] {
      margin-bottom: 0;
    }
  }

  html {
    font-family: "Nunito Sans", serif;
  }

  body {
    background: #f4f4f8;
    color: #333333;
    -webkit-font-smoothing: antialiased;
  }

  body, input {
    font-family: "Nunito Sans", serif;
    font-size: 16px;
  }

  button {
    font-family: "Inter", serif;
    cursor: pointer;
    font-size: 14px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
    font-family: "Inter", sans-serif;
  }

  :root {
    // Vars
    --primary-dark-1: #D9364A;
    --primary-dark-2: #C01C30;
    --primary-dark-3: #A61123;

    --primary-main: #E92E30;
    --primary-dark: #D9364A;
    --primary-light: #FF5E5F;

    --secondary-dark: #1A1919;
    --secondary-main: #333333;
    --secondary-light: #7F7F7F;

    --tertiary-dark: #BBBBBB;
    --tertiary-main: #F6F6F6;
    --tertiary-light: #FFFFFF;

    --success-1: #0DDEB1;
    --success-2: #00C49A;
    --success-3: #00AB86;
    --warning: #FABC2A;
    --error: #E03131;
    --notice: #2ABCFA;

    --chart-green-1: #00AB86;
    --chart-green-2: #147C66;
    --chart-yellow-1: #F77D00;

    --white: #ffffff;
    --black: #000000;

    --grayscale-100: #50507c;
    --grayscale-90: #585889;
    --grayscale-80: #5f5f95;
    --grayscale-70: #7676a7;
    --grayscale-60: #8383af;
    --grayscale-50: #8f8fb7;
    --grayscale-40: #9b9bbf;
    --grayscale-30: #a8a8c7;
    --grayscale-20: #b4b4cf;
    --grayscale-25: #231F20;
    --grayscale-10: #c1c1d7;
    --grayscale-8: #cdcddf;
    --grayscale-6: #dadae7;
    --grayscale-4: #e6e6ef;
    --grayscale-2: #f4f4f8;

    --elevation-1: 0px 1px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px 1px rgba(0, 0, 0, 0.06);
    --elevation-2: 0px 1px 2px rgba(0, 0, 0, 0.12), 0px 2px 6px 2px rgba(0, 0, 0, 0.06);
    --elevation-3: 0px 4px 8px 3px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.12);
    --elevation-4: 0px 6px 10px 4px rgba(0, 0, 0, 0.06), 0px 2px 3px rgba(0, 0, 0, 0.12);
    --elevation-5: 0px 10px 20px rgba(0, 0, 0, 0.12);
  }
`;
