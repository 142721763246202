import { ReactNode } from 'react';
import { MdRemoveCircleOutline } from 'react-icons/md';
import { Input } from '../../../../LegacyV4Components';

import {
  CustomerIntegrationsContextData,
  CustomerIntegrationsProvider,
} from '../../../../../hooks/customerIntegrations';

import { TieAccountsModal } from '../TieAccountsModal';

import { googleAnalytics as options } from '../platforms';

import * as Edit from '../components/IntegrationEditRow/styles';
import * as Summary from '../components/IntegrationSummaryRow/styles';

interface GoogleAnalyticsModalProps {
  show: boolean;
  onClose: () => void;
}

const googleAnalyticsRegex = /[0-9]{9}/;

function testRegex(integrationId: string): boolean {
  return googleAnalyticsRegex.test(integrationId);
}

export function ConfigGoogleAnalytics4Modal({
  show,
  onClose,
}: GoogleAnalyticsModalProps) {
  const tableColumns = [
    {
      accessor: 'customer',
      label: 'Cliente',
      sizing: 1,
      sortable: true,
    },
    {
      accessor: 'integrationId',
      label: 'ID da Propriedade',
      sizing: 1,
      sortable: true,
    },
    {
      accessor: 'status',
      label: 'Status',
      sizing: 0.5,
      sortable: false,
    },
  ];

  function isConnected(integration: AdsAccounts): boolean {
    return (
      !!integration.projectId &&
      testRegex(integration.integrationId) &&
      !integration.isDeleted
    );
  }

  function additionalValidation(integration: AdsAccounts): boolean {
    return isConnected(integration);
  }

  function renderEditRow(
    integration: AdsAccounts,
    error: boolean,
    context: CustomerIntegrationsContextData,
  ): ReactNode {
    const { setIntegrationId, deleteIntegration } = context;
    const idError = error && !testRegex(integration.integrationId);
    return (
      <Edit.Row title={integration.integrationId}>
        <div>
          <Input
            label=""
            value={integration.integrationId}
            name="integrationId"
            onChange={({ target: { value } }) =>
              setIntegrationId(integration, value)
            }
            className={idError ? 'error' : ''}
            placeholder="00000000"
            mask="999999999"
            style={{ width: '10rem' }}
          />
          {idError && <Edit.Error>ID inválido</Edit.Error>}
        </div>
        <MdRemoveCircleOutline
          size={22}
          onClick={() => deleteIntegration(integration)}
        />
      </Edit.Row>
    );
  }

  function renderSummaryRow(integration: AdsAccounts): ReactNode {
    return (
      <td title={integration.integrationId}>
        <Summary.IntegrationParagraph>
          {integration.integrationId}
        </Summary.IntegrationParagraph>
      </td>
    );
  }

  return (
    <CustomerIntegrationsProvider type={options.type} show={show}>
      <TieAccountsModal
        options={options}
        show={show}
        onClose={onClose}
        tableColumns={tableColumns}
        renderEditRow={renderEditRow}
        renderSummaryRow={renderSummaryRow}
        isConnected={isConnected}
        additionalValidation={additionalValidation}
      />
    </CustomerIntegrationsProvider>
  );
}
