import React, { useCallback, useEffect, useState } from 'react';
import { LabDsBreadcrumb } from 'v4web-components-react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Section } from '../../components/LegacyV4Components';
import CustomerStakeholders from '../../components/AtomicDesign/templates/CustomerStakeholders';

import { customerApi } from '../../services/api';
import { useAuth } from '../../hooks/auth';

export function CustomerStakeholdersView() {
  const { id } = useParams<{ id: string }>();

  const [customer, setCustomer] = useState<any>();
  const navigate = useNavigate();
  const { user } = useAuth();

  const isHeadquarterUser =
    user.unitId === `${process.env.REACT_APP_HEADQUARTER_ID}`;

  const pageTitle = isHeadquarterUser ? 'Clientes V4' : 'Meus clientes';
  const breadcrumbsItems = isHeadquarterUser
    ? [
        {
          label: pageTitle,
          link: '/clientes',
          key: 'customers',
          event: () => navigate('/clientes'),
        },
        {
          label: `${customer?.name ?? ''}`,
          link: `/cliente/${id}`,
          key: 'customer',
          event: () => navigate(`/cliente/${id}`),
        },
        {
          label: `Stakeholders do projeto`,
          link: '',
          key: 'stakeholders',
          event: () => null,
        },
      ]
    : [
        {
          label: pageTitle,
          link: '/clientes',
          key: 'customers',
          event: () => navigate('/clientes'),
        },
        {
          label: `${customer?.name ?? ''}`,
          link: `/cliente/${id}`,
          key: 'customer',
          event: () => navigate(`/cliente/${id}`),
        },
        {
          label: `Stakeholders do projeto`,
          link: '',
          key: 'stakeholders',
          event: () => null,
        },
      ];

  const getCustomers = useCallback(async () => {
    await customerApi({
      url: `/customer/${id}`,
      method: 'GET',
    })
      .then((res) => setCustomer(res.data))
      .catch(() => navigate('/clientes'));
  }, [id, navigate]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  return (
    <Container>
      <LabDsBreadcrumb breadcrumbs={breadcrumbsItems} />
      <Section>
        <CustomerStakeholders customer={customer} />
      </Section>
    </Container>
  );
}
