import { FiMinus, FiPlus } from 'react-icons/fi';
import * as S from './styles';

interface InputProps {
  value: number;
  min: number;
  onValueChange: (values: number) => void;
}

export function NumberInput({ value, min, onValueChange }: InputProps) {
  function handleInput(newValue: number) {
    onValueChange(newValue);
  }

  return (
    <S.ContainerSelectNumber className="ContainerSelectNumber">
      <S.DecrescentNumber
        onClick={() => {
          handleInput(value - 1);
        }}
        disabled={value <= min}
      >
        <FiMinus size="0.5rem" />
      </S.DecrescentNumber>
      <input
        type="number"
        value={value}
        onChange={(e) => {
          if (parseInt(e.target.value, 10) >= min)
            if (e.target.value === '') handleInput(0);
          handleInput(parseInt(e.target.value, 10));
        }}
      />
      <S.CrescentNumber
        onClick={() => {
          handleInput(value + 1);
        }}
      >
        <FiPlus size="0.5rem" />
      </S.CrescentNumber>
    </S.ContainerSelectNumber>
  );
}
