import React, { SetStateAction } from 'react';
import { User } from '../../hooks/types';

export enum Tabs {
  active = 'Ativas',
  inactive = 'Histórico de contas',
}
export interface ModalPropsUser {
  disableUnitProps: {
    name: string;
    id: string;
    email: string;
    unitId: string;
  };
  selectedEmail?: string;
  user: User;
  getUser: React.Dispatch<SetStateAction<User | undefined>>;
}

export interface tableDataSimpleProps
  extends Omit<User, 'mandate' | 'seniority'> {
  action: React.ReactNode;
  avatar: React.ReactNode;
  mandate?: string;
  seniority?: string;
}
export interface tableDataSimplePropsUnit extends tableDataSimpleProps {
  ekyte: React.ReactNode;
}
