import { useAuth } from '../hooks/auth';

import LoggedInRoutes from './loggedIn';
import LoggedInWithoutUnit from './loggedInWithoutUnit';
import LoggedOutRoutes from './loggedOut';

export default function AppRoutes() {
  const { user } = useAuth();

  const signed = !!user._id;

  const hasUnitId = user?.unitId ? <LoggedInRoutes /> : <LoggedInWithoutUnit />;

  return signed ? hasUnitId : <LoggedOutRoutes />;
}
