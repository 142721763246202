import { PaymentValidate } from '../../../types/paymentValidate';
import { financeApi } from '../../api';

export async function paymentValidate(
  customerId: string,
): Promise<PaymentValidate> {
  try {
    const response = await financeApi.post<PaymentValidate>(
      `payments/prePaymentValidation/${customerId}`,
    );
    return response.data;
  } catch (err) {
    return {} as PaymentValidate;
  }
}
