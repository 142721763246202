import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiChevronRight, FiX } from 'react-icons/fi';
import { LabDsButton, LabDsSearchBar } from 'v4web-components-react';

import { MdOpenInNew, MdHelpOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Paragraph, Headline } from '../../../../LegacyV4Components';
import { useAuth } from '../../../../../hooks/auth';
import { useToast } from '../../../../../hooks/toast';

import { DataGrid } from '../../../atoms/DataGrid';

import SelectQuery, { SelectValue } from '../../../atoms/SelectQuery';
import { IntegrationStatus } from '../components/IntegrationStatus';

import { getFacebookAdsIntegrations } from '../../../../../services/requests/Ads/getFacebookAdsIntegrations';
import { handleFetchInitialValue } from '../../../../../services/requests/CustomersNew/handleFetchInitialValue';
import { handleFetchOptions } from '../../../../../services/requests/CustomersNew/handleFetchOptions';

import { facebookAds as options } from '../platforms';

import * as TieAccountStyles from '../TieAccountsModal/styles';
import * as S from '../styles';
import { createAccountIntegration } from '../../../../../services/requests/Ads/createAccountIntegration';
import { updateAccountIntegration } from '../../../../../services/requests/Ads/updateAccountIntegration';

interface FacebookAdsModalProps {
  show: boolean;
  onClose: () => void;
}

interface AccountProps {
  _id?: string;
  integrationId: string;
  customerId: string;
  projectId: string;
  customerNewId: string;
  authToken: string;
  type: string;
  name: string;
  value?: SelectValue<IProject>;
  status?: number;
}

export function ConfigFacebookAdsModal({
  show,
  onClose,
}: FacebookAdsModalProps) {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [timestamp, setTimestamp] = useState(new Date().getTime());

  const [filterQuery, setFilterQuery] = useState('');

  const [disableInteractions, setDisableInteractions] = useState(false);

  const [accounts, setAccounts] = useState<AccountProps[]>([]);

  const [phase, setPhase] = useState(1);

  const isEditMode = phase === 2;

  const connectedAccounts = accounts.filter(
    (account) => !!account.projectId,
  ).length;

  const tableColumns = [
    {
      accessor: 'facebook-id',
      label: 'ID da conta',
      sizing: 25,
      sizingUnit: '%',
      sortable: false,
    },
    {
      accessor: 'facebook-name',
      label: 'Nome da conta',
      sizing: 25,
      sizingUnit: '%',
      sortable: false,
    },
    {
      accessor: 'customer',
      label: 'Cliente',
      sizing: isEditMode ? 50 : 37.5,
      sizingUnit: '%',
      sortable: false,
    },
  ];

  if (!isEditMode) {
    tableColumns.push({
      accessor: 'status',
      label: 'Status',
      sizing: 12.5,
      sizingUnit: '%',
      sortable: false,
    });
  }

  useEffect(() => {
    async function loadAccounts() {
      const response = await getFacebookAdsIntegrations();
      setTimestamp(new Date().getTime());

      if (response) {
        const auxAccounts: AccountProps[] = [];

        const { pendingAccounts, registeredAccounts } = response;

        auxAccounts.push(
          ...pendingAccounts?.map((account) => {
            return {
              integrationId: account.id,
              name: account.name,
              authToken: account.authToken,
              projectId: '',
              customerNewId: '',
              customerId: '',
              type: 'FACEBOOK_ADS',
              status: 0,
            };
          }),
        );

        auxAccounts.push(
          ...registeredAccounts?.map((account) => {
            return {
              _id: account._id,
              name: account.name,
              authToken: account.authToken,
              projectId: account.projectId,
              customerNewId: account.customerNewId,
              customerId: account.customerId,
              integrationId: account.integrationId,
              type: 'FACEBOOK_ADS',
              status: account.status || 0,
            };
          }),
        );

        setAccounts(auxAccounts);
      }
    }

    if (show) {
      setAccounts([]);
      loadAccounts();
    }
  }, [show, user.unitId]);

  const handleFetchCustomersOptions = useCallback(
    (query: string, page: number) => {
      return handleFetchOptions(query, page, user);
    },
    [user],
  );

  const handleSelectChange = useCallback(
    (account: AccountProps, value?: SelectValue<IProject>) => {
      const list = [...accounts];
      const index = list.findIndex(
        (i) => i.integrationId === account.integrationId,
      );
      list[index].customerId = value?.value.customerId ?? '';
      list[index].projectId = value?.value._id ?? '';
      list[index].customerNewId = value?.value.customerId ?? '';
      list[index].value = value;

      setAccounts(list);
    },
    [accounts],
  );

  const tableData = useMemo(() => {
    return accounts
      .filter((account) => {
        if (filterQuery) {
          return [account.integrationId, account.name].some((value) =>
            value.toLocaleLowerCase().includes(filterQuery.toLocaleLowerCase()),
          );
        }
        return true;
      })
      .map((account, index: number) => {
        const selectedCustomer = isEditMode ? '' : account.value?.label;
        const showErrorClass = !isEditMode && !selectedCustomer;

        const integration: AdsAccounts = {
          ...account,
          _id: account._id || '',
        };

        const content = (
          <>
            <div title={account.integrationId} className="text-wrapper">
              <Paragraph className={showErrorClass ? 'color-error' : ''}>
                {account.integrationId}
              </Paragraph>
            </div>
            <div title={account.name} className="text-wrapper">
              <Paragraph className={showErrorClass ? 'color-error' : ''}>
                {account.name}
              </Paragraph>
            </div>
            {isEditMode ? (
              <SelectQuery
                id={`${index}-${timestamp}`}
                fetchInitialValue={
                  account.projectId
                    ? () => handleFetchInitialValue(account.projectId)
                    : undefined
                }
                value={account.value}
                fetchOptions={handleFetchCustomersOptions}
                setValue={(value) => handleSelectChange(account, value)}
                placeholder="Selecione o cliente pertencente a esse ID"
              />
            ) : (
              <S.HiddenOverflowDiv
                title={selectedCustomer || 'Nenhum cliente vinculado'}
              >
                <Paragraph className={showErrorClass ? 'color-error' : ''}>
                  {selectedCustomer || 'Nenhum cliente vinculado'}
                </Paragraph>
              </S.HiddenOverflowDiv>
            )}
            {isEditMode ? null : (
              <IntegrationStatus integration={integration} />
            )}
          </>
        );

        return {
          id: account.integrationId,
          content,
        };
      });
  }, [
    accounts,
    filterQuery,
    handleSelectChange,
    isEditMode,
    timestamp,
    handleFetchCustomersOptions,
  ]);

  const handleClose = () => {
    if (!disableInteractions) {
      setPhase(1);
      setFilterQuery('');
      onClose();
    }
  };

  const handleUpdateLoginClick = () => {
    window.location.href = `https://www.facebook.com/${process.env.REACT_APP_FB_GRAPH_VERSION}/dialog/oauth?client_id=${process.env.REACT_APP_FB_CLIENT_ID}&scope=public_profile,email,ads_read,ads_management&state=mktlab&redirect_uri=${process.env.REACT_APP_FB_REDIRECT_URI}&auth_type=rerequest`;
  };

  const handleSave = async () => {
    setDisableInteractions(true);
    try {
      const newAccounts = accounts
        .filter((account) => !account._id && !!account.projectId)
        .map((account) => {
          return {
            integrationId: account.integrationId,
            projectId: account.projectId,
            customerId: account.customerId,
            customerNewId: account.customerNewId,
            authToken: account.authToken,
            name: account.name,
            type: 'FACEBOOK_ADS',
            status: 0,
            unitId: user.unitId,
            userId: user._id,
          };
        });

      const updateAccounts = accounts
        .filter((account) => !!account._id && !!account.projectId)
        .map((account) => {
          return {
            _id: account._id,
            integrationId: account.integrationId,
            projectId: account.projectId,
            customerId: account.customerId,
            customerNewId: account.customerNewId,
            name: account.name,
            type: 'FACEBOOK_ADS',
            authToken: account.authToken,
            status: account.status || 0,
          };
        });

      await createAccountIntegration(newAccounts);

      await updateAccountIntegration(updateAccounts);

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'As informações foram salvas!',
      });
    } catch {
      addToast({
        type: 'error',
        title: 'Atenção',
        description:
          'Houve um erro ao salvar as informações, por favor, tente novamente!',
      });
    }
    setDisableInteractions(false);
    setPhase(1);
    onClose();
  };

  function handleNext(): void {
    switch (phase) {
      case 2:
        setPhase(3);
        break;
      case 3:
        handleSave();
        break;
      case 1:
      default:
        setPhase(phase + 1);
        break;
    }
  }

  function getButtonText(): string {
    switch (phase) {
      case 2:
        return 'Seguir para o resumo';
      case 3:
        return 'Confirmar';
      case 1:
      default:
        return 'Próximo';
    }
  }

  const { name, description, extraction, requirements, docs } = options;

  return (
    <TieAccountStyles.ModalOverlay
      className={show ? 'show' : ''}
      onClick={handleClose}
    >
      <TieAccountStyles.ModalWrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <TieAccountStyles.ModalHeader>
          <Headline variant="h4">{name}</Headline>
          <FiX size={16} className="close-button" onClick={handleClose} />
        </TieAccountStyles.ModalHeader>
        <TieAccountStyles.ProgressBar porc={`${(phase / 3) * 100}%`} />
        <TieAccountStyles.TopWrapper>
          <TieAccountStyles.Phase>
            <TieAccountStyles.PhaseItem active={phase === 1}>
              1. Introdução
            </TieAccountStyles.PhaseItem>
            <FiChevronRight size={24} />
            <TieAccountStyles.PhaseItem active={phase === 2}>
              2. Vinculação
            </TieAccountStyles.PhaseItem>
            <FiChevronRight size={24} />
            <TieAccountStyles.PhaseItem active={phase === 3}>
              3. Resumo
            </TieAccountStyles.PhaseItem>
          </TieAccountStyles.Phase>
          {phase > 1 && (
            <TieAccountStyles.Count
              className={connectedAccounts > 0 ? 'color-green' : 'color-red'}
            >
              {connectedAccounts}
              {connectedAccounts === 1
                ? ' conta vinculada'
                : ' contas vinculadas'}
            </TieAccountStyles.Count>
          )}
        </TieAccountStyles.TopWrapper>
        <TieAccountStyles.ModalBody>
          {phase === 1 ? (
            <>
              <TieAccountStyles.Description>
                <h4>Descrição</h4>
                {description.map((text) => (
                  <p>{text}</p>
                ))}
              </TieAccountStyles.Description>

              <TieAccountStyles.Info>
                {extraction && extraction.length && (
                  <>
                    <h4>Possibilidade de extração de dados</h4>
                    {extraction.map((text) => (
                      <li>{text}</li>
                    ))}
                  </>
                )}
                {requirements && requirements.length && (
                  <>
                    <h4>Pré-requisitos</h4>
                    {requirements.map((text) => (
                      <li>{text}</li>
                    ))}
                  </>
                )}
              </TieAccountStyles.Info>
            </>
          ) : (
            <S.ModalBody>
              <S.ControllersWrapper>
                <S.Filters>
                  <LabDsSearchBar
                    label="Pesquise por id, usuário ou cliente..."
                    value={filterQuery}
                    onChangeInput={({ detail }) => {
                      setFilterQuery(detail);
                    }}
                  />
                </S.Filters>
                {isEditMode && (
                  <LabDsButton
                    label="Adicionar conta"
                    stepIcon="sync"
                    onHandleButton={() => handleUpdateLoginClick()}
                  />
                )}
              </S.ControllersWrapper>
              <DataGrid
                columns={tableColumns}
                data={tableData}
                showModalUserInvate={false}
                setShowModalUserInvate={() => null}
                notClickable
              />
            </S.ModalBody>
          )}
        </TieAccountStyles.ModalBody>
        <TieAccountStyles.ModalFooter>
          <TieAccountStyles.ManualWrapper>
            <Link to={docs} target="_blank">
              <Paragraph>{`Documentação do ${name}`}</Paragraph>
              <MdOpenInNew size={16} />
            </Link>
            <Link
              to="https://app.pipefy.com/public/form/zsc8VbvF"
              target="_blank"
            >
              <Paragraph>Precisa de ajuda</Paragraph>
              <MdHelpOutline size={16} />
            </Link>
          </TieAccountStyles.ManualWrapper>
          <LabDsButton
            label={phase === 1 ? 'Cancelar' : 'Voltar'}
            variant="outlined"
            onHandleButton={() => {
              phase === 1 ? onClose() : setPhase(phase - 1);
            }}
          />
          <LabDsButton
            label={getButtonText()}
            onHandleButton={() => {
              handleNext();
            }}
          />
        </TieAccountStyles.ModalFooter>
      </TieAccountStyles.ModalWrapper>
    </TieAccountStyles.ModalOverlay>
  );
}
