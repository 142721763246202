import { newAds } from '../../api';

export async function updateAccountIntegration(
  accountIntegration: Partial<AdsAccounts>[],
): Promise<boolean> {
  const { data } = await newAds.put<boolean>(
    `/customerIntegration/updateMany`,
    accountIntegration,
  );
  const adsAccounts = data;
  return adsAccounts;
}
