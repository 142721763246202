import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 3.5rem);
  max-height: 100vh;
  position: absolute;
  overflow-y: auto;
  top: 4rem;
  left: 3.5rem;
  bottom: 0;
  transition: 0.5s;

  @font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    font-weight: 100 700;
    src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v39/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.woff2)
      format('woff2')
      url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v39/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.ttf)
      format('truetype');
  }

  &.full-width {
    width: 100vw;
    left: 0;
  }

  a {
    text-decoration: none;
    color: #333333;
  }

  @media (max-width: 500px) {
    left: 0;
    width: 100vw;
  }
`;
