import { User } from '../../../hooks/types';
import { leadBrokerApi } from '../../api';

export async function verifyLbUser(id: string): Promise<User | false> {
  try {
    const response = await leadBrokerApi.get(`/permissions/user/${id}`);
    const user: any = response.data;
    return user;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar as informações do usuário 😕`,
      err,
    );
    return false;
  }
}
