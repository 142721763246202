import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 704px;
  max-width: 95vw;

  height: 100vh;
  max-height: 88vh;

  border-radius: 0.5rem;
  background-color: var(--white);
`;

export const RadioWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const FilterRadio = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  gap: 1rem;
  padding: 0.75rem 0;

  cursor: pointer;
  user-select: none;

  color: var(--black);
  font-weight: bold;

  border-radius: 4px;

  &.checked {
    border-color: var(--success-2);

    & .radio {
      &::after {
        background-color: var(--success-2);
      }
    }
  }

  & .radio {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    width: 1rem;
    height: 1rem;

    border-radius: 50%;

    border: 1px solid var(--grayscale-60);

    &::after {
      display: block;
      content: '';
      position: absolute;

      width: 0.5rem;
      height: 0.5rem;

      background-color: transparent;

      border-radius: 50%;
    }
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  flex-grow: 0;

  padding: 1.75rem 1.5rem;

  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.12);

  & > button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 1rem;
    height: 1rem;

    background: transparent;
    border: none;
    color: #83834f;

    & > svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding: 1.5rem 2.5rem;

  overflow: auto;

  & > small {
    margin-bottom: 1rem;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 1rem;

  box-shadow: 0 -4px 8px 3px rgba(0, 0, 0, 0.06), 0 -1px 3px rgba(0, 0, 0, 0.12);

  padding: 1.5rem 2.5rem;
`;
