import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const Input = styled.input`
  appearance: none;
  background-color: #fff;
  margin: 0 10px 0 0;
  font: inherit;
  color: ${({ checked }) => (checked ? '#00c49a' : '#8383af')};
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid ${({ checked }) => (checked ? '#00c49a' : '#8383af')};
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #00c49a;
    background-color: CanvasText;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked::before {
    transform: scale(1);
  }
`;
