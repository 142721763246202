import styled from 'styled-components';

interface TokenInputProps {
  borderColor?: string;
}

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.5rem;

  & table {
    flex-grow: 1;
  }
`;

export const ControllersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 10;
  min-height: 2.82rem;
`;

export const Filters = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

export const StatusLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  height: 1.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  &.status-success {
    color: var(--black);
    background-color: var(--success-2);
  }
  &.status-error {
    color: var(--white);
    background-color: var(--error);
  }
`;

export const TokenInput = styled.textarea<TokenInputProps>`
  resize: none;

  padding: 0.75rem 1rem;
  border: 1px solid ${({ borderColor }) => borderColor || '#E6E6EF'};
  font-size: 16px;
  border-radius: 3px;

  font-family: 'Nunito Sans';
  font-size: small;
`;

export const HiddenOverflowDiv = styled.div`
  overflow-x: hidden;
`;
