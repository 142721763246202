import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 50px 0 50px;
  border-radius: 0.5rem;
  background: white;
`;

export const ContainerSwitch = styled.div`
  display: flex;
  align-items: end;
  width: 215px;
  justify-content: flex-end;
  margin: 0.5rem 0;
`;

export const Status = styled.p`
  font-size: 1rem;
  font-weight: 700;
  padding-right: 20px;
`;

export const Title = styled.h4`
  font-size: 1.75rem;
  font-weight: 700;
`;

export const Subtitle = styled.h4`
  font-size: 1.25rem;
  font-weight: 700;
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 2rem;
  border-bottom: 1px solid #b4b4cf;
`;

export const ContainerStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const TextInfo = styled.p<{ disabled: boolean }>`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ disabled }) => (disabled ? '#666668' : '#333333')};
  margin-top: 1rem;
  padding: 24px 0 24px 0;
`;
