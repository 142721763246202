import styled from 'styled-components';

export const Avatar = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  object-fit: cover;
  border-radius: 50%;
  width: 2rem !important;
  height: 2rem !important;
  width: auto;
  height: auto;
  margin-right: 0.313rem;
  cursor: pointer;
`;
