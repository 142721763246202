import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { customerApi as api } from '../../api';
import { Customer } from '../../../types/customer';

export async function getCustomerById(id: string): Promise<Customer | false> {
  try {
    const response = await api.get<Customer>(`/customer/${id}`);

    const customer: Customer = response.data;

    return customer;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os dados dos clientes, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}

export function useQueryGetCustomerById(
  id: string | undefined,
): UseQueryResult<{ data: Customer }, { data: Customer }> {
  return useQuery<{ data: Customer }, { data: Customer }>(
    ['customerById', id],
    () => api.get<Customer>(`/customer/${id}`),
    {},
  );
}
