import { useState } from 'react';
import {
  LabDsButton,
  LabDsIconNotSelectable,
  LabDsTextField,
} from 'v4web-components-react';

import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import { createOportunity } from '../../../../services/requests/Calculator/oportunities/createOportunity';
import { useAuth } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';

interface createLeadModalInterface {
  closeModal: (state: boolean) => void;
}

export function CreateLeadModal({ closeModal }: createLeadModalInterface) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const [leadName, setLeadName] = useState<string>('');

  async function create() {
    setIsLoading(true);
    const response = await createOportunity({
      projectName: leadName,
      responsibleId: user._id,
      status: 'in_negotiation',
    });
    setIsLoading(false);
    if (!response) {
      addToast({
        type: 'error',
        title: 'Erro na criação de oportunidade',
        description: 'Verifique se todos os dados foram preenchidos.',
      });
      return;
    }
    navigate(`/newPriceCalculatorEdit/${leadName}`);
  }

  return (
    <S.Modal>
      <S.Content>
        <LabDsIconNotSelectable
          icon="close"
          onClick={() => {
            closeModal(false);
          }}
        />
        <h5>Criar nova oportunidade</h5>
        <p>
          Para prosseguir é necessário idenficar o lead que terá a negociação
          iniciada. <br /> Digite o ID ou o CNPJ no campo abaixo.
        </p>
        <LabDsTextField
          label="Digite o ID do lead"
          titleInput="Identificação do lead"
          value={leadName}
          onChangeInput={({ detail }) => {
            setLeadName(detail);
          }}
        />
        <S.Actions>
          <LabDsButton
            label="Cancelar"
            variant="danger-outlined"
            onHandleButton={() => {
              closeModal(false);
            }}
          />
          <LabDsButton
            label="Criar nova oportunidade"
            onHandleButton={create}
            loading={isLoading}
          />
        </S.Actions>
      </S.Content>
    </S.Modal>
  );
}
