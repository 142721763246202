/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import { parseCookies } from 'nookies';
import { AiOutlinePlus } from 'react-icons/ai';
import { MdHelpOutline } from 'react-icons/md';
import { FiAlertTriangle, FiX } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Input,
  Button,
  RadioButton,
  InputCurrency,
} from '../../components/LegacyV4Components';
import { useToast } from '../../hooks/toast';

import * as S from './styles';
import { Tooltip } from '../../components/Default/Tooltip';
import {
  getGeolocationIp,
  handlerExtract,
  putBalanceInject,
} from '../../services/creditRequests';
import { TableExtract } from '../../components/Default/TableExtract';
import { Modal } from '../../components/Default/Modal';
import { convertNumberToBRL } from '../../utils/convertNumberToBRL';
import { getUserUnit } from '../../services/requests/User/getUserUnit';
import { GlobalLoading } from '../../components/Default/Loadings/GlobalLoading';

const schema = yup
  .object({
    justification: yup
      .string()
      .required('Campo obrigatorio!')
      .min(3, 'Digite no minímo 3 caracteres'),
    credit_type: yup.string().required('Campo obrigatorio!'),
    amountCents: yup
      .number()
      .positive()
      .integer()
      .required('Campo obrigatorio'),
    expiresAt: yup
      .string()
      .when('credit_type', (credit_type, field) =>
        credit_type === 'BONUS' ? field.required('Campo obrigatório') : field,
      ),
    sourceIp: yup.string(),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

export function ManageUnit() {
  const { unitId } = useParams();
  const { addToast } = useToast();
  const [extract, setExtract] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [unitSocialName, setUnitSocialName] = useState('');
  const [loading, setLoading] = useState(false);

  const cookies = parseCookies(undefined);
  const token = cookies['v4company.token'];

  const minDate = () => {
    const auxData = new Date()
      .toLocaleDateString()
      .replaceAll('/', '-')
      .split('-');
    return `${auxData[2]}-${auxData[1]}-${auxData[0]}`;
  };

  const {
    watch,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmitModal = () => {
    setIsModalOpen(true);
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    if (Number(data.amountCents) > 1) {
      const ip = getGeolocationIp().catch(() => {
        addToast({
          title: 'Falha em adicionar fundos',
          type: 'error',
        });
      });
      const dataIp = await ip;

      if (dataIp) {
        await putBalanceInject({ unitId, data, dataIp })
          .then(() => {
            addToast({
              title: 'Sucesso, fundos adicionados',
              type: 'success',
            });
            setIsModalOpen(false);
            setLoading(false);
          })
          .catch((err) => {
            addToast({
              title: 'Falha em adicionar fundos',
              type: 'error',
              description: JSON.stringify(err?.message),
            });
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    handlerExtract(unitId, setExtract).catch((error) => {
      addToast({
        title: 'Falha em carregar extratos',
        type: 'error',
        description: JSON.stringify(error?.message),
      });
    });
  }, [addToast, unitId]);

  useEffect(() => {
    if (token && unitId && !unitSocialName) {
      getUserUnit(token, unitId).then(({ data }) => {
        setUnitSocialName(data?.socialName);
      });
    }
  }, [unitSocialName, unitId, token]);

  return (
    <S.Container>
      <S.Title>Créditos LeadBroker</S.Title>
      <p>
        Preencha os campos abaixo para liberar a quantidade de créditos que você
        deseja adicionar para a unidade.
      </p>

      <S.Form onSubmit={handleSubmit(onSubmitModal)}>
        {isModalOpen && (
          <Modal modalOpen={isModalOpen}>
            <S.ModalAddsCredits>
              {loading ? (
                <GlobalLoading
                  style={{ bottom: '1.2rem', left: '0.4rem' }}
                  backgroundColor="var(--white)"
                />
              ) : (
                <>
                  <S.ContentModal>
                    <FiAlertTriangle size={44} color="var(--warning)" />

                    <FiX
                      className="closeButton"
                      color="var(--secondary-main)"
                      size={19}
                      onClick={() => setIsModalOpen(!isModalOpen)}
                    />
                    <h4>Tem certeza que deseja realizar esta ação?</h4>
                    <strong>
                      Você está adicionando{' '}
                      <small>
                        {convertNumberToBRL(watch('amountCents'))} créditos
                      </small>{' '}
                      à unidade <small>{unitSocialName}</small>
                    </strong>
                  </S.ContentModal>
                  <S.ButtonsModal>
                    <button
                      type="button"
                      className="buttonCancel"
                      onClick={() => setIsModalOpen(!isModalOpen)}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      className="buttonAddCredits"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Sim, adicionar créditos
                    </button>
                  </S.ButtonsModal>
                </>
              )}
            </S.ModalAddsCredits>
          </Modal>
        )}
        <S.Label> Selecione o tipo de crédito:</S.Label>
        <div style={{ marginBottom: 20 }}>
          <S.Row style={{ marginBottom: 5, marginTop: 10 }}>
            <S.AreaRadioButton className="creditFinancial">
              <Controller
                name="credit_type"
                control={control}
                render={({ field: { onChange, onBlur, name } }) => {
                  return (
                    <RadioButton
                      type="radio"
                      id="financeiro"
                      label="Financeiro"
                      value="FINANCIAL"
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                    />
                  );
                }}
              />
              <Tooltip
                info
                title="Créditos financeiros possibilitam o franqueado sacar esse montante."
                leftContainer
                leftBefore
                bonus
              >
                <MdHelpOutline size={17} color="var(--secondary-main)" />
              </Tooltip>
            </S.AreaRadioButton>
            <S.AreaRadioButton className="creditBonus">
              <Controller
                name="credit_type"
                control={control}
                render={({ field: { onChange, onBlur, name } }) => {
                  return (
                    <RadioButton
                      type="radio"
                      id="bonus"
                      label="Bônus"
                      value="BONUS"
                      onChange={onChange}
                      onBlur={onBlur}
                      name={name}
                    />
                  );
                }}
              />

              <Tooltip
                info
                title="Créditos bônus não tem possibilidade de serem sacados, ou seja, só podem ser utilizados no Lead Broker e possuem prazo de validade."
                leftContainer
                leftBefore
                bonus
              >
                <MdHelpOutline size={17} color="var(--secondary-main)" />
              </Tooltip>
            </S.AreaRadioButton>
          </S.Row>
          <S.Error>{errors.credit_type?.message}</S.Error>
        </div>

        <S.Field>
          <S.Label style={{ marginTop: 0 }}> Motivo dos créditos</S.Label>

          <Controller
            name="justification"
            control={control}
            render={({ field }) => {
              return (
                <S.CreditDescription
                  creditType={watch('credit_type')}
                  placeholder="Escreva aqui o motivo dos créditos"
                  minLength={3}
                  required
                  {...field}
                />
              );
            }}
          />
          <S.Error>{errors.justification?.message}</S.Error>
        </S.Field>

        <S.Row>
          <S.AllContainer>
            <S.InputsContainer creditType={watch('credit_type')}>
              <div>
                <Controller
                  name="amountCents"
                  control={control}
                  render={({ field: { onChange } }) => {
                    return (
                      <InputCurrency
                        required
                        decimalsLimit={2}
                        setCurrency={onChange}
                        style={{ width: '100%', minWidth: '15.625rem' }}
                        label="Quantidade de créditos"
                        placeholder="Ex: R$ 2.000,00"
                      />
                    );
                  }}
                />

                <S.ErrorContainer>
                  <S.Error>{errors.amountCents?.message}</S.Error>
                </S.ErrorContainer>
              </div>

              <div>
                {watch('credit_type') === 'BONUS' && (
                  <>
                    <Controller
                      name="expiresAt"
                      control={control}
                      render={({ field: { onChange, name } }) => {
                        return (
                          <>
                            <Input
                              min={minDate()}
                              disabled={watch('credit_type') === 'FINANCIAL'}
                              type="date"
                              required={
                                watch('credit_type') === 'BONUS' &&
                                name === 'expiresAt'
                              }
                              style={{ minWidth: '15.625rem' }}
                              label="Selecione a data limite"
                              onChange={onChange}
                              name={name}
                            />
                          </>
                        );
                      }}
                    />

                    <S.ErrorContainer>
                      <S.Error>{errors.expiresAt?.message}</S.Error>
                    </S.ErrorContainer>
                  </>
                )}
              </div>
            </S.InputsContainer>

            <Button
              className="buttonAddCredits"
              onClick={() => null}
              color="green"
              variant="primary"
              size="small"
              type="submit"
              leftIcon={<AiOutlinePlus size={30} />}
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Adicionar Créditos
            </Button>
          </S.AllContainer>
        </S.Row>
      </S.Form>

      <S.Title>Extrato</S.Title>
      {extract && (
        <TableExtract
          columns={[
            {
              label: 'Nome',
              accessor: 'name',
              isSorted: false,
              sortable: true,
              sortByOrder: 'asc',
              maxWidth: 100,
            },
            {
              label: 'Email',
              accessor: 'email',
              isSorted: false,
              sortable: true,
              sortByOrder: 'asc',
              maxWidth: 100,
            },
            {
              label: 'Valor',
              accessor: 'value',
              isSorted: false,
              sortable: true,
              sortByOrder: 'asc',
              maxWidth: 100,
            },
            {
              label: 'Data da transação',
              accessor: 'creationDate',
              isSorted: false,
              sortable: true,
              sortByOrder: 'asc',
              maxWidth: 100,
            },
            {
              label: 'Hora da transação',
              accessor: 'creationTime',
              isSorted: false,
              sortable: true,
              sortByOrder: 'asc',
              maxWidth: 100,
            },
            {
              label: 'Tipo de crédito',
              accessor: 'credit_type',
              isSorted: false,
              sortable: true,
              sortByOrder: 'asc',
              maxWidth: 100,
            },
            {
              label: 'Data limite do crédito',
              accessor: 'expiresAt',
              isSorted: false,
              sortable: true,
              sortByOrder: 'asc',
              maxWidth: 100,
            },
            {
              label: '',
              accessor: 'log',
              isSorted: false,
              sortable: true,
              sortByOrder: 'asc',
              maxWidth: 100,
            },
          ]}
          data={extract || []}
        />
      )}
    </S.Container>
  );
}
