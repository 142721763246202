import { RiLineChartLine } from 'react-icons/ri';
import { SectionTitle } from '../../components/LegacyV4Components';
import { UnHappyPath } from '../../components/AtomicDesign/atoms/UnhappyPath';
import { Button } from '../../components/Default/Button';
import { useAuth } from '../../hooks/auth';

import * as S from './styles';

export function HomeWithoutUnit() {
  const { signOut } = useAuth();

  return (
    <S.Container>
      <S.BackgroundImage />
      <S.SectionContent>
        <S.Logo>
          <RiLineChartLine />
        </S.Logo>
        <SectionTitle text="mkt.lab" />
        <UnHappyPath
          assetIndex={6}
          styleContainer={{ marginBottom: '1rem', marginTop: '1.5rem' }}
        />
        <S.SectionDescription>
          Você ainda não possui acesso ao Mkt Lab.
          <br />
          Entre em contato com o responsável da sua unidade e solicite o
          acesso/convite.
        </S.SectionDescription>
        <S.ButtonsContainer>
          <Button small onClick={() => signOut()}>
            Voltar ao Login
          </Button>
        </S.ButtonsContainer>
      </S.SectionContent>
    </S.Container>
  );
}
