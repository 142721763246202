import { Routes, Route } from 'react-router-dom';
import { SignIn } from '../pages/SignIn';
import { ConfirmRegister } from '../pages/ConfirmRegister';

import { PaymentOptions } from '../pages/PaymentOptions';
import { PaymentSuccess } from '../pages/PaymentSuccess';
import { SubscriptionOptions } from '../pages/SubscriptionOptions';
import { SubscriptionSuccess } from '../pages/SubscriptionSuccess';
import { WalletPaymentOptions } from '../pages/WalletPaymentOptions';
import { NotFoundPage } from '../pages/NotFound';
import { PlanOptions } from '../pages/PlanOptions';
import { PaymentLookingAhead } from '../pages/PaymentLookingAhead';

export default function LoggedOutRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="*" element={<NotFoundPage />} />

      <Route path="/confirm-register/:id" element={<ConfirmRegister />} />
      <Route path="/pagamento/:id" element={<PaymentOptions />} />
      <Route path="/payment/LookingAhead" element={<PaymentLookingAhead />} />
      <Route path="/plan/:id" element={<PlanOptions />} />
      <Route path="payment/:id/success" element={<PaymentSuccess />} />
      <Route
        path="/carteira/pagamento/:id"
        element={<WalletPaymentOptions />}
      />
      <Route path="/assinatura/:id" element={<SubscriptionOptions />} />
      <Route path="/assinatura/:id/sucesso" element={<SubscriptionSuccess />} />
    </Routes>
  );
}
