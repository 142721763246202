import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { LabDsButton, LabDsBreadcrumb } from 'v4web-components-react';
import { Container, Headline } from '../../components/LegacyV4Components';

import CustomerInformation from '../../components/AtomicDesign/molecules/CustomerInformation';
import CustomerViewPreview from '../../components/AtomicDesign/templates/CustomerViewPreview';
import CustomerStakeholdersPreview from '../../components/AtomicDesign/templates/CustomerStakeholdersPreview';
import CustomerToolsPreview from '../../components/AtomicDesign/templates/CustomerToolsPreview';

import { useToast } from '../../hooks/toast';
import * as S from './styles';
import { EditCustomerModal } from '../../components/AtomicDesign/molecules/EditCustomerModal';
import { useQueryGetUnit } from '../../services/requests/Unit/useQueryUnit';
import { useQueryGetCustomer } from '../../services/requests/Customers/useQueryCustomer';
import { getInvestorsByCustomersId } from '../../services/requests/Customers/getInvestorsByCustomersId';
import CustomerTeamPreview from '../../components/AtomicDesign/templates/CustomerTeamPreview';
import { InvestorTeam } from '../../hooks/types';

export function Customer() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);

  const { addToast } = useToast();

  const { data: customerData, error: customerError } = useQueryGetCustomer(id);
  const { data: unitData, error: unitError } = useQueryGetUnit(
    customerData?.data?.units[0]?._id,
  );

  const customer = customerData?.data;
  const unit = unitData?.data;

  const [investors, setInvestors] = useState<InvestorTeam[]>([]);

  useEffect(() => {
    async function getInvestors() {
      const data = await getInvestorsByCustomersId(id || '');
      if (data) setInvestors(data);
    }
    getInvestors();
  }, [id]);

  useEffect(() => {
    if (unitError) {
      return addToast({
        title: 'Falha ao buscar cliente',
        type: 'error',
      });
    }

    if (customerError) {
      return addToast({
        title: 'Falha ao buscar cliente',
        type: 'error',
      });
    }
    return undefined;
  }, [addToast, unitError, customerError, customerData]);

  const breadcrumbsItems = [
    {
      label: 'Clientes',
      link: '/clientes',
      key: 'customers',
      event: () => {
        navigate('/clientes');
      },
    },
    {
      label: `${customer?.name ?? ''}`,
      link: '',
      key: 'customer',
      event: () => {
        null;
      },
    },
  ];

  return (
    <Container>
      <LabDsBreadcrumb breadcrumbs={breadcrumbsItems} />

      <S.Title>
        <S.Text>
          <Headline variant="h3">{customer?.name}</Headline>

          {unit?.name ? (
            <p style={{ color: 'var(--success-1)' }}>
              Cliente atendido por {unit?.name}
            </p>
          ) : (
            <p style={{ color: 'var(--primary-light)' }}>
              Cliente sem unidade, Atualize!
            </p>
          )}
        </S.Text>
        <LabDsButton
          label="Editar"
          variant="outlined"
          onHandleButton={() => setIsUpdateModalOpen(true)}
        />
      </S.Title>

      <S.ContainerInfo>
        <CustomerInformation customer={customer} />
      </S.ContainerInfo>

      <S.Row>
        <S.Card>
          <CustomerViewPreview customer={customer} />
        </S.Card>

        <S.Card>
          <CustomerStakeholdersPreview customer={customer} />
        </S.Card>
      </S.Row>

      <S.Row>
        <S.Card>
          <CustomerToolsPreview customer={customer} />
        </S.Card>
        <S.Card>
          <CustomerTeamPreview team={investors} customerId={id || ''} />
        </S.Card>
      </S.Row>

      <EditCustomerModal
        customer={customer}
        isOpen={isUpdateModalOpen}
        setIsOpen={() => setIsUpdateModalOpen(false)}
      />
    </Container>
  );
}
