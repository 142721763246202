import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const Input = styled.input<{
  width?: string;
  height?: string;
  beforeWidth?: string;
  beforeHeight?: string;
  border?: string;
}>`
  appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
  margin: 0 10px 0 0;
  font: inherit;
  color: ${({ checked }) =>
    checked ? 'var(--success-2)' : 'var(--grayscale-60)'};
  width: ${({ width }) => width || '1.25rem'};
  height: ${({ height }) => height || '1.25rem'};
  border: ${({ border }) => border || '0.15em'} solid
    ${({ checked }) => (checked ? 'var(--success-2)' : 'var(--grayscale-60)')};
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  border-radius: 50%;

  &::before {
    content: '';
    width: ${({ beforeWidth }) => beforeWidth || '0.62rem'};
    height: ${({ beforeHeight }) => beforeHeight || '0.62rem'};
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #00c49a;
    background-color: CanvasText;
    transform-origin: bottom left;
    border-radius: 50%;
  }

  &:checked::before {
    transform: scale(1);
  }
`;
