import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LabDsHeader } from 'v4web-components-react';
import { ToolsDropdown } from '../../AtomicDesign/molecules/ToolsDropdown';

import { useAuth } from '../../../hooks/auth';

import Logo from '../../../assets/logos/sign-in-logo.svg';

export function Header() {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const isHeadquarterUser =
    user.unitId === '627c19e328bbf0be319d6bba' ||
    user.unitId === '627554adffa66d39ea56fe60';

  const [showUserOptions, setShowUserOptions] = useState(true);
  const [firstTime, setFirsTime] = useState(true);

  const handleUserOptions = useCallback(() => {
    setShowUserOptions(!showUserOptions);
  }, [setShowUserOptions, showUserOptions]);

  useEffect(() => {
    if (firstTime) {
      setTimeout(() => {
        handleUserOptions();
      }, 10000);
      setFirsTime(false);
    }
  }, [handleUserOptions, firstTime]);

  return (
    <LabDsHeader
      logoHeader={Logo}
      eventMyProfile={() => {
        navigate(`/perfil/${user?._id}`);
      }}
      eventLogout={() => {
        signOut();
        navigate('/');
      }}
      user={
        user
          ? {
              name: user?.name,
              imageSRC: user?.picture,
              unitId: user?.unitId,
              id: user?._id,
            }
          : undefined
      }
      eventLogo={() => {
        navigate('/');
      }}
    >
      <div slot="notifications">
        <ToolsDropdown tools={{ roiWeek: !isHeadquarterUser }} />
      </div>
    </LabDsHeader>
  );
}
