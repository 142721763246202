import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Container,
  Headline,
} from '../../components/LegacyV4Components';
import CustomerGateway from '../../components/AtomicDesign/templates/CustomerGateway';
import MediaWallet from '../../components/AtomicDesign/templates/MediaWallet';
import CustomerPlans from '../../components/AtomicDesign/templates/CustomerPlans';
import { customerApi } from '../../services/api';
import { ClientTabs } from '../../components/AtomicDesign/atoms/ClientTabs';
import { CustomerDashboards } from '../../components/AtomicDesign/organisms/CustomerDashboards';
import { useGetProductList } from '../../services/requests/Finance/getProductList';
import { useToast } from '../../hooks/toast';

import {
  ISubscriptionList,
  getSubscriptionList,
} from '../../services/requests/Finance/getSubscriptionList';
import {
  IPlanList,
  getPlanByCustomer,
} from '../../services/requests/Finance/getPlanByCustomer';

import * as S from './styles';
import { PaymentChecklistValidate } from '../../components/AtomicDesign/organisms/PaymentChecklistValidate';
import { paymentValidate } from '../../services/requests/Finance/paymentValidate';
import { PaymentValidate } from '../../types/paymentValidate';
import { useQueryGetUnitHaveAWSKey } from '../../services/requests/Finance/getUnitHaveAWSKey';
import { useQueryGetCustomertHaveAWSKey } from '../../services/requests/Finance/getCustomerHaveAWSKey';
import { getUnit } from '../../services/requests/Unit/getUnit';
import { KubernetesApiUnit } from '../../hooks/types';
import { Customer } from '../../types/customer';

enum Tabs {
  tab1 = 'Gateway de pagamento',
  tab2 = 'Dashboards',
  tab3 = 'Planos e Cobranças',
  tab4 = 'Media Wallet',
  tab5 = 'Você não tem acesso a nenhuma ferramenta',
}

export function CustomerToolsView() {
  const { id } = useParams<{ id: string }>();
  const [subscriptions, setSubscriptions] = useState<ISubscriptionList>();
  const [plans, setPlans] = useState<IPlanList[]>([]);
  const [customer, setCustomer] = useState<Customer>();
  const [customerUnit, setCustomerUnit] = useState<KubernetesApiUnit['unit']>();
  const [customerUnitId, setCustomerUnitId] = useState<string>('');
  const [clientTab, setClientTab] = useState<string>(Tabs.tab1);
  const { data } = useGetProductList(customer?._id);

  const [paymentsValidateData, setPaymentsValidateData] =
    useState<PaymentValidate>({} as PaymentValidate);

  const { addToast } = useToast();

  const { data: unitData } = useQueryGetUnitHaveAWSKey(customerUnitId);
  const { data: bankResponse } = useQueryGetCustomertHaveAWSKey(id as string);

  const unitHaveAwsKeys = unitData?.data.haveAWSSecrets;
  const unitAccountIsVerifiedOnIugu = unitData?.data.accountIsVerifiedOnIugu;

  const paymentValidateString = JSON.stringify(paymentsValidateData);
  const paymentValidateHaveAnyFalse = paymentValidateString.includes('false');

  const customerHaveAwsKeys = bankResponse?.data.haveAWSSecrets;

  const tabsToShow =
    unitHaveAwsKeys && customerHaveAwsKeys && unitAccountIsVerifiedOnIugu
      ? [Tabs.tab1, Tabs.tab3, Tabs.tab4]
      : [Tabs.tab1, Tabs.tab3, Tabs.tab4];

  const breadcrumbsItems = [
    { title: 'Clientes', link: '/clientes' },
    { title: `${customer?.name ?? ''}`, link: `/cliente/${id}` },
    { title: `Ferramentas `, link: '' },
  ];

  useEffect(() => {
    async function validatePayments() {
      const paymentsInfo = await paymentValidate(id as string);
      setPaymentsValidateData(paymentsInfo);
    }
    validatePayments();
  }, [id]);

  const getCustomer = useCallback(async () => {
    const customerData = await customerApi.get<Customer>(`/customer/${id}`);

    setCustomerUnitId(customerData.data.units[0]._id);

    if (!customerData) {
      addToast({
        title: 'Falha ao buscar cliente',
        type: 'error',
      });

      return;
    }

    if (!customerData?.data?.units || !customerData.data.units[0]) {
      addToast({
        title: 'Falha ao encontrar unidade cliente',
        type: 'error',
      });

      return;
    }

    if (!customerData.data.units[0]._id) {
      addToast({
        title: 'Cliente sem unidade vinculada',
        type: 'error',
      });

      return;
    }
    const response = await getUnit(customerData.data.units[0]._id);

    if (!response) {
      addToast({
        title: 'Falha ao buscar unidade do cliente',
        type: 'error',
      });
      return;
    }

    setCustomerUnit(response);
    setCustomer(customerData.data);
  }, [id, addToast]);

  const getSubscriptions = useCallback(async () => {
    const subscription = await getSubscriptionList(id);

    if (!subscription) {
      addToast({
        title: 'Falha ao buscar assinaturas',
        type: 'error',
      });

      return;
    }

    setSubscriptions(subscription);
  }, [addToast, id]);

  const getPlansList = useCallback(async () => {
    const planList = await getPlanByCustomer(id);

    if (!planList) {
      addToast({
        title: 'Falha ao buscar assinaturas',
        type: 'error',
      });

      return;
    }

    setPlans(planList);
  }, [addToast, id]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  useEffect(() => {
    getPlansList();
  }, [getPlansList]);

  return (
    <Container>
      <Breadcrumb breadcrumbs={breadcrumbsItems} />

      <S.Header>
        <Headline variant="h3">Ferramentas</Headline>
      </S.Header>

      {paymentValidateHaveAnyFalse && (
        <PaymentChecklistValidate data={paymentsValidateData} />
      )}
      {customer?._id && (
        <S.TabsWrapper>
          <ClientTabs tabs={tabsToShow} onChange={(tab) => setClientTab(tab)} />

          {clientTab === Tabs.tab2 && <CustomerDashboards clientId={id} />}

          {clientTab === Tabs.tab1 && (
            <CustomerGateway
              customer={customer}
              productList={data}
              unit={customerUnit}
            />
          )}

          {clientTab === Tabs.tab3 && (
            <CustomerPlans
              customer={customer}
              subscriptions={subscriptions as any}
              plans={plans}
            />
          )}

          {clientTab === Tabs.tab4 && <MediaWallet customer={customer} />}
        </S.TabsWrapper>
      )}
    </Container>
  );
}
