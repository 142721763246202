import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { UnitsAutocomplete } from '../CreateCustomerModal';
import * as S from './styles';

interface TextFieldProps {
  label: string;
  options: any[];
  onChange: (value: any) => void;
}

export default function AutocompleteCustom({ ...props }: TextFieldProps) {
  const [units, setUnits] = useState<UnitsAutocomplete[]>([]);

  useEffect(() => {
    const parsedUnits: UnitsAutocomplete[] = [];
    props.options.map((e) => parsedUnits.push({ label: e.name, id: e._id }));
    setUnits(parsedUnits);
  }, [props.options, units]);

  return (
    <S.InputWrapper>
      <h6>{props.label}</h6>

      <Autocomplete
        disablePortal
        options={units}
        clearIcon={false}
        onChange={(_, value) => props.onChange(value)}
        sx={{
          display: 'flex',
          borderRadius: '0.25rem',
        }}
        renderInput={(params) => (
          <TextField
            onChange={(e) => props.onChange(e)}
            placeholder="Selecione a unidade"
            {...params}
            sx={{
              border: '1px solid var(--grayscale-4)',
              borderRadius: '0.25rem',
              boxShadow:
                '0px 1px 2px rgba(0, 0, 0, 0.12), 0px 2px 6px 2px rgba(0, 0, 0, 0.06)',
              width: '100%',
              display: 'flex',
            }}
          />
        )}
      />
    </S.InputWrapper>
  );
}
