import { useState, useRef } from 'react';
import { TfiClip } from 'react-icons/tfi';
import { MdClose } from 'react-icons/md';
import { Modal, Button } from '../../../LegacyV4Components';

import { verifyUrl } from '../../../../utils/verifyUrl';

import * as S from './styles';

interface LastUpdateOperationProps {
  isOpen: boolean;
  closeModal: () => void;
  handleCreateLastUpdate: (data: {
    name: string;
    description: string;
    videoUrl: string;
    files: File[];
  }) => void;
}

export function CreateLastUpdateModal({
  isOpen,
  closeModal,
  handleCreateLastUpdate,
}: LastUpdateOperationProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [loadedFiles, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState({
    name: false,
    description: false,
    videoUrl: false,
    fileSize: false,
  });

  const handleFileInputClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      if (files[0].size > 15728640) {
        setError({
          ...error,
          fileSize: true,
        });
        return;
      }
      const filesArray = Array.from(files);
      setFiles([...filesArray, ...(loadedFiles as File[])]);

      setError({
        ...error,
        fileSize: false,
      });
    }
  };

  const handleRemoveFile = (i: number) => {
    loadedFiles.splice(i, 1);

    setFiles([...loadedFiles]);
  };

  function handleCloseModal() {
    setName('');
    setVideoUrl('');
    setDescription('');
    setFiles([]);
    setError({
      name: false,
      description: false,
      videoUrl: false,
      fileSize: false,
    });
    closeModal();
  }

  async function handleSubmit() {
    if (!name || !videoUrl || !description || !verifyUrl(videoUrl)) {
      setError({
        name: !name,
        description: !description,
        videoUrl: !videoUrl || !verifyUrl(videoUrl),
        fileSize: false,
      });
      return;
    }
    setIsLoading(true);

    handleCreateLastUpdate({
      name,
      description,
      videoUrl: videoUrl.split('/view')[0],
      files: loadedFiles,
    });

    setIsLoading(false);

    handleCloseModal();
  }
  return (
    <>
      <Modal
        show={isOpen}
        title="Anexar novo vídeo"
        closeButton
        onBackground={handleCloseModal}
        size="md"
      >
        <S.Container>
          <p>Nome do vídeo</p>

          <S.InputField
            type="text"
            onChange={(e) => {
              setError({
                ...error,
                name: false,
              });
              setName(e.target.value);
            }}
            value={name}
            error={error.name}
          />
          {error.name && <S.ErrorTag>*Preencha o campo nome</S.ErrorTag>}

          <p>Descrição</p>
          <S.InputField
            type="text"
            onChange={(e) => {
              setError({
                ...error,
                description: false,
              });
              setDescription(e.target.value);
            }}
            value={description}
            error={error.description}
          />
          {error.description && (
            <S.ErrorTag>*Preencha o campo descrição</S.ErrorTag>
          )}

          <p>Link do vídeo</p>
          <S.InputField
            type="text"
            onChange={(e) => {
              setError({
                ...error,
                videoUrl: false,
              });
              setVideoUrl(e.target.value);
            }}
            value={videoUrl}
            error={error.videoUrl}
          />

          {error.videoUrl && (
            <S.ErrorTag>*Preencha o campo com um link</S.ErrorTag>
          )}

          <S.SizeWarning>
            Os arquivos selecionados precisam ser menores que <b>15Mb</b>.
          </S.SizeWarning>

          <S.ImportFilesTitle onClick={() => handleFileInputClick()}>
            <TfiClip size={18} />
            Anexar arquivos
          </S.ImportFilesTitle>

          {error.fileSize && (
            <S.ErrorTag> O arquivo ultrapassou o tamanho limite</S.ErrorTag>
          )}

          <input
            type="file"
            onChange={handleFileUpload}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />

          <S.FileList>
            {loadedFiles?.map((loadedFile, i) => (
              <S.File key={loadedFile.name}>
                <S.FileName>{loadedFile.name}</S.FileName>
                <MdClose size={16} onClick={() => handleRemoveFile(i)} />
              </S.File>
            ))}
          </S.FileList>
        </S.Container>
        <S.ButtonContainer>
          <Button
            size="default"
            color="green"
            onClick={handleSubmit}
            variant="primary"
            loading={isLoading}
            disabled={isLoading}
          >
            Anexar
          </Button>
        </S.ButtonContainer>
      </Modal>
    </>
  );
}
