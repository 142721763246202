import { User } from '../../../hooks/types';
import { usersApi as api } from '../../api';

type ApiResponse = User[];

type ApiResponseWithPagination = {
  data: User[];
  totalCount: number;
  totalPages: number;
};
interface GetUsers {
  unitId: string;
  isActive?: boolean;
  showDeleted?: boolean;
  search?: string;
  withPagination?: boolean;
  mandatesId?: string[];
  senioritiesId?: string[];
  wage?: { start: string; end: string };
  page?: number;
  limit?: number;
  canOffer?: boolean;
  canTransition?: boolean;
}

export async function getUsersByUnitId({
  unitId,
  isActive,
  showDeleted,
  search,
  mandatesId,
  senioritiesId,
  wage,
  withPagination = false,
  page = 1,
  limit = 10,
  canOffer,
  canTransition,
}: GetUsers): Promise<{
  users: User[];
  totalCount?: number;
  totalPages?: number;
}> {
  try {
    const response = await api.get<ApiResponse | ApiResponseWithPagination>(
      '/user',
      {
        params: {
          unitId,
          isActive,
          showDeleted,
          search,
          withPagination,
          limit,
          page,
          mandatesId: JSON.stringify(mandatesId),
          senioritiesId: JSON.stringify(senioritiesId),
          wage,
          canOffer,
          canTransition,
        },
      },
    );

    const users: User[] = Array.isArray(response.data)
      ? response.data
      : response.data.data;

    const { totalCount } = response.data as ApiResponseWithPagination;
    const { totalPages } = response.data as ApiResponseWithPagination;

    return { users, totalCount, totalPages };
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os usuários, atualize a página e tente novamente 😕`,
      err,
    );

    throw err;
  }
}
