import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 46.25rem;

  padding: 1.5rem 2rem;
  width: 100%;

  background-color: var(--white);
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);

  .delete-column {
    text-align: right;
    cursor: pointer;
  }
  .paid {
    display: flex;
    padding: 4px 16px 4px 16px;
    align-items: center;
    background: #00c49a;
    border-radius: 4px;
    width: fit-content;
  }
  .canceled {
    display: flex;
    padding: 4px 16px 4px 16px;
    align-items: center;
    background: #bbbbbb;
    border-radius: 4px;
    width: fit-content;
  }
  .pending {
    display: flex;
    padding: 4px 16px 4px 16px;
    align-items: center;
    background: #fabc2a;
    border-radius: 4px;
    width: fit-content;
  }
  .expired {
    display: flex;
    padding: 4px 16px 4px 16px;
    align-items: center;
    background: #e03131;
    border-radius: 4px;
    width: fit-content;
  }
`;

export const Header = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Saldo = styled.div`
  display: flex;
  margin: auto 0px;
  p {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
`;

export const topLineHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  aling-items: center;
  margin-top: 1.75rem;

  button:first-child {
    margin-right: 1rem;
  }
`;
