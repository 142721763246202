import { FiX } from 'react-icons/fi';
import { Divider } from '@mui/material';
import { useEffect, useState } from 'react';

import { LabDsButton, LabDsSelect } from 'v4web-components-react';
import { IOptionsSelect } from 'v4web-components';
import { useMemo } from 'react';
import { Headline, Modal } from '../../../../components/LegacyV4Components';
import * as S from './styles';

import { User } from '../../../../hooks/types';

import { useToast } from '../../../../hooks/toast';
import { ProfileCard } from '../../../../components/Default/ProfileCard';

import { assignInvestor } from '../../../../services/requests/User/CapacityManage/assignInvestor';
import { getUsersByUnitId } from '../../../../services/requests/Unit/getUsersByUnitId';
import { dedicationOptions } from './hooks';
import { getAllMandateItems } from '../../../../components/AtomicDesign/organisms/ProfileForm/hooks';

export function AddInvestorModal({
  isShown,
  setIsShown,
  unitId,
  customerName,
  customerId,
}: {
  isShown: boolean;
  setIsShown: (value: boolean) => void;
  unitId: string;
  customerName: string;
  customerId: string;
}) {
  const [mandates, setMandates] = useState<IOptionsSelect[]>([]);
  const [mandateSelected, setMandateSelected] = useState<{
    name: string;
    id: string;
  } | null>(null);

  const [dedicationSelected, setDedicationSelected] = useState<string>('');
  const [userSelected, setUserSelected] = useState<User | null>();

  const [investorOptions, setInvestorOptions] = useState<IOptionsSelect[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [step, setStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingGetUserOptions, setIsLoadingGetUserOptions] =
    useState<boolean>(false);

  const { addToast } = useToast();

  useEffect(() => {
    const mandatesItems = getAllMandateItems(true);
    setMandates(mandatesItems as IOptionsSelect[]);
  }, [unitId]);

  useMemo(() => {
    async function filterUsers() {
      const nameMandateAllowedToCientistaDoMarketing = [
        'Gestor de Tráfego',
        'Account Manager',
        'Analista de B.I',
        'Copywriting',
      ];
      setIsLoadingGetUserOptions(true);
      let options: IOptionsSelect[] = [];
      let usersFinded: User[] = [];

      const userWithMandateSelected = await getUsersByUnitId({
        unitId,
        isActive: true,
        mandatesId: [mandateSelected?.id || ''],
      });

      if (userWithMandateSelected) {
        const filtered = userWithMandateSelected.users.map((user: User) => {
          return { key: user._id, label: user.name, isChecked: false };
        });
        usersFinded = usersFinded.concat(userWithMandateSelected.users);
        options = filtered;
      }

      if (
        nameMandateAllowedToCientistaDoMarketing.includes(
          mandateSelected?.name || '',
        )
      ) {
        const idMandatesCientista = (mandates as IOptionsSelect[]).find(
          (item) => {
            return (
              item.label === 'Cientista do Marketing' ||
              item.label === 'Cientista'
            );
          },
        );
        const responseCientista = await getUsersByUnitId({
          unitId,
          isActive: true,
          mandatesId: [idMandatesCientista?.key || ''],
        });
        if (responseCientista) {
          usersFinded = usersFinded.concat(responseCientista.users);
          responseCientista.users.map((user: User) => {
            options.push({ key: user._id, label: user.name, isChecked: false });
            return { key: user._id, label: user.name, isChecked: false };
          });
        }
      }

      setUsers(usersFinded);
      setInvestorOptions(options);
      setIsLoadingGetUserOptions(false);
    }
    filterUsers();
  }, [mandateSelected, mandates, unitId]);

  return (
    <>
      <Modal show={isShown} height="50rem">
        <S.Content>
          <S.HeaderModal>
            <S.Row>
              <Headline variant="h5">
                Adicionar investidor não obrigatório
              </Headline>

              <button
                type="button"
                onClick={() => {
                  setStep(1);
                  setIsShown(false);
                  setMandateSelected(null);
                  setDedicationSelected('');
                  setUserSelected(null);
                }}
                aria-label="default"
              >
                <FiX />
              </button>
            </S.Row>
            {step === 1 && (
              <p>
                Para adicionar o investidor selecione o cargo e a dedicação.
                Atenção: O investidor selecionado será deduzido diretamente da
                capacidade da sua unidade.
              </p>
            )}
            <Divider />
          </S.HeaderModal>

          {step === 1 ? (
            <>
              <LabDsSelect
                titleInput="Selecione uma Função/Cargo"
                label="Selecione o cargo"
                avatar={false}
                options={mandates as IOptionsSelect[]}
                onChangeInputOptions={(e) => {
                  setMandateSelected({
                    id: e.detail[0].key,
                    name: e.detail[0].label,
                  });
                  setUserSelected(null);
                }}
                type="radioButton"
              />
              <LabDsSelect
                titleInput="Selecione o uma dedicação"
                label="Selecione o uma dedicação"
                avatar={false}
                options={dedicationOptions}
                onChangeInputOptions={(e) => {
                  setDedicationSelected(e.detail[0].key);
                }}
                type="radioButton"
              />
              <LabDsSelect
                titleInput="Selecione um investidor"
                label="Selecione um investidor"
                avatar={false}
                options={investorOptions}
                onChangeInputOptions={(e) => {
                  const userS = [
                    users?.find((user) => {
                      return user._id === e.detail[0]?.key;
                    }),
                  ];

                  if (userS) {
                    setUserSelected(userS[0]);
                  }
                }}
                type="radioButton"
                disabled={!mandateSelected || isLoadingGetUserOptions}
              />
            </>
          ) : (
            <>
              <p>
                Você está adicionando a {userSelected?.name} para o projeto{' '}
                <b>{customerName}</b>. Deseja realmente adicionar?
              </p>
              <S.UserBoard>
                {userSelected && <ProfileCard profile={userSelected} />}
                <h2>
                  <b>{userSelected?.name}</b>
                </h2>
                <p>
                  <b>
                    {userSelected?.mandate?.name}{' '}
                    {userSelected?.seniority?.name}
                  </b>
                </p>
                <p>{userSelected?.email}</p>
                <p>
                  Dedicação:{' '}
                  {dedicationSelected === 'dedicated'
                    ? 'Dedicado'
                    : 'Compartilhado'}
                </p>
              </S.UserBoard>
            </>
          )}
          <S.Actions>
            {step === 2 && (
              <LabDsButton
                label="Cancelar"
                variant="danger-outlined"
                onClick={() => {
                  setMandateSelected(null);
                  setDedicationSelected('');
                  setUserSelected(null);
                  setStep(1);
                  setIsShown(false);
                  return null;
                }}
                disabled={isLoading}
              />
            )}
            <LabDsButton
              label="Adicionar investidor"
              color="green"
              size="medium"
              loading={isLoading}
              onClick={async () => {
                if (step === 1) setStep(2);
                else {
                  setIsLoading(true);
                  const response = await assignInvestor(
                    userSelected?._id || '',
                    customerId,
                    true,
                    dedicationSelected === 'dedicated',
                  );
                  if (response) {
                    addToast({
                      type: 'success',
                      title: '',
                      description: 'Investidor adicionado com sucesso!',
                    });
                    setMandateSelected(null);
                    setDedicationSelected('');
                    setUserSelected(null);
                    setStep(1);
                    setIsLoading(false);
                    setIsShown(false);
                  } else {
                    setIsLoading(false);
                    addToast({
                      type: 'error',
                      title: 'Erro adicionar investidor no projeto.',
                      description: 'Erro ao adicionar investidor!',
                    });
                  }
                }
              }}
              variant="primary"
              disabled={
                mandateSelected === null ||
                dedicationSelected.length === 0 ||
                userSelected === null
              }
            />
          </S.Actions>
        </S.Content>
      </Modal>
    </>
  );
}
