// Rodrigo Schieck, Rodrigo Homolog, Rodrigo Dev, Marlon Ferreira, Diego Luz, Leonardo Rosa, Torres, Torres2, Fazenda, Brenda, Andrea, Edson Dev
export const superAdmins = [
  '6282b5619582b42be5085a87',
  '63b5bdafb70de21faa3fa95c',
  '628e9250b7011b10507aa662',
  '627bebfd3898816772fe92a3',
  '6398b091a9107710680044d8',
  '62bf1ebb113cfe7111934f95',
  '6286acc49582b42be5085afc',
  '62c2e2d9be654a2061ef1ff5',
  '62bf2330e0ea1ccb77d0b87b',
  '62a896c188e78b1032e2e045',
  '62bf1833af8b915297a8da9d',
  '63adaca226bbe36d2d3b05ef',
  '6297dfd6030602ff2c75f10d',
  '638105dd0f84afcac5d72b02',
  '63bc4cc4b044400a456b5c53',
  '62bf1ba6ef7b500554a5d89c',
];

export const customerTestUnits = [
  '6286574e0fb9bb733c2b0234',
  '628657510fb9bb733c2b0380',
  '6286574e0fb9bb733c2b0276',
  '6286574e0fb9bb733c2b0288',
  '6286574f0fb9bb733c2b02d0',
  '6286574d0fb9bb733c2b0231',
  '628657500fb9bb733c2b02eb',
  '6286574e0fb9bb733c2b0249',
  '628657500fb9bb733c2b033b',
  '628657510fb9bb733c2b0341',
  '6286574f0fb9bb733c2b02d3',
  '628657530fb9bb733c2b03fb',
  '628657500fb9bb733c2b0332',
  '628657510fb9bb733c2b036e',
  '63d2c1bd696f31ee04e89377',
  '628657560fb9bb733c2b04f0',
  '628657530fb9bb733c2b0422',
  '628657550fb9bb733c2b04d8',
  '628657520fb9bb733c2b03cb',
  process.env.REACT_APP_HEADQUARTER_ID, // HQ
];

export const mainStakeholderTag = 'Stakeholder principal';
