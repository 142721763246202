import { useState } from 'react';
import { LabDsBreadcrumb, LabDsTab } from 'v4web-components-react';

import { useNavigate } from 'react-router-dom';

import { ConnectStackDigitalAccounts } from '../../components/AtomicDesign/organisms/ConnectDigitalStackAccounts';

import * as S from './styles';

export function StackDigital() {
  const [tabActive, setTabActive] = useState<string>('tools');

  const navigate = useNavigate();

  const breadcrumbItems = [
    {
      label: 'Meus clientes',
      link: '/clientes',
      key: 'customers',
      event: () => navigate('/clientes'),
    },
    {
      label: 'Stack Digital',
      link: '/clientes/stackdigital',
      key: 'stackdigital',
      event: () => navigate('/clientes/stackdigital'),
    },
  ];

  return (
    <S.Container>
      <LabDsBreadcrumb breadcrumbs={breadcrumbItems} />
      <S.Content>
        <h1>Stack Digital</h1>
        <S.Tabs>
          <LabDsTab
            value={tabActive}
            tabs={[
              {
                title: 'Ferramentas',
                key: 'tools',
                event: () => {
                  setTabActive('tools');
                },
              },
            ]}
          />
        </S.Tabs>
        <ConnectStackDigitalAccounts />
      </S.Content>
    </S.Container>
  );
}
