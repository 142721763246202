import { createGlobalStyle, withTheme } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap");

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html {
    font-family: "Nunito Sans", serif;
  }

  body {
    background: #f4f4f8;
    color: #333333;
    -webkit-font-smoothing: antialiased;
  }

  body, input {
    font-family: "Nunito Sans", serif;
    font-size: 16px;
  }

  button {
    font-family: "Inter", serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
    font-family: "Inter", sans-serif;
  }

  p, span, a {
    font-family: "Nunito Sans", sans-serif;
  }

  button {
    cursor: pointer;
  }

  :root {
    // Vars
    --primary-dark-1: #D9364A;
    --primary-dark-2: #C01C30;
    --primary-dark-3: #A61123;

    --primary-main: #E92E30;
    --primary-dark: #D9364A;
    --primary-light: #FF5E5F;

    --secondary-dark: #1A1919;
    --secondary-main: #333333;
    --secondary-light: #7F7F7F;

    --tertiary-dark: #BBBBBB;
    --tertiary-main: #F6F6F6;
    --tertiary-light: #FFFFFF;


    --success-1: #0DDEB1;
    --success-2: #00C49A;
    --success-3: #00AB86;
    --warning: #FABC2A;
    --error: #E03131;
    --notice: #2ABCFA;

    --white: #ffffff;  
    --black: #000000;

    --grayscale-100: #50507c;
    --grayscale-90: #585889;
    --grayscale-80: #5f5f95;
    --grayscale-70: #7676a7;
    --grayscale-60: #8383af;
    --grayscale-50: #8f8fb7;
    --grayscale-40: #9b9bbf;
    --grayscale-30: #a8a8c7;
    --grayscale-20: #b4b4cf;
    --grayscale-10: #c1c1d7;
    --grayscale-8: #cdcddf;
    --grayscale-6: #dadae7;
    --grayscale-4: #e6e6ef;
    --grayscale-2: #f4f4f8;
  }
`;

export default withTheme(GlobalStyle);
