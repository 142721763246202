import React, { useState } from 'react';

import { FiPercent, FiCreditCard, FiInfo } from 'react-icons/fi';
import { Box } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FiX } from 'react-icons/fi';
import { Button, Headline } from '../../../LegacyV4Components';
import * as S from './styles';
import { Modal } from '../../../Default/Modal';
import { FormikCurrencyInput } from '../../molecules/FormikCurrencyInput';
import { FormikPercentageInput } from '../../molecules/FormikPercentageInput';
import { Tooltip } from '../../../Default/Tooltip';
import { ResumeChargeModal } from '../ResumeChargeModal';
import { CreateInvoiceMW } from '../../../../types/createCustomerFNC';

export function MidiaWalletGenerateChargeModal({
  isShown,
  setIsShown,
  customerId,
}: {
  isShown: boolean;
  setIsShown: (value: React.SetStateAction<boolean>) => void;
  customerId: string;
}) {
  const [boletoChecked, setBoletoCheck] = useState(true);
  const [pixChecked, setPixCheck] = useState(true);
  const [isOpenResume, setIsOpenResume] = useState(false);
  const [isVariableValue, setIsVariableValue] = useState<boolean>();

  const GenerateSchema = Yup.object().shape({
    ammount: Yup.number()
      .min(1, 'O valor deve maior que R$ 1,00')
      .required('O campo é obrigatório!'),
    splits: Yup.object().shape({
      method: Yup.string()
        .oneOf(['fixed', 'percent'])
        .required('Forma de corretagem deve ser informada'),
    }),
    paymentMethods: Yup.array()
      .of(Yup.string().required())
      .required('O campo é obrigatório!'),
  });

  return (
    <>
      <Formik
        initialValues={{
          ammount: '',
          splits: {
            method: '',
            value: 0,
          },
          paymentMethods: ['pix', 'bank_slip'],
        }}
        validationSchema={GenerateSchema}
        onSubmit={async () => {
          setIsOpenResume(true);
        }}
      >
        {({ values, handleBlur, setFieldValue, isValid, dirty }) => (
          <>
            <>
              {isOpenResume ? (
                <ResumeChargeModal
                  isShown={isOpenResume}
                  setIsOpenResume={setIsOpenResume}
                  setIsShown={setIsShown}
                  data={values as unknown as CreateInvoiceMW}
                  isRoyalties
                  isVariableValue={isVariableValue}
                  customerId={customerId}
                />
              ) : null}
            </>
            <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
              <S.ModalWrapper>
                <S.ModalHeader>
                  <Headline variant="h4">Gerar Cobrança</Headline>
                  <button
                    type="button"
                    onClick={() => setIsShown(false)}
                    aria-label="default"
                  >
                    <FiX />
                  </button>
                </S.ModalHeader>
                <S.ModalBody>
                  <Form>
                    <S.SectionGenerate>
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '1.875rem',
                          paddingBottom: '2rem',
                        }}
                        className="containerProduct"
                      >
                        <FormikCurrencyInput
                          label="Valor"
                          onValueChange={(value) => {
                            setFieldValue('ammount', value);
                          }}
                          value={values.ammount}
                        />
                      </div>
                    </S.SectionGenerate>
                    <S.SectionGenerate>
                      <div style={{ paddingBottom: '2rem' }}>
                        <Headline variant="h5">
                          <FiPercent />
                          Porcentagem de corretagem
                        </Headline>
                        <Box sx={{ marginTop: '1.5rem' }}>
                          <span>
                            Qual será a forma de corretagem do valor para a
                            franquia?
                          </span>
                        </Box>
                        <S.ContainerValueVariable>
                          <Box sx={{ display: 'flex', marginTop: '1rem' }}>
                            <S.ContainerRadio
                              color={isVariableValue ? '1px solid #00C49A' : ''}
                              onClick={() => {
                                setIsVariableValue(true);
                                setFieldValue('splits.method', 'percent');
                              }}
                            >
                              <input
                                name="variable"
                                type="radio"
                                checked={isVariableValue === true}
                                onChange={() => {
                                  setIsVariableValue(true);
                                  setFieldValue('splits.method', 'percent');
                                }}
                              />
                              <p>Valor variável</p>
                            </S.ContainerRadio>
                            <S.ContainerRadio
                              color={
                                isVariableValue === false
                                  ? '1px solid #00C49A'
                                  : ''
                              }
                              onClick={() => {
                                setIsVariableValue(false);
                                setFieldValue('splits.method', 'fixed');
                              }}
                            >
                              <input
                                name="variable"
                                type="radio"
                                checked={isVariableValue === false}
                                onChange={() => {
                                  setIsVariableValue(false);
                                  setFieldValue('splits.method', 'fixed');
                                }}
                              />
                              <p>Valor fixo</p>
                            </S.ContainerRadio>
                          </Box>
                        </S.ContainerValueVariable>
                        {isVariableValue ? (
                          <Box sx={{ marginLeft: '1rem' }}>
                            <>
                              <S.ContainerPercentageVariable>
                                <span>
                                  Informe a porcentagem de corretagem que será
                                  transferido para a franquia
                                  <Tooltip
                                    info
                                    title="Essa porcentagem deve ser referente ao montante que será transferido para a franquia sobre o valor total."
                                    className="TooltipCheckout"
                                  >
                                    <FiInfo />
                                  </Tooltip>
                                </span>
                                <FormikPercentageInput
                                  number
                                  width="9.375rem"
                                  name="splits.value"
                                  label=""
                                  value={values.splits.value}
                                  placeholder="0%"
                                  validateOnChange={false}
                                  onBlur={handleBlur}
                                  status={
                                    values.splits.value >= 40 &&
                                    values.splits.value <= 70
                                      ? 'warning'
                                      : 'default'
                                  }
                                  onValueChange={(value) => {
                                    setFieldValue('splits.value', value);
                                  }}
                                  warningMessage="O valor informado é relativamente alto. Verifique se esse é o valor realmente acordado."
                                />
                              </S.ContainerPercentageVariable>
                            </>
                          </Box>
                        ) : (
                          isVariableValue === false && (
                            <Box sx={{ marginLeft: '1rem' }}>
                              <S.ContainerPercentageVariable>
                                <span>
                                  Informe o valor de corretagem que será
                                  transferido para a franquia
                                  <Tooltip
                                    info
                                    title="Esse valor deve ser referente ao montante que será transferido para a franquia sobre o valor total."
                                    className="TooltipCheckout"
                                  >
                                    <FiInfo />
                                  </Tooltip>
                                </span>
                                <FormikCurrencyInput
                                  width="8rem"
                                  onValueChange={(value) => {
                                    setFieldValue('splits.value', value);
                                  }}
                                  value={values.splits.value}
                                />
                              </S.ContainerPercentageVariable>
                            </Box>
                          )
                        )}
                      </div>
                    </S.SectionGenerate>
                    <S.SectionGenerate>
                      <Headline variant="h5">
                        <FiCreditCard />
                        Habilitar formas de pagamento
                      </Headline>
                      <S.ContainerProductType>
                        <S.ContainerCheckbox
                          color={pixChecked ? '1px solid #00C49A' : ''}
                        >
                          <input
                            type="checkbox"
                            name="paymentMethods"
                            value="pix"
                            checked={values.paymentMethods.includes('pix')}
                            onChange={(e) => {
                              const { checked } = e.target;
                              if (values.paymentMethods[0] === '') {
                                values.paymentMethods.splice(0, 1);
                              }
                              const valueIncluded =
                                values.paymentMethods.includes('pix');
                              if (valueIncluded && !checked) {
                                setPixCheck(false);
                                const position = values.paymentMethods.indexOf(
                                  e.target.value,
                                );
                                values.paymentMethods.splice(position, 1);
                              }
                              if (!valueIncluded && checked) {
                                setPixCheck(true);
                                setFieldValue('paymentMethods', [
                                  ...values.paymentMethods,
                                  e.target.value,
                                ]);
                              }
                            }}
                          />
                          <Headline variant="h5">PIX</Headline>
                        </S.ContainerCheckbox>
                        <S.ContainerCheckbox
                          color={boletoChecked ? '1px solid #00C49A' : ''}
                        >
                          <input
                            type="checkbox"
                            name="paymentMethods"
                            value="bank_slip"
                            checked={values.paymentMethods.includes(
                              'bank_slip',
                            )}
                            onChange={(e) => {
                              const { checked } = e.target;
                              if (values.paymentMethods[0] === '') {
                                values.paymentMethods.splice(0, 1);
                              }
                              const valueIncluded =
                                values.paymentMethods.includes('bank_slip');
                              if (valueIncluded && !checked) {
                                setBoletoCheck(false);
                                const position = values.paymentMethods.indexOf(
                                  e.target.value,
                                );
                                values.paymentMethods.splice(position, 1);
                              }
                              if (!valueIncluded && checked) {
                                setBoletoCheck(true);
                                setFieldValue('paymentMethods', [
                                  ...values.paymentMethods,
                                  e.target.value,
                                ]);
                              }
                            }}
                          />
                          <Headline variant="h5">Boleto</Headline>
                        </S.ContainerCheckbox>
                      </S.ContainerProductType>
                    </S.SectionGenerate>
                  </Form>
                </S.ModalBody>
                <S.ModalFooter>
                  <Button
                    variant="primary"
                    color="green"
                    size="default"
                    onClick={async () => {
                      setIsOpenResume(true);
                      setIsShown(false);
                    }}
                    disabled={!(isValid && dirty)}
                  >
                    Prosseguir para tela de resumo
                  </Button>
                  <Button
                    variant="secondary"
                    color="red"
                    size="default"
                    onClick={() => {
                      setFieldValue('payableWith', ['pix', 'bank_slip']);
                      setFieldValue('value', 0);
                      setBoletoCheck(true);
                      setFieldValue('splits.value', 0);
                      setFieldValue('splits.value', 0);
                      setPixCheck(true);
                      setIsShown(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </S.ModalFooter>
              </S.ModalWrapper>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
}
