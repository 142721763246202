import { ReactNode } from 'react';
import { MdRemoveCircleOutline } from 'react-icons/md';
import { Input, Paragraph } from '../../../../LegacyV4Components';

import {
  CustomerIntegrationsContextData,
  CustomerIntegrationsProvider,
} from '../../../../../hooks/customerIntegrations';

import { TieAccountsModal } from '../TieAccountsModal';

import { vtex as options } from '../platforms';

import * as Edit from '../components/IntegrationEditRow/styles';

interface VtexModalProps {
  show: boolean;
  onClose: () => void;
}

export function ConfigVtexModal({ show, onClose }: VtexModalProps) {
  const tableColumns = [
    {
      accessor: 'customer',
      label: 'Cliente',
      sizing: 25,
      sizingUnit: 'vw',
      sortable: true,
    },
    {
      accessor: 'accountName',
      label: 'Nome da conta',
      sizing: 1,
      sortable: true,
    },
    {
      accessor: 'integrationId',
      label: 'Chave do app',
      sizing: 1,
      sortable: true,
    },
    {
      accessor: 'appToken',
      label: 'Chave secreta',
      sizing: 1,
      sortable: true,
    },
    {
      accessor: 'actions',
      label: '',
      sizing: 0.075,
      sortable: false,
    },
    {
      accessor: 'status',
      label: 'Status',
      sizing: 0.75,
      sortable: false,
    },
  ];

  function isConnected(integration: AdsAccounts): boolean {
    return (
      !!integration.accountName &&
      !!integration.appToken &&
      !!integration.projectId &&
      !integration.isDeleted
    );
  }

  function additionalValidation(integration: AdsAccounts): boolean {
    return isConnected(integration);
  }

  function renderEditRow(
    integration: AdsAccounts,
    error: boolean,
    context: CustomerIntegrationsContextData,
  ): ReactNode {
    const { setIntegrationId, setAccountName, setAppToken, deleteIntegration } =
      context;
    const linkError = error && !integration.accountName;
    const integrationError = error && !integration.integrationId;
    const appTokenError = error && !integration.appToken;
    return (
      <>
        <Edit.Row id={integration.accountName}>
          <div title={integration.accountName}>
            <Input
              label=""
              value={integration.accountName}
              name="accountName"
              onChange={({ target: { value } }) =>
                setAccountName(integration, value)
              }
              borderColor={linkError ? 'var(--error)' : ''}
            />
            {linkError && <Edit.Error>Nome da conta inválido</Edit.Error>}
          </div>
        </Edit.Row>
        <Edit.Row id={integration.integrationId}>
          <div title={integration.integrationId}>
            <Input
              label=""
              value={integration.integrationId}
              name="integrationId"
              onChange={({ target: { value } }) =>
                setIntegrationId(integration, value)
              }
              className={integrationError ? 'error' : ''}
            />
            {integrationError && <Edit.Error>ID inválido</Edit.Error>}
          </div>
        </Edit.Row>
        <Edit.Row id={integration.appToken}>
          <div title={integration.appToken}>
            <Input
              label=""
              value={integration.appToken}
              name="appToken"
              onChange={({ target: { value } }) =>
                setAppToken(integration, value)
              }
              className={appTokenError ? 'error' : ''}
            />
            {appTokenError && <Edit.Error>Chave inválida</Edit.Error>}
          </div>
        </Edit.Row>
        <Edit.Row>
          <MdRemoveCircleOutline
            size={22}
            onClick={() => deleteIntegration(integration)}
          />
        </Edit.Row>
      </>
    );
  }

  function renderSummaryRow(integration: AdsAccounts): ReactNode {
    return (
      <>
        <td title={integration.accountName}>
          <Paragraph>{integration.accountName}</Paragraph>
        </td>
        <td title={integration.integrationId}>
          <Paragraph>{integration.integrationId}</Paragraph>
        </td>
        <td title={integration.appToken}>
          <Paragraph>{integration.appToken}</Paragraph>
        </td>
        <td aria-label="default" />
      </>
    );
  }

  return (
    <CustomerIntegrationsProvider type={options.type} show={show}>
      <TieAccountsModal
        options={options}
        show={show}
        onClose={onClose}
        tableColumns={tableColumns}
        renderEditRow={renderEditRow}
        renderSummaryRow={renderSummaryRow}
        isConnected={isConnected}
        additionalValidation={additionalValidation}
      />
    </CustomerIntegrationsProvider>
  );
}
