import styled from 'styled-components';

export const Container = styled.div<{
  show: boolean;
  height?: string;
  width?: string;
}>`
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 30;

  display: ${({ show }) => (show ? 'flex' : 'none')};
`;

export const ModalBase = styled.div<{
  size: 'sm' | 'md' | 'lg';
  height?: string;
  width?: string;
  mhModalBase?: string;
}>`
  max-width: ${({ size, width }) => {
    switch (size) {
      case 'sm':
        return width || '500px';
      case 'md':
        return width || '700px';
      case 'lg':
        return width || '80vw';
      default:
        return width || '700px';
    }
  }};

  height: ${({ height }) => height || 'auto'};
  max-height: 98vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  background: var(--white);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;

  main {
    padding: 1rem;
  }

  @media (max-width: 450px) {
    height: 100vh;
    max-width: 100vw;
    max-height: ${({ mhModalBase }) => mhModalBase};
    border-radius: 0;
  }
`;

export const ModalContent = styled.div<{
  zIndex?: string;
  maxHeightModal?: string;
}>`
  position: relative;
  overflow: auto;
  max-height: ${({ maxHeightModal }) => maxHeightModal || '100vh'};
  z-index: ${({ zIndex }) => zIndex};
  flex-grow: 1;

  @media (max-width: 450px) {
    min-height: 100vh;
  }
`;

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const ModalTextHeader = styled.header`
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.header<{
  boxShadow?: string;
  mtModalHeader?: string;
  ptModalHeader?: string;
  visibleHeader?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: ${({ boxShadow }) =>
    boxShadow || '0px 4px 14px rgba(0, 0, 0, 0.05)'};
  padding: 2rem;
  display: ${({ visibleHeader }) => visibleHeader === false && 'none'};

  h3 {
    font-family: 'Inter', sans-serif !important;
    font-size: 1.5rem;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 2.5rem;
    display: flex;
    align-items: center;
  }

  p {
    font-family: 'Inter', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 0.5rem;
  }

  svg {
    cursor: pointer;
    color: var(--primary-dark-1);
    height: 1rem;
    width: 1rem;
    margin-left: 1rem;
  }

  @media (max-width: 450px) {
    align-items: center;
    justify-content: space-between;
    padding: ${({ ptModalHeader }) => ptModalHeader || '1.5rem'};
    margin-top: ${({ mtModalHeader }) => mtModalHeader};
    h3 {
      font-size: 1rem;
      font-weight: 600;
      white-space: normal;
      line-height: 1.5rem;
      padding: 1rem;
    }
    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  .arrowLeftButton {
    margin-top: 1rem;
    margin-right: 1.2rem;

    :active {
      opacity: 0.8;
    }
  }
`;

export const ModalFooter = styled.footer<{
  visibleButtonFooter?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 2rem;
  box-shadow: 0px -4px 8px 3px rgba(0, 0, 0, 0.06),
    0px -1px 3px rgba(0, 0, 0, 0.12);

  button:nth-child(1) {
    visibility: ${({ visibleButtonFooter }) => visibleButtonFooter};
  }
`;

export const ReturnIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const LeftModalContent = styled.div<{
  alignItemContentHeader?: string;
}>`
  display: flex;
  align-items: ${({ alignItemContentHeader }) => alignItemContentHeader};
`;

export const ContainerHeader = styled.div`
  display: flex;
`;

export const BackButton = styled.button`
  width: 100%;
`;
