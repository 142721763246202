import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 0rem;
  border-radius: 0.3rem;
  border-top-left-radius: 0;
  width: 68%;
  @media (max-width: 880px) {
    width: auto;
  }
  .title {
    margin-bottom: 40px;
  }
`;

export const TableInfo = styled.div`
  width: 100%;
  background: var(--white);
  border-radius: 0.25rem;
  border: 1px solid var(--grayscale-6);
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .containerContent {
    display: flex;
    align-items: center;
    @media (max-width: 880px) {
      flex-direction: column;
      gap: 1.5rem;
    }
  }
  .mobileContentValue {
    @media (max-width: 880px) {
      display: flex;
      h1 {
        font-size: 22px;
      }
      .valueContent {
        margin-right: 0px;
      }
    }
  }
  .descriptionContent {
    margin-right: 2.5rem;
  }
  .valueContent {
    margin-right: 2rem;
  }
  input[type='number'] {
    border: 0.5px solid #dadae7;
    border-radius: 0.125rem;
    width: 2.5rem;
    height: 1.5rem;
    border: none;
    margin-right: 0px;
    padding-left: 0px;
    text-align: center;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
  p {
    color: #333333;
    font-size: 1rem;
    font-weight: 400;
  }
  .ContainerSelectNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.625rem;
    border-radius: 0.125rem;
  }
  .ContainerInstallments {
    display: flex;
  }

  &.selected {
    & .radio {
      border-color: var(--success-2);

      &::after {
        background-color: var(--success-2);
      }
    }
  }
`;

export const CrescentNumber = styled.button`
  width: 1.875rem;
  cursor: pointer;
  height: 1.25rem;
  border: 0.5px solid #e6e6ef;
  border-radius: 3px;
  background: #fff;
  svg {
    width: 0.5rem;
  }
`;
export const DecrescentNumber = styled.button`
  width: 1.875rem;
  cursor: pointer;
  height: 1.25rem;
  border: none;
  border: 0.5px solid #e6e6ef;
  border-radius: 3px;
  background: #fff;
  svg {
    width: 0.5rem;
  }
`;
