import styled from 'styled-components';

export const Headline = styled.div`
  font-family: 'Inter';
  font-weight: 900;

  &.h1 {
    font-size: 4.25rem;
    line-height: 5rem;
  }
  &.h2 {
    font-size: 3.25rem;
    line-height: 4rem;
  }
  &.h3 {
    font-size: 2.375rem;
    line-height: 3rem;
  }
  &.h4 {
    font-size: 1.75rem;
    line-height: 2.5rem;
  }
  &.h5 {
    font-size: 1.375rem;
    line-height: 2rem;
  }
  &.h6 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;
