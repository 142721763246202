import React from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbItem } from './InDevelopment.types';

import * as S from './styles';

interface BreadcrumbProps {
  breadcrumbs: BreadcrumbItem[];
  containerStyle?: React.CSSProperties;
}

export function Breadcrumb({ breadcrumbs, containerStyle }: BreadcrumbProps) {
  return (
    <S.Container className="breadcrumb" style={containerStyle}>
      {breadcrumbs.map((breadcrumb) => (
        <div className="breadcrumb-content">
          {breadcrumb.redirect ? (
            <a href={breadcrumb.redirect}>
              <p>/</p>
              <span>{breadcrumb.title}</span>
            </a>
          ) : (
            <Link key={breadcrumb.link} to={breadcrumb.link}>
              <p>/</p>
              <span>{breadcrumb.title}</span>
            </Link>
          )}
        </div>
      ))}
    </S.Container>
  );
}
