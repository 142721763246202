import * as Yup from 'yup';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  FiCreditCard,
  FiFile,
  FiMapPin,
  FiShoppingCart,
  FiUser,
} from 'react-icons/fi';

import { parseCookies } from 'nookies';
import { Headline } from '../../components/LegacyV4Components';
import v4CompanyLogo from '../../assets/logos/v4-company-logo.svg';
import { AboutYou } from '../../components/AtomicDesign/templates/AboutYou';
import { MethodPayment } from '../../components/AtomicDesign/templates/MethodPayment';
import { ResumePayment } from '../../components/AtomicDesign/templates/ResumePayment';
import { AddressPayment } from '../../components/AtomicDesign/templates/AddressPayment';
import { convertNumberToBRL } from '../../utils/convertNumberToBRL';
import { CustomerFinishPayment } from '../../components/AtomicDesign/templates/CustomerFinishPayment';
import { createPaymentProps } from '../../services/requests/Finance/createPayment';

import * as S from './styles';
import { ModalCookies } from '../../components/AtomicDesign/molecules/ModalCookies';
import { ModalAcceptedCookies } from '../../components/AtomicDesign/molecules/ModalAcceptedCookies';
import { ModalFieldsIncomplete } from '../../components/AtomicDesign/molecules/ModalFieldsIncomplete';
import { useGetDepositById } from '../../services/requests/MediaWallet/getDeposit';

export interface FormikPaymentOptions {
  recipientId: string;
  paymentMethod: string;
  name: string;
  email: string;
  phone: string;
  cpf_cnpj: string;
  address: {
    district: string;
    number: null;
    street: string;
    zip_code: string;
    complement: string;
  };
  cardName?: string;
  parcelas?: string;
}

export function WalletPaymentOptions() {
  const [isLoading, setIsLoading] = useState(false);
  const [indexTab, setIndexTab] = useState<number>(1);
  const [paymentIsCreate, setPaymentIsCreate] = useState(false);
  const [selectMethod, setSelectMethod] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(true);
  const [fieldsIncomplete, setFieldsIncomplete] = useState(false);
  const [isCookiesAccepted, setIsCookiesAccepted] = useState<string>();
  const [responsePayment, setResponsePayment] = useState(
    {} as createPaymentProps,
  );
  const { id } = useParams<{ id: string }>();

  function ValidateCPF(cpf: any) {
    const cpfAux = cpf.replace(/[\s.-]*/gim, '');
    let soma = 0;
    let resto;
    if (
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    )
      return false;
    for (let i = 1; i <= 9; i += 1)
      soma += parseInt(cpfAux.substring(i - 1, i), 10) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpfAux.substring(9, 10), 10)) return false;
    soma = 0;
    for (let i = 1; i <= 10; i += 1)
      soma += parseInt(cpfAux.substring(i - 1, i), 10) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpfAux.substring(10, 11), 10)) return false;
    return true;
  }

  const configBankSchema = Yup.object().shape({
    name: Yup.string().required('O campo é obrigatório!'),
    email: Yup.string()
      .email('E-mail inválido')
      .required('Formato de e-mail inválido.'),
    phone: Yup.string()
      .min(11, 'Número inválido')
      .required('Insira seu número'),
    cpf_cnpj: Yup.string()
      .required('Insira seu cpf')
      .test((value) => ValidateCPF(value))
      .nullable(),
    cardName: Yup.number()
      .max(70, 'O valor da porcentagem de comissão não pode ser maior que 70%')
      .required('O campo é obrigatório!'),
    paymentMethod: Yup.array().required('O campo é obrigatório!'),
    address: Yup.object().shape({
      district: Yup.string().required('O campo é obrigatório!'),
      number: Yup.number().required('O campo é obrigatório'),
      street: Yup.string().required('O campo é obrigatório!'),
      zip_code: Yup.string().required('O campo é obrigatório!'),
      complement: Yup.string().required('O campo é obrigatório!'),
    }),
  });

  const { data: product } = useGetDepositById(id);

  const customerHexColor = '#00c49a';

  useEffect(() => {
    const { 'v4company.cookiesAccepted': cookiesAccepted } = parseCookies();

    if (!cookiesAccepted) {
      setIsOpenModal(true);
      return;
    }
    setIsOpenModal(false);
    setIsCookiesAccepted(cookiesAccepted);
  }, []);
  return (
    <>
      <Formik
        initialValues={{
          paymentMethod: 'pix',
          name: '',
          email: '',
          phone: '',
          cpf_cnpj: '',
          address: {
            district: '',
            number: null,
            street: '',
            zip_code: '',
            complement: '',
          },
        }}
        validationSchema={configBankSchema}
        onSubmit={async () => {
          setIndexTab(indexTab + 1);
        }}
      >
        {({ errors, values, handleBlur, setFieldValue, touched }) => (
          <>
            <S.Container>
              <S.Navbar>
                {indexTab !== 5 ? (
                  <>
                    <S.ContainerTabs>
                      <section
                        style={{
                          border: `${
                            indexTab === 1 && `1px solid ${customerHexColor}`
                          }`,
                          background: `${
                            indexTab === 1 ? `#fff` : `${customerHexColor}`
                          }`,
                        }}
                        aria-hidden
                        className={`${
                          (indexTab === 1 && `selected`) ||
                          (indexTab !== 1 && 'NotSelected')
                        }`}
                        key={1}
                        onClick={() => {
                          if (
                            values.name &&
                            !errors.name &&
                            !errors.email &&
                            values.email &&
                            values.cpf_cnpj &&
                            !errors.cpf_cnpj &&
                            values.phone
                            // !errors.tel
                          ) {
                            indexTab === 1 && setIndexTab(1);
                            indexTab === 2 && setIndexTab(indexTab - 1);
                          }
                        }}
                      >
                        <FiUser
                          color={
                            indexTab === 1 ? `${customerHexColor}` : '#333333'
                          }
                        />
                        <span>Sobre você</span>
                      </section>
                    </S.ContainerTabs>
                    <S.ContainerTabs>
                      <section
                        style={{
                          border: `${
                            indexTab === 2
                              ? `1px solid ${customerHexColor}`
                              : '1px solid #dadae7'
                          }`,
                          background: `${
                            (indexTab === 2 && `#fff`) ||
                            (indexTab > 2 && `${customerHexColor}`)
                          }`,
                        }}
                        aria-hidden
                        className={`${
                          (indexTab === 2 && `selected`) ||
                          (indexTab > 2 && 'NotSelected')
                        }`}
                        key={2}
                        onClick={() => {
                          if (
                            values.name &&
                            !errors.name &&
                            !errors.email &&
                            values.email &&
                            values.cpf_cnpj &&
                            !errors.cpf_cnpj &&
                            values.phone
                            // !errors.tel
                          ) {
                            indexTab === 1 && setIndexTab(2);
                            indexTab === 3 && setIndexTab(indexTab - 1);
                          }
                        }}
                      >
                        <FiMapPin
                          color={indexTab === 2 ? `${customerHexColor}` : ''}
                        />
                        <span>Endereço de cobrança</span>
                      </section>
                    </S.ContainerTabs>
                    <S.ContainerTabs>
                      <section
                        style={{
                          border: `${
                            indexTab === 3
                              ? `1px solid ${customerHexColor}`
                              : '1px solid #dadae7'
                          }`,
                          background: `${
                            (indexTab === 3 && `#fff`) ||
                            (indexTab > 3 && `${customerHexColor}`)
                          }`,
                        }}
                        aria-hidden
                        className={`${
                          (indexTab === 3 && `selected`) ||
                          (indexTab > 3 && 'NotSelected')
                        }`}
                        key={3}
                        onClick={() => {
                          if (
                            !errors.address?.zip_code &&
                            !errors.address?.complement &&
                            !errors.address?.district &&
                            !errors.address?.number &&
                            !errors.address?.street
                          ) {
                            indexTab === 2 && setIndexTab(3);
                            indexTab === 4 && setIndexTab(indexTab - 1);
                          }
                        }}
                      >
                        <FiCreditCard
                          color={indexTab === 3 ? `${customerHexColor}` : ''}
                        />
                        <span>Pagamento</span>
                      </section>
                    </S.ContainerTabs>
                    <S.ContainerTabs>
                      <section
                        style={{
                          border: `${
                            indexTab === 4
                              ? `1px solid ${customerHexColor}`
                              : '1px solid #dadae7'
                          }`,
                          background: `${
                            indexTab > 4 && `${customerHexColor}`
                          }`,
                        }}
                        aria-hidden
                        className={`${
                          (indexTab === 4 && `selected`) ||
                          (indexTab > 4 && 'NotSelected')
                        }`}
                        key={4}
                        onClick={() => {
                          indexTab === 3 && setIndexTab(4);
                        }}
                      >
                        <FiFile
                          color={indexTab === 4 ? `${customerHexColor}` : ''}
                        />
                        <span>Resumo</span>
                      </section>
                    </S.ContainerTabs>
                  </>
                ) : null}
              </S.Navbar>

              <S.Body theme={indexTab === 5 ? 'center' : 'space-between'}>
                {indexTab !== 5 ? (
                  <S.RequestsMobile
                    color={indexTab === 4 ? 'none' : 'block'}
                    style={{ border: `1px solid ${customerHexColor}` }}
                  >
                    <div className="your-request">
                      <FiShoppingCart />
                      <Headline variant="h5">Resumo da fatura</Headline>
                    </div>
                    <section>
                      <div className="your-product">
                        <p>Valor total</p>
                        <p>
                          {product?.data
                            ? convertNumberToBRL(product?.data?.ammount / 100)
                            : ''}
                        </p>
                      </div>
                    </section>
                  </S.RequestsMobile>
                ) : null}

                {indexTab === 1 && (
                  <AboutYou
                    touched={touched}
                    setFieldValue={setFieldValue}
                    indexTab={indexTab}
                    setIndexTab={setIndexTab}
                    setFieldsIncomplete={setFieldsIncomplete}
                    values={values}
                    errors={errors}
                    handleBlur={handleBlur}
                    mediaWallet
                  />
                )}

                {indexTab === 2 && (
                  <AddressPayment
                    touched={touched}
                    indexTab={indexTab}
                    setIndexTab={setIndexTab}
                    setFieldValue={setFieldValue}
                    setFieldsIncomplete={setFieldsIncomplete}
                    values={values}
                    errors={errors}
                    handleBlur={handleBlur}
                    mediaWallet
                  />
                )}

                {indexTab === 3 && (
                  <MethodPayment
                    indexTab={indexTab}
                    setIndexTab={setIndexTab}
                    setFieldValue={setFieldValue}
                    values={values}
                    selectMethod={selectMethod}
                    setSelectMethod={setSelectMethod}
                    product={product?.data || undefined}
                  />
                )}

                {indexTab === 4 && (
                  <ResumePayment
                    data={product?.data}
                    indexTab={indexTab}
                    setIndexTab={setIndexTab}
                    values={values}
                    setPaymentIsCreate={setPaymentIsCreate}
                    setResponsePayment={setResponsePayment}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    selectMethod={selectMethod}
                    customerHexColor={customerHexColor}
                    mediaWallet
                    walletId={id}
                  />
                )}

                {indexTab === 5 && paymentIsCreate ? (
                  <CustomerFinishPayment
                    responsePayment={responsePayment}
                    values={values}
                    data={product?.data}
                    mediaWallet
                  />
                ) : null}

                {indexTab !== 5 ? (
                  <S.RequestsDesktop
                    color={indexTab === 4 ? 'none' : 'block'}
                    style={{ border: `1px solid ${customerHexColor}` }}
                  >
                    <div className="your-request">
                      <FiShoppingCart />
                      <Headline variant="h5">Resumo da fatura</Headline>
                    </div>
                    <section>
                      <div className="your-product">
                        <p>Valor total</p>
                        <p>
                          {product?.data
                            ? convertNumberToBRL(product?.data?.ammount / 100)
                            : ''}
                        </p>
                      </div>
                    </section>
                  </S.RequestsDesktop>
                ) : null}
              </S.Body>

              <S.Footer>
                <p>
                  Desenvolvido por: <img src={v4CompanyLogo} alt="" />
                </p>
                <span>2022 © Todos os direitos reservados. V4 Company</span>
              </S.Footer>
            </S.Container>
          </>
        )}
      </Formik>
      {!isOpenModal ? (
        <S.Cookie>
          <ModalCookies />
          {/* <BiCookie size={33} /> */}
        </S.Cookie>
      ) : null}
      {!isCookiesAccepted ? (
        <ModalAcceptedCookies
          show={isOpenModal}
          onClose={() => setIsOpenModal(false)}
        />
      ) : null}
      {fieldsIncomplete ? (
        <ModalFieldsIncomplete
          isOpen={fieldsIncomplete}
          setIsOpen={setFieldsIncomplete}
        />
      ) : null}
    </>
  );
}
