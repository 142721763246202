import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
  max-height: calc(100vh - 2rem);
  width: 55vw;

  border-radius: 8px;
  background-color: var(--white);
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 27px 40px 29px 40px;

  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);

  & > button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 1rem;
    height: 1rem;

    background: transparent;
    border: none;
    color: #83834f;

    & > svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;

  padding: 40px;

  overflow: auto;

  & table {
    tr {
      height: 3.5rem;

      border-bottom: 1px solid #afafaf;
      border-radius: 0;

      p.color-error {
        color: var(--error);
      }

      &:hover {
        background-color: initial;
        border-color: transparent;
        border-bottom-color: #afafaf;
        box-shadow: none;
      }
    }

    & tbody tr .text-wrapper {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    & tbody tr svg {
      cursor: pointer;
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  padding: 1.75rem 2.5rem;
`;

export const ContainerProductType = styled.div`
  display: flex;
  margin-top: 1.5rem;
`;
export const ContainerRadio = styled.div`
  border: ${(props) => props.color};
  display: flex;
  margin-right: 16px;
  justify-content: space-evenly;
  align-items: center;
  width: 132px;
  height: 48px;
  border-radius: 4px;
  padding: 16px 32px;
  background: #f6f6f6;

  input {
    appearance: none;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    outline: none;
    transition: border-color 0.2s;
    position: relative;
    background: #fff;
    border: 1px solid #8383af;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: #fff;
    }
  }

  input:checked {
    border: 1px solid #00c49a;
    background-color: #fff;

    &::before {
      background-color: #00c49a;
    }
  }

  p {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const ContainerLoading = styled.div`
  svg {
    width: 1rem;
    height: 1rem;
  }
  span {
    width: 1rem !important;
    height: 1rem !important;
  }
`;
