import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  margin: 0 1rem;
  border-radius: 0.5rem;
  background: white;

  > section {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;

    > span {
      font-weight: bold;
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      line-height: 25px;
      color: var(--secondary-light);
    }
  }
`;

export const Error = styled.span`
  font-size: 1rem;
  color: var(--error);
`;

export const Info = styled.div`
  min-width: 50%;
  height: 80px;
  margin-left: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  span {
    text-align: right;
  }
  .disabled {
    color: var(--tertiary-dark);
  }
`;

export const ErrorInput = styled.samp`
  font-family: 'Museo Sans', sans-serif;
  font-size: 12px;
  color: red;
`;

export const SuccessInput = styled.samp`
  font-family: 'Museo Sans', sans-serif;
  font-size: 12px;
  color: #00c49a;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  button {
    :first-of-type {
      margin-right: 1rem;
    }
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1120px;
  width: 100%;
  padding: 3rem 4rem;
  margin: 0 1rem;
  border-radius: 0.5rem;
  background: var(--white);
  h2 {
    font-size: 1.5rem;
    margin: 40px 0;
  }
`;

export const SuccessButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  button {
    white-space: nowrap;
    width: auto;
    padding: 1rem 2rem;
    :first-of-type {
      margin-right: 1rem;
    }
  }
`;

export const Loading = styled.div`
  width: 400px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    span:first-child {
      margin-right: 0.5rem;
    }
  }
  .TooltipCheckout {
    span {
      margin-left: -110px;
    }
  }
`;

export const ContentRow = styled.div`
  display: flex;
  margin-bottom: 25px;
  margin-top: 25px;
  justify-content: center;
  align-items: center;
`;

export const InvestorInfoRow = styled.div`
  display: flex;
  gap: 1rem;

  margin-bottom: 20px;
`;

export const DocumentInput = styled.div``;
export const Button = styled.div`
  color: var(--success-2);
  padding: 10px 30px;
  border-radius: 0.25rem;
  width: fit-content;

  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover {
    background-color: var(--grayscale-6);
  }
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 8px;
  font-family: 'Inter', 'Nunito Sans';
`;

export const Description = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 24px;
  font-family: 'Inter', 'Nunito Sans';
`;

export const InvestorSelectContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const InputHolder = styled.div`
  > div {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
    align-items: center;
  }

  > div svg {
    min-width: 2rem;
    min-height: 2rem;
    margin-left: 0.5rem;
    color: var(--grayscale-6);
  }

  > div > div {
    padding: 0.5rem;
  }
`;

export const FileList = styled.div`
  width: 100%;
`;

export const File = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 2.5rem;

  margin-bottom: 0.5rem;
  padding: 0 1rem;

  border-radius: 0.4rem;

  background-color: var(--tertiary-main);

  svg {
    cursor: pointer;
  }
`;

export const FileName = styled.p`
  margin: 0 !important;
`;
