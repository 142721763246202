import { kubernetesApi as api } from '../../../api';
import { oportunityInterface } from '../types';

export async function getOportunities(): Promise<
  oportunityInterface[] | false
> {
  try {
    const response = await api.get(
      `/calculator/new-price/opportunities?sort=lastNegotiation`,
    );

    const oportunityResponse = (
      response.data as { opportunities: oportunityInterface[] }
    ).opportunities;

    return oportunityResponse;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o unidade, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
