import styled, { css } from 'styled-components';

export const Row = styled.div<{ disableHover?: boolean }>`
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--terciary-light);
  background: var(--white);
  transition: 0.4s ease all;

  :first-of-type {
    font-size: 16px;
    line-height: 21px;
  }

  a {
    display: flex;
    width: 100%;
    text-decoration: none;
    color: var(--secondary-main);
  }

  :hover {
    background: ${(props) =>
      props.disableHover ? 'var(--grayscale-4)' : 'var(--white)'};
  }
`;

export const Field = styled.div<{ trash?: boolean }>`
  display: flex;
  padding: 1rem;
  align-items: center;

  img {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    width: 2rem !important;
    height: 2rem !important;
    width: auto;
    height: auto;
    background: var(--primary-main);
    margin-right: 0.5rem;
    color: var(--white);
  }

  svg {
    cursor: not-allowed;
    opacity: 30%;
  }

  > a {
    display: flex;
    align-items: center;
    color: var(--black);
    text-decoration: none;
    transition: 0.5s;

    :hover {
      opacity: 0.5;
    }
  }

  ${({ trash }) =>
    trash &&
    css`
      align-items: center;
      justify-content: flex-end;
    `}
`;

export const PicturePlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;

  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background: var(--primary-main);
  color: var(--white);

  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 0.75rem;
`;
