import { FiX } from 'react-icons/fi';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { useState } from 'react';
import { LabDsButton } from 'v4web-components-react';
import { Headline, Modal } from '../../../../components/LegacyV4Components';
import * as S from './styles';
import { useToast } from '../../../../hooks/toast';
import { unassignInvestor } from '../../../../services/requests/User/CapacityManage/unassignInvestor';

export function RemoveInvestorModal({
  isShown,
  setIsShown,
  customerId,
  userId,
}: {
  isShown: boolean;
  setIsShown(value: boolean): void;
  customerId: string;
  userId: string;
}) {
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  async function removeInvestorFromCustomer() {
    setIsLoading(true);
    const response = await unassignInvestor(userId, customerId);
    setIsLoading(false);
    if (response) {
      addToast({
        type: 'success',
        title: '',
        description: 'Investidor removido com sucesso!',
      });
      setIsShown(false);
    } else {
      addToast({
        type: 'error',
        title: 'Erro adicionar investidor no projeto.',
        description: 'Erro ao adicionar investidor!',
      });
    }
  }

  return (
    <>
      <Modal
        show={isShown}
        onBackground={() => {
          setIsShown(false);
        }}
      >
        <S.Content>
          <S.Row>
            <button
              type="button"
              onClick={() => {
                setIsShown(false);
              }}
              aria-label="default"
            >
              <FiX />
            </button>
          </S.Row>

          <>
            <S.Content>
              <IoCloseCircleOutline size={80} color="var(--error)" />
              <Headline variant="h4">Você tem certeza?</Headline>
            </S.Content>
            <S.Footer>
              <LabDsButton
                color="red"
                onClick={() => {
                  setIsShown(false);
                }}
                label="Cancelar"
                variant="danger-outlined"
                disabled={isLoading}
              />
              <LabDsButton
                color="green"
                loading={isLoading}
                label="Remover investidor"
                onClick={removeInvestorFromCustomer}
                variant="primary"
              />
            </S.Footer>
          </>
        </S.Content>
      </Modal>
    </>
  );
}
