import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem 2rem 2rem;
  width: 680px;
  margin-bottom: 1.5rem;

  .info__card {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 15px;

    text-align: center;
  }

  .sub__info__card {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    margin-top: 10px;
    margin-bottom: 25px;
  }

  .margin__top {
    margin-top: 15px;
  }

  h3 {
    margin-top: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  background: var(--white);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1), 0px 7px 32px rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;

  @media (max-width: 1225px) {
    max-width: 100%;
  }
`;

export const ProgressContent = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 20px;
  border-radius: 20px;

  background-color: #c1c1d7;
`;

export const ProgressBar = styled.div<{ porc: string }>`
  width: ${({ porc }) => porc};
  height: 20px;
  border-radius: 20px;
  background-color: #00c49a;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const RowLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  div :first-child {
    margin-right: 1rem;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  .Wage {
    z-index: 0;
  }
`;
