import styled from 'styled-components';

export const Row = styled.div`
  padding: 1rem;
`;

export const Content = styled.div`
  width: 100%;
  padding: 1rem;
`;

export const Cards = styled.div`
  display: flex;

  flex-flow: wrap;

  width: 100%;

  margin-top: 3rem;

  gap: 1rem;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  text-align: center;

  width: 25rem;
  height: 15rem;

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  border: 1px solid #e0e0e0;

  border-radius: 0.5rem;

  padding: 1rem;

  @media (max-width: 1460px) {
    width: 22.5rem;
  }
`;

export const CardTitle = styled.h3``;

export const CardDescription = styled.p`
  width: 80%;
`;
