import { InvestorTeam } from '../../../../hooks/types';
import { usersApi } from '../../../api';

export async function unassignInvestor(
  userId: string,
  customerId: string,
): Promise<InvestorTeam[] | false> {
  try {
    const response = await usersApi.put(`/capacity/manage/unassign/project`, {
      userId,
      customerId,
    });
    const responseData = response.data as InvestorTeam[];

    return responseData;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar esta cobrança, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
