import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  LabDsBreadcrumb,
  LabDsButton,
  LabDsTable,
} from 'v4web-components-react';
import {
  Container,
  Headline,
  Section,
  SectionContent,
} from '../../components/LegacyV4Components';
import { useToast } from '../../hooks/toast';
import {
  CalculateAllUsersCapacityResponse,
  calculateAllUsersCapacity,
} from '../../services/requests/Customers/calculateAllUsersCapacity';

import * as S from './styles';

import { AddInvestorModal } from './Components/AddInvestorModal';
import { RemoveInvestorModal } from './Components/RemoveInvestorModal';
import { UnHappyPath } from '../../components/AtomicDesign/atoms/UnhappyPath';
import {
  useGetClient,
  useGetInvestorsByProjectId,
} from '../../services/requests/Customers/useGetInvestorsByProjectId';
import { useDataTableHistoricList } from './utils/dataTables';

export function ProjectUserAssignedEdit() {
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const [removeInvestor, setRemoveInvestor] = useState<string>('');
  const [userPoints, setUserPoints] = useState<{ [key: string]: number }>({});
  const [modalAddInvestor, setModalAddInvestor] = useState<boolean>(false);
  const [modalRemoveInvestor, setModalRemoveInvestor] =
    useState<boolean>(false);

  const {
    data: investorOnCustomer,
    error: investorError,
    refetch,
  } = useGetInvestorsByProjectId(id || '');

  useEffect(() => {
    if (investorError) {
      addToast({
        title: 'Error fetching investors',
        description: 'An unexpected error occurred',
        type: 'error',
      });
    }
  }, [investorOnCustomer, investorError, addToast]);

  const { data: project, error: projectError } = useGetClient(id || '');
  refetch();

  useEffect(() => {
    if (projectError) {
      setUserPoints({});
      refetch();
    }
    setUserPoints({});
    refetch();
  }, [project, projectError, addToast, refetch]);

  const handleSelectChange = useCallback((userId: string, points: number) => {
    setUserPoints((prevPoints) => ({
      ...prevPoints,
      [userId]: points,
    }));
  }, []);

  const createProductivityOptions = useCallback(
    (
      userProductivityNumber: number,
      maxWorkload: number,
      userInvestorProductivity: number,
      isOnboarding: boolean,
    ) => {
      const workload = isOnboarding ? maxWorkload / 2 : maxWorkload;
      return Array.from({ length: workload }, (_, i) => ({
        label: `${userProductivityNumber * (i + 1)} = ${i + 1} slot`,
        key: `${i + 1}`,
        isChecked: i + 1 === userInvestorProductivity,
      }));
    },
    [],
  );

  const dataTableUserList = useDataTableHistoricList(
    investorOnCustomer || [],
    handleSelectChange,
    createProductivityOptions,
    setModalRemoveInvestor,
    setRemoveInvestor,
  );

  const callCalculateAllUsersCapacity = async (
    userPointsArray: CalculateAllUsersCapacityResponse[],
  ) => {
    const capacity = await calculateAllUsersCapacity(userPointsArray);

    // setUserPoints({});

    if (!capacity) {
      addToast({
        title: 'Erro ao calcular a capacidade dos investidores',
        type: 'error',
      });
    } else {
      addToast({
        title: 'Capacidade dos investidores calculada com sucesso',
        type: 'success',
      });
    }
  };

  const userPointsArray: CalculateAllUsersCapacityResponse[] = Object.entries(
    userPoints,
  ).map(([userId, points]) => ({
    id: userId,
    value: points as number,
    clientId: id,
  }));

  const breadcrumbsItems = [
    {
      label: 'Editar time responsável',
      link: '',
      key: 'Editar time responsável',
      event: () => {
        return;
      },
    },
  ];

  return (
    <>
      <Container>
        <LabDsBreadcrumb breadcrumbs={breadcrumbsItems} />
        <Section>
          <SectionContent>
            <S.Row>
              <Headline variant="h3">Editar time responsável</Headline>
              {!project?.isNewPrice && (
                <LabDsButton
                  variant="link-button"
                  label="Adicionar investidor"
                  leadingIcon="add"
                  onClick={() => {
                    setModalAddInvestor(true);
                    refetch();
                  }}
                />
              )}
            </S.Row>
            {project?.isNewPrice ? (
              <S.Subtitle>
                Esse é um projeto de <b>New Price</b>, por isso algumas posições
                são fixas e devem estar sempre preenchidas.
              </S.Subtitle>
            ) : (
              <S.Subtitle>
                Edite o time responsável, se for preciso acrescente mais
                investidores.
              </S.Subtitle>
            )}
            {(investorOnCustomer?.length as unknown as number) > 0 ? (
              <S.Table>
                <LabDsTable
                  style={{
                    width: '100%',
                    marginBottom: '24rem',
                  }}
                  data={dataTableUserList}
                  actionField
                />
                <S.Buttons>
                  <LabDsButton
                    variant="danger-outlined"
                    label="Cancelar"
                    size="medium"
                    maxWidth
                    onHandleButton={() => refetch()}
                  />
                  <LabDsButton
                    variant="primary"
                    label="Salvar"
                    size="medium"
                    maxWidth
                    onHandleButton={() => {
                      callCalculateAllUsersCapacity(userPointsArray);
                    }}
                  />
                </S.Buttons>
              </S.Table>
            ) : (
              <UnHappyPath
                assetIndex={0}
                title="Nenhum investidor foi adicionado neste projeto"
                descriptionItems={['Adicione investidores no projeto']}
              />
            )}
          </SectionContent>
        </Section>
      </Container>
      <AddInvestorModal
        isShown={modalAddInvestor}
        setIsShown={() => {
          setModalAddInvestor(false);
          refetch();
        }}
        unitId={project?.units[0]._id || ''}
        customerName={project?.projectName || ''}
        customerId={id || ''}
      />
      <RemoveInvestorModal
        isShown={modalRemoveInvestor}
        setIsShown={() => {
          setModalRemoveInvestor(false);
          refetch();
        }}
        customerId={id || ''}
        userId={removeInvestor}
      />
    </>
  );
}
