import styled from 'styled-components';

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Divider = styled.div`
  background: var(--grayscale-20, #b4b4cf);
  width: 0.0625rem;
  flex: 1;
  height: 100%;
`;
export const VerificalDivider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  align-self: stretch;
`;
export const UserBoard = styled.div`
  display: flex;
  padding: 2.5rem;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--tertiary-dark, #bbb);
  width: fit-content;
  margin: auto;
`;
export const Row = styled.div`
  display: flex;
  & > button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 1rem;
    height: 1rem;

    background: transparent;
    border: none;
  }
`;

export const HeaderModal = styled.div`
  p {
    font-size: 1rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 1rem;
  bottom: 1rem;

  lab-ds-button {
    margin-left: 1rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  height: 48rem;

  border-radius: 0.5rem;
  background-color: var(--white);

  border-bottom: 1px;
  padding: 0 2.5rem 2.5rem 2.5rem;

  lab-ds-select {
    margin-bottom: 1rem;
  }
  height: 80vh;
  hr {
    margin: 1.5rem 0;
  }
`;
