import styled, { css } from 'styled-components';

interface InputContainerProps {
  isFocused?: boolean;
  isFilled?: boolean;
  isErrored?: boolean;
}

export const Container = styled.div<InputContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;

  border-radius: 0.5rem;

  label {
    margin-bottom: 8px;
  }

  h6 {
    font-size: 1rem;
    font-family: 'Inter';
    font-weight: bold;
    line-height: 1.5rem;
    margin-bottom: 8px;
  }

  label {
    font-size: 1rem;
    font-weight: bold;

    h4 {
      text-transform: uppercase;
    }
  }

  p {
    font-size: 0.875rem;
    color: var(--grayscale-20);
    margin-bottom: 0.5rem;
  }

  small {
    color: var(--primary-main);
    bottom: 0;
    margin: 0.25rem;
  }

  ${(props) =>
    props.isErrored &&
    css`
      label {
        color: var(--primary-main);
      }
      border-color: var(--error);
    `}

  ${(props) =>
    props.isFilled &&
    css`
      label {
        color: var(--black);
      }
      border-color: var(--tertiary-dark);
    `}
`;

export const SelectContainer = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;
  background: var(--white);
  border: 1px solid #e6e6ef;
  color: var(--secondary-light);
  border-radius: 3px;
  height: 48px;
  transition: 0.5s;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.04));

  select {
    border: none;
    width: 100%;
    padding: 0rem 0 0rem 1rem;
    cursor: pointer;
    margin-right: 1rem;
    font-family: 'Nunito Sans';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #333333;
  }

  & + div {
    margin-top: 1rem;
  }

  svg {
    margin-left: 1rem;
  }

  p {
    margin-right: 0.5rem;
    color: var(--white);
  }

  ${(props) =>
    props.isErrored &&
    css`
      color: var(--error);
      border-color: var(--error);
    `}

  input:-webkit-autofill::first-line,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: var(--secondary-light) !important;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
  }
`;

export const Error = styled.small`
  color: var(--error) !important;
  line-height: 1rem;

  margin-top: 1rem !important;
  font-size: 0.75rem;
`;
