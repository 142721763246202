import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Container,
  Section,
  SectionContent,
} from '../../components/LegacyV4Components';
import { useAuth } from '../../hooks/auth';
import { UserCard } from '../../components/AtomicDesign/organisms/UserCard';
import { CustomLoading } from '../../components/Default/Loadings/CustomLoading';
import { User } from '../../hooks/types';
import { getUser } from '../../services/requests/User/getUser';

export function Profile() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{
    id: string;
    origin: string;
    url: string;
    name: string;
  }>();
  const [profileUser, setProfileUser] = useState<User>();
  const { user } = useAuth();

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const setUserInfo = useCallback(async () => {
    setLoading(true);

    if (id) {
      const newUser = await getUser(id);

      if (newUser) {
        setProfileUser(newUser);
      }
    }

    setLoading(false);
  }, [id]);

  useEffect(() => {
    setUserInfo();
  }, [setUserInfo]);

  return (
    <Container>
      {loading ? (
        <CustomLoading />
      ) : (
        <>
          {user._id === profileUser?._id ? (
            <Breadcrumb
              breadcrumbs={[
                { title: 'Home', link: '/' },
                { title: 'Meu Perfil', link: `/perfil/${profileUser?._id}` },
              ]}
            />
          ) : (
            <Breadcrumb
              breadcrumbs={JSON.parse(decodeURI(query.get('origin') as string))}
            />
          )}

          <Section
            style={{
              justifyContent: 'center',
              background: 'transparent',
              boxShadow: 'none',
            }}
          >
            <SectionContent style={{ width: 'auto', display: 'flex' }}>
              {profileUser && <UserCard profileUser={profileUser} />}
            </SectionContent>
          </Section>
        </>
      )}
    </Container>
  );
}
