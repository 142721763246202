import React, { SetStateAction } from 'react';
import ReactPaginate from 'react-paginate';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import * as S from './styles';
import './pagination.css';

interface PaginationProps {
  pageCount: number;
  forcePage: number;
  setCurrentPage: React.Dispatch<SetStateAction<number>>;
}

export function Pagination({
  forcePage,
  pageCount,
  setCurrentPage,
}: PaginationProps) {
  return (
    <S.PaginationContent>
      <ReactPaginate
        previousLabel={<AiFillCaretLeft />}
        nextLabel={<AiFillCaretRight />}
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pageCount}
        onPageChange={(e) => setCurrentPage(e.selected)}
        pageRangeDisplayed={4}
        marginPagesDisplayed={1}
        containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
        hrefAllControls
        forcePage={forcePage}
      />
    </S.PaginationContent>
  );
}
