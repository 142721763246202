import styled from 'styled-components';

export const InputWrapper = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;

  font-family: 'Nunito Sans';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;

  h6 {
    font-size: 1rem;
    font-family: 'Inter';
    font-weight: bold;
    line-height: 1.5rem;
    margin-bottom: 8px;
  }
`;
