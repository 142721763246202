import { User } from '../../../hooks/types';
import { usersApi } from '../../api';

export async function getUser(id: string): Promise<User | false> {
  try {
    const response = await usersApi.get(`/user/read/${id}?showDeleted=true`);
    const user: any = response.data;

    return user;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar  os usuários, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
