import { kubernetesApi as api } from '../../../api';
import { createOportunityInterface, oportunityInterface } from '../types';

export async function deleteOportunity(
  oportuniId: string,
  data: createOportunityInterface,
): Promise<oportunityInterface | false> {
  try {
    const response = await api.put(
      `/calculator/new-price/opportunity/${oportuniId}`,
      data,
    );

    const oportunityResponse = (
      response.data as { opportunity: oportunityInterface }
    ).opportunity;
    return oportunityResponse;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o unidade, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
