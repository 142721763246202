import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 40rem;
  overflow: auto;

  padding: 2rem 2rem;
  width: 100%;

  background-color: var(--white);
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;
export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  display: flex;
  background: var(--white);
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  padding: 1rem;
  width: 33%;

  border: 1px solid #c1c1d7;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 1px 3px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;

  .warn {
    color: var(--grayscale-1);
  }
  .check {
    color: var(--success-2);
  }

  span {
    display: flex;
    align-items: center;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 0.725rem;
    line-height: 16px;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  p {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
  }
`;
export const Header = styled.div`
  h3 {
    margin: 2rem 0;
  }
  p {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 32px;
    margin-bottom: 1rem;
  }
`;
