import { useEffect, useRef, useState, ReactNode } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { Headline, Paragraph } from '../../../LegacyV4Components';

import * as S from './styles';

interface QuestionAccordionProps {
  question: string;
  description: string;
  children: ReactNode;
}

export function QuestionAccordion({
  question,
  description,
  children,
}: QuestionAccordionProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  const openClass = isOpen ? 'open' : '';

  function handleHeaderClick() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    const element = contentRef.current;

    if (element && height === 0) {
      setHeight(isOpen ? element.scrollHeight : 0);
    }
  }, [isOpen, height]);

  return (
    <S.QuestionAccordion>
      <S.QuestionHeader className={openClass} onClick={handleHeaderClick}>
        <S.QuestionTitle className={openClass}>
          <Headline variant="h5">{question}</Headline>
          <Paragraph>{description}</Paragraph>
        </S.QuestionTitle>
        <MdChevronLeft size={24} />
      </S.QuestionHeader>
      <S.QuestionContentWrapper
        ref={contentRef}
        className={openClass}
        height={height}
      >
        <S.QuestionContent>{children}</S.QuestionContent>
      </S.QuestionContentWrapper>
    </S.QuestionAccordion>
  );
}
