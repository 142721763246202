import React from 'react';
import { FiX } from 'react-icons/fi';
import { AiOutlineWhatsApp, AiOutlineMail } from 'react-icons/ai';

import { format } from 'date-fns';
import { Button, Headline, Input } from '../../../LegacyV4Components';
import { Modal } from '../../../Default/Modal';

import * as S from './styles';
import { useToast } from '../../../../hooks/toast';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';
import { createPlanFNC } from '../../../../types/createPlanFNC';

export function ShareLinkPlanModal({
  isShown,
  setIsShown,
  data,
}: {
  isShown: boolean;
  setIsShown(value: boolean): any;
  data: createPlanFNC;
}) {
  const { addToast } = useToast();

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);

    addToast({
      type: 'success',
      title: 'Copiado para a área de transferência!',
    });
  }

  return (
    <>
      <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
        <>
          <S.ModalWrapper>
            <S.ModalHeader>
              <Headline variant="h4">Compartilhar Link</Headline>
              <button
                type="button"
                onClick={() => setIsShown(false)}
                aria-label="default"
              >
                <FiX />
              </button>
            </S.ModalHeader>
            <S.ModalBody>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    padding: '1rem',
                    margin: '.5rem',
                    backgroundColor: '#F4F4F8',
                  }}
                >
                  <Headline variant="h6">Plano criado com sucesso!</Headline>
                  <p>
                    Agora você pode compartilhar o link com o cliente{' '}
                    <b>Nome do cliente</b> que irá assinar o plano{' '}
                    <b>{data?.name}!</b>
                  </p>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '1rem',
                      marginBottom: '1rem',
                    }}
                  >
                    <S.Input>
                      <Input
                        label=""
                        value={`${process.env.REACT_APP_DOMAIN_NAME}/assinatura/${data?.identifier}`}
                      />
                    </S.Input>
                    <S.Button>
                      <Button
                        onClick={() => {
                          copyToClipboard(
                            `${process.env.REACT_APP_DOMAIN_NAME}/assinatura/${data?.identifier}`,
                          );
                          addToast({
                            title: 'Link copiado com sucesso!',
                            type: 'success',
                          });
                        }}
                        variant="primary"
                        color="green"
                        size="default"
                      >
                        Copiar Link
                      </Button>
                    </S.Button>
                  </div>
                  <p>Ou envie diretamente por:</p>
                  <S.Icons>
                    <AiOutlineWhatsApp
                      onClick={() => {
                        window.open(
                          `https://api.whatsapp.com/send?text=${process.env.REACT_APP_DOMAIN_NAME}/assinatura/${data?.identifier}`,
                        );
                      }}
                    />
                    <AiOutlineMail
                      onClick={() => {
                        window.open(
                          `mailto:,?subject=Link de assinatura da assessoria&body=Link: ${process.env.REACT_APP_DOMAIN_NAME}/assinatura/${data?.identifier}`,
                        );
                      }}
                    />
                  </S.Icons>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',

                    padding: '1rem',
                    margin: '.5rem',
                    backgroundColor: '#F4F4F8',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      flex: 1,
                    }}
                  >
                    <div>
                      <p style={{ color: '#00C49A', marginBottom: '1rem' }}>
                        {data?.name}
                      </p>
                      <span> {convertNumberToBRL(data?.valueCents / 100)}</span>
                      {data?.intervalType === 'months' ? (
                        <p style={{ marginTop: '1rem' }}>
                          <b>
                            Data da cobrança: Dia {format(new Date(), 'dd')}
                          </b>
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div style={{ alignItems: 'baseline' }}>
                    <p style={{ textAlign: 'left', fontSize: '0.75rem' }}>
                      Meios de pagamento disponibilizados: Cartão de Crédito.
                    </p>
                  </div>
                </div>
              </div>
            </S.ModalBody>
          </S.ModalWrapper>
        </>
      </Modal>
    </>
  );
}
