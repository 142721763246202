import { usersApi } from '../../api';

interface UploadReturn {
  _id?: string;
  name: string;
  email: string;
  picture: string;
  error?: string;
}

export async function uploadPicture(file: FormData): Promise<UploadReturn> {
  const imageUrl = await usersApi({
    method: 'PATCH',
    url: '/user/avatar',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: file,
  })
    .then((res) => {
      return res.data as UploadReturn;
    })
    .catch((err) => {
      return { error: err.message } as UploadReturn;
    });

  return imageUrl;
}
