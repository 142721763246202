import { dashboardsApi } from '../../api';

export async function deleteDashboard(dashboardId: string): Promise<boolean> {
  try {
    const response = await dashboardsApi.delete(`/dashboard/${dashboardId}`);
    if (response.status === 204) return true;
    return false;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao deletar o dashboard, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
