import { useState, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LabDsBreadcrumb, LabDsTable } from 'v4web-components-react';
import { format } from 'date-fns';
import { useQueryClient } from '@tanstack/react-query';
import { IDataRow } from 'v4web-components/dist/types/components/layout/lab-ds-table/lab-ds-table';

import { useToast } from '../../hooks/toast';
import { useFindIntegrationsByProjectId } from '../../services/requests/Ads/findIntegrationsByProjectId';
import { updateAccountIntegration } from '../../services/requests/Ads/updateAccountIntegration';

import { getConnectionLabel } from '../../components/AtomicDesign/molecules/TieAccountsModals/components/IntegrationStatus';
import { DeleteForm } from '../../components/AtomicDesign/atoms/DeleteForm';

import { mediaPlatformsDictionary } from '../MediaHub/data/mediaPlatforms';
import { accountsDataHeaders } from './columns';

import * as S from './styles';

export function CustomerAccounts() {
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();

  const { addToast } = useToast();

  const location = useLocation();
  const { project } = location.state as { project: IProject };
  const { name } = project;

  const queryClient = useQueryClient();

  const [showDelete, setShowDelete] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<AdsAccounts>();

  const breadcrumbsItems = [
    {
      label: 'MediaHub',
      link: '/clientes/mediahub',
      key: 'mediahub',
      event: () => {
        navigate('/clientes/mediahub');
      },
    },
    {
      label: 'Manual',
      link: '/clientes/mediahub/duvidas-frequentes',
      key: 'manual',
      event: () => {
        navigate('/clientes/mediahub/duvidas-frequentes');
      },
    },
    {
      label: 'Visão geral',
      link: '/clientes/mediahub/visao-geral',
      key: 'visao-geral',
      event: () => {
        navigate('/clientes/mediahub/visao-geral');
      },
    },
    {
      label: 'Conexão de contas',
      link: `/clientes/mediahub/accounts/${projectId}`,
      key: 'conexao-de-contas',
      event: () => {
        navigate(`/clientes/mediahub/accounts/${projectId}`);
      },
    },
  ];

  const { data: integrationData } = useFindIntegrationsByProjectId(
    projectId ?? '',
  );

  function getStatusState(status?: number) {
    switch (status) {
      case 0:
        return 'warning';
      case 1:
        return 'success';
      case 2:
        return 'error';
      case 3:
        return 'error';
      default:
        return 'warning';
    }
  }

  function formatDate(date?: string) {
    if (!date) return '';
    return format(new Date(date).getTime(), 'dd/MM/yyyy');
  }

  const handleDeleteIntegration = async (
    integration?: AdsAccounts,
  ): Promise<void> => {
    if (!integration) return;
    const updatedIntegration = { ...integration, isDeleted: true };
    const result = await updateAccountIntegration([updatedIntegration]);
    if (result) {
      addToast({
        type: 'success',
        title: 'Integração excluída com sucesso',
      });
      queryClient.invalidateQueries([
        'get-account-integrations-user',
        projectId,
        undefined,
      ]);
    }
  };

  const headers = accountsDataHeaders;
  const rows: Record<string, IDataRow>[] = useMemo(() => {
    if (!integrationData) return [];
    return integrationData
      ?.filter((integration) => !integration.isDeleted)
      .map((integration) => {
        const { type, integrationId, createdAt, status, DataUpdatedAt } =
          integration;
        return {
          type: {
            text: {
              title:
                mediaPlatformsDictionary[
                  type as keyof typeof mediaPlatformsDictionary
                ],
            },
          },
          integrationId: {
            text: {
              title: integrationId,
            },
          },
          createdAt: {
            text: {
              title: formatDate(createdAt),
            },
          },
          updatedAt: {
            text: {
              title: formatDate(DataUpdatedAt),
            },
          },
          status: {
            badge: {
              label: getConnectionLabel(integration.isDeleted, status),
              variant: 'outlined',
              size: 'medium',
              state: getStatusState(status),
            },
          },
          action: {
            action: {
              items: [],
              destructiveItem: {
                icon: 'delete',
                label: 'Excluir',
                key: 'delete',
                event: () => {
                  setSelectedIntegration(integration);
                  setShowDelete(true);
                },
              },
            },
          },
        };
      });
  }, [integrationData]);

  return (
    <S.Container>
      <LabDsBreadcrumb breadcrumbs={breadcrumbsItems} />
      <S.Header>
        <h3>Conexão de contas: {name}</h3>
        <S.DetailsHeader>
          <p>Visualize detalhes das integrações de conta do cliente</p>
        </S.DetailsHeader>
      </S.Header>
      <S.Content>
        <S.TableWrapper>
          <LabDsTable data={{ headers, rows }} actionField />
        </S.TableWrapper>
      </S.Content>
      <DeleteForm
        showDelete={showDelete}
        name="item"
        onSubmit={() => {
          handleDeleteIntegration(selectedIntegration);
        }}
        onCancel={() => setShowDelete(false)}
        itemName={selectedIntegration?.integrationId}
      />
    </S.Container>
  );
}
