import { DashboardModels } from '../DashboardModels';
import { TokenId } from '../TokenId';
import { GoogleSheets } from '../GoogleSheets';

import * as S from './styles';

export function ExtractData() {
  return (
    <S.Container>
      <TokenId />
      <GoogleSheets />
      <DashboardModels />
    </S.Container>
  );
}
