import styled from 'styled-components';

export const Paragraph = styled.p`
  font-family: 'Nunito Sans';
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;

  &.plus {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }

  &.bold {
    font-weight: 900;
  }
`;
