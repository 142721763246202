import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  background: var(--white);
  padding: 0 2rem;
  justify-content: space-between;
  width: 100%;
  border-radius: 0.5rem;
  transition: 0.4s;

  h1 {
    font-size: 38px;
  }

  > section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }

  section > div {
    margin: 0;
  }

  > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
    }
  }

  > header h3 {
    font-size: 28px;
  }

  > header p {
    margin-top: 0.5rem;
  }

  > main {
    max-width: 100%;
    overflow: auto;
    padding: 1rem 0;
  }

  > main > div {
    flex-direction: row;
  }
`;

export const Tabs = styled.div`
  display: flex;

  > p {
    margin: 0.4rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid var(--success-1);
    opacity: 0.5;
    cursor: not-allowed;
  }

  > p:first-child {
    font-weight: bold;
    opacity: 1;
    cursor: pointer;
    margin-left: 0;
    margin-right: 1rem;
  }
`;

export const Cards = styled.div`
  flex-direction: row;
  display: flex;
  > div {
    margin-right: 1rem;
    min-width: 15rem;
  }
  @media (max-width: 1920rem) {
    max-width: 40rem;
  }
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12rem;
  padding-right: 10rem;
  margin-top: 1rem;

  svg {
    width: 14rem !important;
    height: 14rem !important;

    &:last-child {
      display: none;
    }
  }
`;
