import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LabDsBreadcrumb } from 'v4web-components-react';

import { useFindProjectById } from '../../services/requests/CustomersNew/findProjectById';
import { getMetabaseUrl } from '../../services/requests/Ads/getMetabaseUrl';

import * as S from './styles';
import { UnHappyPath } from '../../components/AtomicDesign/atoms/UnhappyPath';

export function MetabaseResults() {
  const navigate = useNavigate();
  const { projectId } = useParams<string>();
  const [url, setUrl] = useState('');

  const breadcrumbsItems = [
    {
      label: 'MediaHub',
      link: '/clientes/mediahub',
      key: 'mediahub',
      event: () => {
        navigate('/clientes/mediahub');
      },
    },
    {
      label: 'Manual',
      link: '/clientes/mediahub/duvidas-frequentes',
      key: 'manual',
      event: () => {
        navigate('/clientes/mediahub/duvidas-frequentes');
      },
    },
    {
      label: 'Visão geral',
      link: '/clientes/mediahub/visao-geral',
      key: 'visao-geral',
      event: () => {
        navigate('/clientes/mediahub/visao-geral');
      },
    },
    {
      label: 'Painel de resultados',
      link: `/clientes/mediahub/resultados/${projectId}`,
      key: 'painel-de-resultados',
      event: () => {
        navigate(`/clientes/mediahub/resultados/${projectId}`);
      },
    },
  ];

  const dashboardId = 55;

  useEffect(() => {
    getMetabaseUrl(dashboardId, {
      project_id: projectId || '',
    }).then((response) => {
      setUrl(response);
    });
  }, [projectId]);

  const { data: project, isLoading } = useFindProjectById(projectId || '');

  const unhappyCondition = !isLoading && (!project || !url);

  return (
    <S.Container>
      <LabDsBreadcrumb breadcrumbs={breadcrumbsItems} />
      {unhappyCondition ? (
        <UnHappyPath
          title="Não foi possível carregar os resultados"
          assetIndex={0}
        />
      ) : (
        <>
          <S.Header>
            <h3>Painel de resultados: {project?.name}</h3>
            <S.DetailsHeader>
              <p>
                Visualize o detalhamento dos resultados de mídia do cliente
                selecionado
              </p>
            </S.DetailsHeader>
          </S.Header>
          <S.IFrameWrapper>
            <iframe title="metabase-dashboard" src={url} />
          </S.IFrameWrapper>
        </>
      )}
    </S.Container>
  );
}
