import { useState, useRef } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Formik } from 'formik';
import { FiHelpCircle, FiX } from 'react-icons/fi';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from 'react-icons/ai';
import { Box, Skeleton } from '@mui/material';
import { FaBarcode, FaClock } from 'react-icons/fa';
import { validarBoleto } from '@mrmgomes/boleto-utils';
import { format } from 'date-fns';
import { Button } from '../../../LegacyV4Components';
import { useToast } from '../../../../hooks/toast';
import { ModalLoading } from '../../../Default/Loadings/ModalLoading';
import * as S from './styles';
import { TextField } from '../TextField';
import { requestMedia } from '../../../../services/requests/MediaWallet/requestMedia';
import { foundBankByCode } from '../../../../utils/foundBankByCode';

interface CreateNewUnitProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  customerId: string;
}

export function PayTicketModal({
  isOpen,
  setIsOpen,
  customerId,
}: CreateNewUnitProps) {
  const [valueBar, setValueBar] = useState<number>(0);
  const [codeBar, setCodeBar] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const [validateDate, setValidateDate] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [showBarExample, setShowBarExample] = useState<boolean>(false);

  const createUnitForm = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const schema = Yup.object().shape({
    codeBar: Yup.string().required(''),
  });

  const handleVerifyBoleto = async (values: string) => {
    const verifyBoleto = validarBoleto(values);
    setCodeBar(verifyBoleto.linhaDigitavel);
    setLoading(true);
    if (verifyBoleto.sucesso) {
      setValueBar(verifyBoleto.valor);
      setValidateDate(verifyBoleto.vencimento);
      setIsValid(true);
      const issuerCode = verifyBoleto.codigoBarras.substring(0, 3);
      setBankName(foundBankByCode(issuerCode));
      addToast({
        title: 'Sucesso',
        description: 'Boleto validado com sucesso!',
        type: 'success',
      });
    } else {
      setIsValid(false);
      addToast({
        title: 'Erro',
        description:
          'O boleto inserido é invalido, tente novamente ou insira outro código.',
        type: 'error',
      });
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const dataToSend = {
      customerId,
      barcode: codeBar,
      documentAmountCents: valueBar,
      amountCents: valueBar,
    };
    const request = await requestMedia(dataToSend, customerId);
    if (request.status) {
      addToast({
        title: 'Sucesso',
        description: 'Solicitação de pagamento feita com sucesso!',
        type: 'success',
      });
      setIsOpen(false);
    } else {
      addToast({
        title: 'Erro',
        description: `${
          request.message.amount_cents[0] || 'Erro ao realizar solicitação'
        }`,
        type: 'error',
      });
    }
    setIsOpen(false);
    setLoading(false);
  };

  return (
    <S.Container show={isOpen}>
      <S.ModalBase size="md">
        <S.ModalHeader>
          {step === 1 && <h3>Realizar pagamento de mídia</h3>}
          {step === 2 && (
            <h3>
              <AiOutlineArrowLeft onClick={() => setStep(1)} /> Confirmação dos
              dados
            </h3>
          )}
          <FiX color="var(--grayscale-25)" onClick={() => setIsOpen(!isOpen)} />
        </S.ModalHeader>

        <S.ModalContent>
          <main>
            <S.ProgressContent>
              <S.ProgressBar porc={step === 1 ? '50%' : '100%'} />
            </S.ProgressContent>
            <Formik
              ref={createUnitForm}
              initialValues={{
                codeBar: '',
              }}
              validationSchema={schema}
              onSubmit={async () => {
                null;
              }}
            >
              {({ values, setFieldValue }) => (
                <S.ContainerForm
                  onSubmit={async () => {
                    null;
                  }}
                >
                  {loading ? (
                    <S.Loading>
                      <ModalLoading rows={2} />
                    </S.Loading>
                  ) : (
                    <>
                      {step === 1 && (
                        <>
                          <Box className="ContainerStepOne">
                            <S.BoxFlex>
                              <p>
                                Insira o código de barras do boleto de pagamento
                                emitido na conta de anúncio do cliente
                              </p>
                              <Box
                                onMouseOver={() => setShowBarExample(true)}
                                onMouseOut={() => setShowBarExample(false)}
                              >
                                <FiHelpCircle color="var(--grayscale-25)" />
                              </Box>
                              {showBarExample && (
                                <Box className="showWhereAreCodeBar" />
                              )}
                            </S.BoxFlex>
                            <h5>Código de barras</h5>
                            <Box className="ContainerField">
                              <TextField
                                label=""
                                name="codeBar"
                                leftIcon={
                                  <FaBarcode color="var(--grayscale-25)" />
                                }
                                className="fieldCodeSlip"
                                format="##"
                                onChange={() => {
                                  setFieldValue('codeBar', values);
                                }}
                              />
                              <Button
                                size="small"
                                color="green"
                                variant="primary"
                                onClick={() =>
                                  handleVerifyBoleto(values.codeBar)
                                }
                                loading={loading}
                                rightIcon={<AiOutlineArrowRight />}
                                // disabled={isValid}
                              >
                                Verificar boleto
                              </Button>
                            </Box>
                            <S.BoxFlex>
                              <FaClock color="var(--grayscale-25)" />
                              <p>
                                Pagamentos de boletos podem ser realizados até
                                as 17h
                              </p>
                            </S.BoxFlex>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Box>
                              <h5>Data de vencimento</h5>
                              {isValid ? (
                                <p>
                                  {format(new Date(validateDate), 'dd/MM/yyyy')}
                                </p>
                              ) : (
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: '1rem' }}
                                  animation={false}
                                />
                              )}
                            </Box>
                            <Box sx={{ marginLeft: '20rem' }}>
                              <h5>Valor</h5>
                              {isValid ? (
                                <p>R$ {valueBar}</p>
                              ) : (
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: '1rem' }}
                                  animation={false}
                                />
                              )}
                            </Box>
                            <Box>
                              <h5>Banco</h5>
                              {isValid ? (
                                <p>{bankName}</p>
                              ) : (
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: '1rem' }}
                                  animation={false}
                                />
                              )}
                            </Box>
                          </Box>
                        </>
                      )}
                      {step === 2 ? (
                        <S.Confirmation>
                          <S.Information>
                            <h5>Data de vencimento</h5>
                            <p>
                              {format(new Date(validateDate), 'dd/MM/yyyy')}
                            </p>
                          </S.Information>
                          <S.Information>
                            <h5>Valor</h5>
                            {valueBar}
                          </S.Information>
                          <S.Information>
                            <h5>Banco</h5>
                            <p>{bankName}</p>
                          </S.Information>
                          <button
                            type="button"
                            onClick={() => {
                              setIsOpen(!isOpen);
                              setStep(1);
                            }}
                            style={{ display: 'none' }}
                          >
                            Cancelar
                          </button>
                        </S.Confirmation>
                      ) : null}
                    </>
                  )}
                </S.ContainerForm>
              )}
            </Formik>
          </main>
        </S.ModalContent>
        <S.ModalFooter>
          <div style={{ marginRight: 10 }}>
            <Button
              size="default"
              color="red"
              variant="secondary"
              onClick={() => {
                setIsOpen(!isOpen);
                // createUnitForm.current?.setFieldValue(`name`, '');
                // // setIsOpen(false);
                // setStep(1);
                // console.log(createUnitForm.current?);
              }}
              disabled={loading}
            >
              Cancelar
            </Button>
          </div>
          {step === 1 ? (
            <Button
              size="default"
              color="green"
              variant="primary"
              onClick={() => setStep(2)}
              disabled={!isValid}
              loading={loading}
              rightIcon={<AiOutlineArrowRight />}
              // disabled={isValid}
            >
              Prosseguir para o resumo
            </Button>
          ) : null}
          {step === 2 ? (
            <Button
              size="default"
              color="green"
              variant="primary"
              onClick={() => handleSubmit()}
              loading={loading}
              // disabled={isValidSubmit}
            >
              Realizar pagamento
            </Button>
          ) : null}
        </S.ModalFooter>
      </S.ModalBase>

      <S.Background
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
    </S.Container>
  );
}
