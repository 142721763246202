interface GoProfileProps {
  id: string;
  userName: string;
  routes: {
    title: string;
    link: string;
  }[];
}

export const goProfile = ({ id, userName, routes }: GoProfileProps) => {
  return `/perfil/${id}?origin=${encodeURI(
    JSON.stringify([
      ...routes,
      {
        title: userName,
        link: '/',
      },
    ]),
  )}`;
};
