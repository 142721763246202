import { TableSimpleProps } from '../components/AtomicDesign/atoms/TableSimple/interfaces';

type Header = TableSimpleProps['data']['headers'];

export const headerTableBase: Header = [
  {
    title: '',
    key: 'avatar',
    sortable: false,
    width: '2.188rem',
  },
  {
    title: 'Nome',
    key: 'name',
    sortable: true,
    type: 'string',
    copy: true,
  },
  {
    title: 'E-mail',
    key: 'email',
    sortable: true,
    type: 'string',
    copy: true,
  },
  {
    title: 'Cargo',
    key: 'mandate',
    sortable: false,
    type: 'string',
    copy: true,
  },
  {
    title: 'Senioridade',
    key: 'seniority',
    sortable: false,
    type: 'string',
    copy: true,
  },
];

export const headerTableNotAdmin: Header = headerTableBase;

export const headerTable: Header = [
  ...headerTableBase,
  {
    title: 'Ekyte',
    key: 'ekyte',
    sortable: false,
  },
  {
    title: '',
    key: 'action',
    sortable: false,
    width: '0.625rem',
  },
];

export const headerTableWage: Header = [
  ...headerTableBase,
  {
    title: 'Salário',
    key: 'wage',
    sortable: true,
    type: 'coin',
    copy: true,
  },
  // the title is empty, because is the column for actions
  {
    title: '',
    key: 'action',
    sortable: false,
    width: '0.625rem',
  },
];

export const headerTableEkyte: Header = [
  ...headerTableBase,
  {
    title: 'Ekyte',
    key: 'ekyte',
    sortable: false,
  },
  headerTable[headerTable.length - 1],
];

export const headerTableHistoric: Header = [
  ...headerTableBase,
  {
    title: 'Entrada',
    key: 'startedAt',
    sortable: true,
    type: 'date',
  },
  {
    title: 'Saida',
    key: 'deletedAt',
    sortable: true,
    type: 'date',
  },
];
