import styled from 'styled-components';

export const Item = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
`;

export const ItemHead = styled.div`
  display: flex;
  position: relative;
  gap: 0.25rem;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  user-select: none;

  padding-bottom: 0.5rem;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: var(--tertiary-dark);
    bottom: 0;
  }

  & > svg {
    width: 1.5rem;
    height: 1.5rem;

    flex-shrink: 0;
  }
`;

export const ItemBody = styled.div`
  overflow: hidden;
  box-sizing: border-box;

  height: 0;
  opacity: 0;

  transition: 0.3s;

  &.active {
    opacity: 1;
  }
`;
