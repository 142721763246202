import { usersApi as api } from '../../api';

interface emailSuggestions {
  suggestions: string[];
}

export async function emailSuggestions(
  fullName: string,
): Promise<string[] | false> {
  try {
    const response: any = (
      await api.get(`/workspace/suggestions?fullName=${fullName}`)
    ).data;

    return response.suggestions;
  } catch (err) {
    console.error(`Ocorreu algum problema ao convidar este usuário 😕`, err);
    return false;
  }
}
