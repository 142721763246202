import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 20rem;

  padding: 1.5rem 2rem;
  width: 100%;

  background-color: var(--white);
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);

  .delete-column {
    text-align: right;
    cursor: pointer;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    margin: 0.5rem 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;

  span {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
  }

  svg {
    margin-right: 0.5rem;
  }

  .dash {
    color: var(--primary-dark);
  }

  .whats {
    color: var(--success-1);
  }

  .video-play {
    color: var(--warning);
  }
`;

export const HeaderPreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EditCustomer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 14px;
  color: var(--success-1);
  cursor: pointer;
  text-decoration: underline;
  background: transparent;
  border: none;
  ${({ disabled }) =>
    disabled &&
    css`
       {
        color: var(--tertiary-dark);
      }
    `}
  svg {
    margin-left: 0.5rem;
  }
`;
