import { useState } from 'react';
import { Button, Headline } from '../../../LegacyV4Components';

import * as S from './styles';

import thumb1 from '../../../../assets/images/media-thumb-1.png';

export function MediaTemplate() {
  const [images, setImages] = useState([{ active: true, src: thumb1 }]);

  const selectedImage = images.find((i) => i.active)?.src || thumb1;

  const handleDotClick = (index: number) => {
    setImages((oldImages) => {
      return oldImages.map((item, i) => {
        if (i === index) {
          return { ...item, active: true };
        }
        return { ...item, active: false };
      });
    });
  };

  return (
    <S.TemplateCard>
      <S.TemplateCardHeader>
        <Headline variant="h5">Mídia (Facebook Ads e Google Ads)</Headline>
      </S.TemplateCardHeader>
      <S.Carrousel>
        <S.CarrouselImage background={selectedImage} />
        <S.CarrouselDots>
          {images.map((item, index) => {
            return (
              <S.CarrouselDot
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                active={item.active}
                onClick={() => handleDotClick(index)}
              />
            );
          })}
        </S.CarrouselDots>
      </S.Carrousel>
      <S.ButtonWrapper>
        <a href="https://bit.ly/466WJoo" target="_blank" rel="noreferrer">
          <Button
            variant="primary"
            color="green"
            size="small"
            onClick={() => null}
          >
            Baixar
          </Button>
        </a>
      </S.ButtonWrapper>
    </S.TemplateCard>
  );
}
