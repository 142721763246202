import { InvestorTeam } from '../../../../hooks/types';
import { usersApi } from '../../../api';

export async function assignInvestor(
  userId: string,
  customerId: string,
  active: boolean,
  isDedicated: boolean,
): Promise<InvestorTeam[] | false> {
  try {
    const response = await usersApi.post(`/capacity/manage/assign/project`, {
      userId,
      customerId,
      active,
      isDedicated,
    });
    const product = response.data as InvestorTeam[];

    return product;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao inserir investidor no projeto, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
