import styled, { css } from 'styled-components';

import { Tooltip } from '../Tooltip';

interface Props {
  isTextareaFocused: boolean;
  isErrored: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: center;

  background: var(--white);
  border: 2px solid #e7e7e7;
  color: #818181;
  border-radius: 0.25rem;
  padding: 1rem;
  width: 100%;
  transition: 0.5s;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.04));

  textarea {
    flex: 1;
    border: 0;
    background: transparent;

    resize: none;
    height: 8rem;

    color: #818181 !important;

    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;

    &::placeholder {
      color: var(--grayscale-40);
    }

    &:focus {
      outline: 0;
    }
  }

  & + div {
    margin-top: 1rem;
  }

  :hover {
    background: var(--white);
    border-color: var(--primary-dark);
  }

  p {
    margin-right: 0.5rem;
    color: var(--white);
  }

  ${(props) =>
    props.isTextareaFocused &&
    css`
      background: #e7e7e7 !important;
      color: #818181;
      border-color: var(--primary-dark);
    `}

  ${(props) =>
    props.isErrored &&
    css`
      color: var(--error);
      border-color: var(--error);
    `}

  svg {
    margin-left: 1rem;
  }
`;

export const Error = styled(Tooltip)`
  width: 2rem;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  margin-left: 1rem;

  span {
    left: 27%;
  }
`;
