import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type PremisesContextData = {
  premises: Premise[];
  setPremises: Dispatch<SetStateAction<Premise[]>>;
};

export const PremisesContext = createContext({} as PremisesContextData);

type PremisesContextProviderProps = {
  children: ReactNode;
};

export const PremisesProvider: React.FC<PremisesContextProviderProps> = ({
  children,
}: PremisesContextProviderProps) => {
  const [premises, setPremises] = useState({} as Premise[]);

  return (
    <PremisesContext.Provider
      value={{
        premises,
        setPremises,
      }}
    >
      {children}
    </PremisesContext.Provider>
  );
};

export const usePremises = (): PremisesContextData => {
  return useContext(PremisesContext);
};
