import { parseCookies } from 'nookies';
import { stackApi } from '../../api';

export const ekyteAuth = async () => {
  const cookies = parseCookies(undefined);
  const token = cookies['v4company.token'];

  try {
    const response = await stackApi({
      method: 'POST',
      url: '/ekyte/auth',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(async (res) => res.data as { data: string })
      .then(async (res: { data: string }) => res.data);

    return response;
  } catch (error) {
    return false;
  }
};
