import { Dashboard } from '../../../types/dashboard';
import { midiaWalletApi } from '../../api';

interface IRequestMedia {
  data: IDataRequestMedia;
  customerId: string;
}

interface IDataRequestMedia {
  customerId: string;
  barcode: string;
  documentAmountCents: number;
  amountCents: number;
}

export async function requestMedia(
  data: IDataRequestMedia,
  customerId: string,
): Promise<IRequestMedia | any> {
  try {
    const response = await midiaWalletApi.post<Dashboard>(
      `/media-wallet/transaction/exit/mediawallet-${customerId}`,
      data,
    );
    const product = response.data;

    return product;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar esta solicitação, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
