import { IconBaseProps } from 'react-icons';
import { Field, FieldAttributes, FieldProps } from 'formik';
import InputMask from 'react-input-mask';

import { Box } from '@mui/material';
import { Smalled } from '../../../LegacyV4Components';

import * as S from './styles';

export type TextFieldStatus =
  | 'default'
  | 'focus'
  | 'filled'
  | 'error'
  | 'warning'
  | 'success';

interface TextFieldProps extends FieldAttributes<any> {
  label: string;
  helper?: string;
  mask?: string;
  setValue?: (value: string) => void;
  status?: TextFieldStatus;
  leftIcon?: IconBaseProps;
  rightIcon?: IconBaseProps;
  width?: string;
  warningMessage?: string;
}

export function TextField({ ...props }: TextFieldProps) {
  let inputStatus = '';

  if (props.value !== '') {
    inputStatus = 'filled';
  }

  if (props.helper) {
    inputStatus = 'error';
  }

  if (props.status === 'default') {
    inputStatus = 'default';
  }

  return (
    <S.InputWrapper className={inputStatus}>
      <label htmlFor={props.name}>
        <h6>{props.label}</h6>
        <S.InputControl
          className="input-control"
          style={{
            width: props.width ? `${props.width}` : 'auto',
            background: '#fff',
          }}
        >
          <>
            {props.leftIcon}
            {props.mask ? (
              <Field
                name={props.name}
                render={({ field }: FieldProps) => (
                  <InputMask
                    mask={props.mask as string}
                    {...field}
                    placeholder={props.placeholder}
                  />
                )}
              />
            ) : (
              <Field
                format={props.format}
                type={props.number ? 'number' : 'text'}
                id={props.name}
                name={props.name}
                value={props.value}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                placeholder={props.placeholder}
                disabled={props.disabled}
              />
            )}

            {props.rightIcon}
          </>
        </S.InputControl>
        <Box sx={{ height: '1rem' }}>
          {props.helper ? <Smalled>{props.helper}</Smalled> : null}
          {props.status === 'warning' ? (
            <Smalled>{props.warningMessage}</Smalled>
          ) : null}
        </Box>
      </label>
    </S.InputWrapper>
  );
}
