import { KubernetesApiUnit } from '../../../hooks/types';
import { kubernetesApi, leadBrokerApi } from '../../api';

interface UnitBlockProps {
  blockedAt: string;
}

export async function getUnit(id: string) {
  try {
    const { data } = await kubernetesApi.get<KubernetesApiUnit['unit']>(
      `/unit/${id}`,
    );
    const units = data;

    return units;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os usuários, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}

export async function getUnitBlock(unitId: string) {
  try {
    const { data } = await leadBrokerApi.get<UnitBlockProps>(
      `/blockments/${unitId}`,
    );

    return data?.blockedAt;
  } catch (err) {
    console.error(
      'Ocorreu algum problema ao carregar as informações de bloqueio da unidade',
      err,
    );
    return false;
  }
}
