import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 1180px;
  max-width: 95vw;

  height: 100vh;
  max-height: 95vh;

  border-radius: 0.5rem;
  background-color: var(--white);

  svg {
    color: #8383af;
    margin-right: 16px;
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  flex-grow: 0;

  padding: 1.75rem 1.5rem;

  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.12);

  & > button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 1rem;
    height: 1rem;

    background: transparent;
    border: none;
    color: #83834f;

    & > svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding: 1.5rem 2.5rem;

  overflow: auto;

  & > small {
    margin-bottom: 1rem;
  }
  overflow-y: auto;

  .input-control {
    margin-right: 32px;
  }

  h5 {
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 8px;
    font-size: 16px;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 1rem;

  box-shadow: 0 -4px 8px 3px rgba(0, 0, 0, 0.06), 0 -1px 3px rgba(0, 0, 0, 0.12);

  padding: 1.5rem 2.5rem;
`;

export const ContainerRadio = styled.div`
  display: flex;
  margin-right: 16px;
  justify-content: space-evenly;
  align-items: center;
  width: 132px;
  height: 48px;
  border-radius: 4px;
  padding: 16px, 32px, 16px, 32px;
  background: #f6f6f6;
  input {
    background: #8383af;
  }
  p {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  margin-right: 16px;
  justify-content: space-evenly;
  align-items: center;
  width: max-content;
  padding: 40px 32px 40px 32px;
  height: 96px;
  border-radius: 4px;
  padding: 16px, 32px, 16px, 32px;
  background: #f6f6f6;
  margin-bottom: 32px;
  input {
    background: #8383af;
    margin-right: 24px;
  }
  p {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const ContainerProductType = styled.div`
  display: flex;
  margin-top: 1.5rem;
`;

export const SectionResume = styled.section`
  border-bottom: 1px solid #bbbbbb;
  margin-bottom: 32px;
  small {
    margin-top: 24px;
  }
  padding-bottom: 16px;
`;
