import { HiOutlineLockClosed } from 'react-icons/hi';
import { LabDsButton } from 'v4web-components-react';

import { useState } from 'react';
import { Paragraph } from '../../../LegacyV4Components';
import { useToast } from '../../../../hooks/toast';

import * as S from './styles';
import { generateExternalToken } from '../../../../services/requests/User/generateExternalToken';

export function TokenId() {
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleGenerateTokenClick() {
    setIsLoading(true);
    const token = await generateExternalToken();

    if (token) {
      navigator.clipboard.writeText(token);
      addToast({
        type: 'success',
        title: 'Token ID gerado com sucesso!',
      });
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao gerar Token ID!',
      });
    }

    setIsLoading(false);
  }

  return (
    <S.Card>
      <HiOutlineLockClosed size={20} />
      <S.Description>
        <Paragraph variants={['bold']}>Token ID</Paragraph>
        <Paragraph>
          Gere um Token ID único para utilizar nas suas APIs e integrações
        </Paragraph>
      </S.Description>
      <S.ButtonWrapper>
        <LabDsButton
          label="Gerar"
          size="small"
          loading={isLoading}
          disabled={isLoading}
          onHandleButton={() => handleGenerateTokenClick()}
        />
      </S.ButtonWrapper>
    </S.Card>
  );
}
