import styled from 'styled-components';

export const DeleteForm = styled.div`
  max-width: 700px;
  width: 100%;

  max-height: 368px;
  height: 100%;

  padding: 2rem 4rem;
  background: var(--white);
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 10px;

  svg {
    color: var(--primary-main);
    min-height: 2rem;
    min-width: 2rem;
  }

  h3 {
    text-align: center;
    margin: 0.5rem;
  }

  p {
    font-size: 1rem;
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
  }

  footer {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    button:first-child {
      margin-right: 1rem;
    }
  }
`;
