import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const MobMenu = styled.div`
  position: absolute;
  left: 0px;
  top: 4rem;
  padding-top: 1.5rem;
  bottom: 0;
  min-height: 100vw;
  width: 100%;
  background-color: var(--white);
  z-index: 5;

  @media (min-width: 501px) {
    display: none;
  }
`;

export const MobMenuItem = styled(Link)<{ isSelected: boolean }>`
  &:hover {
    background-color: var(--grayscale-4);
  }
  background-color: var(--white);
  text-decoration: none;
  display: flex;
  height: 3.5rem;
  line-height: 1.5rem;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--secondary-main);

  strong {
    color: var(--secondary-main);
    font-weight: 700;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #e2fbf5;
      border-bottom: 2px solid var(--success-2);

      strong {
        color: var(--success-2);
      }

      p {
        opacity: 1;
        width: 8rem;
      }
    `}
`;

export const Logout = styled.a<{ isSelected: boolean }>`
  &:hover {
    background-color: var(--grayscale-4);
  }
  background-color: var(--white);
  text-decoration: none;
  display: flex;
  height: 3.5rem;
  line-height: 1.5rem;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--secondary-main);

  a {
    color: var(--secondary-main);
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #e2fbf5;
      border-bottom: 2px solid var(--success-2);

      a {
        color: var(--success-2);
      }

      p {
        opacity: 1;
        width: 8rem;
      }
    `}
`;

export const Logo = styled.div`
  position: absolute;
  bottom: 0;
  left: calc(50% - 1rem);
  margin-bottom: 2.5rem;

  img {
    width: 2rem;
    height: 2rem;
  }
`;
