import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    justify-content: flex-end;
  }
`;

export const InfoButtonText = styled.div`
  width: 17rem;
  bottom: 2rem;
  right: 0;
  opacity: 0;
  position: absolute;
  display: flex;
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--tertiary-dark);
  background-color: var(--white);
  transition: all 0.2s ease-in-out;
  cursor: default;

  :before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--tertiary-dark);
    border-bottom: 5px solid transparent;
    right: 5px;
    bottom: -10px;
  }
  :after {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--white);
    border-bottom: 5px solid transparent;
    right: 5px;
    bottom: -8px;
  }
`;

export const Text = styled.p`
  font-size: 0.75rem;
  color: var(--secondary-main);
  text-align: left;
  font-weight: 400;
`;

export const InfoButton = styled.div`
  position: relative;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  border: none;

  img {
    align-self: flex-end;
  }
`;

export const HighlightButton = styled.button<{
  textHover: string;
}>`
  border-radius: 0.25rem;
  min-width: 11rem;
  height: 2.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  border: 0;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  background: var(--success-3);
  color: var(--secondary-main);
  font-family: 'Roboto', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  box-shadow: 0;

  &:hover {
    background: var(--success-2);
    box-shadow: 0px 8px 20px 6px rgba(28, 28, 33, 0.15),
      0px 4px 10px rgba(28, 28, 33, 0.3);
    animation: step-end;

    ${({ textHover }) =>
      css`
        :after {
          content: '${textHover}';
        }
      `}
  }

  @keyframes highlight {
    0% {
      background: var(--success-3);
    }

    50% {
      background: var(--success-2);
      box-shadow: 0px 8px 20px 6px rgba(28, 28, 33, 0.15),
        0px 4px 10px rgba(28, 28, 33, 0.3);
    }

    100% {
      background: var(--success-3);
    }
  }
`;

export const Icon = styled.img``;
