import { useEffect, useMemo, useState } from 'react';

import { FiX } from 'react-icons/fi';
import { Modal } from '../../../LegacyV4Components';
import * as S from './styles';

import { DataGrid, DataProps } from '../../atoms/DataGrid';
import { getAllCapacities } from '../../../../services/requests/User/Capacities/getAllCapacities';

interface NewPendingInvitesProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

interface ColumnProps {
  label: string;
  accessor: string;
  isSorted: boolean;
  sortable: boolean;
  sortByOrder: 'asc' | 'desc';
  maxWidth: number;
  isActions?: boolean;
}

export function AllCapacitiesModal({
  isOpen,
  setIsOpen,
}: NewPendingInvitesProps) {
  const [capacities, setCapacities] = useState([]);

  const tableColumns = [
    {
      accessor: 'project',
      label: 'Cargos',
    },
    {
      accessor: 'dedication',
      label: 'Mínimo',
      sizing: 1,
    },
    {
      accessor: 'dedication',
      label: 'Máximo',
      sizing: 1,
    },
  ] as ColumnProps[];

  useEffect(() => {
    const getCustomers = async () => {
      const usersPromise = await getAllCapacities();
      if (usersPromise) setCapacities(usersPromise);
      return usersPromise;
    };
    getCustomers();
  }, []);

  const tableContent = useMemo(() => {
    const tableData: DataProps[] = [];
    capacities?.forEach((currentProject: any) => {
      tableData.push({
        id: currentProject._id,
        content: (
          <>
            <td>
              {currentProject.mandate.name} {currentProject.seniority.name}
            </td>
            <td>1</td>
            <td>{currentProject.maxWorkload}</td>
          </>
        ),
      });
    });

    return tableData;
  }, [capacities]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Modal show={isOpen} onBackground={closeModal} width="44.5rem">
        <S.ModalHeader>
          <FiX onClick={closeModal} />
        </S.ModalHeader>
        <S.Content>
          <h2>Lista de capacidades</h2>
          <p>
            Quantidade mínima e máxima de projetos que o investidor poderá
            trabalhar ao mesmo tempo
          </p>
          <DataGrid
            showModalUserInvate={false}
            setShowModalUserInvate={() => null}
            columns={tableColumns}
            data={tableContent || []}
          />
        </S.Content>
      </Modal>
    </>
  );
}
