import { Box, CircularProgress } from '@mui/material';
import { FiArrowRight, FiShoppingCart, FiUser } from 'react-icons/fi';
import { MdOutlinePayment } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Headline } from '../../../LegacyV4Components';
import { useToast } from '../../../../hooks/toast';
import { FormikPaymentOptions } from '../../../../pages/PaymentOptions';
import {
  createPayment,
  createPaymentHQ,
  createPaymentKommoStack,
  createPaymentProps,
} from '../../../../services/requests/Finance/createPayment';
import { IProductById } from '../../../../services/requests/Finance/getProductById';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';
import { GatewayTerms } from '../../molecules/GatewayTerms';
import * as S from './styles';
import { createDeposit } from '../../../../services/requests/MediaWallet/createDeposit';

interface ResumePayment {
  values: FormikPaymentOptions;
  data: IProductById | any;
  setPaymentIsCreate: React.Dispatch<React.SetStateAction<boolean>>;
  setIndexTab: React.Dispatch<React.SetStateAction<number>>;
  indexTab: number;
  setResponsePayment: React.Dispatch<React.SetStateAction<createPaymentProps>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
  selectMethod?: string;
  customerHexColor: string | undefined;
  useGMPTerms?: boolean;
  mediaWallet?: boolean;
  walletId?: string;
  cupomDescount?: any;
  quantity?: number;
}

export function ResumePayment({
  values,
  data,
  setPaymentIsCreate,
  setIndexTab,
  indexTab,
  setResponsePayment,
  setIsLoading,
  isLoading,
  selectMethod,
  customerHexColor,
  useGMPTerms,
  mediaWallet,
  walletId,
  cupomDescount,
  quantity,
}: ResumePayment) {
  const { addToast } = useToast();
  const [showTerms, setShowTerms] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptGMPTerms, setAcceptGMPTerms] = useState(false);
  const [payment, setPayment] = useState<createPaymentProps>();
  const [buttonDisable, setButtonDisable] = useState(true);
  const [searchParams] = useSearchParams();
  const isMatriz = searchParams.get('isMatriz');

  useEffect(() => {
    if (useGMPTerms && acceptGMPTerms && acceptTerms) {
      setButtonDisable(false);
      return;
    }

    if (!useGMPTerms && acceptTerms) {
      setButtonDisable(false);
      return;
    }

    setButtonDisable(true);
  }, [acceptTerms, acceptGMPTerms, useGMPTerms]);

  return (
    <S.Container>
      <>
        <S.ContainerInfo style={{ border: `1px solid ${customerHexColor}` }}>
          <Headline variant="h5">
            <FiShoppingCart />
            Seu pedido
          </Headline>
          <S.Details>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '1rem',
              }}
            >
              <p>Valor</p>
              {isMatriz === 'true' ? (
                <p>
                  {quantity
                    ? convertNumberToBRL(data?.products[0]?.value * quantity)
                    : convertNumberToBRL(data?.products[0]?.value)}
                </p>
              ) : (
                <>
                  {data?.valueCents ? (
                    <p>{convertNumberToBRL(data?.valueCents / 100) || ''}</p>
                  ) : (
                    <p>
                      {mediaWallet !== true && data
                        ? convertNumberToBRL(data?.value / 100)
                        : (data && convertNumberToBRL(data?.ammount / 100)) ||
                          ''}
                    </p>
                  )}
                </>
              )}
            </Box>
            {cupomDescount && (
              <>
                {!cupomDescount?.disable ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0rem 1rem 1rem',
                      }}
                    >
                      <p>Subtotal</p>{' '}
                      <p>
                        {data?.products[0] &&
                          convertNumberToBRL(data?.products[0]?.value)}
                      </p>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0rem 1rem 1rem',
                      }}
                    >
                      <p>Cupom aplicado</p>
                      <p>{cupomDescount.name}</p>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0rem 1rem 0.5rem 1rem',
                      }}
                    >
                      {cupomDescount.discountType === 'percentage' ? (
                        <p>Desconto de {cupomDescount.value}%</p>
                      ) : (
                        <p>Desconto</p>
                      )}
                      <p>{convertNumberToBRL(cupomDescount.discount)}</p>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderTop: '1px solid #BBBBBB',
                        padding: '1rem',
                        opacity: '0.8',
                      }}
                    >
                      <p>TOTAL</p>{' '}
                      <p>{convertNumberToBRL(cupomDescount.finalValue)}</p>
                    </Box>
                  </>
                ) : (
                  <p>Cupom inválido ou desabilitado.</p>
                )}
              </>
            )}
          </S.Details>
        </S.ContainerInfo>
        <S.ContainerInfo style={{ border: `1px solid ${customerHexColor}` }}>
          <Headline variant="h5">
            <FiUser />
            Sobre você
          </Headline>
          <S.Details>
            <p>Nome completo</p>
            <small>{values?.name}</small>
          </S.Details>
          <S.Details>
            <p>Email</p>
            <small>{values?.email || ''}</small>
          </S.Details>
          <S.Details>
            <p>Telefone</p>
            <small>{mediaWallet ? values?.phone : values?.telephone}</small>
          </S.Details>
          <S.Details>
            <p>CPF</p>
            <small>{mediaWallet ? values?.cpf_cnpj : values?.cpf}</small>
          </S.Details>
        </S.ContainerInfo>
        <S.ContainerInfo style={{ border: `1px solid ${customerHexColor}` }}>
          <Headline variant="h5">
            <MdOutlinePayment />
            Pagamento
          </Headline>
          <S.Details>
            <p>{selectMethod}</p>
            {data?.subscriptions && <p>{data?.subscriptions[0].name}</p>}
          </S.Details>
        </S.ContainerInfo>
        <S.Terms>
          <input
            type="checkbox"
            checked={acceptTerms}
            onChange={() => setAcceptTerms(!acceptTerms)}
          />
          <p>
            Li e estou de acordo com os{' '}
            <button type="button" onClick={() => setShowTerms(true)}>
              Termos de Condições e de uso
            </button>
          </p>
        </S.Terms>
        {useGMPTerms && (
          <S.Terms>
            <input
              id="GMPTerms"
              type="checkbox"
              onChange={() => setAcceptGMPTerms(!acceptGMPTerms)}
            />
            <p>
              Li e estou de acordo com os{' '}
              <a
                href="https://vendas.v4company.com/gmp/termos-e-condicoes/"
                target="_blank"
                rel="noreferrer"
              >
                <strong>Termos & Condições do Curso GMP</strong>
              </a>
            </p>
          </S.Terms>
        )}
      </>
      <div style={{ float: 'right' }}>
        <Button
          variant="primary"
          rightIcon={
            !isLoading ? (
              <FiArrowRight />
            ) : (
              <S.ContainerLoading>
                <CircularProgress sx={{ width: '1rem', height: '1rem' }} />
              </S.ContainerLoading>
            )
          }
          color="green"
          size="default"
          onClick={async () => {
            try {
              setIsLoading(true);
              let response;

              if (mediaWallet === true && walletId) {
                response = await createDeposit(values, walletId);
                setResponsePayment(response);
              }

              const dataToSend = { ...values };

              let dataToStack = {};
              if (data?.stack) {
                dataToStack = {
                  checkoutId: values?.recipientId,
                  name: values?.name,
                  email: values?.email,
                  cpfCnpj: values?.cpf || values?.cpf_cnpj,
                  telephone: values?.telephone,
                  paymentMethod: values?.paymentMethod,
                  address: {
                    district: values?.address?.district,
                    number: values?.address?.number,
                    street: values?.address?.street,
                    zipCode: values?.address?.zipCode,
                    complement: values?.address?.complement,
                  },
                };
              }

              if (cupomDescount) {
                dataToSend.couponApplied = cupomDescount.name;
                dataToSend.finalValue = cupomDescount.finalValue;
                dataToSend.discount = cupomDescount.cupomDescount;
              }
              if (data?.stack) {
                response = await createPaymentKommoStack(dataToStack as any);
                if (!response) {
                  return;
                }
                setResponsePayment(response);
              }

              if (isMatriz !== 'true' && !mediaWallet && !data?.stack) {
                response = await createPayment(values);
                if (!response) {
                  return;
                }
                setPayment(response);
                setResponsePayment(response);
              }
              payment?.name && setIsLoading(false);
              payment?.name && setPaymentIsCreate(true);
              payment?.name && setIndexTab(indexTab + 1);

              if (isMatriz === 'true') {
                response = await createPaymentHQ(dataToSend);
                if (!response) {
                  return;
                }
                setResponsePayment(response);
              }

              if (response?.name) {
                setIsLoading(false);
                setPaymentIsCreate(true);
                setIndexTab(indexTab + 1);
                addToast({
                  title: 'Sucesso ao gerar fatura!',
                  type: 'success',
                });
              }
            } catch (error) {
              addToast({
                title: 'Ocorreu um erro!',
                description:
                  'Erro ao gerar a fatura, tente novamente mais tarde ou entre em contato com nosso suporte interno.',
                type: 'error',
              });
              setIsLoading(false);
            }
          }}
          disabled={buttonDisable}
        >
          Finalizar pedido
        </Button>
      </div>
      <GatewayTerms show={showTerms} onClose={() => setShowTerms(false)} />
    </S.Container>
  );
}
