import React, { ReactNode } from 'react';
import * as S from './styles';

type Variant = 'plus' | 'bold';

interface ParagraphProps {
  variants?: Variant[];
  className?: string;
  children?: ReactNode;
}

export function Paragraph({
  variants,
  className = '',
  children,
}: ParagraphProps) {
  const elementClass = `${variants ? variants.join(' ') : ''} ${className}`;

  return <S.Paragraph className={elementClass}>{children}</S.Paragraph>;
}
