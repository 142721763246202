import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px 8px 8px 16px;
  background: #e6e6ef;
  border: 1px solid #cdcddf;
  border-radius: 16px;

  height: 35px;
  transition: 300ms;

  margin: 5px;

  span {
    font-size: 12px;
    font-weight: 700;
  }

  &:hover {
    background-color: #cdcddf;
  }
`;

export const Icon = styled.img`
  width: 16px;
  -webkit-filter: grayscale(100%);
  transition: 100ms;

  &:hover {
    -webkit-filter: grayscale(0%);
  }
`;

export const IconBtn = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
