import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { Customer } from '../types/customer';

type CustomerContextData = {
  customer: Customer;
  setCustomer: Dispatch<SetStateAction<Customer>>;
  customers: Customer[];
  setCustomers: Dispatch<SetStateAction<Customer[]>>;
};

export const CustomerContext = createContext({} as CustomerContextData);

type CustomerContextProviderProps = {
  children: ReactNode;
};

export const CustomerProvider: React.FC<CustomerContextProviderProps> = ({
  children,
}: CustomerContextProviderProps) => {
  const [customer, setCustomer] = useState({} as Customer);
  const [customers, setCustomers] = useState<Customer[]>([]);

  return (
    <CustomerContext.Provider
      value={{
        customer,
        setCustomer,
        customers,
        setCustomers,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = (): CustomerContextData => {
  return useContext(CustomerContext);
};
