import { usersApi } from '../../api';

interface IDashboardsPermission {
  roleName: string;
  admin: boolean;
  view: boolean;
  edit: boolean;
  delete: boolean;
}

const getDashboardsPermission = async (userId: string) => {
  const permission: IDashboardsPermission = await usersApi({
    method: 'GET',
    url: `/user/roles/${userId}`,
  })
    .then((res) => res.data)
    .then((res: any) => res?.dashboard);

  return permission;
};

export { getDashboardsPermission };
export type { IDashboardsPermission };
