import { useNavigate } from 'react-router-dom';

import {
  LabDsBreadcrumb,
  LabDsButton,
  LabDsTable,
} from 'v4web-components-react';
import { useEffect, useMemo, useState } from 'react';
import { IData } from 'v4web-components';
import {
  Container,
  Section,
  SectionContent,
} from '../../components/LegacyV4Components';
import * as S from './styles';
import { CreateLeadModal } from './components/createLeadModal';
import { getOportunities } from '../../services/requests/Calculator/oportunities/getOportunities';
import { oportunityInterface } from '../../services/requests/Calculator/types';
import { CancelOportunityModal } from './components/removeOportunityModal';
import { useAuth } from '../../hooks/auth';

const textInfo = {
  in_negotiation: 'Em negociação',
  allocated_project: 'Projeto alocado',
  project_canceled: 'Projeto cancelado',
};

export function OportunityManager() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const isHeadquarter = user.unitId === process.env.REACT_APP_HEADQUARTER_ID;

  const [newOportuniyModal, setNewOportuniyModal] = useState(false);
  const [cancelOportunity, setCancelOportunity] = useState<boolean>(false);
  const [oportunities, setOportunities] = useState<oportunityInterface[]>();
  const [oportunitySeletectedToCancel, setOportunitySeletectedToCancel] =
    useState<oportunityInterface>();

  function getDateFormat(date: string) {
    const dateInfo = new Date(date);
    return `${dateInfo.getDate()} / ${
      dateInfo.getMonth() + 1
    } / ${dateInfo.getFullYear()}`;
  }

  const headers: IData['headers'] = [
    {
      title: 'Projeto',
      colKey: 'project',
      type: 'text',
    },
    {
      title: 'Data da última alteração',
      colKey: 'lastUpdate',
      type: 'text',
    },
    {
      title: 'Responsável',
      colKey: 'responsible',
      type: 'text',
    },
    {
      title: 'Status',
      colKey: 'status',
      type: 'badge',
    },
  ];

  const rows: IData['rows'] = useMemo(() => {
    const rowsData: IData['rows'] = [];
    if (oportunities) {
      oportunities?.forEach((currentOportunity: oportunityInterface) => {
        rowsData.push({
          project: {
            text: {
              title: currentOportunity.projectName,
            },
            handleRow: () => {
              navigate(
                `/newPriceCalculatorEdit/${currentOportunity?.projectName}`,
              );
            },
          },
          lastUpdate: {
            text: {
              title: `${getDateFormat(currentOportunity.lastNegotiation)}`,
            },
            handleRow: () => {
              navigate(
                `/newPriceCalculatorEdit/${currentOportunity?.projectName}`,
              );
            },
          },
          responsible: {
            text: {
              title: currentOportunity.responsibleName || '',
            },
            handleRow: () => {
              navigate(
                `/newPriceCalculatorEdit/${currentOportunity?.projectName}`,
              );
            },
          },
          status: {
            badge: {
              label: textInfo[currentOportunity.status],
              state:
                currentOportunity.status === 'project_canceled'
                  ? 'error'
                  : 'success',
            },
            handleRow: () => {
              navigate(
                `/newPriceCalculatorEdit/${currentOportunity?.projectName}`,
              );
            },
          },
          action: {
            action: {
              items: [],
              destructiveItem: {
                icon: 'delete',
                label: 'Cancelar projeto',
                key: 'delete',
                event: () => {
                  setOportunitySeletectedToCancel(currentOportunity);
                  setCancelOportunity(true);
                },
              },
            },
          },
        });
      });
    }
    return rowsData;
  }, [oportunities, navigate]);

  async function getAllOportunities() {
    const response = await getOportunities();
    if (response) {
      setOportunities(response);
    }
  }

  useEffect(() => {
    getAllOportunities();
  }, [setCancelOportunity]);

  return (
    <Container>
      <LabDsBreadcrumb
        breadcrumbs={[
          {
            label: 'Minha unidade',
            link: '',
            key: 'Minha unidade',
            event: () => {
              navigate('');
            },
          },
          {
            label: 'Capacidade operacional',
            link: '',
            key: 'Capacidade operacional',
            event: () => {
              navigate('');
            },
          },
        ]}
      />
      <Section>
        <SectionContent>
          <S.Row>
            <h3>Gerenciamento de oportunidades</h3>
            <S.Actions>
              {!isHeadquarter && (
                <LabDsButton
                  label="Calculadora avulsa"
                  variant="link-button"
                  onHandleButton={() => {
                    navigate('/newPriceCalculatorEdit');
                  }}
                />
              )}
              <LabDsButton
                label="Criar nova oportunidade"
                onHandleButton={() => {
                  setNewOportuniyModal(true);
                }}
              />
            </S.Actions>
          </S.Row>

          <S.Table>
            <LabDsTable data={{ headers, rows }} actionField />
          </S.Table>
        </SectionContent>
      </Section>
      {newOportuniyModal && (
        <CreateLeadModal closeModal={setNewOportuniyModal} />
      )}

      {cancelOportunity && oportunitySeletectedToCancel && (
        <CancelOportunityModal
          closeModal={setCancelOportunity}
          currentOportunity={oportunitySeletectedToCancel}
        />
      )}
    </Container>
  );
}
