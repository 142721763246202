import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  confirmRegister,
  ConfirmRegisterResponse,
} from '../../services/requests/User/confirmRegister';
import logo from '../../assets/sign-in-logo.svg';
import * as S from './styles';

export function ConfirmRegister() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [response, setResponse] = useState<ConfirmRegisterResponse | false>();
  useEffect(() => {
    async function confirm() {
      if (id) {
        const confirmation = await confirmRegister(id);
        setResponse(confirmation);
        if (confirmation) {
          setTimeout(() => {
            navigate('/');
          }, 3000);
        }
      }
    }
    confirm();
  }, [id, navigate]);

  return (
    <S.container>
      <img src={logo} alt="logo" />
      <h1>{response ? 'Registro completo' : 'Falha ao registrar'}</h1>
    </S.container>
  );
}
