import { LabDsSelect, LabDsTable } from 'v4web-components-react';
import { IData, IOptionsSelect } from 'v4web-components';
import * as S from '../../styles';
import { Unit } from '../../../../hooks/types';

interface UnitsAvailable {
  units: Unit[];
  unitSeleted: string;
  setUnitSelected: (state: string) => void;
  loading: boolean;
}

export function UnitsAvailable({
  units,
  unitSeleted,
  setUnitSelected,
  loading,
}: UnitsAvailable) {
  const rows: IData['rows'] = units?.map((item) => {
    return {
      units: {
        text: { title: item.name },
      },
      locality: {
        text: {
          title: item.address.city,
        },
      },
    };
  });

  const options: IOptionsSelect[] = units?.map((item) => {
    return {
      key: item.unitId,
      label: item.name,
      isChecked: unitSeleted === item.unitId,
    };
  });

  const headers: IData['headers'] = [
    {
      title: 'Franquias disponíveis',
      colKey: 'units',
      type: 'text',
    },
    {
      title: 'Localidade',
      colKey: 'locality',
      type: 'text',
    },
  ];

  return (
    <S.ContentBox>
      <S.ContentHeader>
        <h4>Franquias disponíveis</h4>
      </S.ContentHeader>

      <S.Content>
        <S.Select>
          <LabDsSelect
            className="select"
            avatar={false}
            type="radioButton"
            titleInput="Selecione uma franquia"
            label="Selecione uma franquia"
            options={options}
            onChangeInputOptions={(e) => {
              setUnitSelected(e.detail[0].key);
            }}
          />
        </S.Select>
        <LabDsTable data={{ headers, rows }} loading={loading} />
        {!rows.length && !loading && <h5>Sem franquias disponíveis</h5>}
      </S.Content>
    </S.ContentBox>
  );
}
