import styled from 'styled-components';

export const CustomerLastUpdates = styled.section`
  display: flex;

  justify-content: center;
  flex-direction: column;
  height: 14.5rem;

  padding: 0 2rem;
  margin-top: 2.75rem;

  border-radius: 0.25rem;

  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.12);

  background-color: white;
  p {
    display: flex;
    align-items: center;
    font-weight: 400;
    svg {
      margin-right: 0.625rem;
    }

    :first-child {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddLastUpdateButton = styled.p`
  font-size: 0.75rem;
  color: var(--success-2);
  text-decoration: underline;
  cursor: pointer;
`;

export const HeaderText = styled.div`
  max-width: 80%;

  p {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    color: black;
  }
`;

export const LastUpdates = styled.section`
  display: flex;
  justify-content: start;

  margin-top: 1.5rem;

  overflow: auto;
`;

export const LastUpdate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;

  min-width: 20rem;
  height: 5rem;
  margin-right: 0.5rem;
  border-radius: 0.5rem;

  background-color: #ededed;

  svg {
    :first-child {
      font-size: 1.25rem;
      color: var(--warning);
    }
  }
`;

export const LastUpdateText = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-weight: normal;
    font-size: 0.85rem;
    margin: 0 !important;
    width: 15rem;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }

  a {
    text-decoration: underline;
  }
`;
