import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
  max-height: calc(100vh - 2rem);
  width: 44rem;
  max-width: 44rem;

  border-radius: 8px;
  background-color: var(--white);
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 27px 40px 29px 40px;

  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);

  & > button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 1rem;
    height: 1rem;

    background: transparent;
    border: none;
    color: #83834f;

    & > svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;

  padding: 40px;

  overflow: auto;

  & table {
    tr {
      height: 3.5rem;

      border-bottom: 1px solid #afafaf;
      border-radius: 0;

      p.color-error {
        color: var(--error);
      }

      &:hover {
        background-color: initial;
        border-color: transparent;
        border-bottom-color: #afafaf;
        box-shadow: none;
      }
    }

    & tbody tr .text-wrapper {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    & tbody tr svg {
      cursor: pointer;
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  padding: 1.75rem 2.5rem;
`;
