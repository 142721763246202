import { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { NumberFormatValues } from 'react-number-format';
import { FiArrowRight, FiX } from 'react-icons/fi';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Button, Headline, Smalled } from '../../../LegacyV4Components';

import {
  CustomerFinance,
  getFinanceCustomer,
} from '../../../../services/requests/Finance/getCustomer';
import { FormikCardNumber } from '../../molecules/FormikCardNumber';
import { Modal } from '../../../Default/Modal';
import { TextField } from '../../molecules/TextField';
import { GenerateCheckoutModal } from '../GenerateCheckoutModal';
import { createCustomer } from '../../../../services/requests/Finance/createCustomer';
import { bankList } from '../../../../utils/iuguBankList';
import { SelectField } from '../../molecules/SelectField';
import { FormikAgencyNumber } from '../../molecules/FormikAgencyNumber';
import { useToast } from '../../../../hooks/toast';
import * as S from './styles';
import { createUnitAWS } from '../../../../services/requests/Finance/createUnitAWS';
import { Customer } from '../../../../types/customer';

export function ConfigBankModal({
  isShown,
  setIsShown,
  data,
  userId,
  modalType = 'customer',
}: {
  isShown: boolean;
  setIsShown(value: boolean): any;
  data: Customer;
  userId?: string;
  modalType?: 'unit' | 'customer';
}) {
  const [isOpenGenerate, setIsOpenGenerate] = useState(false);
  const [isConfig, setIsConfig] = useState(true);
  const [selectedBankId, setSelectedBankId] = useState('001');
  const [selectedNumberCard, setSelectedNumberCard] = useState('');
  const [selectedAgencyBank, setSelectedAgencyBank] = useState('');
  const [customerInfo, setCustomerInfo] = useState<CustomerFinance>();
  const [valuesBank, setValuesBank] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const sendFormRef = useRef<HTMLButtonElement>(null);
  const { addToast } = useToast();
  const { id } = useParams();

  const isCustomer: boolean = modalType === 'customer';

  const modalTitle = isCustomer
    ? 'Informações do cliente'
    : 'Informações da unidade';

  const modalSubtitle = isCustomer
    ? 'Preencha as informações referentes ao seu cliente V4'
    : 'Preencha as informações referente a sua unidade';

  const modalDescription = isCustomer
    ? 'Primeiramente, informe os dados do seu cliente V4 Company. Esses dados ficarão salvos após o primeiro preenchimento.'
    : 'Primeiramente, informe os dados da sua unidade. Esses dados ficarão salvos após o primeiro preenchimento.';

  const buttonSubmitDescription = isCustomer
    ? 'Seguir para configuração do checkout'
    : 'Salvar';

  const customerUnitId = data.units[0]._id;

  useEffect(() => {
    async function getBankInfo() {
      const info = await getFinanceCustomer(data?._id);

      if (info) {
        if (isCustomer) setCustomerInfo(info);

        setSelectedBankId(
          bankList.filter((item) => item.label === info?.bank)[0].id,
        );
      }
    }
    getBankInfo();
  }, [isShown, data._id, isCustomer]);

  const bankMasks = useMemo(() => {
    const [selectedBank] = bankList.filter(
      (item) => item.id === selectedBankId,
    );
    return selectedBank;
  }, [selectedBankId]);

  const configBankSchemaCustomer = Yup.object().shape({
    bank: Yup.string().required('O campo é obrigatório!'),
    accountType: Yup.string().required('O campo é obrigatório!'),
    bankAgency: Yup.string(),
    bankCc: Yup.string(),
    businessType: Yup.string().required('O campo é obrigatório!'),
    physicalProducts: Yup.boolean().required('Selecione'),
  });

  const configBankSchemaUnit = Yup.object().shape({
    bank: Yup.string().required('O campo é obrigatório!'),
    accountType: Yup.string().required('O campo é obrigatório!'),
    bankAgency: Yup.string().required('O campo é obrigatório!'),
    bankCc: Yup.string().required('O campo é obrigatório!'),
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          customerId: `${id}`,
          telephone: `${data?.stakeholders[0]?.tel?.replace(/[^0-9.]+/g, '')}`,
          bank: customerInfo?.bank || '',
          bankAgency: customerInfo?.bankAgency || '',
          accountType: customerInfo?.accountType || '',
          bankCc: customerInfo?.bankCc || '',
          responsibleName: `${data?.stakeholders[0]?.name}`,
          responsibleCpf: `${data?.stakeholders[0]?.cpf}`,
          physicalProducts: customerInfo?.physicalProducts || false,
          businessType: customerInfo?.businessType || '',
        }}
        validationSchema={
          isCustomer ? configBankSchemaCustomer : configBankSchemaUnit
        }
        onSubmit={() => {
          setIsConfig(false);
        }}
      >
        {({ errors, values, handleBlur, isValid, dirty, setFieldValue }) => {
          async function handleSubmit() {
            const dataToSendCustomer = {
              ...values,
              bankCc: selectedNumberCard,
              bankAgency: selectedAgencyBank,
            };

            const dataToSendUnit = {
              unitId: customerUnitId,
              bank: values.bank,
              accountType: values.accountType,
              bankCc: selectedNumberCard,
              bankAgency: selectedAgencyBank,
            };

            setIsLoading(true);
            const configBank = isCustomer
              ? await createCustomer(dataToSendCustomer)
              : await createUnitAWS(dataToSendUnit);
            setIsLoading(false);

            if (!configBank) {
              addToast({
                title: 'Ocorreu um erro!',
                description:
                  'Ocorreu um erro durante a configuração de seus dados bancários, caso o erro persista, aguarde ou entre em contato conosco!',
                type: 'error',
              });
            }

            if (configBank) {
              addToast({
                title: 'Configuração feita com sucesso!',
                type: 'success',
              });
            }

            configBank && setValuesBank(values);
            configBank && isCustomer && setIsOpenGenerate(true);
            configBank && setIsShown(false);
          }

          return (
            <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
              <S.ModalWrapper>
                <S.ModalHeader>
                  <Headline variant="h4">{modalTitle}</Headline>
                  <button
                    type="button"
                    onClick={() => setIsShown(false)}
                    aria-label="default"
                  >
                    <FiX />
                  </button>
                </S.ModalHeader>
                <S.ModalBody>
                  <Headline variant="h5">{modalSubtitle}</Headline>
                  <Smalled>{modalDescription}</Smalled>
                  {isConfig ? '' : ''}
                  <Form>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <div
                        style={{
                          marginRight: '8px',
                          marginBottom: '32px',
                          maxWidth: '400px',
                          width: '100%',
                        }}
                      >
                        <SelectField
                          width="400px"
                          name="bank"
                          value={values?.bank}
                          placeholder="Buscar instituição"
                          label="Nome do banco"
                          options={bankList}
                          onChange={(e: any) => {
                            const changeObject = e as {
                              id: string;
                              label: string;
                            };
                            setFieldValue('bank', changeObject.label);
                            setSelectedBankId(changeObject.id);
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div
                        style={{
                          maxWidth: '214px',
                          width: '100%',
                        }}
                      >
                        <SelectField
                          name="accountType"
                          value={values.accountType}
                          placeholder="Selecione a conta"
                          label="Tipo de conta"
                          options={[
                            { value: 'Corrente', label: 'Corrente' },
                            { value: 'Poupança', label: 'Poupança' },
                          ]}
                          onBlur={handleBlur}
                          onChange={(e: any) => {
                            const changeObject = e as {
                              id: string;
                              label: string;
                            };
                            setFieldValue('accountType', changeObject.label);
                          }}
                          width="214px"
                        />
                      </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '8px', marginBottom: '32px' }}>
                        <FormikAgencyNumber
                          mask={bankMasks.placeholderAgency}
                          max={bankMasks.maxAgency}
                          min={bankMasks.maxAgency}
                          width="214px"
                          name="bankAgency"
                          status="default"
                          label="Agência"
                          value={values?.bankAgency}
                          placeholder={bankMasks.placeholderAgency}
                          helper={errors?.bankAgency}
                          validateOnChange={false}
                          onBlur={handleBlur}
                          onValueChange={(val: NumberFormatValues) => {
                            setSelectedAgencyBank(val as unknown as string);
                            setFieldValue(`bankAgency`, val);
                          }}
                        />
                      </div>
                      <FormikCardNumber
                        mask={bankMasks.placeholderAccount}
                        max={bankMasks.maxAccount}
                        min={bankMasks.maxAccount}
                        width="400px"
                        name="bankCc"
                        status="default"
                        label="Número da conta"
                        value={values?.bankCc}
                        placeholder={bankMasks.placeholderAccount}
                        helper={errors?.bankCc}
                        validateOnChange={false}
                        onBlur={handleBlur}
                        onValueChange={(val: NumberFormatValues) => {
                          setSelectedNumberCard(val as unknown as string);
                          setFieldValue(`bankCc`, val);
                        }}
                      />
                    </div>
                    {isCustomer && (
                      <TextField
                        status="default"
                        width="400px"
                        name="businessType"
                        label="Tipo de negócio"
                        value={values.businessType}
                        placeholder="Digite o tipo de negócio do cliente"
                        helper={errors.businessType}
                        onBlur={handleBlur}
                      />
                    )}
                    {isCustomer && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Headline variant="h6">Produto físico?</Headline>
                        <S.ContainerProductType>
                          <S.ContainerRadio
                            color={
                              values.physicalProducts
                                ? '1px solid var(--success-2)'
                                : ''
                            }
                            onClick={() => {
                              setFieldValue('physicalProducts', true);
                            }}
                          >
                            <input
                              name="physicalProducts"
                              type="radio"
                              checked={values?.physicalProducts === true}
                              onChange={() => {
                                setFieldValue('physicalProducts', true);
                              }}
                            />
                            <p>Sim</p>
                          </S.ContainerRadio>
                          <S.ContainerRadio
                            color={
                              !values.physicalProducts
                                ? '1px solid var(--success-2)'
                                : ''
                            }
                            onClick={() => {
                              setFieldValue('physicalProducts', false);
                            }}
                          >
                            <input
                              name="physicalProducts"
                              type="radio"
                              checked={values?.physicalProducts === false}
                              onChange={() => {
                                setFieldValue('physicalProducts', false);
                              }}
                            />
                            <p>Não</p>
                          </S.ContainerRadio>
                        </S.ContainerProductType>
                      </div>
                    )}
                    <button
                      ref={sendFormRef}
                      type="submit"
                      style={{ display: 'none' }}
                    >
                      Enviar
                    </button>
                  </Form>
                </S.ModalBody>
                <S.ModalFooter>
                  <Button
                    variant="primary"
                    color="green"
                    size="default"
                    rightIcon={
                      !isLoading ? (
                        <FiArrowRight />
                      ) : (
                        <S.ContainerLoading>
                          <CircularProgress
                            sx={{ width: '1rem', height: '1rem' }}
                          />
                        </S.ContainerLoading>
                      )
                    }
                    onClick={async () => {
                      handleSubmit();
                    }}
                    disabled={!(isValid && dirty)}
                  >
                    {buttonSubmitDescription}
                  </Button>
                </S.ModalFooter>
              </S.ModalWrapper>
            </Modal>
          );
        }}
      </Formik>

      <GenerateCheckoutModal
        recipientId={userId}
        isShown={isOpenGenerate}
        setIsShown={setIsOpenGenerate}
        valuesBank={valuesBank}
      />
    </>
  );
}
