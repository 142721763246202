import { GoogleLogin, GoogleCredentialResponse } from '@react-oauth/google';

import { useState } from 'react';
import { Loading } from '../../components/LegacyV4Components';
import * as S from './styles';

import SignInLogo from '../../assets/sign-in-logo.svg';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

export function SignIn() {
  const { signIn } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const plataform = params.get('plataform');

  const handleLogin = async (e: GoogleCredentialResponse) => {
    setLoading(true);
    const { credential } = e as GoogleCredentialResponse;

    if (!credential) return;

    await signIn({ tokenId: credential, plataform: plataform as string });
  };

  const handleLoginFailed = () => {
    addToast({
      type: 'error',
      title: 'Erro ao realizar o login',
    });
  };

  return (
    <S.Container>
      <S.Content>
        <S.AnimationContainer>
          <img src={SignInLogo} alt="MktLab" style={{ marginBottom: '2rem' }} />

          {loading ? (
            <S.Loading>
              <Loading containerStyles={{ width: '2rem' }} />
            </S.Loading>
          ) : (
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                handleLogin(credentialResponse);
              }}
              useOneTap
              onError={() => {
                handleLoginFailed();
              }}
              text="signin_with"
            />
          )}
        </S.AnimationContainer>

        <S.Terms>
          <a
            href={`${process.env.REACT_APP_MEDIAHUB_URL}/terms`}
            target="_blank"
            rel="noreferrer"
          >
            Termos de Serviço
          </a>
          <a
            href="https://v4company.com/politica-de-privacidade"
            target="_blank"
            rel="noreferrer"
          >
            Políticas de Privacidade
          </a>
        </S.Terms>
      </S.Content>

      <S.Background />
    </S.Container>
  );
}
