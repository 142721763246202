import React from 'react';
import { FiX } from 'react-icons/fi';

import * as S from './styles';

interface ModalProps {
  children: React.ReactNode;
  onBackground?: () => void;
  title?: string;
  closeButton?: boolean;
  show: boolean;
  containerStyle?: React.CSSProperties;
  size?: 'sm' | 'md' | 'lg';
  header: React.ReactNode;
  footer?: React.ReactNode;
}

export function ModalRight({
  children,
  onBackground,
  show,
  title,
  closeButton,
  containerStyle,
  size = 'md',
  header,
  footer,
}: ModalProps) {
  return (
    <S.Container show={show} style={containerStyle}>
      <S.ModalContent size={size}>
        {(title || closeButton) && (
          <S.ModalHeader>
            {header}
            {closeButton && <FiX onClick={onBackground} />}
          </S.ModalHeader>
        )}

        <main>{children}</main>

        {footer && <S.ModalFooter>{footer}</S.ModalFooter>}
      </S.ModalContent>

      <S.Background onClick={onBackground} />
    </S.Container>
  );
}
