import { newAds } from '../../api';

interface ResponseProps {
  pendingAccounts: { id: string; name: string; authToken: string }[];
  registeredAccounts: {
    _id: string;
    userId: string;
    unitId: string;
    name: string;
    authToken: string;
    type: string;
    integrationId: string;
    customerId: string;
    projectId: string;
    customerNewId: string;
    status: number;
    createdAt: Date;
    updatedAt: Date;
  }[];
}

async function getFacebookAdsIntegrations(): Promise<
  ResponseProps | undefined
> {
  const response = await newAds.get<ResponseProps>(
    '/facebookAccounts/business',
  );

  if (!response) {
    return undefined;
  }

  const accounts = response.data;

  return accounts;
}

export { getFacebookAdsIntegrations };
