import { useNavigate } from 'react-router-dom';
import {
  Button,
  NotFound,
  Headline,
} from '../../components/LegacyV4Components';

import NotFoundImage from '../../assets/not_found.svg';
import * as S from './styles';

export function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <S.Container>
      <NotFound img={NotFoundImage} />

      <Headline variant="h5">Ou sua sessão expirou</Headline>

      <Button
        variant="primary"
        size="default"
        color="green"
        onClick={() => navigate('/')}
      >
        Voltar para o login
      </Button>
    </S.Container>
  );
}
