import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { financeApi, midiaWalletApi } from '../../api';

export interface IQueryWallet {
  data: IDataQueryWallet;
  status: number;
  statusText: string;
  headers: Headers;
  request: Request;
}

export interface IDataQueryWallet {
  _id: string;
  customerId: string;
  balance: number;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface IHistoryWallet {
  data: IDataHistoryWallet[];
}

export interface IDataHistoryWallet {
  _id: string;
  type: string;
  customerId: string;
  amount: number;
  invoiceId?: string;
  status: string;
  invoiceUrl?: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  payInvoiceId?: string;
}

export function useQueryWallet(
  customerId: string | undefined,
): UseQueryResult<{ data: IDataQueryWallet }> {
  return useQuery<{ data: IDataQueryWallet }>(
    ['Wallet', customerId],
    () => financeApi.get(`/media-wallet/${customerId}`),
    {},
  );
}

export function useQueryWalletHistory(
  customerId: string | undefined,
): UseQueryResult<{ data: IDataHistoryWallet[] }> {
  return useQuery<{ data: IDataHistoryWallet[] }>(
    ['WalletHistory', customerId],
    () => midiaWalletApi.get(`/media-wallet/transaction/${customerId}/all`),
    {},
  );
}

export function useQueryWalletBalance(
  customerId: string | undefined,
): UseQueryResult<{ data: number }> {
  return useQuery<{ data: number }>(
    ['WalletBalance', customerId],
    () => midiaWalletApi.get(`/media-wallet/balance/${customerId}`),
    {},
  );
}
