import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { financeApi } from '../../api';

export function useGetDepositById(
  depositId: string | undefined,
): UseQueryResult<{ data: any }, { data: any }> {
  return useQuery<{ data: any }, { data: any }>(
    ['Wallet', depositId],
    () => financeApi.get(`/media-wallet/deposit/${depositId}`),
    {},
  );
}
