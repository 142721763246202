import { financeApi } from '../../api';

export interface createProductHQ {
  products: Product[];
  payableWith: string[];
  feeResponsible: string;
  creditCardInstallments: number;
  physicalProduct: boolean;
}

export interface Product {
  id: string;
  value: number;
}

export async function createCheckoutHQ(
  data: any,
): Promise<createProductHQ | false> {
  try {
    const response = await financeApi.post<createProductHQ>(
      `/checkouts/HQ`,
      data,
    );
    const product = response.data;

    return product;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o checkout, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
