import styled from 'styled-components';

interface TitleProps {
  textColor?: string;
}

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const Title = styled.h1<TitleProps>`
  flex: 1;
  color: var(--black);
`;
