import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export function TiktokIntegration() {
  const [params] = useSearchParams();

  useEffect(() => {
    if (window.opener) {
      const code = params.get('code');
      const authCode = params.get('auth_code');
      const state = params.get('state');

      window.opener.postMessage(
        {
          error: undefined,
          status: 'ok',
          code,
          authCode,
          state,
        },
        '*',
      );
    }
  }, [params]);

  return <div>Conta TikTok Ads autenticada</div>;
}
