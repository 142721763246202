import { IData } from 'v4web-components';

export const summaryDataHeaders: IData['headers'] = [
  {
    title: 'Cliente',
    colKey: 'name',
    type: 'text',
  },
  {
    title: 'Conexão de contas',
    colKey: 'accounts',
    type: 'text',
    icon: 'add',
    sortable: true,
  },
  {
    title: 'Painel de resultados',
    colKey: 'button',
    type: 'text',
  },
];
