import { FiArrowRight, FiMinus, FiPlus } from 'react-icons/fi';
import { Button } from '../../../LegacyV4Components';
import * as S from './styles';
import { FormikPaymentOptions } from '../../../../pages/PaymentOptions';
import { FormikPlansOptions } from '../../../../pages/PlanOptions';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';
import { ICheckoutById } from '../../../../services/requests/Finance/getCheckoutById';

interface PropsTickets {
  values: FormikPaymentOptions | FormikPlansOptions;
  dataCheckout: ICheckoutById | undefined;
  indexTab: number;
  setIndexTab: React.Dispatch<React.SetStateAction<number>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}

export function TicketsLookingAhead({
  values,
  dataCheckout,
  indexTab,
  setIndexTab,
  setFieldValue,
}: PropsTickets) {
  const valueTicket = dataCheckout?.value;
  return (
    <S.Container>
      <S.TableInfo>
        <h1>{dataCheckout?.products[0]?.name}</h1>
        <div className="containerContent">
          <p className="descriptionContent">
            {dataCheckout?.products[0]?.description}
          </p>
          <h1 className="valueContent">
            {valueTicket && values?.quantity
              ? convertNumberToBRL(valueTicket * values?.quantity)
              : '...'}
          </h1>
          <div className="ContainerSelectNumber">
            <S.DecrescentNumber
              onClick={(e) => {
                e.preventDefault();
                values?.quantity &&
                  setFieldValue('quantity', values?.quantity - 1);
              }}
              disabled={values?.quantity === 0}
            >
              <FiMinus />
            </S.DecrescentNumber>
            <input type="number" value={values?.quantity} />
            <S.CrescentNumber
              onClick={(e) => {
                e.preventDefault();
                values?.quantity &&
                  setFieldValue('quantity', values?.quantity + 1);
              }}
            >
              <FiPlus />
            </S.CrescentNumber>
          </div>
        </div>
      </S.TableInfo>
      <div style={{ float: 'right', marginTop: '48px' }}>
        <Button
          variant="primary"
          rightIcon={<FiArrowRight />}
          color="green"
          size="default"
          onClick={() => setIndexTab(indexTab + 1)}
        >
          Continuar
        </Button>
      </div>
    </S.Container>
  );
}
