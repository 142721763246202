import { sdrWhatsappBot } from '../../api';
import { ISdrLeads } from '../../../types/sdrBotMessages';

export async function getSdrLeads(): Promise<ISdrLeads[] | undefined> {
  try {
    const response = await sdrWhatsappBot.get(`/leads`);

    const { leads } = response.data as { leads: ISdrLeads[] };

    return leads;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os leads, atualize a página e tente novamente 😕`,
      err,
    );
    return undefined;
  }
}
