import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 704px;
  max-width: 95vw;

  height: 100vh;
  max-height: 88vh;

  border-radius: 0.5rem;
  background-color: var(--white);
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  padding: 0rem 1.5rem;

  & > button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 1rem;
    height: 1rem;

    background: transparent;
    border: none;
    color: #8383af;

    & > svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-itens: center;
  flex-direction: column;
  text-align: center;

  padding: 1.5rem 1.5rem;

  overflow: auto;

  svg {
    color: #e03131;
    width: 4rem;
    text-align: center;
    height: 4rem;
  }

  .label {
    margin-bottom: 1rem;
  }

  & > small {
    margin-bottom: 1rem;
  }

  h4 {
    margin-bottom: 1.5rem;
  }
`;

export const Title = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  .confirmation {
    text-align: left;
  }
  h6 {
    margin-bottom: 0.5rem;
  }

  span {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const Status = styled.div`
  background-color: ${(props) => props.color};
  padding: 0.2rem 1rem;
  border-radius: 4px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

export const Confirmation = styled.div`
  padding: 1rem;
  margin: 0.5rem;
  margin-top: 1.5rem;
  background-color: #f4f4f8;
  border: 1px solid #e03131;
  border-radius: 4px;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-itens: center;
  margin-top: 1rem;
`;
