import styled from 'styled-components';

export const Container = styled.div`
  border-top: 1px solid #c1c1d7;
  border-bottom: 1px solid #c1c1d7;
  padding: 24px 16px 24px 16px;
`;

export const ContainerHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  opacity: ${(props) => props.color};
  .title {
    margin: 0px;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => props.color};
  p {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
  }
`;

export const ButtonToDisable = styled.button`
  cursor: auto;
  background: #f4f4f8;
  border-radius: 4px;
  padding: 4px 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 12px;
    color: #333333;
    font-weight: 400;
  }
`;

export const ContainerInfo = styled.section`
  opacity: ${(props) => props.color};
  display: flex;
  flex-direction: column;
  p {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
  }
`;

export const ButtonCopyLink = styled.button`
  background: #fff;
  border: none;
  display: flex;
  margin-top: 30px;
  p {
    margin-right: 8px;
    color: #00c49a;
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline;
  }
  svg {
    color: #00c49a;
  }
`;
