import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 46.25rem;

  padding: 1.5rem 2rem;
  width: 100%;

  background-color: var(--white);
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);

  .delete-column {
    text-align: right;
    cursor: pointer;
  }
`;

export const Header = styled.section`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.section`
  width: 100%;

  overflow: auto;
`;

export const StakeholderCard = styled.div<{ main?: boolean }>`
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 17.5rem;

  margin-bottom: 1.5rem;
  padding: 2rem;
  border: 1px solid black;

  ${({ main }) =>
    main &&
    `
      border: 1px solid var(--success-2);
    `}

  border-radius: 0.25rem;
`;

export const PersonalInfo = styled.section<{ main?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 15rem;
  height: 17.4rem;

  margin: -1.98rem;

  text-align: center;

  border-right: 1px solid black;

  ${({ main }) =>
    main &&
    `
      background-color: rgba(230, 249, 245, 0.5);
      border-right: 1px solid var(--success-2);
    `}

  svg {
    border: 1px solid black;
    ${({ main }) => main && `border: 2px solid var(--success-2);`}
    border-radius: 50%;

    padding: 0.25rem;
    width: 3rem;
    height: 3rem;
  }

  .profile_outline {
    background-color: white;
  }

  .start-icon {
    color: var(--success-2);

    margin-bottom: 0.2rem;
    width: 1.6rem;
    height: 1.6rem;
    border: none;
  }

  h4 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;

    p {
      width: 8rem;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Info = styled.section`
  width: 23.75rem;
  h5 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 0.75rem;

    border-bottom: 1px solid black;

    font-size: 1rem;
  }
`;

export const Data = styled.div<{ completedGrowthClass?: boolean }>`
  margin-top: 1.5rem;

  p {
    display: flex;
    align-items: center;

    margin-bottom: 1rem;
  }

  svg {
    margin-left: 0.5rem;

    color: var(--success-1);
    ${({ completedGrowthClass }) =>
      completedGrowthClass
        ? 'color: var(--success-1)'
        : 'color: var(--primary-dark-3)'}
  }
`;

export const InviteStatus = styled.div<{ lastAccess: boolean }>`
  p {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 11rem;

    font-weight: bold;

    ${({ lastAccess }) =>
      lastAccess
        ? 'border: 2px solid var(--success-2);'
        : 'border: 2px solid var(--primary-dark-1);'}
    border-radius: 1rem;

    svg {
      ${({ lastAccess }) =>
        lastAccess
          ? 'color: var(--success-2)'
          : 'color: var(--primary-dark-1);'}
    }
  }
`;
