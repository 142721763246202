export const formatPhone = (phone: string) => {
  return String(phone).replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2.$3-$4');
};

export function formatPhoneWithCountryCode(numberString: string) {
  const numericString = numberString.replace(/\D/g, '');

  // handle extra '9' digit
  const isExtraDigit = numericString.length === 13;

  const pattern = isExtraDigit
    ? /^(\d{2})(\d{2})(\d{5})(\d{4})$/
    : /^(\d{2})(\d{2})(\d{4})(\d{4})$/;

  const groups = numericString.match(pattern);

  if (!groups) {
    return 'Invalid number string';
  }

  const formattedString = isExtraDigit
    ? `+${groups[1]} (${groups[2]}) ${groups[3]}-${groups[4]}`
    : `+${groups[1]} (${groups[2]}) ${groups[3].substring(
        0,
        4,
      )}-${groups[3].substring(4)}${groups[4]}`;

  return formattedString;
}
