export const permissionTransition = [
  {
    label: 'Não visualizar',
    value: 'CAN_NOT_SEE',
    desc: 'Ambiente de transições desabilitado para tal pessoa investidora',
  },
  {
    label: 'Apenas visualizar ofertas',
    value: 'READ_ONLY',
    desc: 'Permissão apenas para visualizar os projetos ofertados para transições disponiveis',
  },
  {
    label: 'Transicionar projeto',
    value: 'TRANSITION_ONLY',
    desc: 'Permissão para disponibilizar projetos para o processo de transição',
  },
  {
    label: 'Demonstrar interesse',
    value: 'INTEREST_ONLY',
    desc: 'Permissão apenas para demonstrar interesse nos projetos ofertados para transição',
  },
  {
    label: 'Administrador',
    value: 'ADMIN',
    desc: 'Permissão para realizar todas as ações referente a transição de projetos',
  },
];
