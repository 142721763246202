import { newAds } from '../../api';

interface ResponseData {
  access_token: string;
  advertiser_ids: string[];
}

export async function getTiktokTokens(
  auth_code: string,
): Promise<ResponseData> {
  const { data } = await newAds.post<ResponseData>(
    `/customerIntegration/getTiktokToken`,
    { auth_code },
  );
  return data;
}
