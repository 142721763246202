import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { newAds } from '../../api';

async function findIntegrationsByProjectId(
  projectId: string,
): Promise<AdsAccounts[]> {
  const { data } = await newAds.get<AdsAccounts[]>(
    `/customerIntegration/project/${projectId}`,
  );

  const adsAccounts = data;
  return adsAccounts;
}

export function useFindIntegrationsByProjectId(
  projectId: string,
  type?: string,
): UseQueryResult<AdsAccounts[]> {
  return useQuery<AdsAccounts[]>(
    ['get-account-integrations-user', projectId, type],
    () => findIntegrationsByProjectId(projectId),
  );
}
