import React from 'react';
import { useNavigate } from 'react-router-dom';
import USER_IMG_NOT_FOUND from '../../../../assets/images/user_img_not_found.png';
import * as S from './styles';
import { handlerClick } from '../../../../hooks/actions';
import { goProfile } from '../../../../hooks/goProfile';

interface AvatarTableProps {
  _id: string;
  name: string;
  picture: string;
  routes: {
    title: string;
    link: string;
  }[];
}

export const AvatarTable = ({
  _id,
  name,
  picture,
  routes,
}: AvatarTableProps) => {
  const navigate = useNavigate();

  const handlerGoProfile = (id: string, userName: string) => {
    return navigate(goProfile({ id, userName, routes }));
  };

  return (
    <div onClick={(e) => handlerClick(e)} aria-hidden="true">
      <S.Avatar
        onClick={() => handlerGoProfile(_id, name)}
        src={picture || USER_IMG_NOT_FOUND}
        alt={name}
      />
    </div>
  );
};
