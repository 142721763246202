import styled from 'styled-components';

export const Link = styled.div`
  text-decoration: underline;
  color: var(--success-1);
  cursor: pointer;
`;

export const DataGrid = styled.div`
  td {
    max-width: 15rem;
    overflow: hidden;
    p {
      max-width: 11rem;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
  border-bottom: none;

  :first-of-type {
    font-size: 1rem;
    line-height: 1.313rem;
  }
  a {
    font-weight: bold;
    text-decoration: underline;

    display: flex;
    align-items: center;

    svg {
      margin-right: 0.625rem;
    }
  }
`;

export const Avatar = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  width: 2rem !important;
  height: 2rem !important;
  background: var(--primary-dark);
  margin-right: 5px;
`;

export const Infomation = styled.div`
  .icon {
    display: flex;
    align-items: center;
  }

  .message {
    display: none;
  }
  :hover {
    cursor: pointer;
    .message {
      display: inline-block;
      position: relative;
      top: -2.5rem;
      p {
        display: inline-block;
        position: absolute;
        min-width: fit-content;
        background-color: white;
        border: solid 1px var(--grayscale-4);
        padding: 0.2rem 0.5rem;
        left: 95%;
      }
    }
  }
`;

export const RowTable = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1.5rem 0 1.5rem;
  button {
    margin: 0 !important;
  }
`;
