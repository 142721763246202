import React, { useState } from 'react';

import { IoWarningOutline } from 'react-icons/io5';
import { FiX } from 'react-icons/fi';
import { Button, Headline, Input } from '../../../LegacyV4Components';
import { Modal } from '../../../Default/Modal';

import * as S from './styles';
import { useToast } from '../../../../hooks/toast';

export function ActivePlanModal({
  isShown,
  setIsShown,
  data,
}: {
  isShown: boolean;
  setIsShown(value: boolean): any;
  data: any;
}) {
  const [name, setName] = useState('');
  const { addToast } = useToast();
  data;

  return (
    <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
      <>
        <S.ModalWrapper>
          <S.ModalHeader>
            <button
              type="button"
              onClick={() => setIsShown(false)}
              aria-label="default"
            >
              <FiX />
            </button>
          </S.ModalHeader>
          <S.ModalBody>
            <div>
              <IoWarningOutline />
            </div>
            <Headline variant="h4">
              Você está preste a reativar um plano!
            </Headline>

            <S.Title>
              <p>Ao reativar o plano você poderá voltar a alterá-lo.</p>
            </S.Title>
            <S.Title>
              <p>As futuras cobranças serão reativadas novamente!</p>
            </S.Title>
            <S.Confirmation>
              <S.Title>
                <p className="confirmation">
                  Para confirmar sua intenção, digite abaixo o nome do cliente
                  que você deseja reativar o plano.
                </p>
              </S.Title>
              <Input
                label=""
                placeholder="Nome do cliente aqui"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </S.Confirmation>
            <S.Button>
              <Button
                onClick={() => {
                  setIsShown(false);
                  addToast({
                    type: 'success',
                    title: 'Plano ativado com sucesso!',
                  });
                }}
                variant="primary"
                color="green"
                size="default"
              >
                Ativar
              </Button>
            </S.Button>
          </S.ModalBody>
        </S.ModalWrapper>
      </>
    </Modal>
  );
}
