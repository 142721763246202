import React from 'react';
import * as S from './styles';

interface ContainerProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export function Container({ children, style }: ContainerProps) {
  return <S.Container style={style}>{children}</S.Container>;
}
