import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { SelectValue } from '../components/AtomicDesign/atoms/SelectQuery';

import { getAccountIntegrationByUnit } from '../services/requests/Ads/getAccountIntegrationsByUnit';
import { createAccountIntegration } from '../services/requests/Ads/createAccountIntegration';
import { updateAccountIntegration } from '../services/requests/Ads/updateAccountIntegration';
import { getTokens } from '../services/requests/Ads/kommoAuth';
import { getTiktokTokens } from '../services/requests/Ads/getTiktokTokens';

import { useAuth } from './auth';

export type CustomerIntegrationsContextData = {
  integrations: AdsAccounts[];
  setIntegrations: Dispatch<SetStateAction<AdsAccounts[]>>;
  setIntegration: (integration: AdsAccounts) => void;
  setIntegrationId: (integration: AdsAccounts, integrationId: string) => void;
  setLink: (integration: AdsAccounts, link: string) => void;
  setValue: (integration: AdsAccounts, value?: SelectValue<IProject>) => void;
  setClientSecret: (integration: AdsAccounts, clientSecret: string) => void;
  setAccountName: (integration: AdsAccounts, accountName: string) => void;
  setAppToken: (integration: AdsAccounts, appToken: string) => void;
  setBitrixFields: (integration: AdsAccounts, link: string) => void;
  handleAddKommo: (integrationId: string) => void;
  handleAddTiktok: () => void;
  timestamp: number;
  validate: (
    additionalValidation?: (integration: AdsAccounts) => boolean,
  ) => string[];
  createIntegration: (integration: AdsAccounts) => void;
  deleteIntegration: (integration: AdsAccounts) => void;
  saveIntegrations: () => Promise<void>;
};

export const CustomerIntegrationsContext = createContext(
  {} as CustomerIntegrationsContextData,
);

type CustomerIntegrationsContextProviderProps = {
  children: ReactNode;
  type: string;
  show: boolean;
};

export const CustomerIntegrationsProvider: React.FC<
  CustomerIntegrationsContextProviderProps
> = ({ children, type, show }: CustomerIntegrationsContextProviderProps) => {
  const [integrations, setIntegrations] = useState<AdsAccounts[]>([]);
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const { user } = useAuth();

  const loadIntegrations = useCallback(async () => {
    const data = await getAccountIntegrationByUnit(user.unitId, type);
    if (data) {
      setIntegrations(data as AdsAccounts[]);
    }
    setTimestamp(new Date().getTime());
  }, [type, user]);

  useEffect(() => {
    loadIntegrations();
  }, [loadIntegrations, show]);

  function findIndex(integration: AdsAccounts): number {
    return integrations.findIndex((i) => i._id === integration._id);
  }

  function setIntegration(integration: AdsAccounts) {
    const tempIntegrations = [...integrations];
    const index = findIndex(integration);
    tempIntegrations[index] = integration;
    setIntegrations(tempIntegrations);
  }

  function setIntegrationId(integration: AdsAccounts, integrationId: string) {
    const tempIntegrations = [...integrations];
    const index = findIndex(integration);
    tempIntegrations[index].integrationId = integrationId;
    setIntegrations(tempIntegrations);
  }

  function setLink(integration: AdsAccounts, link: string) {
    const tempIntegrations = [...integrations];
    const index = findIndex(integration);
    tempIntegrations[index].link = link;
    setIntegrations(tempIntegrations);
  }

  function setValue(integration: AdsAccounts, value?: SelectValue<IProject>) {
    const tempIntegrations = [...integrations];
    const index = findIndex(integration);
    tempIntegrations[index].value = value;
    tempIntegrations[index].projectId = value?.value._id ?? '';
    tempIntegrations[index].customerNewId = value?.value.customerId ?? '';
    tempIntegrations[index].customerId = value?.value.customerId ?? '';

    setIntegrations(tempIntegrations);
  }

  function setClientSecret(integration: AdsAccounts, clientSecret: string) {
    const tempIntegrations = [...integrations];
    const index = findIndex(integration);
    tempIntegrations[index].clientSecret = clientSecret;
    setIntegrations(tempIntegrations);
  }

  function setAccountName(integration: AdsAccounts, accountName: string) {
    const tempIntegrations = [...integrations];
    const index = findIndex(integration);
    tempIntegrations[index].accountName = accountName;
    setIntegrations(tempIntegrations);
  }

  function setAppToken(integration: AdsAccounts, appToken: string) {
    const tempIntegrations = [...integrations];
    const index = findIndex(integration);
    tempIntegrations[index].appToken = appToken;
    setIntegrations(tempIntegrations);
  }

  function setBitrixFields(integration: AdsAccounts, link: string) {
    const tempIntegrations = [...integrations];
    const index = findIndex(integration);

    try {
      const url = new URL(link);
      const accountName = url.hostname.split('.')[0];
      const authKey = url.pathname
        .split('/')
        .reverse()
        .find((item) => item.length);

      tempIntegrations[index].link = link;
      tempIntegrations[index].accountName = accountName;
      tempIntegrations[index].authKey = authKey;
      tempIntegrations[index].integrationId = `${accountName}-${authKey}`;
    } catch (error) {
      tempIntegrations[index].link = link;
    } finally {
      setIntegrations(tempIntegrations);
    }
  }

  function setUpdatedIntegration(integration: {
    integrationId: string;
    subdomain?: string;
    clientSecret?: string;
    authCode?: string;
    authKey?: string;
    refreshKey?: string;
    referer?: string;
    authToken?: string;
  }): AdsAccounts | undefined {
    const tempIntegrations = [...integrations];
    const foundIntegration = integrations.find(
      (i) => i.integrationId === integration.integrationId,
    );
    if (foundIntegration) {
      const index = integrations.indexOf(foundIntegration);
      const updatedIntegration = { ...foundIntegration, ...integration };
      tempIntegrations.splice(index, 1, updatedIntegration);
      setIntegrations(tempIntegrations);
      return updatedIntegration;
    }
    return undefined;
  }

  let popup: Window | null;

  async function updateAuthInfo(integration: {
    integrationId: string;
    subdomain: string;
    authCode: string;
    referer: string;
  }) {
    let updatedIntegration = setUpdatedIntegration(integration);
    if (updatedIntegration) {
      const result = await getTokens(updatedIntegration);
      const { access_token, refresh_token } = result;
      updatedIntegration.authKey = access_token;
      updatedIntegration.refreshKey = refresh_token;
      updatedIntegration = setUpdatedIntegration(updatedIntegration);
    }
  }

  async function updateTiktok(code: string) {
    const { access_token, advertiser_ids } = await getTiktokTokens(code);

    const newAndUpdatedIntegrations: AdsAccounts[] = advertiser_ids?.map(
      (id) => {
        const registeredIntegration = integrations.find(
          (integration) => integration.integrationId === id,
        );
        if (registeredIntegration) {
          return {
            ...registeredIntegration,
            appToken: access_token,
          };
        }
        return {
          _id: id,
          type: 'TIKTOK_ADS',
          projectId: '',
          customerNewId: '',
          customerId: '',
          isNew: true,
          integrationId: id,
          appToken: access_token,
          status: 0,
        };
      },
    );

    const registeredIntegrations = integrations.filter(
      (integration) =>
        !newAndUpdatedIntegrations?.find(
          (toRemove) => toRemove._id === integration._id,
        ),
    );

    setIntegrations([...newAndUpdatedIntegrations, ...registeredIntegrations]);
  }

  async function handleMessageEvent(
    e: MessageEvent<{
      status: string;
      code: string;
      authCode: string;
      referer: string;
      subdomain: string;
      clientId: string;
      state: string;
      error?: string;
    }>,
  ) {
    if (e.data?.error) {
      return;
    }

    const { data } = e;
    const { state } = data;

    if (state === 'kommo') {
      const { clientId, referer, code, subdomain } = data;
      const integration = {
        integrationId: clientId,
        subdomain,
        authCode: code,
        referer,
      };
      await updateAuthInfo(integration);
      if (popup) {
        popup.close();
      }
      window.removeEventListener('message', handleMessageEvent);
    }

    if (state === 'tiktok') {
      const { code } = data;
      await updateTiktok(code);
      if (popup) {
        popup.close();
      }
      window.removeEventListener('message', handleMessageEvent);
    }
  }

  function handleAddKommo(integrationId: string) {
    const state = 'kommo';
    popup = window.open(
      `https://www.kommo.com/oauth?client_id=${integrationId}&state=${state}&mode=post_message`,
      'Allow Access',
      'scrollbars, status, resizable, width=750, height=580',
    );

    window.addEventListener('message', handleMessageEvent);
  }

  function handleAddTiktok() {
    const state = 'tiktok';
    const url = `https://business-api.tiktok.com/portal/auth?app_id=${process.env.REACT_APP_TIKTOK_APP_ID}&state=${state}&redirect_uri=${process.env.REACT_APP_TIKTOK_REDIRECT_URI}`;
    popup = window.open(
      url,
      'Allow Access',
      'scrollbars, status, resizable, width=750, height=580',
    );

    window.addEventListener('message', handleMessageEvent);
  }

  function validate(
    additionalValidation?: (integration: AdsAccounts) => boolean,
  ): string[] {
    const errors: string[] = [];
    integrations.forEach((integration) => {
      if (integration.isDeleted) return;
      if (!integration.integrationId || !integration.projectId) {
        errors.push(integration._id);
      }
      if (additionalValidation && !additionalValidation(integration)) {
        errors.push(integration._id);
      }
    });
    return errors;
  }

  function createIntegration(integration: AdsAccounts): void {
    const tempIntegrations = [integration];
    setIntegrations(tempIntegrations.concat(integrations));
  }

  async function deleteIntegration(integration: AdsAccounts) {
    const tempIntegrations = [...integrations];
    const index = findIndex(integration);
    tempIntegrations[index].isDeleted = true;

    if (tempIntegrations[index].isNew) {
      tempIntegrations.splice(index, 1);
    }
    setIntegrations(tempIntegrations);
  }

  async function saveIntegrations() {
    const newItems = integrations
      .filter((integration) => !integration._id || integration.isNew)
      .map((integration) => ({
        projectId: integration.projectId,
        customerNewId: integration.customerNewId,
        customerId: integration.customerId,
        integrationId: integration.integrationId,
        type: integration.type,
        link: integration.link,
        clientSecret: integration.clientSecret,
        subdomain: integration.subdomain,
        authKey: integration.authKey,
        refreshKey: integration.refreshKey,
        accountName: integration.accountName,
        appToken: integration.appToken,
        authToken: integration.authToken,
        status: integration.status,
        unitId: user.unitId,
        userId: user._id,
      }));

    const editedItems = integrations.filter(
      (integration) => !!integration._id && !integration.isNew,
    );

    if (newItems) {
      await createAccountIntegration(newItems);
    }
    if (editedItems) {
      await updateAccountIntegration(editedItems);
    }

    loadIntegrations();
  }

  return (
    <CustomerIntegrationsContext.Provider
      value={{
        integrations,
        setIntegrations,
        setIntegration,
        setIntegrationId,
        setLink,
        setValue,
        setClientSecret,
        setAccountName,
        setAppToken,
        setBitrixFields,
        handleAddKommo,
        handleAddTiktok,
        timestamp,
        validate,
        createIntegration,
        deleteIntegration,
        saveIntegrations,
      }}
    >
      {children}
    </CustomerIntegrationsContext.Provider>
  );
};

export const useCustomerIntegrations = (): CustomerIntegrationsContextData => {
  return useContext(CustomerIntegrationsContext);
};
