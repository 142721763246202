import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 0.3rem;
  border-top-left-radius: 0;
  width: 68%;
  @media (max-width: 880px) {
    width: auto;
  }
`;

export const ApprovedPayment = styled.section`
  display: flex;

  background: #fff;
  border: 1px solid #dadae7;
  width: 100%;
  border-radius: 0.25rem;
  margin-bottom: 2rem;
  padding: 1rem;

  svg {
    margin-right: 1rem;
  }
  p {
    font-weight: 700;
  }
`;

export const ConfirmedPayment = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  background: #fff;
  border: 1px solid #dadae7;
  width: 100%;
  border-radius: 0.25rem;
  margin-bottom: 2rem;
  padding: 1rem;

  svg {
    width: 3rem;
    height: 3rem;
  }
  p {
    font-weight: 700;
    font-size: 16px;
  }
  small {
    color: #7f7f7f;
    font-size: 12px;
  }
`;

export const ConfirmationPayment = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  background: #fff;
  border: 1px solid #00c49a;
  border-radius: 0.25rem;
  padding: 1.5rem 0.5rem;
  width: 100%;

  .verifyPayment {
    position: absolute;
    width: 100%;
    top: 0;
    justify-content: right;
    border: none;
    background: none;
    display: flex;

    span {
      font-size: 14px;
      font-weight: 700px;
      color: #0ddeb1;
      text-decoration: underline;
    }

    svg {
      margin-left: 9px;
      width: 16px;
      height: 16px;
      color: #0ddeb1;
    }
  }
`;

export const ContainerPix = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  small {
    font-weight: 700;
    font-size: 12px;
    margin-top: 16px;
  }
  button {
    margin-top: 2rem;
    margin-right: 1.25rem;
  }
`;

export const ContainerBarCode = styled.div`
  @media (max-width: 880px) {
    img {
      display: none;
    }
    small {
      display: block;
    }
  }
  @media (min-width: 880px) {
    img {
      display: block;
    }
    small {
      display: none;
    }
  }
`;

export const ContainerCreditCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  svg {
    width: 54px;
    height: 42px;
  }
  p {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    margin-top: 42px;
    margin-bottom: 24px;
  }
  small {
    color: #7f7f7f;
  }
`;

export const ContainerSlip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  small {
    font-size: 12px;
    font-weight: 700;
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: #7f7f7f;
    padding: 32px 0px 32px 0px;
  }
`;

export const QRCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    max-width: 15rem;
    max-height: 15rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  small {
    margin-bottom: 2rem;
  }
`;

export const ContainerInfo = styled.div`
  width: 100%;
  padding: 1rem;
  background: #fff;
  border: 1px solid #00c49a;
  border-radius: 4px;
  margin-bottom: 1.25rem;
  svg {
    margin-right: 1.563rem;
  }

  h5 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
  }
`;

export const Details = styled.div`
  margin-top: 24px;
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #333333;
  }
  small {
    color: #7f7f7f;
    font-size: 12px;
  }
`;
