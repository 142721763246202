import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 73.75rem;
  max-width: 95vw;

  height: 100vh;
  max-height: 95vh;

  border-radius: 0.5rem;
  background-color: var(--white);

  svg {
    color: #8383af;
    margin-right: 1rem;
  }
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  flex-grow: 0;

  padding: 1.75rem 1.5rem;

  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.12);

  & > button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 1rem;
    height: 1rem;

    background: transparent;
    border: none;
    color: #83834f;

    & > svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding: 1.5rem 2.5rem;

  overflow: auto;

  & > small {
    margin-bottom: 1rem;
  }
  overflow-y: auto;

  .input-control {
    margin-right: 2rem;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: row-reverse;
  gap: 1rem;

  box-shadow: 0 -4px 8px 3px rgba(0, 0, 0, 0.06), 0 -1px 3px rgba(0, 0, 0, 0.12);

  padding: 1.5rem 2.5rem;
`;

export const MatrizRadio = styled.div`
  display: flex;
  margin-right: 1rem;
  align-items: center;
  width: 16.25rem;
  height: 3rem;
  border-radius: 0.25rem;
  padding: 1rem, 2rem, 1rem, 2rem;
  background: #f6f6f6;
  border: ${(props) => props.color};
  input {
    background: #8383af;
    margin-left: 2rem;
    margin-right: 1.562rem;
  }

  p {
    color: #333333;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 2rem;
  }
`;

export const ContainerRadio = styled.div`
  display: flex;
  margin-right: 1rem;
  align-items: center;
  width: 11.625rem;
  height: 3rem;
  border-radius: 0.25rem;
  padding: 1rem, 2rem, 1rem, 2rem;
  background: #f6f6f6;
  border: ${(props) => props.color};
  input {
    background: #8383af;
    margin-left: 2rem;
    margin-right: 1.563rem;
  }

  p {
    color: #333333;
    font-size: 1rem;
    font-weight: 400;
  }
`;

export const ContainerCheckbox = styled.div`
  border: ${(props) => props.color};
  display: flex;
  margin-right: 1rem;
  justify-content: space-evenly;
  align-items: center;
  width: max-content;
  padding: 2.5rem 2rem 2.5rem 2rem
  height: 6rem;
  border-radius: 0.25rem;
  padding: 1rem 2rem 1rem 2rem;
  background: #f6f6f6;
  margin-bottom: 2rem;
  input {
    background: #8383af;
    margin-right: 1.5rem;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    all: unset;
    margin: 0;
  }
  input[type='number'] {
    background: #e6e6ef;
    border: 0.5px solid #dadae7;
    border-radius: 0.125rem;
    width: 1.5rem;
    height: 1.5rem;
    border: none;
    margin-right: 0px;
    padding-left: 0px;
  }
  p {
    color: #333333;
    font-size: 1rem;
    font-weight: 400;
  }
  .ContainerSelectNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 2rem;
    margin-left: 0.625rem;
    border: 0.5px solid #dadae7;
    border-radius: 0.125rem;
    background: #e6e6ef;
  }
  .ContainerInstallments {
    display: flex;
  }
`;

export const CrescentNumber = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  background: #e6e6ef;
  border: none;
  padding-left: 0.313rem;
`;
export const DecrescentNumber = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  background: #e6e6ef;
  border: none;
`;
export const ContainerProductType = styled.div`
  display: flex;
  margin-top: 1.5rem;
`;

export const ContainerHeadquartesType = styled.div`
  display: flex;
  margin-top: 1.5rem;
`;

export const ContainerValueVariable = styled.div`
  margin-top: 1.5rem;
  border-left: 1px solid #ccc;
  padding-left: 1.25rem;
  p {
    font-size: 1rem;
    font-weight: 700;
  }
`;

export const ContainerPercentageVariable = styled.div`
  margin-top: 1.5rem;
  border-left: 1px solid #ccc;
  padding-left: 1.25rem;
  span {
    display: flex;
    svg {
      margin-left: 0.375rem;
    }
  }
  p {
    font-size: 1rem;
    font-weight: 700;
  }
`;

export const SectionGenerate = styled.section`
  border-bottom: 1px solid #bbbbbb;
  margin-bottom: 2rem;
  small {
    margin-top: 1.5rem;
    display: flex;
    svg {
      margin-left: 1rem;
    }
  }
  .TooltipCheckout {
    span {
      background: #fff;
      border: 1px solid #b4b4cf;
      color: #333333;
      font-size: 0.75rem;
      font-weight: 400;
    }
  }
`;

export const CurrencyContainer = styled.div`
  .input-currency {
    height: 2.6rem;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid var(--grayscale-4);
    border-radius: 0.25rem;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 12%), 0px 2px 6px 2px rgb(0 0 0 / 6%);
  }
`;
