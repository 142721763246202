import { useState, useCallback, memo } from 'react';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { FileList } from './fileList';

// Import dropzone and file list components:
import { DropZone } from './dropZone';

interface DragNDropProps {
  setFile: (file: File) => void;
  file: File | undefined;
}

export const DragNDrop = memo(({ setFile, file }: DragNDropProps) => {
  const [isDropActive, setIsDropActive] = useState(false);

  const onDragStateChange = useCallback(
    (dragActive: boolean) => {
      setIsDropActive(dragActive);
      return isDropActive;
    },
    [isDropActive],
  );

  // Create handler for dropzone's onFilesDrop:
  const onFilesDrop = useCallback(
    (filesF: File[]) => {
      const fileType = filesF[0].type.split('/')[0];
      if (fileType !== 'image') {
        return;
      }

      setFile(filesF[0]);
    },
    [setFile],
  );

  return (
    <div>
      <DropZone onDragStateChange={onDragStateChange} onFilesDrop={onFilesDrop}>
        <h2>Upload de imagem</h2>

        <p>Tipo de arquivo: PNG ou JPG</p>
        <p>Proporção recomendada: 16:9</p>

        <MdOutlineCloudUpload size={64} />

        <p>
          Arraste e solte ou procure um arquivo <br /> no seu computador
        </p>
      </DropZone>
      {file && <FileList file={file} />}
    </div>
  );
});
