import { ReactNode } from 'react';
import { MdRemoveCircleOutline } from 'react-icons/md';
import { Input } from '../../../../LegacyV4Components';

import {
  CustomerIntegrationsContextData,
  CustomerIntegrationsProvider,
} from '../../../../../hooks/customerIntegrations';

import { TieAccountsModal } from '../TieAccountsModal';

import { activeCampaign as options } from '../platforms';

import * as S from '../styles';
import * as Edit from '../components/IntegrationEditRow/styles';
import * as Summary from '../components/IntegrationSummaryRow/styles';

interface ActiveCampaignModalProps {
  show: boolean;
  onClose: () => void;
}

export function ConfigActiveCampaignModal({
  show,
  onClose,
}: ActiveCampaignModalProps) {
  const tableColumns = [
    {
      accessor: 'customer',
      label: 'Cliente',
      sizing: 25,
      sizingUnit: 'vw',
      sortable: true,
    },
    {
      accessor: 'url',
      label: 'Url do cliente',
      sizing: 1,
      sortable: true,
    },
    {
      accessor: 'token',
      label: 'Token',
      sizing: 1,
      sortable: true,
    },
    {
      accessor: 'status',
      label: 'Status',
      sizing: 0.5,
      sortable: false,
    },
  ];

  function additionalValidation(integration: AdsAccounts): boolean {
    return !!integration.link;
  }

  function isConnected(integration: AdsAccounts): boolean {
    return (
      !!integration.integrationId &&
      !!integration.link &&
      !!integration.projectId &&
      !integration.isDeleted
    );
  }

  function renderEditRow(
    integration: AdsAccounts,
    error: boolean,
    context: CustomerIntegrationsContextData,
  ): ReactNode {
    const { setIntegrationId, setLink, deleteIntegration } = context;
    const linkError = error && !integration.link;
    const tokenError = error && !integration.integrationId;
    return (
      <>
        <div title={integration.link}>
          <Input
            label=""
            value={integration.link}
            name="link"
            onChange={({ target: { value } }) => setLink(integration, value)}
            className={linkError ? 'error' : ''}
            placeholder="https://empresa.api-us1.com/"
          />
          {linkError && <Edit.Error>Url inválida</Edit.Error>}
        </div>
        <Edit.Row title={integration.integrationId}>
          <div>
            <S.TokenInput
              rows={3}
              cols={20}
              onChange={({ target: { value } }) =>
                setIntegrationId(integration, value)
              }
              value={integration.integrationId}
              className={tokenError ? 'error' : ''}
              name="integrationId"
              placeholder="x21496x9xx1591295xxxx2ha3j536de1f3872d66f39f578ee10e14154df89f9f6ab8963x"
            />
            {tokenError && <Edit.Error>Token inválido</Edit.Error>}
          </div>
          <MdRemoveCircleOutline
            size={22}
            onClick={() => deleteIntegration(integration)}
          />
        </Edit.Row>
      </>
    );
  }

  function renderSummaryRow(integration: AdsAccounts): ReactNode {
    return (
      <>
        <td title={integration.link}>
          <Summary.IntegrationParagraph>
            {integration.link}
          </Summary.IntegrationParagraph>
        </td>
        <td title={integration.integrationId}>
          <Summary.IntegrationLink>
            {integration.integrationId}
          </Summary.IntegrationLink>
        </td>
      </>
    );
  }

  return (
    <CustomerIntegrationsProvider type={options.type} show={show}>
      <TieAccountsModal
        options={options}
        show={show}
        onClose={onClose}
        tableColumns={tableColumns}
        renderEditRow={renderEditRow}
        renderSummaryRow={renderSummaryRow}
        isConnected={isConnected}
        additionalValidation={additionalValidation}
      />
    </CustomerIntegrationsProvider>
  );
}
