import { LabDsButton, LabDsTab } from 'v4web-components-react';
import { useEffect, useState } from 'react';
import { IOptionsSelect } from 'v4web-components';
import { useParams } from 'react-router';
import * as S from './styles';
import { Observations } from './Components/Observations';
import { TeamStructure } from './Components/TeamStructure';
import { UnitsAvailable } from './Components/UnitsAvaiable';
import { Payment } from './Components/Payment';
import { Investiment } from './Components/Investiment';
import { TeamStructureInterface } from './interface';
import {
  calculate,
  investmentInfos,
} from '../../services/requests/Unit/calculate';
import { Unit } from '../../hooks/types';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { OpportunityDetails } from './Components/OporttunityDetails';

export function NewPriceCalculatorEdit() {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const IS_HEADQUARTER = user.unitId === process.env.REACT_APP_HEADQUARTER_ID;

  const [teamStructure, setTeamStructure] = useState<TeamStructureInterface[]>(
    [],
  );
  const [units, setUnits] = useState<Unit[]>([]);
  const [unitSeleted, setUnitSelected] = useState<string>('');
  const [investment, setInvestment] = useState<investmentInfos | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<IOptionsSelect[]>([]);
  const [totalPayment, setTotalPayment] = useState<number>(0);
  const [contractDuration, setContractDuration] = useState<IOptionsSelect[]>(
    [],
  );
  const [leadId] = useState<string>(id || '');
  const [discount, setDiscount] = useState('0');
  const [liquid, setLiquid] = useState(25);
  const [isLoading, setIsloading] = useState(false);

  async function calculateInvesment() {
    if (liquid < 20 || liquid > 100) {
      addToast({
        title: 'Valor deve ser maior que 20 e menor que 100',
        type: 'error',
      });
      return;
    }
    if (!unitSeleted.length) {
      addToast({
        title: 'Selecione uma franquia',
        type: 'warning',
      });
      setInvestment(null);
      return;
    }
    const response = await calculate(
      unitSeleted,
      0,
      liquid,
      teamStructure,
      contractDuration[0]?.key,
    );
    if (typeof response !== 'string') setInvestment(response);
  }

  useEffect(() => {
    if (!IS_HEADQUARTER) {
      setUnitSelected(user.unitId);
    }
    calculateInvesment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitSeleted, contractDuration, setUnitSelected]);

  return (
    <S.Page>
      <S.Header>
        <S.HeaderInfo>
          <h1>Calculadora V4X </h1>
        </S.HeaderInfo>
      </S.Header>
      <LabDsTab
        tabs={[
          {
            title: 'Calculadora',
            key: 'calculator',
            event: () => {
              return;
            },
          },
        ]}
      />

      <S.Container>
        {leadId !== '' && <OpportunityDetails leadId={leadId} />}
        <TeamStructure
          setUnits={setUnits}
          unitsSelected={unitSeleted}
          setTeamStructure={setTeamStructure}
          teamStructure={teamStructure}
          setUnitSelected={setUnitSelected}
          setIsloading={setIsloading}
        />
        <Observations />
        {IS_HEADQUARTER && (
          <UnitsAvailable
            units={units}
            unitSeleted={unitSeleted}
            setUnitSelected={setUnitSelected}
            loading={isLoading}
          />
        )}

        <Payment
          totalInvestment={investment?.detailedInvestmentPerMonth}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          setPayment={setTotalPayment}
          contractDuration={contractDuration}
          setContractDuration={setContractDuration}
          discount={discount}
        />
        <Investiment
          detailedInvestment={investment?.detailedInvestment}
          detailedInvestmentPerMonth={investment?.detailedInvestmentPerMonth}
          liquidDiscount={liquid}
          totalPayment={totalPayment}
          setLiquidDiscount={setLiquid}
          discount={discount}
          setDiscount={setDiscount}
          contractDuration={contractDuration[0]?.key || ''}
          paymentMethod={paymentMethod[0]?.key}
        />
      </S.Container>
      <S.Footer>
        <LabDsButton
          label="Atualizar orçamento"
          variant="danger-outlined"
          onHandleButton={calculateInvesment}
        />
      </S.Footer>
    </S.Page>
  );
}
