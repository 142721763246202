import React, { useRef } from 'react';
import { FiArrowLeft, FiLink, FiShoppingCart } from 'react-icons/fi';
import { FiX } from 'react-icons/fi';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query';
import { Button, Headline } from '../../../LegacyV4Components';
import { Modal } from '../../../Default/Modal';

import * as S from './styles';
import { useToast } from '../../../../hooks/toast';
import { createCoupons } from '../../../../services/requests/Finance/createCoupons';
import { DataCoupons } from '../../../../services/requests/Finance/getCouponsList';
import { editCoupons } from '../../../../services/requests/Finance/editCoupons';

export type IProductsByCoupons = IDataProductsByCoupons[];

export interface IDataProductsByCoupons {
  value?: string;
  label: string;
}

export function ResumeCouponsModal({
  isShown,
  setIsShown,
  data,
  setIsOpenResume,
  hours,
  minutes,
  refetch,
  isEditCoupons,
  IdCoupons,
  products,
}: {
  isShown: boolean;
  setIsShown: (value: React.SetStateAction<boolean>) => void;
  data: any;
  setIsOpenResume: (value: React.SetStateAction<boolean>) => void;
  hours: number;
  minutes: number;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<DataCoupons, DataCoupons>>;
  isEditCoupons: boolean;
  IdCoupons: string;
  products: IProductsByCoupons;
}) {
  const sendFormRef = useRef<HTMLButtonElement>(null);
  const { addToast } = useToast();
  const productsToCoupons = products.map(
    (item: IDataProductsByCoupons) => item.value,
  );
  async function handleSubmit() {
    const dataToSend = {
      name: data?.name,
      initialValidity: `${format(
        parseISO(data?.initialValidity),
        'yyyy-MM-dd',
        {
          locale: ptBR,
        },
      )}T00:00:00.906Z`,
      endValidity: `${format(parseISO(data?.endValidity), 'yyyy-MM-dd', {
        locale: ptBR,
      })}T${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:00.906Z`,
      discountType: data?.discountType,
      value: data?.value,
      products: productsToCoupons,
      maximumQuantityAllowed: data?.maximumQuantityAllowed,
    };
    if (productsToCoupons.length > 0) {
      dataToSend.products = productsToCoupons;
    }

    if (isEditCoupons === true) {
      const editedCoupons = await editCoupons(dataToSend, IdCoupons);

      !!editedCoupons &&
        addToast({
          title: 'Sucesso',
          description: 'Cupom editado com sucesso',
          type: 'success',
        });
      refetch();
      !editedCoupons &&
        addToast({
          title: 'error',
          description: 'Ocorreu um erro na edição deste Cupom.',
          type: 'error',
        });
      sendFormRef.current?.click();
      editedCoupons !== false && setIsOpenResume(false);
    } else {
      const createdCoupons = await createCoupons(dataToSend);

      !!createdCoupons &&
        addToast({
          title: 'Sucesso',
          description: 'Cupom criado com sucesso',
          type: 'success',
        });
      refetch();
      !createdCoupons &&
        addToast({
          title: 'error',
          description: 'Ocorreu um erro na criação deste Cupom.',
          type: 'error',
        });
      sendFormRef.current?.click();
      createdCoupons !== false && setIsOpenResume(false);
    }
  }

  return (
    <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
      <S.ModalWrapper>
        <S.ModalHeader>
          <Headline variant="h4">Resumo do cupom</Headline>
          <button
            type="button"
            onClick={() => setIsOpenResume(false)}
            aria-label="default"
          >
            <FiX />
          </button>
        </S.ModalHeader>
        <S.ModalBody>
          <S.SectionResume>
            <Headline variant="h5">
              <FiShoppingCart />
              Cupom
            </Headline>
            <p>Nome do cupom: {data.name}</p>
            <p>Valor de desconto: R${data.value}</p>
            <p>
              Validade do cupom:
              {format(parseISO(data?.initialValidity), ' dd MMMM yyyy ', {
                locale: ptBR,
              })}
              até
              {format(parseISO(data?.endValidity), ' dd MMMM yyyy ', {
                locale: ptBR,
              })}
            </p>
            <p>
              Horário de encerramento: Às {hours.toString().padStart(2, '0')}:
              {minutes.toString().padStart(2, '0')}:00
            </p>
          </S.SectionResume>
        </S.ModalBody>
        <S.ModalFooter>
          <Button
            variant="primary"
            color="green"
            size="default"
            onClick={handleSubmit}
          >
            {isEditCoupons === true ? 'Finalizar edição' : 'Gerar link'}
            <FiLink color="#333333" />
          </Button>
          <Button
            variant="secondary"
            color="green"
            size="default"
            onClick={() => {
              setIsOpenResume(false);
              setIsShown(true);
            }}
          >
            <FiArrowLeft color="#00C49A" />
            Voltar para edição
          </Button>
        </S.ModalFooter>
      </S.ModalWrapper>
    </Modal>
  );
}
