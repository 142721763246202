import { FiArrowRight } from 'react-icons/fi';
import { NumberFormatValues } from 'react-number-format';
import { FormikTouched } from 'formik';
import { Box } from '@mui/material';
import { useState } from 'react';
import { Button } from '../../../LegacyV4Components';
import * as S from './styles';
import { TextField } from '../../molecules/TextField';
import { FormikPhoneInput } from '../../molecules/FormikPhoneInput';
import { FormikPaymentOptions } from '../../../../pages/PaymentOptions';
import { FormikCpfCnpjInput } from '../../molecules/FormikCpfCnpjInput';
import { FormikPlansOptions } from '../../../../pages/PlanOptions';
import { SelectField } from '../../molecules/SelectField';
import { UnitsList } from '../../../../utils/unitsList';

interface PropsAboutYou {
  values: FormikPaymentOptions | FormikPlansOptions;
  errors: any;
  handleBlur: React.FocusEventHandler<HTMLInputElement> | undefined;
  indexTab: number;
  setIndexTab: React.Dispatch<React.SetStateAction<number>>;
  setFieldsIncomplete?: React.Dispatch<React.SetStateAction<boolean>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  touched: FormikTouched<FormikPaymentOptions>;
  mediaWallet?: boolean;
  lookingAhead?: boolean;
}

export function AboutYou({
  values,
  errors,
  handleBlur,
  indexTab,
  setIndexTab,
  setFieldsIncomplete,
  setFieldValue,
  touched,
  mediaWallet,
  lookingAhead,
}: PropsAboutYou) {
  const [unitName, setUnitName] = useState('');

  const ErrorName = values.name !== '';
  const ErrorEmail = values.email !== '';
  const ErrorTelephone = values.telephone !== '';
  const ErrorPhone = values.phone !== '';
  const ErrorCPF = values.cpf !== '';
  const ErrorCpfCnpj = values.cpf_cnpj !== '';

  return (
    <S.Container>
      {lookingAhead && (
        <>
          <SelectField
            name="ticketUnitId"
            value={unitName}
            placeholder="Buscar unidade"
            label="Selecione sua unidade"
            options={UnitsList}
            onChange={(e: any) => {
              const changeObject = e as {
                id: string;
                label: string;
              };
              setFieldValue('ticketUnitId', changeObject.id);
              setUnitName(changeObject.label);
            }}
            onBlur={handleBlur}
            styledInSelect="var(--white)"
            containerStyle={{ marginBottom: '1rem' }}
          />
          {touched?.ticketUnitId && errors?.ticketUnitId ? (
            <p className="helperUnit">O campo é obrigatório!</p>
          ) : (
            ''
          )}
        </>
      )}
      <TextField
        name="name"
        label="Nome ou razão social *"
        value={values.name}
        placeholder="Informe seu nome completo ou sua razão social"
        helper={touched?.name && errors?.name ? errors?.name : ''}
        validateOnChange={false}
        validateOnBlur={false}
        onBlur={handleBlur}
      />
      <TextField
        name="email"
        label="Email *"
        value={values.email}
        placeholder="Informe seu melhor email"
        helper={touched?.email && errors?.email ? errors?.email : ''}
        validateOnChange={false}
        validateOnBlur={false}
        onBlur={handleBlur}
      />
      {mediaWallet === true ? (
        <>
          <FormikPhoneInput
            name="phone"
            label="Telefone *"
            value={values.phone}
            placeholder="(33) 00000-0000"
            helper={touched?.phone && errors?.phone ? errors?.phone : ''}
            validateOnChange={false}
            validateOnBlur={false}
            onBlur={handleBlur}
            onValueChange={(val: NumberFormatValues) => {
              setFieldValue(`phone`, val.value);
            }}
          />
          <FormikCpfCnpjInput
            name="cpf_cnpj"
            label="CPF ou CNPJ*"
            value={values.cpf as string}
            placeholder="000.000.000-00"
            helper={
              touched?.cpf_cnpj && errors?.cpf_cnpj ? errors?.cpf_cnpj : ''
            }
            onBlur={handleBlur}
            validateOnChange={false}
            validateOnBlur={false}
            format={
              values?.cpf_cnpj && values?.cpf_cnpj?.length < 12
                ? '###.###.###-###'
                : '##.###.###/####-##'
            }
            fullWidth
            onValueChange={(val: NumberFormatValues) => {
              setFieldValue(`cpf_cnpj`, val.value);
            }}
          />
        </>
      ) : (
        <Box>
          <FormikPhoneInput
            name="telephone"
            label="Telefone *"
            value={values.telephone}
            placeholder="(33) 00000-0000"
            helper={
              touched?.telephone && errors?.telephone ? errors?.telephone : ''
            }
            validateOnChange={false}
            validateOnBlur={false}
            onBlur={handleBlur}
            onValueChange={(val: NumberFormatValues) => {
              setFieldValue(`telephone`, val.value);
            }}
          />
          <FormikCpfCnpjInput
            name="cpf"
            label="CPF/CNPJ *"
            value={values.cpf as string}
            placeholder="EX: 89.086.144/0001-16"
            helper={touched?.cpf && errors?.cpf ? errors?.cpf : ''}
            onBlur={handleBlur}
            validateOnChange={false}
            validateOnBlur={false}
            format={
              values?.cpf && values?.cpf?.length < 12
                ? '###.###.###-###'
                : '##.###.###/####-##'
            }
            fullWidth
            onValueChange={(val: NumberFormatValues) => {
              setFieldValue(`cpf`, val.value);
            }}
          />
        </Box>
      )}
      <div style={{ float: 'right' }}>
        <Button
          variant="primary"
          rightIcon={<FiArrowRight />}
          color="green"
          size="default"
          onClick={async () => {
            if (
              ErrorName &&
              !errors.name &&
              !errors.email &&
              ErrorEmail &&
              (mediaWallet ? ErrorCpfCnpj : ErrorCPF) &&
              (mediaWallet ? !errors.cpf_cnpj : !errors.cpf) &&
              (mediaWallet ? ErrorPhone : ErrorTelephone) &&
              (mediaWallet ? !errors.phone : !errors.tel)
            ) {
              setIndexTab(indexTab + 1);
            } else if (setFieldsIncomplete) setFieldsIncomplete(true);
          }}
        >
          Continuar
        </Button>
      </div>
    </S.Container>
  );
}
