import { ReactNode, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';

import * as S from './styles';

interface MenuProps {
  className?: string;
  children: ReactNode;
}

export function Menu({ className, children }: MenuProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <S.Container isOpen={isOpen} className={className}>
      <FiMoreVertical onClick={() => setIsOpen(!isOpen)} size={20} />

      <span aria-hidden onClick={() => setIsOpen(false)}>
        {children}
      </span>
    </S.Container>
  );
}
