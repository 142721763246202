import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  section {
    cursor: pointer;
    padding: 0.75rem 1rem;
    position: relative;
    display: flex;
    justify-content: center;

    &:first-child {
      padding-left: 0;
      margin-left: 0;
    }
  }
  span {
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Nunito Sans', sans-serif;
    line-height: 1.5rem;
  }
  .selected {
    border-radius: 0.5rem 0.5rem 0 0rem;
    background: var(--white);
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: calc(100% - 1.4rem);
      height: 4px;
      background-color: var(--success-2);
      border-radius: 2px;
    }
  }
`;
