import { IOptionsSelect } from 'v4web-components';
import { getAllMandates } from '../../../../services/requests/User/Mandates/getAllMandates';
import { getAllSeniorities } from '../../../../services/requests/User/Seniorities/getAllSeniorities';
import { InputItens } from '../../molecules/ModalPermissionsEdit/interfaces';
import { Mandate, Seniority } from '../../../../hooks/types';

export const getAllMandateItems = (newPrice: boolean) => {
  const mandateItemsAux: InputItens[] | IOptionsSelect[] = [];
  getAllMandates().then((result: Mandate[]) => {
    if (!newPrice)
      result?.forEach((mandate) => {
        mandateItemsAux.push({
          label: mandate.name,
          value: mandate,
          key: mandate._id,
          isChecked: false,
        });
      });
    else
      result?.forEach((mandate: Mandate) => {
        if (mandate.isNewPrice)
          mandateItemsAux.push({
            label: mandate.name,
            value: mandate,
            key: mandate._id,
            isChecked: false,
          });
      });
  });
  return mandateItemsAux;
};

export const getAllHeadquartersMandateItems = (headquarter: boolean) => {
  const mandateItemsAux: InputItens[] | IOptionsSelect[] = [];
  getAllMandates().then((result: Mandate[]) => {
    if (!headquarter)
      result?.forEach((mandate) => {
        if (!mandate.isHeadquarters)
          mandateItemsAux.push({
            label: mandate.name,
            value: mandate,
            key: mandate._id,
            isChecked: false,
          });
      });
    else
      result?.forEach((mandate: Mandate) => {
        mandateItemsAux.push({
          label: mandate.name,
          value: mandate,
          key: mandate._id,
          isChecked: false,
        });
      });
  });
  return mandateItemsAux;
};

export const getAllSeniorityItems = () => {
  const seniorityItemsAux: InputItens[] = [];
  getAllSeniorities().then((result) => {
    result?.forEach((seniority: Seniority) => {
      seniorityItemsAux.push({
        label: seniority.name,
        value: seniority,
      });
    });
  });
  return seniorityItemsAux;
};

export const responsibilityItems = [
  {
    label: 'Coach',
    value: 'Coach',
  },
  {
    label: 'Cap',
    value: 'Cap',
  },
  {
    label: 'Player',
    value: 'Player',
  },
];
