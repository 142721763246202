import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { kubernetesApi } from '../../api';
import { IDataSalesforceLead } from '../../../types/salesforce';

export function useGetSalesforceLead(
  leadId: string,
): UseQueryResult<
  { data: IDataSalesforceLead },
  { data: IDataSalesforceLead }
> {
  return useQuery<{ data: IDataSalesforceLead }, { data: IDataSalesforceLead }>(
    ['lead', leadId],
    () => kubernetesApi.get(`/sale/lead/${leadId}`),
    {},
  );
}

export function useGetSalesforceForm(
  formId: string,
): UseQueryResult<{ data: any }, { data: any }> {
  return useQuery<{ data: any }, { data: any }>(
    ['leadForm', formId],
    () => kubernetesApi.get(`/sale/lead/form/${formId}`),
    {},
  );
}
