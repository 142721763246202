// Unfortunately I made this very very quickly, its just an very MINIMAL viable product (MVP).

import axios, { AxiosRequestHeaders } from 'axios';
import { useCallback, useState } from 'react';
import { Button } from '../../components/LegacyV4Components';
import { SelectField } from '../../components/AtomicDesign/molecules/SelectField';
import { useToast } from '../../hooks/toast';
import * as S from './styles';

interface IProduct {
  name: string;
  seniority: string;
  franchiseTier: string;
  possibleTypes: string[];
  productType: string;
  requirements: {
    employees: {
      name: string;
      amount: number;
    }[];
  };
  employeePrices?: number;
}

type IPossibleProductTypes =
  | 'Google Ads'
  | 'Social Ads'
  | 'Google + Social Ads';

export function ProductsCalculator() {
  const seniorities = ['Sênior', 'Pleno', 'Júnior'];
  const franchiseTiers = ['Walk', 'Run', 'Fly', 'Master', 'Outlier'];

  const productTypes = {
    'Google Ads': {
      employees: [
        {
          name: 'Gestor de Tráfego',
          amount: 1,
        },
      ],
    },
    'Social Ads': {
      employees: [
        {
          name: 'Gestor de Tráfego',
          amount: 1,
        },
      ],
    },
    'Google + Social Ads': {
      employees: [
        {
          name: 'Gestor de Tráfego',
          amount: 2,
        },
      ],
    },
  };

  const managementProduct: IProduct = {
    name: 'Gestão e Atendimento',
    seniority: 'Pleno',
    franchiseTier: 'Walk',
    productType: 'Completo',
    possibleTypes: ['Completo'],
    requirements: {
      employees: [
        {
          name: 'Head',
          amount: 1,
        },
        {
          name: 'Account Manager',
          amount: 1,
        },
      ],
    },
  };

  const mediaManagement: IProduct = {
    name: 'Gestão de Mídia',
    seniority: 'Júnior',
    franchiseTier: 'Walk',
    possibleTypes: ['Google Ads', 'Social Ads', 'Google + Social Ads'],
    productType: 'Google Ads',
    requirements: {
      employees: [
        {
          name: 'Gestor de Tráfego',
          amount: 1,
        },
      ],
    },
  };

  const [products, setProducts] = useState<IProduct[]>([
    managementProduct,
    mediaManagement,
  ]);

  const { addToast } = useToast();

  const handleCalculatePrice = useCallback(async () => {
    // Calls a lambda function that handles all the math.
    // https://github.com/V4-Company/product-price-calculator

    const apiUrl =
      'https://jastds0ju1.execute-api.sa-east-1.amazonaws.com/product';
    const headers: AxiosRequestHeaders = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
    };

    const productsWithPrice = await axios({
      url: apiUrl,
      method: 'post',
      data: products,
      headers,
    });

    if (!productsWithPrice.data) {
      addToast({
        type: 'error',
        title: 'Falha em chamar Api de precos',
      });

      return;
    }

    const productsResponse = productsWithPrice?.data as {
      products: IProduct[];
    };

    setProducts(productsResponse.products);
  }, [addToast, products]);

  function handleFieldUpdate({
    value,
    product,
    field,
  }: {
    value: string;
    product: IProduct;
    field: 'seniority' | 'franchiseTier';
  }) {
    const productsCopy = [...products];
    const productIndex = products.findIndex(
      (item) => item.name === product.name,
    );

    productsCopy[productIndex][field] = value as string;

    setProducts([...productsCopy]);
  }

  function handleRequirementUpdate({
    product,
    productType,
  }: {
    product: IProduct;
    productType: IPossibleProductTypes;
  }) {
    const productsCopy = [...products];
    const productIndex = products.findIndex(
      (item) => item.name === product.name,
    );

    productsCopy[productIndex].productType = productType;
    productsCopy[productIndex].requirements = productTypes[productType];

    setProducts([...productsCopy]);
  }

  return (
    <S.Container>
      <h1>Produtos V4</h1>

      <main>
        {products.map((product) => {
          return (
            <section>
              <h3>{product.name}</h3>

              <section>
                <p>Senioridade</p>
                <SelectField
                  onChange={(value: { value: string }) => {
                    handleFieldUpdate({
                      value: value.value,
                      product,
                      field: 'seniority',
                    });
                  }}
                  options={seniorities.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  value={product.seniority}
                />
              </section>

              <section>
                <p>Tier franquia</p>
                <SelectField
                  onChange={(value: { value: string }) => {
                    handleFieldUpdate({
                      value: value.value,
                      product,
                      field: 'franchiseTier',
                    });
                  }}
                  options={franchiseTiers.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  value={product.franchiseTier}
                />
              </section>

              <section>
                <p>Tipo do produto</p>{' '}
                <SelectField
                  value={product.productType}
                  options={product.possibleTypes.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  onChange={(value: { value: string }) => {
                    if (value.value === 'Completo') {
                      return;
                    }

                    handleRequirementUpdate({
                      productType: value.value as IPossibleProductTypes,
                      product,
                    });
                  }}
                />
              </section>

              <section>
                <p style={{ fontWeight: 'bold', marginBottom: 0.5 }}>
                  Workload:
                </p>

                {product.requirements.employees.map((item) => {
                  return (
                    <p>
                      Tipo: {item.name}, Quantidade: {item.amount}
                    </p>
                  );
                })}
              </section>

              {product.employeePrices && (
                <h4>Total: R${product.employeePrices}</h4>
              )}
            </section>
          );
        })}
      </main>

      <Button
        variant="primary"
        size="small"
        color="green"
        onClick={() => handleCalculatePrice()}
      >
        Buscar
      </Button>
    </S.Container>
  );
}
