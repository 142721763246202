import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-basis: 33.3%;

  padding: 1.5rem 2rem;
  min-width: 22.75rem;

  border-radius: 0.5rem;

  border: 1px solid var(--grayscale-10);
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.12);

  svg {
    margin-bottom: 1.5rem;
  }

  button {
    width: 10.1875rem;
    padding: 0.5rem;
    height: 2rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  flex-grow: 1;
`;

export const ModalOverlay = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  z-index: 30;

  background-color: rgb(0 0 0 / 25%);

  &.show {
    display: flex;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  height: 100%;
  max-height: calc(100vh - 2rem);
  width: 70vw;

  border-radius: 8px;
  background-color: var(--white);
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 1.6875rem 2.5rem 1.8125rem 2.5rem;

  box-shadow: 0 0.25rem 0.875rem rgba(0, 0, 0, 0.05);

  & .close-button {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    cursor: pointer;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 3.5rem 3.25rem 2.5rem 3.25rem;

  & a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  & svg {
    color: var(--success-2);
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  padding: 1.75rem 2.5rem;

  border-radius: 0rem 0rem 0.5rem 0.5rem;
  background: var(--white);
  box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.12),
    0px -4px 8px 3px rgba(0, 0, 0, 0.06);
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 1rem;

  & p.bold {
    font-weight: 700;
  }
`;

export const Content = styled.div`
  display: flex;
`;

export const Left = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 100;
  gap: 2rem;

  h5 {
    margin-bottom: 0.5rem;
  }

  li {
    margin-left: 0.75rem;
    color: var(--secondary-main);
    font-size: 1.375rem;
    line-height: 2rem;
    white-space: nowrap;
  }
`;

export const Right = styled.section`
  display: flex;
  justify-content: center;
  flex-shrink: 100;
`;

export const Image = styled.img`
  max-width: 100%;
`;
export const Token = styled.div`
  display: flex;
`;
export const GenerateTokenWrapper = styled.p`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;

  color: var(--success-2);

  & svg {
    cursor: pointer;

    &.loading {
      cursor: progress;
    }
  }
`;

export const HelpLink = styled.p`
  color: var(--secondary-main);
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration-line: underline;
`;
