import React from 'react';
import * as S from './styles';
import { Section } from '../../atoms/Section';
import { Container } from '../../atoms/Container';
import { Breadcrumb } from '../../../Navigation/Breadcrumb';
import { SectionContent } from '../../atoms/SectionContent';
import { NotFoundProps } from './NotFound.types';

export function NotFound({ img }: NotFoundProps) {
  return (
    <Container>
      <Breadcrumb
        breadcrumbs={[
          { title: 'Página inicial', link: '/' },
          { title: 'Página não encontrada', link: '/pagina-nao-encontrada' },
        ]}
      />
      <Section>
        <SectionContent>
          <S.SectionImage>
            <img src={img} alt="Página não encontrada" />
          </S.SectionImage>
        </SectionContent>
      </Section>
    </Container>
  );
}
