import { cnpj, cpf } from 'cpf-cnpj-validator';

export function ValidateCPFOrCNPJ(value: any) {
  const cleanedValue = value?.replace(/[\s.-]*/gim, '');

  if (cleanedValue?.length === 11) {
    if (cpf?.isValid(cleanedValue)) {
      return true;
    }
    return false;
  }
  if (cleanedValue?.length === 14) {
    if (cnpj?.isValid(cleanedValue)) {
      return true;
    }
    return false;
  }
  // Invalid length
  return false;
}
