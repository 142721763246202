import { MdOutlineLocationOn } from 'react-icons/md';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { LabDsButton } from 'v4web-components-react';
import { Modal } from '../../../LegacyV4Components';
import * as S from './styles';
import { getUnits } from '../../../../services/requests/Unit/getUnits';
import { useToast } from '../../../../hooks/toast';
import { NumberField } from '../NumberField';
import { TextField } from '../TextField';
import { FormikCnpjInput } from '../FormikCnpjInput';
import { FormikCepInput } from '../FormikCepInput';
import { getAddresByCep } from '../../../../utils/getAddressByCep';
import AutocompleteCustom from '../AutocompleteCustom';
import {
  CreateCostumerDTO,
  createCustomer,
} from '../../../../services/requests/Customers/createCustomer';

export interface UnitsAutocomplete {
  label: string;
  id: string;
}

export function CreateCustomerModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: () => void;
}) {
  const { addToast } = useToast();
  const [units, setUnits] = useState<UnitsAutocomplete[]>([]);
  const navigate = useNavigate();

  const validateInformations = Yup.object().shape({
    name: Yup.string().required('Nome do cliente deve ser informado'),
    unitId: Yup.string().required('Unidade do cliente deve ser informado'),
    cnpj: Yup.string()
      .required('CNPJ deve ser informado')
      .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, 'CNPJ inválido'),
    address: Yup.object().shape({
      state: Yup.string().required('Estado deve ser informado'),
      city: Yup.string().required('Cidade deve ser informado'),
      country: Yup.string(),
      street: Yup.string().required('Rua deve ser informado'),
      number: Yup.string(),
      complement: Yup.string().required('Complemento deve ser informado'),
      zipCode: Yup.string().required('CEP deve ser informado'),
    }),
  });

  async function handleCreateCostumer(values: any) {
    await createCustomer(values as unknown as CreateCostumerDTO)
      .then((res) => {
        addToast({
          title: 'Cliente criado com com sucesso',
          type: 'success',
          description: '',
        });
        setIsOpen();
        navigate(`/cliente/${res._id}`);
      })
      .catch(() =>
        addToast({
          title:
            'Não foi possível criar o cliente, verifique as informações e tente novamente',
          type: 'error',
          description: '',
        }),
      );
  }

  async function getUnit(query?: any) {
    const response = (await getUnits(0, query ?? '', true)) as any;
    if (response) {
      setUnits(response.data);
    }
  }

  useEffect(() => {
    getUnit();
  }, []);

  function handleClose() {
    setIsOpen();
  }

  return (
    <Modal
      size="lg"
      show={isOpen}
      title="Cadastrar novo cliente"
      closeButton
      onBackground={() => setIsOpen()}
    >
      <Formik
        initialValues={{
          name: '',
          cnpj: '',
          unitId: '',
          address: {
            country: '',
            state: '',
            city: '',
            street: '',
            number: '',
            complement: '',
            zipCode: '',
            district: '',
          },
        }}
        validationSchema={validateInformations}
        onSubmit={() => handleClose()}
      >
        {({
          values,
          handleChange,
          touched,
          errors,
          handleBlur,
          setFieldValue,
        }) => (
          <S.Container>
            <AutocompleteCustom
              label="Unidade *"
              options={units}
              onChange={(e) => {
                getUnit({ name: e.name });
                setFieldValue('unitId', e.id);
              }}
            />

            <TextField
              name="name"
              label="Nome do cliente *"
              value={values.name}
              placeholder="Informe o nome do cliente"
              helper={errors.name && touched.name ? errors.name : ''}
              validateOnChange={false}
              validateOnBlur
              onBlur={handleBlur}
            />
            <FormikCnpjInput
              name="cnpj"
              label="CNPJ *"
              value={values.cnpj}
              placeholder="00.000.000/0000-00"
              helper={errors.cnpj && touched.cnpj ? errors.cnpj : ''}
              onBlur={handleBlur}
              validateOnChange={false}
              validateOnBlur={false}
              format="##.###.###/####-##"
              onChange={handleChange}
            />
            <hr />
            <S.AddressTitle>
              <MdOutlineLocationOn className="icon" size={24} />
              Endereço
            </S.AddressTitle>

            <FormikCepInput
              format="#####-###"
              mask="_"
              name="address.zipCode"
              label="CEP *"
              value={values?.address?.zipCode}
              placeholder="Digite o CEP"
              onChange={handleChange}
              validateOnChange={false}
              helper={
                errors?.address?.zipCode && touched.address?.zipCode
                  ? errors.name
                  : ''
              }
              onBlur={() =>
                getAddresByCep(
                  setFieldValue,
                  values.address?.zipCode as string,
                ).then(() => setFieldValue('address.country', 'Brazil'))
              }
            />
            <TextField
              name="address.state"
              label="Estado *"
              value={values.address?.state}
              placeholder="Informe o estado"
              helper={
                errors?.address?.state && touched.address?.state
                  ? errors.name
                  : ''
              }
              validateOnChange={false}
              validateOnBlur
              onBlur={handleBlur}
            />
            <TextField
              name="address.country"
              label="País *"
              value={values.address?.country}
              placeholder="Informe o País"
              helper={
                errors?.address?.country && touched.address?.country
                  ? errors.name
                  : ''
              }
              validateOnChange={false}
              validateOnBlur
              onBlur={handleBlur}
            />
            <TextField
              name="address.city"
              label="Cidade *"
              value={values.address?.city}
              placeholder="Informe a cidade"
              helper={
                errors?.address?.city && touched.address?.city
                  ? errors.name
                  : ''
              }
              validateOnChange={false}
              validateOnBlur
              onBlur={handleBlur}
            />
            <TextField
              name="address.street"
              label="Rua *"
              value={values.address?.street}
              placeholder="Informe a rua"
              helper={
                errors?.address?.street && touched.address?.street
                  ? errors.name
                  : ''
              }
              validateOnChange={false}
              validateOnBlur
              onBlur={handleBlur}
            />
            <TextField
              name="address.district"
              label="Bairro *"
              value={values.address?.district}
              helper={
                errors?.address?.district && touched.address?.district
                  ? errors.name
                  : ''
              }
              placeholder="Informe o bairro"
              validateOnChange={false}
              validateOnBlur
              onBlur={handleBlur}
            />
            <NumberField
              format="########"
              name="address.number"
              label="Número *"
              value={values.address?.number}
              helper={
                errors?.address?.number && touched.address?.number
                  ? errors.name
                  : ''
              }
              placeholder="Informe o número do endereço"
              validateOnChange={false}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              name="address.complement"
              label="Complemento *"
              value={values.address?.complement}
              helper={
                errors?.address?.complement && touched.address?.complement
                  ? errors.name
                  : ''
              }
              placeholder="Informe o complemento"
              validateOnChange={false}
              validateOnBlur
              onBlur={handleBlur}
            />
            <S.Observation>
              * Após cadastrar o customer, necessário inserir as informações de
              stakeholder na página do cliente
            </S.Observation>
            <S.ButtonContainer>
              <LabDsButton
                label="Salvar"
                disabled={
                  values.address.zipCode.length < 1 && values.cnpj.length < 1
                }
                onHandleButton={() => handleCreateCostumer(values)}
              />
            </S.ButtonContainer>
          </S.Container>
        )}
      </Formik>
    </Modal>
  );
}
