import React, { useCallback, useState } from 'react';

import { Box } from '@mui/material';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query';
import { FiX } from 'react-icons/fi';
import { Button, DotMenu, Headline } from '../../../LegacyV4Components';
import { UnHappyPath } from '../../atoms/UnhappyPath';
import { Modal } from '../../../Default/Modal';

import * as S from './styles';
import { IProductList } from '../../../../services/requests/Finance/getProductList';
import { CreateProductCheckout } from '../../molecules/CreateProductCheckout';
import { useToast } from '../../../../hooks/toast';
import { financeApi } from '../../../../services/api';
import { EditProductCheckout } from '../../molecules/EditProductCheckout';
import { DeleteForm } from '../../atoms/DeleteForm';

interface DotMenuOptions {
  name: string;
  action: () => void;
}

export function ListProductsCheckout({
  isShown,
  setIsShown,
  productType,
  data,
  refetch,
}: {
  isShown: boolean;
  setIsShown: (value: React.SetStateAction<boolean>) => void;
  productType: string;
  data: any;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<any, any>>;
}) {
  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [showEditProduct, setShowEditProduct] = useState(false);
  const [IdProduct, setIdProduct] = useState('');
  const [productSelect, setProductSelect] = useState({} as IProductList);
  const [openDeleteForm, setOpenDeletForm] = useState(false);
  const { addToast } = useToast();

  const handleDeleteProduct = useCallback(
    (productId: string) => {
      financeApi({
        url: `/products/${productId}`,
        method: 'DELETE',
      })
        .then(async () => {
          refetch();
          addToast({
            title: 'Sucesso',
            description: 'Produto excluído com sucesso!',
            type: 'success',
          });
        })
        .catch(() => {
          addToast({
            title: 'Atenção',
            description: 'Houve um erro ao excluir o produto.',
            type: 'error',
          });
          refetch();
        });
    },
    [addToast, refetch],
  );

  const dotMenuOptions = useCallback(
    (productId: string, product?: any): DotMenuOptions[] => {
      if (!productId)
        return [
          {
            name: '',
            action: () => {
              return;
            },
          },
        ];
      return [
        {
          name: 'Remover',
          action: () => {
            setOpenDeletForm(true);
            setIdProduct(productId);
            setProductSelect(product);
            setIsShown(false);
            refetch();
          },
        },
        {
          name: 'Editar',
          action: () => {
            setIdProduct(productId);
            setProductSelect(product);
            setShowEditProduct(true);
            setIsShown(false);
            refetch();
          },
        },
      ];
    },
    [setIsShown, refetch],
  );

  return (
    <>
      <CreateProductCheckout
        isShown={showCreateProduct}
        setIsShown={setShowCreateProduct}
        productType={productType}
        refetch={refetch}
      />
      <EditProductCheckout
        isShown={showEditProduct}
        setIsShown={setShowEditProduct}
        productType={productType}
        refetch={refetch}
        productId={IdProduct}
        data={productSelect}
      />
      <DeleteForm
        showDelete={openDeleteForm}
        name="produto"
        onSubmit={() => {
          handleDeleteProduct(IdProduct);
          refetch();
        }}
        onCancel={() => setOpenDeletForm(false)}
        itemName={productSelect?.name}
      />
      <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
        <S.ModalWrapper>
          <S.ModalHeader>
            <Headline variant="h4">Produtos</Headline>
            <button
              type="button"
              onClick={() => setIsShown(false)}
              aria-label="default"
            >
              <FiX />
            </button>
          </S.ModalHeader>
          <S.ModalBody>
            {data ? (
              <>
                {data?.map((item: IProductList) => (
                  <Box
                    sx={{
                      borderBottom: '0.5px solid #BBBBBB',
                      paddingBottom: '24px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingRight: '2rem',
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Headline variant="h6">{item?.name}</Headline>
                      <span style={{ marginTop: '8px' }}>
                        {item?.description}
                      </span>
                    </Box>
                    <DotMenu
                      options={dotMenuOptions(item?._id, item)}
                      reversed
                    />
                  </Box>
                ))}
              </>
            ) : (
              <Box sx={{ textAlign: 'center' }}>
                <UnHappyPath assetIndex={6} />
                <Headline variant="h6">
                  Ainda não foram cadastrados produtos
                </Headline>
              </Box>
            )}
          </S.ModalBody>
          <S.ModalFooter>
            <Button
              variant="primary"
              color="green"
              size="default"
              onClick={async () => {
                setShowCreateProduct(true);
                setIsShown(false);
                refetch();
              }}
            >
              Cadastrar novo produto
            </Button>
          </S.ModalFooter>
        </S.ModalWrapper>
      </Modal>
    </>
  );
}
