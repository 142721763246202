import { unitsApi as api } from '../../api';
import { UnitInterface } from '../../../types/units';

export async function deleteUnit(id: string) {
  try {
    const data = await api.delete<UnitInterface>(`/units/${id}`);

    return data;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao desabilitar a unidade, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
