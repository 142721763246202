import { IData } from 'v4web-components';

type Header = IData['headers'];

export const headerTableBase: Header = [
  {
    title: 'Nome',
    colKey: 'name',
    sortable: true,
    type: 'text',
  },
  {
    title: 'E-mail',
    colKey: 'email',
    sortable: true,
    type: 'text',
  },
  {
    title: 'Cargo',
    colKey: 'mandate',
    sortable: false,
    type: 'text',
  },
  {
    title: 'Senioridade',
    colKey: 'seniority',
    sortable: false,
    type: 'text',
  },
];

export const headerTableNotAdmin: Header = headerTableBase;

export const headerTable: Header = [...headerTableBase];

export const headerTableWage: Header = [
  ...headerTableBase,
  {
    title: 'Salário',
    colKey: 'wage',
    sortable: true,
    type: 'text',
  },
  // the title is empty, because is the column for actions
];

export const headerTableEkyte: Header = [
  ...headerTableBase,
  {
    title: 'Ekyte',
    colKey: 'ekyte',
    type: 'text',
    sortable: false,
  },
  headerTable[headerTable.length - 1],
];

export const headerTableHistoric: Header = [
  ...headerTableBase,
  {
    title: 'Entrada',
    colKey: 'startedAt',
    sortable: true,
    type: 'text',
  },
  {
    title: 'Saida',
    colKey: 'deletedAt',
    sortable: true,
    type: 'text',
  },
];
