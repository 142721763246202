import { LabDsButton } from 'v4web-components-react';
import { Paragraph } from '../../../LegacyV4Components';

import ActiveCampaignLogo from '../../../../assets/icons/active_campaign.svg';
import TrayEcommerceLogo from '../../../../assets/icons/tray_ecommerce.svg';
import VindiLogo from '../../../../assets/icons/VindiLogo.svg';
import QualifiqueiLogo from '../../../../assets/icons/QualifiqueiLogo.svg';
import LetalkLogo from '../../../../assets/icons/LetalkLogo.svg';
import KommoLogo from '../../../../assets/icons/KommoLogo.svg';

import * as S from './styles';

const openPipefyAction = () =>
  window.open(process.env.REACT_APP_DIGITAL_STACK_URL);

export function ConnectStackDigitalAccounts() {
  const ads_services = [
    {
      name: 'ActiveCampaign',
      logo: ActiveCampaignLogo,
      action: openPipefyAction,
    },
    {
      name: 'Tray Commerce',
      logo: TrayEcommerceLogo,
      action: openPipefyAction,
    },
    {
      name: 'Vindi Pagamentos',
      logo: VindiLogo,
      action: openPipefyAction,
    },
    {
      name: 'Qualifiquei Leads',
      logo: QualifiqueiLogo,
      action: openPipefyAction,
    },
    {
      name: 'Letalk',
      logo: LetalkLogo,
      action: openPipefyAction,
    },
    {
      name: 'AmoCRM - Kommo',
      logo: KommoLogo,
      action: openPipefyAction,
    },
  ];

  return (
    <>
      <S.Cards>
        {ads_services.map((service) => (
          <S.Card key={service.name}>
            <S.ImageIcon src={service.logo} alt={service.name} />
            <S.ButtonWrapper>
              <h4>{service.name}</h4>
              <Paragraph>
                Aproveite nossas condições exclusivas para as melhores
                ferramentas do mercado.
              </Paragraph>
              <LabDsButton
                label="Implemente agora"
                onHandleButton={() => service.action()}
              />
            </S.ButtonWrapper>
          </S.Card>
        ))}
      </S.Cards>
    </>
  );
}
