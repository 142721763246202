import styled from 'styled-components';
import backgroundImage from '../../assets/UnhappyPaths/backgroundLoginUnhappyPath.png';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: var(--black);
`;

export const BackgroundImage = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  filter: blur(0.25rem);
  background: url(${backgroundImage});
  background-size: cover;
  background-position: center;
`;

export const SectionContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  overflow: auto;
  background: var(--white);
  box-shadow: 0px 0.25rem 0.6125rem rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  margin: 3.125rem 8rem;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  background-color: var(--primary-dark);
  margin-bottom: 1rem;

  svg {
    height: 2.5rem;
    font-size: 1.5rem;
    color: var(--white);
  }
`;

export const SectionDescription = styled.h3`
  text-align: center;
  line-height: 2.25rem;
  margin-bottom: 1.5rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
