import { useState, useEffect, useCallback } from 'react';
import {
  Container,
  SectionContent,
  Breadcrumb,
  SectionTitle,
  Section,
} from '../../components/LegacyV4Components';

import { Button } from '../../components/Default/Button';
import { TableUser } from '../../components/AtomicDesign/molecules/TableUser';
import { InviteNewColab } from '../../components/AtomicDesign/molecules/inviteNewColab';
import { UnHappyPath } from '../../components/AtomicDesign/atoms/UnhappyPath';

import { getUsersByUnitId } from '../../services/requests/Unit/getUsersByUnitId';
import { useAuth } from '../../hooks/auth';
import { User } from '../../hooks/types';

import * as S from './styles';
import { CustomLoading } from '../../components/Default/Loadings/CustomLoading';

export function Unit() {
  const [users, setUsers] = useState<User[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const { user: loggedUser } = useAuth();

  const setUnitInfo = useCallback(async () => {
    setLoading(true);

    if (loggedUser) {
      const newUsers = await getUsersByUnitId({
        unitId: loggedUser.unitId,
        showDeleted: false,
        isActive: true,
      });

      if (newUsers) {
        setUsers(newUsers.users);
      }
    }

    setLoading(false);
  }, [loggedUser]);

  useEffect(() => {
    setUnitInfo();
  }, [setUnitInfo]);

  return (
    <>
      <Container>
        <Breadcrumb
          breadcrumbs={[
            { title: 'Central de Dashboards', link: '/dashboards' },
            { title: 'Unidade', link: '/unidade' },
          ]}
        />

        <Section>
          <SectionContent>
            <SectionTitle text="Unidade" />
            <S.Filters>
              {/* <Search placeholder="Pesquisar" width="360px" /> */}
              <Button
                small
                onClick={() => setOpenModal(true)}
                style={{ marginLeft: 'auto' }}
              >
                Convidar novo investidor
              </Button>
            </S.Filters>
            <S.Table>
              <S.Row>
                <S.Field>Investidor</S.Field>
                <S.Field>Email</S.Field>
                <S.Field style={{ width: '4rem' }} />
              </S.Row>

              {loading ? (
                <CustomLoading style={{ alignSelf: 'center' }} />
              ) : (
                users?.length > 0 &&
                users.map((user) => {
                  return <TableUser key={user._id} user={user} />;
                })
              )}
            </S.Table>

            {!loading && !users.length && (
              <S.UnHappyPath>
                <UnHappyPath
                  assetIndex={3}
                  title="Investidores ainda não cadastrados"
                />
              </S.UnHappyPath>
            )}
          </SectionContent>
        </Section>
      </Container>

      {openModal && (
        <InviteNewColab setIsOpen={setOpenModal} isOpen={openModal} />
      )}
    </>
  );
}
