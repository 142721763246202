import { LastUpdates } from '../../../types/lastUpdates';
import { customerApi as api } from '../../api';

export async function getLastUpdates(
  customerId: string,
): Promise<LastUpdates[] | false> {
  try {
    const response = await api.get<LastUpdates[]>(`/lastUpdates/${customerId}`);

    const lastUpdates: LastUpdates[] = response.data;

    return lastUpdates;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar os dados dos clientes, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
