import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';

import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Headline, Tooltip } from '../../../LegacyV4Components';
import { Modal } from '../../../Default/Modal';

import * as S from './styles';
import { EditPlanModal } from '../EditPlanModal';

export function AttemptsPlanModal({
  isShown,
  setIsShown,
}: {
  isShown: boolean;
  setIsShown(value: boolean): any;
}) {
  const [showEditPlanModal, setShowEditPlanModal] = useState(false);

  return (
    <>
      <Modal modalOpen={isShown} onBackground={() => setIsShown(false)}>
        <>
          <S.ModalWrapper>
            <S.ModalHeader>
              <Headline variant="h4">Tentativas de Cobrança</Headline>
              <button
                type="button"
                onClick={() => setIsShown(false)}
                aria-label="default"
              >
                <FiX />
              </button>
            </S.ModalHeader>
            <S.ModalBody>
              <S.Title>
                <p>
                  Quando não for identificado o pagamento na data definida, será
                  feita até 3 novas tentativas nos dias seguintes. Se falharem,
                  <span> o assinante é pausado</span>
                </p>
              </S.Title>
              <div
                style={{
                  marginTop: '2rem',
                }}
              >
                <S.RowTimeline>
                  <S.Bar>
                    <S.Dot style={{ backgroundColor: '#fabc2a' }} />
                  </S.Bar>
                  <S.Row>
                    <div className="first-element">
                      <p>Data da cobrança</p>
                    </div>
                    <div>
                      <span>Tentativa de cobrança</span>
                    </div>
                  </S.Row>
                </S.RowTimeline>
                <S.RowTimeline>
                  <S.Bar>
                    <S.Dot />
                  </S.Bar>
                  <S.Row>
                    <div className="first-element">
                      <p>2 dias depois</p>
                    </div>
                    <div>
                      <span>Nova tentativa de cobrança</span>
                    </div>
                  </S.Row>
                </S.RowTimeline>
                <S.RowTimeline>
                  <S.Bar>
                    <S.Dot />
                  </S.Bar>
                  <S.Row>
                    <div className="first-element">
                      <p>5 dias depois</p>
                    </div>
                    <div>
                      <span>Nova tentativa de cobrança</span>
                    </div>
                  </S.Row>
                </S.RowTimeline>
                <S.RowTimeline>
                  <S.Bar>
                    <S.Dot />
                  </S.Bar>
                  <S.Row>
                    <div className="first-element">
                      <p>7 dias depois</p>
                    </div>
                    <div>
                      <span>Nova tentativa de cobrança</span>
                    </div>
                  </S.Row>
                </S.RowTimeline>
                <S.RowTimeline>
                  <S.Bar
                    style={{
                      height: '1rem',
                    }}
                  >
                    <S.Dot
                      style={{
                        backgroundColor: '#E03131',
                      }}
                    />
                  </S.Bar>
                  <S.Row>
                    <div className="first-element">
                      <p>10 dias depois</p>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <span>Pausa da assinatura</span>
                      <Tooltip title="O cliente não receberá novas cobranças até as mesmas serem ativadas novamente.">
                        <AiOutlineQuestionCircle />
                      </Tooltip>
                    </div>
                  </S.Row>
                </S.RowTimeline>
              </div>
            </S.ModalBody>
            <S.ModalFooter />
          </S.ModalWrapper>
        </>
      </Modal>
      <EditPlanModal
        isShown={showEditPlanModal}
        setIsShown={setShowEditPlanModal}
        data={{}}
      />
    </>
  );
}
