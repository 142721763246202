import { User } from '../../../hooks/types';
import { usersApi as api } from '../../api';

export async function updateUserApi(
  userId: string,
  user: {
    mandateId?: string;
    seniorityId?: string;
    responsibility?: string;
    links?: { local: string; url: string }[];
  },
): Promise<User> {
  try {
    const response = await api.put(`/user/${userId}`, user);
    const updatedUser: any = response.data;

    return updatedUser;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao atualizar os dados do usuário, atualize a página e tente novamente 😕`,
      err,
    );
    return err.response.data;
  }
}
