import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { financeApi } from '../../api';

export type DataCoupons = ICouponsList[];

export interface ICouponsList {
  _id: string;
  name: string;
  disable: boolean;
  initialValidity: string;
  endValidity: string;
  discountType: string;
  value: number;
  createdAt: string;
  updatedAt: string;
  maximumQuantityAllowed: number;
  quantityAlreadyUsed: number;
  __v: number;
}

export function useGetCouponsList(): UseQueryResult<DataCoupons, DataCoupons> {
  return useQuery<DataCoupons, DataCoupons>(
    ['coupons'],
    () =>
      financeApi.get<DataCoupons>(`coupons`).then(async (r) => {
        return r.data;
      }),
    {},
  );
}
