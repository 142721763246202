import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp, FiSearch } from 'react-icons/fi';

import { Paragraph } from '../../../LegacyV4Components';
import { slugify } from '../../../../utils/slugify';
import * as S from './styles';

interface SelectSearchProps {
  id?: string;
  placeholder: string;
  value: string | string[];
  setValue: (value: string) => void;
  options: SelectSearchOption[];
  customStyles?: {
    selectWrapper?: string;
  };
  notSearch?: boolean;
  disabled?: boolean;
  multiOptions?: boolean;
}

interface SelectSearchOption {
  value: string;
  label: string;
  selected?: boolean;
  searchValue?: string;
}

export function SelectSearch({
  id = '',
  placeholder,
  value,
  setValue,
  options,
  customStyles,
  notSearch,
  disabled,
  multiOptions,
}: SelectSearchProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');

  const [processedOptions, setProcessedOptions] = useState<
    SelectSearchOption[]
  >([]);
  const [filteredOptions, setFilteredOptions] = useState<SelectSearchOption[]>(
    [],
  );

  const [displayValue, setDisplayValue] = useState(placeholder);

  const handlePlaceholderClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    setIsOpen(!isOpen);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleOptionClick = (selectedValue: string) => {
    setProcessedOptions(
      processedOptions.map((option) => {
        const isSelected = selectedValue === option.value;

        const alreadySelected = option.selected;

        if (isSelected && !alreadySelected) {
          setValue(selectedValue);
          setDisplayValue(option.label);
        } else if (isSelected && alreadySelected) {
          setValue('');
          setDisplayValue(placeholder);
        }

        return { ...option, selected: isSelected && !alreadySelected };
      }),
    );

    setIsOpen(false);
  };

  const CloseClickOutside = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  CloseClickOutside(wrapperRef);

  useMemo(() => {
    const optionsWithSlug = options.map((option) => {
      const isSelected = value === option.value;

      if (isSelected) {
        setDisplayValue(option.label);
      }

      return {
        ...option,
        searchValue: slugify(option.label),
        selected: isSelected,
      };
    });

    setProcessedOptions(optionsWithSlug);
  }, [options, value]);

  useMemo(() => {
    const querySlug = slugify(query);

    const searchedOptions = processedOptions.filter((option) => {
      if (!option?.searchValue) {
        return true;
      }
      return option?.searchValue?.indexOf(querySlug) > -1;
    });

    setFilteredOptions(searchedOptions);
  }, [processedOptions, query]);

  useMemo(() => {
    if (value && processedOptions.length > 0) {
      const hasSelectedOption = processedOptions.find(
        (option) => option.value === value,
      );

      if (!hasSelectedOption) {
        setValue('');
        setDisplayValue(placeholder);
      }
    }
  }, [placeholder, processedOptions, setValue, value]);

  return (
    <S.SelectWrapper
      id={id}
      className={`${isOpen ? 'open' : ''} ${
        customStyles?.selectWrapper || ''
      } `}
      title={displayValue}
      ref={wrapperRef}
    >
      <S.SelectPlaceholder
        className={`${value ? 'filled' : ''} ${isOpen ? 'open' : ''} ${
          disabled && 'disabled'
        }`}
        onClick={handlePlaceholderClick}
      >
        <Paragraph>{displayValue}</Paragraph>
        <S.SelectArrows>
          <FiChevronDown size={24} className="select-arrow arrow-down" />
          <FiChevronUp size={24} className="select-arrow arrow-up" />
        </S.SelectArrows>
      </S.SelectPlaceholder>
      <S.SelectOptionsWrapper className={isOpen ? 'open' : ''}>
        {!notSearch && (
          <S.SelectSearchWrapper>
            <S.SelectSearch>
              <input
                placeholder="Buscar"
                type="text"
                value={query}
                onChange={handleChange}
              />
              <FiSearch size={16} />
            </S.SelectSearch>
          </S.SelectSearchWrapper>
        )}
        <S.SelectOptionsBody>
          <S.SelectOptions>
            {filteredOptions.map((option) => (
              <S.SelectOption
                key={`${id}-${option.value}`}
                className={option.selected ? 'selected' : ''}
                title={option.label}
                onClick={() => handleOptionClick(option.value)}
              >
                {!multiOptions ? (
                  <>
                    <S.SelectOptionRadio className="radio" />
                    <Paragraph>{option.label}</Paragraph>
                  </>
                ) : (
                  <>
                    <S.SelectOptionCheckbox
                      className="checkbox"
                      checked={option.selected}
                    />

                    <Paragraph>{option.label}</Paragraph>
                  </>
                )}
              </S.SelectOption>
            ))}
          </S.SelectOptions>
        </S.SelectOptionsBody>
      </S.SelectOptionsWrapper>
    </S.SelectWrapper>
  );
}
