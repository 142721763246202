import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #f4f4f8;
  border-radius: 8px;
  padding: 40px 80px 40px 80px;
  @media (max-width: 880px) {
    padding: 40px 16px 40px 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  svg {
    width: 2rem;
    height: 2rem;
    color: #8383af;
  }
`;

export const Navbar = styled.section`
  display: flex;
  margin-top: 4.375rem;
  gap: 1.5rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: #8383af;
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3rem;
  p {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-left: 8px;
    }
  }
  span {
    font-size: 12px;
    font-weight: 400;
    color: #7f7f7f;
  }
`;

export const RequestsDesktop = styled.div`
  margin-top: 64px;
  height: fit-content;
  width: 32%;
  background: #fff;
  border-radius: 4px;
  display: ${(props) => props.color};

  .your-request {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #b4b4cf;
    padding: 16px 10px 8px;
    img {
      margin-right: 1rem;
      width: 24px;
      height: 24px;
    }
  }
  .your-product {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    padding: 16px 10px 0px;
  }

  section > p {
    padding: 10px 8px;
    font-size: 12px;
    color: #7f7f7f;
  }

  @media (max-width: 880px) {
    display: none;
  }
`;

export const RequestsMobile = styled.div`
  display: none;
  margin-top: 64px;
  height: fit-content;
  width: auto;
  background: #fff;
  border: 1px solid #00c49a;
  border-radius: 4px;
  .your-request {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #b4b4cf;
    padding: 16px 10px 8px;
    img {
      margin-right: 1rem;
      width: 24px;
      height: 24px;
    }
  }
  .your-product {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    padding: 16px 10px 0px;
  }

  section > p {
    padding: 4px 10px 8px;
    font-size: 12px;
    color: #7f7f7f;
  }

  @media (max-width: 880px) {
    display: ${(props) => props.color};
  }
`;

export const About = styled.div`
  display: flex;
  justify-content: ${(props) => props.theme};
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;
  border: 1px solid #00c49a;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 2px 6px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background: #ffffff;
  padding: 2.5rem 0.5rem;
  margin-inline: 10vw;
  @media (max-width: 880px) {
    margin-inline: 0;
    flex-direction: column;
  }

  p svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.3rem;
  }

  p {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Terms = styled.div`
  display: flex;
  font-size: 0.75rem;
  margin: 0.5rem 0;
  margin-top: 2rem;
  input {
    margin-right: 1rem;
    width: 1.3rem;
  }

  button {
    background: transparent;
    border: none;
    text-decoration: underline;
    font-weight: 600;
    font-size: 0.8rem;
  }

  @media (max-width: 450px) {
    p {
      font-size: 0.65rem;
    }
    input {
      margin-right: 0.2rem;
      width: 1.3rem;
    }

    button {
      font-size: 0.65rem;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  justify-content: ${(props) => props.theme};
  gap: 24px;
  @media (max-width: 880px) {
    flex-direction: column;
  }
`;

export const ContainerTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  width: 100%;
  gap: 20px;
  display: ${(props) => props.color};
  @media (max-width: 880px) {
    gap: 0px;
  }
  section {
    cursor: pointer;
    position: relative;
    display: flex;
    width: 100%;
    height: 56px;
    border-radius: 1px solid #00c49a;
    background: #fff;
  }
  span {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    font-family: 'Inter';
    @media (max-width: 880px) {
      display: none;
    }
  }
  .selected1 {
    border-radius: 4px;
    border: 1px solid #00c49a;
  }
  section {
    border: 1px solid #dadae7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: #7f7f7f;
      svg {
        color: #7f7f7f;
      }
  }
  svg {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
    @media (max-width: 880px) {
      margin: 0 auto;
    }
  }
  .selected {
    background: #fff;
    color: #333333;
      svg {
        color: #00c49a;
      }
    }
  }
  .NotSelected {
    // background: #00c49a;
    color: #333333;
    svg {
      color: #333333;
    }
  }
`;

export const CustomerAvatar = styled.img`
  max-width: 220px;
  max-height: 120px;
  object-fit: contain;
  border: none;
`;

export const Cookie = styled.div`
  position: fixed;
  right: 4rem;
  bottom: 1rem;
  border-radius: 50%;
  width: 3.2rem;
  height: 3.2rem;
  background-color: #00c49a;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 880px) {
    right: 1rem;
  }
  &:hover {
    background-color: #0ddeb1;
  }
`;
