import styled from 'styled-components';

export const Button = styled.button<{ width?: string; height?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  border: 2px solid;
  border-radius: 0.25rem;

  width: ${({ width }) => width || 'fit-content'};
  height: ${({ height }) => height || '45px'};

  font-family: 'Inter';
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 0.875rem;

  transition: color 0.3s, border-color 0.3s, background-color 0.3s;

  &[disabled] {
    cursor: no-drop;
    color: var(--secondary-main);
    border-color: var(--tertiary-dark);
    background-color: var(--tertiary-dark);
  }

  & > svg {
    width: 1rem;
    height: 1rem;
  }

  &.secondary {
    background-color: var(--white);
  }

  &.secondary[disabled] {
    color: var(--tertiary-dark);
    border-color: var(--tertiary-dark);
  }

  &.default {
    padding: calc(0.75rem - 2px) calc(2rem - 1.5px);
  }

  &.small {
    padding: calc(0.5rem - 2px) calc(1rem - 1.5px);
  }

  &.primary.red:not([disabled]) {
    color: var(--white);
    border-color: var(--primary-dark-2);
    background-color: var(--primary-dark-2);
  }

  &.primary.red:hover:not([disabled]) {
    border-color: var(--primary-dark-1);
    background-color: var(--primary-dark-1);
  }

  &.primary.red:active:not([disabled]) {
    border-color: var(--primary-dark-3);
    background-color: var(--primary-dark-3);
  }

  &.primary.green:not([disabled]) {
    color: var(--secondary-main);
    border-color: var(--success-2);
    background-color: var(--success-2);
  }

  &.primary.green[data-attention='true'],
  &.primary.green:hover:not([disabled]) {
    border-color: var(--success-1);
    background-color: var(--success-1);
  }

  &.primary.green:active:not([disabled]) {
    border-color: var(--success-3);
    background-color: var(--success-3);
  }

  &.secondary.red:not([disabled]) {
    color: var(--primary-dark-2);
    border-color: var(--primary-dark-2);
  }

  &.secondary.red:hover:not([disabled]) {
    color: var(--primary-dark-1);
    border-color: var(--primary-dark-1);
  }

  &.secondary.red:active:not([disabled]) {
    color: var(--primary-dark-3);
    border-color: var(--primary-dark-3);
  }

  &.secondary.green:not([disabled]) {
    color: var(--success-2);
    border-color: var(--success-2);
  }

  &.secondary.green:hover:not([disabled]) {
    color: var(--success-1);
    border-color: var(--success-1);
  }

  &.secondary.green:active:not([disabled]) {
    color: var(--success-3);
    border-color: var(--success-3);
  }

  &.tertiary:not([disabled]) {
    color: var(--success-2);
    border-color: transparent;
    background-color: transparent;
  }

  &.tertiary:hover:not([disabled]) {
    color: var(--success-1);
    border-color: var(--grayscale-4);
    background-color: var(--grayscale-4);
  }

  &.tertiary:active:not([disabled]) {
    color: var(--success-3);
    border-color: var(--grayscale-6);
    background-color: var(--grayscale-6);
  }
`;
