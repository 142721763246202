import { useState } from 'react';
import { LabDsButton } from 'v4web-components-react';
import { Paragraph } from '../../../LegacyV4Components';

import ActiveCampaignLogo from '../../../../assets/icons/active_campaign.svg';
import GoogleAdsLogo from '../../../../assets/icons/google_ads.svg';
import UnbounceLogo from '../../../../assets/icons/unbounce.svg';
import GoogleAnalytics from '../../../../assets/icons/google_analytics.svg';
import TrayEcommerceLogo from '../../../../assets/icons/tray_ecommerce.svg';
import KommoCrmLogo from '../../../../assets/icons/kommo_crm.svg';
import VTexLogo from '../../../../assets/icons/vtex.svg';
import TikTokAdsLogo from '../../../../assets/icons/tiktok.svg';
import Bitrix24Logo from '../../../../assets/icons/Bitrix24.png';
import RDStaionLogo from '../../../../assets/icons/rdstation.png';
import LojaIntegradaLogo from '../../../../assets/icons/lojaintegrada.png';
import PipedriveLogo from '../../../../assets/icons/pipedrive.png';

import { ConnectFacebook } from '../../molecules/ConnectFacebook';
import { ConfigGoogleAdsModal } from '../../molecules/TieAccountsModals/GoogleAdsModal';
import { ConfigFacebookAdsModal } from '../../molecules/TieAccountsModals/FacebookAdsModal';
import { ConfigActiveCampaignModal } from '../../molecules/TieAccountsModals/ActiveCampaignModal';
import { ConfigUnbounceModal } from '../../molecules/TieAccountsModals/UnbounceModal';
import { ConfigGoogleAnalytics4Modal } from '../../molecules/TieAccountsModals/GoogleAnalytics4Modal';
import { ConfigTrayEcommerceModal } from '../../molecules/TieAccountsModals/TrayEcommerceModal';
import { ConfigKommoModal } from '../../molecules/TieAccountsModals/KommoModal';
import { ConfigVtexModal } from '../../molecules/TieAccountsModals/VTexModal';
import { ConfigTiktokModal } from '../../molecules/TieAccountsModals/TikTokModal';
import { ConfigBitrixModal } from '../../molecules/TieAccountsModals/BitrixModal';
import { ConfigRDStationModal } from '../../molecules/TieAccountsModals/RDStationModal';
import { ConfigLojaIntegradaModal } from '../../molecules/TieAccountsModals/LojaIntegradaModal';
import { ConfigPipedriveModal } from '../../molecules/TieAccountsModals/PipedriveModal';

import * as S from './styles';

export function ConnectAccounts() {
  const [facebookAdsModal, setFacebookAdsModal] = useState(false);
  const [activeCampaignModal, setActiveCampaignModal] = useState(false);
  const [googleAdsModal, setGoogleAdsModal] = useState(false);
  const [unbounceModal, setUnbounceModal] = useState(false);
  const [googleAnalytics4Modal, setGoogleAnalytics4Modal] = useState(false);
  const [trayEcommerceModal, setTrayEcommerceModal] = useState(false);
  const [kommoModal, setKommoModal] = useState(false);
  const [vtexModal, setVtexModal] = useState(false);
  const [tiktokModal, setTiktokModal] = useState(false);
  const [bitrixModal, setBitrixModal] = useState(false);
  const [rdstationModal, setRdstationModal] = useState(false);
  const [lojaIntegradaModal, setLojaIntegradaModal] = useState(false);
  const [pipedriveModal, setPipedriveModal] = useState(false);

  const ads_services = [
    {
      name: 'Google Ads',
      logo: GoogleAdsLogo,
      action: () => setGoogleAdsModal(true),
    },
    {
      name: 'Google Analytics',
      logo: GoogleAnalytics,
      action: () => setGoogleAnalytics4Modal(true),
    },
    {
      name: 'ActiveCampaign',
      logo: ActiveCampaignLogo,
      action: () => setActiveCampaignModal(true),
    },
    {
      name: 'Unbounce',
      logo: UnbounceLogo,
      action: () => setUnbounceModal(true),
    },
    {
      name: 'Tray',
      logo: TrayEcommerceLogo,
      action: () => setTrayEcommerceModal(true),
    },
    {
      name: 'Kommo CRM',
      logo: KommoCrmLogo,
      action: () => setKommoModal(true),
    },
    {
      name: 'Vtex',
      logo: VTexLogo,
      action: () => setVtexModal(true),
    },
    {
      name: 'TikTok Ads',
      logo: TikTokAdsLogo,
      action: () => setTiktokModal(true),
      blur: false,
    },
    {
      name: 'Bitrix24',
      logo: Bitrix24Logo,
      action: () => setBitrixModal(true),
      blur: false,
    },
    {
      name: 'RD Station CRM',
      logo: RDStaionLogo,
      action: () => setRdstationModal(true),
      blur: false,
    },
    {
      name: 'Loja Integrada',
      logo: LojaIntegradaLogo,
      action: () => setLojaIntegradaModal(true),
      blur: false,
    },
    {
      name: 'Pipedrive',
      logo: PipedriveLogo,
      action: () => setPipedriveModal(true),
      blur: false,
    },
  ];

  return (
    <>
      <S.Cards>
        <ConnectFacebook setShowModal={setFacebookAdsModal} />
        {ads_services.map((service) => (
          <S.Blur blur={service.blur!}>
            <S.Card key={service.name}>
              <S.ImageIcon src={service.logo} alt={service.name} />
              <S.ButtonWrapper>
                <Paragraph>{service.name}</Paragraph>
                <LabDsButton
                  label={service.blur! ? 'Em breve' : 'Vincular'}
                  variant="outlined"
                  onHandleButton={() => service.action()}
                />
              </S.ButtonWrapper>
            </S.Card>
          </S.Blur>
        ))}
      </S.Cards>
      <ConfigFacebookAdsModal
        show={facebookAdsModal}
        onClose={() => setFacebookAdsModal(false)}
      />
      <ConfigGoogleAdsModal
        show={googleAdsModal}
        onClose={() => setGoogleAdsModal(false)}
      />
      <ConfigActiveCampaignModal
        show={activeCampaignModal}
        onClose={() => setActiveCampaignModal(false)}
      />
      <ConfigUnbounceModal
        show={unbounceModal}
        onClose={() => setUnbounceModal(false)}
      />
      <ConfigGoogleAnalytics4Modal
        show={googleAnalytics4Modal}
        onClose={() => setGoogleAnalytics4Modal(false)}
      />
      <ConfigTrayEcommerceModal
        show={trayEcommerceModal}
        onClose={() => setTrayEcommerceModal(false)}
      />
      <ConfigKommoModal
        show={kommoModal}
        onClose={() => setKommoModal(false)}
      />
      <ConfigVtexModal show={vtexModal} onClose={() => setVtexModal(false)} />
      <ConfigTiktokModal
        show={tiktokModal}
        onClose={() => setTiktokModal(false)}
      />
      <ConfigBitrixModal
        show={bitrixModal}
        onClose={() => setBitrixModal(false)}
      />
      <ConfigRDStationModal
        show={rdstationModal}
        onClose={() => setRdstationModal(false)}
      />
      <ConfigLojaIntegradaModal
        show={lojaIntegradaModal}
        onClose={() => setLojaIntegradaModal(false)}
      />
      <ConfigPipedriveModal
        show={pipedriveModal}
        onClose={() => setPipedriveModal(false)}
      />
    </>
  );
}
