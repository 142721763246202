import { parseCookies } from 'nookies';
import { stackApi } from '../../api';

interface EkyteCompany {
  _id: string;
  active: boolean;
  unitId: string;
  _v: number;
}

export async function getEkyteCompany(
  unitId: string,
): Promise<EkyteCompany | undefined> {
  try {
    const cookies = parseCookies(undefined);
    const token = cookies['v4company.token'];
    const { data } = await stackApi({
      method: 'GET',
      url: `/ekyte/unit/${unitId}`,
      headers: { Authorization: `Bearer ${token}` },
    });

    return data as EkyteCompany;
  } catch (err) {
    return undefined;
  }
}
