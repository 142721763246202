import React, { useMemo, useState } from 'react';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import * as S from './style';
import { LoadingTable } from './skeleton';
import { typeToConvert } from './functions';
import { TableSimpleProps } from './interfaces';
import { CopyButton } from '../CopyButton';

export const TableSimple = ({
  loading,
  data,
  onRowClick,
}: TableSimpleProps) => {
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  }>({
    key: '',
    direction: '',
  });

  const handleSort = (key: string) => {
    const direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
    if (data.headers.find((header) => header.key === key)?.sortable) {
      setSortConfig({ key, direction });
    }
  };

  const sortedRows = useMemo(() => {
    const sortedRowsData = [...data.rows];
    if (sortConfig.key) {
      sortedRowsData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedRowsData;
  }, [data.rows, sortConfig]);

  const checkTypeRow = (key: string) => {
    return key.split('.');
  };

  return (
    <S.TableWrapper>
      {loading ? (
        <LoadingTable coll={4} row={5} />
      ) : (
        <S.Table>
          <thead>
            <tr>
              {data.headers.map((header, index) => (
                <S.TableHeader
                  style={{ cursor: header?.sortable ? 'pointer' : 'default' }}
                  key={Number(index)}
                  onClick={() => handleSort(header.key)}
                >
                  {header.title}{' '}
                  {header?.sortable &&
                    sortConfig.key === header.key &&
                    sortConfig.key.length > 0 && (
                      <>
                        {sortConfig.direction === 'asc' ? (
                          <AiFillCaretUp />
                        ) : (
                          <AiFillCaretDown />
                        )}
                      </>
                    )}
                </S.TableHeader>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedRows.map((row, indexRow) => (
              <S.TableRow
                key={Number(indexRow)}
                onClick={() => onRowClick?.(row)}
              >
                {data.headers.map((header, index) => (
                  <S.TableCell
                    width={header.width}
                    key={Number(index)}
                    title={String(row[header.key])}
                  >
                    <S.Cell width={header.width}>
                      <p>
                        {typeToConvert(
                          checkTypeRow(header.key).length === 1
                            ? row[checkTypeRow(header.key)[0]]
                            : row[checkTypeRow(header.key)[0]][
                                checkTypeRow(header.key)[1]
                              ],
                          header?.type || 'string',
                        )}
                      </p>

                      {header.copy && (
                        <S.CopyButtonArea className="copy_button">
                          <CopyButton text={row[header.key]} />
                        </S.CopyButtonArea>
                      )}
                    </S.Cell>
                  </S.TableCell>
                ))}
              </S.TableRow>
            ))}
          </tbody>
        </S.Table>
      )}
    </S.TableWrapper>
  );
};
