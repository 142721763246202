import styled from 'styled-components';

import { Container } from '../../components/LegacyV4Components';

export const RoiContainer = styled(Container)`
  flex-grow: 1;
`;

export const Content = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-grow: 1;
`;

export const IFrameWrapper = styled.div`
  display: flex;
  flex-grow: 1;

  overflow: hidden;
  border-radius: 8px;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.12));

  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    width: 100vw;
    height: 100vh;

    border-radius: 0;
    filter: none;
  }

  iframe {
    border: none;
    flex-grow: 1;
  }
`;
