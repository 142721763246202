import { ReactNode } from 'react';

import { useAuth } from '../../../../../../hooks/auth';
import {
  CustomerIntegrationsContextData,
  useCustomerIntegrations,
} from '../../../../../../hooks/customerIntegrations';

import SelectQuery from '../../../../atoms/SelectQuery';

import { handleFetchInitialValue } from '../../../../../../services/requests/CustomersNew/handleFetchInitialValue';
import { handleFetchOptions } from '../../../../../../services/requests/CustomersNew/handleFetchOptions';

import * as S from './styles';

interface IntegrationEditRowProps {
  integration: AdsAccounts;
  renderEditRow: (
    integration: AdsAccounts,
    error: boolean,
    context: CustomerIntegrationsContextData,
  ) => ReactNode;
  error?: boolean;
}

export function IntegrationEditRow({
  integration,
  renderEditRow,
  error = false,
}: IntegrationEditRowProps) {
  const { user } = useAuth();
  const customerIntegrationsContext = useCustomerIntegrations();
  const { setValue, timestamp } = customerIntegrationsContext;

  async function handleFetchCustomersOptions(query: string, page: number) {
    return handleFetchOptions(query, page, user);
  }

  const selectedCustomer = integration?.value?.label ?? '-';

  return (
    <>
      <S.TableData id={`td-${integration._id}-${timestamp}`}>
        <SelectQuery
          id={integration._id}
          fetchInitialValue={
            integration.projectId
              ? () => handleFetchInitialValue(integration.projectId)
              : undefined
          }
          value={integration.value}
          fetchOptions={handleFetchCustomersOptions}
          setValue={(value) => setValue(integration, value)}
          placeholder="Selecione o cliente pertencente a esse ID"
        />

        {error && selectedCustomer === '-' && (
          <S.Error>Selecione o cliente</S.Error>
        )}
      </S.TableData>
      {renderEditRow(integration, error, customerIntegrationsContext)}
    </>
  );
}
