import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;

  .verify {
    color: var(--primary-main);
  }

  & svg {
    color: var(--grayscale-10);
  }

  .error {
    border-color: var(--error);
  }

  .flex {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  div:first-child {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const TableData = styled.div`
  min-width: 25vw;
  overflow: visible;
`;

export const Error = styled.p`
  color: var(--error);
  font-size: '0.75rem';
  opacity: '0';
`;
