import { UnitInterface } from '../../../types/units';
import { UpdateUnit } from '../../../types/updateUnit';
import { unitsApi as api } from '../../api';

export async function updateUnit(id: string, data: UpdateUnit) {
  try {
    const response = await api.put<UnitInterface>(`/units`, {
      ...data,
      _id: id,
    });
    const unit = response.data;
    return unit;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao atualizar a unidade, atualize a página e tente novamente 😕`,
      err,
    );
    return err.response.data.status ? 'error' : err.response.data.message;
  }
}
