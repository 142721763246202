import { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  LabDsBreadcrumb,
  LabDsButton,
  LabDsTable,
} from 'v4web-components-react';
import { IData } from 'v4web-components';
import {
  Container,
  Headline,
  Section,
  SectionContent,
} from '../../components/LegacyV4Components';
import { customerApi } from '../../services/api';
import { getInvestorsByCustomersId } from '../../services/requests/Customers/getInvestorsByCustomersId';

import * as S from './styles';

import { Customer } from '../../types/customer';
import { InvestorTeam } from '../../hooks/types';
import { AddInvestorModal } from './Components/AddInvestorModal';
import { RemoveInvestorModal } from './Components/RemoveInvestorModal';
import { UnHappyPath } from '../../components/AtomicDesign/atoms/UnhappyPath';

export function CustomerTeamEdit() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [customer, setCustomer] = useState<Customer>();
  const [removeInvestor, setRemoveInvestor] = useState<string>('');
  const [investorOnCustomer, setInvestorOnCustomer] = useState<InvestorTeam[]>(
    [],
  );
  const [modalAddInvestor, setModalAddInvestor] = useState<boolean>(false);
  const [modalRemoveInvestor, setModalRemoveInvestor] =
    useState<boolean>(false);

  useEffect(() => {
    async function getInvestors() {
      const usersPromise = await getInvestorsByCustomersId(id || '');
      if (usersPromise) setInvestorOnCustomer(usersPromise);
      return usersPromise;
    }
    getInvestors();
  }, [id, modalAddInvestor, modalRemoveInvestor]);

  const getCustomer = useCallback(async () => {
    await customerApi({
      url: `/customer/${id}`,
      method: 'GET',
    })
      .then((res) => setCustomer(res.data as Customer))
      .catch(() => {
        return;
      });
  }, [id]);

  const handlerRemoveUserFromProject = (idUser: string) => {
    setRemoveInvestor(idUser);
    setModalRemoveInvestor(true);
    return idUser;
  };

  const headerTableHistoric: IData['headers'] = [
    {
      title: 'Investidor',
      colKey: 'name',
      type: 'text',

      sortable: false,
    },
    {
      colKey: 'mandate',
      title: 'Cargo',
      type: 'text',
      sortable: false,
    },
    {
      colKey: 'seniority',
      title: 'Senioridade',
      type: 'text',

      sortable: false,
    },
    {
      colKey: 'dedication',
      title: 'Dedicação',
      type: 'text',

      sortable: false,
    },
  ];

  const tableContent: IData['rows'] = useMemo(() => {
    const rowsData: IData['rows'] = [];
    investorOnCustomer?.forEach((currentUser: InvestorTeam) => {
      rowsData.push({
        mandate: {
          text: {
            title: currentUser.user.mandate?.name,
          },
        },
        seniority: {
          text: {
            title: currentUser.user.seniority?.name,
          },
        },
        dedication: {
          text: {
            title: currentUser.isDedicated ? 'Dedicado' : 'Compartilhado',
          },
        },
        name: {
          text: {
            title: currentUser.user.name,
            avatar: true,
            avatarImgSRC: currentUser.user.picture,
          },
        },
        action: {
          action: {
            items: [
              {
                key: 'removeFromProject',
                icon: 'menu',
                label: 'Remover do projeto',
                event: () => {
                  handlerRemoveUserFromProject(currentUser.user._id as string);
                },
              },
            ],
          },
        },
      });
    });
    return rowsData;
  }, [investorOnCustomer]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  const breadcrumbsItems = [
    {
      label: 'Meus Clientes',
      link: '/clientes',
      key: 'Meus Clientes',
      event: () => {
        navigate('/clientes');
      },
    },
    {
      label: `${customer?.name ?? ''}`,
      link: `/cliente/${customer?._id}`,
      key: `${customer?.name ?? ''}`,
      event: () => {
        navigate(`/cliente/${customer?._id}`);
      },
    },
    {
      label: 'Time responsável',
      link: `/cliente/${customer?._id}/team`,
      key: 'Time responsável',
      event: () => {
        navigate(`/cliente/${customer?._id}/team`);
      },
    },
    {
      label: 'Editar time responsável',
      link: '',
      key: 'Editar time responsável',
      event: () => {
        return;
      },
    },
  ];

  return (
    <>
      <Container>
        <LabDsBreadcrumb breadcrumbs={breadcrumbsItems} />

        <Section>
          <SectionContent>
            <S.Row>
              <Headline variant="h3">Editar time responsável</Headline>
              {!customer?.isNewPrice && (
                <LabDsButton
                  variant="outlined"
                  label="Adicionar investidor"
                  leadingIcon="add"
                  onClick={() => {
                    setModalAddInvestor(true);
                  }}
                />
              )}
            </S.Row>
            {customer?.isNewPrice ? (
              <S.Subtitle>
                Esse é um projeto de <b>New Price</b>, por isso alumas posições
                são fixas e devem estar sempre preenchidas.
              </S.Subtitle>
            ) : (
              <S.Subtitle>
                Edite o time resposável, se for preciso acrescente mais
                investidores.
              </S.Subtitle>
            )}

            {tableContent.length ? (
              <LabDsTable
                data={{ headers: headerTableHistoric, rows: tableContent }}
                actionField
              />
            ) : (
              <UnHappyPath
                assetIndex={0}
                title="Nenhum investidor foi adicionado neste projeto"
                descriptionItems={['Adicione investidores no projeto']}
              />
            )}
          </SectionContent>
        </Section>
      </Container>
      <AddInvestorModal
        isShown={modalAddInvestor}
        setIsShown={() => setModalAddInvestor(false)}
        unitId={customer?.units[0]._id || ''}
        customerName={customer?.name || ''}
        customerId={customer?._id || ''}
      />
      <RemoveInvestorModal
        isShown={modalRemoveInvestor}
        setIsShown={() => setModalRemoveInvestor(false)}
        customerId={customer?._id || ''}
        userId={removeInvestor}
      />
    </>
  );
}
