export const audiences = [
  {
    title: 'Comércios de doces',
    description: '',
    value: 1,
  },

  {
    title: 'Lojas de Calçados',
    description: '',
    value: 2,
  },
  {
    title: 'Lojas de jóias, relógios e bijuterias',
    description: '',
    value: 4,
  },
  {
    title: 'Comércio de material de construção',
    description: '',
    value: 3,
  },
  {
    title: 'Comércio de cosméticos e perfumaria',
    description: '',
    value: 5,
  },
  {
    title: 'Consultorias e assessorias financeira',
    description: '',
    value: 6,
  },
];
