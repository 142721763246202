import React from 'react';
import * as S from './styles';

interface SectionProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export function Section({ children, style }: SectionProps) {
  return <S.Section style={style}>{children}</S.Section>;
}
