import { BiMinusCircle, BiPlusCircle } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { AiOutlineReload } from 'react-icons/ai';
import { LabDsBreadcrumb, LabDsSkeleton } from 'v4web-components-react';
import {
  Container,
  Section,
  Headline,
} from '../../components/LegacyV4Components';
import * as S from './styles';

import { formatStringToBRLDate } from '../../utils/formatStringToBRLDate';

import { getCustomerHistoric } from '../../services/requests/Customers/getCustomerHistoric';

import { Customer, HistoricParticipationInterface } from '../../types/customer';
import { UnHappyPath } from '../../components/AtomicDesign/atoms/UnhappyPath';
import { getCustomerById } from '../../services/requests/Customers/getCustomerById';

export function CustomerHistoric() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [customer, setCustomer] = useState<Customer | null>();
  const [customerHistoric, setCustomerHistoric] = useState<
    HistoricParticipationInterface[]
  >([]);

  useEffect(() => {
    async function getHistory() {
      const response = await getCustomerHistoric(id as string);
      if (response) {
        setCustomerHistoric(response.reverse());
      }
      setIsLoading(false);
    }
    async function getCustomer() {
      const response = await getCustomerById(id || '');
      if (response) setCustomer(response);
    }

    getHistory();
    getCustomer();
  }, [id, navigate]);

  const iconAndAction = {
    icon: {
      ASSIGN: <BiPlusCircle color="var(--success-2)" size="1.5rem" />,
      UNASSIGN: <BiMinusCircle color="var(--error)" size="1.5rem" />,
      REASSIGN: <AiOutlineReload color="var(--success-2)" size="1.5rem" />,
    },
    action: {
      ASSIGN: 'adicionado',
      UNASSIGN: 'removido',
      REASSIGN: 'adicionado novamente',
    },
  };

  return (
    <>
      <Container>
        <LabDsBreadcrumb
          breadcrumbs={[
            {
              label: 'Clientes da unidade',
              link: `/clientes`,
              key: 'Clientes da unidade',
              event: () => {
                navigate('/clientes');
              },
            },
            {
              label: customer?.name ?? '',
              link: `/cliente/${id}`,
              key: customer?.name ?? '',
              event: () => {
                navigate(`/cliente/${id}`);
              },
            },
            {
              label: 'Time responsável',
              link: `/cliente/${id}/team`,
              key: 'Time responsável',
              event: () => {
                navigate(`/cliente/${id}/team`);
              },
            },
            {
              label: 'Histórico',
              link: 'Histórico',
              key: '',
              event: () => {
                return;
              },
            },
          ]}
        />

        <S.Content>
          <Section>
            <S.Body>
              <S.Row>
                <Headline variant="h3">Histórico</Headline>
              </S.Row>
              <S.List>
                {isLoading && (
                  <>
                    <LabDsSkeleton width="100%" height="50" />
                    <LabDsSkeleton width="100%" height="50" />
                    <LabDsSkeleton width="100%" height="50" />
                  </>
                )}

                {isLoading || customerHistoric.length ? (
                  customerHistoric?.map(
                    (historicAction: HistoricParticipationInterface) => {
                      return (
                        <S.Info>
                          {iconAndAction.icon[historicAction.action]}

                          <S.Text>
                            <p>
                              Investidor {historicAction.user?.name}{' '}
                              {iconAndAction.action[historicAction.action]}
                            </p>
                            <Headline className="title" variant="h6">
                              Data:{' '}
                              <span>
                                {formatStringToBRLDate(
                                  historicAction.createdAt.toString(),
                                )}
                              </span>
                            </Headline>
                          </S.Text>
                        </S.Info>
                      );
                    },
                  )
                ) : (
                  <UnHappyPath
                    assetIndex={0}
                    title="Nenhuma ação foi feita ainda"
                    descriptionItems={['Adicione investidores no projeto']}
                  />
                )}
              </S.List>
            </S.Body>
          </Section>
        </S.Content>
      </Container>
    </>
  );
}
