import { stackApi } from '../../api';

interface EkytePermission {
  userId: string;
  accessLevel: number;
}

export async function postPermissionEkyte(
  userId: string,
  accessLevel: number,
): Promise<EkytePermission | undefined> {
  try {
    const response = await stackApi.post<EkytePermission>(`/ekyte/user`, {
      userId,
      accessLevel,
    });

    return response.data;
  } catch (err) {
    return undefined;
  }
}

export async function putPermissionEkyte(
  userId: string,
  accessLevel: number,
  unitId: string | undefined,
): Promise<EkytePermission | undefined> {
  try {
    const response = await stackApi.put<EkytePermission>(
      `/ekyte/user/${userId}`,
      {
        unitId,
        accessLevel,
      },
    );

    return response.data;
  } catch (err) {
    return undefined;
  }
}
