import { useState } from 'react';
import { IoAppsOutline } from 'react-icons/io5';
import { Headline } from '../../../LegacyV4Components';

import v4Logo from '../../../../assets/logos/v4-company-logo.png';

import * as S from './styles';

interface ToolsDropdownProps {
  tools?: {
    roiWeek?: boolean;
  };
}

export function ToolsDropdown({ ...props }: ToolsDropdownProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showToolsDropdown = Object.values(props?.tools || []).some(
    (value) => value === true,
  );

  return showToolsDropdown ? (
    <S.ToolsWrapper>
      <S.ToggleTools
        className={isModalOpen ? 'active' : ''}
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        <IoAppsOutline />
      </S.ToggleTools>
      <S.ToolsModal isExpanded={isModalOpen}>
        <div className="modal-header">
          <Headline variant="h6">Hub de Ferramentas</Headline>
        </div>
        <div className="modal-body" />
        <div className="modal-footer">
          <img src={v4Logo} alt="V4 Company" />
        </div>
      </S.ToolsModal>
    </S.ToolsWrapper>
  ) : null;
}
