import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 20rem;

  padding: 1.5rem 2rem;
  width: 100%;

  background-color: var(--white);
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);

  .delete-column {
    text-align: right;
    cursor: pointer;
  }

  div {
    max-height: 13rem;
  }
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    margin: 0.5rem 0;
  }
  .no-content{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: #7F7F7F;
    margin-top:2rem;
  }
  h4{
    color var(--success-2);
    margin: 1rem 0
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const HeaderPreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EditStakeholder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 14px;
  color: var(--success-1);
  cursor: pointer;
  text-decoration: underline;
  svg {
    margin-left: 0.5rem;
  }
`;
