import { financeApi } from '../../api';

export interface IPlanList {
  _id: string;
  name: string;
  identifier: string;
  interval: string;
  intervalType: string;
  valueCents: number;
  royalties: number;
  payableWith: string[];
  maxCycles?: number;
  disabled?: boolean;
  billing_days?: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export async function getPlanByCustomer(
  recipientId: string | undefined,
): Promise<IPlanList[]> {
  try {
    const response = await financeApi.get<IPlanList[]>(
      `/subscriptions/plans/customer?customerId=${recipientId}`,
    );

    return response.data;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao carregar o plano, atualize a página e tente novamente 😕`,
      err,
    );
    return [];
  }
}
