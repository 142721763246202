import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { newAds } from '../../api';

interface ResponseData {
  userId: string;
  unitId: string;
  fbAccessToken: string;
}

export async function getFacebookTokens(): Promise<ResponseData[]> {
  const { data } = await newAds.get<ResponseData[]>(`/facebookAccounts`);
  return data;
}

export function useGetFacebookTokens(): UseQueryResult<ResponseData[]> {
  return useQuery<ResponseData[]>(['get-facebook-tokens'], () =>
    getFacebookTokens(),
  );
}
