import { IData } from 'v4web-components';
import { investmentInfos } from '../../../../../services/requests/Unit/calculate';
import { convertNumberToBRL } from '../../../../../utils/convertNumberToBRL';

export function paymentMenu({
  discount,
  totalInvestment,
  paymentMethod,
}: {
  discount: number;
  totalInvestment?: investmentInfos['detailedInvestmentPerMonth'];
  paymentMethod: string;
}): IData {
  const DISCOUNT_IN_CASH = 0.88;
  const DISCOUNT_PAY_IN_SIX_MONTHS = 0.94;

  const HEADER: IData['headers'] = [
    {
      title: 'Forma de pagamento',
      colKey: 'paymentMethod',
      type: 'text',
    },
    {
      title: 'Investimento total',
      colKey: 'totalInvestment',
      type: 'text',
    },
  ];

  const ROWS: IData['rows'] = [
    {
      paymentMethod: {
        text: { title: 'À vista' },
      },
      totalInvestment: {
        text: {
          title: `${
            totalInvestment?.operationalInvestment
              ? convertNumberToBRL(
                  totalInvestment?.operationalInvestment * DISCOUNT_IN_CASH -
                    (paymentMethod === 'inCash' ? discount : 0),
                )
              : '-'
          }`,
        },
      },
    },
    {
      paymentMethod: {
        text: { title: '6 meses' },
      },
      totalInvestment: {
        text: {
          title: `${
            totalInvestment?.operationalInvestment
              ? convertNumberToBRL(
                  totalInvestment?.operationalInvestment *
                    DISCOUNT_PAY_IN_SIX_MONTHS -
                    (paymentMethod === 'semester' ? discount : 0),
                )
              : '-'
          }`,
        },
      },
    },
    {
      paymentMethod: {
        text: { title: 'Mensal' },
      },
      totalInvestment: {
        text: {
          title: `${
            totalInvestment?.operationalInvestment
              ? convertNumberToBRL(
                  totalInvestment?.operationalInvestment -
                    (paymentMethod === 'monthly' ? discount : 0),
                )
              : '-'
          }`,
        },
      },
    },
  ];

  return {
    headers: HEADER,
    rows: ROWS,
  };
}
