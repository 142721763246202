import { ReactNode } from 'react';

import unbounceTutorial from '../../assets/images/unbounce-tutorial.png';
import googleMccThumb from '../../assets/images/google-mcc-thumb.png';

export interface IQuestion {
  question: string;
  description: string;
  content: ReactNode;
}

export const questions: IQuestion[] = [
  {
    question: 'Como conectar contas de anúncio do Facebook Ads',
    description:
      'Saiba o passo a passo para conectar as contas de anúncio do Facebook Ads com o Mkt.Lab',
    content: (
      <>
        <b>
          Para conectar uma conta de anúncio do Facebook Ads ao Mkt.Lab pelo
          menu &quot;Configurações&quot;:
        </b>
        1. Acesse o botão &quot;Conecte-se agora&quot; na opção &quot;Facebook
        Ads&quot;. Você será redirecionado para páginado Facebook para fazer
        login em sua conta e dar permissão de edição para a V4 Company; <br />
        2. No Mkt.Lab, clique em &quot;Vincular contas&quot;, na aba
        &quot;Facebook Ads&quot;;
        <br />
        3. Na coluna &quot;Cliente&quot;, vincule um cliente específico ao seu
        respectivo ID e nome da conta de anúncio;
        <br />
        4. Clique em &quot;Seguir para o resumo&quot;, analise os dados
        configurados e confirme. <br />
        <br />
        Obs: Caso você não tenha dado todas as permissões para V4, as contas de
        seus clientes não serãomostradas no menu de conexão. Para corrigir, será
        necessário clicar no botão &quot;Atualizar login&quot; e refazer o passo
        1. <br />
        <br />
        <b>
          Para conectar uma conta de anúncio do Facebook Ads ao Mkt.Lab pela
          página do cliente:
        </b>
        1. Acesse botão &quot;Conectar&quot; na opção &quot;Facebook Ads&quot;
        <br />
        2. Utilize a caixa de pesquisa para encontrar a conta de anúncio do
        cliente específico, pesquisandopelo ID ou nome da conta;
        <br />
        3. Clique no botão &quot;Adicionar +&quot; e, logo depois, em salvar
        alterações.
      </>
    ),
  },
  {
    question: 'Como conectar contas de anúncio do Google Ads',
    description:
      'Saiba o passo a passo para conectar as contas de anúncio do Google Ads com o Mkt.Lab',
    content: (
      <>
        Obs.: A sua unidade precisa estar na MCC da V4 Company Matriz para que a
        integração seja feita. <br />
        Acesse o link para saber como adicionar uma unidade ao MCC: <br />
        <b>
          <a href="https://edu.v4company.com/pt/docs/processo-de-vinculo-de-mcc-da-unidade-com-a-mcc-da-v4-matriz?highlight=mcc">
            https://edu.v4company.com/pt/docs/processo-de-vinculo-de-mcc-da-unidade-com-a-mcc-da-v4-matriz?highlight=mcc
          </a>
        </b>
        <br />
        Para conectar uma conta de anúncio do Google Ads ao Mkt.Lab, pelo menu
        &quot;Configurações&quot;: <br />
        1. Acesse o botão &quot;Conecte-se agora&quot; na aba &quot;Google
        Ads&quot;; <br />
        2. Na coluna &quot;Cliente&quot;, selecione o cliente que deseja
        conectar; <br />
        3. Vincule o cliente específico ao seu respectivo ID da conta de anúncio
        do Google Ads; <br />
        4. Clique em &quot;Seguir para o resumo&quot;, analise os dados
        configurados e confirme.
        <br />
        <br />
        Para conectar uma conta de anúncio do Google Ads ao Mkt.Lab pela página
        do cliente: 1. Acesse o botão &quot;Conectar&quot; na opção &quot;Google
        Ads&quot;; 2. Utilize a caixa de pesquisa para encontrar a conta de
        anúncio do cliente específico,
        <br />
        pesquisando pelo ID ou nome da conta. <br />
        3. Clique no botão &quot;Adicionar +&quot; e, logo depois, em salvar
        alterações.
      </>
    ),
  },
  {
    question: 'Como conectar contas de anúncio do Google Analytics',
    description:
      'Saiba o passo a passo para conectar as contas de anúncio do Google Analytics com o Mkt.Lab',
    content: (
      <>
        Certifique-se de que tenha dado acesso de leitor, com o e-mail, à
        propriedade do cliente ou ao grupo
        <br />
        de contas do Google Analytics. Para cada tipo de plataforma, utilize um
        dos e-mails abaixo: <br />
        <p>
          Google Analytics Universal:
          <b>v4data-analytics@sturdy-bastion-371713.iam.gserviceaccount.com</b>
        </p>
        <p>
          <b>
            Google Analytics 4:
            v4data-analytics@ga4-dados.iam.gserviceaccount.com
          </b>
        </p>
        <br />
        Para criar um grupo de contas no Google Analytics, acesse o seguinte
        tutorial:
        <br />
        <br />
        <b>
          <a
            href="https://www.loom.com/share/9d331320b4694250bb0256f253ec3157"
            target="_blank"
            rel="noreferrer"
          >
            <img src={googleMccThumb} alt="Tutorial Google MCC" />
          </a>
        </b>
        <br />
        <b>
          Para conectar uma conta de anúncio do Google Analytics ao Mkt.Lab,
          pelo menu &quot;Configurações&quot;:
        </b>
        1. Acesse o botão &quot;Conecte-se agora&quot; na aba &quot;Google
        Analytics&quot;; <br />
        2. Selecione o tipo de conta que deseja conectar (Google Analytics
        Universal ou Google Analytics 4);
        <br />
        3. Clique em &quot;Adicionar novo view do Google Analytics +&quot; para
        criar um novo campo de preenchimento; <br />
        4. Na coluna &quot;Cliente&quot;, selecione o cliente que deseja
        conectar;
        <br />
        5. Informe a view do cliente no Google Analytics; 6. Clique em
        &quot;Seguir para o resumo&quot;, analise os dados configurados e
        confirme.
        <br />
        <br />
        <b>
          Para conectar uma conta de anúncio do Google Analytics ao Mkt.Lab pela
          página do cliente:
        </b>
        1. Acesse o botão &quot;Conectar&quot; na opção &quot;Google
        Analytics&quot;; <br />
        2. Selecione o tipo de conta que deseja conectar (Google Analytics
        Universal ou Google Analytics 4);
        <br />
        3. Utilize a caixa de pesquisa para encontrar a conta de anúncio do
        cliente específico,
        <br />
        pesquisando pelo ID ou nome da conta. <br />
        4. Clique no botão &quot;Adicionar +&quot; e, logo depois, em salvar
        alterações.
      </>
    ),
  },
  {
    question: 'Como conectar contas de anúncio do Unbounce',
    description:
      'Saiba o passo a passo para conectar as contas de anúncio do Unbounce com o Mkt.Lab',
    content: (
      <>
        <b>
          Para conectar uma conta de anúncio do Unbounce ao Mkt.Lab, pelo menu
          &quot;Configurações&quot;:
        </b>
        1. Acesse o botão &quot;Conecte-se agora&quot; na aba
        &quot;Unbounce&quot;; <br />
        2. Clique em &quot;Adicionar novo ID +&quot; para criar um novo campo de
        preenchimento; <br />
        3. Na coluna &quot;Cliente&quot;, selecione o cliente que deseja
        conectar; <br />
        4. Informe a sequência de números que configura o ID da landing page do
        cliente no Unbounce, presente na URL do site, a partir da palavra
        pages/, como mostra a imagem abaixo;
        <img
          src={unbounceTutorial}
          alt="Tutorial do Unbounce"
          style={{ width: 'fit-content', padding: '10px' }}
        />
        5. Clique em &quot;Seguir para o resumo&quot;, analise os dados
        configurados e confirme.
        <br />
        <br />
        <b>
          Para conectar uma conta de anúncio do Unbounce ao Mkt.Lab pela página
          do cliente:
        </b>
        1. Acesse o botão &quot;Conectar&quot; na opção &quot;Unbounce&quot;;
        <br />
        2. Utilize a caixa de pesquisa para encontrar a conta de anúncio do
        cliente específico,
        <br />
        pesquisando pelo ID ou nome da conta. <br />
        3. Clique no botão &quot;Adicionar +&quot; e, logo após, em salvar
        alterações.
      </>
    ),
  },
  {
    question: 'Como conectar contas de anúncio do Active Campaign',
    description:
      'Saiba o passo a passo para conectar as contas de anúncio do Active Campaign com o Mkt.Lab',
    content: (
      <>
        <b>
          Para conectar uma conta de anúncio do Active Campaign ao Mkt.Lab, pelo
          menu &quot;Configurações&quot;:
        </b>
        1. Acesse o botão &quot;Conecte-se agora&quot; na aba &quot;Active
        Campaign&quot;; <br />
        2. Clique em &quot;Adicionar novo ID +&quot; para criar um novo campo de
        preenchimento; <br />
        3. Na coluna &quot;Cliente&quot;, selecione o cliente que deseja
        conectar; <br />
        4. Informe a URL e o token do cliente no Active Campaign; 5. Clique em
        &quot;Seguir para o resumo&quot;, analise os dados configurados e
        confirme.
        <br />
        <br />
        <b>
          Para conectar uma conta de anúncio do Active Campaign ao Mkt.Lab pela
          página do cliente:
        </b>
        1. Acesse o botão &quot;Conectar&quot; na opção &quot;Active
        Campaign&quot;; <br />
        2. Informe a URL e o token do cliente no Active Campaign; 3. Clique no
        botão &quot;Adicionar +&quot; e, logo após, em salvar alterações.
      </>
    ),
  },
  {
    question: 'Como conectar contas de anúncio da Tray',
    description:
      'Saiba o passo a passo para conectar as contas de anúncio da Tray com o Mkt.Lab',
    content: (
      <>
        No site da Tray, acesse o marketplace de aplicativos e pesquise e
        instale o aplicativo da V4 Company. Logo após, acesse o aplicativo
        instalado e copie o código e a URL disponibilizados;
        <br />
        <br />
        <b>
          Para conectar uma conta de anúncio do Google Analytics ao Mkt.Lab,
          pelo menu &quot;Configurações&quot;:
        </b>
        1. No Mkt.lab, acesse a página de integrações; <br />
        2. Clique no botão &quot;Conecte-se agora&quot; na aba &quot;Tray&quot;;{' '}
        <br />
        3. Clique em &quot;Adicionar novo ID +&quot; para criar um novo campo de
        preenchimento; <br />
        4. Na coluna &quot;Cliente&quot;, selecione o cliente que deseja
        conectar; <br />
        5. Informe a URL e o code do cliente na Tray; 6. Clique em &quot;Seguir
        para o resumo&quot;, analise os dados configurados e confirme.
        <br />
        <br />
        <b>
          Para conectar uma conta de anúncio do Facebook Ads ao Mkt.Lab pela
          página do cliente:
        </b>
        1. No Mkt.Lab, acesse o botão &quot;Conectar&quot; na opção
        &quot;Tray&quot;; <br />2 Informe a URL e o code do cliente na Tray; 3.
        Clique no botão &quot;Adicionar +&quot; e, logo após, em salvar
        alterações.
      </>
    ),
  },
];
