import { newAds } from '../../api';

interface ICreateAudience {
  name: string;
  description: string;
  integrationId: string;
  cohort_id: number;
}

export async function createAudience(
  audience: ICreateAudience,
): Promise<{ id?: string; url?: string }> {
  const { data } = await newAds.post<{ id?: string; url?: string }>(
    `/audience`,
    audience,
  );
  const createdAudience = data;
  return createdAudience;
}
