import { Form as Unform } from '@unform/web';

import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1120px;
  height: 100%;
  margin: 4rem auto;
  background: var(--white);
  box-shadow: 0px 20px 20px rgba(147, 147, 147, 0.1);
  border-radius: 0.5rem;
  padding: 2rem;

  > h1 {
    font-size: 2.5rem;
  }

  > button {
    margin-top: 2rem;
    margin-right: 0;
    margin-left: auto;
  }

  > ul {
    margin-top: 1rem;
  }
`;

export const Form = styled(Unform)`
  padding: 2rem;
  background: var(--white);
  border-radius: 0.5rem;

  h3 {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  > div {
    width: 20rem;
  }

  footer {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;

    button:first-child {
      margin-right: 1rem;
    }
  }
`;
