import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100% - 3rem;
  padding: 2rem 0;
  > section {
    background: var(--white);
    max-width: 87.5rem;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 0.5rem;
    h1 {
      margin-bottom: 2rem;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1rem;
`;

export const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  a {
    font-weight: bold;
    text-decoration: underline;

    display: flex;
    align-items: center;

    svg {
      margin-right: 0.625rem;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  div {
    width: 370px;
  }
  button {
    margin-left: 2rem;
    width: auto;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  a {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    text-decoration: underline;
    svg {
      margin-right: 0.5rem;
    }
  }
`;

export const UnHappyPath = styled.div`
  align-self: center;
  margin-top: 1rem;
  text-align: center;
`;

export const DotMenu = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 4rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const MenuContent = styled.div`
  display: flex;
`;

export const unitLockedMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-dark-3);
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Nunito Sans';
  margin-bottom: 1.2rem;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DotOptions = styled.div<{ isHidden: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.5s;
  border-radius: 0.5rem;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
  opacity: 0;
  max-height: 15rem;
  button {
    background: var(--white);
    padding: 1rem 1.5rem;
    border: none;
    font-weight: 400;
    text-align: left;
    pointer-events: none;
  }
  ${({ isHidden }) =>
    isHidden &&
    css`
       {
        max-height: 15rem;
        overflow: hidden;
        opacity: 1;
        button {
          pointer-events: auto;
        }
      }
    `}
  button + button {
    border-top: 1px solid var(--tertiary-dark);
  }
`;
