import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const LabelContainer = styled.div<{
  isOpen: boolean;
}>`
  display: flex;
  height: 3rem;
  align-items: center;

  input {
    width: 100%;
    height: 100%;
    margin-right: 0.5rem;
    padding: 0.5rem 0;
    color: var(--secondary-light);
    border: 1px solid transparent;
    transition: 0.2s ease all;
    border-radius: 0.25rem;
    flex: 1;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    background-color: transparent;
    border: none;
    transition: 0.2s ease all;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    width: 3rem;
    height: 3rem;

    svg {
      color: var(--grayscale-60);
      fill: var(--grayscale-60);
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      input {
        color: var(--secondary-main);
        font-weight: 700;
        border: 1px solid var(--grayscale-20);
        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.06);
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
        padding: 0.75rem 1rem;
      }

      button {
        opacity: 1;
        border: 1px solid var(--grayscale-20);
        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.06);
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));

        svg {
          color: var(--success-2);
          fill: var(--success-2);
        }
      }
    `}

  :hover {
    input {
      border: 1px solid var(--grayscale-20);
      box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.06);
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
      padding: 0.75rem 1rem;
    }

    button {
      opacity: 1;
      border: 1px solid var(--grayscale-20);
      box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.06);
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12));
    }
  }
`;

export const DropdownList = styled.div<{ isOpen: boolean }>`
  display: none;
  width: 100%;
  flex-direction: column;
  border: 1px solid var(--grayscale-20);
  margin-top: 0.5rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12),
    0px 2px 6px 2px rgba(0, 0, 0, 0.06);
  background: var(--white);
  border-radius: 0.25rem;
  transition: 0.4s ease all;
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 3rem;

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: flex;
      opacity: 1;
    `}
`;

export const List = styled.div`
  margin: 0.5rem 0 0;
  padding-top: 0.5rem;
  border-top: 1px solid var(--grayscale-4);
  display: flex;
  flex-direction: column;
`;

export const Item = styled.label<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  height: 3rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  position: relative;
  z-index: 20;

  :hover {
    background: var(--grayscale-4);
  }

  input {
    margin-right: 1.5rem;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15ch;
    color: var(--secondary-main);
  }

  ${({ isChecked }) =>
    isChecked &&
    css`
      background: var(--grayscale-4);
    `}
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background: var(--grayscale-4);
  border: 1px solid var(--grayscale-10);
  border-radius: 3rem;
  margin-right: 1rem;

  svg {
    color: var(--primary-dark-1);
  }
`;
