import styled from 'styled-components';

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: center;
  @media (max-width: 880px) {
    flex-direction: column;
  }
`;

export const CardLabel = styled.span`
  font-weight: 400;
  font-size: 1rem;
  font-style: normal;
  line-height: 24px;
`;

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--tertiary-dark);
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  svg {
    margin-left: 0.5rem;
    cursor: pointer;
  }
  .sm {
    max-width: 25%;
  }
  .sm-span {
    font-size: 14px !important;
  }
  .sm-label {
    font-size: 14px;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardInfo = styled.div`
  width: 25%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin: 0 1rem;
  gap: 16px;
  background-color: #fff;
  border: 1px solid var(--tertiary-dark);
  border-radius: 4px;
  @media (max-width: 880px) {
    margin: 1rem 0;
    min-width: 100%;
    min-height: 0%;
  }
`;

export const CardTitle = styled.span`
  margin-left: 5px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
