import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  margin: 0 0rem;
  border-radius: 0.5rem;
  background: white;
  .first {
    justify-content: space-between;
  }
  .complement {
    width: 100%;
    margin-right: 0;
  }
  .fe {
    width: 100%;
    margin-left: 0;
  }
  .se {
    width: 100%;
  }
  span {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Museo Sans', sans-serif;
  }
  h5 {
    display: flex;
    .icon {
      margin-right: 0.7rem;
      padding-top: 0.1rem;
    }
    font-size: 1.4rem;
    margin: 1rem 0;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  }
  > section {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    > span {
      font-weight: bold;
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      line-height: 25px;
      color: var(--secondary-light);
    }
  }
`;

export const ErrorTag = styled.h6`
  color: var(--error);
  margin-top: -1.5rem;
  margin-botton: 1rem;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    background: none;
    color: #00c49a;
    margin: 0;
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  .mb {
    margin-bottom: 1rem;
  }
  .first {
    justify-content: space-between;
  }
`;
export const InputCol = styled.div`
  margin-right: 1rem;
  .complement {
    width: 100%;
  }
`;

export const InputColStakeholder = styled.div`
  margin-left: 1rem;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  button {
    :first-of-type {
      margin-right: 1rem;
    }
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1120px;
  width: 100%;
  padding: 3rem 4rem;
  margin: 0 1rem;
  border-radius: 0.5rem;
  background: var(--white);
  h2 {
    font-size: 2rem;
    margin: 40px 0;
  }
`;

export const SuccessButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  button {
    white-space: nowrap;
    width: auto;
    padding: 1rem 2rem;
    :first-of-type {
      margin-right: 1rem;
    }
  }
`;

export const Loading = styled.div`
  width: 400px;
`;
