import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 2rem;
  margin: 0 1rem;
  border-radius: 0.5rem;
  background: white;

  h2 {
    font-size: 2rem;
    margin-right: 4rem;
  }

  > section {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;

    > span {
      font-weight: bold;
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      line-height: 25px;
      color: var(--secondary-light);
    }
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;

  h3 {
    font-family: 'Inter', sans-serif !important;
    font-size: 1.5rem;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  svg {
    cursor: pointer;
    color: var(--primary-dark-1);
    height: 1rem;
    width: 1rem;
    margin-left: 1rem;
  }

  @media (max-width: 450px) {
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    h3 {
      font-size: 1rem;
      font-weight: 600;
      white-space: normal;
      line-height: 1.5rem;
    }
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 40px;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  span {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    max-width: 40vw;
    text-align: center;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  margin-top: 25px;
  justify-content: center;
  align-items: center;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 45px;
  padding: 15px;
`;
