import { serverlessGateway } from '../../api';

export async function getUser(email: string): Promise<UserData | false> {
  try {
    const response = await serverlessGateway.post(
      'users/eletromidia/get-user',
      {
        email,
      },
    );

    const { data }: any = response;

    const userData: UserData = data.data;

    return userData;
  } catch (error) {
    return false;
  }
}
