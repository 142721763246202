import styled from 'styled-components';

export const IFrameWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;

  overflow: hidden;
  border-radius: 8px;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.12));

  iframe {
    border: 0;
    width: 100%;
  }
`;
