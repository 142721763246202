import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import * as S from './styles';

type Props = {
  modalOpen: boolean;
  children: ReactNode;
  onBackground?: () => void;
  style?: React.CSSProperties;
};

export function Modal({ modalOpen, children, onBackground, style }: Props) {
  if (!modalOpen) return null;

  return createPortal(
    <>
      <S.Wrapper style={style}>{children}</S.Wrapper>
      <S.Background onClick={() => onBackground && onBackground()} />
    </>,
    document.body,
  );
}
