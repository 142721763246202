import React, {
  ReactNode,
  useState,
  useRef,
  useEffect,
  CSSProperties,
} from 'react';
import { MdArrowDropDown } from 'react-icons/md';

import { Headline } from '../../atoms/Headline';

import * as S from './styles';

export interface AccordionProps {
  title: string;
  children: ReactNode;
  isOpen?: boolean;
}

export function Accordion({ ...props }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(props.isOpen ?? false);
  const [initialHeight, setInitialHeight] = useState(0);
  const [accordionStyle, setAccordionStyle] = useState<CSSProperties>({});

  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = itemRef.current;

    if (element) {
      if (isOpen) {
        if (initialHeight === 0) {
          setInitialHeight(element.scrollHeight + 32);
          setAccordionStyle({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: element.scrollHeight + 32,
          });
        } else {
          setAccordionStyle({ ...accordionStyle, height: initialHeight });
        }
      } else {
        setAccordionStyle({ ...accordionStyle, height: 0 });
      }
    }
  }, [isOpen, initialHeight, accordionStyle]);

  return (
    <S.Item>
      <S.ItemHead onClick={() => setIsOpen(!isOpen)}>
        <Headline variant="h6">{props.title.toUpperCase()}</Headline>
        <MdArrowDropDown />
      </S.ItemHead>
      <S.ItemBody
        ref={itemRef}
        className={isOpen ? 'active' : ''}
        style={accordionStyle}
      >
        {props.children}
      </S.ItemBody>
    </S.Item>
  );
}
