import * as Yup from 'yup';

const emptyString = '';

export const createUnitSchema = Yup.object().shape({
  name: Yup.string().required('Informe o nome da unidade'),
  royalties: Yup.number()
    .required('Informe o valor dos royalties')
    .min(20, 'Royalties deve ser no mínimo 20%')
    .max(100, 'Royalties deve ser no máximo 100%'),
  taxRegime: Yup.string().required('Informe o regime tributário'),
  v4Email: Yup.string()
    .email('Por favor, informe um e-mail válido')
    .matches(/@v4company.com/, 'O e-mail deve ser da V4 Company'),
  contractSignatureDate: Yup.date().required('Informe a data da assinatura'),
  fantasyName: Yup.string().required('Informe o nome fantasia'),
  socialName: Yup.string().required('Informe a razão social'),
  cnpj: Yup.string().required('Informe o CNPJ'),
  companyNumber: Yup.string()
    .required('Informe o número da empresa')
    .min(14, 'O número da empresa deve ter 11 dígitos'),
  address: Yup.object()
    .required('Informe o endereço')
    .shape({
      zipCode: Yup.string()
        .required('Informe o CEP')
        .min(8, 'O CEP deve ter 8 dígitos'),
      state: Yup.string().required('Informe o estado'),
      city: Yup.string().required('Informe a cidade'),
      street: Yup.string().required('Informe a rua'),
      district: Yup.string().required('Informe o bairro'),
      number: Yup.number().required('Informe o número da empresa'),
      complement: Yup.string(),
    }),
  stakeholders: Yup.array().of(
    Yup.object()
      .required('informe o sócio')
      .shape({
        name: Yup.string()
          .required('Informe o nome do sócio')
          .notOneOf([emptyString], 'O nome não pode ser vazio'),
        email: Yup.string()
          .required('Informe o e-mail do sócio')
          .email('Por favor, informe um e-mail válido')
          .notOneOf([emptyString], 'O nome não pode ser vazio'),
        rg: Yup.string().required('Informe o RG do sócio'),
        cpf: Yup.string()
          .required('Informe o CPF do sócio')
          .min(11, 'O CPF deve ter 11 dígitos')
          .notOneOf([emptyString], 'O nome não pode ser vazio'),
        isPrincipal: Yup.boolean()
          .default(false)
          .required('Informe se o sócio é o principal'),
        partnerId: Yup.number(),
        phone: Yup.string()
          .required('Informe o telefone do sócio')
          .notOneOf([emptyString], 'O nome não pode ser vazio'),
      }),
  ),
});
