import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 1.5rem 2rem;
  width: 100%;
  background-color: var(--white);
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
  h6 {
    margin: 0 auto;
  }
  svg {
    margin: 0 auto;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const ContainerHead = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Buttonexplanations = styled.div`
  border: none;
  display: flex;
  cursor: pointer;
  p {
    margin-left: 8px;
    color: #333333;
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline;
  }
  svg {
    color: #333333;
  }
`;
