import { useEffect, useRef } from 'react';
import * as lottie from 'lottie-web';

import unhappypathAnimation01 from '../../../../assets/UnhappyPaths/unhappypathanimation01.json';
import unhappypathAnimation02 from '../../../../assets/UnhappyPaths/unhappypathanimation02.json';
import unhappypathAnimation03 from '../../../../assets/UnhappyPaths/unhappypathanimation03.json';
import unhappypathAnimation04 from '../../../../assets/UnhappyPaths/unhappypathanimation04.json';
import unhappypathAnimation05 from '../../../../assets/UnhappyPaths/unhappypathanimation05.json';
import unhappypathAnimation06 from '../../../../assets/UnhappyPaths/unhappypathanimation06.json';
import unhappypathAnimation07 from '../../../../assets/UnhappyPaths/unhappypathanimation07.json';

import * as S from './styles';

interface Props {
  assetIndex?: number;
  descriptionItems?: string[];
  title?: string;
  styleContainer?: React.CSSProperties;
}

export function UnHappyPath({
  assetIndex = 0,
  descriptionItems,
  title,
  styleContainer,
}: Props) {
  const lottieAnimation = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const unhappyPathAnimations = [
      unhappypathAnimation01,
      unhappypathAnimation02,
      unhappypathAnimation03,
      unhappypathAnimation04,
      unhappypathAnimation05,
      unhappypathAnimation06,
      unhappypathAnimation07,
    ];

    if (lottieAnimation.current) {
      lottieAnimation.current.innerHTML = '';

      lottie.default.loadAnimation({
        container: lottieAnimation.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: unhappyPathAnimations[assetIndex],
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
    }
  }, [assetIndex]);

  return (
    <S.Container style={styleContainer}>
      <div className="animation" ref={lottieAnimation} />

      <h3>{title}</h3>

      {descriptionItems && (
        <ul>
          {descriptionItems.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      )}
    </S.Container>
  );
}
