import { fileApi } from '../../api';

export async function sendFile(file: File) {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await fileApi.post('/storage', formData);

    return response.data;
  } catch (error) {
    return error.data;
  }
}
