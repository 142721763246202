import styled from 'styled-components';

export const ManualContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  padding: 0 2rem;
`;

export const ManualTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
