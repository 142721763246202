export const permissionsBlackBox = [
  {
    label: 'Visualizar Black Box',
    value: 'READ_ONLY',
    desc: 'Permissão para visualizar apenas as Black Box ofertadas',
  },
  {
    label: 'Não Visualizar',
    value: 'CAN_NOT_SEE',
    desc: 'Não tem permissão para visualizar o ambiente da Black Box',
  },
  {
    label: 'Comprar Black Box',
    value: 'CAN_BID_BLACK_BOX',
    desc: 'Permissão para visualizar e realizar a compra de Black Box',
  },
  {
    label: 'Adminstrar',
    value: 'ADMIN',
    desc: 'Permissão para comprar, depositar e sacar.',
  },
];
