/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { LabDsButton } from 'v4web-components-react';
import { MdOutlineInfo, MdOutlineClose } from 'react-icons/md';
import * as S from './styles';
import { useToast } from '../../../../hooks/toast';
import { CustomerDashboards } from '../../molecules/CustomerDashboards';
import { Dashboard } from '../../../../types/dashboard';
import { LastUpdates } from '../../../../types/lastUpdates';

import { deleteDashboard } from '../../../../services/requests/Dashboards/deleteDashboard';
import { createDashboard } from '../../../../services/requests/Dashboards/createDashboard';
import { CustomerLastUpdates } from '../CustomerLastUpdates';
import { createLastUpdate } from '../../../../services/requests/LastUpdates/createLastUpdate';
import { deleteLastUpdate } from '../../../../services/requests/LastUpdates/deleteLastUpdate';

interface DashboardsSchema {
  newDashboards: Dashboard[];
  removedDashboards: string[];
  loadedDashboards: Dashboard[];
}

interface LastUpdatesSchema {
  newLastUpdates: LastUpdates[];
  removedLastUpdates: string[];
  loadedLastUpdates: LastUpdates[];
}

export function EditCustomerVision() {
  const { id } = useParams<{ id: string }>();

  const customerId = id;

  const [dashboards, setDashboards] = useState<DashboardsSchema>({
    newDashboards: [],
    removedDashboards: [],
    loadedDashboards: [],
  });

  const [lastUpdates, setLastUpdates] = useState<LastUpdatesSchema>({
    newLastUpdates: [],
    removedLastUpdates: [],
    loadedLastUpdates: [],
  });

  const updates =
    dashboards.newDashboards.length > 0 ||
    dashboards.removedDashboards.length > 0 ||
    lastUpdates.newLastUpdates.length > 0 ||
    (lastUpdates.removedLastUpdates.length > 0 && true);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isPageInfoOpen, setIsPageInfoOpen] = useState<boolean>(true);
  const { addToast } = useToast();

  async function save() {
    for (const dash of dashboards.newDashboards) {
      const data = {
        name: dash.name,
        description: dash.description,
        link: dash.link,
        customerId: customerId as string,
        type: 'CUSTOMER',
      };
      await createDashboard(data);
    }

    for (const lastUpdate of lastUpdates.newLastUpdates) {
      const formData = new FormData();

      formData.append('name', lastUpdate.name);
      formData.append('description', lastUpdate.description);
      formData.append('videoUrl', lastUpdate.videoUrl);
      formData.append('customerId', lastUpdate.customerId);

      lastUpdate.files?.forEach((file) => {
        formData.append('files', file);
      });

      await createLastUpdate(formData);
    }
  }

  async function handleDelete() {
    for (const dash of dashboards.removedDashboards) {
      await deleteDashboard(dash);
    }

    for (const lastUpdate of lastUpdates.removedLastUpdates) {
      await deleteLastUpdate(lastUpdate);
    }
  }

  async function handlePublish() {
    setIsLoading(true);
    await save();
    await handleDelete();

    setDashboards({
      newDashboards: [],
      removedDashboards: [],
      loadedDashboards: [
        ...dashboards.loadedDashboards,
        ...dashboards.newDashboards,
      ],
    });

    setLastUpdates({
      newLastUpdates: [],
      removedLastUpdates: [],
      loadedLastUpdates: [
        ...lastUpdates.loadedLastUpdates,
        ...lastUpdates.newLastUpdates,
      ],
    });

    addToast({
      type: 'success',
      title: 'Sucesso',
      description: 'Mudanças salvas com sucesso',
    });

    setIsLoading(false);
  }

  return (
    <>
      <S.Container
        style={{
          paddingLeft: '2rem',
          paddingRight: '2rem',
          paddingTop: '2rem',
          borderRadius: '8px',
        }}
      >
        <S.HeaderContainer>
          <h1>Gestão Painel do Cliente</h1>
          <S.HeaderOptions>
            <LabDsButton
              label="Publicar"
              stepIcon="save"
              loading={isLoading}
              onHandleButton={() => handlePublish()}
            />
          </S.HeaderOptions>
        </S.HeaderContainer>
        {updates && (
          <S.UpdatesWarning>Ainda há alterações não salvas</S.UpdatesWarning>
        )}
        {isPageInfoOpen && (
          <S.PageInfo>
            <S.PageInfoHeader>
              <p>
                <MdOutlineInfo />O que estou vendo?
              </p>
              <MdOutlineClose onClick={() => setIsPageInfoOpen(false)} />
            </S.PageInfoHeader>
            <p>
              Neste ambiente você estará editando a visualização do acesso do
              cliente, ou seja, tudo que você publicar neste local será
              refletido no que seu cliente irá ver e em breve mais opções.
            </p>
          </S.PageInfo>
        )}

        <CustomerDashboards
          dashboards={dashboards}
          setDashboards={setDashboards}
          updates={updates}
        />

        <CustomerLastUpdates
          lastUpdates={lastUpdates}
          setLastUpdates={setLastUpdates}
          updates={updates}
        />
      </S.Container>
    </>
  );
}
