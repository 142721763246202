import { useInfiniteQuery } from '@tanstack/react-query';

import { PagedResponse } from '../../../components/AtomicDesign/atoms/SelectQuery';

function useInfiniteQueryOptions<T>(
  id: string,
  debouncedSearch: string,
  fetchOptions: (query: string, page: number) => Promise<PagedResponse<T>>,
) {
  return useInfiniteQuery(
    ['select-options', debouncedSearch ?? ''],
    async ({ pageParam = 1 }) => {
      const fetchedOptions = await fetchOptions(debouncedSearch, pageParam);

      return fetchedOptions;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.page - 1 ?? undefined,
      getNextPageParam: (lastPage) =>
        lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
}

export { useInfiniteQueryOptions };
