import styled from 'styled-components';

export const Table = styled.div`
  width: 100%;
  overflow: auto;
  border-collapse: collapse;
  border-radius: 0.35rem;
  max-height: 34rem;

  thead {
    overflow: auto;

    background: var(--grayscale-2);
  }

  thead tr {
    border-radius: 0.25rem;
    background: var(--grayscale-2);
  }

  thead th {
    padding-left: 1rem;
    text-align: left;
    height: 1rem;
  }

  @media (min-width: 1280px) {
    display: table;
  }
`;

export const Body = styled.tbody`
  tr {
    border-radius: 0.35rem;
    border: 0.07rem solid transparent;
    transition: border-color 0.3s, background-color 0.3s;

    td {
      min-width: 6.3rem;
      padding: 1rem;
      max-width: 15rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      :nth-child(1) {
        padding-left: 1.2rem;
        padding-right: 0;
      }

      :nth-child(8) {
        padding: 0 1rem;
      }
    }

    &:hover {
      background: var(--grayscale-2);
      cursor: pointer;
    }
  }
`;

export const ContainerModal = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 30;
  display: flex;
`;

export const AllContainer = styled.div`
  max-width: 44rem;
  height: 40rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background: var(--white);
  border-radius: 0.5rem;
  box-shadow: 0 0 0.63rem rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow-y: auto;

  main {
    padding: 1rem;
    padding-inline: 0rem;
  }

  @media (max-width: 450px) {
    height: 100vh;
    max-width: 100vh;
    border-radius: 0;
  }

  h3 {
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Inter';
    margin-bottom: 1rem;
  }

  strong {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Nunito Sans';
  }
`;

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0.3rem 0.88rem rgba(0, 0, 0, 0.11);
  padding: 2rem;
`;

export const ModalContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2rem 2rem 0rem;

  div {
    margin-bottom: 2.2rem;
  }
`;

export const ReasonCredits = styled.div`
  padding: 0 2rem;
  margin-bottom: 1.5rem;
`;
