import React, { useState } from 'react';

import { BiDotsVerticalRounded } from 'react-icons/bi';
import * as S from './styles';

interface DotMenuOptions {
  name: string;
  action: () => void;
}

interface IDotMenu {
  options: DotMenuOptions[];
  reversed?: boolean;
}

export function DotMenu({ options, reversed }: IDotMenu) {
  const [isOpen, setIsOpen] = useState(false);
  window.onclick = (e) => {
    const { target } = e;

    if (target) {
      if (!(target as HTMLButtonElement).matches('.dot-menu')) {
        setIsOpen(false);
      }
    }
  };
  return (
    <S.DotMenu>
      <div
        className="dot-menu"
        aria-hidden="true"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <BiDotsVerticalRounded />
      </div>

      <S.DotOptions isHidden={isOpen} reversed={reversed}>
        {options.map((option, index) => (
          <button
            key={index as number}
            style={{
              borderBottomLeftRadius: '0.5rem',
              borderBottomRightRadius: '0.5rem',
            }}
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              option.action();
            }}
          >
            {option.name}
          </button>
        ))}
      </S.DotOptions>
    </S.DotMenu>
  );
}
