import React, { SetStateAction } from 'react';
import { Controller } from 'react-hook-form';
import { InputCurrency } from '../../LegacyV4Components';

export const InputCurrencyForm = ({
  name,
  control,
  label,
  placeholder,
  required = false,
  setValue,
  value,
  error,
  width,
}: {
  name: string;
  control: any;
  label: string;
  placeholder?: string;
  required?: boolean;
  value: string | number;
  setValue: React.Dispatch<SetStateAction<string | number>>;
  error?: string;
  width?: string;
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref } }) => {
        return (
          <InputCurrency
            setCurrency={setValue}
            label={label}
            placeholder={placeholder}
            name={name}
            ref={ref}
            value={value}
            required={required}
            error={error}
            width={width}
          />
        );
      }}
    />
  );
};
