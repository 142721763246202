import { Form } from '@unform/web';
import styled from 'styled-components';

export const ContainerForm = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  margin: 0 0rem;
  border-radius: 0.5rem;
  height: 70vh;
  background: var(--white);
  span {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem
    line-height: 1.5rem;
    margin-bottom: 1rem;
    color: #333333;
  }
  
  h6 {
    font-size: 1rem;
    font-family: 'Inter';
    font-weight: bold;
    line-height: 1.5rem;
    margin-bottom: 0;
  }

  h5 {
    display: flex;
    .icon {
      margin-right: 0.7rem;
      padding-top: 0.1rem;
    }
    font-size: 1.4rem;
    margin: 1rem 0;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  }
  > section {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    > span {
      font-weight: bold;
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      line-height: 1.5rem;
      color: var(--secondary-light);
    }
  }
  .ContainerStepOne {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    background: #fff;

    border-radius: 0.75rem;
    
    padding: 2rem 1.5rem;
    margin-top: 1rem;

    p {
      color: #333;
      font-family: Nunito Sans;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }

    .showWhereAreCodeBar {
      opacity: 1;
      position: absolute;
      top: 10rem;
      height: 70%;
      width: 70%;
      background-image: url(https://www.zignet.com.br/_next/static/media/boleto.3722c9856880065d8ce1234ea5202faf.png);
     background-size: cover;
      border-radius: 0.75rem;
      -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.14), 0 2px 1px rgba(0,0,0,.12), 0 1px 3px rgba(0,0,0,.2);
      box-shadow: 0 1px 1px rgba(0,0,0,.14), 0 2px 1px rgba(0,0,0,.12), 0 1px 3px rgba(0,0,0,.2);
      
    }

    .ContainerField {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 2.5rem;
      padding-top: 0px;
      gap: 1rem;
      
      div {
        width: 44rem;
        width: 100%;
        height: 2.5rem;

      }

      button {
        height: auto;
        min-width: 10.25rem;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

export const BoxFlex = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  align-items: center;
`;

export const Information = styled.div`
  margin-bottom: 2rem;
`;
export const Confirmation = styled.div`
  span {
    font-family: 'Nunito Sans';
    font-style: regular;
    font-weight: 400;
    font-size: 1.38rem;
    line-height: 1.5rem;
    color: var(--secondary-main);
  }

  h5 {
    font-family: 'Nunito Sans';
    font-style: bold;
    font-weight: 700;
    font-size: 1.38rem;
    line-height: 2rem;
  }
`;

export const Counter = styled.span`
  text-align: right;
  font-size: 0.75rem;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
`;

export const Modal = styled.div`
  main {
    padding-inline: 0rem;
  }
`;

export const ProgressContent = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-top: -1rem;

  width: 100%;
  height: 0.5rem;

  background-color: var(--grayscale-10);
`;

export const ProgressBar = styled.div<{ porc: string }>`
  width: ${({ porc }) => porc};
  height: 0.5rem;
  padding-inline: -1rem;

  background-color: #00c49a;
`;

export const ErrorTag = styled.h6`
  color: var(--error);
  margin-top: -1.5rem;
  margin-botton: 1rem;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    background: none;
    color: #00c49a;
    margin: 0;
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  .mb {
    margin-bottom: 1rem;
  }
  .first {
    justify-content: space-between;
  }
`;
export const InputCol = styled.div`
  margin-right: 1rem;
  .complement {
    width: 100%;
  }
`;

export const InputColStakeholder = styled.div`
  margin-left: 1rem;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  button {
    :first-of-type {
      margin-right: 1rem;
    }
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 70rem;
  width: 100%;
  padding: 3rem 4rem;
  margin: 0 1rem;
  border-radius: 0.5rem;
  background: var(--white);
  h2 {
    font-size: 2rem;
    margin: 2.5rem 0;
  }
`;

export const SuccessButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  button {
    white-space: nowrap;
    width: auto;
    padding: 1rem 2rem;
    :first-of-type {
      margin-right: 1rem;
    }
  }
`;

export const Loading = styled.div`
  width: 25rem;
`;

export const Container = styled.div<{ show: boolean }>`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 30;

  display: ${({ show }) => (show ? 'flex' : 'none')};
`;

export const ModalBase = styled.div<{ size: 'sm' | 'md' | 'lg' }>`
  max-width: 70vw;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background: var(--white);
  border-radius: 0.3rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;

  main {
    padding: 1rem;
    padding-inline: 0rem;
  }

  @media (max-width: 28rem) {
    height: 100vh;
    max-width: 100vh;
    border-radius: 0;
  }
`;

export const ModalContent = styled.div`
  position: relative;
  overflow: auto;
  max-height: 60vh;

  @media (max-width: 28rem) {
    min-height: 100vh;
  }
`;

export const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  padding: 2rem;

  h3 {
    font-family: 'Inter', sans-serif !important;
    font-size: 1.5rem;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  svg {
    cursor: pointer;
    color: #333333;
    height: 1rem;
    width: 1rem;
    margin-left: 0rem;
  }

  @media (max-width: 28rem) {
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    h3 {
      font-size: 1rem;
      font-weight: 600;
      white-space: normal;
      line-height: 1.5rem;
    }
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const ModalFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 2rem;
  box-shadow: 0px -4px 0.5rem 3px rgba(0, 0, 0, 0.06),
    0px -1px 3px rgba(0, 0, 0, 0.12);
`;
