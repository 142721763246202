import styled, { css } from 'styled-components';

export const Container = styled.div<{
  info?: boolean;
  leftContainer?: boolean;
  leftBefore?: boolean;
  bonus?: boolean;
  extract?: boolean;
}>`
  position: relative;

  span {
    width: 10rem;
    background: var(--primary-main);
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;
    position: absolute;
    bottom: ${({ extract }) =>
      extract ? 'calc(100% - 0.2rem)' : 'calc(100% + 1.1rem)'};
    left: ${({ leftContainer }) => (leftContainer ? '0%' : '50%')};
    transform: translateX(-50%);

    color: var(--white);

    &::before {
      content: '';
      border-style: solid;
      border-color: var(--primary-main) transparent;
      border-width: ${({ bonus }) =>
        bonus ? '0.6rem 0.6rem 0 0.6rem' : '0.5rem 0.5rem 0 0.5rem'};
      top: 100%;
      position: absolute;
      left: ${({ leftBefore }) => (leftBefore ? '56%' : '50%')};
      transform: translateX(-50%);
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }

  ${({ info }) =>
    info &&
    css`
      span {
        width: 24rem;
        background: var(--white);
        border: 2px solid var(--tertiary-dark);
        color: var(--black);
        font-family: 'Nunito Sans', sans-serif;
        text-align: inherit;

        &::before {
          border-color: var(--tertiary-dark) transparent;
        }
      }
    `}

  ${({ extract }) =>
    extract &&
    css`
      span {
        width: max-content;
        left: 42%;
      }
    `}

  ${({ bonus }) =>
    bonus &&
    css`
      span {
        width: ${bonus ? '20rem' : '24rem'};
      }
    `}
`;
