import styled from 'styled-components';

export const ColorPicker = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;

  max-width: 5rem;

  background-color: var(--tertiary-main);

  border: 1px solid var(--tertiary-dark);
  cursor: pointer;
  svg {
    color: var(--tertiary-dark);
    margin-left: 1rem;
  }
`;

export const Color = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  background-image: ${({ color }) =>
    color === 'rainbow' &&
    'linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);'};

  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
`;

export const ColorsContainer = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  background-color: var(--tertiary-main);
  z-index: 2;

  position: absolute;
  right: 0.075rem;
  top: 2.5rem;

  max-width: 10.5rem;
  border: 1px solid var(--tertiary-dark);

  div {
    margin-right: 0.5rem;
  }

  div:last-child {
    margin-right: 0;
  }
`;

export const SketchContainer = styled.div`
  position: absolute;
  right: 0.075rem;
  top: 6rem;
`;
