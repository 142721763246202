import { TeamStructureInterface } from '../../../pages/NewPriceCalculatorEdit/interface';
import { kubernetesApi as api } from '../../api';

export interface investmentInfosDetails {
  operationalInvestment: number;
  taxPercentage: number;
  taxValue: number;
  directExpenses: number;
  directOperationSalary: number;
  royaltiesPercentage: number;
  margin: number;
  marginPercentage: number;
}

export interface investmentInfos {
  basisCalculation: {
    mandateName?: string;
    seniorityName?: string;
    wageAverage: number;
    markup: number;
    royalties: number;
    taxes: number;
    profitMargin?: number;
    percentageProfitMargin?: number;
    costPerSlot: number;
  }[];
  detailedInvestment: investmentInfosDetails;
  detailedInvestmentPerMonth: investmentInfosDetails;
}

export async function calculate(
  unitId: string,
  discount: number,
  liquidDiscount: number,
  teamStructure: TeamStructureInterface[],
  contractDuration: string,
): Promise<investmentInfos | string> {
  try {
    const response: { data: investmentInfos } = await api.post(
      `/calculator/new-price/detailed-investment`,
      {
        unitId,
        months: contractDuration || '24',
        liquidDiscount,
        discount,
        teamStructure,
      },
    );
    const user = response.data;

    return user;
  } catch (err) {
    console.error(`Ocorreu algum problema ao atualizar este usuário 😕`, err);
    return err.response.data.message;
  }
}
