import { createCustomerFNC } from '../../../types/createCustomerFNC';
import { financeApi } from '../../api';

export async function createCustomer(
  data: createCustomerFNC,
): Promise<boolean> {
  try {
    await financeApi.post(`/customers/create`, data);

    return true;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o dashboard, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
