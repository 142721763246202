import { useQuery } from '@tanstack/react-query';
import { customerNewApi as api } from '../../api';

async function findProjectById(projectId: string): Promise<IProject | null> {
  try {
    const response = await api.get<IProject>(`/find-project/${projectId}`);
    return response.data;
  } catch (err) {
    return null;
  }
}

function useFindProjectById(projectId: string) {
  return useQuery(['find-projects', projectId], async () => {
    const response = findProjectById(projectId);
    return response;
  });
}

export { useFindProjectById, findProjectById };
