import { useEffect } from 'react';

interface UseOutsideAlerterProps {
  ref: any;
  action: () => void;
}
export function useOutsideAlerter({ ref, action }: UseOutsideAlerterProps) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, action]);
}
