import React, { ReactNode } from 'react';

import * as S from './styles';

interface HeadlineProps {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  children?: ReactNode;
}

export function Headline({
  variant = 'h1',
  className = '',
  children,
}: HeadlineProps) {
  const elementClass = `${variant} ${className}`;

  return (
    <S.Headline as={variant} className={elementClass}>
      {children}
    </S.Headline>
  );
}
