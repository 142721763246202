import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;

  main {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    grid-gap: 2rem;

    > section {
      margin: 1rem 0;
      background-color: var(--white);
      border-radius: 0.5rem;
      padding: 1rem;

      > section {
        margin: 1rem 0rem;
      }
    }
  }
`;
