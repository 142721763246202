import { useEffect, useState } from 'react';

import { SingleSelect } from '../../../../../LegacyV4Components';

import { permissionsLead } from '../../../../../../utils/PermissionsOptions/leadPermission';
import { permissionsPaymentGateway } from '../../../../../../utils/PermissionsOptions/paymentGateWay';
import { permissionsCustomer } from '../../../../../../utils/PermissionsOptions/customerPermission';
import { permissionsDash } from '../../../../../../utils/PermissionsOptions/dashPermission';
import { permissionsHead } from '../../../../../../utils/PermissionsOptions/headPermission';
import { useAuth } from '../../../../../../hooks/auth';
import * as S from './styles';
import {
  CreateUserInterface,
  InputItens,
  SetPermissionsFunction,
} from '../../interfaces';
import { permissionsUser } from '../../../../../../utils/PermissionsOptions/userPermissions';
import { unitPermissionOptions } from '../../../../../../utils/PermissionsOptions/unitPermission';
import { permissionsBlackBox } from '../../../../../../utils/PermissionsOptions/blackBoxPermission';
import { permissionsDealBroker } from '../../../../../../utils/PermissionsOptions/dealBrokerPermissions';

interface PermissionsProps {
  error: any;
  setPermissions: SetPermissionsFunction;
  isHeadquarter: boolean;
}

export const Permissions = ({
  error,
  setPermissions,
  isHeadquarter = false,
}: PermissionsProps) => {
  const { user } = useAuth();
  const { permissions } = user;

  const [unitRole, setUnitRole] = useState<InputItens[]>([]);
  const [headquarterRole, setHeadquarterRole] = useState<InputItens[]>([]);
  const [dashboardRole, setDashboardRole] = useState<InputItens[]>([]);
  const [customer, setCustomerRole] = useState<InputItens[]>([]);
  const [userRole, setUserRole] = useState<InputItens[]>([]);
  const [paymentGatewayRole, setPaymentGatewayRole] = useState<InputItens[]>(
    [],
  );

  const [broker, setBroker] = useState<InputItens[]>([permissionsLead[0]]);
  const [blackBox, setBlackBox] = useState<InputItens[]>([
    permissionsBlackBox[0],
  ]);
  const [dealBroker, setDealBroker] = useState<InputItens[]>([
    permissionsDealBroker[0],
  ]);

  const createHandleSetItensSelected = (
    setRole: React.Dispatch<React.SetStateAction<InputItens[]>>,
    permissionKey: keyof CreateUserInterface,
  ) => {
    return (val: React.SetStateAction<InputItens[]>) => {
      setRole(val);

      if (Array.isArray(val)) {
        setPermissions(
          permissionKey,
          (val[0].value as string) || 'CAN_NOT_SEE',
        );
      }
    };
  };

  useEffect(() => {
    setDashboardRole([permissionsDash[0]]);
    setCustomerRole([permissionsCustomer[0]]);
    setPaymentGatewayRole([permissionsPaymentGateway[0]]);
    setUnitRole([unitPermissionOptions[0]]);
    setHeadquarterRole([permissionsHead[0]]);
    setBlackBox([permissionsBlackBox[0]]);
    setDealBroker([permissionsDealBroker[0]]);
    setUserRole([permissionsUser[0]]);
  }, []);

  return (
    <>
      <S.Row style={{ marginBottom: 24, marginTop: 20 }}>
        <h2>Permissões</h2>
      </S.Row>
      {isHeadquarter && (
        <S.ContentRow>
          <SingleSelect
            name="headquarterRole"
            label="Headquarter"
            itensSelected={headquarterRole}
            placeholder="Escolha um item"
            options={permissionsHead}
            setItensSelected={createHandleSetItensSelected(
              setHeadquarterRole,
              'headquarterRole',
            )}
            disabled={!permissions?.headquarter?.admin || !isHeadquarter}
            error={error.headquarterRole?.message}
          />
          <S.Info>
            {permissions?.headquarter?.admin ? (
              <span>{headquarterRole[0]?.desc}</span>
            ) : (
              <span className="disabled">
                Você deve ser admin para alterar essa permissão
              </span>
            )}
            {headquarterRole.length === 0 && (
              <S.Error
                className={!permissions?.headquarter?.admin ? 'disabled' : ''}
              >
                Selecione uma das opções
              </S.Error>
            )}
          </S.Info>
        </S.ContentRow>
      )}
      <hr />

      <S.ContentRow>
        <SingleSelect
          name="unitRole"
          label="Unidade"
          itensSelected={unitRole}
          placeholder="Escolha um item"
          options={unitPermissionOptions}
          setItensSelected={createHandleSetItensSelected(
            setUnitRole,
            'unitRole',
          )}
          disabled={!isHeadquarter}
          error={error.unitRole?.message}
        />
        <S.Info>
          {permissions?.unit?.admin ? (
            <span>{unitRole[0]?.desc}</span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
          {unitRole.length === 0 && (
            <S.Error className={!permissions?.unit?.admin ? 'disabled' : ''}>
              Selecione uma das opções
            </S.Error>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />
      <S.ContentRow>
        <SingleSelect
          name="dashboardRole"
          label="Central de Dashboards"
          itensSelected={dashboardRole}
          placeholder="Selecione uma permissão"
          options={permissionsDash}
          setItensSelected={createHandleSetItensSelected(
            setDashboardRole,
            'dashboardRole',
          )}
          disabled={!permissions?.dashboard?.admin}
          error={error.dashboardRole?.message}
        />
        <S.Info>
          {permissions?.dashboard?.admin ? (
            <span className={!permissions?.dashboard?.admin ? 'disabled' : ''}>
              {dashboardRole[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
          {dashboardRole.length === 0 && (
            <S.Error>Selecione uma das opções</S.Error>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />
      <S.ContentRow>
        <SingleSelect
          name="userRole"
          label="Usuários"
          itensSelected={userRole}
          placeholder="Selecione uma permissão"
          options={permissionsUser}
          setItensSelected={createHandleSetItensSelected(
            setUserRole,
            'userRole',
          )}
          disabled={!permissions?.users?.admin}
          error={error.userRole?.message}
        />
        <S.Info>
          {permissions?.users?.admin ? (
            <span className={!permissions?.users?.admin ? 'disabled' : ''}>
              {userRole[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
          {userRole.length === 0 && <S.Error>Selecione uma das opções</S.Error>}
        </S.Info>
      </S.ContentRow>
      <hr />
      <S.ContentRow>
        <SingleSelect
          name="customerRole"
          itensSelected={customer}
          setItensSelected={createHandleSetItensSelected(
            setCustomerRole,
            'customerRole',
          )}
          placeholder="Selecione uma permissão"
          label={isHeadquarter ? 'Clientes' : 'Meus Clientes'}
          options={permissionsCustomer}
          disabled={!permissions?.customer?.admin}
          error={error.customerRole?.message}
        />
        <S.Info>
          {permissions?.customer?.admin ? (
            <span className={!permissions?.customer?.admin ? 'disabled' : ''}>
              {customer[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />

      <S.ContentRow>
        <SingleSelect
          name="paymentGatewayRole"
          itensSelected={paymentGatewayRole}
          setItensSelected={createHandleSetItensSelected(
            setPaymentGatewayRole,
            'paymentGatewayRole',
          )}
          placeholder="Selecione uma permissão"
          label="Gateway de pagamento"
          options={permissionsPaymentGateway}
          disabled={!permissions?.paymentGateway?.admin}
          error={error.paymentGatewayRole?.message}
        />
        <S.Info>
          {permissions?.paymentGateway?.admin ? (
            <span
              className={!permissions?.paymentGateway?.admin ? 'disabled' : ''}
            >
              {customer[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />

      <S.ContentRow>
        <SingleSelect
          name="leadbrokerRole"
          itensSelected={broker}
          setItensSelected={createHandleSetItensSelected(
            setBroker,
            'leadbrokerRole',
          )}
          placeholder="Selecione uma permissão"
          label="Lead Broker"
          options={permissionsLead}
          disabled={!permissions?.leadbroker?.admin}
          error={error.leadbrokerRole?.message}
        />
        <S.Info>
          {permissions?.leadbroker?.admin ? (
            <span className={!permissions?.leadbroker?.admin ? 'disabled' : ''}>
              {broker[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
          {broker.length === 0 && (
            <S.Error
              className={!permissions?.leadbroker?.admin ? 'disabled' : ''}
            >
              Selecione uma das opções
            </S.Error>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />
      <S.ContentRow>
        <SingleSelect
          name="blackBoxRole"
          itensSelected={blackBox}
          setItensSelected={createHandleSetItensSelected(
            setBlackBox,
            'blackBoxRole',
          )}
          placeholder="Selecione uma permissão"
          label="Black Box"
          options={permissionsBlackBox}
          disabled={!permissions?.blackBox?.admin}
          error={error.blackBoxRole?.message}
        />
        <S.Info>
          {permissions?.blackBox?.admin ? (
            <span className={!permissions?.blackBox?.admin ? 'disabled' : ''}>
              {blackBox[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
          {blackBox.length === 0 && (
            <S.Error
              className={!permissions?.blackBox?.admin ? 'disabled' : ''}
            >
              Selecione uma das opções
            </S.Error>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />
      <S.ContentRow>
        <SingleSelect
          name="dealBrokerRole"
          itensSelected={dealBroker}
          setItensSelected={createHandleSetItensSelected(
            setDealBroker,
            'dealBrokerRole',
          )}
          placeholder="Selecione uma permissão"
          label="Deal Broker"
          options={permissionsDealBroker}
          disabled={!permissions?.dealBroker?.admin}
          error={error.dealBrokerRole?.message}
        />
        <S.Info>
          {permissions?.dealBroker?.admin ? (
            <span className={!permissions?.dealBroker?.admin ? 'disabled' : ''}>
              {dealBroker[0]?.desc}
            </span>
          ) : (
            <span className="disabled">
              Você deve ser admin para alterar essa permissão
            </span>
          )}
          {dealBroker.length === 0 && (
            <S.Error
              className={!permissions?.dealBroker?.admin ? 'disabled' : ''}
            >
              Selecione uma das opções
            </S.Error>
          )}
        </S.Info>
      </S.ContentRow>
      <hr />
    </>
  );
};
