import { useState, useEffect, useMemo, useCallback } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
  LabDsButton,
  LabDsSearchBar,
  LabDsTab,
  LabDsBreadcrumb,
  LabDsTable,
} from 'v4web-components-react';
import { IData } from 'v4web-components';
import {
  Container,
  SectionContent,
  SectionTitle,
  Section,
} from '../../components/LegacyV4Components';
import useDebounce from '../../hooks/useDebounce';
import * as S from './styles';
import { getUsersByUnitId } from '../../services/requests/Unit/getUsersByUnitId';
import { useAuth } from '../../hooks/auth';
import { User } from '../../hooks/types';

import { convertNumberToBRL } from '../../utils/convertNumberToBRL';
import { ActionUser } from '../../components/AtomicDesign/molecules/actionUser';
import { ModalCreateUser } from '../../components/AtomicDesign/molecules/ModalCreateUser';
import ModalPermissionsEdit from '../../components/AtomicDesign/molecules/ModalPermissionsEdit';
import {
  headerTable,
  headerTableHistoric,
  headerTableNotAdmin,
  headerTableWage,
} from './constants/table';
import { ModalPropsUser, Tabs } from './interfaces';
import { Pagination } from '../../components/AtomicDesign/atoms/Pagination';

export function Headquarter() {
  const INIT_PAGE = 0;
  const LIMIT_PAGE = 10;
  const [currentPage, setCurrentPage] = useState<number>(INIT_PAGE);
  const [tab, setTab] = useState(Tabs.active.toString());
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce<string>(query, 500);
  const [OpenModalCreateEmail, setOpenModalCreateEmail] = useState(false);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [modalProps, setModalProps] = useState<ModalPropsUser>();
  const [showUserInvate, setShowUserInvate] = useState<boolean>(false);
  const [userUpd, getUserUpd] = useState<User>();
  const [userSelect, setUserSelect] = useState<User | undefined>(undefined);
  const { user } = useAuth();
  const { permissions } = user;
  let headers;

  const ADMIN_HEADQUARTER = permissions?.headquarter?.admin;
  const HR_HEADQUARTER = permissions?.headquarter?.hr;

  const { data, refetch, isLoading } = useInfiniteQuery(
    ['users', query, currentPage],
    async () => {
      const usersPromise = await getUsersByUnitId({
        unitId: user.unitId,
        showDeleted: tab === 'Histórico de contas',
        isActive: tab === 'Ativas',
        search: query,
        limit: LIMIT_PAGE,
        page: currentPage + 1,
        withPagination: true,
      });

      return usersPromise;
    },
  );

  const handlerDeleteUser = useCallback(
    async (id: string, name: string, email: string, unitId: string) => {
      setModalProps({
        disableUnitProps: {
          name,
          id,
          email,
          unitId,
        },
        user: userUpd as User,
        getUser: getUserUpd,
      });
      setOpenModalAction(true);
    },
    [userUpd],
  );

  const handleManagementInvestor = useCallback(
    async (
      unitId: string,
      email: string,
      _id: string,
      name: string,
    ): Promise<void> => {
      handlerDeleteUser(_id as string, name, email, unitId);
    },
    [handlerDeleteUser],
  );

  useEffect(() => {
    refetch({ refetchPage: (_page, index) => index === 0 });
    setCurrentPage(INIT_PAGE);
  }, [debouncedQuery, refetch, tab, userUpd]);

  const tableContent = useMemo(() => {
    const handlerGoProfile = (id: string, name: string) => {
      const routes = [
        {
          title: name,
          link: `/`,
        },
      ];

      return navigate(
        `/perfil/${id}?origin=${encodeURI(JSON.stringify(routes))}`,
      );
    };
    const tableDataSimple: IData = {
      headers: [],
      rows: [],
    };

    function formatDate(date: Date): string {
      if (!date) return '';
      return format(date, 'dd/MM/yyyy');
    }

    const handleRowClick = (row: User) => {
      setUserSelect({
        _id: row._id,
        name: row.name,
        isActive: row.currentUser?.isActive,
        picture: row.picture,
        unitId: row.unitId,
        email: row.currentUser?.email,
        mandate: row.mandate,
        seniority: row.seniority,
        wage: row.currentUser?.wage,
        phone: row.currentUser?.phone,
        startedAt: row.currentUser?.createdAt,
      } as User);
      setShowUserInvate(true);
    };

    data?.pages?.forEach((page) => {
      if (page) {
        const { users } = page;
        users?.forEach(
          ({
            _id,
            name,
            picture,
            unitId,
            mandate,
            seniority,
            ...currentUser
          }) => {
            const userTab = {
              _id,
              name,
              picture,
              unitId,
              currentUser,
            };
            tableDataSimple.rows.push({
              name: {
                text: {
                  title: name,
                  avatarImgSRC: picture,
                  avatar: true,
                },
                handleRow: () => handleRowClick(userTab as User),
                key: _id,
              },
              email: {
                text: { title: currentUser?.email },
                handleRow: () => handleRowClick(userTab as User),
                key: _id,
              },
              mandate: {
                text: { title: mandate?.name },
                handleRow: () => handleRowClick(userTab as User),
                key: _id,
              },
              seniority: {
                text: { title: seniority?.name },
                handleRow: () => handleRowClick(userTab as User),
                key: _id,
              },
              startedAt: {
                text: {
                  title:
                    currentUser?.createdAt &&
                    formatDate(new Date(currentUser?.createdAt)),
                },
              },
              deletedAt: {
                text: {
                  title:
                    currentUser?.deletedAt &&
                    formatDate(new Date(currentUser?.deletedAt)),
                },
              },
              wage: {
                text: {
                  title: convertNumberToBRL(Number(currentUser?.wage || '0')),
                },
                handleRow: () => handleRowClick(userTab as User),
                key: _id,
              },
              action: {
                action: {
                  items: [
                    {
                      icon: 'person',
                      label: 'Ver Perfil',
                      key: 'profile',
                      event: () => {
                        handlerGoProfile(_id, name);
                      },
                    },
                  ],
                  destructiveItem: {
                    icon: 'delete',
                    label: 'Excluir',
                    key: 'delete',
                    event: () => {
                      handleManagementInvestor(
                        unitId,
                        currentUser.email,
                        _id,
                        name,
                      );
                    },
                  },
                },
              },
            });
          },
        );
      }
    });

    return { tableDataSimple };
  }, [data?.pages, handleManagementInvestor, navigate]);

  if (tab === 'Histórico de contas') {
    headers = headerTableHistoric;
  } else if (ADMIN_HEADQUARTER && HR_HEADQUARTER) {
    headers = headerTableWage;
  } else if (ADMIN_HEADQUARTER) {
    headers = headerTable;
  } else {
    headers = headerTableNotAdmin;
  }

  const dataTableSingle: IData = {
    headers,
    rows: tableContent.tableDataSimple.rows,
  };

  return (
    <>
      <Container>
        <LabDsBreadcrumb
          breadcrumbs={[
            {
              label: 'Headquarter',
              link: '/headquarter',
              key: '1',
              event: () => {
                navigate('/headquarter');
              },
            },
          ]}
        />
        <Section>
          <SectionContent>
            <S.Row>
              <SectionTitle text="Headquarter" />
            </S.Row>
            <S.Row>
              <LabDsTab
                value="active"
                tabs={[
                  {
                    title: 'Ativas',
                    key: 'active',
                    event: () => setTab(Tabs.active),
                  },
                  {
                    title: 'Histórico de contas',
                    key: 'inactive',
                    event: () => setTab(Tabs.inactive),
                  },
                ]}
              />
            </S.Row>

            <S.Filters>
              <S.InputArea>
                <LabDsSearchBar
                  label="Busque por investidor, mandato..."
                  value={query}
                  onChangeInput={({ detail }) => setQuery(detail)}
                />
              </S.InputArea>
              <S.Actions>
                <LabDsButton
                  variant="primary"
                  color="green"
                  size="medium"
                  leadingIcon="add"
                  label="Novo investidor"
                  onHandleButton={() => {
                    setOpenModalCreateEmail(true);
                  }}
                  disabled={!ADMIN_HEADQUARTER}
                />
              </S.Actions>
            </S.Filters>

            <LabDsTable
              data={dataTableSingle}
              loading={isLoading}
              actionField
              headerPosition="left"
            />
            {dataTableSingle.rows.length >= 0 && (
              <Pagination
                forcePage={currentPage}
                pageCount={data?.pages[0].totalPages || 0}
                setCurrentPage={setCurrentPage}
              />
            )}
          </SectionContent>
        </Section>
      </Container>

      {showUserInvate && userSelect?.isActive ? (
        <ModalPermissionsEdit
          user={userSelect as User}
          setIsOpen={setShowUserInvate}
          isOpen={showUserInvate}
          unitId={user.unitId}
        />
      ) : null}

      {modalProps && openModalAction && (
        <ActionUser
          modalProps={modalProps}
          setIsOpen={setOpenModalAction}
          isOpen={openModalAction}
        />
      )}
      {OpenModalCreateEmail && (
        <ModalCreateUser
          setIsOpen={setOpenModalCreateEmail}
          isOpen={OpenModalCreateEmail}
          unitId={user.unitId}
        />
      )}
    </>
  );
}
