import { useState } from 'react';
import { FiLink } from 'react-icons/fi';
import { Headline } from '../../../LegacyV4Components';
import { useToast } from '../../../../hooks/toast';
import { convertNumberToBRL } from '../../../../utils/convertNumberToBRL';
import { Switch } from '../../molecules/Switch';
import { disableProduct } from '../../../../services/requests/Finance/disableProduct';
import * as S from './styles';

export interface CardCheckoutHQProps {
  id: string;
  creditCardInstallments: number;
  feeResponsible: string;
  payableWith: string[];
  disabled: boolean;
  paymentUrl: string;
  products: {
    _id: string;
    createdAt: string;
    description: string;
    name: string;
    physicalProduct: boolean;
    value: number;
    productType: string;
  };
}

export default function CardCheckoutHQ({ productList }: any) {
  const [switchOn, setSwitchOn] = useState(productList.disabled);
  const { addToast } = useToast();

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);

    addToast({
      type: 'success',
      title: 'Copiado para a área de transferência!',
    });
  }

  async function handleSwitchInteraction() {
    const response = await disableProduct(productList._id, !switchOn);

    if (response) {
      addToast({
        type: 'success',
        title: `Gateway ${
          switchOn ? 'habilitado' : 'desabilitado'
        } com sucesso!`,
      });
      setSwitchOn(!switchOn);
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao desabilitar gateway!',
      });
    }
  }

  return (
    <div key={productList.id}>
      <S.Container>
        <S.ContainerHead>
          <S.ContainerTitle color={switchOn ? '0.5' : '1'}>
            <Headline variant="h6" className="title">
              {productList.products[0].name}
            </Headline>
            <p>{productList.products[0].description}</p>
          </S.ContainerTitle>
          <S.ButtonToDisable>
            <p>{switchOn ? 'Habilitar gateway' : 'Desabilitar gateway'}</p>
            <Switch switchOn={switchOn} setSwitchOn={handleSwitchInteraction} />
          </S.ButtonToDisable>
        </S.ContainerHead>
        <S.ContainerHead color={switchOn ? '0.5' : '1'}>
          <S.ContainerInfo>
            <p>
              Produto fisico:{' '}
              {productList.products[0].physicalProduct === false
                ? 'Não'
                : 'Sim'}
            </p>
            <p>Valor: {convertNumberToBRL(productList.products[0].value)}</p>
            <p>
              <div style={{ display: 'flex' }}>
                <>
                  Método de pagamento:{' '}
                  {productList.payableWith.map((productLists: any) => {
                    switch (productLists) {
                      case 'credit_card':
                        return ` - Cartão ${productList.creditCardInstallments}x`;
                      case 'bank_slip':
                        return ' - Boleto';
                      case 'pix':
                        return ' - Pix';
                      default:
                        break;
                    }
                    return productLists;
                  })}
                </>
              </div>
            </p>
          </S.ContainerInfo>
          <S.ContainerInfo>
            {/* <p>Comissão: {`${productList.commissionPercentage}%`}</p> */}
            <p>
              Risco de juros:{' '}
              {productList.feeResponsible === 'customer'
                ? 'Cliente V4'
                : 'Cliente final'}
            </p>
          </S.ContainerInfo>
          <S.ContainerInfo>
            <S.ButtonCopyLink
              onClick={() => {
                !switchOn
                  ? copyToClipboard(
                      `https://mktlab.app/pagamento/${productList?._id}?isMatriz=true`,
                    )
                  : addToast({
                      type: 'error',
                      title: 'Este link está desativado!',
                    });
              }}
            >
              <p>Copiar link do Gateway</p>
              <FiLink />
            </S.ButtonCopyLink>
          </S.ContainerInfo>
        </S.ContainerHead>
      </S.Container>
    </div>
  );
}
