import styled from 'styled-components';

export const CopyButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 1rem;
  height: 1rem;

  border: none;
  background-color: transparent;

  & > svg {
    width: 0.75rem;
    height: 0.75rem;
  }
`;
