import { kubernetesApi as api } from '../../../api';
import { createOportunityInterface } from '../types';

export async function createOportunity(
  data: createOportunityInterface,
): Promise<createOportunityInterface | false> {
  try {
    const response = await api.post(`/calculator/new-price/opportunity`, data);
    const oportunityResponse: createOportunityInterface =
      response.data as createOportunityInterface;

    return oportunityResponse;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o unidade, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
