import styled from 'styled-components';

export const ContainerSelectNumber = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  margin-left: 0.625rem;
  border: none;
  background: transparent;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    all: unset;
    margin: 0;
  }
  input[type='number'] {
    background: white;
    border: 0.5px solid #dadae7;
    border-radius: 0.14rem;
    display: flex;
    padding: 0 0.625rem;
    width: 3.25rem;
    height: 2rem;
    margin: 0 1rem;
  }

  input {
    max-width: 2.6rem;
  }

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const CrescentNumber = styled.button`
  padding: 0.3rem 0.7rem;
  display: flex;
  background: white;
  border: 0.5px solid #dadae7;
  border-radius: 0.14rem;
`;
export const DecrescentNumber = styled.button`
  padding: 0.3rem 0.7rem;
  display: flex;
  background: white;
  border: 0.5px solid #dadae7;
  border-radius: 0.14rem;
`;
