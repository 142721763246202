import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MdOutlineAdd, MdOutlineClose } from 'react-icons/md';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { CreateLastUpdateModal } from '../../molecules/CreateCustomerLastUpdateModal';
import { useToast } from '../../../../hooks/toast';

import * as S from './styles';

import { LastUpdates } from '../../../../types/lastUpdates';
import { getLastUpdates } from '../../../../services/requests/LastUpdates/getLastUpdates';

interface CustomerDashboardsProps {
  lastUpdates: {
    newLastUpdates: LastUpdates[];
    removedLastUpdates: string[];
    loadedLastUpdates: LastUpdates[];
  };
  setLastUpdates: (lastUpdates: {
    newLastUpdates: LastUpdates[];
    removedLastUpdates: string[];
    loadedLastUpdates: LastUpdates[];
  }) => void;
  updates: boolean;
}

export function CustomerLastUpdates({
  lastUpdates,
  setLastUpdates,
  updates,
}: CustomerDashboardsProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const customerId = id;

  function handleCreateTemporaryLastUpdate(data: {
    name: string;
    description: string;
    videoUrl: string;
    files?: File[];
  }) {
    const lastUpdate = {
      name: data.name,
      videoUrl: data.videoUrl,
      description: data.description,
      customerId: customerId as string,
      files: data.files,
      _id: '',
    };
    setLastUpdates({
      ...lastUpdates,
      newLastUpdates: [...lastUpdates.newLastUpdates, lastUpdate],
    });

    addToast({
      title: 'Vídeo anexado!',
      type: 'warning',
      description:
        'Para confirmar as alterações feitas, clique no botão publicar no canto superior direito. Assim as informações ficarão visíveis para o cliente!',
    });
  }

  function handleTemporaryDashboardDelete(lastUpdateId: string, i: number) {
    if (!lastUpdateId) {
      lastUpdates.newLastUpdates.splice(i, 1);

      setLastUpdates({
        ...lastUpdates,
        newLastUpdates: [...lastUpdates.newLastUpdates],
      });
      return;
    }

    lastUpdates.loadedLastUpdates.splice(i, 1);

    setLastUpdates({
      ...lastUpdates,
      loadedLastUpdates: [...lastUpdates.loadedLastUpdates],
      removedLastUpdates: [...lastUpdates.removedLastUpdates, lastUpdateId],
    });
  }

  useEffect(() => {
    async function getCustomerLastUpdates() {
      const response = await getLastUpdates(customerId as string);

      if (response && !updates) {
        setLastUpdates({
          loadedLastUpdates: response,
          newLastUpdates: [],
          removedLastUpdates: [],
        });
      }
    }

    getCustomerLastUpdates();
  }, [setLastUpdates, customerId, updates]);

  return (
    <>
      <S.CustomerLastUpdates>
        <S.Header>
          <S.HeaderText>
            <h3>Gravações e vídeos</h3>
            <p>
              Adicione vídeos e gravações de check-ins e outras agendas que
              forem importantes para o cliente ter acesso.
            </p>
          </S.HeaderText>

          <S.AddLastUpdateButton onClick={() => setIsModalOpen(true)}>
            Adicionar novo <MdOutlineAdd />
          </S.AddLastUpdateButton>
        </S.Header>
        <S.LastUpdates>
          {lastUpdates.loadedLastUpdates.map((lastUpdate, i) => (
            <S.LastUpdate key={lastUpdate._id}>
              <AiOutlinePlayCircle />
              <S.LastUpdateText>
                <p>{lastUpdate.name}</p>
                <p>
                  Link:{' '}
                  <a href={lastUpdate.videoUrl} target="blank">
                    {lastUpdate.videoUrl}
                  </a>
                </p>
              </S.LastUpdateText>
              <MdOutlineClose
                onClick={() =>
                  handleTemporaryDashboardDelete(lastUpdate._id, i)
                }
                style={{ cursor: 'pointer' }}
              />
            </S.LastUpdate>
          ))}
          {lastUpdates.newLastUpdates.map((lastUpdate, i) => (
            <S.LastUpdate>
              <AiOutlinePlayCircle />
              <S.LastUpdateText>
                <p>{lastUpdate.name}</p>
                <p>
                  Link:{' '}
                  <a href={lastUpdate.videoUrl} target="blank">
                    {lastUpdate.videoUrl}
                  </a>
                </p>
              </S.LastUpdateText>
              <MdOutlineClose
                onClick={() =>
                  handleTemporaryDashboardDelete(lastUpdate._id, i)
                }
                style={{ cursor: 'pointer' }}
              />
            </S.LastUpdate>
          ))}
        </S.LastUpdates>
      </S.CustomerLastUpdates>
      <CreateLastUpdateModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        handleCreateLastUpdate={handleCreateTemporaryLastUpdate}
      />
    </>
  );
}
