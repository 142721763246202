import { Dashboard } from '../../../types/dashboard';
import { createProductFNC } from '../../../types/createCustomerFNC';
import { financeApi } from '../../api';

export interface createProductHQ {
  name: string;
  value: number;
  description: string;
  physicalProduct: boolean;
  productType: string;
}

export async function createProduct(
  data: createProductFNC,
): Promise<Dashboard | false> {
  try {
    const response = await financeApi.post<Dashboard>(
      `/checkouts/create`,
      data,
    );
    const product = response.data;
    return product;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o produto, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}

export async function createProductHQ(
  data: any,
): Promise<createProductHQ | false> {
  try {
    const response = await financeApi.post<createProductHQ>(`/products`, data);
    const product = response.data;

    return product;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao criar o produto, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}

export async function EditProductHQ(
  data: any,
  id: string,
): Promise<createProductHQ | false> {
  try {
    const response = await financeApi.patch<createProductHQ>(
      `/products/${id}`,
      data,
    );
    const product = response.data;

    return product;
  } catch (err) {
    console.error(
      `Ocorreu algum problema ao editar o produto, atualize a página e tente novamente 😕`,
      err,
    );
    return false;
  }
}
