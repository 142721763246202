import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { financeApi } from '../../api';

export interface CheckoutList {
  _id: string;
  recipientId: string;
  products: Product[];
  name: string;
  value: number;
  description: string;
  physicalProduct: boolean;
  payableWith: string[];
  feeResponsible: string;
  creditCardInstallments: number;
  commissions: Commissions;
  disabled: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  paymentUrl: string;
}

export interface Product {
  id: string;
  value: number;
}

export interface Commissions {
  comissionType: string;
  commissionValue: CommissionValue;
}

export interface CommissionValue {
  unitComission: number;
  headquarterComission: number;
}

export function useGetCheckoutHQList(
  type: string | undefined,
): UseQueryResult<CheckoutList, CheckoutList> {
  return useQuery<CheckoutList, CheckoutList>(
    ['checkoutList', type],
    () =>
      financeApi.get<CheckoutList>(`checkouts/hq/${type}`).then(async (r) => {
        return r.data;
      }),
    {},
  );
}
