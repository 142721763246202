import { useCallback, useState, useRef } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Modal } from '../../../LegacyV4Components';

import { Input } from '../../../Default/Input';
import { useToast } from '../../../../hooks/toast';

import { getValidationErrors } from '../../../../utils/getValidationErrors';
import { Button } from '../../../Default/Button';
import { ModalLoading } from '../../../Default/Loadings/ModalLoading';

import * as S from './styles';

interface InviteNewColabProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

export function InviteNewColab({ isOpen, setIsOpen }: InviteNewColabProps) {
  const [loading, setLoading] = useState(false);
  const inviteUserForm = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const cancel = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleSubmit = useCallback(
    async (data: { email: string }) => {
      try {
        inviteUserForm.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Precisa ser um email valido')
            .required('E-mail obrigatório.')
            .max(255, 'No máximo 255 dígitos.')
            .min(3, 'No mínimo 3 dígitos.')
            .matches(
              RegExp('^[A-Za-z0-9._%+-]+@v4company.com$'),
              'O email deve ser @v4company',
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        // if (invite) {
        //   addToast({
        //     type: 'success',
        //     title: 'Usuário convidado com sucesso!',
        //     description: `Convite enviado com sucesso!`,
        //   });
        // }

        setLoading(false);
        setIsOpen(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          inviteUserForm.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'Erro ao convidar usuário',
          description: `Problema ao enviar o convite.`,
        });
        setLoading(false);
      }
    },
    [addToast, setIsOpen],
  );

  return (
    <Modal show={isOpen} onBackground={() => setIsOpen(false)}>
      <S.Container ref={inviteUserForm} onSubmit={handleSubmit}>
        {loading ? (
          <S.Loading>
            <ModalLoading rows={2} />
          </S.Loading>
        ) : (
          <>
            <h2>Cadastrar Novo Investidor</h2>

            <section>
              <span>E-mail</span>
              <Input name="email" placeholder="E-mail do investidor" />
            </section>

            <S.Buttons>
              <Button small secondary onClick={cancel}>
                Cancelar
              </Button>
              <Button small type="submit">
                Cadastrar
              </Button>
            </S.Buttons>
          </>
        )}
      </S.Container>
    </Modal>
  );
}
