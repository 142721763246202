import * as yup from 'yup';

export function updateInvestorSchemaCreator(isAdmin: boolean) {
  let updateInvestorSchema = yup.object().shape({
    // cep: yup.string().required('Campo Obrigatório!'),
    phone: yup
      .string()
      .test(
        'maxLenght',
        'Número inválido',
        (val) => `${val?.replaceAll('_', '')}`.length === 16 || false,
      )
      .required('Insira seu número'),
    wage: yup.number().min(0, 'Campo Obrigatório!'),
  });

  if (isAdmin)
    updateInvestorSchema = updateInvestorSchema.concat(
      yup.object().shape({
        mandateId: yup
          .string()
          .nullable(false)
          .typeError('Campo Obrigatório!')
          .required('Campo Obrigatório!'),
        seniorityId: yup
          .string()
          .nullable(false)
          .typeError('Campo Obrigatório!')
          .required('Campo Obrigatório!'),
      }),
    );
  return updateInvestorSchema;
}
