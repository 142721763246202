import styled from 'styled-components';

export const Status = styled.td`
  display: flex;
`;

export const StatusLabel = styled.div`
  align-items: center;
  justify-content: center;
  max-width: 10.5rem;
  min-width: 7.6rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;

  p {
    color: var(--secondary-main, #333);
    text-align: right;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1rem;
  }

  &.integrando {
    display: flex;
    padding: 0.25rem 1rem;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    border-radius: 2.6875rem;
    border: 2px solid var(--warning, #fabc2a);

    svg {
      color: var(--warning, #fabc2a);
    }
  }
  &.problema {
    display: flex;
    padding: 0.25rem 1rem;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    border-radius: 2.6875rem;
    border: 2px solid var(--error, #e03131);

    svg {
      color: var(--error, #e03131);
    }
  }
  &.vinculado {
    display: flex;
    padding: 0.25rem 1rem;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    border-radius: 2.6875rem;
    border: 2px solid var(--success, #00ab86);

    svg {
      color: var(--success, #00ab86);
    }
  }
`;
