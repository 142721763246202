export const permissionsPaymentGateway = [
  {
    label: 'Visualizar',
    value: 'READ_ONLY',
    desc: 'Permissão para visualizar apenas o gateway de pagamento',
    roles: {
      view: true,
      edit: false,
    },
  },
  {
    label: 'Criar e Editar',
    value: 'WRITE_AND_READ',
    desc: 'Permissão para criar e editar o gateway de pagamento',
    roles: {
      view: true,
      edit: true,
    },
  },
];
