import { useInfiniteQuery } from '@tanstack/react-query';
import { customerNewApi as api } from '../../api';

interface IFindAllProjectsResponse {
  page: number;
  totalPages: number;
  projects: IProject[];
}

async function findAllProjects(
  unitId: string,
  name?: string,
  page = 1,
  isActive = true,
): Promise<IFindAllProjectsResponse> {
  try {
    const params = {
      limit: 10,
      page,
      isActive,
      name,
    };

    if (unitId !== process.env.REACT_APP_HEADQUARTER_ID)
      Object.assign(params, { unitId });

    const response = await api.get<IFindAllProjectsResponse>(
      '/find-project-all',
      {
        params,
      },
    );

    return response.data;
  } catch {
    return {
      page: 0,
      totalPages: 0,
      projects: [],
    };
  }
}

function useFindAllProjects(unitId: string, name?: string, isActive = true) {
  return useInfiniteQuery(
    ['find-all-projects', unitId, name, isActive],
    async ({ pageParam = 1 }) => {
      const response = findAllProjects(unitId, name, pageParam, isActive);
      return response;
    },
    {
      getPreviousPageParam: (currentPage) => currentPage.page - 1 ?? undefined,
      getNextPageParam: (currentPage) =>
        currentPage.page + 1 <= currentPage.totalPages
          ? currentPage.page + 1
          : undefined,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
}

export { useFindAllProjects, findAllProjects };
