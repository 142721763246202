import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { customerApi } from '../../api';
import { Customer } from '../../../types/customer';

export function useQueryGetCustomer(
  customerId: string | undefined,
): UseQueryResult<{ data: Customer }, { data: Customer }> {
  return useQuery<{ data: Customer }, { data: Customer }>(
    ['Customer', customerId],
    () => customerApi.get<Customer>(`/customer/${customerId}`),
    {},
  );
}
