import { useEffect, useMemo, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { Headline, Modal, Paragraph } from '../../../LegacyV4Components';
import * as S from './styles';
import { DataGrid, DataProps } from '../../atoms/DataGrid';
import { getCustomersByUserId } from '../../../../services/requests/Customers/getCustomersByUserId';
import { User } from '../../../../hooks/types';
import { getCustomerResponse } from '../../../../types/customer';

interface NewPendingInvitesProps {
  isOpen: boolean;
  investorSelected: string;
  userInfos: User;
  setIsOpen: (state: boolean) => void;
}

interface ColumnProps {
  label: string;
  accessor: string;
  isSorted: boolean;
  sortable: boolean;
  sortByOrder: 'asc' | 'desc';
  maxWidth: number;
  isActions?: boolean;
}

export function ProjectsModal({
  isOpen,
  setIsOpen,
  userInfos,
  investorSelected,
}: NewPendingInvitesProps) {
  const tableColumns = [
    {
      accessor: 'project',
      label: 'Projeto',
    },
    {
      accessor: 'dedication',
      label: 'Dedicação',
      sizing: 1,
    },
  ] as ColumnProps[];

  const [projectsApi, setProjects] = useState<getCustomerResponse[]>([]);

  useEffect(() => {
    const getCustomers = async () => {
      const usersPromise = await getCustomersByUserId(investorSelected);
      if (usersPromise) setProjects(usersPromise);
      return usersPromise;
    };
    getCustomers();
  }, [investorSelected]);

  const tableContent = useMemo(() => {
    const tableData: DataProps[] = [];
    projectsApi.forEach((currentProject: getCustomerResponse) => {
      if (currentProject.active)
        tableData.push({
          id: currentProject.customerId,
          content: (
            <>
              <td>
                <S.RowTable>
                  <Paragraph>
                    {currentProject.customer?.name || currentProject.customerId}
                  </Paragraph>
                </S.RowTable>
              </td>
              <td>
                <S.RowTable>
                  <Paragraph>
                    {currentProject.isDedicated ? 'Dedicado' : 'Compartilhado'}
                  </Paragraph>
                </S.RowTable>
              </td>
            </>
          ),
        });
    });

    return tableData;
  }, [projectsApi]);

  return (
    <>
      <Modal show={isOpen} onBackground={() => setIsOpen(false)}>
        <S.Close>
          <FiX
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </S.Close>
        <S.Header>
          {userInfos.capacity?.isOnboarding ? (
            <S.OnboardingProcessInfo>
              {`${userInfos.name} está em processo de onboarding.`} <br />
              {`A previsão para conclusão é de
              ${
                userInfos.capacity?.daysInOnboarding -
                userInfos.capacity?.daysPast
              } dias.`}
            </S.OnboardingProcessInfo>
          ) : null}
          <Headline variant="h5">Projetos que atende</Headline>
        </S.Header>
        {tableContent.length ? (
          <DataGrid
            showModalUserInvate={false}
            setShowModalUserInvate={() => null}
            columns={tableColumns}
            data={tableContent || []}
          />
        ) : (
          <p>Nenhuma projeto é atendido por este usuário</p>
        )}
      </Modal>
    </>
  );
}
