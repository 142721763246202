import { useQuery } from '@tanstack/react-query';

import { newAds } from '../../api';

interface IPlatformIntegrations {
  name: string;
  count: number;
}

interface IResponse {
  total: number;
  platforms: IPlatformIntegrations[];
}

function useGetUnitIntegrations(unitId: string) {
  async function handleGetUnitIntegrations() {
    const { data } = await newAds.get<IResponse>(
      `/history/customerIntegration/${unitId}`,
    );

    return data;
  }
  return useQuery(['integrations', unitId], handleGetUnitIntegrations);
}

export { useGetUnitIntegrations };
