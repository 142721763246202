export const educationalPrograms = [
  {
    label: 'Não entrou através de programas de Educação',
    value: 'NO_EDUCATIONAL_PROGRAM',
  },
  {
    label: 'Apollo',
    value: 'APOLLO',
  },
  {
    label: 'Assessor / GMP',
    value: 'ASSESSOR_OR_GMP',
  },
  {
    label: 'Scout',
    value: 'SCOUT',
  },
  {
    label: 'Outros',
    value: 'OTHERS',
  },
];
