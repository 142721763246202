import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 1rem;
  color: var(--secondary-main);
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 4vh;

  :first-child {
    margin-top: 4vh;
  }
`;

export const InputLoading = styled.div`
  width: 60%;
  margin-right: 0.8rem;
  height: 2rem;
  background-image: linear-gradient(
    90deg,
    var(--grayscale-2) 0%,
    var(--grayscale-2) 40%,
    var(--tertiary-main) 50%,
    var(--tertiary-main) 55%,
    var(--grayscale-2) 65%,
    var(--grayscale-2) 100%
  );
  border-radius: 0.2rem;
  background-size: 400%;
  animation: loading 1500ms infinite;

  & + div {
    width: 40%;
    margin-left: 0.8rem;
  }

  @keyframes loading {
    from {
      background-position: 100% 100%;
    }
    to {
      background-position: 0 0;
    }
  }
`;
